import { useUserDataV2 } from './useUserDataV2'
import { printArticleDataSheet } from '@obeta/utils/lib/printPdf'
import { useAppDomain } from './useAppDomain'
import { useTranslation } from 'react-i18next'

export const usePrintArticleDataSheet = () => {
  const { tokens } = useUserDataV2()
  const domain = useAppDomain()
  const { t } = useTranslation()

  const print = (sapId: string, requestParamsUrl?: string) => {
    const notificationData = {
      id: t('PRINT.DATASHEET_EVENT_ID', { sapId }),
      heading: t('PRINT.DATASHEET_PDF_HEADER'),
      body: t('PRINT.DATASHEET_PDF_BODY', { sapId }),
    }
    return printArticleDataSheet({
      sapId,
      accessToken: tokens?.accessToken,
      domain,
      notificationData,
      requestParamsUrl,
    })
  }

  return { print }
}
