import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useMemo, useRef } from 'react'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'

export type SetAmount = (
  product: ProductAggregate | MaybeCompleteSearchProduct,
  amount: number
) => void
export type GetAmount = (product: ProductAggregate) => void

/**
 * intented to be used for amounts caching
 * ref object is used internally instead of useState to avoid unnecessary re-renders
 * @returns
 */
export const useArticlesAmounts = () => {
  /** allows to "hook" amount. used on user actions (for example, redirect).
   * ref is enough for now (it also allows to reduce number of rerenders)
   */
  const amountsRef = useRef<Record<string, number>>({})

  return useMemo(() => {
    return {
      getAmount: (product: ProductAggregate) => {
        return amountsRef.current[product.sapId] || product.minimumAmount
      },
      setAmount: (product: ProductAggregate, amount: number) => {
        amountsRef.current[product.sapId] = amount
      },
    }
  }, [])
}
