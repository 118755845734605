import { pluginFactories } from '@react-page/plugins-slate'
import { useState } from 'react'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'
import { Typography, Box } from '@mui/material'
import styles from './customSlatePlugin.module.scss'

const CollapsibleContent = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { children, attributes } = props
  const handleClick = () => {
    setIsCollapsed(!isCollapsed)
  }
  const title = { ...children, props: { children: [children.props.children[0]] } }
  const content = { ...children, props: { children: children.props.children.slice(1) } }

  return (
    <Box {...attributes} className={styles.mainWrapper}>
      <Box className={styles.titleWrapper}>
        <div onClick={handleClick} className={styles.title}>
          {title}
        </div>
        <div onClick={handleClick} className={styles.btnCollapsible}>
          {isCollapsed ? <ArrowDown /> : <ArrowUp />}
        </div>
      </Box>
      <div className={styles.titleUnderline} />
      <div
        style={{ display: `${isCollapsed ? 'none' : 'block'}` }}
        className={styles.contentWrapper}
      >
        {content}
      </div>
    </Box>
  )
}

export const CollapsibleWrapper = pluginFactories.createComponentPlugin({
  addToolbarButton: true,
  type: 'Collapsible/Wrapper',
  label: 'Insert Collapsible Text',
  object: 'block',
  icon: <span>C</span>,
  addHoverButton: false,
  Component: CollapsibleContent,
})

export const CollapsibleHeader = pluginFactories.createComponentPlugin({
  type: 'Collapsible/Header',
  object: 'block',
  addToolbarButton: false,
  addHoverButton: false,
  Component: (props) => {
    const isBold = props.childNodes[0]['EMPHASIZE/STRONG']

    return (
      <Typography
        {...props.attributes}
        className={styles.collapsibleTitle}
        fontWeight={isBold ? 'bold' : 'inherit'}
        variant="body1"
      >
        {props.children}
      </Typography>
    )
  },
})
