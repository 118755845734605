import { FC, ReactNode } from 'react'
import { Card as MUICard, Typography } from '@mui/material'

// Styles
import styles from './Card.module.scss'

type CardProps = {
  header: string
  children: ReactNode
}

export const Card: FC<CardProps> = (props) => {
  const { children, header } = props

  return (
    <MUICard className={styles.card}>
      <Typography variant="bodyBold">{header}</Typography>
      {children}
    </MUICard>
  )
}
