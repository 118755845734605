import clsx from 'clsx'
import { Typography } from '@mui/material'
import styles from './ArticleStickyHeader.module.scss'
import { ArticleStickyHeaderPriceProps } from './ArticleStickyHeaderPrice'

export const ArticleStickyHeaderStrikeThroughPrice: React.FC<ArticleStickyHeaderPriceProps> = ({
  price,
  userCanReadPrices,
}) => {
  if (!price || !userCanReadPrices) return null

  return (
    <Typography className={clsx(styles.oldPrice)} noWrap variant="smallTextLineThrough">
      {price}
    </Typography>
  )
}
