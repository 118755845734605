import { ButtonBase } from '@mui/material'
import React from 'react'
import { ReactComponent as SettingsIcon } from '@obeta/assets/icon/toast/settings.svg'
import styles from './SettingsButton.module.scss'
import clsx from 'clsx'

export const SettingsButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
  const { className, ...rest } = props

  return (
    <ButtonBase className={clsx(styles.button, className)} {...rest}>
      <SettingsIcon />
    </ButtonBase>
  )
}
