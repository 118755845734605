import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ActionButton } from '@obeta/components/lib/action-button/ActionButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from './DigitalBillingLogin.module.scss'
import { useLogin } from '@obeta/data/lib/hooks'

export const DigitalBillingLogin = () => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const { startLogin } = useLogin()
  return (
    <>
      <div className={styles.topSectionBackground} />
      <Box className={clsx(styles.sectionWrapper, mobile && styles.mobileWrapper)}>
        <Typography variant="bodyBold" className={styles.info}>
          {t('DIGITAL_BILLING.LOGIN.INFO')}
        </Typography>
        <Box display="flex" justifyContent="center">
          <ActionButton
            typographyVariant="body"
            color="darkGray"
            variant="contained"
            onClick={startLogin}
            className={styles.loginButton}
          >
            {t('DIGITAL_BILLING.LOGIN.BUTTON')}
          </ActionButton>
        </Box>
      </Box>
    </>
  )
}
