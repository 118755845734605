import { FC } from 'react'
import { AdvertisingContextProvider } from './hooks/useAdvertaisingProvider'
import { AllAdvertsSection } from './components/AllAdvertsSection/AllAdvertsSection'
import { MonthsAdvertisementSection } from './components/MonthsAdvertisementSection/MonthsAdvertisementSection'
import { TitleSection } from './components/TitleSection/TitleSection'
import { ControlSection } from './components/ControlSection/ControlSection'
import styles from './AdvertisingPage.module.scss'

export const AdvertisingPage: FC = () => {
  return (
    <div className={styles.advertsPageWrapper}>
      <AdvertisingContextProvider>
        <TitleSection />
        <MonthsAdvertisementSection />
        <ControlSection />
        <AllAdvertsSection />
      </AdvertisingContextProvider>
    </div>
  )
}
