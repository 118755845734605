import { Link as BaseLink } from '@mui/material'
import clsx from 'clsx'
import styles from './Link.module.scss'

type LinkProps = React.ComponentProps<typeof BaseLink>

export const Link: React.FC<LinkProps> = (props) => {
  const { className, variant = 'smallText' } = props
  return <BaseLink {...props} className={clsx(styles.link, className)} variant={variant} />
}
