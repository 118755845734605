/**
 * Chains a series of functions together, passing the output of one function as
 * the input of the next function.
 *
 * @template T
 * @param {T} input - The initial input value.
 * @param {...function(T): T} fns - An array of functions to apply in sequence.
 * @returns {T} The final output value.
 */
export const pipe = (input, ...fns) => fns.reduce((acc, fn) => fn(acc), input)
