export const base64ToFile = (base64String: string, mimeType: string, filename: string) => {
  const binaryString = Buffer.from(base64String, 'base64').toString('binary')
  const uintArray = new Uint8Array(binaryString.length)

  for (let i = 0; i < binaryString.length; i++) {
    uintArray[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([uintArray], { type: mimeType })

  return new File([blob], filename, { type: mimeType })
}
