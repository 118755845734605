import { useEffect, useState } from 'react'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import styles from './TecworldPage.module.scss'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// UI
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'

const WHOLESALER_ID = '47344'

export const TecworldPage = () => {
  const { mobile } = useBreakpoints()
  const params = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const skeletonContentHeight = mobile ? '14rem' : '20rem'
  const skeletonSubtitleWidth = mobile ? '70%' : '30%'
  const skeletonTitleWidth = mobile ? '100%' : '70%'

  useEffect(() => {
    if (params.id) {
      const script = document.createElement('script')
      script.src = 'https://www.tecworld.com/widget.v1.0.0.js'
      script.async = true
      script.setAttribute('data-mode', 'promo')
      script.setAttribute('data-id', params.id)
      script.setAttribute('data-wholesaler-id', WHOLESALER_ID)
      document.getElementById('widget-container')?.appendChild(script)
    }
  }, [params.id])

  useEffect(() => {
    const waitForElement = () => {
      const element = document.querySelector('.tecworld-widget-lightdom')
      if (element) {
        // The .tecworld-widget-lightdom element, which is loaded via a script, is now present in the DOM.
        // We need to append it to the wrapper element to display it in the correct location.
        const wrapper = document.querySelector('.tecworld-wrapper')
        if (wrapper) {
          setIsLoading(false)
          wrapper.appendChild(element)
        }
      } else {
        setTimeout(waitForElement, 100)
      }
    }
    waitForElement()
  }, [])

  return (
    <div id="widget-container">
      {isLoading && (
        <div className={styles.skeletons}>
          <TextSkeleton
            className={styles.skeletonsTitle}
            height="2.375rem"
            width={skeletonTitleWidth}
          />
          <TextSkeleton
            className={styles.skeletonsSubtitle}
            height="1.5rem"
            width={skeletonSubtitleWidth}
          />
          <TextSkeleton
            className={styles.skeletonsContent}
            height={skeletonContentHeight}
            width="100%"
          />
          <div className={styles.skeletonsList}>
            {Array.from(Array(3), (e, i) => (
              <TextSkeleton key={i} height="0.875rem" width="100%" />
            ))}
          </div>
        </div>
      )}
      <div className="tecworld-wrapper"></div>
    </div>
  )
}
