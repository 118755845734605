import { TypographyLineClamp } from '@obeta/components/lib/typography/TypographyLineClamp'
import { Category } from '@obeta/models/lib/models/Category'
import { CategoryIcon } from '@obeta/components/lib/categories/Categories'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { ShowMoreList } from '@obeta/components/lib/show-more-list/ShowMoreList'
import { DispatchFiltersAction, SetChosenCategory } from '../reducer'
import { Header } from '@obeta/components/lib/stack/Header'
import { CollapseComponent } from '../CollapseComponent'
import { useTranslation } from 'react-i18next'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { Card } from '@mui/material'
import collapseCardStyles from '@obeta/components/lib/collapse-card/CollapseCard.module.scss'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { HierarchicalFacetElement } from '@obeta/models/lib/models/Search'
import { NavigatorApi, StackNavigator, StackScreen } from '@obeta/components/lib/stack'
import { FadeTransition } from '@obeta/components/lib/stack/FadeTransition'
import { useHasMounted } from '@obeta/data/lib/hooks/useHasMounted'
import { constructCatId } from '@obeta/utils/lib/categories'
import styles from './Categories.module.scss'
import { useCategories } from '@obeta/data/lib/hooks/useCategories'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import React from 'react'
import { Size } from '../Size'
import { CategoriesListSkeletons } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import { E2EProps } from '@obeta/components/lib/types'

const catHeightRem = parseFloat(styles.catHeight)
const catGapRem = parseFloat(styles.catGap)

interface IBaseCategoriesProps extends Partial<WithBreakpoints<'mobile'>> {
  chosenCategory: Category | undefined
  dispatchFiltersAction: DispatchFiltersAction
}

type CatType = SetChosenCategory['payload']['type']

interface ICategoriesProps extends IBaseCategoriesProps {
  title: string
  type: CatType
  withIcon: boolean
}

const defCatalog: HierarchicalFacetElement = {
  subFacets: [],
  meta: {
    name: '',
  },
  value: '',
  count: 0,
}
const defItems = []

const Cat: React.FC<
  {
    onClick?: MouseEventHandler
    iconId: string | null
    catHeight: number
    cat: Category
    catType: CatType
    size?: number
  } & E2EProps
> = (props) => {
  const { cat, catHeight, iconId, onClick, catType, size, datatestid } = props

  return (
    <div
      className={styles.category}
      onClick={onClick}
      {...(datatestid && { 'data-testid': `${datatestid}-item` })}
    >
      {iconId && (
        <CategoryIcon
          className={styles.categoryIcon}
          catId={iconId}
          size={catType === 'dehaCategory' ? 'xs' : 'md'}
          imgSize={catType === 'dehaCategory' ? undefined : 'xs'}
        />
      )}
      <TypographyLineClamp typography="bodyBold" maxElHeight={catHeight}>
        {cat.name} <Size datatestid={datatestid} size={size} />
      </TypographyLineClamp>
    </div>
  )
}

const LEVELS = [-1, 0, 1, 2, 3]

type CatsByLevel = Record<number, Category>

const Categories: React.FC<ICategoriesProps & { currentLevel: number } & E2EProps> = (props) => {
  const { mobile, chosenCategory, dispatchFiltersAction, type, withIcon, title, currentLevel } =
    props

  const CardComp = mobile ? 'div' : Card
  const parent = chosenCategory?.parent
  const catHeight = useRemToPx(catHeightRem)
  const catGap = useRemToPx(catGapRem)
  const nextLevel = currentLevel + 1

  const { t } = useTranslation()

  const { searchParams } = useArticlesSearchProvider()
  const {
    categories: { topLevelCategories, obetaCatalog },
    loading,
  } = useCategories({
    searchParams,
    currentCategory: chosenCategory,
    type,
  })

  const catalog = obetaCatalog?.[0] || defCatalog
  const items = chosenCategory ? catalog.subFacets : topLevelCategories || defItems

  if (catalog.count === 0) {
    return null
  }

  return (
    <CardComp
      className={clsx(collapseCardStyles.card, styles.card, {
        [styles.mobileCard]: mobile,
      })}
    >
      {chosenCategory ? (
        <Header
          typographyVariant="smallText"
          title={parent?.name ? parent.name : t<string>('SEARCH.EXIT_FILTERS')}
          onBackClicked={() => {
            dispatchFiltersAction({
              type: 'setChosenCategory',
              payload: {
                type,
                category: parent ?? null,
              },
            })
          }}
        />
      ) : null}
      <CollapseComponent
        mobile={true}
        iconLeft={false}
        className={styles.collapseComp}
        classes={{ header: collapseCardStyles.header }}
        header={
          chosenCategory ? (
            <Cat
              cat={chosenCategory}
              iconId={withIcon ? constructCatId(chosenCategory.rootId) : null}
              catHeight={catHeight}
              catType={type}
            />
          ) : (
            title
          )
        }
        datatestid={props.datatestid}
      >
        <div className={styles.root}>
          {loading ? (
            <CategoriesListSkeletons />
          ) : (
            <ShowMoreList
              itemHeight={catHeight}
              gapHeight={catGap}
              listSize={items.length}
              maxSize={3}
              classes={{
                listWrap: styles.categories,
              }}
            >
              {items.map((cat) => {
                const name = cat.meta.name || cat.value
                return (
                  <Cat
                    key={cat.value}
                    cat={{ name: name, id: cat.value }}
                    iconId={withIcon && !chosenCategory ? constructCatId(cat.value) : null}
                    catHeight={catHeight}
                    catType={type}
                    size={cat.count}
                    onClick={() => {
                      if (nextLevel && nextLevel > 3) {
                        // lvl4 doesn't exist
                        return
                      }

                      let parent: Category | undefined
                      if (chosenCategory) {
                        parent = {
                          ...chosenCategory,
                        }
                      }

                      dispatchFiltersAction({
                        type: 'setChosenCategory',
                        payload: {
                          type,
                          category: {
                            id: cat.value,
                            name: name,
                            level: nextLevel,
                            parent,
                            rootId: parent ? parent.rootId : cat.value,
                          },
                        },
                      })
                    }}
                    datatestid={props.datatestid}
                  />
                )
              })}
            </ShowMoreList>
          )}
        </div>
      </CollapseComponent>
    </CardComp>
  )
}

const CategoriesWithFade: React.FC<ICategoriesProps & E2EProps> = (props) => {
  const stackScreenApiRef = useRef<NavigatorApi>(null)
  const hasMounted = useHasMounted()
  const { chosenCategory: _chosenCategory } = props

  const chosenCategory = _chosenCategory || null
  const currentLevel = chosenCategory ? chosenCategory.level || 0 : -1

  // catsByLevel used to show fade effect for unmounted categories view.
  // view rendered by level.
  const [catsByLevel, setCatsByLevel] = useState<CatsByLevel>({})
  useEffect(() => {
    setCatsByLevel((base) => {
      let parent = chosenCategory
      const c: CatsByLevel = {}

      while (parent) {
        c[parent.level || 0] = parent
        parent = parent?.parent || null
      }

      return {
        ...base,
        ...c,
      }
    })
  }, [chosenCategory])

  useEffect(() => {
    if (!hasMounted) {
      return
    }

    const api = stackScreenApiRef.current
    if (!api) {
      return
    }

    api.push(currentLevel.toString())
  }, [currentLevel, hasMounted])

  return (
    <StackNavigator ref={stackScreenApiRef} initialScreenName={currentLevel.toString()}>
      <FadeTransition>
        {LEVELS.map((lvl) => {
          return (
            <StackScreen className={styles.stackScreen} key={lvl} name={lvl.toString()}>
              <Categories {...props} currentLevel={lvl} chosenCategory={catsByLevel[lvl]} />
            </StackScreen>
          )
        })}
      </FadeTransition>
    </StackNavigator>
  )
}

export const StockAssortmentCatalog = React.memo<IBaseCategoriesProps & E2EProps>((props) => {
  const { t } = useTranslation()

  return (
    <CategoriesWithFade
      {...props}
      withIcon={true}
      title={t<string>('SEARCH.STOCK_ASSORTMENT_CATALOGUE')}
      type="obetaCategory"
    />
  )
})

export const OnlineCategories = React.memo<IBaseCategoriesProps & E2EProps>(
  function OnlineCategories(props) {
    const { t } = useTranslation()

    return (
      <CategoriesWithFade
        {...props}
        withIcon={true}
        title={t<string>('SEARCH.ONLINE_CATEGORIES')}
        type="dehaCategory"
      />
    )
  }
)
