import {
  OfferPriceOptions,
  ShowPricesWithVAT,
  DatsheetPrintOptions,
} from '../../models/Datasheet/IndividualDatasheet'
import { OpenPosition } from '../../schema-models/open-positions'

export enum ScannerListType {
  ShopingCart = 'shopingCart',
  Article = 'article',
  CartTemplate = 'cartTemplate',
  OfferDetails = 'offerDetails',
}

export interface ScannerListComponentData {
  title: string
  price: number
  dehaId: string
  unit: string
}

export enum TEMPLATE_VARIANTS {
  Zweckform4780 = 'Zweckform4780',
  Zweckform3490 = 'Zweckform3490',
  Zweckform4781 = 'Zweckform4781',
  ZweckformC32258 = 'ZweckformC32258',
}

export enum ContentTypes {
  CART,
  ORDER,
  DATASHEET,
  SCANNERLIST,
  OPEN_POSITION,
}

export enum HistogramLabelTypes {
  ArticleDataSheet = 'articleDataSheet',
  ArticleDataSheetByIds = 'articleDataSheetByIds',
  Cart = 'cart',
  Order = 'order',
  scannerlist = 'scannerlist',
  openPosition = 'openPosition',
}

export type ScannerListData = {
  templateType: TEMPLATE_VARIANTS
  offset: number
  parentId?: string
  childIds?: string[]
  childIdsExcluded?: string[]
  type: ScannerListType
}

export enum PrintCartViewTypes {
  Detailed = 'detailed',
  Tabular = 'tabular',
}

export type IndividualDatasheet = {
  showPricesWithVAT?: ShowPricesWithVAT
  offerPriceOption?: OfferPriceOptions
  fixedPriceValue?: number
  percentagePriceBaseValue?: string
  priceMarkup?: number
  listPrice?: boolean
  catalogPrice?: boolean
  metalSurcharge?: boolean
  type: DatsheetPrintOptions.INDIVIDUAL
}

type StandartDatasheet = {
  type: DatsheetPrintOptions.STANDART
}

export type DatasheetConfig = IndividualDatasheet | StandartDatasheet

export type OpenPositionFilter = 'all' | 'overdue' | 'discountable' | 'credits'

type OpenPositionSortingOrder = 'asc' | 'desc'
type OpenPositionSortingOrderBy = keyof Pick<
  OpenPosition,
  | 'receipt'
  | 'invoiceFromDate'
  | 'invoiceDueDate'
  | 'invoiceAmount'
  | 'openAmount'
  | 'dunningLevel'
  | 'discountDate'
>
export type OpenPositionSorting = {
  orderBy: OpenPositionSortingOrderBy
  order: OpenPositionSortingOrder
}

type OpenPositionSearchAttribute = 'receipt' | 'invoiceDueDate' | 'invoiceFromDate'
type OpenPositionSearchOperator = 'eq' | 'lt' | 'gt'
export type OpenPositionSearch = {
  searchAttributeId: OpenPositionSearchAttribute
  searchOperatorId: OpenPositionSearchOperator
  searchTerm: string
}

export interface PrintInput {
  contentType: ContentTypes
  userId: string
  token: string
  contentIds?: string[]
  contentId?: string
  histogramLabel: HistogramLabelTypes
  withPrice?: boolean
  extraData?: {
    scannerListData?: ScannerListData
    view?: PrintCartViewTypes
    datasheetConfig?: DatasheetConfig
    openPositionFilter?: OpenPositionFilter
    openPositionSorting?: OpenPositionSorting
    openPositionSearch?: OpenPositionSearch
  }
}
