import React, { FC, useEffect, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'react-i18next'
import {
  CellMeasurerCache,
  List,
  CellMeasurer,
  WindowScroller,
  InfiniteLoader,
  Index,
} from 'react-virtualized'
import clsx from 'clsx'
import { SuppliersOverviewCard } from '../SuppliersOverviewCard/SuppliersOverviewCard'
import { ListRowProps } from 'react-virtualized/dist/es/List'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useSuppliersOverviewProvider } from '../../hooks/useSuppliersOverviewProvider'
import { Skeleton } from '@mui/material'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import {
  MATH_SUPPLIERS_CAREGORY_FILTER_LABEL,
  SuppliersNewsCategory,
} from '../SuppliersOverviewControlSection/SuppliersOverviewControlSection'
import styles from './SuppliersListSection.module.scss'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

const DISTANCE_BETWEEN_ROWS_DESKTOP = 16
const DISTANCE_BETWEEN_ROWS_TABLET = 16

const getElementsPerRow = (
  desktop: boolean,
  mobile: boolean,
  tablet: boolean,
  tabletWide: boolean
) => {
  if (desktop || tabletWide) return 4
  if (tablet) return 3
  if (mobile) return 2
  return 2
}

const HEADER_HEIGHT = 172

export const SuppliersListSection: FC = () => {
  const { suppliers, pagination, total, updatePagination, isLoaded, filters } =
    useSuppliersOverviewProvider()
  const isNativeUITarget = isUiTarget('app')
  const { t } = useTranslation()
  const [stopIndex, setStopIndex] = useState(0)

  const [cache] = useState<CellMeasurerCache>(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 120,
      minHeight: 100,
    })
  )

  const { desktop, mobile, tablet, tabletWide } = useBreakpoints()

  const elementsPerRow = getElementsPerRow(desktop, mobile, tablet, tabletWide)

  const isRowLoaded = ({ index }: Index) => index * elementsPerRow < suppliers.length

  // The user can scroll rapidly to the bottom and continue doing so until reaching the end of the list.
  // Therefore, we need to ensure that we update all items promptly.
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isLoaded && suppliers.length === total) clearInterval(intervalId)

      if (stopIndex * elementsPerRow > suppliers.length && suppliers.length < total) {
        updatePagination()
      }
    }, 1500)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopIndex, elementsPerRow, suppliers, total, updatePagination, isLoaded])

  // We need to inform the list that it needs to perform recalculations every time suppliers changes.
  useEffect(() => {
    cache.clearAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers])

  const loadMoreItems = async (info) => {
    suppliers.length <= total && updatePagination()
    setStopIndex(info.stopIndex)
  }

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const newsPerRow = suppliers.slice(
      index * elementsPerRow,
      index * elementsPerRow + elementsPerRow
    )

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {() => (
          <div
            className={clsx(styles.supplierCell, {
              [styles.twoColumnGrid]: elementsPerRow === 2,
              [styles.threeColumnGrid]: elementsPerRow === 3,
              [styles.fourColumnGrid]: elementsPerRow === 4,
            })}
            key={index}
            style={{
              ...style,
              height:
                // TODO: Investigate why we need manually set height for web(nextjs) app ( 2 places )
                typeof style.height === 'number' && !isNativeUITarget
                  ? style.height -
                    (desktop ? DISTANCE_BETWEEN_ROWS_DESKTOP : DISTANCE_BETWEEN_ROWS_TABLET)
                  : style.height,
            }}
          >
            {newsPerRow.map((suppliersEntry, idx) => {
              if (!suppliersEntry) {
                return <div key={idx} className={clsx(styles.supplierlistitem, styles.dummy)}></div>
              }

              return <SuppliersOverviewCard {...suppliersEntry} key={suppliersEntry.id} />
            })}
            {index * elementsPerRow > suppliers.length && suppliers.length < total && (
              <>
                {new Array(elementsPerRow).fill(null).map((_dummyEl, dummyElIndx) => {
                  return (
                    <div
                      key={index + dummyElIndx}
                      className={clsx(styles.supplierlistitem, styles.dummy)}
                    >
                      <Skeleton variant="rectangular" className={styles.skeleton}></Skeleton>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        )}
      </CellMeasurer>
    )
  }

  const onResize = () => cache.clearAll()

  const searchTerm = filters.name

  return (
    <>
      {isLoaded && !suppliers.length && (
        <NoResults
          body={searchTerm ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.BODY') : undefined}
          title={
            searchTerm
              ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.TITLE', {
                  searchTerm: searchTerm,
                  tabName: t(
                    MATH_SUPPLIERS_CAREGORY_FILTER_LABEL[
                      filters.hasTecselect
                        ? SuppliersNewsCategory.Tecselect
                        : SuppliersNewsCategory.All
                    ]
                  ),
                })
              : t('COMMON.FILTER_TABS.NO_RESULTS.TITLE')
          }
        />
      )}
      <AutoSizer disableHeight onResize={onResize}>
        {({ width }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreItems}
            rowCount={Math.ceil(Math.max(0, total) / elementsPerRow)}
            threshold={elementsPerRow === 2 ? 10 : 3}
            minimumBatchSize={pagination.limit}
          >
            {({ onRowsRendered }) => (
              <WindowScroller scrollElement={window}>
                {({ height, isScrolling, scrollTop }) => (
                  <List
                    deferredMeasurementCache={cache}
                    autoHeight
                    isScrolling={isScrolling}
                    onRowsRendered={onRowsRendered}
                    height={height}
                    overscanRowCount={10}
                    rowCount={Math.ceil(Math.max(0, total) / elementsPerRow)}
                    rowHeight={cache.rowHeight}
                    estimatedRowSize={cache.defaultHeight}
                    rowRenderer={rowRenderer}
                    width={width}
                    scrollTop={scrollTop - HEADER_HEIGHT}
                  />
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </>
  )
}
