import { Typography } from '@mui/material'
import styles from './LabelWithIcon.module.scss'

export interface ILabelWithIconProps {
  icon: JSX.Element
  label: string
}

export const LabelWithIcon: React.FC<ILabelWithIconProps> = (props) => {
  return (
    <div className={styles.labelRoot}>
      {props.icon}
      <Typography variant="bodyBold">{props.label}</Typography>
    </div>
  )
}
