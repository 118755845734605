import { calcListHeight } from '@obeta/utils/lib/calcListHeight'
import clsx from 'clsx'
import { useState } from 'react'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { ToggleMore } from '../toggle-more/ToggleMore'
import { Box } from '@mui/material'
import styles from './ShowMoreList.module.scss'

interface IWithShowMore {
  className?: string
  gapHeight: number
  itemHeight: number
  listSize: number
  maxSize: number
  classes?: {
    listWrap?: string
  }
  keepMounted?: boolean // TODO: implement
}

export const ShowMoreList: React.FC<IWithShowMore> = (props) => {
  const { className, gapHeight, itemHeight, listSize, maxSize, children, classes } = props
  const [showMore, setShowMore] = useState(false)

  const maxHeight = calcListHeight({ count: maxSize, itemHeight: itemHeight, gapHeight: gapHeight })
  const height = calcListHeight({
    count: listSize,
    itemHeight: itemHeight,
    gapHeight: gapHeight,
  })

  const canToggle = height > maxHeight

  return (
    <div className={clsx(styles.root, className)}>
      <Box
        className={clsx(styles.listWrap, classes?.listWrap)}
        maxHeight={showMore ? 'initial' : maxHeight}
      >
        {children}
      </Box>
      {canToggle && <LightGrayDivider orientation="horizontal" />}
      <ToggleMore
        canToggle={canToggle}
        showMore={showMore}
        toggleMore={() => {
          setShowMore((b) => !b)
        }}
      />
    </div>
  )
}
