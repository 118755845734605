import { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'

// Styles
import styles from './SelectionBar.module.scss'

// Utils
import { getSelectionBarContainer } from './SelectionBarContainer'
import clsx from 'clsx'

type SelectionBarProps = {
  children: ReactNode
  portalKey: string
  className?: string
}

export const SelectionBar: FC<SelectionBarProps> = (props) => {
  const { children, portalKey, className } = props

  const selectionBarContainerElem = getSelectionBarContainer()

  if (!selectionBarContainerElem) return null

  return createPortal(
    <div className={clsx(styles.selectionBar, className)}>
      <div className={styles.selectionBarContent}>{children}</div>
    </div>,
    selectionBarContainerElem,
    portalKey
  )
}
