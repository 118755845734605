import { useEventCallback } from '@mui/material'
import { useMemo } from 'react'
import { OrderItem, OrderItemWithoutLinkToArticleDetail } from '../product-card'

export function OrderItemWrapper(props: React.ComponentProps<typeof OrderItem>) {
  const {
    onChange,
    onAddClicked,
    changeProductAmount,
    shippingValue: shippingValueProp,
    ...others
  } = props

  const shippedAmount = shippingValueProp.shippedAmount
  const orderedAmount = shippingValueProp.orderedAmount
  const unit = shippingValueProp.unit ?? ''

  const shippingValue = useMemo<typeof shippingValueProp>(() => {
    return {
      shippedAmount: shippedAmount,
      orderedAmount: orderedAmount,
      unit: unit,
    }
  }, [shippedAmount, orderedAmount, unit])

  const handleChange = useEventCallback(onChange)
  const handleAddClicked = useEventCallback(onAddClicked)
  const handleChangeProductAmount = useEventCallback(changeProductAmount)

  // Memoize other callbacks if they are used in the future.
  if (props.isSapPromotionalProduct) {
    return (
      <OrderItemWithoutLinkToArticleDetail
        shippingValue={shippingValue}
        onChange={handleChange}
        onAddClicked={handleAddClicked}
        changeProductAmount={handleChangeProductAmount}
        {...others}
      />
    )
  }
  return (
    <OrderItem
      shippingValue={shippingValue}
      onChange={handleChange}
      onAddClicked={handleAddClicked}
      changeProductAmount={handleChangeProductAmount}
      {...others}
    />
  )
}
