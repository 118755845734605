import React, { SyntheticEvent } from 'react'
import { Autocomplete, SvgIcon, TextField, useTheme } from '@mui/material'
import { OfferForDropdown } from '@obeta/models/lib/models/Offer/OfferV2'
import { ShoppingCartForDropdown } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { ReactComponent as ArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'

interface Props {
  items: OfferForDropdown[] | ShoppingCartForDropdown[]
  updateCartOffer: (offerId: string) => void
  selectedItem?: OfferForDropdown
  clearable: boolean
}

export const Dropdown: React.FC<Props> = (props) => {
  const { items, updateCartOffer, selectedItem } = props
  const theme = useTheme()

  const handleOfferSelectedViaDropdown = (offerId: string | undefined) => {
    updateCartOffer(!offerId ? '0' : offerId)
  }
  return (
    <Autocomplete
      onChange={(event: SyntheticEvent, offer: OfferForDropdown | null) => {
        handleOfferSelectedViaDropdown(offer?.id)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Angebot auswählen'}
          color="secondary"
          sx={{
            height: '3rem',
            '& .MuiFormControl-root': {
              height: '3rem',
            },
            '& .MuiFormLabel-root': {
              top: '-4px',
              color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-shrink': {
              top: '0 !important',
            },
            '& .MuiOutlinedInput-root': {
              position: 'absolute',
              width: 'inherit !important',
              height: '3rem',
              pr: 1,
            },
            '& legend': {
              width: '7rem',
            },
            '& .MuiAutocomplete-popupIndicator:hover': {
              backgroundColor: theme.palette.white.main,
            },
            '& .MuiAutocomplete-clearIndicator:hover': {
              backgroundColor: theme.palette.white.main,
            },
          }}
        />
      )}
      getOptionLabel={(items) => items.name}
      value={selectedItem || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      filterSelectedOptions={true}
      options={items}
      noOptionsText={''}
      disabled={items.length === 0}
      componentsProps={{
        popper: { placement: 'bottom', modifiers: [{ name: 'flip', enabled: false }] },
      }}
      popupIcon={
        !selectedItem || items.length > 1 ? (
          <SvgIcon
            component={ArrowDownIcon}
            sx={{
              color:
                items.length === 0 ? theme.palette.secondary.light : theme.palette.secondary.main,
              '&:hover': { color: theme.palette.grayVariant.dark55K },
            }}
          />
        ) : (
          false
        )
      }
      clearIcon={
        selectedItem ? (
          <SvgIcon
            component={CloseIcon}
            sx={{
              fontSize: 20,
              color: theme.palette.secondary.main,
              '&:hover': { color: theme.palette.grayVariant.dark55K },
            }}
          />
        ) : (
          false
        )
      }
    />
  )
}
