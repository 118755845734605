import { FC, ComponentType, Fragment, useState, MouseEventHandler } from 'react'
import { Typography, Tooltip, Stack } from '@mui/material'
import clsx from 'clsx'
import { useTranslation, TFunction } from 'react-i18next'
import { AppLauncher } from '@capacitor/app-launcher'
import styles from './Address.module.scss'

import { usePageContext } from '../context/context'
import { Collapse } from '@obeta/components/lib/collapse/Collapse'
import { ReactComponent as InfoIcon } from '@obeta/assets//icon/designsystem/info.svg'
import { ReactComponent as TelIcon } from '@obeta/assets//icon/designsystem/t.svg'
import { ReactComponent as MailIcon } from '@obeta/assets//icon/designsystem/m.svg'
import { IAddress, IAddresses, IRentArticle } from '@obeta/models/lib/models/RentArticle/types'
import { SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { trackClick } from '@obeta/utils/lib/tracking'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

export interface IAddressesProps {
  className?: string
  addresses: IAddresses
  article: IRentArticle
}

export interface IAddressProps {
  isMain?: boolean
  withDivider?: boolean
  address: IAddress
  article: IRentArticle
}

export interface ILinkButtonProps {
  link: string
  Icon: ComponentType<{ className?: string }>
  isMain?: boolean
  onClick?: MouseEventHandler
  mobileText: string
}

export interface IAddressInfoTooltipProps {
  address: IAddress
}

export interface IAddressGridProps {
  className?: string
}

const prepareMailTo = (product: IRentArticle, email: string, t: TFunction) => {
  const subject = encodeURIComponent(product.title)
  const body = encodeURIComponent(
    `${t('RENTABLE_ARTICLES.REQUEST_EMAIL')}\n\n${product.description}`
  )

  return `mailto:${email}?subject=${subject}&body=${body}`
}

const LinkButton: FC<ILinkButtonProps> = ({
  Icon,
  link,
  isMain,
  onClick,
  children,
  mobileText,
}) => {
  const { mobile } = usePageContext()
  const fontVariant = isMain ? 'bodyBold' : 'body'

  if (mobile) {
    return (
      <a className={styles['link']} href={link}>
        <SecondaryButton leftIcon={<Icon className={styles['linkIcon']} />} onClick={onClick}>
          {mobileText}
        </SecondaryButton>
      </a>
    )
  }

  return (
    <a
      className={clsx(styles['linkButton'], styles['link'])}
      href={link}
      onClick={() => trackClick('rentable-articles-send-inquiry')}
    >
      <Icon />
      <Typography variant={fontVariant}>{mobile ? mobileText : children}</Typography>
    </a>
  )
}

const AddressInfoTooltip: FC<IAddressInfoTooltipProps> = ({ address }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { desktop } = usePageContext()

  return (
    <Tooltip
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      arrow={false}
      classes={{ tooltip: styles['addressInfoTooltip'], popper: styles['addressInfoPopper'] }}
      placement="top-start"
      title={
        <Stack spacing={0.5}>
          <Stack spacing={0.25}>
            <Typography variant="bodyBold">{t('RENTABLE_ARTICLES.ADDRESS')}</Typography>
            <Typography variant="body">{address.address}</Typography>
          </Stack>
          <Stack spacing={0.25}>
            <Typography variant="bodyBold">{t('RENTABLE_ARTICLES.OPEN_HOURS')}</Typography>
            <div className={styles['workingHours']}>
              {address.workingHours.map(({ days, times }, index) => (
                <Fragment key={index}>
                  <Typography variant="body">{days}</Typography>
                  <Typography variant="body">{times}</Typography>
                </Fragment>
              ))}
            </div>
          </Stack>
        </Stack>
      }
    >
      <InfoIcon
        className={styles['addressInfo']}
        onClick={!desktop ? () => setOpen((prevShow) => !prevShow) : undefined}
      />
    </Tooltip>
  )
}

const Address: FC<IAddressProps> = ({ withDivider, isMain, address, article }) => {
  const { t } = useTranslation()
  const isCapacitorApp = !isPlatform('web')
  const { mobile } = usePageContext()
  const fontVariant = isMain || mobile ? 'bodyBold' : 'body'
  const { tel, name, mail } = address

  return (
    <>
      <Typography className={styles['filialeName']} variant={fontVariant}>
        {name}
      </Typography>
      <LinkButton
        Icon={TelIcon}
        link={`tel:${tel}`}
        isMain={isMain}
        mobileText={t('RENTABLE_ARTICLES.CALL')}
      >
        {tel}
      </LinkButton>
      <LinkButton
        Icon={MailIcon}
        link={prepareMailTo(article, mail, t)}
        isMain={isMain}
        mobileText={t('RENTABLE_ARTICLES.SEND_INQUIRY')}
        onClick={async (e) => {
          if (isCapacitorApp) {
            e.preventDefault()
            const link = prepareMailTo(article, mail, t)

            const { value } = await AppLauncher.canOpenUrl({ url: link })

            if (value) await AppLauncher.openUrl({ url: link })
          }
          trackClick('rentable-articles-send-inquiry')
        }}
      >
        {t('RENTABLE_ARTICLES.SEND_INQUIRY')}
      </LinkButton>
      <AddressInfoTooltip address={address} />
      {withDivider && !mobile && <div className={styles['divider']} />}
    </>
  )
}

const AddressGrid: FC<IAddressGridProps> = ({ className, children }) => {
  return <div className={clsx(styles['addressGrid'], className)}>{children}</div>
}

export const Addresses: FC<IAddressesProps> = ({ className, addresses, article }) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles['root'], className)}>
      {addresses.main && (
        <AddressGrid className={styles['mainAddress']}>
          <Address address={addresses.main} article={article} isMain />
        </AddressGrid>
      )}
      {!!addresses.collapsible?.length && (
        <Collapse
          className={styles['addressesCollapse']}
          classes={{ header: styles.collapseHeader, headerText: styles.collapseHeader }}
          header={t('RENTABLE_ARTICLES.MORE_LOCATIONS', {
            count: addresses.collapsible.length,
            context: addresses.main ? 'more' : '',
          })}
          initialExpanded={false}
          iconLeft={false}
          unmountOnExit
        >
          <AddressGrid>
            {addresses.collapsible.map((address, index, arr) => (
              <Address
                key={index}
                address={address}
                withDivider={index !== arr.length - 1}
                article={article}
              />
            ))}
          </AddressGrid>
        </Collapse>
      )}
    </div>
  )
}
