import { FC, Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

// Models
import { OrderType, OrdersFacetV2 } from '@obeta/schema'

// Stores
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'
import styles from './OrderListFilters.module.scss'
import { CheckboxWithAmountItem } from '../checbox-radio-item/CheckboxRadioItem'
import {
  getOrderListFilterToggleState,
  sortFacetByCustomOrder,
  toggleOrderListFilter,
} from '@obeta/utils/lib/orders-helpers'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import {
  FACETS_ORDER_TYPE_ORDER,
  ORDER_TYPE_TRANSLATIONS,
} from '@obeta/models/lib/models/Orders/OrderListViewModels'
import { useScrollRestorationContext } from '@obeta/data/lib/stores/useScrollRestorationContext'

export const OrderListFiltersOrderType: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { facets, isLoading, resetOrders, setIsLoading } = useOrderListContext()
  const { resetOrderListCache } = useScrollRestorationContext()
  const initiateRefresh = useCallback(
    (orderTypeValue) => {
      // Reset order list
      resetOrders()
      resetOrderListCache()
      setIsLoading(true)
      toggleOrderListFilter(history, 'orderType', orderTypeValue)

      return true
    },
    [history, resetOrderListCache, resetOrders, setIsLoading]
  )

  return (
    <div className={styles.content}>
      {sortFacetByCustomOrder(facets?.orderType as OrdersFacetV2[], FACETS_ORDER_TYPE_ORDER).map(
        (orderType) => {
          const orderTypeValue = orderType.search as OrderType
          const addition = orderType.isAddition ? '+' : ''
          const disabled = orderType.count === 0
          const rightAdornment: JSX.Element = (
            <Typography
              variant="bodyBold"
              className={clsx(styles.checkboxWithAmount, disabled && styles.disabled)}
            >{`(${addition}${orderType.count})`}</Typography>
          )
          return (
            <Fragment key={orderTypeValue}>
              {orderTypeValue === 'Retoure' && <Divider />}
              <CheckboxWithAmountItem
                disabled={disabled}
                rightAdornment={rightAdornment}
                onChange={() => !isLoading && initiateRefresh(orderTypeValue)}
                id={orderTypeValue}
                label={t(getTranslationString<OrderType>(ORDER_TYPE_TRANSLATIONS, orderTypeValue))}
                value={orderTypeValue}
                checked={getOrderListFilterToggleState(
                  history.location.search,
                  'orderType',
                  orderTypeValue
                )}
                datatestid="orderTypes"
              />
            </Fragment>
          )
        }
      )}
    </div>
  )
}
