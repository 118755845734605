import { useEffect, useRef, useState } from 'react'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { gql, useApolloClient } from '@apollo/client'
import { ALL_BRANDS_OPTION_KEY, AllTeasersFilters } from '../hooks/useAdvertaisingProvider'
import { ShopDomains } from '@obeta/utils/lib/domains'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

const teasersTotalsQuery = gql`
  query allTeasersBrands($filters: TeasersBrandsFiltersInput) {
    allTeasersBrands(filters: $filters) {
      teasersTotals {
        All
        MegaDeal
        WeekendDeal
        RedArticle
        ActionWeek
      }
    }
  }
`

export interface TeasersTotals {
  All: number
  MegaDeal: number
  WeekendDeal: number
  RedArticle: number
  ActionWeek: number
}

interface IAssignedBrandFilters {
  selectedBrand?: string
  endDate: { gte: Date }
  startDate: { lte: Date }
  showForObeta?: { eq: boolean }
  showForEldis?: { eq: boolean }
  showForHaeusler?: { eq: boolean }
  showInApp?: { eq: boolean }
}

export const useTeasersTotals = (selectedBrand: AllTeasersFilters['brand']) => {
  const [teasersTotals, setTeasersTotals] = useState<TeasersTotals>({
    All: 0,
    MegaDeal: 0,
    WeekendDeal: 0,
    RedArticle: 0,
    ActionWeek: 0,
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)
  const currentDate = new Date()

  const domain = useAppDomain()
  const isWeb = isPlatform('web')

  const apolloClient = useStrapiGql()
  const client = useApolloClient(apolloClient)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const geTotals = async () => {
      if (!isMounted.current) return

      setLoading(true)

      const getFilters = () => {
        const variables: IAssignedBrandFilters = {
          endDate: { gte: currentDate },
          startDate: { lte: currentDate },
          ...(selectedBrand && selectedBrand !== ALL_BRANDS_OPTION_KEY ? { selectedBrand } : {}),
        }

        if (isWeb) {
          if (domain === ShopDomains.Obeta) {
            variables.showForObeta = { eq: true }
          } else if (domain === ShopDomains.Eldis) {
            variables.showForEldis = { eq: true }
          } else {
            variables.showForHaeusler = { eq: true }
          }
        } else {
          variables.showInApp = { eq: true }
        }

        return variables
      }

      try {
        const response = await client.query({
          query: teasersTotalsQuery,
          context: { fetchOptions: { signal } },
          variables: { filters: getFilters() },
        })

        const totals = response.data.allTeasersBrands.teasersTotals

        setTeasersTotals(totals)

        setLoading(false)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }
        setError(err)
        setLoading(false)
      }
    }

    geTotals()

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand])

  return { teasersTotals, loading, error }
}
