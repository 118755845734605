import styles from './ShoppingCartEmptyBox.module.scss'
import { Button, Card, Grid, Typography } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'

export const ShoppingCartEmptyBox = () => {
  const { tabletAll, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const history = useHistory()

  const { redirectToStartPage } = useRedirect()

  return (
    <Card className={styles.card}>
      <Grid container direction={'column'} alignItems={'center'}>
        <Grid item>
          <Typography variant={'h3'} align={'center'}>
            {t('SHOPPING_CART.ALL_CARTS_EMPTY')}
          </Typography>
          <LightGrayDivider className={styles.divider} />
        </Grid>
        <Grid
          container
          direction={'row'}
          item
          justifyContent={'center'}
          className={styles.buttonGrid}
        >
          <Grid item className={styles.fullWidth}>
            <Button
              variant={'contained'}
              color={'secondary'}
              className={clsx(styles.button)}
              onClick={redirectToStartPage}
            >
              {t('SHOPPING_CART.NAVIGATION.START_PAGE')}
            </Button>
          </Grid>
          {(desktop || tabletAll) && (
            <Grid item>
              <Button
                variant={'outlined'}
                className={styles.button}
                onClick={() => {
                  history.push(`/stores`)
                }}
              >
                {t('SHOPPING_CART.NAVIGATION.STORES')}
              </Button>
            </Grid>
          )}
          {(desktop || tabletAll) && (
            <Grid item>
              <Button
                variant={'outlined'}
                className={styles.button}
                onClick={() => {
                  history.push(`/contact`)
                }}
              >
                {t('SHOPPING_CART.NAVIGATION.CONTACT')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
