import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { FacetElement, SelectedEtimValue } from '@obeta/models/lib/models/Search'
import { DispatchFiltersAction } from '../reducer'
import { calcTotalSize } from '../utils/calcTotalSize'

export const useFeaturePointerData = (p: {
  activeEtimFeatureName: string | null
  selectedEtimValues: Record<string, SelectedEtimValue>
  dispatchEtimsAction: DispatchFiltersAction
}) => {
  const { activeEtimFeatureName, selectedEtimValues, dispatchEtimsAction } = p
  const { normalizedEtimAttributes } = useArticleFilterData()

  const featurePointer = activeEtimFeatureName
    ? normalizedEtimAttributes[activeEtimFeatureName]
    : null

  const activeEtimValue = featurePointer?.name ? selectedEtimValues[featurePointer?.name] : null
  let total = 0
  if (featurePointer) {
    total = calcTotalSize(featurePointer.values)
  }

  const selectFeature = (value: FacetElement) => {
    if (!featurePointer) {
      return //
    }

    const attr = normalizedEtimAttributes[featurePointer.name]
    if (!attr) {
      console.error(`etim attr "${featurePointer.name}" not found!`)
      return
    }

    dispatchEtimsAction({
      type: 'selectEtimValue',
      payload: {
        attribute: attr,
        attributeValue: value,
      },
    })
  }

  return { activeEtimValue, total, featurePointer, selectFeature }
}
