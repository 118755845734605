import { SafeArea } from 'capacitor-plugin-safe-area'

export const detectInsets = () => {
  SafeArea.getSafeAreaInsets().then(({ insets }) => {
    const style = document.documentElement.style
    for (const [key, value] of Object.entries(insets)) {
      style.setProperty(`--obeta-safe-area-${key}`, `${value}px`)
    }
  })
}
