import {
  AddCartContextEnum,
  addCartTemplatesToCartGraphQL,
  addProductToCartGraphQL,
  AddProductToCartGraphQLActionResult,
  AddCartTemplatesToCartGraphQLActionResult,
  CartsActionTypes,
  ProductItem,
} from '@obeta/data/lib/actions/cart-actions'
import { CartTemplateItemInput } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { SplitButton } from '../split-button/SplitButton'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { useDispatch } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { updateUserSettings } from '@obeta/data/lib/actions/customer-actions'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { PrimaryButton } from '../custom-button/CustomButton'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'
import { AnimatedCartButton } from '../add-items-to-cart-button/AnimatedCartButton'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import {
  EButtonState,
  useAnimatedButtonState,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'

interface AddCartTemplateItemsToCartButtonProps {
  carts: ShoppingCartV2[]
  cartTemplateName?: string // Needed only when adding via cartTemplateId in order to display within notification
  disabled?: boolean
  input: CartTemplateItemInput[] | string[]
  mobile: boolean
  size?: 'small' | 'large'
  updateWaitingAddItems?: boolean
}

export const AddCartTemplateItemsToCartButton: React.FC<AddCartTemplateItemsToCartButtonProps> = (
  props
) => {
  const {
    carts,
    // Once multi-select on list page is enabled we may need to reconsider the notification(s) and how we pass this information
    cartTemplateName = '',
    disabled = false,
    input,
    mobile,
    size,
    updateWaitingAddItems = false,
    ...rest
  } = props

  const splitButtonRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const popoverState = usePopoverState(splitButtonRef)
  const { getSessionCartName, isSessionCart } = useSessionCartName()
  const { setAnimationState: setButtonState, animationState } = useAnimatedButtonState()
  const [waitingAddItemsResult, setWaitingAddItemsResult] = useState(false)
  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

  const waitForAddProductToCartGraphQLResult = useActionNotification(
    CartsActionTypes.AddProductToCartGraphQLResult
  )
  const waitForAddCartTemplateToCartGraphQLResult = useActionNotification(
    CartsActionTypes.AddCartTemplatesToCartGraphQLResult
  )

  useEffect(() => {
    if (isAddToCartProcessIndicator) return
    setWaitingAddItemsResult(updateWaitingAddItems)
  }, [isAddToCartProcessIndicator, updateWaitingAddItems])

  const userV2 = useUserV2()
  const defaultCartId = userV2?.settings?.defaultCartId ?? ''
  const defaultCart = carts.find((cart) => cart.id === defaultCartId)

  const addItemsToCart = (cart: ShoppingCartV2) => {
    // Add selected articles to the shopping cart
    const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
    if (typeof input[0] === 'object') {
      const items: ProductItem[] = input.map((cartTemplateItem) => {
        return {
          sapId: cartTemplateItem.productId,
          amount: cartTemplateItem.amount,
        }
      })
      if (isAddToCartProcessIndicator) {
        setButtonState(EButtonState.Loading)
      } else {
        setWaitingAddItemsResult(true)
      }
      dispatch(
        addProductToCartGraphQL({
          cartId: cart.id,
          cartTitle: cartName,
          context: AddCartContextEnum.CartTemplate,
          items: items,
        })
      )

      if (!isAddToCartProcessIndicator) {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.AddSelectedCartTemplateItemsToCart,
          id: `${NotificationType.AddSelectedCartTemplateItemsToCart}-${cart.id}`,
          options: {
            itemCount: input.length,
            cartName: cartName,
            cartTemplateName: cartTemplateName,
          },
        })
      }
      // Add all articles within a cart template to the shopping cart via cartTemplateIds
    } else {
      if (isAddToCartProcessIndicator) {
        setButtonState(EButtonState.Loading)
      } else {
        setWaitingAddItemsResult(true)
      }
      dispatch(
        addCartTemplatesToCartGraphQL({
          cartId: cart.id,
          cartTemplateIds: input as string[],
          cartName: cartName,
          cartTemplateName: cartTemplateName,
        })
      )
    }

    const handleSuccessfulAdding = (
      result: AddProductToCartGraphQLActionResult | AddCartTemplatesToCartGraphQLActionResult
    ) => {
      if (isAddToCartProcessIndicator) {
        setButtonState(result.payload?.success ? EButtonState.Success : EButtonState.Error)
      } else {
        setWaitingAddItemsResult(false)
      }
      // Set the last used cart as the 'active' cart
      if (userV2?.settings?.defaultCartId !== cart.id) {
        dispatch(
          updateUserSettings({
            defaultCartId: cart.id,
          })
        )
      }
    }

    waitForAddProductToCartGraphQLResult((result: AddProductToCartGraphQLActionResult) => {
      handleSuccessfulAdding(result)
    })

    waitForAddCartTemplateToCartGraphQLResult(
      (result: AddCartTemplatesToCartGraphQLActionResult) => {
        handleSuccessfulAdding(result)
      }
    )
  }

  const handleAddToDefaultCart = () => {
    if (!defaultCart) {
      console.warn('Add item to cart: no default cart to add cart template items to!')
      return
    }
    addItemsToCart(defaultCart)
  }

  const handleAddToSpecificCart = (cart) => {
    addItemsToCart(cart)
  }
  let cartName = '-'
  if (defaultCart) {
    cartName = isSessionCart(defaultCart) ? getSessionCartName(defaultCart) : defaultCart.name
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {mobile &&
        (isAddToCartProcessIndicator ? (
          <AnimatedCartButton
            disabled={disabled}
            onClick={() => handleAddToDefaultCart()}
            buttonState={animationState}
            {...rest}
          />
        ) : (
          <PrimaryButton
            disabled={disabled}
            leftIcon={<AddShoppingCartIcon />}
            onClick={() => {
              handleAddToDefaultCart()
            }}
          />
        ))}
      {!mobile && (
        <>
          {isAddToCartProcessIndicator ? (
            <AnimatedCartButton
              ref={splitButtonRef}
              cartsLength={carts.length}
              buttonState={animationState}
              title={cartName}
              onArrowDownClicked={popoverState.handleClick}
              onClick={() => handleAddToDefaultCart()}
              size={size}
              disabled={disabled}
              {...rest}
            />
          ) : carts.length > 1 ? (
            <SplitButton
              disabled={disabled}
              loading={waitingAddItemsResult}
              ref={splitButtonRef}
              size={size}
              title={cartName}
              onArrowDownClicked={popoverState.handleClick}
              onClick={() => handleAddToDefaultCart()}
              {...rest}
            />
          ) : (
            <PrimaryButton
              disabled={disabled}
              leftIcon={<AddShoppingCartIcon />}
              size={size}
              onClick={() => handleAddToDefaultCart()}
            >
              {cartName}
            </PrimaryButton>
          )}
          <DropdownCartsBase
            carts={carts.map((cart) => {
              return { ...cart, count: cart.items.length }
            })}
            mobile={mobile}
            onOptionSelected={(cart) => {
              handleAddToSpecificCart(cart)
            }}
            dropdown={{
              anchorEl: mobile ? null : popoverState.anchorEl,
              onClose: popoverState.onClose,
              open: popoverState.open,
            }}
          />
        </>
      )}
    </div>
  )
}
