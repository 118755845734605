import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MenuBurgerIcon } from '@obeta/assets/icon/menu-burger.svg'
import { TypographyEllipsis } from '@obeta/components/lib/typography/TypographyEllipsis'
import styles from './DropdownVariant.module.scss'
import { IArticle } from './types'
import { useLocalSearch } from '@obeta/data/lib/hooks/useLocalSearch'
import { useSearchText } from '@obeta/data/lib/hooks/useSearchText'
import { ProductVariant } from '@obeta/models/lib/models/Article/Shop/Product'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useMemo } from 'react'

import {
  EProductIdType,
  isIdStartsWith,
  parseProductId,
} from '@obeta/data/lib/hooks/useCurrentProductIds'

import {
  BaseEntityDropdown,
  EntityDropdownSearchField,
} from '@obeta/components/lib/entity-dropdown/EntityDropdown'
import { DropdownButton } from '@obeta/components/lib/dropdown-button'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ShopRoutes } from '@obeta/utils/lib/variables'

interface IDropdownVariantProps {
  variants: NonNullable<IArticle['variants']>
  productId: string
}

type Option = { id: string; selected: boolean } & ProductVariant

const isProductSelected = (productId: string, variant: ProductVariant) => {
  if (isIdStartsWith(EProductIdType.Obeta, productId)) {
    const { productId: obetaId } = parseProductId(productId)
    return obetaId === variant.product.obetaId
  }

  if (isIdStartsWith(EProductIdType.Deha, productId)) {
    const { productId: dehaId } = parseProductId(productId)
    return dehaId === variant.product.dehaId
  }

  return productId === variant.sapId
}

const keys = ['sapId', 'title', 'product.dehaId']
export const DropdownVariant: React.FC<IDropdownVariantProps> = (props) => {
  const { variants, productId } = props
  const { mobile } = useBreakpoints()
  const { t } = useTranslation()
  const { searchText, setSearchText } = useSearchText()
  const { result } = useLocalSearch<ProductVariant>(variants, searchText, keys)
  const history = useHistory()
  const changeSearchParams = useChangeSearchParams()

  const { options, selected } = useMemo(() => {
    return result.reduce<{ options: Array<Option>; selected: Option | null }>(
      (acc, v) => {
        const selected = isProductSelected(productId, v)
        const opt = { ...v, id: v.sapId, selected }
        acc.options.push(opt)
        if (selected) {
          acc.selected = opt
        }

        return acc
      },
      { options: [], selected: null }
    )
  }, [result, productId])

  const { handleClick, ...dropdown } = usePopoverState()

  return (
    <DropdownButton
      buttonId="variant-button"
      disabled={false}
      title={selected ? selected.title : ''}
      dropdownId="variant-menu"
      justifyContent="spaceBetween"
      borderColor="lightGray"
      onClick={handleClick}
      expanded={dropdown.open}
    >
      <BaseEntityDropdown
        buttonId="variant-button"
        topAdornment={<EntityDropdownSearchField mobile={mobile} onTextUpdated={setSearchText} />}
        bottomAdornment={
          <div
            onClick={() => {
              changeSearchParams({
                searchParams: {
                  productIds: variants.map((variant) => {
                    return variant.sapId
                  }),
                },
                route: 'push',
              })
            }}
            className={styles['btn-wrapper']}
          >
            <MenuBurgerIcon />
            <Typography variant="bodyBold">
              {t<string>('ARTICLE_DETAIL.DISPLAY_VARIANTS_AS_ITEM_LIST')}
            </Typography>
          </div>
        }
        onOptionSelected={(option) => {
          history.push(`${ShopRoutes.ArticleDetailPage}/${option.id}`)
        }}
        formatOption={(opt) => {
          return (
            <>
              <TypographyEllipsis
                variant={opt.selected ? 'bodyBold' : 'body'}
                className={styles.menuItemTitle}
              >
                {opt.title}
              </TypographyEllipsis>
              <Typography className={styles.menuItemId} component="span" variant="smallText">
                {opt.product.dehaId}
              </Typography>
            </>
          )
        }}
        options={options}
        mobile={mobile}
        classes={{
          scrollbar: styles.scrollbar,
          menuItem: styles.menuItem,
          menuList: styles.menuList,
          paper: styles.paper,
        }}
        {...dropdown}
      ></BaseEntityDropdown>
    </DropdownButton>
  )
}
