import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'

// Stores
import { useOfferListContext } from '../stores/useOfferListContext'

// Types
import {
  GetOffersItemsQuery,
  GetOffersItemsQueryVariables,
  GetTotalOfferCountQuery,
  GetTotalOfferCountQueryVariables,
  OffersInput,
  OffersItemsInput,
  SearchOffersQuery,
  SearchOffersQueryVariables,
} from '@obeta/schema'
import { OffersInputWithAction } from '@obeta/models/lib/models'
import { OfferForListPage } from '@obeta/models/lib/schema-models/offer-list'

// Utils
import { EventType, NotificationType, getEventSubscription } from '@obeta/utils/lib/pubSub'
import { trackCustom } from '@obeta/utils/lib/tracking'

// Constants
const OFFERS_ITEMS_QUERY = gql`
  query getOffersItems($input: OffersItemsInput!) {
    getOffersItems(input: $input) {
      success
      errorCode
      errorMessage
      offers {
        items {
          offerItemPosition
          product {
            images {
              url
              width
            }
            imageData {
              images {
                large
              }
            }
            title
            type
            oxomiId
            sapId
            supplierId
            supplierImageData {
              sapId
              large
            }
          }
          supplierImageData {
            sapId
            large
          }
        }
        offerId
      }
    }
  }
`

const SEARCH_QUERY = gql`
  query searchOffers($input: OffersInput!) {
    searchOffers(input: $input) {
      success
      errorCode
      errorMessage
      totalFavoriteOfferCount
      totalOfferCount
      offers {
        offerId
        offerName
        estate
        commission
        customerReference
        isActive
        isFavorite
        itemCount
        lastUpdated
        netPrice
        offerFlags
        startDate
        endDate
        isDeleted
        itemCount
      }
    }
  }
`

export const TOTAL_OFFER_COUNT_QUERY = gql`
  query getTotalOfferCount($input: OffersInput!) {
    searchOffers(input: $input) {
      success
      errorCode
      errorMessage
      totalOfferCount
    }
  }
`

export const useOfferSearch = () => {
  const client = useApolloClient()
  const {
    offers,
    offersItems,
    setHasOffers,
    setOffers,
    setOffersItems,
    setTotalFavoriteOfferCount,
    setTotalOfferCount,
  } = useOfferListContext()

  /**
   * Get offer items by offer ids.
   * @param input OffersItemsInput
   */
  const getOffersItems = useCallback(
    async (request: { input: OffersItemsInput; offers: OfferForListPage[] }) => {
      const response = await client.query<GetOffersItemsQuery, GetOffersItemsQueryVariables>({
        query: OFFERS_ITEMS_QUERY,
        variables: {
          input: request.input,
        },
      })

      const data = response.data?.getOffersItems

      if (data) {
        const updatedOffersItems = [...offersItems]

        // Iterate through fetched offers items
        for (const offer of data.offers) {
          const matchedOffer = updatedOffersItems.find(
            (fetchedOfferItems) => fetchedOfferItems.offerId === offer.offerId
          )
          // Add fetched offers items to list on no match
          if (!matchedOffer) {
            updatedOffersItems.push(offer)
          }
        }
        setOffersItems(updatedOffersItems)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, offersItems]
  )

  /**
   * Handler to search offers by input variables.
   * @param input OffersInput
   */
  const getTotalOfferCount = useCallback(
    async (input: OffersInput): Promise<number> => {
      const response = await client.query<
        GetTotalOfferCountQuery,
        GetTotalOfferCountQueryVariables
      >({
        query: TOTAL_OFFER_COUNT_QUERY,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })

      const data = response.data.searchOffers

      if (data.success) {
        return data.totalOfferCount
      }
      return 0
    },
    [client]
  )

  /**
   * Handler to search offers by input variables.
   * @param input OffersInput
   */
  const searchOffers = useCallback(
    async (inputWithAction: OffersInputWithAction) => {
      const input = { ...inputWithAction }
      delete input.action

      const response = await client.query<SearchOffersQuery, SearchOffersQueryVariables>({
        query: SEARCH_QUERY,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })

      const data = response.data.searchOffers

      if (data.success) {
        trackCustom('search-offers', {
          request: inputWithAction,
          response: data.offers,
        })

        setTotalFavoriteOfferCount(data.totalFavoriteOfferCount as number)
        setTotalOfferCount(data.totalOfferCount as number)

        // Check if user has offers to display when there is no filter or search term active, otherwise display EmptyBox component
        if (inputWithAction.filter.length === 0 && inputWithAction.searchTerm === '') {
          data.offers.length > 0 ? setHasOffers(true) : setHasOffers(false)
        }

        if (data.offers) {
          if (inputWithAction.offset === '0') {
            // Replace offers on filter change
            setOffers([...data.offers])
          } else {
            // Add offers on scroll
            setOffers([...offers, ...data.offers])
          }

          // Get offers items by fetched mapped offer ids array
          const offerIds = data.offers.map((offer) => offer.offerId)
          getOffersItems({
            input: {
              limit: '10', // Maximum items to fetch
              offerIds: offerIds.toString(),
            },
            offers: data.offers,
          })
        }
      } else {
        trackCustom('search-offers-error', data)

        // Redirect to root page on missing permission to read offers
        if (data.errorCode === '403') {
          getEventSubscription().next({
            type: EventType.Data,
            notificationType: NotificationType.OfferNoPermission,
            id: `${new Date().toISOString()}-${NotificationType.OfferNoPermission}`,
            options: {
              heading: '',
              body: '',
            },
          })
        }
      }
    },
    // eslint-disable-next-line
    [offers, offersItems]
  )

  return {
    getOffersItems,
    getTotalOfferCount,
    searchOffers,
  }
}
