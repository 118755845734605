import { pluginFactories } from '@react-page/plugins-slate'

export const colorsPlugin = pluginFactories.createComponentPlugin<{
  color: string
}>({
  addHoverButton: false,
  addToolbarButton: false,
  type: 'customColor',
  object: 'mark',
  Component: 'span',
  getStyle: ({ color }) => ({ color }),
})
