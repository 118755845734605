import Axios from 'axios-observable'
import { App as AppPlugin } from '@capacitor/app'
import { firstValueFrom } from 'rxjs'
import { AppActions } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import type { CustomerMetaData } from '@obeta/data/lib/hooks/useUserData'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

/**
 * @deprecated This method is used to communicate with the legacy app.obeta.de backend and takes care of
 * refreshing expired php session. Should only be used if the legacy communication is still required
 *
 * @param db
 * @param maintenanceSubject$
 * @param appActions
 */
export const initAxiosLegacy = (
  db: RxDatabase<CollectionsOfDatabase>,
  maintenanceSubject$,
  appActions: AppActions
) => {
  Axios.defaults.withCredentials = true
  Axios.defaults.baseURL =
    process.env.REACT_APP_API_BASE_URL || process.env.NEXT_PUBLIC_API_BASE_URL
  const setAppHeader = async () => {
    let type = 'web'
    if (!isPlatform('web')) {
      type = isPlatform('ios') ? 'ios' : 'android'
    }

    let appVersion = 'latest'
    if (type !== 'web') {
      const info = await AppPlugin.getInfo()
      appVersion = `${info.version} ${info.build}`
    }

    Axios.interceptors.request.use(async function (config) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers['X-App-Version'] = appVersion
      config.headers['X-App-Type'] = type
      return config
    })
  }
  setAppHeader()

  let lastSessionPrefetch = 0

  Axios.interceptors.request.use(async (request) => {
    const date = new Date().getTime()
    const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
    const isLoggedIn = userMeta?.get('isLoggedIn')

    if (
      isLoggedIn &&
      lastSessionPrefetch < date - 1000 * 60 * 5 &&
      (request.url?.indexOf('recommendation/promotion') !== -1 ||
        request.url?.indexOf('article') !== -1)
    ) {
      await appActions.refreshSession()
      lastSessionPrefetch = new Date().getTime()
    }
    return request
  })

  Axios.interceptors.response.use(
    function (response) {
      maintenanceSubject$?.next('')
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error) => {
      const originalRequest = error.config
      if (error.response && error.response.status === 501) {
        const message =
          error.response.data?.message || 'Die Systeme sind momentan nicht erreichbar.'
        maintenanceSubject$?.next(message)

        return
      } else {
        maintenanceSubject$?.next('')
      }
      if (error.response && error.response.status === 401) {
        const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
        const isLoggedIn = userMeta?.get('isLoggedIn')
        if (!isLoggedIn) {
          return
        }

        // logout may result in 401, since this means, that the session is invalid
        // and there is nothing more to do
        if (originalRequest.url?.indexOf('logout') !== -1) {
          return
        }

        //if we cannot refresh the session, we should not create an endless loop
        // and break here
        const success = await appActions.refreshSession()
        if (!success) {
          return
        }
        return firstValueFrom(Axios.request({ ...originalRequest }))
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      throw error
    }
  )
}
