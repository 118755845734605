import { mapReceiptTypeToDisplayValue } from '../mapOpenPositionReceiptType'
import { dateFormatter } from '../dateFormatter'
import { normalizePrice } from '../data-formatter/normalizePrice'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'
import { TFunction } from 'react-i18next'

export const createOpenPositionCsv = (
  selectedItems: OpenPosition[],
  t: TFunction<'translation', undefined>
): Blob => {
  const content: string[] = []
  const headerParts = [
    t('MY_ACCOUNTING.RECEIPT'),
    'Typ',
    t('MY_ACCOUNTING.FROM_DATE'),
    `${t('MY_ACCOUNTING.DISCOUNTABLE')}/${t('MY_ACCOUNTING.OVERDUE')}`,
    t('MY_ACCOUNTING.DUNNING_LEVEL'),
    t('MY_ACCOUNTING.AMOUNT_INVOICE'),
    t('MY_ACCOUNTING.AMOUNT_OUTSTANDING'),
    t('MY_ACCOUNTING.DUE_DATE'),
    `${t('MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.0')} ${t(
      'MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.1'
    )} ${t('MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.2')}`,
  ]
  const header = headerParts.join(';') + '\n'

  content.push(header)
  let cumulated = 0
  for (const item of selectedItems) {
    cumulated += item.openAmount
    const receiptTypeDisplayValue = mapReceiptTypeToDisplayValue(item.receiptType)
    const isDiscountOrOverdueText = item.isDiscount
      ? t('MY_ACCOUNTING.DISCOUNTABLE')
      : item.isOverdue
      ? t('MY_ACCOUNTING.OVERDUE')
      : ''
    const row =
      [
        item.receipt,
        receiptTypeDisplayValue,
        dateFormatter(item.invoiceFromDate),
        isDiscountOrOverdueText,
        item.dunningLevel,
        normalizePrice(item.invoiceAmount, false),
        normalizePrice(item.openAmount, false),
        dateFormatter(item.invoiceDueDate),
        normalizePrice(cumulated, false),
      ].join(';') + '\n'

    content.push(row)
  }
  return new Blob(content, { type: 'text/csv' })
}
