import { ActionButton } from '../../../message-center-action-button'
import React from 'react'
import styles from './FooterActionButton.module.scss'

export const FooterActionButton: typeof ActionButton = (props) => {
  return (
    <div className={styles.wrapper}>
      <ActionButton noPaddingLeft={true} {...props} />
    </div>
  )
}
