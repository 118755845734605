import React from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { EPlacement } from '@obeta/models/lib/models/Teasers/Teasers'
import {
  ALL_BRANDS_OPTION_KEY,
  useAdvertisingProvider,
  formatBrandFilterLabel,
  TeasersCategory,
} from '../../hooks/useAdvertaisingProvider'
import { TeasersSectionView } from '../../../startPage/TeasersSection'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import { useTranslation } from 'react-i18next'
import { MATH_FILTER_VALUE_TO_LABEL } from '../ControlSection/ControlSection'

export const AllAdvertsSection = () => {
  const { adverts, isLoading, filters, assignedBrands } = useAdvertisingProvider()
  const { mobile } = useBreakpoints()
  const { t } = useTranslation()

  const getSearchTerm = () => {
    if (filters.brand !== ALL_BRANDS_OPTION_KEY) {
      const filterName = assignedBrands.find((brand) => brand.id === filters?.brand)?.filterName

      return formatBrandFilterLabel(filterName)
    }
  }

  const searchTerm = getSearchTerm()

  return (
    <>
      {!isLoading && !adverts.length && (
        <NoResults
          body={searchTerm ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.BODY') : undefined}
          title={
            searchTerm
              ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.TITLE', {
                  searchTerm: searchTerm,
                  tabName: t(MATH_FILTER_VALUE_TO_LABEL[filters.category || TeasersCategory.All]),
                })
              : t('COMMON.FILTER_TABS.NO_RESULTS.TITLE')
          }
        />
      )}
      <TeasersSectionView teasers={adverts} maxEls={mobile ? 3 : 5} placement={EPlacement.All} />
    </>
  )
}
