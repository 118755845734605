import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './Sidebar.module.scss'

import { LocationFilter } from '../filters/LocationFilter'
import { CategoryFilter } from '../filters/CategoryFilter'
import { StaticMessage } from '../filters/StaticMessage'
import { IFiltersCombined, TSetFilter } from '../types'
import { ILocation, ICategory } from '@obeta/models/lib/models/RentArticle/types'

export interface ISidebarProps {
  filters: IFiltersCombined
  setFilter: TSetFilter
  locations: ILocation[] | null
  categories: ICategory[] | null
  className?: string
  isLoading: boolean
}

export const Sidebar: FC<ISidebarProps> = ({
  className,
  isLoading,
  locations,
  categories,
  filters,
  setFilter,
}) => {
  return (
    <div className={clsx(styles['sidebarRoot'], className)}>
      <LocationFilter
        isLoading={isLoading}
        locations={locations}
        filters={filters}
        setFilter={setFilter}
      />
      <CategoryFilter
        isLoading={isLoading}
        categories={categories}
        filters={filters}
        setFilter={setFilter}
      />
      <StaticMessage />
    </div>
  )
}
