import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

// Components
import {
  InfiniteWindowScroller as OfferDetailProductListContent,
  TInfiniteLoaderProps,
} from '../window-scroller'
import { CancelledOfferItem } from '../product-card'
import { OfferItemWrapper } from './OfferItemWrapper'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useEntities, useSelectShoppingCart } from '@obeta/data/lib/hooks'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

// Models
import {
  MultiSelectionNumber,
  OfferForDropdown,
  ShoppingCartForDropdown,
  ShoppingCartV2,
} from '@obeta/models/lib/models'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { OfferV3 } from '@obeta/schema'
import { OfferItemForDetailsPage } from '@obeta/models/lib/schema-models/offer-details'

// Stores
import { useOfferDetailsContext } from '@obeta/data/lib/stores/useOfferDetailsContext'
import { useScrollRestorationContext } from '@obeta/data/lib/stores/useScrollRestorationContext'

// UI
import { ConfirmWithOfferSelect } from '../alert-and-confirm/ConfirmWithOfferSelect'

// Utils
import { addOrReplaceOfferInCartGraphQL } from '@obeta/data/lib/actions'
import { trackClick } from '@obeta/utils/lib/tracking'
import { getModifiedOfferItemAmount } from './utils/offers.utils'

// Constants
const INITIAL_PRODUCTS_COUNT = 20 // Initial offer items (products) to display on mount

type OfferDetailProductListProps = {
  id: string
  dropDownCarts: ShoppingCartForDropdown[]
  modifiedOfferItemAmounts: { offerItemPosition: number; amount: number }[]
  offer: OfferV3
  selectedOfferItems: MultiSelectionNumber
  totalOfferItemsCount?: number
  onLoadMoreOfferItems: (queryParams: QueryParams) => void
  onOfferItemAmountChanged: (offerItemPosition: number, amount: number) => void
  onSelectOfferItem: (offerItemPosition: number) => void
  scrollTo: () => void
}

export const OfferDetailProductList: FC<OfferDetailProductListProps> = (props) => {
  const {
    id,
    dropDownCarts,
    modifiedOfferItemAmounts,
    selectedOfferItems,
    offer,
    totalOfferItemsCount,
    onLoadMoreOfferItems,
    onOfferItemAmountChanged,
    onSelectOfferItem,
    scrollTo,
  } = props

  const { mobile, tabletAll } = useBreakpoints()
  const dispatch = useDispatch()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const onSetActiveCart = useSelectShoppingCart()
  const { offerDetailsCache } = useScrollRestorationContext()
  const { isLoggedIn, user } = useUserDataV2()

  // Offer details store
  const { offerItems, queryParams, totalItemCount, setQueryParams } = useOfferDetailsContext()

  // Component state
  const [cartIdToShowConflictFor, setCartIdToShowConflictFor] = useState<string>('')
  const [offerItemPositionToShowConflictFor, setOfferItemPositionToShowConflictFor] =
    useState<number>(0)
  const [offersForDropdown, setOffersForDropdown] = useState<OfferForDropdown[]>([])
  const [rvData, setRvData] = useState<{
    elementsPerRow: number
    rowCount: number
    rowTotalCount: number
  }>({
    elementsPerRow: 0,
    rowCount: 0,
    rowTotalCount: 0,
  })
  const [showOfferConflictDialog, setShowOfferConflictDialog] = useState(false)

  // Set elements per row, row count and total row count by offer items (products) length
  useEffect(() => {
    if (offerItems && offerItems.length > 0) {
      setRvData({
        elementsPerRow: 1,
        rowCount: offerItems.length,
        rowTotalCount: totalOfferItemsCount ?? 0,
      })
    }
  }, [offerItems, totalOfferItemsCount])

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => {
      return Boolean(offerItems[index])
    },
    [offerItems]
  )

  const onAddToCart = useCallback(
    (cart: ShoppingCartV2, offerItem: OfferItemForDetailsPage) => {
      trackClick('add-to-cart', {
        offerId: id,
        cartId: cart.id,
        offerItemPosition: offerItem.offerItemPosition,
      })
      if (cart?.offerId !== '' && cart?.offerId !== offer.offerId) {
        const name =
          dropDownCarts.find((dropDownCart) => dropDownCart.id === cart.id)?.offerName || ''
        setOffersForDropdown([
          { id: cart?.offerId ?? '', name: name ?? '' },
          { id: offer.offerId, name: offer.offerName },
        ])
        setCartIdToShowConflictFor(cart.id)
        setOfferItemPositionToShowConflictFor(offerItem.offerItemPosition)
        setShowOfferConflictDialog(true)
      } else {
        dispatch(
          addOrReplaceOfferInCartGraphQL(
            offer.offerId,
            offer.offerName,
            1,
            cart.id,
            true,
            [offerItem.offerItemPosition],
            [],
            modifiedOfferItemAmounts.filter(
              (item) => item.offerItemPosition === offerItem.offerItemPosition
            ),
            {
              filter: '',
              orderBy: '',
              orderDir: '',
              searchTerm: '',
            }
          )
        )
        onSetActiveCart(cart.id)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropDownCarts, id, modifiedOfferItemAmounts, offer]
  )

  /**
   * Load more rows (offer items) when InfiniteLoader threshold is reached.
   */
  const onLoadMoreRows = useCallback(
    async () => {
      if (offerItems.length < totalItemCount) {
        const updatedQueryParams = { ...queryParams, offset: offerItems.length.toString() }
        setQueryParams(updatedQueryParams)
        onLoadMoreOfferItems(updatedQueryParams)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerItems, queryParams, totalItemCount]
  )
  const handleOfferConflictDialogCancelled = () => {
    setShowOfferConflictDialog(false)
  }
  const handleOfferConflictDialogConfirmed = (chosenOffer) => {
    dispatch(
      addOrReplaceOfferInCartGraphQL(
        offer.offerId,
        offer.offerName,
        1,
        cartIdToShowConflictFor,
        chosenOffer.id === offer.offerId,
        [offerItemPositionToShowConflictFor],
        [],
        modifiedOfferItemAmounts.filter(
          (item) => item.offerItemPosition === offerItemPositionToShowConflictFor
        ),
        {
          filter: '',
          orderBy: '',
          orderDir: '',
          searchTerm: '',
        }
      )
    )
    onSetActiveCart(cartIdToShowConflictFor)
    setShowOfferConflictDialog(false)
  }

  // Renders on
  const { rowRenderer } = useMeasuredRowRenderer({
    cache: offerDetailsCache.current,
    render: ({ index }) => {
      const offerItem = offerItems[index]

      if (!offerItem) {
        return null
      }

      return offerItem.isCancelled ? (
        <div style={{ paddingBottom: '0.5rem', width: '100%' }}>
          <CancelledOfferItem
            isLoggedIn={true}
            mobile={mobile}
            offerItem={offerItem}
            selected={false}
            tablet={tabletAll}
            user={user}
            withCheckbox={false}
          />
        </div>
      ) : (
        <div style={{ paddingBottom: '0.5rem' }}>
          <OfferItemWrapper
            carts={carts}
            isLoggedIn={isLoggedIn}
            mobile={mobile}
            modifiedAmount={getModifiedOfferItemAmount(offerItem, modifiedOfferItemAmounts)}
            offerItem={offerItem}
            productId={offerItem?.product?.sapId ?? ''}
            selected={
              (selectedOfferItems.selectAll &&
                !selectedOfferItems.exclude.includes(offerItem.offerItemPosition)) ||
              selectedOfferItems.include.includes(offerItem.offerItemPosition)
            }
            selectedCart={carts.find((cart) => cart.id === user?.settings?.defaultCartId || '')}
            selectedStore={undefined}
            tablet={tabletAll}
            user={user}
            withCheckbox
            changeProductAmount={(offerItemPosition, amount) => {
              onOfferItemAmountChanged(offerItemPosition, amount)
            }}
            onChange={() => {
              onSelectOfferItem(offerItem.offerItemPosition)
            }}
            onAddClicked={(offerItem) => {
              trackClick('on-add-to-cart-template-init', {
                offerId: id,
                offerItemPosition: offerItem.offerItemPosition,
              })
            }}
            onAddToCart={(cart) => onAddToCart(cart, offerItem)}
            url={
              offerItem.product.images && offerItem.product.images.length > 0
                ? offerItem.product.images[0].url
                : ''
            }
          />
        </div>
      )
    },
  })

  return (
    <Grid columnSpacing={0.5} container direction="column">
      <OfferDetailProductListContent
        infiniteLoader={{
          isRowLoaded,
          loadMoreRows: onLoadMoreRows,
          minimumBatchSize: INITIAL_PRODUCTS_COUNT, // Amount of offer items (products) to load at a time
          threshold: mobile || tabletAll ? 5 : 10, // Load more items when within the last 5/10 rows
        }}
        list={{
          rowRenderer,
          rowHeight: offerDetailsCache.current.rowHeight,
          deferredMeasurementCache: offerDetailsCache.current,
        }}
        preventScrollRestoration
        rowCount={rvData.rowCount} // Row count for loaded offers
        rowTotalCount={rvData.rowTotalCount} // Total row count for offers (including not loaded yet offers)
        onRowsRendered={scrollTo}
      />
      <ConfirmWithOfferSelect
        handleConfirm={handleOfferConflictDialogConfirmed}
        handleCancel={handleOfferConflictDialogCancelled}
        offersForSelect={offersForDropdown}
        openDialog={showOfferConflictDialog}
      />
    </Grid>
  )
}
