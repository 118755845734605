import styles from './ShoppingCartItemV2.module.scss'
import meatballs from 'assets/img/shoppingcartoverview/meatballs.svg'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Link,
  SvgIcon,
  Typography,
} from '@mui/material'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { ProductOxomiImage } from '../product-images/ProductOxomiImage'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'
import clsx from 'clsx'
import { SecondaryLightButton } from '../custom-button/CustomButton'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'

interface IBaseProps {
  showPrices: boolean
}

interface Props extends IBaseProps {
  cart: ShoppingCartV2
  onCartSelected?: (cartId: string) => void
  onEmptyCartClick: (cart: ShoppingCartV2) => void
}

const BaseShoppingCard = React.forwardRef<HTMLDivElement, PropsWithChildren<IBaseProps>>(
  (props, ref) => {
    const { children } = props

    return (
      <Card elevation={1} className={styles.shoppingcartitem} data-testid="base-shopping-card">
        <CardContent className={styles.shoppingcartitemcontent}>
          <Grid
            className={styles.shoppingCartItemContainer}
            container
            direction={'column'}
            ref={ref}
          >
            {children}
          </Grid>
        </CardContent>
      </Card>
    )
  }
)

export const ShoppingCardSkeleton: React.FC<IBaseProps> = (props) => {
  return (
    <BaseShoppingCard {...props}>
      <Grid className={styles.headerGrid} item>
        <TextSkeleton />
      </Grid>
      <Grid className={styles.dividerGrid} item>
        <Divider />
      </Grid>
      <Grid item className={styles.approvalGrid}>
        <TextSkeleton />
      </Grid>
      <Grid item className={styles.imageGrid}>
        <ImageList
          className={clsx(styles.imageList, styles.fitWidth)}
          cols={3}
          rowHeight={24}
          gap={8}
        >
          {new Array(3).fill(null).map((_, idx) => {
            return <TextSkeleton key={idx} width={24} height={24} />
          })}
        </ImageList>
      </Grid>
    </BaseShoppingCard>
  )
}

export const ShoppingCartItemV2: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { cart, onCartSelected, showPrices, onEmptyCartClick } = props
  const cartItemContainer = useRef<HTMLDivElement>(null)
  const [imageListSize, setImageListSize] = useState(0)
  const { getSessionCartName, isSessionCart } = useSessionCartName()

  const handleCartItemClick = (id: string) => {
    goToCartDetailPage(id)
  }

  const goToCartDetailPage = (id: string) => {
    onCartSelected && onCartSelected(id)
  }

  const handleEmptyCartClick = () => {
    onEmptyCartClick(cart)
  }

  const useImgProxy = useFeatureToggle('UseImgProxy')

  useEffect(() => {
    const width = cartItemContainer?.current?.offsetWidth || 0
    if (width > 0) {
      setImageListSize(Math.floor(width / 32) - 1)
    }
  }, [cartItemContainer?.current?.offsetWidth])

  const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
  return (
    <BaseShoppingCard showPrices={showPrices} ref={cartItemContainer}>
      <Link
        href={`/shopping-cart-details/${cart.id}`}
        color={'inherit'}
        underline={'none'}
        onClick={(event) => {
          event.preventDefault()
          handleCartItemClick(cart.id)
        }}
      >
        <Grid className={styles.headerGrid} item>
          <Box sx={{ display: 'flex', height: '1.5rem' }}>
            <Box>
              <SvgIcon component={CartIcon} fontSize={'large'} sx={{ mr: 0.5 }} />
            </Box>
            <Box className={styles.shoppingcartellipsis}>
              <Typography variant={'boldText'}>{cartName}</Typography>
            </Box>
            <Box>
              <Typography variant={'boldText'} sx={{ pl: 0.5 }}>
                ({cart.items.length})
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={styles.dividerGrid} item>
          <Divider />
        </Grid>
        {showPrices && (
          <Grid item className={styles.priceGrid}>
            <Typography variant={'body'}>{t('SHOPPING_CART.OVERALL_VALUE')}: </Typography>
            <Typography variant={'bodyBold'} component="span" fontWeight="fontWeightBold">
              {normalizePrice(cart.shoppingCartPrices?.netSum)}
            </Typography>
          </Grid>
        )}
        <Grid item className={styles.approvalGrid}>
          <Typography variant={'smallText'} sx={{ whiteSpace: 'nowrap' }}>
            {/*{t('SHOPPING_CART.DETAILS.APPROVED_BY')}*/}&nbsp;
          </Typography>
          <Typography variant={'smallText'} className={styles.shoppingcartellipsis}>
            {/*{cart.name}{' '}*/}
          </Typography>
        </Grid>
        <Grid item className={styles.imageGrid}>
          <ImageList cols={imageListSize + 1} rowHeight={24} gap={8} className={styles.imageList}>
            {cart.items.slice(0, imageListSize).map((shoppingCartItem) => {
              const validUrl = ensureValidImgProxyUrl(shoppingCartItem.product?.images?.[0]?.url)
              return (
                <ImageListItem
                  key={cart.id + '-' + shoppingCartItem.id}
                  sx={{ flexDirection: 'row' }}
                >
                  {useImgProxy && validUrl ? (
                    <ImgProxyImage
                      className={styles.previewImage}
                      url={validUrl}
                      title={shoppingCartItem.product.title}
                      width={shoppingCartItem?.product?.images?.[0]?.width}
                      mobileWidthRem={6}
                      tabletWideWidthRem={6}
                      tabletWidthRem={6}
                      desktopWidthRem={6}
                    />
                  ) : (
                    <ProductOxomiImage
                      alt={cart.id + '-' + shoppingCartItem.id}
                      src={shoppingCartItem.product.imageData.images[0]?.large}
                      className={styles.previewImage}
                      oxomiId={shoppingCartItem.product.oxomiId}
                      supplierId={shoppingCartItem.product.supplierId}
                    />
                  )}
                </ImageListItem>
              )
            })}
            {cart.items.length > imageListSize ? (
              <ImageListItem>
                <img alt={'meatballs'} src={meatballs} className={styles.previewImage} />
              </ImageListItem>
            ) : (
              ''
            )}
          </ImageList>
        </Grid>
        <Grid item className={styles.emptyCartGrid}>
          <SecondaryLightButton id="empty-button" onClick={() => handleEmptyCartClick()}>
            {t('SHOPPING_CART.EMPTY_CART')}
          </SecondaryLightButton>
        </Grid>
      </Link>
    </BaseShoppingCard>
  )
}
