import React from 'react'
import { RadioGroup, Skeleton } from '@mui/material'
import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { ArticleSearchArea, ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { useAvailability } from '../hooks/useAvailability'
import { useTranslation } from 'react-i18next'
import cardContentStyles from '../CardContent.module.scss'
import { DispatchFiltersAction } from '../reducer'
import { E2EProps } from '@obeta/components/lib/types'
import { Size } from '../../../searchPage/filters/Size'
import styles from './Sidebar.module.scss'

interface IAvailabilityProps {
  searchParams: ArticleSearchParamsWithId
  dispatchFiltersAction: DispatchFiltersAction
}

export const Availability = React.memo<IAvailabilityProps & E2EProps>(function Availability(props) {
  const { searchParams, dispatchFiltersAction, ...restProps } = props
  const { t } = useTranslation()
  const { availability, loading } = useAvailability({ searchParams })

  const onFilterAction = (e) => {
    const val = e.target.value as ArticleSearchArea | null
    dispatchFiltersAction({ type: 'selectSearchArea', payload: { searchArea: val } })
  }

  return (
    <RadioGroup
      className={cardContentStyles.cardContent}
      name="availability"
      onChange={onFilterAction}
    >
      <RadioItem
        key={ArticleSearchArea.ALL}
        id="all"
        value={ArticleSearchArea.ALL}
        checked={searchParams.articleSearchArea === ArticleSearchArea.ALL}
        label={t<string>('SEARCH.ARTICLELIST.FILTER.SEARCH_AREA.ALL')}
        rightAdornment={
          loading ? (
            <Skeleton
              className={styles.availabilitySkeleton}
              animation="wave"
              variant="rectangular"
              height={20}
              width={60}
            />
          ) : (
            <Size
              className={styles.size}
              size={availability[ArticleSearchArea.ALL]}
              datatestid="suppliers"
            />
          )
        }
        {...restProps}
      />
      <RadioItem
        key="immediately"
        id="immediately"
        value={ArticleSearchArea.IMMEDIATELY_AVAILABLE}
        checked={searchParams.articleSearchArea === ArticleSearchArea.IMMEDIATELY_AVAILABLE}
        label={t<string>('SEARCH.ARTICLELIST.FILTER.SEARCH_AREA.IMMEDIATELY_AVAILABLE')}
        rightAdornment={
          loading ? (
            <Skeleton
              className={styles.availabilitySkeleton}
              animation="wave"
              variant="rectangular"
              height={20}
              width={60}
            />
          ) : (
            <Size
              className={styles.size}
              size={availability[ArticleSearchArea.IMMEDIATELY_AVAILABLE]}
              datatestid="suppliers"
            />
          )
        }
        {...restProps}
      />
    </RadioGroup>
  )
})
