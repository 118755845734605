import clsx from 'clsx'
import React from 'react'
import styles from './Image.module.scss'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'

export const Image = React.forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement> & {
    width?: number
  }
>(function Image(props, ref) {
  const useImgProxy = useFeatureToggle('UseImgProxy')
  const { className, width, ...restProps } = props
  const validUrl = ensureValidImgProxyUrl(restProps.src)
  if (useImgProxy && validUrl) {
    return (
      <ImgProxyImage
        ref={ref}
        className={clsx(styles.img, className)}
        width={width}
        url={validUrl}
        {...restProps}
      />
    )
  } else {
    return <img ref={ref} className={clsx(styles.img, className)} alt={props.alt} {...restProps} />
  }
})
