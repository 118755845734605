import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import {
  GetIdsFormFieldsForCartTemplateItemSearchQuery,
  GetIdsFormFieldsForCartTemplateItemSearchQueryVariables,
  GetIdsFormFieldsForOfferItemSearchQuery,
  GetIdsFormFieldsForOfferItemSearchQueryVariables,
  GetIdsFormFieldsForOrderItemSearchQuery,
  GetIdsFormFieldsForOrderItemSearchQueryVariables,
  IdsFormFields,
  IdsFormFieldsForCartTemplateItemSearchRequest,
  IdsFormFieldsForOfferItemSearchRequest,
  IdsFormFieldsForOrderItemSearchRequest,
} from '@obeta/schema'
import { submitIdsPostForm } from '@obeta/utils/lib/idsHelper'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { useTranslation } from 'react-i18next'

type IdsBulkQueryEntity = 'order' | 'offer' | 'cartTemplate'

const IDS_BULK_QUERY_ORDER_ITEMS = gql`
  query getIdsFormFieldsForOrderItemSearch($input: IdsFormFieldsForOrderItemSearchRequest!) {
    getIdsFormFieldsForOrderItemSearch(input: $input) {
      success
      errorCode
      errorMessage
      idsFormFields {
        version
        action
        warenkorb
      }
    }
  }
`
const IDS_BULK_QUERY_CART_TEMPLATE_ITEMS = gql`
  query getIdsFormFieldsForCartTemplateItemSearch(
    $input: IdsFormFieldsForCartTemplateItemSearchRequest!
  ) {
    getIdsFormFieldsForCartTemplateItemSearch(input: $input) {
      success
      errorCode
      errorMessage
      idsFormFields {
        version
        action
        warenkorb
      }
    }
  }
`
const IDS_BULK_QUERY_OFFER_ITEMS = gql`
  query getIdsFormFieldsForOfferItemSearch($input: IdsFormFieldsForOfferItemSearchRequest!) {
    getIdsFormFieldsForOfferItemSearch(input: $input) {
      success
      errorCode
      errorMessage
      idsFormFields {
        version
        action
        warenkorb
      }
    }
  }
`

type TransferIdsBulkProducts = (
  request:
    | IdsFormFieldsForCartTemplateItemSearchRequest
    | IdsFormFieldsForOrderItemSearchRequest
    | IdsFormFieldsForOfferItemSearchRequest
) => void
type UseIdsBulkTransferBack = (entity: IdsBulkQueryEntity) => TransferIdsBulkProducts

export const useIdsBulkTransferBack: UseIdsBulkTransferBack = (entity) => {
  const apolloClient = useApolloClient()
  const { t } = useTranslation()

  const transferIdsBulkProducts = useCallback(
    async (
      request:
        | IdsFormFieldsForCartTemplateItemSearchRequest
        | IdsFormFieldsForOrderItemSearchRequest
        | IdsFormFieldsForOfferItemSearchRequest
    ) => {
      if (!entity) return
      const sessionContext = getSessionContext()

      let idsFormFields: IdsFormFields | null = null
      let errorMessage: string | null = null

      switch (entity) {
        case 'cartTemplate': {
          const response = await apolloClient.query<
            GetIdsFormFieldsForCartTemplateItemSearchQuery,
            GetIdsFormFieldsForCartTemplateItemSearchQueryVariables
          >({
            query: IDS_BULK_QUERY_CART_TEMPLATE_ITEMS,
            variables: {
              input: request as IdsFormFieldsForCartTemplateItemSearchRequest,
            },
          })
          if (
            response &&
            response.data.getIdsFormFieldsForCartTemplateItemSearch.success &&
            response.data.getIdsFormFieldsForCartTemplateItemSearch.idsFormFields
          ) {
            idsFormFields = response.data.getIdsFormFieldsForCartTemplateItemSearch.idsFormFields
          } else if (
            response &&
            response.data.getIdsFormFieldsForCartTemplateItemSearch.errorMessage
          ) {
            errorMessage = response.data.getIdsFormFieldsForCartTemplateItemSearch.errorMessage
          }
          break
        }
        case 'order': {
          const response = await apolloClient.query<
            GetIdsFormFieldsForOrderItemSearchQuery,
            GetIdsFormFieldsForOrderItemSearchQueryVariables
          >({
            query: IDS_BULK_QUERY_ORDER_ITEMS,
            variables: {
              input: request as IdsFormFieldsForOrderItemSearchRequest,
            },
          })
          if (
            response &&
            response.data.getIdsFormFieldsForOrderItemSearch.success &&
            response.data.getIdsFormFieldsForOrderItemSearch.idsFormFields
          ) {
            idsFormFields = response.data.getIdsFormFieldsForOrderItemSearch.idsFormFields
          } else if (response && response.data.getIdsFormFieldsForOrderItemSearch.errorMessage) {
            errorMessage = response.data.getIdsFormFieldsForOrderItemSearch.errorMessage
          }

          break
        }
        case 'offer': {
          const response = await apolloClient.query<
            GetIdsFormFieldsForOfferItemSearchQuery,
            GetIdsFormFieldsForOfferItemSearchQueryVariables
          >({
            query: IDS_BULK_QUERY_OFFER_ITEMS,
            variables: {
              input: request as IdsFormFieldsForOfferItemSearchRequest,
            },
          })

          if (
            response &&
            response.data.getIdsFormFieldsForOfferItemSearch.success &&
            response.data.getIdsFormFieldsForOfferItemSearch.idsFormFields
          ) {
            idsFormFields = response.data.getIdsFormFieldsForOfferItemSearch.idsFormFields
          } else if (response && response.data.getIdsFormFieldsForOfferItemSearch.errorMessage) {
            errorMessage = response.data.getIdsFormFieldsForOfferItemSearch.errorMessage
          }
          break
        }
      }
      if (idsFormFields && sessionContext) {
        submitIdsPostForm(
          idsFormFields,
          sessionContext.hookUrl as string,
          sessionContext.target as string
        )
      } else if (errorMessage) {
        const message = errorMessage.length > 0 ? errorMessage : t('IDS.ERROR_FALLBACK_MESSAGE')
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: 'ids-transfer-back-product-error',
          options: {
            message,
          },
        })
      }
    },
    [apolloClient, entity, t]
  )
  return transferIdsBulkProducts
}
