import React from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { PromotionDateRange } from './promotionDateRange'
import { PromotionMoreMenu } from './promotionMoreMenu'
import { PromotionProgressbar } from './promotionProgressbar'
import { PromotionProgressStatus } from './promotionProgressStatus'

import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import styles from './promotionCheckout.module.scss'
import { useTranslation } from 'react-i18next'
import { DetachPromotionButton } from './detachPromotionButton'
import { usePromotionData } from '@obeta/data/lib/hooks/usePromotionData'

dayjs.extend(isSameOrBefore)

interface IPromotionCheckoutProps {
  simpleView?: boolean
  promotionId?: string
}

export const PromotionCheckoutView: React.FC<IPromotionCheckoutProps> = (props) => {
  const { promotionId, simpleView } = props
  const promotion = usePromotionData()
  const {
    name,
    endTime,
    startTime,
    teaser,
    benefits,
    criteriaType,
    criteriaUnit,
    progress: promotionProgress,
  } = promotion
  const { t } = useTranslation()

  const endMoment = dayjs(endTime)
  const isFinished = !dayjs().isSameOrBefore(endMoment)
  const isSingleBenefit = benefits.length === 1
  const firstBenefitGoal = benefits[0]?.goalToReach
  const sumDifference = firstBenefitGoal - promotionProgress

  return (
    <div
      className={clsx(styles['promotion-checkout-wrapper'], {
        [styles['promotion-checkout-wrapper-shadow']]: !simpleView,
        [styles['promotion-checkout-no-padding']]: simpleView,
      })}
    >
      <div className={styles['promotion-checkout-promotion-data']}>
        {!simpleView && (
          <>
            <div className={styles['promotion-checkout-header']}>
              <div className={styles['promotion-checkout-header-text-block']}>
                <div
                  className={clsx(styles['promotion-checkout-title'], {
                    [styles['promotion-checkout-title-expired']]: isFinished,
                  })}
                >
                  <Typography variant="bodyBold">{name}</Typography>
                </div>
                <PromotionDateRange startTime={startTime} endTime={endTime} />
              </div>
              <div className={styles['promotion-checkout-header-button']}>
                {isFinished ? (
                  <DetachPromotionButton />
                ) : (
                  <PromotionMoreMenu promotionId={promotionId} />
                )}
              </div>
            </div>
            <div className={styles['promotion-checkout-teaser']}>
              <Typography variant="body">
                {!isFinished ? teaser : t<string>('PROMOTIONS.PROMOTION_EXPIRED')}
              </Typography>
            </div>
          </>
        )}
        {!isFinished && (
          <PromotionProgressbar
            cartSum={promotionProgress}
            benefits={benefits}
            criteriaType={criteriaType}
            hideTopStatus={simpleView}
            criteriaUnit={criteriaUnit}
          />
        )}
        {!isFinished && isSingleBenefit && (
          <PromotionProgressStatus
            isAchieved={sumDifference <= 0}
            name={benefits[0].name}
            sum={sumDifference}
            criteriaType={criteriaType}
            criteriaUnit={criteriaUnit}
          />
        )}
      </div>
      {!isFinished &&
        isSingleBenefit &&
        benefits.map((benefit) => (
          <div className={styles['promotion-checkout-benefit-image']}>
            <img
              alt={benefit.name}
              width={benefit.image.data?.attributes.width}
              height={benefit.image.data?.attributes.height}
              className={styles['promotion-progressbar-reward-image']}
              src={benefit.image.data?.attributes.url}
            />
          </div>
        ))}
    </div>
  )
}
