import {
  SectionHeader as BaseSectionHeader,
  ISectionHeaderProps,
} from '@obeta/components/lib/section/SectionHeader'
import React from 'react'
import { useSection } from './SectionProvider'

export const SectionHeader: React.FC<Omit<ISectionHeaderProps, 'variant'>> = (props) => {
  const { typographyHeaderVariant } = useSection()
  return <BaseSectionHeader {...props} variant={typographyHeaderVariant} />
}
