import React from 'react'
import { parseUnit } from './parseUnit'
import { normalizePriceWithBindedLocale } from './normalizePrice'
import { normalizeAmount } from './normalizeAmount'

type Data = { count?: string | number; unit?: string }
type DataType = 'price' | 'amount'

export const formatDataRaw = (data: Data, dataType: DataType) => {
  if (!data.count && !data.unit) {
    return {}
  }

  const { unit, slice } = parseUnit(data.unit)

  if (dataType === 'price') {
    return { formattedData: normalizePriceWithBindedLocale(data.count, unit), slice }
  }

  return { formattedData: normalizeAmount(data.count, unit), slice }
}

export const formatDataStr = (data: Data, dataType: DataType = 'price') => {
  const f = formatDataRaw(data, dataType)
  if (!f.formattedData) {
    return ''
  }

  if (!f.slice) {
    return f.formattedData
  }

  return `${f.formattedData}${f.slice}`
}

export const formatData = (data: Data, dataType: DataType = 'price') => {
  const f = formatDataRaw(data, dataType)

  if (!f.formattedData) {
    return <span className="price"></span>
  }

  return (
    <span className="price">
      {f.formattedData}
      {f.slice ? <span className="priceslice">&nbsp;{f.slice}</span> : null}
    </span>
  )
}
