import { TextSkeleton } from '../text-skeleton/TextSkeleton'

interface ITextAvailableProps extends React.ComponentProps<typeof TextSkeleton> {
  available: boolean
}

export const TextAvailable: React.FC<ITextAvailableProps> = (props) => {
  const { available, children, ...restSkeletonProps } = props

  if (!available) {
    return <TextSkeleton {...restSkeletonProps} />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
