import { createContext, useContext } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

type TPageContext = ReturnType<typeof useBreakpoints>

const defaultValue: TPageContext = {
  mobile: false,
  tabletWide: false,
  tablet: false,
  tabletAll: false,
  desktop: false,
}

const PageContext = createContext<TPageContext>(defaultValue)
export const PageContextProvider = PageContext.Provider

export const usePageContext = () => useContext(PageContext)
