import { FC } from 'react'
import { useLogin } from '@obeta/data/lib/hooks/useLogin'
import './PleaseLoginScreen.scss'

interface Props {
  title: string
  body: string
}

export const PleaseLoginScreen: FC<Props> = ({ title, body }) => {
  const { startLogin } = useLogin()
  return (
    <div className="loginscreen__content">
      <div className="guestmode__content">
        <div>{title}</div>
        <p>
          {body}{' '}
          <span id="guest-mode-login-btn" onClick={startLogin}>
            zum Login
          </span>
        </p>
      </div>
    </div>
  )
}
