import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import React, { FC, KeyboardEvent, useState, useCallback } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import styles from './OpenPositionSearch.module.scss'
import { Select } from '@obeta/components/lib/select/Select'
import {
  OpenPositionSearchAttributeOption,
  OPEN_POSITION_SEARCH_ATTRIBUTES,
  OPEN_POSITION_SEARCH_OPERATORS,
  useMyAccountingContext,
} from '@obeta/data/lib/stores/useMyAccountingContext'
import { useTranslation } from 'react-i18next'
import { OpenPositionSearchAttribute } from '@obeta/data/lib/hooks/my-accounting/useSortedAndFilteredOpenPositions'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  onSearch: (searchTerm: string) => void
}
const SEARCH_ATTRIBUTE_TO_PLACEHOLDER_TRANSLATION: Record<
  OpenPositionSearchAttribute,
  { aboveMobile: string; mobile: string }
> = {
  receipt: {
    aboveMobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_RECEIPT',
    mobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_RECEIPT_MOBILE',
  },
  invoiceDueDate: {
    aboveMobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_INVOICE_DUE_DATE',
    mobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_INVOICE_DUE_DATE_MOBILE',
  },
  invoiceFromDate: {
    aboveMobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_INVOICE_FROM_DATE',
    mobile: 'MY_ACCOUNTING.SEARCH_PLACEHOLDER_INVOICE_FROM_DATE_MOBILE',
  },
}

export const OpenPositionSearch: FC<Props> = ({ onSearch }) => {
  const { mobile } = useBreakpoints()
  const [inputValue, setInputValue] = useState<string>('')
  const { setSearchTerm, searchAttribute, searchOperator, setSearchAttribute, setSearchOperator } =
    useMyAccountingContext()
  const { t } = useTranslation()
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onSearch(inputValue)
      setSearchTerm(inputValue)
    }
  }
  const onReset = useCallback(() => {
    setInputValue('')
    setSearchTerm('')
    onSearch('')
  }, [onSearch, setSearchTerm, setInputValue])

  const placeholder = mobile
    ? t(SEARCH_ATTRIBUTE_TO_PLACEHOLDER_TRANSLATION[searchAttribute.id].mobile)
    : t(SEARCH_ATTRIBUTE_TO_PLACEHOLDER_TRANSLATION[searchAttribute.id].aboveMobile)

  const translateAttributeOption = (option: OpenPositionSearchAttributeOption) => ({
    ...option,
    title: t(option.title),
  })

  return (
    <div className={styles.openPositionSearch}>
      <SearchField
        value={inputValue}
        onChange={setInputValue}
        onKeyUp={handleKeyPress}
        onReset={onReset}
        placeholder={placeholder}
        inputClassName={styles.inputContainer}
        startAdornment={
          <div className={styles.selectContainer}>
            <Select
              borderless
              selectedOption={translateAttributeOption(searchAttribute)}
              options={OPEN_POSITION_SEARCH_ATTRIBUTES.map(translateAttributeOption)}
              onOptionSelected={(option) => {
                if (option.id !== searchAttribute.id) {
                  setSearchAttribute(option)
                  onReset()
                }
              }}
            />
            <Select
              borderless
              selectedOption={searchOperator}
              options={OPEN_POSITION_SEARCH_OPERATORS}
              onOptionSelected={(option) => {
                if (option.id !== searchOperator.id) {
                  setSearchOperator(option)
                }
              }}
            />
          </div>
        }
      />
      <div
        className={styles.searchIconButton}
        onClick={() => {
          setSearchTerm(inputValue)
          onSearch(inputValue)
        }}
      >
        <SearchIcon />
      </div>
    </div>
  )
}
