import { useEffect, useRef } from 'react'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Keyboard, KeyboardResize } from '@capacitor/keyboard'
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { PluginListenerHandle } from '@capacitor/core'

const redirectURISchemeUrl = process.env.REACT_APP_REDIRECT_URL_APP as string

export const AppUrlOpenListener = ({ children }) => {
  const history = useHistory()
  const isInBuildBrowserOpen = useRef(false)

  useEffect(() => {
    let browserPageLoadedListener: PluginListenerHandle | undefined
    let browserFinishedListener: PluginListenerHandle | undefined

    const run = async () => {
      browserPageLoadedListener = await Browser.addListener('browserPageLoaded', () => {
        isInBuildBrowserOpen.current = true
      })

      browserFinishedListener = await Browser.addListener('browserFinished', () => {
        isInBuildBrowserOpen.current = false
      })
    }

    run()

    return () => {
      browserPageLoadedListener?.remove()
      browserFinishedListener?.remove()
    }
  }, [])

  // TODO: Move this to another place to avoid confusion, this is not part of app link listener
  useEffect(() => {
    if (!isPlatform('web') && isPlatform('ios')) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None })
    }
  }, [])

  useEffect(() => {
    if (isPlatform('web')) return

    const handleURILink = async (data: URLOpenListenerEvent) => {
      // de.obeta.shop.app://path:id  => /path:id
      const slug = data.url.replace(/^[^:]+:\/+/, '/')

      if (
        slug?.startsWith(ShopRoutes.CreateOrderPage) ||
        slug?.startsWith(ShopRoutes.ShoppingCartCheckout)
      ) {
        if (isInBuildBrowserOpen.current) {
          await Browser.close()
        }
      }

      history.push(slug)
    }

    const handleUniversalLink = (data: URLOpenListenerEvent) => {
      const url = new URL(data.url)
      if (url && url.pathname) history.push(url.pathname + url.search)
    }

    CapApp.addListener('appUrlOpen', (data) => {
      redirectURISchemeUrl && data.url.startsWith(redirectURISchemeUrl)
        ? handleURILink(data)
        : handleUniversalLink(data)
    })
  }, [history])

  return children
}
