import { useMemo } from 'react'
import { useStackNavigatorContext } from '../context'

export const useStackHistory = () => {
  const context = useStackNavigatorContext()

  return useMemo(() => {
    const go = context.go

    return {
      name: context.state.name,
      direction: context.state.direction,
      push: context.push,
      go: go,
      goBack: () => go(-1),
    }
  }, [context.state.name, context.state.direction, context.push, context.go])
}
