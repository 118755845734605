import { FC } from 'react'
import { RadioGroup as MUIRadioGroup } from '@mui/material'

// Styles
import styles from './RadioGroup.module.scss'

// Models
import { RadioGroupOption as IRadioGroupOption } from '@obeta/models/lib/models/UI'

// UI
import { RadioItem as RadioGroupOption } from '../checbox-radio-item/CheckboxRadioItem'

type RadioGroupProps = {
  items: IRadioGroupOption[]
  value: string | number
  onChange: (value: string) => void
}

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  const { items, value, onChange } = props
  return (
    <MUIRadioGroup
      className={styles.radioGroup}
      onChange={(event) => onChange(event?.target.value)}
    >
      {items.map((item) => (
        <RadioGroupOption
          key={item.value}
          id={item.value?.toString() ?? 'undefined'}
          disabled={item.disabled}
          checked={value === item.value}
          label={item.label}
          value={item.value}
        />
      ))}
    </MUIRadioGroup>
  )
}
