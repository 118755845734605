import {
  INITIAL_OFFER_DETAILS_FILTERS,
  INITIAL_OFFER_DETAILS_QUERY_PARAMS,
  OfferDetailsURLSearchParams,
} from '@obeta/models/lib/models'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { OfferItemsInput } from '@obeta/schema'
import { parseSearchOfferItemsFilters } from './parseSearchOfferItemsFilters'

export const createOfferItemsInput = (
  offerId: string,
  queryParams: QueryParams,
  urlSearchParams: OfferDetailsURLSearchParams
): OfferItemsInput => {
  const isActive: boolean = urlSearchParams.isActive === 'true'

  return {
    limit: queryParams.limit ?? INITIAL_OFFER_DETAILS_QUERY_PARAMS.limit,
    offerId,
    offset: queryParams.offset ?? INITIAL_OFFER_DETAILS_QUERY_PARAMS.offset,
    searchTerm: urlSearchParams.searchTerm ?? INITIAL_OFFER_DETAILS_FILTERS.searchTerm,
    filterV2: parseSearchOfferItemsFilters(isActive, urlSearchParams.supplierId ?? null),
    orderBy: INITIAL_OFFER_DETAILS_FILTERS.orderBy,
    orderDir: urlSearchParams.orderDir ?? INITIAL_OFFER_DETAILS_FILTERS.orderDir,
  }
}
