import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { useMemo } from 'react'
import { IProductIds } from './useCurrentProductIds'
import { useWarehouse } from './useWarehouse'

export const useStoresStocks = (
  productIds: IProductIds,
  stores: StoreV2[],
  isLoggedIn: boolean
) => {
  const stocksIds = useMemo(() => {
    return stores.map((store) => store.id)
  }, [stores])

  const { stocks } = useWarehouse(productIds, stocksIds, isLoggedIn)
  return stocks
}
