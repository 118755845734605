import { gql, useQuery } from '@apollo/client'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { GetFundingsQuery, GetFundingsQueryVariables } from '@obeta/schema'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { useWarehouseContext } from '../stores/useWarehouseContext'

const FUNDINGS_QUERY = gql`
  query GetFundings($searchString: String, $facets: [String!], $filter: String) {
    searchProducts(
      searchString: $searchString
      requestOptions: { facets: $facets, filters: $filter }
    ) {
      facets {
        name
        values {
          value
          count
        }
      }
    }
  }
`
export const useFundings = (p: { searchParams?: ArticleSearchParams }) => {
  const { warehouseId } = useWarehouseContext()
  const s: ArticleSearchParams = {
    ...p.searchParams,
    fundings: undefined,
  }

  const filters = buildFilterParams(s, warehouseId)
  const { data, loading } = useQuery<GetFundingsQuery, GetFundingsQueryVariables>(FUNDINGS_QUERY, {
    variables: {
      facets: ['eligibleFor'],
      filter: filters || '',
      searchString: p.searchParams?.searchString || '',
    },
  })

  return {
    fundings:
      data?.searchProducts?.facets.find((facet) => facet.name === 'eligibleFor')?.values || [],
    loading,
  }
}
