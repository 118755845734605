import { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { useStoreFinder } from '@obeta/data/lib/hooks'
import { StoreV2, StoreV2Types } from '@obeta/models/lib/models/Stores/StoreV2'
import { LocationCoordinates } from '@obeta/models/lib/models/GoogleMap'

export interface ILocationPageContextData {
  stores: StoreV2[]
  onChangeDistance: (event: Event, value: number | number[]) => void
  distanceValue: number
  overlayLocation: StoreV2 | null
  changeOverlayLocation: (store: StoreV2 | null) => void
  setChosenLocation: (store: LocationCoordinates | null) => void
  chosenLocation?: LocationCoordinates | null
}

export const LocationPageContext = createContext<ILocationPageContextData | null>(null)

export const LocationPageContextProvider = ({ children }) => {
  const [distanceValue, setDistanceValue] = useState(0)
  const [overlayLocation, setOverlayLocation] = useState<StoreV2 | null>(null)
  const { stores } = useStoreFinder({})
  const [chosenLocation, setChosenLocation] = useState<LocationCoordinates | null | undefined>(
    undefined
  )

  const allStores = useMemo(() => {
    return stores.filter((store) => store.type === StoreV2Types.normal)
  }, [stores])

  const onChangeDistance = (_, value) => setDistanceValue(value)

  const changeOverlayLocation = useCallback((store: StoreV2) => {
    setOverlayLocation(store)
  }, [])

  const updateChoosenLocation = (chosenLocation: LocationCoordinates) => {
    setChosenLocation(chosenLocation)
  }

  return (
    <LocationPageContext.Provider
      value={{
        stores: allStores,
        onChangeDistance,
        distanceValue,
        overlayLocation,
        changeOverlayLocation,
        setChosenLocation: updateChoosenLocation,
        chosenLocation,
      }}
    >
      {children}
    </LocationPageContext.Provider>
  )
}

export const useLocationPageProvider = () => {
  const searchData = useContext(LocationPageContext)

  if (!searchData) {
    throw new Error(
      'LocationPage is not defined. Make sure you call useNewsProvider inside one of NewsContextProvider child'
    )
  }

  return searchData
}
