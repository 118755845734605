import { useEffect, useRef } from 'react'
import { getCookiebotPreferencesFlag } from './utils/getCookiebotFlags'

export const useCookiebotPreferencesConsentHandling = (onAccept: () => void) => {
  const cookiebotConsentPreferences = useRef<boolean>(getCookiebotPreferencesFlag() ?? false)

  useEffect(() => {
    const listener = function () {
      if (!getCookiebotPreferencesFlag()) {
        // user is declining or has declined
        if (cookiebotConsentPreferences.current) {
          // Note that there is currently no way to de-initialise DDRUM, therefore we have to resort to this workaround
          cookiebotConsentPreferences.current = false
          window?.location.reload()
        }
      } else {
        // user is accepting or has accepted
        cookiebotConsentPreferences.current = true
        onAccept()
      }
    }

    window?.addEventListener('CookiebotOnAccept', listener, false)
    window?.addEventListener('CookiebotOnDecline', listener, false)
    return () => {
      window.removeEventListener('CookiebotOnAccept', listener)
      window.removeEventListener('CookiebotOnDecline', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
