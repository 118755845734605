import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import {
  ARTICLE_TYPE_FIELDS,
  ENHANCED_TYPE_FIELDS,
  SEARCH_TYPE_FIELDS,
} from '../queries/teasersFragments'

const teaserQuery = gql`
  ${ARTICLE_TYPE_FIELDS}
  ${ENHANCED_TYPE_FIELDS}
  ${SEARCH_TYPE_FIELDS}
  query obetaTeaserDetails($id: ID) {
    obetaTeaser(id: $id) {
      data {
        id
        attributes {
          teaserText
          newsType {
            ...Article
            ...Enhanced
            ...Search
          }
          teaser {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

export const useTeasersDetails = (teaserId: string): ITeaser | null => {
  const [detailedTeasers, setDetailedTeasers] = useState<ITeaser | null>(null)
  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getNews = async () => {
      if (!isMounted.current || !teaserId) return

      const response = await client.query({
        query: teaserQuery,
        variables: { id: teaserId },
      })

      const result = response.data.obetaTeaser.data?.attributes
      const news = !result
        ? null
        : {
            ...result,
            id: teaserId,
            imageUrl: result.teaser.data?.attributes.url,
            imageAlt: result.teaser.data?.attributes.alternativeText,
            newsType: result.newsType[0],
          }
      delete news.__typename
      delete news.teaser

      setDetailedTeasers(news)
    }

    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaserId])

  return detailedTeasers
}
