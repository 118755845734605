import { Box } from '@mui/material'
import { CategoryIcon, getCategoryIconBGColour } from '@obeta/components/lib/categories/Categories'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { TypographyEllipsis } from '@obeta/components/lib/typography'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { normalizeInt } from '@obeta/utils/lib/data-formatter/normalizeAmount'
import clsx from 'clsx'
import styles from './CategoryHeader.module.scss'

interface ICategoryHeaderProps extends WithBreakpoints<'mobile'> {
  size?: number
  catId: string | null
  title: string
  rightAdornment: JSX.Element | null
  bottomAdornment: JSX.Element | null
  header?: JSX.Element
}

export const CategoryHeader: React.FC<ICategoryHeaderProps> = (props) => {
  const { title, size: _size, catId, mobile, rightAdornment, bottomAdornment, header } = props

  const size = _size ? `(${normalizeInt(_size)})` : ''

  return (
    <div className={clsx(styles.root, { [styles.mobileRoot]: mobile })}>
      <div className={styles.body}>
        {header || (
          <div className={styles.titleRoot}>
            {catId && <CategoryIcon size="lg" className={styles.icon} catId={catId} />}
            <TypographyEllipsis variant={catId ? 'headline4Bold' : 'headline3Bold'}>
              {`${title} ${size}`}
            </TypographyEllipsis>
          </div>
        )}
        {!mobile && rightAdornment}
      </div>
      {catId && <Box className={styles.footer} bgcolor={getCategoryIconBGColour(catId)} />}
      {mobile && rightAdornment}
      {!catId && <LightGrayDivider orientation="horizontal" />}
      {bottomAdornment}
    </div>
  )
}
