import React, { useImperativeHandle, useMemo, useReducer } from 'react'
import { StackNavigatorContext, StackNavigatorContextValue, StackNavigatorState } from './context'
import { stackNavigatorReducer, stackNavigatorReducerInitializer } from './navigator-reducer'

interface INavigatorProps {
  initialScreenName: string
  children?: React.ReactNode
}

export type NavigatorApi = Pick<StackNavigatorContextValue, 'push' | 'go'>

export const StackNavigator = React.forwardRef<NavigatorApi | undefined, INavigatorProps>(
  (props, ref) => {
    const { initialScreenName, children } = props

    const [state, dispatch] = useReducer(
      stackNavigatorReducer,
      { initialScreenName },
      stackNavigatorReducerInitializer
    )

    const publicState = useMemo(() => {
      const publicState: StackNavigatorState = {
        stack: state.stack,
        index: state.index,
        name: state.stack[state.index],
        length: state.stack.length,
        direction: state.index >= state.stack.length - 1 ? 'push' : 'pop',
      }

      return publicState
    }, [state.index, state.stack])

    const methods = useMemo(() => {
      return {
        push: (name: string) => dispatch({ type: 'push', payload: { name } }),
        go: (delta: number) => dispatch({ type: 'go', payload: { delta } }),
      }
    }, [dispatch])

    useImperativeHandle(ref, () => methods, [methods])

    const context = useMemo(() => {
      const context: StackNavigatorContextValue = {
        state: publicState,
        push: methods.push,
        go: methods.go,
      }

      return context
    }, [publicState, methods])

    return (
      <StackNavigatorContext.Provider value={context}>{children}</StackNavigatorContext.Provider>
    )
  }
)
