import { Box, Button, Slider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ListPrice.module.scss'
import { DispatchFiltersAction } from '../reducer'
import { formatListPriceForFilterAsNumber } from '@obeta/utils/lib/data-formatter/normalizePrice'

interface IListPriceProps {
  minListPrice: number
  maxListPrice: number
  dispatchFiltersAction: DispatchFiltersAction
}

export const ListPrice = React.memo<IListPriceProps>(function ListPrice(props) {
  const { minListPrice, maxListPrice, dispatchFiltersAction } = props

  const { t } = useTranslation()

  function valuetext(value: number) {
    return `${value} €`
  }
  const [chosenValueRange, setChosenValueRange] = useState<(number | string)[]>([
    minListPrice,
    maxListPrice,
  ])

  useEffect(() => {
    setChosenValueRange([minListPrice, maxListPrice])
  }, [minListPrice, maxListPrice])

  const handleChangeViaSlider = (event: Event, newValue: number | number[]) => {
    setChosenValueRange(newValue as number[])
  }

  const handleChangeViaMinValueField = (event) => {
    setChosenValueRange([
      event.target.value === '' ? '' : parseInt(event.target.value),
      chosenValueRange[1],
    ])
  }

  const handleChangeViaMaxValueField = (event) => {
    setChosenValueRange([
      chosenValueRange[0],
      event.target.value === '' ? '' : parseInt(event.target.value),
    ])
  }
  const handleApplyButtonClick = () => {
    dispatchFiltersAction({
      type: 'applyListPriceRange',
      payload: {
        minListPrice: chosenValueRange[0] as number,
        maxListPrice: chosenValueRange[1] as number,
      },
    })
  }

  const stagingWarning =
    window.location.href.indexOf('staging') > 0
      ? '!Staging: range inaccurate due to differing sapIds!'
      : ''

  return (
    <div>
      <Typography color={'red'} align={'center'}>
        {stagingWarning}
      </Typography>
      <Slider
        className={styles.slider}
        getAriaLabel={() => t('SEARCH.ARTICLEDETAILS.SUPPLIER_ARTICLE_PRICE')}
        value={chosenValueRange as number[]}
        onChange={handleChangeViaSlider}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabelFormat={(value) => {
          return formatListPriceForFilterAsNumber(value)
        }}
        min={minListPrice}
        max={maxListPrice}
      />
      <Box display={'flex'} className={styles.inputWrapper}>
        <TextField
          className={styles.textField}
          value={formatListPriceForFilterAsNumber(chosenValueRange[0])}
          onChange={handleChangeViaMinValueField}
        />
        <Typography className={styles.minusSymbol} variant={'bodyBold'}>
          -
        </Typography>
        <TextField
          className={styles.textField}
          value={formatListPriceForFilterAsNumber(chosenValueRange[1])}
          onChange={handleChangeViaMaxValueField}
        />
      </Box>
      <Button className={styles.applyButton} variant={'outlined'} onClick={handleApplyButtonClick}>
        {t('MAIN.APPLY')}
      </Button>
    </div>
  )
})
