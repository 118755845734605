import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

// Models
import { FilterSection } from '@obeta/models/lib/models/UI'

// Styles
import styles from './Filters.module.scss'

// UI
import { ActionBar } from '../action-bar/ActionBar'
import { CollapseCard } from '../collapse-card/CollapseCard'
import { Modal } from '../modal/Modal'

type FiltersProps = {
  modal?: boolean
  sections: FilterSection[]
}

export const Filters: FC<FiltersProps> = (props) => {
  const { modal, sections } = props

  return (
    <div className={clsx(styles.filters, modal ? styles.filtersModal : styles.filtersSidebar)}>
      {sections.map((section) => (
        <CollapseCard key={section.id} datatestid={section.id} header={section.title}>
          {section.component}
        </CollapseCard>
      ))}
    </div>
  )
}

type MobileFiltersProps = {
  amount: number
  open: boolean
  sections: FilterSection[]
  onClose: () => void
  onOpen?: () => void
  onSubmit: () => void
}

export const MobileFilters: FC<MobileFiltersProps> = (props) => {
  const { amount, open, sections, onClose, onOpen, onSubmit } = props

  const { t } = useTranslation()

  return (
    <Modal isFullScreen open={open} onClose={onClose} onOpen={onOpen}>
      <div className={styles.mobile}>
        <Filters modal sections={sections} />
        <ActionBar
          submitText={
            amount === 1
              ? t('COMMON.FILTERS.SHOW_RESULTS', { amount })
              : t('COMMON.FILTERS.SHOW_RESULTS_PLURAL', { amount })
          }
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </div>
    </Modal>
  )
}
