import { FC, useCallback, useEffect, useState } from 'react'
import { CellMeasurerCache } from 'react-virtualized'

// Components
import { UnknownOrderItem } from '../product-card'
import { OrderItemWrapper } from './OrderItemWrapper'
import {
  InfiniteWindowScroller as OrderDetailProductListContent,
  TInfiniteLoaderProps,
} from '../window-scroller'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useEntities } from '@obeta/data/lib/hooks'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { useOrderDetailsContext } from '@obeta/data/lib/stores/useOrderDetailsContext'
import {
  useOrderItemsSearch,
  INITIAL_ORDER_ITEMS_SEARCH_INPUT,
} from '@obeta/data/lib/hooks/useOrderItemsSearch'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

// Models
import { MultiSelectionString, ShoppingCartV2 } from '@obeta/models/lib/models'

// Styles
import styles from './OrderDetailProductList.module.scss'

// UI
import { ListSkeletons } from '../list-skeletons/ListSkeletons'
import { NoResults } from '../no-results/NoResults'

// Utils
import { trackClick } from '@obeta/utils/lib/tracking'
import { getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

// Constants
const INITIAL_PRODUCTS_COUNT = 20 // Initial order items (products) to display on mount
const ROW_HEIGHT = 200
const INITIAL_CACHE = {
  defaultHeight: ROW_HEIGHT,
  fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
  minHeight: ROW_HEIGHT, // expected minimum row height
}

type Props = {
  itemCount: number
  onOrderItemAmountChanged: (orderItem: string, amount: number) => void
  onSelectOrderItem: (orderItemId) => void
  selectedOrderItems: MultiSelectionString
}

export const OrderDetailProductList: FC<Props> = (props) => {
  const { itemCount, onOrderItemAmountChanged, onSelectOrderItem, selectedOrderItems } = props
  const useSapPromotionalProductsVisible = useFeatureToggle('UseSapPromotionalProductsVisible')

  // Please note that tabletWide and desktop share the same design, tablet(Small) differs - therefore tabletAll cannot be used!
  const { mobile, tablet, tabletWide } = useBreakpoints()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const { itemState, orderItems, searchTerm } = useOrderDetailsContext()
  const {
    isLoading,
    searchOrderItemsInput,
    searchOrderItems,
    setIsLoading,
    setSearchOrderItemsInput,
  } = useOrderItemsSearch()
  const params = useParams()
  const { isLoggedIn, user } = useUserDataV2()

  // Component state
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/CellMeasurer.md#cellmeasurercache
  const [cache] = useState(new CellMeasurerCache(INITIAL_CACHE))
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)
  const [rvData, setRvData] = useState<{
    rowCount: number
    rowTotalCount: number
  }>({
    rowCount: 0,
    rowTotalCount: 0,
  })

  // Search initial order items by order id on component mount
  useEffect(() => {
    if (isInitialLoading) {
      setSearchOrderItemsInput({
        ...INITIAL_ORDER_ITEMS_SEARCH_INPUT,
        orderId: params.id,
      })
      setIsInitialLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, params.id])

  // Refetch orderItems should we receive a notification that the order has been updated
  useEffect(() => {
    const sub = getEventSubscription().subscribe((event) => {
      if (
        event.notificationType === NotificationType.OrderUpdate &&
        'orderId' in event.options &&
        event.options.orderId === params.id
      ) {
        if (searchOrderItemsInput.orderId && searchOrderItemsInput.orderId.length > 0) {
          setIsLoading(true)
          searchOrderItems(searchOrderItemsInput, user?.settings?.defaultStoreId ?? '')
        }
      }
    })
    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Search order items on search term change
  useDebouncedEffect(
    () => {
      if (!isInitialLoading) {
        setSearchOrderItemsInput({
          ...INITIAL_ORDER_ITEMS_SEARCH_INPUT,
          orderId: params.id,
          filter: itemState !== 'all' ? [itemState] : [],
          searchTerm,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id, itemState, searchTerm],
    500
  )

  useEffect(() => {
    if (searchOrderItemsInput.orderId && searchOrderItemsInput.orderId.length > 0) {
      setIsLoading(true)
      searchOrderItems(searchOrderItemsInput, user?.settings?.defaultStoreId ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOrderItemsInput, user?.settings?.defaultStoreId])

  // Set elements per row, row count and total row count by offer items (products) length
  useEffect(() => {
    if (orderItems && orderItems.length > 0) {
      setRvData({
        rowCount: orderItems.length,
        rowTotalCount: itemCount ?? 0,
      })
    }
  }, [itemCount, orderItems])

  //
  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => {
      return Boolean(orderItems[index])
    },
    [orderItems]
  )

  /**
   * Load more rows (order items) when InfiniteLoader threshold is reached.
   * @param params Order item list (products) start and stop index
   */
  const onLoadMoreRows = useCallback(
    async (params: { startIndex: number; stopIndex: number }) => {
      setSearchOrderItemsInput({ ...searchOrderItemsInput, offset: orderItems.length.toString() })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderItems, searchOrderItemsInput]
  )

  // Renders single order item by row index
  const { rowRenderer } = useMeasuredRowRenderer({
    cache,
    render: ({ index }) => {
      const orderItem = orderItems[index]

      // Temporarily skip the SAP Zugabeartikel until the Feature Toggle 'UseSapPromotionalProductsVisible' is enabled
      if (!orderItem || (orderItem.isSapPromotionalProduct && !useSapPromotionalProductsVisible)) {
        return null
      }

      return orderItem.product === null ? (
        <div className={styles.unknownOrderItemDiv}>
          <UnknownOrderItem
            isLoggedIn={true}
            mobile={mobile}
            orderItem={orderItem}
            tablet={tablet}
            tabletWide={tabletWide}
            user={user}
            selectedStore={undefined}
          />
        </div>
      ) : (
        <div className={styles.orderItemDiv}>
          <OrderItemWrapper
            carts={carts}
            isLoggedIn={isLoggedIn}
            mobile={mobile}
            //modifiedAmount={getModifiedOrderItemAmount(orderItem, modifiedOrderItemAmounts)}
            orderItem={orderItem}
            productId={orderItem?.product?.sapId ?? ''}
            selected={
              (selectedOrderItems.selectAll &&
                !selectedOrderItems.exclude.includes(orderItem.id)) ||
              selectedOrderItems.include.includes(orderItem.id)
            }
            selectedCart={carts.find((cart) => cart.id === user?.settings?.defaultCartId || '')}
            selectedStore={undefined}
            tablet={tablet} // tabletWide and desktop share the same design, tablet(Small) uses a custom one however
            tabletWide={tabletWide} // tabletWide and desktop share the same design, tablet(Small) uses a custom one however
            url={
              orderItem.product?.images && orderItem.product?.images?.length > 0
                ? orderItem.product?.images[0].url
                : ''
            }
            user={user}
            withCheckbox={true}
            orderWithPrice={true}
            changeProductAmount={(orderItemPosition, amount) => {
              onOrderItemAmountChanged(orderItem.id, amount)
            }}
            onChange={() => {
              onSelectOrderItem(orderItem.id)
            }}
            onAddClicked={(orderItem) => {
              trackClick('on-add-to-cart-template-init', {
                orderId: orderItem.orderId,
                orderItemId: orderItem.id,
              })
            }}
            shippingValue={{
              shippedAmount: orderItem.shippingAmount,
              orderedAmount: orderItem.orderAmount,
              unit: orderItem.unit ?? '',
            }}
            isSapPromotionalProduct={
              useSapPromotionalProductsVisible && orderItem.isSapPromotionalProduct
            }
          />
        </div>
      )
    },
  })

  return (
    <div className={styles.list}>
      {!isLoading && orderItems.length === 0 && (
        <NoResults className={styles.noResults} searchTerm={searchTerm} />
      )}
      <OrderDetailProductListContent
        infiniteLoader={{
          isRowLoaded,
          loadMoreRows: onLoadMoreRows,
          minimumBatchSize: INITIAL_PRODUCTS_COUNT, // Amount of offer items (products) to load at a time
          threshold: mobile || tablet ? 5 : 10, // Load more items when within the last 5/10 rows
        }}
        list={{
          rowRenderer,
          rowHeight: cache.rowHeight,
          deferredMeasurementCache: cache,
        }}
        rowCount={rvData.rowCount} // Row count for loaded offers
        rowTotalCount={rvData.rowTotalCount} // Total row count for offers (including not loaded yet offers)
      />
      {isLoading && <ListSkeletons />}
    </div>
  )
}
