import { SecondaryLightButton } from '@obeta/components/lib/custom-button/CustomButton'
import { PromotionCheckoutView } from '../../promotionPage/promotionCheckout'
import { useTranslation } from 'react-i18next'
import { useInitialUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { Typography } from '@mui/material'
import styles from './progressbarPlugins.module.scss'
import { useLogin } from '@obeta/data/lib/hooks/useLogin'

export const PROGRESSBAR_PLUGIN_ID = 'de/obeta/plugins/strapi-progressbar'
export const ProgressBarPlugin = {
  Renderer: () => {
    const { startLogin } = useLogin()
    const { t } = useTranslation()
    const userV2Data = useInitialUserDataV2()

    const { isLoggedIn } = userV2Data
    return (
      <div className={styles['promotion-wrapper']}>
        {isLoggedIn ? (
          <PromotionCheckoutView simpleView />
        ) : (
          <div className={styles['auth-wrapper']}>
            <Typography variant="boldText" className={styles.explainer}>
              {t<string>('ONBOARDING.LOGIN_TO_SEE_PROMOTIONS')}
            </Typography>
            <div className={styles['btn-wrapper']}>
              <SecondaryLightButton onClick={startLogin}>
                {t<string>('ONBOARDING.LOGIN')}
              </SecondaryLightButton>
            </div>
          </div>
        )}
      </div>
    )
  },
  id: PROGRESSBAR_PLUGIN_ID,
  isInlinable: true,
  title: 'Progressbar',
  description: 'Progressbar plugin',
  version: 1,
}
