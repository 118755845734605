import { FC, ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './DigitalBillingNotRegistered.module.scss'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ActionButton } from '@obeta/components/lib/action-button/ActionButton'
import { BillingType } from './useDigitalBillingActions'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { isValidEmail } from '@obeta/utils/lib/isValidEmail'
import clsx from 'clsx'

type Props = { onSubmit: (billingType: string, email: string) => void }

export const DigitalBillingNotRegistered: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const [billingType, setBillingType] = useState<BillingType>('pdf')
  const [emailAddress, setEmailAddress] = useState('')
  const [error, setError] = useState<string | null>()

  return (
    <>
      <div className={styles.topSectionBackground} />
      <Box className={clsx(styles.sectionWrapper, mobile && styles.mobileWrapper)}>
        <Stack>
          <Typography className={styles.headline}>
            {t('DIGITAL_BILLING.NOT_REGISTERED.HEADLINE')}
          </Typography>
          <RadioGroup
            defaultValue="pdf"
            onChange={(value) => setBillingType(value.target.value as BillingType)}
            value={billingType}
          >
            <Box display="flex" gap={2}>
              <FormControlLabel
                className={styles.radio}
                value="pdf"
                control={<Radio />}
                label="PDF-Versand"
              />
              <FormControlLabel
                className={styles.radio}
                value="zugferd"
                control={<Radio />}
                label="ZUGFeRD (PDF)"
              />
            </Box>
          </RadioGroup>
          <Box
            display="flex"
            alignItems={mobile ? '' : 'center'}
            sx={{ pt: 1.5, pb: 0.5, width: '100%' }}
            flexDirection={mobile ? 'column' : 'row'}
            gap={0.5}
          >
            <Box display="flex" sx={{ height: '100%', mt: 1 }}>
              <Typography className={styles.emailLabel}>
                {t('DIGITAL_BILLING.NOT_REGISTERED.EMAIL_INPUT_LABEL')}
              </Typography>
            </Box>
            <TextField
              sx={{ flexGrow: 1 }}
              placeholder={t('DIGITAL_BILLING.NOT_REGISTERED.EMAIL_INPUT_PLACEHOLDER')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setError(null)
                setEmailAddress(event.target.value)
              }}
              value={emailAddress}
              type="email"
              error={Boolean(error)}
              helperText={error ? t('DIGITAL_BILLING.NOT_REGISTERED.EMAIL_INPUT_ERROR') : ' '}
            />
          </Box>
        </Stack>
        <Box display="flex" flexDirection="column" sx={{ pt: 0.5 }}>
          <Typography variant="bodyBold">{t('DIGITAL_BILLING.HINT_LABEL')}</Typography>
          <Typography>{t('DIGITAL_BILLING.HINT')}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <ActionButton
            onClick={() => {
              const result = isValidEmail(emailAddress)
              if (result) {
                onSubmit(billingType, emailAddress)
              } else {
                setError('Bitte geben Sie eine korrekte E-Mail Adresse ein.')
              }
            }}
            className={styles.submitButton}
            typographyVariant="body"
            color="red100pct"
            variant="contained"
          >
            {t('DIGITAL_BILLING.NOT_REGISTERED.SUBMIT')}
          </ActionButton>
        </Box>
      </Box>
    </>
  )
}
