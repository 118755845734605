import { useEffect, useRef, useState } from 'react'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { gql, useApolloClient } from '@apollo/client'
import { ShopDomains } from '@obeta/utils/lib/domains'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

const allTeasersBrandsQuery = gql`
  query allTeasersBrands($filters: TeasersBrandsFiltersInput) {
    allTeasersBrands(filters: $filters) {
      data {
        id
        name
        filterName
      }
    }
  }
`

interface IAssignedBrand {
  name: string
  id: string
  filterName: string
}

interface IAssignedBrandFilters {
  selectedBrand?: string
  endDate: { gte: Date }
  startDate: { lte: Date }
  showForObeta?: { eq: boolean }
  showForEldis?: { eq: boolean }
  showForHaeusler?: { eq: boolean }
  showInApp?: { eq: boolean }
}

export const useTeasersAssignedBrands = () => {
  const [assignedBrands, setAsignedBrands] = useState<IAssignedBrand[]>([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)
  const currentDate = new Date()

  const domain = useAppDomain()
  const isWeb = isPlatform('web')

  const apolloClient = useStrapiGql()
  const client = useApolloClient(apolloClient)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const getBrands = async () => {
      if (!isMounted.current) return

      setLoading(true)

      const getFilters = () => {
        const variables: IAssignedBrandFilters = {
          endDate: { gte: currentDate },
          startDate: { lte: currentDate },
        }

        if (isWeb) {
          if (domain === ShopDomains.Obeta) {
            variables.showForObeta = { eq: true }
          } else if (domain === ShopDomains.Eldis) {
            variables.showForEldis = { eq: true }
          } else {
            variables.showForHaeusler = { eq: true }
          }
        } else {
          variables.showInApp = { eq: true }
        }

        return variables
      }

      try {
        const response = await client.query({
          query: allTeasersBrandsQuery,
          context: { fetchOptions: { signal } },
          variables: { filters: getFilters() },
        })

        const result = response.data.allTeasersBrands.data
        delete result.__typename

        setAsignedBrands(result)

        setLoading(false)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }
        setError(err)
        setLoading(false)
      }
    }

    getBrands()

    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { assignedBrands, loading, error }
}
