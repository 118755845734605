import React, { createContext, useState } from 'react'

interface IIsPaymentMethodPermittedContext {
  isSelectedPaymentMethodPermitted: boolean
  setIsSelectedPaymentMethodPermitted: (value: boolean) => void
}

export const IsPaymentMethodPermittedContext = createContext<IIsPaymentMethodPermittedContext>({
  isSelectedPaymentMethodPermitted: true,
  setIsSelectedPaymentMethodPermitted: () => {
    //
  },
})

export const IsPaymentMethodPermittedProvider: React.FC = (props) => {
  const [isSelectedPaymentMethodPermitted, setIsSelectedPaymentMethodPermitted] =
    useState<boolean>(true)
  const { children } = props

  return (
    <IsPaymentMethodPermittedContext.Provider
      value={{
        isSelectedPaymentMethodPermitted,
        setIsSelectedPaymentMethodPermitted,
      }}
    >
      {children}
    </IsPaymentMethodPermittedContext.Provider>
  )
}
