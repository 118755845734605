import { SimpleInfoBlock } from '@obeta/components/lib/notifications/SimpleInfoBlock'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'

export const PricesNoPermission: React.FC<{ listPricePermission: boolean }> = (props) => {
  const { listPricePermission } = props
  const { t } = useTranslation()

  const body = listPricePermission
    ? t<string>('ARTICLE_DETAIL.PERMISSION_TO_VIEW_ONLY_LIST_PRICE')
    : t<string>('ARTICLE_DETAIL.NO_PERMISSION_TO_VIEW_PRICES')

  return <SimpleInfoBlock Icon={InfoIcon} variant="medium" body={t<string>(body)} />
}
