import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RadioItem } from '../../../../../checbox-radio-item/CheckboxRadioItem'
import Box from '@mui/material/Box'
import { getCheckboxItemConfig } from '../../../../utils/getCheckboxItemConfig'
import { ShowPricesWithVAT } from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'
import styles from './AllPricesSection.module.scss'

export const AllPricesSection = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Box className={styles.wrapper}>
      <Controller
        defaultValue={ShowPricesWithVAT.Exclusive}
        name="showPricesWithVAT"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Box>
              <RadioItem
                label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.EXCLUDING_VAT')}
                onChange={() => onChange(ShowPricesWithVAT.Exclusive)}
                checked={value === ShowPricesWithVAT.Exclusive}
                {...getCheckboxItemConfig<ShowPricesWithVAT>(ShowPricesWithVAT.Exclusive)}
              />
            </Box>

            <Box>
              <RadioItem
                label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.INCLUDING_VAT')}
                onChange={() => onChange(ShowPricesWithVAT.Inclusive)}
                checked={value === ShowPricesWithVAT.Inclusive}
                {...getCheckboxItemConfig<ShowPricesWithVAT>(ShowPricesWithVAT.Inclusive)}
              />
            </Box>
          </>
        )}
      />
    </Box>
  )
}
