import React, { FC } from 'react'
import { LocationPageContextProvider } from './hooks/LocationPageContextProvider'
import { LocationOverlay } from './components/LocationOverlay/LocationOverlay'
import { SlideTransition, StackNavigator, StackScreen } from '@obeta/components/lib/stack'
import styles from './LocationPage.module.scss'
import { MainScreen } from './components/MainScreen/MainScreen'

export const LOCATION_SCREENS = {
  MAIN: 'MAIN',
  STORE_OVERLAY: 'STORE_OVERLAY',
}

export const LocationPage: FC = () => {
  return (
    <div className={styles.mainWrapper}>
      <LocationPageContextProvider>
        <StackNavigator initialScreenName={LOCATION_SCREENS.MAIN}>
          <SlideTransition>
            <StackScreen
              unmountOnExit={false}
              className={styles.stackScreen}
              name={LOCATION_SCREENS.MAIN}
            >
              <MainScreen />
            </StackScreen>
            <LocationOverlay />
          </SlideTransition>
        </StackNavigator>
      </LocationPageContextProvider>
    </div>
  )
}
