import { StoreV2 } from '@obeta/models/lib/models'

export type Coordinates = {
  latitude: number
  longitude: number
}

const EARTH_RADIUS = 6371
const DEGREES_TO_RADIANS = 180

const degreesToRadians = (degrees: number) => (degrees * Math.PI) / DEGREES_TO_RADIANS

const calculateDistance = (
  { latitude: lat1, longitude: lon1 }: Coordinates,
  { latitude: lat2, longitude: lon2 }: Coordinates
): number => {
  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)

  const halfSquareOfSphericalTriangle =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const centralAngle =
    2 *
    Math.atan2(
      Math.sqrt(halfSquareOfSphericalTriangle),
      Math.sqrt(1 - halfSquareOfSphericalTriangle)
    )

  return EARTH_RADIUS * centralAngle
}

export const getClosestPlaces = (
  selectedLocation: Coordinates,
  places: StoreV2[],
  numberOfClosestPlaces: number
): StoreV2[] => {
  if (selectedLocation.latitude === undefined || selectedLocation.longitude === undefined) {
    throw new Error('Invalid coordinates for reference point')
  }

  // Sort array by distance from res position
  places.sort((a, b) => {
    const distanceA = calculateDistance(selectedLocation, {
      latitude: Number(a.latitude),
      longitude: Number(a.longitude),
    })
    const distanceB = calculateDistance(selectedLocation, {
      latitude: Number(b.latitude),
      longitude: Number(b.longitude),
    })
    return distanceA - distanceB
  })

  return places.slice(0, numberOfClosestPlaces)
}
