import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { useActionNotification } from './useActionNotification'

const DEFAULT_POLL_TIME = 10 * 1000 * 60

/**
 * All deps (start, stop, polling flag) must never change.
 * This allows to savely use polling inside react's useEffect.
 * User must decide on its own when to start/stop polling.
 * @param p
 * @returns
 */
export const useDispatchPoll = <ResultAction extends string>(p: {
  resultAction: ResultAction
  interval?: number
}) => {
  const dispatch = useDispatch()
  const interval = typeof p?.interval === 'number' ? p.interval : DEFAULT_POLL_TIME
  const waitForStockResults = useActionNotification(p.resultAction)
  const pollingIdRef = useRef<NodeJS.Timeout | null>(null)
  const pollingStartedRef = useRef(false)
  const stopPollingRef = useRef(() => {
    pollingStartedRef.current = false
    if (pollingIdRef.current) {
      clearTimeout(pollingIdRef.current)
    }
  })

  const startPolling = useRef((action: AnyAction) => {
    const req = () => {
      return new Promise<void>((res) => {
        dispatch(action)

        waitForStockResults(() => {
          res()
        })
      })
    }

    const pollStocks = async () => {
      await req()
      if (!pollingStartedRef.current) {
        return
      }
      pollingIdRef.current = setTimeout(() => {
        pollStocks()
      }, interval)
    }

    pollingStartedRef.current = true
    pollStocks()
  })

  return {
    startPolling: startPolling.current,
    stopPolling: stopPollingRef.current,
    pollingStartedRef,
  }
}
