import React from 'react'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from './OpenPositionsLoading.module.scss'

export const OpenPositionsLoading = () => {
  const { desktop, tablet, tabletWide, mobile } = useBreakpoints()

  let creditInfoLoadingHeight = ''
  if (desktop || tabletWide) {
    creditInfoLoadingHeight = '3.2rem'
  } else if (tablet) {
    creditInfoLoadingHeight = '5.5rem'
  } else if (mobile) {
    creditInfoLoadingHeight = '13rem'
  }

  let accountAndInvoiceLineWidth = ''
  if (desktop || tabletWide) {
    accountAndInvoiceLineWidth = '21rem'
  } else if (tablet) {
    accountAndInvoiceLineWidth = '15rem'
  } else if (mobile) {
    accountAndInvoiceLineWidth = '10rem'
  }

  return (
    <div>
      <TextSkeleton
        style={{ maxWidth: '11rem' }}
        className={styles.skeletonMarginSmall}
        height="1.8rem"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextSkeleton
          style={{ maxWidth: accountAndInvoiceLineWidth }}
          className={styles.skeletonMarginSmall}
          height="1.5rem"
        />
        <TextSkeleton
          style={{ maxWidth: accountAndInvoiceLineWidth }}
          className={styles.skeletonMarginSmall}
          height="1.5rem"
        />
      </div>
      <TextSkeleton className={styles.skeletonMarginLarge} height={creditInfoLoadingHeight} />
      <TextSkeleton
        style={{ maxWidth: '11rem' }}
        className={styles.skeletonMarginLarge}
        height="1.5rem"
      />

      <TextSkeleton className={styles.skeletonMarginLarge} height="2.5rem" />
      <TextSkeleton
        style={{ maxWidth: '28rem' }}
        className={styles.skeletonMarginLarge}
        height="2rem"
      />
      <TextSkeleton className={styles.skeletonMarginLarge} height="20rem" />
    </div>
  )
}
