import { ButtonBase, List, ListItem, ListItemText } from '@mui/material'
import { useStackHistory } from '@obeta/components/lib/stack'
import { SlideWrap } from '@obeta/components/lib/stack/SlideWrap'
import { DispatchFiltersAction } from '../reducer'
import { EtimSlides } from './types'
import { Header } from '@obeta/components/lib/stack/Header'
import { ReactComponent as ChebronRight } from '@obeta/assets/icon/chevron_right20x20.svg'
import styles from './AttributesSlide.module.scss'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import React from 'react'
import { Size } from '../Size'
import { EtimSkeletons } from './EtimSkeletons'
import { SelectedFeatureInfo } from '../SelectedFeatureInfo'
import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { Etim, SelectedEtimValue } from '@obeta/models/lib/models/Search'

interface IAttributesProps {
  dispatchEtimAction: DispatchFiltersAction
  selectedEtimClass: Etim | null
  selectedEtimValues: Record<string, SelectedEtimValue>
}

export const Attributes: React.FC<IAttributesProps> = (props) => {
  const { dispatchEtimAction, selectedEtimClass, selectedEtimValues } = props
  const historyStack = useStackHistory()

  const {
    articleSearchFilter: { etimAttributes },
  } = useArticleFilterData()

  return (
    <SlideWrap>
      <Header
        title={selectedEtimClass?.name || ''}
        onBackClicked={() => {
          historyStack.goBack()
        }}
        rightAdornment={<Size size={selectedEtimClass?.count} />}
      />
      {etimAttributes.length > 0 ? (
        <List disablePadding className={styles.list}>
          <LightGrayDivider orientation="horizontal" />
          {etimAttributes.map((attr, idx) => {
            const last = etimAttributes.length - 1 === idx

            const selectedFeature = selectedEtimValues[attr.name]
            const selectedFeatureInfoEl = (
              <SelectedFeatureInfo attribute={attr} selectedValue={selectedFeature} />
            )

            return (
              <React.Fragment key={attr.name}>
                <ListItem
                  disablePadding
                  className={styles.listItem}
                  onClick={() => {
                    dispatchEtimAction({
                      type: 'selectEtimAttribute',
                      payload: {
                        etimAttribute: attr,
                      },
                    })
                    historyStack.push(EtimSlides.EtimAttributesKeywords)
                  }}
                >
                  <ListItemText
                    className={styles.listItemText}
                    primary={attr.meta.name}
                    secondary={selectedFeatureInfoEl}
                  ></ListItemText>
                  <ButtonBase>
                    <ChebronRight />
                  </ButtonBase>
                </ListItem>
                {!last && <LightGrayDivider orientation="horizontal" />}
              </React.Fragment>
            )
          })}
        </List>
      ) : (
        <EtimSkeletons height={60} />
      )}
    </SlideWrap>
  )
}
