import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { UNKNOWN_USER_CONTEXT, UNKNOWN_USER_KEY } from '../configs/ldProviderConfig'
import { useSetldClient } from './useSetldClient'
import { UserV2Data } from '@obeta/models/lib/models/Users/UserV2'

export const useUserAnalytics = (userV2Data: UserV2Data) => {
  const { ldClient } = useSetldClient()

  useEffect(() => {
    const launchDarklyContext = ldClient?.getContext()
    if (userV2Data?.fullUserId) {
      const { userId: datadogUserId } = datadogRum.getUser()
      const isDifferentUser = datadogUserId !== userV2Data.fullUserId

      const shouldIdentifyDatadog = !datadogUserId || isDifferentUser
      const shouldIdentifyLaunchDarkly =
        launchDarklyContext?.key === UNKNOWN_USER_KEY || launchDarklyContext?.anonymous

      if (shouldIdentifyDatadog) {
        datadogRum.setUser({
          id: userV2Data.fullUserId,
          companyId: userV2Data.companyId,
        })
      }

      if (shouldIdentifyLaunchDarkly) {
        const context = {
          kind: 'user',
          key: userV2Data.fullUserId,
          custom: {
            customerId: userV2Data.userId ?? '',
            origin: 'app',
          },
        }
        ldClient?.identify(context)
      }
    } else {
      ldClient?.identify(UNKNOWN_USER_CONTEXT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userV2Data])
}
