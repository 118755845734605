import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Context
import { CartTemplateDetailsContext } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'

// Styles
import styles from './CartTemplateDetailsSearchBar.module.scss'

// UI
import { Checkbox } from '../checkbox/Checkbox'
import { SearchField } from '../search-field/SearchField'
import clsx from 'clsx'

interface Props {
  count: number
  onSearch: (searchTerm: string) => void
}

export const CartTemplateDetailsSearchBar: FC<Props> = ({ count, onSearch }) => {
  const { t } = useTranslation()
  const { searchTerm, state, resetSelectedItems, selectAllItems, setSearchTerm } = useContext(
    CartTemplateDetailsContext
  )
  const { mobile } = useBreakpoints()

  const onChange = () => {
    if (state.selectedCartTemplateItems.selectAll) {
      resetSelectedItems()
    } else {
      selectAllItems()
    }
  }

  // Delay search for 200ms after last key input
  useDebouncedEffect(
    () => {
      onSearch(searchTerm)
    },
    [searchTerm],
    200
  )

  return (
    <div className={clsx(mobile ? styles.containerMobile : styles.container)}>
      {mobile && (
        <div className={styles.actionWrapper}>
          <Checkbox
            checked={state.selectedCartTemplateItems.selectAll}
            indeterminate={
              (state.selectedCartTemplateItems.selectAll &&
                state.selectedCartTemplateItems.exclude.length > 0) ||
              state.selectedCartTemplateItems.include.length > 0
            }
            label={`(${count})`}
            onChange={onChange}
          />
        </div>
      )}
      <SearchField
        placeholder={t('TEMPLATES.DETAILS_SEARCH_PLACEHOLDER')}
        value={searchTerm}
        onChange={setSearchTerm}
        onReset={() => setSearchTerm('')}
      />
      {!mobile && (
        <div className={styles.actionWrapper}>
          <Checkbox
            checked={state.selectedCartTemplateItems.selectAll}
            indeterminate={
              (state.selectedCartTemplateItems.selectAll &&
                state.selectedCartTemplateItems.exclude.length > 0) ||
              state.selectedCartTemplateItems.include.length > 0
            }
            label={`${t('COMMON.SELECT_ALL')} (${count})`}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  )
}
