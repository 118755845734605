import { SortBy } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { CartTemplate } from '@obeta/schema'

export const sort = (templates: CartTemplate[], sortBy: SortBy) => {
  return templates.sort((a, b) => {
    if (sortBy === SortBy.PRIVATE_TEMPLATES || sortBy === SortBy.PUBLIC_TEMPLATES) {
      if (a.isShared === b.isShared) {
        return 0
      } else if (a.isShared) {
        return -1
      } else {
        return 1
      }
    } else if (sortBy === SortBy.ALPHABETICAL) {
      return a.name.localeCompare(b.name)
    } else {
      return b.updatedAt.localeCompare(a.updatedAt)
    }
  })
}
