import React from 'react'
import { pluginFactories } from '@react-page/plugins-slate'
import { Typography } from '@mui/material'

export const subtitlePlugin = pluginFactories.createComponentPlugin({
  addHoverButton: false,
  addToolbarButton: false,
  type: 'customSubtitle',
  object: 'mark',
  Component: (props) => {
    return (
      <Typography {...props.attributes} variant="subtitle1">
        {props.children}
      </Typography>
    )
  },
})
