import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../alert-and-confirm/Alert'
import { Confirm } from '../alert-and-confirm/Confirm'
import { CartVerificationErrorCode } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'

interface Props {
  errorCode: CartVerificationErrorCode
  handleCloseByUnsettingErrorCode: () => void
  submitCart: () => void
  nameOfPickupOption?: string
}

export const CartVerification: React.FC<Props> = (props) => {
  const { errorCode, handleCloseByUnsettingErrorCode, nameOfPickupOption, submitCart } = props
  const { t } = useTranslation()

  return (
    <>
      {/* User hat Berechtigung verloren - Alert + Notification (handled by OrderFailureNotification.tsx) */}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.ERROR')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.NO_PERMISSION_GRANTED')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.UserNotPermitted}
      />
      {/* Kreditlimit überschritten - Alert + Notification (handled by OrderFailureNotification.tsx)*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.ERROR')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.CREDIT_LIMIT_EXCEEDED')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.CreditLimitExceeded}
      />
      {/* Xpress ist aufgrund von Bestellzeit nicht mehr möglich - Alert*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.XPRESS_NOT_POSSIBLE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.XPRESS_ORDER_CUT_OFF_TIME_EXCEEDED')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.ExpressOrderDeadlineExceeded}
      />
      {/* Xpress ist aufgrund von Bestand nicht mehr möglich - Alert*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.XPRESS_NOT_POSSIBLE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.CART_CONTAINS_NON_XPRESS_ITEMS')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.ExpressOutOfStock}
      />
      {/* Artikel ist auf VK Nein gesprungen - Alert */}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.ERROR')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.CART_CONTAINS_NOT_FOR_SALE_ITEMS')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.ContainsProductsNotForSale}
      />
      {/* Keine Paketlieferung, da Kunde weder 50 noch 65 im Kundenstamm hat oder Barkunde ist - Alert */}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.DELIVERY_OPTION_NOT_POSSIBLE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.UNSUPPORTED_SELECTED_DELIVERY_OPTION')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.DeliveryOptionNotPossible}
      />
      {/* (Bar-)Kunde darf nicht (an Renzbox) abholen - Alert */}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.PICKUP_OPTION_NOT_POSSIBLE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.UNSUPPORTED_SELECTED_PICKUP_OPTION', {
          pickupOption: nameOfPickupOption ?? '',
        })}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.PickupOptionNotPossible}
      />
      {/* Barkunde (keine PKW-Lieferung) welcher weder 50 noch 65 im Kundenstamm hat (keine Paketlieferung) darf nur abholen - Alert */}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.DELIVERY_OPTION_NOT_POSSIBLE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.CASH_CUSTOMER_TO_CHOOSE_PICKUP_OPTION')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.OnlyPickupPossible}
      />
      {/* Wunschlieferdatum ist nicht möglich (aufgrund Feiertag etc.) - Alert*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.PREFERRED_DELIVERY_DATE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.ORDER_TIME_NOT_POSSIBLE')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.ShippingDateNotPossible}
      />
      {/* Postleitzahl außerhalb des Liefergebietes - Alert*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.NO_SHIPPING_OPTION')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.NOT_WITHIN_DELIVERY_AREA')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.AddressNotWithinDeliveryArea}
      />
      {/* Telefonnummer nicht korrekt*/}
      <Alert
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.WRONG_PHONE_NUMBER')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.PROVIDE_PHONE_NUMBER')}
        handleConfirm={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openAlertDialog={errorCode === CartVerificationErrorCode.WrongPhoneNumber}
      />
      {/* Wunschlieferdatum ist nicht mehr möglich - Confirm*/}
      <Confirm
        heading={t('SHOPPING_CART.CHECKOUT.VERIFICATION.PREFERRED_DELIVERY_DATE')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.ORDER_CUT_OFF_TIME_EXCEEDED')}
        handleConfirm={() => {
          submitCart()
        }}
        handleCancel={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openConfirmDialog={errorCode === CartVerificationErrorCode.ShippingDateNotPossibleAnymore}
      />
      {/* User is Cash-Customer, nur Barzahlung möglich - Confirm*/}
      <Confirm
        heading={t('SHOPPING_CART.CHECKOUT.PAYMENT_INFORMATION')}
        body={t('SHOPPING_CART.CHECKOUT.VERIFICATION.USER_IS_CASH_CUSTOMER')}
        handleConfirm={() => {
          submitCart()
        }}
        handleCancel={() => {
          handleCloseByUnsettingErrorCode()
        }}
        openConfirmDialog={errorCode === CartVerificationErrorCode.UserIsCashCustomer}
      />
    </>
  )
}
