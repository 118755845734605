import { Typography } from '@mui/material'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RelatedProductEnum, useRelatedProducts } from '@obeta/data/lib/hooks/useRelatedProducts'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { ProductsSwiper } from './ProductsSwiper'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { ArticleCarouselCard } from '@obeta/components/lib/product-card'
import { useCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import clsx from 'clsx'
import styles from './ProductsCarousel.module.scss'
import { useUpCrosssSelling } from '@obeta/data/lib/hooks/useUpCrosssSelling'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useOnEnterViewport } from '@obeta/data/lib/hooks/useOnEnterViewPort'
import {
  CrossSellingProduct,
  RecommendationProduct,
  UpSellingProduct,
} from '@obeta/models/lib/schema-models/recommendation'
import {
  ProductCollectionActionOptions,
  ProductCollectionActionProvider,
} from '@obeta/data/lib/hooks/useProductCollectionActions'

interface IProductsCarousel extends WithBreakpoints<'mobile' | 'tabletAll' | 'tablet'> {
  type: 'recommendations' | 'alternative'
  selectedStore: StoreV2 | undefined
  titleClassName?: string
  upSelling?: boolean
  crossSelling?: boolean
  onEnterViewport?: () => void
  onRenderProducts?: () => void
}

export const ProductsCarousel: React.FC<IProductsCarousel> = (props) => {
  const {
    type,
    mobile,
    tablet,
    tabletAll,
    selectedStore,
    titleClassName,
    upSelling = false,
    crossSelling = false,
    onEnterViewport,
    onRenderProducts,
  } = props
  const { isLoggedIn, user } = useUserDataV2()

  const { t } = useTranslation()

  let id: ArticleDetailsSections
  let title: string
  let productType = RelatedProductEnum.None
  if (type === 'alternative') {
    id = ArticleDetailsSections.Alternative
    title = t<string>('ARTICLE_DETAIL.ALTERNATIVE')
    productType = RelatedProductEnum.Similar
  } else if (crossSelling) {
    title = t<string>('ARTICLE_DETAIL.CROSSSELLING')
    id = ArticleDetailsSections.CrossSelling
  } else if (upSelling) {
    title = t<string>('ARTICLE_DETAIL.UPSELLING')
    id = ArticleDetailsSections.UpSelling
  } else {
    id = ArticleDetailsSections.Recommendations
    title = t<string>('ARTICLE_DETAIL.RECOMMENDATIONS')
  }

  const productIds = useCurrentProductIds()
  const relatedProducts = useRelatedProducts(productIds, [productType], selectedStore?.id)
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()
  const shouldFetchUpAndCrossSelling = upSelling || crossSelling
  const { upSellingProducts, crossSellingProducts, getTrackingOptions } = useUpCrosssSelling(
    productIds,
    selectedStore?.id,
    shouldFetchUpAndCrossSelling
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const onRenderProductsRef = useRef(onRenderProducts)

  let products = [] as Array<ProductAggregate | RecommendationProduct>

  const filterProducts = (
    products: Array<ProductAggregate | UpSellingProduct | CrossSellingProduct>
  ) => products.filter((product) => product.isForSale === true && product.isBlackListed === false)

  if (upSelling) {
    products = filterProducts(upSellingProducts)
  } else if (crossSelling) {
    products = filterProducts(crossSellingProducts)
  } else {
    products = filterProducts(relatedProducts)
  }
  useEffect(() => {
    if (onRenderProductsRef.current && products.length > 0) {
      onRenderProductsRef.current()
      onRenderProductsRef.current = undefined
    }
  }, [products.length])
  useOnEnterViewport(containerRef, onEnterViewport, products.length)

  if (!products.length) {
    return null
  }

  const elementsPerRow = mobile ? 'auto' : tablet ? 2 : 4
  let trackingContextValue: null | ProductCollectionActionOptions = null
  if (upSelling) {
    trackingContextValue = getTrackingOptions('upselling')
  } else if (crossSelling) {
    trackingContextValue = getTrackingOptions('crossselling')
  }

  return (
    <div className={styles.carousel} id={id} ref={containerRef}>
      <ProductCollectionActionProvider value={trackingContextValue}>
        <div className={styles.wrapper}>
          <Typography className={clsx(titleClassName)} variant="headline3Bold">
            {title}
          </Typography>
          <ProductsSwiper
            cardWrapperClassName={clsx(styles.cardWrapper)}
            withNavigateButtons={!mobile}
            elementsPerRow={elementsPerRow}
            productElements={products.map((p) => {
              return (
                <ArticleCarouselCard
                  key={p.sapId}
                  carts={carts}
                  selectedCart={selectedCart}
                  product={p}
                  tablet={tabletAll}
                  selectedStore={selectedStore}
                  mobile={mobile}
                  isLoggedIn={isLoggedIn}
                  user={user}
                  productId={p.sapId}
                />
              )
            })}
          />
        </div>
      </ProductCollectionActionProvider>
    </div>
  )
}
