import { useState, useEffect } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  ObetaRentArticleEntity,
  ObetaRentArticleResponse,
  ObetaRentLocationEntity,
} from './graphqlTypes'
import { usePrepareData, TDataSelector, IPreparedData } from './usePrepareData'
import {
  TArticlesImagesDataResponse,
  useFetchArticlesImagesData,
} from './useFetchArticlesImagesData'
import { useFetchRentArticlesData } from './useFetchRentArticlesData'
import { useEntities } from '../useEntities'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { useUserDataV2 } from '../useUserDataV2'

function findAndMergeStore(
  rentLocation: ObetaRentLocationEntity,
  stores: StoreV2[],
  defaultStoreId?: string
): ObetaRentLocationEntity & { defaultStore?: boolean } {
  if (!rentLocation.attributes.obetaStoreId) {
    return rentLocation
  }

  const store = stores.find((store) => store.id === rentLocation.attributes.obetaStoreId)

  if (!store) {
    return rentLocation
  }

  const { obetaStoreId, mail, phoneNumber, address, name, openingHours } = rentLocation.attributes

  return {
    ...rentLocation,
    attributes: {
      obetaStoreId,
      name, //This field is not displayed
      mail: mail || store.mail,
      phoneNumber: phoneNumber || store.phoneNumber,
      openingHours: openingHours.length ? openingHours : store.openingHours,
      address: {
        id: address?.id || store.id,
        name1: address?.name1 || store.address.name1,
        zipCode: address?.zipCode || store.address.zipCode,
        city: address?.city || store.address.city,
        street: address?.street || store.address.street,
        houseNumber: address?.houseNumber || store.address.houseNumber,
      },
      isDefaultStore: store.id === defaultStoreId,
    },
  }
}

/**
 * Merges data from 3 different sources into a single IRentArticleWithAdditionalData
 * Main rentArticle data comes from strapi.
 * Strapi might provide an image url, but it also provides sapId/dehaId, which are used to get images from the product
 * Locations are also enreached with data from storage, using obetaStoreId field
 * */
function mergeRentArticles(
  rawStrapiRentArticles: ObetaRentArticleResponse | null,
  articlesImagesData: TArticlesImagesDataResponse | null,
  obetaStores: StoreV2[],
  defaultStoreId?: string
): IRentArticleWithAdditionalData[] | null {
  if (!rawStrapiRentArticles) {
    return null
  }

  const _articlesImagesData = articlesImagesData || { products: [] }

  return rawStrapiRentArticles.obetaRentArticles.data.map((article) => {
    const mergedLocations = article.attributes.rental_locations.data.map((location) =>
      findAndMergeStore(location, obetaStores, defaultStoreId)
    )

    const matchedImagesData = _articlesImagesData.products.find(
      (data) => data.sapId === article.attributes.sapId || data.dehaId === article.attributes.dehaId
    )

    const matchedDataImage = matchedImagesData?.imageData.images[0]

    return {
      ...article,
      attributes: {
        ...article.attributes,
        rental_locations: { data: mergedLocations },
      },
      oxomiId: matchedImagesData?.oxomiId,
      supplierId: matchedImagesData?.supplierId,
      productImageUrl: matchedDataImage?.large,
    }
  })
}

export const useRentArticlesData = (
  select: TDataSelector,
  strapiApolloClient: ApolloClient<NormalizedCacheObject>
): IRentArticlesData => {
  const stores = useEntities<StoreV2>('storesv2')
  const { user } = useUserDataV2()
  const defaultStoreId = user?.settings?.defaultStoreId

  const [mergedRentArticles, setMergedRentArticles] = useState<
    IRentArticleWithAdditionalData[] | null
  >(null)
  const {
    data: strapiRentArticlesRaw,
    isError,
    isLoading: isArticlesDataLoading,
  } = useFetchRentArticlesData(strapiApolloClient)
  const { data: articlesImagesData, isLoading: isArticlesImagesLoading } =
    useFetchArticlesImagesData(strapiRentArticlesRaw)

  useEffect(() => {
    const _mergedRentArticle = mergeRentArticles(
      strapiRentArticlesRaw,
      articlesImagesData,
      stores,
      defaultStoreId
    )
    //wait until stores are loaded to make data retrievable in case the rentarticle only references the store id
    if (!stores || stores.length === 0) return

    setMergedRentArticles(_mergedRentArticle)
  }, [articlesImagesData, defaultStoreId, stores, strapiRentArticlesRaw])

  const preparedData = usePrepareData(mergedRentArticles, select)

  return { preparedData, isLoading: isArticlesDataLoading || isArticlesImagesLoading, isError }
}

interface IRentArticlesData {
  preparedData: IPreparedData
  isLoading: boolean
  isError: boolean
}

export interface IRentArticleWithAdditionalData extends ObetaRentArticleEntity {
  oxomiId?: string
  supplierId?: string
  productImageUrl?: string
}
