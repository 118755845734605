import { useState } from 'react'
import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'
import { useSubscription } from 'observable-hooks'
import { Box, Typography } from '@mui/material'

/*
 We use this wrapper to unmount the application during logout, thereby preventing the execution of any effects
  that might interact with the database and slow down the logout process
 */
export const AppLogoutWrapper = ({ children }) => {
  const [isLogout, setLogout] = useState(false)
  const { logout$, logoutFinished$ } = useAppActions()

  useSubscription(logout$, () => {
    setLogout(true)
  })

  useSubscription(logoutFinished$, () => {
    setLogout(false)
  })

  return isLogout ? (
    <Box padding="2rem">
      <Typography>Sie werden sicher abgemeldet.</Typography>
    </Box>
  ) : (
    children
  )
}
