import { ApolloError, gql, useQuery } from '@apollo/client'
import { calcProductTotalPrice, ICalcTotalParams, ITotal } from '@obeta/utils/lib/productPrices'

export interface IFetchedPrices {
  listPrice: number
  bulkPrice: number
  bulkPriceAmount: number
  priceDimension: number
  minimumAmount: number
  catalogPrice: number
  metalNeAddition: number
  netPrice: number
  tecSelect: number
  strikeThroughPrice: number
  currency: string
}

export const PRODUCT_PRICE_DETAIL_QUERY = gql`
  query ProductPriceDetails($sapId: String!) {
    getProductDetailPrices(sapId: $sapId) {
      listPrice
      bulkPrice
      bulkPriceAmount
      priceDimension
      minimumAmount
      catalogPrice
      metalNeAddition
      netPrice
      tecSelect
      strikeThroughPrice
      currency
    }
  }
`

export interface IProductPrices {
  prices: IFetchedPrices | null
  loading: boolean
  error: ApolloError | undefined
}

export const useProductPrices = (
  sapId: string | undefined,
  isLoggedIn: boolean
): IProductPrices => {
  const { data, error } = useQuery<
    {
      getProductDetailPrices: IFetchedPrices
    },
    { sapId: string }
  >(PRODUCT_PRICE_DETAIL_QUERY, {
    skip: !sapId || !isLoggedIn,
    variables: {
      sapId: sapId as string,
    },
  })

  const prices = data?.getProductDetailPrices || null
  return { prices, loading: !prices && !error && isLoggedIn, error }
}

export type ITotalPriceParams = Pick<ICalcTotalParams, 'amount'>

export const useProductTotalPrice = (
  prices: IFetchedPrices | null,
  params: ITotalPriceParams | 'skip'
): ITotal => {
  if (!prices || params === 'skip') {
    return { total: 0, subTotal: 0, totalMetalAdition: 0 }
  }

  return calcProductTotalPrice({
    ...prices,
    ...params,
  })
}
