import { useEventCallback } from '@mui/material/utils'
import { TemplateItem } from '../../product-card'

export function TemplateItemWrapper(props: React.ComponentProps<typeof TemplateItem>) {
  const { onChange, ...others } = props

  const handleChange = useEventCallback(onChange)

  // Memoize other callbacks if they are used in the future.

  return <TemplateItem onChange={handleChange} {...others} />
}
