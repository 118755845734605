import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import url1 from '@obeta/assets/img/OnBoarding/intro1.png'
import url2 from '@obeta/assets/img/OnBoarding/categroiesarray.svg'
import url3 from '@obeta/assets/img/OnBoarding/intro2.png'
import { Box, Typography } from '@mui/material'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { OnboardingSlider } from './OnboardingSlider'
import styles from './Onboarding.module.scss'

interface Props {
  onSkip: VoidFunction
}

export const Onboarding: React.FC<Props> = (props) => {
  const { onSkip } = props

  const { t } = useTranslation()

  const slides = [
    {
      title: t('ONBOARDING.INTRO.ALWAYS_CLOSE_BY'),
      text: t('ONBOARDING.INTRO.ALWAYS_CLOSE_BY_DESCRIPTION'),
      backgroundImage: url1,
    },
    {
      title: t('ONBOARDING.INTRO.DIGITAL_CATALOG'),
      text: t('ONBOARDING.INTRO.DIGITAL_CATALOG_DESCRIPTION'),
      backgroundImage: url2,
    },
    {
      title: t('ONBOARDING.INTRO.COMPOSURE'),
      text: t('ONBOARDING.INTRO.COMPOSURE_DESCRIPTION'),
      backgroundImage: url3,
    },
  ]

  const content = (
    <div className={styles.slidescontainer}>
      <OnboardingSlider slides={slides} />
    </div>
  )

  const buttons: ReactNode[] = [
    <Box key="skip" id="skipAndgoToLogin">
      <TertiaryButton onClick={onSkip}>
        <Typography variant="bodyBold">{t('ONBOARDING.SKIP_INTRO')}</Typography>
      </TertiaryButton>
    </Box>,
  ]

  return (
    <div className={styles.onboarding}>
      {content}
      <div className={styles.actionscontainer}>{buttons}</div>
    </div>
  )
}
