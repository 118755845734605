import { gql } from '@apollo/client'

export const gqlUserCreditInfo = gql`
  query getCreditInfo {
    getUser {
      creditInfo {
        creditLimit
        openOrders
        openPositions
        creditUsed
        creditAvailable
      }
    }
  }
`
