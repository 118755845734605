import { useCallback, useMemo, useState } from 'react'
import { TSetFilter, TSetFilters, IFiltersCombined } from '../types'
import { IFilter } from '@obeta/models/lib/models/RentArticle/types'
import {
  ALL_CATEGORIES,
  ALL_LOCATIONS,
  ALL_VALUE,
  EMPTY_SEARCH,
} from '@obeta/data/lib/hooks/rentArticles'

const defaultFilters: IFiltersCombined = {
  category: ALL_CATEGORIES,
  location: ALL_LOCATIONS,
  search: EMPTY_SEARCH,
}

export const useFiltersState = () => {
  const [filters, _setFilters] = useState<IFiltersCombined>(defaultFilters)

  const setFilter = useCallback<TSetFilter>((filter) => {
    _setFilters((filters) => ({ ...filters, [filter.type]: filter }))
  }, [])

  const setFilters = useCallback<TSetFilters>((filters) => {
    _setFilters((prevFilters) => ({ ...prevFilters, ...filters }))
  }, [])

  const activeFilters: IFilter[] = useMemo(() => {
    return Object.values(filters).filter(
      ({ type, value }) => type !== 'search' && value !== ALL_VALUE
    )
  }, [filters])

  const resetFilter = useCallback(
    (filter: IFilter) => setFilter(defaultFilters[filter.type]),
    [setFilter]
  )

  return {
    activeFilters,
    resetFilter,
    filters,
    setFilter,
    setFilters,
  }
}
