import './CartImportPage.scss'
import React, { useState, useContext } from 'react'
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
  ListRowProps,
} from 'react-virtualized'

import { UserContext, AvailablePermissions } from '@obeta/data/lib/hooks/useUserData'

import {
  CartSelectionMenu,
  CART_SELECTION_CONTEXT,
} from '@obeta/components/lib/menus/CartSelection'
import { MenuWrapper } from '@obeta/components/lib/menus/MenuWrapper'
import { PageContent } from '../../common/components/page-content/PageContent'
import { SearchResultItem } from '@obeta/components/lib/product-card'
import {
  ReactRouterParamsProvider,
  useHistory,
  useLocation,
} from '@obeta/data/lib/hooks/useHistoryApi'

import { ReactComponent as ShoppingcartIcon } from '@obeta/assets/icon/shoppingCart/warenkorb_1.svg'
import { formatData } from '@obeta/utils/lib/data-formatter/formatData'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useActiveCartId } from '@obeta/data/lib/hooks/useActiveCartId'
import { useArticleImport } from '@obeta/data/lib/hooks/useArticleImport'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useAddItemsToCart } from '@obeta/data/lib/hooks/useAddItemsToCart'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { Modal } from '@obeta/components/lib/modal/Modal'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography, ButtonBase } from '@mui/material'

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export const CartImportPage: React.FC = () => {
  const { hasPermission, isLoggedIn, singleCartMode } = useContext(UserContext)
  const purchasePricesVisible = hasPermission(AvailablePermissions.showPurchasePrices)
  const history = useHistory()

  const [showCartPicker, setShowCartPicker] = useState(false)
  const [selectedCartId, setSelectedCartId] = useState<ShoppingCartV2['id'] | undefined>(undefined)
  const { selectedStore, user: userv2 } = useUserSelectedStore()
  const queryParams = useQuery()
  //not yet finally decided
  // const isBelowMd = useMediaQuery(belowMdQuery)
  // const elementsPerRow = isBelowMd ? 1 : 2
  const elementsPerRow = 1
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const articleData = useArticleImport(
    queryParams.get('articleIds') || '',
    queryParams.get('amounts') || ''
  )

  const activeCartId = useActiveCartId()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = carts.find((cart) => cart.id === selectedCartId)

  const userSelectedCart = useSelectedCart()

  const closeCartSelectionMenu = (cart?: ShoppingCartV2) => {
    if (cart) addArticlesToCart(cart.id)
    setShowCartPicker(false)
  }

  function cartPickerNecessary() {
    if (!singleCartMode) {
      setShowCartPicker(true)
    } else if (activeCartId) {
      addArticlesToCart(activeCartId)
    }
  }

  const { loading: importing, add: addItemsToCart } = useAddItemsToCart({
    onSuccess: () => {
      setShowSuccess(true)
    },
  })

  const addArticlesToCart = (cartId: ShoppingCartV2['id']) => {
    if (!articleData.length) return

    setSelectedCartId(cartId)
    addItemsToCart(
      cartId,
      articleData.map((a) => {
        return {
          sapId: a.articleId,
          amount: a.amount,
          discount: undefined,
        }
      })
    )
  }

  const finishImport = () => {
    if (!selectedCartId) {
      // nothing to finish in this case. should be considered as bug
      return
    }

    setShowSuccess(false)
    history.push('/carts/' + selectedCartId)
    // TODO: remove indicator, selected card, "import started" flag.
  }

  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 300,
      minHeight: 50,
    })
  )

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const articles = articleData
    let articleSlice = articles.slice(
      index * elementsPerRow,
      index * elementsPerRow + elementsPerRow
    )
    const missingToFullRow = elementsPerRow - articleSlice.length
    if (missingToFullRow !== 0) {
      articleSlice = articleSlice.concat(new Array(missingToFullRow).fill(undefined))
    }
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {() => (
          <div className="articlelist__singleelrow" style={style} key={key}>
            {articleSlice.map((articleDataEl, idx) => {
              const article = articleDataEl?.article
              // todo: why amount and price are not used ==> clarify or remove both lines
              // const amount = articleDataEl?.amount
              // const price = articleDataEl?.price
              if (!article) {
                return (
                  <div className="grid articlelistitem articlelistitem--dummy " key={idx}>
                    <div className="row">
                      <div className="articlelistitem__image articlelistitem--horizontal">
                        <TextSkeleton height="100%" width="100%" />
                      </div>
                      <div className="articlelistitem__metawrap">
                        <TextSkeleton height="1.5rem" width="90%" />
                        <TextSkeleton height="1rem" width="50%" sx={{ marginTop: '1rem' }} />
                      </div>
                    </div>
                  </div>
                )
              }

              if (cache.getHeight(index, 0) === 116) {
                cache.clear(index, 0)
              }

              return (
                <SearchResultItem
                  carts={carts}
                  selectedCart={userSelectedCart}
                  isLoggedIn={isLoggedIn}
                  user={userv2}
                  mobile={false}
                  tablet={false}
                  key={article.sapId}
                  product={article}
                  selectedStore={selectedStore}
                  selected={false}
                  onChange={() => {
                    //
                  }}
                  productId={article.sapId}
                />
              )
            })}
          </div>
        )}
      </CellMeasurer>
    )
  }

  let isLoading = false
  articleData.forEach((artDta) => {
    if (artDta.price === undefined) {
      isLoading = true
    }
  })

  const addToCartButton = isLoading ? (
    <div className="scan__tocartbutton__wrapper">
      <TextSkeleton height="3rem" width="100%" />
    </div>
  ) : (
    <div className="scan__tocartbutton__wrapper">
      <div
        onClick={(event) => {
          event.stopPropagation()
          cartPickerNecessary()
        }}
        key="tocart-button"
        className="scan__tocartbutton"
      >
        <ShoppingcartIcon className="shopingCartIcon" />
        <span>Artikel in Warenkorb legen</span>
      </div>
    </div>
  )

  return (
    <div className="shoppingcartimport">
      <ReactRouterParamsProvider>
        <PageContent>
          <div className="shoppingcartimport__content">
            {showSuccess && selectedCartId && (
              <Modal fitContent open={showSuccess} onClose={finishImport}>
                <div className="alertWrapper">
                  <Typography variant="body1">
                    Die Artikel wurden erfolgreich in den Warenkorb {selectedCart?.name} gelegt.
                  </Typography>
                  <div className="btnContainer">
                    <ButtonBase onClick={finishImport}>
                      <Typography className="alertOkBtn" variant="bodyBold">
                        OK
                      </Typography>
                    </ButtonBase>
                  </div>
                </div>
              </Modal>
            )}
            {importing && (
              <Modal fitContent open={importing}>
                <div className="importingLoader" key="importing-articles">
                  <CircularProgress className="spinner" />
                </div>
              </Modal>
            )}
            {showCartPicker && (
              <MenuWrapper show={showCartPicker} onMenuClosed={closeCartSelectionMenu}>
                <CartSelectionMenu
                  context={CART_SELECTION_CONTEXT.IMPORT_ARTICLES}
                  onCloseClick={closeCartSelectionMenu}
                  onCartSelected={(cart) => {
                    closeCartSelectionMenu(cart)
                  }}
                ></CartSelectionMenu>
              </MenuWrapper>
            )}
            <div className="importarticle__meta">
              <h1>Scan Details</h1>
              <div>
                <div className="valueitem">
                  <span>Positionen</span>
                  {articleData.length}
                </div>
                {purchasePricesVisible && (
                  <div className="valueitem">
                    <span>Gesamtwert</span>
                    {isLoading ? (
                      <TextSkeleton height="0.375rem" width="7rem" />
                    ) : (
                      formatData({
                        count: articleData.reduce((curry, el) => curry + (el.price || 0), 0),
                      })
                    )}
                  </div>
                )}
              </div>
              {addToCartButton}
            </div>
            <div className="importarticle__list">
              <h1>Alle gescannten Artikel</h1>
              {articleData && (
                <WindowScroller scrollElement={window}>
                  {({ height, onChildScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <List
                          deferredMeasurementCache={cache}
                          autoHeight
                          height={height}
                          overscanRowCount={5}
                          rowCount={articleData.length}
                          rowHeight={cache.rowHeight}
                          estimatedRowSize={cache.defaultHeight}
                          rowRenderer={rowRenderer}
                          width={width}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          className="articlelist__elements"
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              )}
            </div>
          </div>
        </PageContent>
      </ReactRouterParamsProvider>
    </div>
  )
}
