import React, { useRef, useState } from 'react'
import { OBETA_WWW_URL } from '@obeta/utils/lib/config'
import useResizeObserver from '@react-hook/resize-observer'
import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import styles from './Turntable.module.scss'
import clsx from 'clsx'

interface Props {
  images: string[]
  loadingImage: string
  className?: string
}
let lastknownTouchX: number | undefined = undefined

export const Turntable = React.forwardRef<HTMLDivElement, Props>(function Turntable(props, ref) {
  const [dim, setDim] = useState({ width: 100, height: 100 })
  const [outerDim, setOuterDim] = useState({ width: 100, height: 100 })
  const [active360Index, setActive360Index] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const swipeEvent = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const images = props.images
    if (!images) {
      return
    }
    const screenMiddle = window.innerWidth / 2
    const lastX = lastknownTouchX || screenMiddle
    const currentX = event.changedTouches[0].clientX
    //give it some space before moving
    if (Math.abs(currentX - lastX) < 10) return
    const dir = currentX > lastX ? 'right' : 'left'

    let nextActiveSlide = dir === 'right' ? active360Index + 1 : active360Index - 1
    if (nextActiveSlide < 0) {
      nextActiveSlide = props.images.length - 1
    }
    setActive360Index(nextActiveSlide % props.images.length)
    lastknownTouchX = currentX
  }
  function imageLoaded(e: React.SyntheticEvent<HTMLImageElement>) {
    const img = e.target as HTMLImageElement
    setDim({
      width: img.naturalWidth,
      height: img.naturalHeight,
    })
  }

  useResizeObserver(containerRef, (entry) => {
    // Dont't ever use ref to get its element contentRect
    // use "entries" instead
    // (it possible that ref element won't have latest updates when this callback is called)
    const clientRect = entry.contentRect
    const width = clientRect.width
    const height = clientRect.height
    if (outerDim.width !== width || outerDim.height !== height) {
      setOuterDim({
        width: width,
        height: height,
      })
    }
  })

  return (
    <div
      ref={composeReactRefs(containerRef, ref)}
      className={clsx(styles.turntableimage, props.className)}
      style={{ maxWidth: dim.width, maxHeight: dim.height }}
      onTouchMove={swipeEvent}
    >
      <div
        className={styles['turntableimage__framewrapper']}
        style={{
          marginLeft: -(active360Index * outerDim.width),
          width: props.images.length * outerDim.width,
          height: dim.height,
          overflow: 'hidden',
        }}
      >
        {props.images.map((url, idx) => {
          return (
            <div
              key={url}
              className={styles['turntableimage__frame']}
              style={{
                backgroundImage: `url(${OBETA_WWW_URL(url)})`,
                width: `${100 / props.images.length}%`,
              }}
            ></div>
          )
        })}
      </div>
      <img src={props.loadingImage} onLoad={imageLoaded} alt="" />
    </div>
  )
})
