import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { TabFilters } from '@obeta/components/lib/tab-filters/TabFilters'
import { useNewsProvider } from '../../hooks/useNewsProvider'
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { IconButton } from '@mui/material'
import styles from './ControlSection.module.scss'

// Hooks
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'

// UI
import { Dropdown2 } from '@obeta/components/lib/dropdown2/Dropdown2'
import { MobileRadioGroupPopover } from '@obeta/components/lib/mobile-radio-group-popover/MobileRadioGroupPopover'
import { SearchField } from '@obeta/components/lib/search-field/SearchField'

export enum NewsCategory {
  All = 'Alle anzeigen',
  Produktnews = 'Produktnews',
  Branchennews = 'Branchennews',
  Onlinewelt = 'Onlinewelt',
  Extras = 'Extras',
}

export const ControlSection: FC = () => {
  const { updateFilters, filters, updateSort, sort, meta } = useNewsProvider()
  const { mobile } = useBreakpoints()
  const { t } = useTranslation()
  const [isOpenMobileSort, setOpenMobileSort] = useState(false)

  const [searchText, setSearchText] = useState<string>('')

  useDebouncedEffect(
    () => {
      updateSearchNewsFilter(searchText)
    },
    [searchText],
    500
  )

  const onSortNewsBy = (sortBy: string) => updateSort(sortBy)

  const sortByOpts: DropdownOption[] = [
    { value: 'startDate:desc', label: t('NEWS.ORDER_BY.NEW') },
    { value: 'title:asc', label: t('NEWS.ORDER_BY.ALPHABETICAL') },
    { value: 'startDate:asc', label: t('NEWS.ORDER_BY.OLD') },
    { value: 'sortDate:desc', label: t('NEWS.ORDER_BY.RELEVANT') },
  ]

  const filterTabsOptions = [
    {
      label: t('NEWS.ALL_NEWS_TITLE'),
      value: NewsCategory.All,
      disabled: meta.All === 0,
      total: meta.All,
    },
    {
      label: t('NEWS.PRODUCT_NEWS_TITLE'),
      value: NewsCategory.Produktnews,
      disabled: meta.Produktnews === 0,
      total: meta.Produktnews,
    },
    {
      label: t('NEWS.BRANCHEN_NEWS_TITLE'),
      value: NewsCategory.Branchennews,
      disabled: meta.Branchennews === 0,
      total: meta.Branchennews,
    },
    {
      label: t('NEWS.ONLINE_WORLD_NEWS_TITLE'),
      value: NewsCategory.Onlinewelt,
      disabled: meta.Onlinewelt === 0,
      total: meta.Onlinewelt,
    },
    {
      label: t('NEWS.EXTRAS_NEWS_TITLE'),
      value: NewsCategory.Extras,
      disabled: meta.Extras === 0,
      total: meta.Extras,
    },
  ]

  const updateNewsCategoryFilter = (value: string) => {
    updateFilters({ category: value === NewsCategory.All ? '' : value })
  }

  const updateSearchNewsFilter = (value: string) => updateFilters({ search: value })

  return (
    <div className={styles.controlWrapper}>
      <div className={styles.searchWrapper}>
        <SearchField
          placeholder={t('NEWS.NEWS_SEARCH_PLACEHOLDER')}
          value={searchText}
          onChange={setSearchText}
          onReset={() => setSearchText('')}
        />
        {mobile && (
          <>
            <IconButton onClick={() => setOpenMobileSort(true)} className={styles.mobileSortBtn}>
              <FilterAltIcon />
            </IconButton>
            <MobileRadioGroupPopover
              label={t('SEARCH.SORT_BY.TITLE')}
              open={isOpenMobileSort}
              options={sortByOpts}
              value={sort}
              onClose={() => setOpenMobileSort(false)}
              onSubmit={(value) => {
                onSortNewsBy(value as string)
                setOpenMobileSort(false)
              }}
            />
          </>
        )}
      </div>
      <div className={styles.sortFiltersWrapper}>
        <div className={styles.filtersWrapper}>
          <TabFilters<string>
            spaceBetween={24}
            options={filterTabsOptions}
            onChangeTab={updateNewsCategoryFilter}
            activeTab={filters.category || NewsCategory.All}
          />
        </div>
        {!mobile && (
          <Dropdown2
            label={t('SEARCH.SORT_BY.TITLE')}
            options={sortByOpts}
            value={sort}
            onChange={(value: string) => onSortNewsBy(value)}
          />
        )}
      </div>
    </div>
  )
}
