import { FC } from 'react'
import { Typography } from '@mui/material'
import styles from './styles.module.scss'

import { ReactComponent as WarningIcon } from '@obeta/assets/icon/designsystem/warning_amber.svg'

export const StaticMessage: FC = () => {
  return (
    <div className={styles['staticMessage']}>
      <WarningIcon className={styles['icon']} />
      <Typography variant="body">
        Wir bitten um einen sorgsamen Umgang mit ausgeliehenen Artikeln. Bei Rückgabe von
        verschmutzten oder anderweitig nicht ordentlichen Artikeln muss leider eine{' '}
        <strong>Reinigungspauschale in Höhe von 22,-€ netto</strong> in Rechnung gestellt werden.
      </Typography>
    </div>
  )
}
