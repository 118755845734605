import { ButtonBase, List, ListItem } from '@mui/material'
import { AutoSizer, List as VirtualizedList, ListRowProps } from 'react-virtualized'
import { useTranslation } from 'react-i18next'
import { DispatchFiltersAction } from '../reducer'
import { useStackHistory } from '@obeta/components/lib/stack'
import { EtimSlides } from './types'
import { ReactComponent as ChevronRight } from '@obeta/assets/icon/chevron_right20x20.svg'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { SlideWrap } from '@obeta/components/lib/stack/SlideWrap'
import { Header } from '@obeta/components/lib/stack/Header'
import styles from './ClassesSlide.module.scss'
import { Size } from '../Size'
import { EtimSkeletons } from './EtimSkeletons'
import { Etim } from '@obeta/models/lib/models/Search'

interface IEtimClassesProps {
  dispatchEtimAction: DispatchFiltersAction
  selectedEtimClass: Etim | null
}

export const EtimClasses: React.FC<IEtimClassesProps> = (props) => {
  const { dispatchEtimAction, selectedEtimClass } = props
  const stackHistory = useStackHistory()
  const rowHeight = useRemToPx(parseInt(styles.etimClassRowHeight))

  const {
    articleSearchFilter: { etimClasses },
  } = useArticleFilterData()

  const { t } = useTranslation()

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    if (!index) {
      return (
        <ListItem disablePadding key={'reset'} className={styles.itemWrap}>
          <RadioItem
            id={'reset'}
            label={t<string>('COMMON.ALL')}
            checked={!selectedEtimClass}
            onChange={() => {
              dispatchEtimAction({
                type: 'resetEtims',
              })
            }}
          />
        </ListItem>
      )
    }

    const etim = etimClasses[index - 1]
    if (!etim) {
      return null
    }

    const selectEtim = () => {
      dispatchEtimAction({
        type: 'selectEtimClass',
        payload: {
          etim,
        },
      })
    }

    return (
      <ListItem disablePadding key={key} className={styles.itemWrap} style={style}>
        <RadioItem
          id={etim.value}
          label={etim.meta.name}
          checked={selectedEtimClass?.id === etim.value}
          onChange={selectEtim}
          rightAdornment={<Size className={styles.size} size={etim.count} />}
        />
        <ButtonBase
          className={styles.toEtimAttrs}
          onClick={() => {
            stackHistory.push(EtimSlides.EtimAttributes)
            selectEtim()
          }}
        >
          <ChevronRight />
        </ButtonBase>
      </ListItem>
    )
  }

  return (
    <SlideWrap>
      <Header title={t<string>('SEARCH.ARTICLE_CLASSES')} />
      {etimClasses.length > 0 ? (
        <List disablePadding className={styles.list}>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <VirtualizedList
                  rowCount={etimClasses.length + 1}
                  rowRenderer={rowRenderer}
                  rowHeight={rowHeight}
                  width={width}
                  height={height}
                />
              )
            }}
          </AutoSizer>
        </List>
      ) : (
        <EtimSkeletons />
      )}
    </SlideWrap>
  )
}
