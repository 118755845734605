import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import {
  ObetaRentLocationEntity,
  ILocationOpeningHours,
  IRentArticleAccessories,
  ERentalCurrency,
  ERentalDuration,
  ObetaRentLocations,
} from './graphqlTypes'
import {
  IAddress,
  ICategory,
  ILocation,
  IRentArticle,
} from '@obeta/models/lib/models/RentArticle/types'
import { ALL_CATEGORIES, ALL_LOCATIONS } from './constants'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { IRentArticleWithAdditionalData } from './useRentArticlesData'
import { ShopRoutes } from '@obeta/utils/lib/variables'

const mapRentalCurrency: Record<ERentalCurrency, string> = {
  [ERentalCurrency.Eur]: '€',
}

const mapRentalDuration: Record<ERentalDuration, string> = {
  [ERentalDuration.Day]: 'RENTABLE_ARTICLES.RENT_DURATION.DAY',
  [ERentalDuration.Week]: 'RENTABLE_ARTICLES.RENT_DURATION.WEEK',
}

function prepareLocations(rentableArticles: IRentArticleWithAdditionalData[]): ILocation[] {
  const locations = new Set<string>()

  rentableArticles.forEach((rentableArticleEntity) => {
    rentableArticleEntity.attributes.rental_locations.data.forEach((locationEntity) => {
      if (locationEntity.attributes.address) {
        locations.add(locationEntity.attributes.address.name1)
      }
    })
  })

  const locationsArray: ILocation[] = Array.from(locations).map((value) => ({
    type: 'location',
    value,
  }))

  locationsArray.unshift(ALL_LOCATIONS)

  return locationsArray
}

function prepareCategories(rentableArticles: IRentArticleWithAdditionalData[]): ICategory[] {
  const categories: Record<string, number> = {}

  rentableArticles.forEach((rentableArticleEntity) => {
    const categoryName = rentableArticleEntity.attributes?.rental_category?.data?.attributes?.name
    if (categoryName) {
      categories[categoryName] = (categories[categoryName] || 0) + 1
    }
  })

  const categoriesArray: ICategory[] = Object.entries(categories).map(([value, count]) => ({
    type: 'category',
    value,
    count,
  }))

  categoriesArray.unshift({
    ...ALL_CATEGORIES,
    count: rentableArticles.length,
  })

  return categoriesArray
}

function prepareOpenHours(openHours: ILocationOpeningHours[]): IAddress['workingHours'] {
  /** Strapi's time has a format: "06:30:00.000", so we need to conver it to "6:30" */
  const prepareStrapiTime = (op: ILocationOpeningHours) => {
    const fromTime = new Date(`1970-01-01T${op.from}`)
    const toTime = new Date(`1970-01-01T${op.to}`)

    const fromHours = fromTime.getHours()
    const fromMinutes = fromTime.getMinutes().toString().padStart(2, '0')
    const toHours = toTime.getHours()
    const toMinutes = toTime.getMinutes().toString().padStart(2, '0')

    return {
      weekday: op.weekday,
      from: `${fromHours}:${fromMinutes}`,
      to: `${toHours}:${toMinutes}`,
    }
  }

  const openHoursFormattedTime = openHours.map((op) => {
    /** Assume that strapi's time is always longer than 5 chars */
    const isStrapiOp = op.from.length > 5

    if (isStrapiOp) {
      return prepareStrapiTime(op)
    } else {
      return op
    }
  })
  return formatOpeningHours(openHoursFormattedTime)
}

function prepareAddress(rentLocation: ObetaRentLocationEntity): IAddress {
  return {
    name: rentLocation.attributes.address.name1 || '',
    address: [
      rentLocation.attributes.address.street || '',
      rentLocation.attributes.address.houseNumber || '',
      rentLocation.attributes.address.zipCode || '',
      rentLocation.attributes.address.city || '',
    ]
      .join(' ')
      .trim(),
    workingHours: prepareOpenHours(rentLocation.attributes.openingHours),
    tel: rentLocation.attributes.phoneNumber || '',
    mail: rentLocation.attributes.mail || '',
  }
}

function prepareAddresses(rentLocations: ObetaRentLocations): IRentArticle['addresses'] {
  return rentLocations.data.reduce<IRentArticle['addresses']>(
    (prev, location) => {
      if (location.attributes.isDefaultStore) {
        prev.main = prepareAddress(location)
      } else {
        prev.collapsible = prev.collapsible || []
        prev.collapsible.push(prepareAddress(location))
      }

      return prev
    },
    { main: null, collapsible: null }
  )
}

function preparePrice(value: number, currency: string, duration: string, t: TFunction) {
  return `${normalizePrice(value, mapRentalCurrency[currency])} / ${t(mapRentalDuration[duration])}`
}

function prepareAccessories(
  accessories: IRentArticleAccessories[],
  t: TFunction
): IRentArticle['accessories'] {
  if (!accessories.length) {
    return null
  }

  return accessories.map(({ description, rental_duration, rental_price, rental_currency }) => ({
    description,
    price: preparePrice(rental_price, rental_currency, rental_duration, t),
  }))
}

function prepareRentableArticles(
  rentableArticles: IRentArticleWithAdditionalData[],
  t: TFunction
): IRentArticle[] {
  return rentableArticles.map((rentableArticleEntity) => {
    const {
      id,
      oxomiId,
      supplierId,
      productImageUrl,
      attributes: {
        title,
        dehaId,
        sapId,
        description,
        media,
        rental_category,
        rental_price,
        rental_currency,
        rental_locations,
        rental_duration,
        accessories,
      },
    } = rentableArticleEntity

    return {
      id,
      sapId,
      dehaId,
      title: title,
      category: rental_category.data?.attributes.name || '',
      description: description,
      price: preparePrice(rental_price, rental_currency, rental_duration, t),
      articleUrl: sapId ? `${ShopRoutes.ArticleDetailPage}/${sapId}` : null,
      accessories: prepareAccessories(accessories, t),
      addresses: prepareAddresses(rental_locations),
      strapiImageUrl: media.data?.attributes?.url || '',
      productImageUrl: productImageUrl || '',
      oxomiId: oxomiId || '',
      supplierId: supplierId || '',
    }
  })
}

export const usePrepareData = (
  rentArticlesDataRaw: IRentArticleWithAdditionalData[] | null,
  select: TDataSelector
): IPreparedData => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!rentArticlesDataRaw) {
      return {
        preparedLocations: null,
        preparedCategories: null,
        preparedRentableArticles: null,
      }
    }
    return {
      preparedLocations: prepareLocations(rentArticlesDataRaw),
      preparedCategories: prepareCategories(rentArticlesDataRaw),
      preparedRentableArticles: select(prepareRentableArticles(rentArticlesDataRaw, t)),
    }
  }, [rentArticlesDataRaw, select, t])
}

export interface IPreparedData {
  preparedLocations: ILocation[] | null
  preparedCategories: ICategory[] | null
  preparedRentableArticles: IRentArticle[] | null
}

export type TDataSelector = (rentArticles: IRentArticle[]) => IRentArticle[]
