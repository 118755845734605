import { ConsolidatedHistory } from '@obeta/models/lib/models/History'
import { getURLSearchParamsByLocationSearch } from './getURLSearchParamsByLocationSearch'
import { updateURLSearchParams } from './updateURLSearchParams'

export const updateDetailsFilters = (
  id: string,
  url: string,
  history: ConsolidatedHistory<unknown>,
  key: string,
  value: string | undefined
) => {
  // Extract url search params
  /* eslint-disable @typescript-eslint/no-explicit-any */
  let urlSearchParams: { [key: string]: any } = getURLSearchParamsByLocationSearch(
    history.location.search
  )

  if (urlSearchParams[key]) {
    // Update key value in filter list
    urlSearchParams[key] = value
  } else {
    // Add new key value to filter list
    urlSearchParams = {
      ...urlSearchParams,
      [key]: value,
    }
  }

  updateURLSearchParams(urlSearchParams, history, `/${url}/${id}`, 'id')
}
