import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useOfferDetailsContext } from '@obeta/data/lib/stores/useOfferDetailsContext'
import styles from './OfferItemsFilterBar.module.scss'
import { TabFilters } from '../tab-filters/TabFilters'
import {
  INITIAL_OFFER_DETAILS_FILTERS,
  OfferDetailsFilter,
  OfferDetailsURLSearchParams,
} from '@obeta/models/lib/models'
import { getURLSearchParamsValue, updateDetailsFilters } from '@obeta/utils/lib/virtualized-list'

type OfferItemsFilterBarProps = {
  id: string
}

export const OfferItemsFilterBar: FC<OfferItemsFilterBarProps> = (props) => {
  const { id } = props

  const history = useHistory()
  const { notCancelledItemCount, totalItemCount } = useOfferDetailsContext()
  const { t } = useTranslation()

  const filterTabsOptions = [
    {
      label: t('OFFERS.FILTER.SHOW_ALL'),
      value: 'all',
      disabled: totalItemCount === 0,
      total: totalItemCount,
    },
    {
      label: t('OFFERS.FILTER.SHOW_ACTIVE'),
      value: 'notCancelled',
      disabled: notCancelledItemCount === 0,
      total: notCancelledItemCount,
    },
  ]

  const onChange = useCallback(
    (value: string) => {
      const isActive = value === 'notCancelled' ? 'true' : undefined
      updateDetailsFilters(id, 'offer-details', history, 'isActive', isActive)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, id]
  )

  return (
    <Box className={styles.wrapper}>
      <TabFilters<string>
        activeTab={
          getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
            'isActive',
            history?.location?.search ?? '',
            INITIAL_OFFER_DETAILS_FILTERS
          ) === 'true'
            ? 'notCancelled'
            : 'all'
        }
        options={filterTabsOptions}
        spaceBetween={16}
        onChangeTab={onChange}
      />
    </Box>
  )
}
