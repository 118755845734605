import { CSSProperties } from 'react'
import { ReactComponent as ChevronRight } from '@obeta/assets/icon/chevron_right20x20.svg'
import styles from './Breadcrumbs.module.scss'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Scrollbar, Mousewheel } from 'swiper/modules'
// Import Swiper styles
import '@obeta/assets/theme/swiper.scss'
import { Stack } from '@mui/material'

SwiperCore.use([Scrollbar, Mousewheel])

interface IBaseBreadcrumbData {
  id: string | number
}

interface IBreadcrumbsProps<T extends IBaseBreadcrumbData> {
  breadcrumbs: T[]
  leftAdornment?: JSX.Element
  renderBreadcrumb: (d: T, idx: number) => JSX.Element
  className?: string
  style?: CSSProperties
}

export const Breadcrumbs = <T extends IBaseBreadcrumbData>(props: IBreadcrumbsProps<T>) => {
  const { breadcrumbs, leftAdornment, renderBreadcrumb, className } = props

  return (
    <Swiper
      direction="horizontal"
      slidesPerView={'auto'}
      freeMode={true}
      className={clsx(styles.root, className)}
      scrollbar={false}
      spaceBetween={8}
    >
      {leftAdornment && (
        <SwiperSlide key={'leftadornment'} className={styles.crumb}>
          {leftAdornment}
        </SwiperSlide>
      )}
      {breadcrumbs.map((b, idx) => {
        const breadcrumb = renderBreadcrumb(b, idx)
        const last = idx === breadcrumbs.length - 1
        return (
          <SwiperSlide key={b.id} className={styles.crumb}>
            <Stack direction="row" gap=".5rem">
              {breadcrumb}
              {!last && <ChevronRight className={styles.chevronRight} />}
            </Stack>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
