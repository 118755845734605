import { useEventCallback } from '@mui/material'
import { SearchResultItemBase } from '@obeta/components/lib/product-card'
import { ISearchResultItemProps } from '@obeta/components/lib/product-card/makeSearchResultItem'
import { noop } from '@obeta/utils/lib/noop'

export function SearchResultItemBaseWrapper(props: ISearchResultItemProps) {
  const {
    onClick = noop,
    onTitleClick = noop,
    onAmountChange = noop,
    onAddToCart = noop,
    ...others
  } = props

  const handleClick = useEventCallback(onClick)
  const handleTitleClick = useEventCallback(onTitleClick)
  const handleAmountChange = useEventCallback(onAmountChange)
  const handleAddToCart = useEventCallback(onAddToCart)

  // Memoize other callbacks if they are used in the future.

  return (
    <SearchResultItemBase
      onClick={handleClick}
      onTitleClick={handleTitleClick}
      onAmountChange={handleAmountChange}
      onAddToCart={handleAddToCart}
      {...others}
    />
  )
}
