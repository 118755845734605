import { FC } from 'react'

// Styles
import styles from './ListSkeletons.module.scss'

// UI
import { HorizontalDummy } from '../product-card/dummies/HorizontalDummy'

export const ListSkeletons: FC = () => {
  return (
    <div className={styles.listSkeletons}>
      <HorizontalDummy />
      <HorizontalDummy />
      <HorizontalDummy />
    </div>
  )
}
