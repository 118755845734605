import dayjs from 'dayjs'

// Models
import { FeedbackValue } from '@obeta/models/lib/models/Feedback/Feedback'

// Utils
import { trackCustom } from './tracking'

/**
 * Checks if feedback element should be hidden if within session threshold (48h).
 * @param id Id key
 * @returns Boolean
 */
export const isActive = (id: string): boolean => {
  const currentDate = dayjs(new Date().toISOString())
  const storedFeedbackDate = localStorage.getItem(id) as string
  const storedFeedbackDateToDelete = storedFeedbackDate
    ? dayjs(new Date(storedFeedbackDate)).add(48, 'h')
    : currentDate
  if (storedFeedbackDateToDelete > currentDate) {
    return false
  } else {
    localStorage.removeItem(id)
    return true
  }
}

/**
 * Send feedback to datadog.
 * @param id Id key
 * @param value Evulation value
 * @param comment Optional comment
 * @param params Optional params
 */
export const sendFeedback = (
  id: string,
  value: FeedbackValue,
  comment: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
) => {
  trackCustom(id, {
    feedback: {
      comment,
      value,
    },
    params,
  })
}

/**
 * Save feedback time into LocalStorage by feedback id key.
 * @param id Id key
 */
export const saveFeedbackTime = (id: string) => {
  localStorage.setItem(id, new Date().toISOString())
}
