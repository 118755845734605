import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { ArticleMainInfo } from './ArticleMainInfo'
import { ArticleHeader } from './ArticleHeader'
import { ArticleMeta } from './ArticleMeta'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { TechnicalSpecifications } from './TechnicalSpecifications'
import { StickyHeader } from './StickyHeader'
import { ArticleDescription } from './ArticleDescription'
import { ArticleAccessories } from './ArticleAccessories'
import { ArticleDownloads } from './ArticleDownloads'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { ProductsCarousel } from './ProductsCarousel'
import { ArticleCategories } from './ArticleCategories'
import { ProductProvider } from './ProductProvider'
import { SectionProvider, SectionWrapper } from './SectionProvider'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useArticleDetails } from '@obeta/data/lib/hooks/useArticleDetailsData'
import { Box, Typography } from '@mui/material'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { ArticleStickyHeaderProvider } from './wrappers/ArticleStickyHeaderProvider'
import oxomiCatalogStyles from '@obeta/components/lib/oxomi/OxomiCatalogs.module.scss'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { useTranslation } from 'react-i18next'
import { waitForField, waitForResolve } from '@obeta/utils/lib/waitFor'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { useArticleDetailsProductMedia } from '@obeta/data/lib/hooks/useArticleDetailsProductMedia'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { AppLauncher } from '@capacitor/app-launcher'
import {
  extractDownloadCatalogParams,
  extractOpenCatalogParams,
} from '@obeta/utils/lib/oxomi/overrideOxomiHandlersHelpers'
import styles from './ArticleDetailsPage.module.scss'

export const ArticleHeaderWrap: React.FC<{
  article: ProductAggregate | undefined
  mobile: boolean
  desktop: boolean
  titleClassName?: string
}> = ({ article, mobile, desktop, titleClassName }) => {
  return (
    <div className={clsx(styles.header)}>
      <ArticleHeader mobile={mobile} className={titleClassName} />
      {mobile && (
        <Box className={titleClassName}>
          <LightGrayDivider />
        </Box>
      )}
      <ArticleMeta article={article} desktop={desktop} titleClassName={titleClassName} />
    </div>
  )
}

export const ArticleDetailPage: React.FC = () => {
  const article = useArticleDetails()
  const { selectedStore } = useUserSelectedStore()
  const p = useParams()
  const { id: articleId } = p
  const { mobile, tabletAll, tabletWide, tablet, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const showOxomiCatalogs = useFeatureToggle('ShowOxomiCatalogs')
  const isNativeUITarget = isUiTarget('app')
  const oxomiCatalogContainerRef = useRef<HTMLDivElement | null>(null)

  useOxomiIntegration({
    documentId: '',
  })
  // useOxomiProductEnhancement()
  const { oxomiAttachments } = useArticleDetailsProductMedia(
    {},
    article?.oxomiId,
    article?.supplierId
  )

  const isOxomiCatalogVisible = showOxomiCatalogs && oxomiAttachments.length > 0

  useEffect(() => {
    const fetchOxomiResponse = async () => {
      if (!article || !window.oxomi || !showOxomiCatalogs) {
        return
      }
      const supplierId = article?.supplierId as string
      const supplierIdNumber = supplierId.startsWith('L') ? supplierId.slice(1) : supplierId
      const oxomi = await waitForField<OxomiGlobal>({ field: 'oxomi' })
      await waitForResolve({ callback: oxomi.isInitialized })

      window.oxomi.itemPagesWithDownload({
        supplierNumber: supplierIdNumber,
        supplierItemNumber: article?.oxomiId,
        target: '#oxomi-catalog-target_page',
        showDetails: true,
      })
    }

    fetchOxomiResponse()
  }, [article, articleId, showOxomiCatalogs, oxomiAttachments.length])

  useEffect(() => {
    if (!isNativeUITarget) return

    const oxomiCatalog = oxomiCatalogContainerRef.current

    const previewOxomiCatalogNative = (target: HTMLAnchorElement) => {
      const { catalog, query, page } = extractOpenCatalogParams(target.outerHTML)
      window.oxomi.openCatalog({ catalog, query, page })
    }

    const downloadOxomiCatalogNative = async (target: HTMLAnchorElement) => {
      const { actionUrl, portal, user, accessToken, catalog } = extractDownloadCatalogParams(target)
      await AppLauncher.openUrl({
        url: `${actionUrl}?portal=${portal}&user=${user}&accessToken=${accessToken}&catalog=${catalog}`,
      })
    }

    let touchStartTime = 0
    let touchStartX = 0
    let touchStartY = 0
    const TOUCH_THRESHOLD = 250
    const MOVE_THRESHOLD = 20

    const handleOxomiCatalogTouchStart = (event: TouchEvent) => {
      touchStartTime = Date.now()
      touchStartX = event.touches[0].clientX
      touchStartY = event.touches[0].clientY
    }

    const handleOxomiCatalogTouchEnd = async (event: TouchEvent) => {
      const touchEndTime = Date.now()
      const touchDuration = touchEndTime - touchStartTime

      const touchEndX = event.changedTouches[0].clientX
      const touchEndY = event.changedTouches[0].clientY

      const distanceX = Math.abs(touchEndX - touchStartX)
      const distanceY = Math.abs(touchEndY - touchStartY)

      if (
        touchDuration < TOUCH_THRESHOLD &&
        distanceX < MOVE_THRESHOLD &&
        distanceY < MOVE_THRESHOLD
      ) {
        const target = event.target as HTMLAnchorElement

        const isOxomiImgPreviewLink = target.className === 'oxomi-link' && target.nodeName === 'IMG'
        const isOxomiPreviewIcon = target.className === 'oxomi-view-icon'
        const isOxomiPreviewBtn = target.text === 'Blätteransicht'

        const isOxomiDownloadButton = target.text === 'Download'
        const isOxomiDownloadIcon = target.className === 'oxomi-download-icon'

        if (target && (isOxomiImgPreviewLink || isOxomiPreviewIcon || isOxomiPreviewBtn)) {
          return previewOxomiCatalogNative(target)
        }
        if (target && (isOxomiDownloadButton || isOxomiDownloadIcon)) {
          return await downloadOxomiCatalogNative(target)
        }
      }
    }

    const preventDefaultClickEvent = (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
    }

    oxomiCatalog?.addEventListener('click', preventDefaultClickEvent, true)
    oxomiCatalog?.addEventListener('touchstart', handleOxomiCatalogTouchStart, true)
    oxomiCatalog?.addEventListener('touchend', handleOxomiCatalogTouchEnd, true)

    return () => {
      oxomiCatalog?.removeEventListener('click', preventDefaultClickEvent)
      oxomiCatalog?.removeEventListener('touchstart', handleOxomiCatalogTouchStart)
      oxomiCatalog?.removeEventListener('touchend', handleOxomiCatalogTouchEnd)
    }
  }, [isNativeUITarget, isOxomiCatalogVisible])

  return (
    <ArticleStickyHeaderProvider>
      <ProductProvider product={article}>
        <SectionProvider typographyHeaderVariant={desktop ? 'headline3Bold' : 'headline4Bold'}>
          <div className={styles.root}>
            <div className={clsx(styles.row, { [styles.rightOverflowing]: mobile })}>
              <ArticleHeaderWrap
                article={article}
                desktop={desktop || tabletWide}
                mobile={mobile}
                titleClassName={clsx({ [styles.titleSpacing]: mobile })}
              />
              {!mobile && <LightGrayDivider />}
              <ArticleMainInfo
                selectedStore={selectedStore}
                article={article}
                defaultStoreId={selectedStore?.id}
                noRightOverFlowClass={clsx({ [styles.titleSpacing]: mobile })}
              />
            </div>
            {(desktop || tabletWide) && <StickyHeader />}
            <TechnicalSpecifications article={article} />
            {Boolean(article?.articleDescription ?? article?.catalogDescription) && (
              <ArticleDescription
                description={article?.articleDescription ?? ''}
                catalogDescription={article?.catalogDescription ?? ''}
              />
            )}
            <Box className={clsx(styles.row, { [styles.rightOverflowing]: mobile })}>
              <ArticleAccessories
                selectedStore={selectedStore}
                mobile={mobile}
                tabletAll={tabletAll}
              />
            </Box>
            <ArticleDownloads article={article} />

            <Box className={clsx(styles.row, { [styles.rightOverflowing]: mobile })}>
              <ProductsCarousel
                type="recommendations"
                mobile={mobile}
                tabletAll={tabletAll}
                selectedStore={selectedStore}
                tablet={tablet}
              />
            </Box>

            <Box className={clsx(styles.row, { [styles.rightOverflowing]: mobile })}>
              <ArticleCategories
                article={article}
                titleClassName={clsx({ [styles.titleSpacing]: mobile })}
              />
            </Box>

            {showOxomiCatalogs && (
              <SectionWrapper
                available={oxomiAttachments.length > 0}
                id={ArticleDetailsSections.OxomiCatalog}
              >
                <Box className={clsx(styles.row)}>
                  <Typography variant={!desktop ? 'headline4Bold' : 'headline3Bold'}>
                    {t('ARTICLE_DETAIL.OXOMI_CATALOG')}
                  </Typography>
                  <div
                    className={clsx(
                      oxomiCatalogStyles.outerGrid,
                      oxomiCatalogStyles.noPadding,
                      oxomiCatalogStyles.marginTopOneRem
                    )}
                  >
                    <div
                      ref={oxomiCatalogContainerRef}
                      id="oxomi-catalog-target_page"
                      className={oxomiCatalogStyles.oxomiCatalogTarget}
                    ></div>
                  </div>
                </Box>
              </SectionWrapper>
            )}

            <Box
              className={clsx(styles.row, styles.coloredBackground, {
                [styles.rightOverflowing]: mobile,
              })}
            >
              <ProductsCarousel
                titleClassName={clsx({ [styles.titleSpacing]: mobile })}
                type="alternative"
                tablet={tablet}
                mobile={mobile}
                tabletAll={tabletAll}
                selectedStore={selectedStore}
              />
              <ProductsCarousel
                titleClassName={clsx({ [styles.titleSpacing]: mobile })}
                type="recommendations"
                tablet={tablet}
                mobile={mobile}
                tabletAll={tabletAll}
                selectedStore={selectedStore}
                crossSelling={true}
                onEnterViewport={() => trackCustom('visible-recommendation-cross-selling')}
                onRenderProducts={() => trackCustom('rendered-recommendation-cross-selling')}
              />
              <ProductsCarousel
                titleClassName={clsx({ [styles.titleSpacing]: mobile })}
                type="recommendations"
                tablet={tablet}
                mobile={mobile}
                tabletAll={tabletAll}
                selectedStore={selectedStore}
                upSelling={true}
                onEnterViewport={() => trackCustom('visible-recommendation-up-selling')}
                onRenderProducts={() => trackCustom('rendered-recommendation-up-selling')}
              />
            </Box>
          </div>
        </SectionProvider>
      </ProductProvider>
    </ArticleStickyHeaderProvider>
  )
}
