import React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { CheckboxItem } from '../../../../../checbox-radio-item/CheckboxRadioItem'
import { getCheckboxItemConfig } from '../../../../utils/getCheckboxItemConfig'
import { Controller, useFormContext } from 'react-hook-form'
import styles from './PriceTypeSection.module.scss'

export enum ListTypes {
  ListPrice = 'listPrice',
  CatalogPrice = 'catalogPrice',
  MetalSurcharge = 'metalSurcharge',
}

export const PriceTypeSection = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Box className={styles.wrapper}>
      <Controller
        defaultValue={true}
        name="priceType.listPrice"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box>
              <CheckboxItem
                label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.LIST_PRICE')}
                onChange={() => onChange(!value)}
                checked={value}
                {...getCheckboxItemConfig<ListTypes>(ListTypes.ListPrice)}
              />
            </Box>
          )
        }}
      />

      <Controller
        defaultValue={true}
        name="priceType.catalogPrice"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box>
            <CheckboxItem
              label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.CATALOG_PRICE')}
              onChange={() => onChange(!value)}
              checked={value}
              {...getCheckboxItemConfig<ListTypes>(ListTypes.CatalogPrice)}
            />
          </Box>
        )}
      />

      <Controller
        defaultValue={true}
        name="priceType.metalSurcharge"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box>
            <CheckboxItem
              label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.METAL_SURCHARGE')}
              onChange={() => onChange(!value)}
              checked={value}
              {...getCheckboxItemConfig<ListTypes>(ListTypes.MetalSurcharge)}
            />
          </Box>
        )}
      />
    </Box>
  )
}
