import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './OfferFlagItem.module.scss'

// Types
import { OfferFlag } from '@obeta/schema'

type Props = {
  flag: OfferFlag
}

export const OfferFlagItem: FC<Props> = (props) => {
  const { flag } = props

  const { t } = useTranslation()

  let colorClassName: string | undefined = undefined
  let text: string | undefined = undefined

  switch (flag) {
    case 'expired':
      colorClassName = styles.expired
      text = t('OFFERS.FLAGS.EXPIRED')
      break
    case 'expiresSoon':
      colorClassName = styles.expiresSoon
      text = t('OFFERS.FLAGS.EXPIRES_SOON')
      break
    case 'new':
      colorClassName = styles.new
      text = t('OFFERS.FLAGS.NEW')
      break
    case 'notExpired':
      colorClassName = styles.active
      text = t('OFFERS.FLAGS.ACTIVE')
      break
    default:
      break
  }

  if (!colorClassName && !text) {
    return null
  }

  return <Typography className={clsx(styles.flag, colorClassName)}>{text}</Typography>
}
