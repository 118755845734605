import { FC, useState } from 'react'

// Models
import { RadioGroupOption } from '@obeta/models/lib/models/UI'

// Styles
import styles from './MobileRadioGroupPopover.module.scss'

// UI
import { ActionBar } from '../action-bar/ActionBar'
import { Card } from '../card/Card'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { RadioGroup } from '../radio-group/RadioGroup'

type MobileRadioGroupPopoverProps = {
  label: string
  open: boolean
  options: RadioGroupOption[]
  value: string | number
  onClose: () => void
  onSubmit: (value: string | number) => void
}

/**
 * Mobile UI component to replace a DropDown UI component (bigger screens) triggered by an IconButton.
 * @param props MobileRadioGroupPopoverProps
 * @returns MobileRadioGroupPopover UI component
 */
export const MobileRadioGroupPopover: FC<MobileRadioGroupPopoverProps> = (props) => {
  const { label, open, options, value, onClose, onSubmit } = props

  // Component state
  const [updatedValue, setUpdatedValue] = useState<string | number>(value)

  return (
    <PopoverFromBottom isOpen={open} paperStyles={styles.paper} onClose={onClose}>
      <div className={styles.mobileRadioGroupPopover}>
        <Card header={label}>
          <RadioGroup items={options} value={updatedValue} onChange={setUpdatedValue} />
        </Card>
        <ActionBar onClose={onClose} onSubmit={() => onSubmit(updatedValue)} />
      </div>
    </PopoverFromBottom>
  )
}
