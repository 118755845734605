import { useArticlesSearchProvider } from './useArticleSearchProvider'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useEffect, useMemo, useState } from 'react'
import { EntityChange } from './useEntities'
import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'

interface ISearchPageDataParams {
  elementsPerRow?: number
  searchParams: ArticleSearchParamsWithId | null | undefined
  articleChange?: EntityChange<ProductAggregate | MaybeCompleteSearchProduct>
}

export const useLoadMoreProducts = (params: ISearchPageDataParams) => {
  const { elementsPerRow = 1, searchParams, articleChange } = params
  const [page, setPage] = useState(0)
  const {
    searchResult: articles,
    maxPage,
    itemsPerPage,
    hits,
    isFetching,
    changePagination,
    fetchMore,
    dataUpdated$,
  } = useArticlesSearchProvider()

  useEffect(() => {
    if (!articleChange) {
      return
    }

    const sub = dataUpdated$.subscribe((data) => {
      if (data) {
        articleChange(data.prevEntities, data.nextEntities)
      }
    })

    return () => {
      return sub.unsubscribe()
    }
  }, [dataUpdated$, articleChange])

  // TODO: "hits" is unreliable. It represents count of all items.
  // But searchProducts doesn't allow to request all this items if count is too big.
  const rowCount = Math.max(hits || 0, 0)

  const { loadMoreItems } = useMemo(() => {
    const _loadItems = (nextPage: number) => {
      // in case we reached max pages, we don't trigger more actions
      // page is 0-based, so it needs to be lower than maxPage, not equal
      if (!maxPage || !searchParams || nextPage > maxPage) {
        return false
      }

      return fetchMore({ searchParams, page: nextPage, itemsPerPage })
    }

    const loadMoreItems = async (p: { startIndex: number; stopIndex: number }) => {
      const { startIndex, stopIndex } = p
      if (typeof itemsPerPage !== 'number') {
        return
      }
      const nextPage = Math.floor(startIndex / itemsPerPage)
      setPage(nextPage)
      const res = await _loadItems(nextPage)
      if (!res) {
        return
      }

      const currentMaxArticleIndex = nextPage * itemsPerPage * elementsPerRow

      if (stopIndex <= currentMaxArticleIndex) {
        // if stopIndex is higher as the currently requested page, we need to request the next page aswell
        return
      }

      await _loadItems(nextPage + 1)
    }

    return { loadMoreItems }
  }, [fetchMore, elementsPerRow, itemsPerPage, maxPage, searchParams])

  return { rowCount, articles, loadMoreItems, changePagination, isFetching, page }
}
