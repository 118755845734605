import React from 'react'
import { useTranslation } from 'react-i18next'
import { OnlineCategories, StockAssortmentCatalog } from './Categories'
import { Modal } from '@obeta/components/lib/modal/Modal'
import { Availability } from './Availability'
import { useFiltersStateContext } from '../hooks/useFiltersReducer'
import { Suppliers } from './Suppliers'
import { CollapseComponent } from '../CollapseComponent'
import { ListPrice } from './ListPrice'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { MobileSideBarFooter } from '../../../searchPage/filters/facets/MobileSideBarFooter/MobileSideBarFooter'
import styles from './Sidebar.module.scss'
import { Fundings } from '../../../searchPage/filters/facets/Fundings'
import { useFundings } from '@obeta/data/lib/hooks/useFundings'

export const Sidebar: React.FC = React.memo(function Sidebar() {
  const { user } = useUserDataV2()

  const { filtersState, dispatchFiltersAction } = useFiltersStateContext()

  const { t } = useTranslation()

  const list = [
    <CollapseComponent
      key="avilability"
      header={t<string>('SEARCH.AVAILABILITY')}
      datatestid="availability"
    >
      <Availability
        searchParams={filtersState.searchParams}
        dispatchFiltersAction={dispatchFiltersAction}
        datatestid="availability"
      />
    </CollapseComponent>,
    <CollapseComponent
      key="suppliers"
      header={t<string>('SEARCH.ARTICLELIST.FILTER.SUPPLIER')}
      datatestid="suppliers"
    >
      <Suppliers />
    </CollapseComponent>,
    <StockAssortmentCatalog
      key="categories"
      chosenCategory={filtersState.searchParams.obetaCategory}
      dispatchFiltersAction={dispatchFiltersAction}
      datatestid="obeta-categories"
    />,
    <OnlineCategories
      key="online-categories"
      chosenCategory={filtersState.searchParams.dehaCategory}
      dispatchFiltersAction={dispatchFiltersAction}
      datatestid="deha-categories"
    />,
  ]

  const searchParams = filtersState.searchParams
  const { fundings } = useFundings({ searchParams })

  if (fundings.length > 0) {
    list.push(
      <CollapseComponent
        key="fundings"
        header={t('SEARCH.ARTICLELIST.FILTER.FUNDINGS')}
        datatestid="fundings"
      >
        <Fundings
          searchParams={filtersState.searchParams}
          dispatchFiltersAction={dispatchFiltersAction}
        />
      </CollapseComponent>
    )
  }

  const filterData = useArticleFilterData()

  if (
    filterData.articleSearchFilter.listPriceMax > 0 &&
    user &&
    user.permissions?.Global_canReadPrices &&
    user.permissions.Global_canReadPrices !== PricePermissions.NoPrice
  ) {
    list.push(
      <CollapseComponent
        key="list-price"
        header={t<string>('SEARCH.ARTICLEDETAILS.SUPPLIER_ARTICLE_PRICE')}
      >
        <ListPrice
          dispatchFiltersAction={dispatchFiltersAction}
          minListPrice={filterData.articleSearchFilter.listPriceMin}
          maxListPrice={filterData.articleSearchFilter.listPriceMax}
        />
      </CollapseComponent>
    )
  }

  return <div className={styles.sidebar}>{list}</div>
})

export const MobileSidebar: React.FC<{
  open: boolean
  onClose: () => void
}> = (props) => {
  const { open, onClose } = props

  return (
    <Modal isFullScreen open={open} onClose={onClose}>
      <div className={styles.mobileRoot}>
        <div className={styles.mobileContent}>
          <Sidebar />
          <MobileSideBarFooter onClose={onClose} />
        </div>
      </div>
    </Modal>
  )
}
