import { Box, SvgIcon, Typography } from '@mui/material'
import { ArticleClassesExpandable } from '../../ArticleClassesExpandable'
import { DesktopEtim } from './DesktopEtim'
import { DispatchFiltersAction } from '../reducer'
import { SelectedFeatureInfo } from '../SelectedFeatureInfo'
import { withUnit } from '@obeta/utils/lib/withUnit'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './AttributesCards.module.scss'
import { Etim, Facet, SelectedEtimValue } from '@obeta/models/lib/models/Search'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { PopoverDropdownMenu } from '@obeta/components/lib/dropdown-button'
import React from 'react'
import { useFeaturePointerData } from '../hooks/useFeaturePointerData'
import { Size } from '../Size'
import { useTranslation } from 'react-i18next'
import { renderSkeletons } from './EtimSkeletons'
import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { Scrollbar } from '@obeta/components/lib/scrollbar/Scrollbar'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { useTranslateValues } from '@obeta/data/lib/hooks/useTranslateValues'

interface IAttributesCards {
  dispatchEtimAction: DispatchFiltersAction
  selectedEtimClass: Etim | null
  selectedEtimValues: Record<string, SelectedEtimValue>
  activeEtimFeatureName: string | null
}

const Attribute: React.FC<{
  activeEtimFeatureName: string | null
  selectedEtimValues: Record<string, SelectedEtimValue>
  dispatchEtimsAction: DispatchFiltersAction
  attr: Facet
}> = (props) => {
  const { activeEtimFeatureName, selectedEtimValues, dispatchEtimsAction, attr } = props
  const selected = selectedEtimValues[attr.name]
  const { t } = useTranslation()
  const { tValue } = useTranslateValues()

  const { onClose, handleClick: _handleClick, anchorEl, open } = usePopoverState()
  const { total, featurePointer, activeEtimValue, selectFeature } = useFeaturePointerData({
    activeEtimFeatureName,
    dispatchEtimsAction,
    selectedEtimValues,
  })

  const handleClick = (e: React.MouseEvent<HTMLElement>, etim: Facet) => {
    dispatchEtimsAction({
      type: 'selectEtimAttribute',
      payload: {
        etimAttribute: etim,
      },
    })

    _handleClick(e)
  }

  const defaultOptions = [
    {
      id: 'reset',
      name: t<string>('COMMON.ALL'),
      count: total,
      skeleton: false,
      facet: null,
    },
  ]

  return (
    <React.Fragment>
      <DesktopEtim
        onClick={(e) => {
          handleClick(e, attr)
        }}
        withArrowIcon
        name={attr.meta.name}
        subLine={
          <SelectedFeatureInfo
            key="feature-info"
            typographyVariant="smallTextBold"
            sizeTypographyVariant="smallText"
            selectedValue={selected}
            attribute={attr}
          />
        }
      />
      <PopoverDropdownMenu
        ScrollbarComponent={Scrollbar}
        menuClasses={{ scrollbar: styles.valuesContainer }}
        open={open}
        onClose={onClose}
        onOptionSelected={(option) => {
          if (option.id === 'reset') {
            if (activeEtimValue) {
              dispatchEtimsAction({
                type: 'resetSelectedEtimValue',
                payload: {
                  selectedValue: activeEtimValue.value,
                },
              })
            }
          } else if (option.facet) {
            selectFeature(option.facet)
          }

          onClose()
        }}
        options={
          featurePointer
            ? [
                ...defaultOptions,
                ...featurePointer.values.map((feature) => {
                  return {
                    id: feature.value,
                    name: feature.value,
                    count: feature.count,
                    skeleton: false,
                    facet: feature,
                  }
                }),
              ]
            : Array(5).fill({ id: '0', name: '0', count: 0, skeleton: true, facet: null })
        }
        anchorEl={anchorEl}
        formatOption={(option, idx) => {
          if (option.skeleton) {
            return <TextSkeleton width={50} key={idx} height={16} />
          }

          // bold style for selected option
          let variant: React.ComponentProps<typeof Typography>['variant'] = 'smallText'
          if (
            (!activeEtimValue && option.id === 'reset') ||
            (activeEtimValue && activeEtimValue.value === option.name)
          ) {
            variant = 'smallTextBold'
          }

          return (
            <Typography key={option.id} variant={variant}>
              {tValue(option.name)} <Size size={option.count} />
            </Typography>
          )
        }}
      ></PopoverDropdownMenu>
    </React.Fragment>
  )
}

export const AttributesCards: React.FC<IAttributesCards> = React.memo(function AttributesCards(
  props
) {
  const { dispatchEtimAction, selectedEtimClass, selectedEtimValues, activeEtimFeatureName } = props
  const {
    articleSearchFilter: { etimAttributes },
    loading,
  } = useArticleFilterData()

  /**
   * We can't hide completeley this view if loading == fale and there's no data
   * Reason: If user can see attributes means that he selected etim and should be
   * able to remove this etim (via header that rendered by this view)
   */

  const selectedEtimAttributes = Object.entries(selectedEtimValues)
  const activeEtimAttributes = (
    <>
      {selectedEtimAttributes.map(([key, etimValue]) => {
        const facet = etimValue?.facet
        if (!facet) return null
        return (
          <div
            key={etimValue.value}
            className={styles.remove}
            onClick={() => {
              dispatchEtimAction({
                type: 'removeEtimAttribute',
                payload: {
                  etimAttribute: facet,
                },
              })
            }}
          >
            <Typography variant="bodyBold">{`${facet.meta.name}: ${etimValue.value}`}</Typography>
            <SvgIcon fontSize="small" component={CloseIcon} />
          </div>
        )
      })}
    </>
  )
  return (
    <ArticleClassesExpandable
      itemsLength={etimAttributes.length}
      withIcon={false}
      canCollapse={selectedEtimAttributes.length === 0}
      header={
        <div className={styles.header}>
          <Box
            className={styles.remove}
            onClick={() => {
              dispatchEtimAction({
                type: 'resetEtims',
              })
            }}
          >
            <Typography variant="bodyBold">
              {withUnit(selectedEtimClass?.name, selectedEtimClass?.count, true)}
            </Typography>
            <SvgIcon fontSize="small" component={CloseIcon} />
          </Box>
          {activeEtimAttributes}
        </div>
      }
    >
      {loading
        ? renderSkeletons()
        : etimAttributes.map((attr) => {
            return (
              <Attribute
                key={attr.name}
                activeEtimFeatureName={activeEtimFeatureName}
                selectedEtimValues={selectedEtimValues}
                dispatchEtimsAction={dispatchEtimAction}
                attr={attr}
              />
            )
          })}
    </ArticleClassesExpandable>
  )
})
