import React, { useMemo } from 'react'
import styles from './promotionContent.module.scss'
import { Typography } from '@mui/material'
import { usePromotionArticlesList } from '@obeta/data/lib/hooks/usePromotionArticlesList'
import { PromotionContentLongList } from './promotionContentLongList'
import { PromotionContentShortList } from './promotionContentShortList'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { updateCartGraphQL } from '@obeta/data/lib/actions/cart-actions'

interface IPromotionContentProps {
  title?: string
  query?: string
  promotionId: string
}

export const PromotionContent: React.FC<IPromotionContentProps> = (props) => {
  const { title, query, promotionId } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { articleData, itemsCount, isLoading, isDataLoaded } = usePromotionArticlesList(query)
  const isDataExists = useMemo(() => !isLoading && isDataLoaded, [isLoading, isDataLoaded])

  // TODO: might be better to use some context to pass func into components below by tree instead of prop-drilling (?)
  const handleAddToCart = (cart: ShoppingCartV2) => {
    dispatch(updateCartGraphQL({ ...cart, promotionId }))
  }

  return (
    <div className={styles['promotion-page-content-wrapper']}>
      <div className={styles['promotion-page-content-background']} />
      <div className={styles['promotion-page-content']}>
        <Typography variant="h3" className={styles['promotion-page-content-title']}>
          {t('PROMOTIONS.SEARCH_RESULT', {
            name: title,
            itemsCount,
          })}
        </Typography>
        {isDataExists &&
          (itemsCount <= 7 ? (
            <PromotionContentShortList articleData={articleData} onAddToCart={handleAddToCart} />
          ) : (
            <ArticlesSearchProvider mode="local">
              <PromotionContentLongList searchString={query} onAddToCart={handleAddToCart} />
            </ArticlesSearchProvider>
          ))}
      </div>
    </div>
  )
}
