import { ShoppingCartV2, StoreV2 } from '@obeta/models/lib/models'
import { NotificationEvent } from '@obeta/utils/lib/pubSub'
import { ShoppingCartSubmitCartRequestTrackingData } from '@obeta/models/lib/models/ShoppingCart/ShoppingCartTrackingData'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'

export const mapShoppingCartV2ToShoppingCartSubmitCartRequestTrackingData = (
  cart: ShoppingCartV2
): ShoppingCartSubmitCartRequestTrackingData => {
  return {
    cartId: cart.id,
    items: cart.items.map((item) => {
      return {
        amount: item.amount,
        offerItemPosition: item.offerItemPosition,
        prices: item.prices,
        sapId: item.sapId,
      }
    }),
    prices: cart && cart.shoppingCartPrices ? cart.shoppingCartPrices : undefined,
    startTime: Date.now(),
  }
}

export function buildOrderState(event: NotificationEvent, stores: StoreV2[]) {
  return {
    cartId:
      'cartId' in event.options && event.options.cartId !== undefined ? event.options.cartId : '',
    date: 'date' in event.options ? event.options.date : '',
    offerId: 'offerId' in event.options ? event.options.offerId : '',
    orderId: 'orderId' in event.options ? event.options.orderId : '',
    shopPromotionEmail:
      'shopPromotionEmail' in event.options ? event.options.shopPromotionEmail : '',
    shopPromotionId: 'shopPromotionId' in event.options ? event.options.shopPromotionId : '',
    pickupNumber: 'pickupNumber' in event.options ? event.options.pickupNumber : '',
    shippingType: 'shippingType' in event.options ? event.options.shippingType : ShippingType.Cargo,
    storeName:
      'storeId' in event.options
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stores.find((store) => store.id === event.options.storeId)?.address.name1 ?? ''
        : '',
    time: 'time' in event.options ? event.options.time : '',
  }
}
