import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { CommonSkeleton } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import { CollapseComponent } from '@obeta/components/lib/collapseComponent/CollapseComponent'
import { usePageContext } from '../context/context'
import { ICategory } from '@obeta/models/lib/models/RentArticle/types'
import { IFiltersCombined, TSetFilter } from '../types'
import { Size } from './Size'

interface ICategoriesMapProps {
  categories: ICategory[] | null
  setFilter: TSetFilter
  filters: IFiltersCombined
}

interface ICategoryFilterProps extends ICategoriesMapProps {
  isLoading: boolean
}

export const CategoryFilter: FC<ICategoryFilterProps> = ({
  isLoading,
  categories,
  setFilter,
  filters,
}) => {
  const { mobile, tablet } = usePageContext()
  const { t } = useTranslation()

  return (
    <CollapseComponent header={t('RENTABLE_ARTICLES.CATEGORY')} mobile={mobile || tablet}>
      <div className={styles['list']}>
        {isLoading ? (
          <CommonSkeleton count={3} height={32} width={20} />
        ) : (
          <CategoriesMap categories={categories} setFilter={setFilter} filters={filters} />
        )}
      </div>
    </CollapseComponent>
  )
}

const CategoriesMap = memo<ICategoriesMapProps>(({ categories, filters, setFilter }) => {
  if (!categories) {
    return null
  }

  return (
    <>
      {categories.map((el) => (
        <RadioItem
          className={styles['item']}
          onChange={() => setFilter(el)}
          key={el.value}
          id={`category_${el.value}`}
          label={el.value}
          value={el.value}
          checked={el.value === filters.category.value}
          rightAdornment={<Size className={styles.size} size={el.count} />}
        />
      ))}
    </>
  )
})
