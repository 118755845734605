import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

// Styles
import styles from './CartTemplateContextMenuOwnerInfo.module.scss'

// UI
import { LightGrayDivider } from '../../light-gray-divider/LightGrayDivider'

// Utils
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'

type CartTemplateContextMenuOwnerInfoItemProps = {
  title: string
  value: string
}

const CartTemplateContextMenuOwnerInfoItem: FC<CartTemplateContextMenuOwnerInfoItemProps> = (
  props
) => {
  const { title, value } = props

  return (
    <div className={styles.item}>
      <Typography variant="smallText" className={styles.itemTitle}>
        {title}
      </Typography>
      <Typography variant="smallTextBold">{value}</Typography>
    </div>
  )
}

type CartTemplateContextMenuOwnerInfoProps = {
  createdAt: string
  ownerName: string
}

export const CartTemplateContextMenuOwnerInfo: FC<CartTemplateContextMenuOwnerInfoProps> = (
  props
) => {
  const { createdAt, ownerName } = props

  const { t } = useTranslation()

  return (
    <div className={styles.ownerInfo}>
      <LightGrayDivider />
      <div className={styles.ownerInfoContent}>
        <CartTemplateContextMenuOwnerInfoItem
          title={t('TEMPLATES_CONTEXT_MENU.CREATED_BY')}
          value={ownerName}
        />
        <CartTemplateContextMenuOwnerInfoItem
          title={t('TEMPLATES_CONTEXT_MENU.CREATED_ON')}
          value={dateFormatter(createdAt)}
        />
      </div>
    </div>
  )
}
