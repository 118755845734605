import { FC } from 'react'
import { useNews } from '../../../startPage/hooks/useNews'
import { Section } from '../../../startPage/Section'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { NewsCard } from '@obeta/components/lib/newscard/NewsCard'
import { ProductsSwiper } from '../../../articleDetailsPage/ProductsSwiper'
import { formatDateAsMonthNameAndYear } from '../../../startPage/NewsCardSection'
import styles from './SuppliersOverviewTopSection.module.scss'

interface LatestSupplierNews {
  latestSupplierNews: INewsCard[]
}

export const LatestSupplierNewsView: FC<LatestSupplierNews> = ({ latestSupplierNews }) => {
  const { mobile, desktop } = useBreakpoints()

  return (
    <div className={styles.latestSupplierNewsContainer}>
      <ProductsSwiper
        closeNavigationButtons={mobile}
        cardWrapperClassName={styles.cardsWrapper}
        withNavigateButtons={!mobile}
        isSliderLoop={false}
        elementsPerRow={1}
        productElements={latestSupplierNews.map((newsEntry) => (
          <NewsCard
            {...newsEntry}
            options={{
              textMaxLines: desktop ? 10 : 5,
            }}
            classes={{
              cardWrapperClassName: styles.cardWrapper,
              contentWrapperClassName: styles.contentWrapper,
              imageWrapperClassName: styles.imageWrapper,
              themeWrapper: styles.themeWrapper,
            }}
            publishedAt={formatDateAsMonthNameAndYear(newsEntry.publishedAt)}
            key={newsEntry.id}
          />
        ))}
      />
    </div>
  )
}

export const SuppliersOverviewTopSection = () => {
  // 5 last suppliers news
  const { news: latestSupplierNews } = useNews({
    config: {
      pagination: { start: 0, limit: 5 },
      sort: 'startDate:desc',
      filters: { pinAsSupplierNews: { eq: true } },
    },
  })

  if (!latestSupplierNews.length) {
    return null
  }

  return (
    <Section
      className={styles.pinnedSectionWrapper}
      content={<LatestSupplierNewsView latestSupplierNews={latestSupplierNews} />}
    />
  )
}
