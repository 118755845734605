import React, { FC, useContext, useEffect, useState } from 'react'
import { AppBar, SvgIcon, Typography } from '@mui/material'
import { CartTemplateDetailsRenameField } from '../templates/CartTemplateDetailsRenameField'
import { CartTemplateDetailsSearchBar } from '../templates/CartTemplateDetailsSearchBar'
import { CartTemplateDetailsContext } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { Share } from '@capacitor/share'
import { ReactComponent as ShareIcon } from 'assets/icon/designsystem/share.svg'

import styles from './CartTemplateDetailsHeader.module.scss'
import { TertiaryButton, TertiaryIconButton } from '../custom-button/CustomButton'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useTranslation } from 'react-i18next'
import { datadogRum } from '@datadog/browser-rum'
import { Capacitor } from '@capacitor/core'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  hasEditingRights: boolean
  onRenameTemplate: (newName: string) => void
}

export const CartTemplateDetailsHeader: FC<Props> = ({ hasEditingRights, onRenameTemplate }) => {
  const { search, cartTemplate } = useContext(CartTemplateDetailsContext)
  const history = useHistory()
  const { t } = useTranslation()
  // Component state
  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const [isShareAvailable, setIsShareAvailable] = useState(false)
  const { desktop, mobile } = useBreakpoints()

  useEffect(() => {
    const checkShareAvailability = async () => {
      const canShare = await Share.canShare()
      setIsShareAvailable(canShare.value)
    }
    checkShareAvailability()
  }, [])

  const shareData = async (data: Pick<ShareData, 'title' | 'url'>) => {
    const canShare = await Share.canShare()

    if (!canShare.value) return datadogRum.addError(new Error('Data cannot be shared'), data)

    const url = new URL(window.location.href)

    if (Capacitor.isNativePlatform()) {
      // Share url for native part should look like "https://mobile.obeta.de/..."
      const mobileObetaBaseUrl = new URL(process.env.REACT_APP_BASE_URL || '')

      url.protocol = mobileObetaBaseUrl.protocol || 'https:'
      url.host = mobileObetaBaseUrl.host || 'mobile.obeta.de'
    }

    try {
      await Share.share({ ...data, url: url.href })
    } catch (error) {
      datadogRum.addError(error)
    }
  }

  return (
    <>
      <AppBar color={'transparent'} elevation={0} className={styles.header} position={'static'}>
        <div className={styles.backAndShareButtons}>
          <TertiaryButton
            size={'small'}
            leftIcon={<SvgIcon component={ArrowBackIcon} />}
            onClick={() => history.push('/cart-template-list')}
          >
            {mobile ? t('TEMPLATES.TO_OVERVIEW') : t('TEMPLATES.BACK_TO_OVERVIEW')}
          </TertiaryButton>
          {!mobile && (
            <TertiaryIconButton
              icon={<ShareIcon />}
              onClick={() =>
                shareData({
                  title: document.title,
                  url: window.location.href,
                })
              }
              size={!desktop ? 'large' : 'small'}
            >
              {t('TEMPLATES.SHARE')}
            </TertiaryIconButton>
          )}
          {isShareAvailable && mobile && (
            <TertiaryIconButton
              icon={<ShareIcon />}
              onClick={() => {
                shareData({
                  title: document.title,
                  url: window.location.href,
                })
              }}
              size={'large'}
              key="share"
            />
          )}
        </div>
        <div className={styles.buttons}>
          <CartTemplateDetailsRenameField
            hasEditingRights={hasEditingRights}
            isDefaultTemplate={cartTemplate.isDefault}
            isRenaming={isRenaming}
            templateName={cartTemplate.name}
            templateItemsCount={cartTemplate.itemCount}
            onRenameTemplate={onRenameTemplate}
            onClose={() => setIsRenaming(false)}
            onOpen={() => setIsRenaming(true)}
          />
          <div className={styles.visibilityStatus}>
            <Typography variant={'bodyBold'}>
              {cartTemplate.isShared
                ? `${t('TEMPLATES.PUBLIC')} ${t('TEMPLATES.TEMPLATE')}`
                : `${t('TEMPLATES.PRIVATE')} ${t('TEMPLATES.TEMPLATE')}`}
            </Typography>
          </div>
        </div>
      </AppBar>

      <CartTemplateDetailsSearchBar
        // Either display the count of search result hits or the count of all saved items
        count={cartTemplate.cartTemplateItems?.resultsCount ?? cartTemplate.itemCount}
        onSearch={search}
      />
    </>
  )
}
