import React, { useEffect } from 'react'
import { Tab } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { CartTemplate } from '@obeta/schema'
import { useTranslation } from 'react-i18next'
import { TabFilters } from '../tab-filters/TabFilters'
import styles from './TemplateFilterBar.module.scss'
import { Box } from '@mui/material'
// Assets
import { ReactComponent as FavoriteBorderIcon } from '@obeta/assets/icon/designsystem/favourite_border.svg'
import { ReactComponent as FavoriteIcon } from '@obeta/assets/icon/designsystem/favourite.svg'
import { ReactComponent as LockedIcon } from 'assets/icon/designsystem/locked.svg'
import { ReactComponent as OpenIcon } from 'assets/icon/designsystem/open.svg'

interface Props {
  activeCartTemplatesFilter: Tab
  templates: CartTemplate[]
  onToggleActiveCartTemplatesFilter: (ActiveCartTemplatesFilter) => void
  searchTerm: string
}

function filterBySearchTerm(template: CartTemplate, searchTerm: string) {
  return template.name.toLowerCase().includes(searchTerm.toLowerCase())
}

export const TAB_VALUES_LABEL_MAPPING = {
  [Tab.ALL]: 'TEMPLATES.ALL_TEMPLATES',
  [Tab.FAVORITES]: 'TEMPLATES.FAVORITES',
  [Tab.PRIVATE]: 'TEMPLATES.PRIVATE',
  [Tab.PUBLIC]: 'TEMPLATES.PUBLIC',
}

export const TemplateFilterBar: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { activeCartTemplatesFilter, templates, onToggleActiveCartTemplatesFilter, searchTerm } =
    props
  const favoriteTemplatesIsActive = activeCartTemplatesFilter === Tab.FAVORITES

  const totalCount = templates.filter((template) => {
    return filterBySearchTerm(template, searchTerm)
  }).length
  const favoritesCount = templates.filter((template) => {
    return filterBySearchTerm(template, searchTerm) && template.isFavourite
  }).length
  const privateCount = templates.filter((template) => {
    return filterBySearchTerm(template, searchTerm) && !template.isShared
  }).length
  const publicCount = templates.filter((template) => {
    return filterBySearchTerm(template, searchTerm) && template.isShared
  }).length

  const favoriteFilterIsActive = favoriteTemplatesIsActive && favoritesCount > 0

  useEffect(() => {
    if (
      (activeCartTemplatesFilter === Tab.FAVORITES && favoritesCount === 0) ||
      (activeCartTemplatesFilter === Tab.PRIVATE && privateCount === 0) ||
      (activeCartTemplatesFilter === Tab.PUBLIC && publicCount === 0)
    ) {
      onToggleActiveCartTemplatesFilter(Tab.ALL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCartTemplatesFilter, favoritesCount, privateCount, publicCount])

  const filterTabsOptions = [
    {
      label: t(TAB_VALUES_LABEL_MAPPING[Tab.ALL]),
      value: Tab.ALL,
      disabled: totalCount === 0,
      total: totalCount,
    },
    {
      label: t(TAB_VALUES_LABEL_MAPPING[Tab.FAVORITES]),
      value: Tab.FAVORITES,
      disabled: favoritesCount === 0,
      total: favoritesCount,
      icon: favoriteFilterIsActive ? <FavoriteIcon /> : <FavoriteBorderIcon />,
    },
    {
      label: t(TAB_VALUES_LABEL_MAPPING[Tab.PRIVATE]),
      value: Tab.PRIVATE,
      disabled: privateCount === 0,
      total: privateCount,
      icon: <LockedIcon />,
    },
    {
      label: t(TAB_VALUES_LABEL_MAPPING[Tab.PUBLIC]),
      value: Tab.PUBLIC,
      disabled: publicCount === 0,
      total: publicCount,
      icon: <OpenIcon />,
    },
  ]

  return (
    <Box className={styles.wrapper}>
      <TabFilters<Tab>
        spaceBetween={32}
        options={filterTabsOptions}
        onChangeTab={(value) => onToggleActiveCartTemplatesFilter(value)}
        activeTab={activeCartTemplatesFilter || Tab.ALL}
      />
    </Box>
  )
}
