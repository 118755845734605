import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Typography } from '@mui/material'
import styles from './RaffleCheckbox.module.scss'

// UI
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'

interface GiveawayCheckboxProps {
  checked: boolean
  linkText: string
  linkPath: string
  onChange: (checked: boolean) => void
}
export const RaffleCheckbox: FC<GiveawayCheckboxProps> = ({
  checked,
  linkText,
  linkPath,
  onChange,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.labeledCheckbox}>
      <Checkbox
        checked={checked}
        className={styles.labeledCheckboxElem}
        onChange={(event) => onChange(event.target.checked)}
      />
      <Typography>{t('RAFFLE.NOT_REGISTERED.CHECKBOX_TEXT')}</Typography>
      <Link href={linkPath} target="_blank" className={styles.infoLink}>
        <Typography>{linkText}</Typography>
      </Link>
    </div>
  )
}
