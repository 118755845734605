import { Category as ProductCategory } from '@obeta/models/lib/models/Article/Shop/Product'
import { Category } from '@obeta/models/lib/models/Category'

export const createCategoriesTree = (categories: ProductCategory[]): Category | null => {
  const rootId = categories[0]?.id
  return categories.reduce<Category | null>((acc, productCat, idx) => {
    let parent: Category | undefined
    if (acc) {
      parent = acc
    }

    const cat: Category = {
      id: productCat.id,
      name: productCat.title,
      level: idx,
      parent,
      rootId,
    }

    return cat
  }, null)
}
