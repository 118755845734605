import { Card, Typography } from '@mui/material'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import React from 'react'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import styles from './ShippingDetails.module.scss'
import { TertiaryButton } from '../custom-button/CustomButton'
import { useTranslation } from 'react-i18next'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { OrderStateItem } from '../orders/OrderStateItem'
import { useDispatchInvoices } from '@obeta/data/lib/hooks/useDispatchInvoice'
import { useDispatchDeliverySlip } from '@obeta/data/lib/hooks/useDispatchDeliverySlip'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'

export const ShippingDetails = (props) => {
  const user = useUserDataV2()
  const { t } = useTranslation()
  const { orderShippingDetails } = props
  const { dispatchDeliverySlipPdf } = useDispatchDeliverySlip()
  const { dispatchInvoicePdf } = useDispatchInvoices()

  const { createdAt, deliverySlipId, invoiceId, deliverySlipPrintable, orderState, packages } =
    orderShippingDetails

  // const trackingUrl = ''

  return (
    <Card className={styles.card} elevation={1}>
      <div className={styles.titleAndDataItems}>
        <div className={styles.titleAndLabelWrapper}>
          {(deliverySlipId || invoiceId) && (
            <Typography variant="bodyBold">
              {t('ORDERS.SHIPPING_DETAILS.DELIVERY')} {deliverySlipId || invoiceId}
            </Typography>
          )}
          {orderState && <OrderStateItem key={orderState.type} itemState={orderState} />}
        </div>
        <div className={styles.dataItemsWrapper}>
          {createdAt && (
            <div className={styles.dataItems}>
              <Typography variant="body">{t('ORDERS.SHIPPING_DETAILS.DATE')}</Typography>
              <Typography variant="bodyBold">{dateFormatter(createdAt)}</Typography>
            </div>
          )}
          {packages && <LightGrayDivider orientation="vertical" />}
          {packages && (
            <div className={styles.dataItems}>
              <Typography variant="body">
                {t('ORDERS.SHIPPING_DETAILS.NUMBER_OF_PARCELS')}
              </Typography>
              <Typography variant="bodyBold">{packages}</Typography>
            </div>
          )}
          {invoiceId && <LightGrayDivider orientation="vertical" />}
          {invoiceId && (
            <div className={styles.dataItems}>
              <Typography variant="body">{t('ORDERS.SHIPPING_DETAILS.INVOICE_NUMBER')}</Typography>
              <Typography variant="bodyBold">{invoiceId}</Typography>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {deliverySlipId && deliverySlipPrintable && (
          <TertiaryButton
            leftIcon={<DownloadIcon />}
            onClick={() => dispatchDeliverySlipPdf(deliverySlipId)}
          >
            {t('ORDERS.SHIPPING_DETAILS.DELIVERY_SLIP')}
          </TertiaryButton>
        )}
        {invoiceId && user?.permissions?.Global_canReadPrices !== PricePermissions.NoPrice && (
          <TertiaryButton leftIcon={<DownloadIcon />} onClick={() => dispatchInvoicePdf(invoiceId)}>
            {t('ORDERS.SHIPPING_DETAILS.INVOICE')}
          </TertiaryButton>
        )}
      </div>
    </Card>
  )
}
