import React, { FC } from 'react'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { DigitalBillingNotRegistered } from './DigitalBillingNotRegistered'
import { DigitalBillingRegistered } from './DigitalBillingRegistered'
import { useDigitalBillingActions } from './useDigitalBillingActions'
import { DigitalBillingLogin } from './DigitalBillingLogin'

export const DIGITAL_BILLING_PLUGIN_ID = 'de/obeta/plugins/digital-billing-plugin'
const DIGITAL_BILLING_PLUGIN_TITLE = 'Digital Billing'
const DIGITAL_BILLING_PLUGIN_DESCRIPTION =
  'Digital Billing Plugin with registration form and success message if registered'
const DIGITAL_BILLING_PLUGIN_VERSION = 1

const DigitalBillingPluginContent: FC = () => {
  const { isRegistered, register } = useDigitalBillingActions()
  const user = useUserDataV2()

  if (!user.fullUserId) {
    return <DigitalBillingLogin />
  }

  if (isRegistered) {
    return <DigitalBillingRegistered />
  }

  return <DigitalBillingNotRegistered onSubmit={register} />
}

export const DigitalBillingPlugin = {
  id: DIGITAL_BILLING_PLUGIN_ID,
  title: DIGITAL_BILLING_PLUGIN_TITLE,
  description: DIGITAL_BILLING_PLUGIN_DESCRIPTION,
  version: DIGITAL_BILLING_PLUGIN_VERSION,
  Renderer: () => <DigitalBillingPluginContent />,
}
