import './ResponsiveWrapper.scss'
import React from 'react'

export interface IResponsiveWrapperProps {
  backdropClassName?: string
}

export const ResponsiveWrapper: React.FC<IResponsiveWrapperProps> = (props) => {
  const { children } = props

  //this is just needed for development purposes, if we are running in production we won't need it
  const isStorybook = window.location.href.includes('iframe.html')

  return <div className={'obetaheader' + (isStorybook ? ' storybook' : '')}>{children}</div>
}
