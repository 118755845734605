import React, { FC, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Sidebar.module.scss'

import { Modal } from '@obeta/components/lib/modal/Modal'
import { TertiaryButton, DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { Sidebar } from './Sidebar'
import { IFiltersCombined, TSetFilters, TSetFilter } from '../types'
import { ICategory, ILocation } from '@obeta/models/lib/models/RentArticle/types'

export interface ISidebarMobileProps {
  open: boolean
  onClose: () => void
  isLoading: boolean
  locations: ILocation[] | null
  categories: ICategory[] | null
  filters: IFiltersCombined
  setFilters: TSetFilters
  setFilter: TSetFilter
  foundArticlesCount?: number
}

export const SidebarMobile: FC<ISidebarMobileProps> = (props) => {
  const {
    open,
    onClose,
    isLoading,
    locations,
    categories,
    filters,
    setFilter,
    setFilters,
    foundArticlesCount,
  } = props
  const { t } = useTranslation()
  const lastFilters = useRef<IFiltersCombined>(filters)

  const handleClose = () => {
    setFilters(lastFilters.current)
    onClose()
  }

  const handleApply = () => {
    lastFilters.current = filters
    onClose()
  }

  useEffect(() => {
    if (open) {
      lastFilters.current = filters
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal isFullScreen open={open} onClose={handleClose}>
      <div className={styles['mobileRoot']}>
        <Sidebar
          isLoading={isLoading}
          locations={locations}
          categories={categories}
          setFilter={setFilter}
          filters={filters}
        />
        <div className={styles['sidebarActions']}>
          <div className={styles['action']}>
            <TertiaryButton onClick={handleClose} size="large">
              {t('BUTTON.ABORT')}
            </TertiaryButton>
          </div>
          <div className={styles['action']}>
            <DarkButton onClick={handleApply}>
              {t('RENTABLE_ARTICLES.SHOW_N_RESULTS', { count: foundArticlesCount })}
            </DarkButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
