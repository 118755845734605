import React from 'react'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { RaffleNotRegistered } from './RaffleNotRegistered'
import { RaffleRegistered } from './RaffleRegistered'
import { useRaffleActions } from './useRaffleActions'

export const RAFFLE_PLUGIN_ID = 'de/obeta/plugins/raffle-plugin'
const RAFFLE_PLUGIN_TITLE = 'Raffle'
const RAFFLE_PLUGIN_DESCRIPTION =
  'Raffle Plugin with registration form and success message if registered'
const RAFFLE_PLUGIN_VERSION = 1

interface Props {
  startDate: string
  endDate: string
}
const RafflePluginContent: React.FC<{ data: Props }> = ({ data }) => {
  const { isRegistered, register } = useRaffleActions()
  const user = useUserDataV2()

  if (!user.fullUserId) {
    return null
  }

  if (isRegistered) {
    return <RaffleRegistered />
  }

  return (
    <RaffleNotRegistered startDate={data.startDate} endDate={data.endDate} onSubmit={register} />
  )
}

export const RafflePlugin = {
  id: RAFFLE_PLUGIN_ID,
  title: RAFFLE_PLUGIN_TITLE,
  description: RAFFLE_PLUGIN_DESCRIPTION,
  version: RAFFLE_PLUGIN_VERSION,
  Renderer: ({ data }) => <RafflePluginContent data={data} />,
}
