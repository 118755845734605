import { Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as NotificationsActiveIcon } from '@obeta/assets/icon/toast/notifications-active.svg'
import { ReactComponent as InfoIcon } from '@obeta/assets/icon/toast/info.svg'
import styles from './Alert.module.scss'

interface INotificationToastProps {
  leftBorderColor?: 'grey'
  title?: string
  message: React.ReactChild
  type: 'notifcation' | 'info'
  endAdornment?: JSX.Element
  className?: string
}

/**
 * this component can become the "base-alert" component
 * in case all alerts in app should have similar look and structure
 *
 * TODO: design system
 */

export const Alert: React.FC<INotificationToastProps> = (props) => {
  const { leftBorderColor = 'grey', message, type, endAdornment, className } = props

  const icon = type === 'info' ? <InfoIcon /> : <NotificationsActiveIcon />

  return (
    <Paper className={clsx(styles.root, className)}>
      <div className={clsx(styles.border, styles[leftBorderColor])} />
      <div className={styles.body}>
        {icon}
        <Typography className={styles.message} variant="body">
          {message}
        </Typography>
        {endAdornment}
      </div>
    </Paper>
  )
}
