import { useCallback, useState } from 'react'
import { DocumentNode, gql, useApolloClient } from '@apollo/client'
import {
  OfferV3,
  OfferByIdInput,
  GetOfferByIdQuery,
  GetOfferByIdQueryVariables,
} from '@obeta/schema'

export const getOfferByIdDocument: DocumentNode = gql`
  query getOfferById($input: OfferByIdInput!) {
    getOfferById(input: $input) {
      success
      errorMessage
      errorCode
      offer {
        offerId
        offerName
        clerk {
          name
          telephone
          email
        }
        commission
        estate
        customerReference
        isActive
        isDeleted
        isFavorite
        itemCount
        lastUpdated
        netPrice
        requirementPrice
        withoutAdditionsPrice
        offerFlags
        startDate
        endDate
        itemCount
      }
    }
  }
`

export const useGetOfferById = () => {
  const client = useApolloClient()
  const [offer, setOffer] = useState<OfferV3 | null>(null)

  const getOfferById = useCallback(
    async (input: OfferByIdInput) => {
      const response = await client.query<GetOfferByIdQuery, GetOfferByIdQueryVariables>({
        query: getOfferByIdDocument,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      })

      const data = response.data.getOfferById
      if (data.success && data.offer) {
        setOffer(data.offer as OfferV3) // TODO Replace with Complete/IncompleteType
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offer]
  )

  return {
    offer,
    getOfferById: getOfferById,
  }
}
