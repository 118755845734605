import { Typography } from '@mui/material'
import { IColumn, StripedTable } from '@obeta/components/lib/striped-table/StripedTable'
import React, { isValidElement, useMemo, useState, useEffect } from 'react'
import { PriceLabelOptions, ProductVariant } from '@obeta/models/lib/models/Article/Shop/Product'
import { TFunction, useTranslation } from 'react-i18next'
import { IArticle } from './types'
import { DropdownVariant } from './DropdownVariant'
import { useWarehouse } from '@obeta/data/lib/hooks/useWarehouse'
import styles from './ProductProperties.module.scss'
import { NoPrice } from '@obeta/components/lib/no-price/NoPrice'
import {
  isPricePermitted,
  mapPriceValue,
  PricesTypes,
} from '@obeta/components/lib/product-price/ProductPrice'
import { PricePermissions, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { normalizeNumber } from '@obeta/utils/lib/data-formatter/normalizeNumber'
import { useStoresStocks } from '@obeta/data/lib/hooks/useStoresStocks'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { MetaInfoSelectStore } from '@obeta/components/lib/select-store/SelectStore'
import { IProductIds, useCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { Tooltip } from '@obeta/components/lib/tooltip/Tooltip'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { IFetchedPrices, useProductPrices } from '@obeta/data/lib/hooks/useProductPrices'
import { withUnit } from '@obeta/utils/lib/withUnit'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { TextSkeleton } from '@obeta/components/lib/text-skeleton/TextSkeleton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { trackView } from '@obeta/utils/lib/tracking'
interface IPropertiesSectionProps {
  title: string
}

interface IProperty {
  type: React.ReactChild | null
  value: React.ReactChild | null
}

const PropertiesSection: React.FC<IPropertiesSectionProps> = (props) => {
  const { title, children } = props
  return (
    <div className={styles.section}>
      <Typography variant="bodyBold">{title}</Typography>
      {children}
    </div>
  )
}

interface IProductPropertiesProps {
  article: IArticle | undefined
  defaultStoreId: string | undefined
}

const buildPriceValue = (
  data: {
    permissions: PricePermissions | undefined
    type: PricesTypes
    value: number | undefined
    currency: string
    unit: string
    priceDimension?: number
  },
  isLoggedIn: boolean
) => {
  const { permissions, type, value, currency, unit, priceDimension } = data
  if (!isLoggedIn) {
    return <NoPrice currency="€" />
  }

  if (!isPricePermitted(type, permissions)) {
    return <NoPrice currency={currency} />
  }

  return mapPriceValue({
    value,
    unit,
    currency,
    type,
    priceDimension,
  })
}

const createColumns = (
  typeFormat: IColumn<keyof IProperty, IProperty>['format'] | null,
  valueFormat: IColumn<keyof IProperty, IProperty>['format'] | null
): IColumn<keyof IProperty, IProperty>[] => {
  return [
    { accessor: 'type', alignment: 'left', format: typeFormat },
    {
      accessor: 'value',
      alignment: 'right',
      bold: true,
      minWidth: 0,
      format: valueFormat,
    },
  ]
}

const columns = createColumns(
  (val) => {
    if (isValidElement(val)) {
      return val
    }

    return (
      <Typography noWrap variant="body">
        {val}
      </Typography>
    )
  },
  (val) => {
    if (isValidElement(val)) {
      return val
    }

    return (
      <Typography noWrap variant="bodyBold">
        {val}
      </Typography>
    )
  }
)

const pricesToShow: {
  type: PricesTypes
  hiddenIfZero: boolean
  propKey: keyof IFetchedPrices
  label: (t: TFunction, options?: PriceLabelOptions) => string
}[] = [
  {
    type: 'ListPrice',
    propKey: 'listPrice',
    hiddenIfZero: false,
    label: (t) => {
      return t<string>('ARTICLE_DETAIL.SUPPLIER_ARTICLE_PRICE')
    },
  },
  {
    type: 'CatalogPrice',
    propKey: 'catalogPrice',
    hiddenIfZero: false,
    label: (t) => {
      return t<string>('ARTICLE_DETAIL.CATALOG_PRICE')
    },
  },
  {
    type: 'PurchasePrice',
    propKey: 'netPrice',
    hiddenIfZero: false,
    label: (t) => {
      return t<string>('ARTICLE_DETAIL.PRICE')
    },
  },
  {
    type: 'BulkPrice',
    propKey: 'bulkPrice',
    hiddenIfZero: true,
    label: (t, options) => {
      return `${t<string>('ARTICLE_DETAIL.PRICE')} ${t<string>('MAIN.FROM')} ${withUnit(
        options?.amount,
        options?.unit
      )}`
    },
  },
  {
    type: 'MetalAdition',
    propKey: 'metalNeAddition',
    hiddenIfZero: true,
    label: (t) => {
      return t<string>('SHOPPING_CART.DETAILS.METAL_SURCHARGE')
    },
  },
]

interface IProductPropertiesBaseProps extends IProductPropertiesProps {
  productIds: IProductIds
  stores: StoreV2[]
  isLoggedIn: boolean
  user: UserV2 | null
  selectedStore: StoreV2 | undefined
  productId: string
  withSelectStore?: boolean
  withVariants?: boolean
}

export const ProductPropertiesBase: React.FC<IProductPropertiesBaseProps> = (props) => {
  const {
    article,
    defaultStoreId,
    productIds,
    stores,
    isLoggedIn,
    user,
    selectedStore: userStore,
    productId,
    withSelectStore = true,
    withVariants = true,
  } = props

  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const storesStocks = useStoresStocks(productIds, stores, isLoggedIn)
  const { stocks } = useWarehouse(productIds, ['main', defaultStoreId], isLoggedIn)
  const [mainWarehouse, userDefaultWarehouse] = stocks

  const { prices, loading: loadingPrices } = useProductPrices(article?.sapId, isLoggedIn)
  const permissions = user?.permissions?.Global_canReadPrices
  const [selectStoreOpen, setSelectStoreOpen] = useState(false)

  const pricesData = useMemo<IProperty[]>(() => {
    const buildValue = (type: PricesTypes, value: number | undefined) => {
      return buildPriceValue(
        {
          permissions,
          currency: prices?.currency || '',
          unit: article?.unit || '',
          type,
          value,
          priceDimension: prices?.priceDimension,
        },
        isLoggedIn
      )
    }

    return pricesToShow
      .map((priceConfig) => {
        const priceValue = (prices?.[priceConfig.propKey] || 0) as number
        if (!loadingPrices && !priceValue && priceConfig.hiddenIfZero) {
          return null
        }

        const value = loadingPrices ? (
          <TextSkeleton height={16} width={100} />
        ) : (
          buildValue(priceConfig.type, priceValue)
        )

        //TODO: article?.bulkPrices?.[0] is object with amount and unit, it doesn`t contain value, no need to replace it
        return {
          type: priceConfig.label(
            t,
            Object.assign({}, article?.bulkPrices?.[0], { unit: article?.unit })
          ),
          value: value,
        }
      })
      .filter(Boolean) as IProperty[]
  }, [article?.bulkPrices, article?.unit, permissions, prices, t, isLoggedIn, loadingPrices])

  const packagingInforamtion = useMemo(() => {
    return [
      {
        type: t<string>('ARTICLE_DETAIL.MINIMUM_AMOUNT'),
        value: withUnit(article?.minimumAmount, article?.unit),
      },
      {
        type: t<string>('ARTICLE_DETAIL.PACKAGING_UNIT'),
        value: withUnit(article?.packagingUnit, article?.unit),
      },
    ]
  }, [article?.minimumAmount, article?.packagingUnit, article?.unit, t])

  const availability = useMemo(() => {
    const stocksSum = storesStocks.reduce((sum, stock) => {
      return sum + stock.amount
    }, 0)

    let stocksLabel = t('ARTICLE_DETAIL.OTHER_STORES')
    const warehouseWithStock = storesStocks.filter((el) => el.amount !== 0)
    stocksLabel =
      warehouseWithStock.length > 0 ? `${stocksLabel} (${warehouseWithStock.length})` : stocksLabel

    const emptyValue = (
      <Typography color="gray" variant="body">
        - Stk
      </Typography>
    )

    /** TODO: does it makes sense to create util that gets store address? */
    return [
      {
        type: 'Online',
        value: isLoggedIn
          ? t<string>('ARTICLE_DETAIL.ITEMS_AVAILABLE', {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TOptions wants count to be "number"
              count: withUnit(normalizeNumber(mainWarehouse?.amount || 0, 0), article?.unit) as any,
            })
          : emptyValue,
      },
      {
        type: (
          <Tooltip title={userStore?.address?.name1 || ''} activateOnClick={!desktop}>
            <div
              className={clsx({
                [styles['underline']]: !desktop,
              })}
            >
              <Typography variant="body">{t<string>('ARTICLE_DETAIL.MY_STORE')}</Typography>
            </div>
          </Tooltip>
        ),
        value: isLoggedIn
          ? t<string>('ARTICLE_DETAIL.ITEMS_AVAILABLE', {
              count: withUnit(
                normalizeNumber(userDefaultWarehouse?.amount || 0, 0),
                article?.unit
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TOptions wants count to be "number"
              ) as any,
            })
          : emptyValue,
      },
      {
        type:
          stocksSum > 0 ? (
            <div
              className={styles['underline']}
              onClick={() => {
                setSelectStoreOpen(true)
              }}
            >
              <Typography variant="body">{stocksLabel}</Typography>
            </div>
          ) : (
            stocksLabel
          ),
        value: isLoggedIn
          ? t<string>('ARTICLE_DETAIL.ITEMS_AVAILABLE', {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TOptions wants count to be "number"
              count: withUnit(normalizeNumber(stocksSum || 0, 0), article?.unit) as any,
            })
          : emptyValue,
      },
    ]
  }, [
    article?.unit,
    mainWarehouse?.amount,
    storesStocks,
    t,
    userDefaultWarehouse?.amount,
    userStore?.address?.name1,
    isLoggedIn,
    desktop,
  ])

  /**
   * article.variants doesn't contain selected variant.
   * add manually to an array of dropdownVariants
   */
  let dropdownVariants: ProductVariant[] = article
    ? [{ sapId: article.sapId, title: article.title, product: article }]
    : []
  if (article?.variants) {
    dropdownVariants = dropdownVariants.concat(article.variants)
  }

  /**
  tracking
   **/
  const { metaDataReady } = useUserDataV2()

  useEffect(() => {
    if (metaDataReady) {
      if (!isLoggedIn) {
        trackView('articledetails', {
          articleId: article?.sapId,
        })
      }
      if (isLoggedIn && !loadingPrices) {
        trackView('articledetails', {
          articleId: article?.sapId,
          netPrice: prices?.netPrice,
          catalogPrice: prices?.catalogPrice,
          bulkPrice1: prices?.bulkPrice,
          listPrice: prices?.listPrice,
          strikeThroughPrice: prices?.strikeThroughPrice,
          metalNeAddition: prices?.metalNeAddition,
          stock: {
            online: mainWarehouse?.amount,
            branch: userDefaultWarehouse?.amount,
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, prices])
  /**
   end tracking
   **/

  return (
    <div className={styles.properties}>
      <PropertiesSection title={t<string>('ARTICLE_DETAIL.PRICES_INFO')}>
        <StripedTable columns={columns} data={pricesData} />
      </PropertiesSection>
      <PropertiesSection title={t<string>('ARTICLE_DETAIL.PACKAGING_INFORMATION')}>
        <StripedTable columns={columns} data={packagingInforamtion} />
      </PropertiesSection>
      <PropertiesSection title={t<string>('ARTICLE_DETAIL.AVAILABILITY')}>
        <StripedTable columns={columns} data={availability} />
      </PropertiesSection>
      {withVariants && article?.variants && article?.variants.length > 0 && (
        <PropertiesSection title={t<string>('ARTICLE_DETAIL.SELECT_VARIANT')}>
          <DropdownVariant productId={productId} variants={dropdownVariants} />
        </PropertiesSection>
      )}
      {withSelectStore && (
        <MetaInfoSelectStore
          productUnit={article?.unit || ''}
          productTitle={article?.title || ''}
          selectStoreOpen={selectStoreOpen}
          setSelectStoreOpen={setSelectStoreOpen}
        />
      )}
    </div>
  )
}

export const ProductProperties: React.FC<IProductPropertiesProps> = (props) => {
  const productIds = useCurrentProductIds()
  const stores = useEntities<StoreV2>('storesv2')
  const { isLoggedIn } = useUserDataV2()
  const { selectedStore: userStore, user } = useUserSelectedStore()
  // make sure productId is not undefined otherwise storybook will break
  // todo: storybook: mock useParams.
  const { id: productId = '' } = useParams<{ id: string }>()

  return (
    <ProductPropertiesBase
      {...props}
      productIds={productIds}
      stores={stores}
      user={user}
      selectedStore={userStore}
      isLoggedIn={isLoggedIn}
      productId={productId}
    />
  )
}
