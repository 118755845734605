import React, { FC, useEffect, useState } from 'react'
import styles from './OrderCreatedPromotionForm.module.scss'
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { DarkButton } from '../custom-button/CustomButton'
import { useUserAddressesV2 } from '@obeta/data/lib/hooks/useUserAddressesV2'
import { BaseOption, Select } from '../select/Select'

import { StoreV2 } from '@obeta/models/lib/models'
import { useEntities } from '@obeta/data/lib/hooks'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import clsx from 'clsx'
import { ShippingType } from '@obeta/schema'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { FormHelperText } from '../form-helper-text/FormHelperText'
import { useTranslation } from 'react-i18next'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { trackView } from '@obeta/utils/lib/tracking'

interface Props {
  onSubmit: (name: string, address: string) => void
  orderId: string
  promotionType: 'shop' | 'sap' | string
}
export const OrderCreatedPromotionForm: FC<Props> = (props) => {
  const { onSubmit, orderId, promotionType } = props
  const mainAddress = useUserAddressesV2()
  const { selectedStore } = useUserSelectedStore()
  const stores: StoreV2[] = useEntities('storesv2')
  const { t } = useTranslation()

  const [shippingType, setShippingType] = useState<ShippingType>('DefaultPickup')
  const [storeChosen, setStoreChosen] = useState({
    id: selectedStore?.id ?? '',
    title: selectedStore?.address.name1 ?? '',
  })

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  // Log action in Datadog (allows us to compare against actual form submit)
  useEffect(() => {
    trackView('promotion-address-form-rendered', {
      orderId: orderId,
    })
    setStoreChosen({ id: selectedStore?.id ?? '', title: selectedStore?.address.name1 ?? '' })
  }, [orderId, selectedStore])

  const storesOptions: BaseOption[] = stores.map((store) => {
    const storeName = store.address.name1
    return {
      title: storeName,
      id: store.id,
    }
  })

  function isValidEntry(enteredName: string): boolean {
    const parts = enteredName.split(' ')
    return parts.length > 1 && parts.every((part) => part !== '')
  }
  const enteredName: string = useWatch({ control, name: 'fullName' }) ?? ''

  const isEnteredNameError = enteredName.length === 0 || errors.fullName

  const handleStoreChange = (selectedStore) => {
    const store = stores.find((store) => store.id === selectedStore.id)
    if (store) {
      setStoreChosen({ id: store.id, title: store?.address.name1 })
    }
  }

  const handleSubmit = () => {
    let chosenAddress: AddressV2 | undefined = mainAddress?.mainAddress?.address
    if (shippingType === 'DefaultPickup') {
      const store = stores.find((store) => store.id === storeChosen.id)
      chosenAddress = store?.address
    }

    const chosenAddressStringified =
      shippingType === 'Cargo'
        ? `${chosenAddress?.street} ${chosenAddress?.houseNumber}, ${chosenAddress?.zipCode} ${chosenAddress?.city}`
        : `${chosenAddress?.name1}, ${chosenAddress?.street} ${chosenAddress?.houseNumber}, ${chosenAddress?.zipCode} ${chosenAddress?.city}`
    onSubmit(enteredName, chosenAddressStringified)
  }

  return (
    <div
      className={clsx(
        styles.container,
        promotionType === 'sap' ? styles.sapContainer : styles.shopContainer
      )}
    >
      <Typography variant="headline4Bold" className={styles.heading}>
        {t('SHOPPING_CART.PROMOTION.HEADING')}
      </Typography>
      <div className={styles.nameSection}>
        <Typography className={styles.nameContent} variant="bodyBold">
          {t('SHOPPING_CART.PROMOTION.RECIPIENT')}
        </Typography>{' '}
        <Typography className={styles.nameContent} variant="body">
          {t('SHOPPING_CART.PROMOTION.FULL_NAME')}
        </Typography>{' '}
        <Controller
          render={({ field: { onChange, value } }) => (
            <>
              <TextField
                name="fullName"
                id="fullName"
                className="outlined-lightGray"
                placeholder={'Max Mustermann'}
                error={!!errors.fullName}
                onChange={onChange}
                value={value}
                variant="outlined"
              />
              {errors.fullName && (
                <FormHelperText
                  className={styles.formHelperText}
                  errorText={t('SHOPPING_CART.PROMOTION.PLEASE_ENTER_YOUR_FULL_NAME')}
                />
              )}
            </>
          )}
          name="fullName"
          control={control}
          rules={{
            required: true,
            validate: isValidEntry,
          }}
        />
      </div>
      <div
        className={clsx(styles.addressSection, {
          [styles.increasedPaddingBottom]: promotionType === 'shop',
        })}
      >
        <Typography variant="bodyBold">{t('SHOPPING_CART.PROMOTION.DESTINATION')}</Typography>{' '}
        {promotionType === 'sap' ? (
          <Typography variant="body">
            {t('SHOPPING_CART.PROMOTION.PROVIDED_AS_PART_OF_DELIVERY')}
          </Typography>
        ) : (
          <>
            <Typography variant="body">
              {t('SHOPPING_CART.PROMOTION.PLEASE_CHOOSE_A_STORE')}
            </Typography>
            <RadioGroup
              defaultValue="pdf"
              onChange={(value) => setShippingType(value.target.value as ShippingType)}
              value={shippingType}
            >
              <div className={styles.options}>
                <div className={clsx(styles.card, styles.pickUpCard)}>
                  {/*<RadioGroup />*/}
                  <FormControlLabel
                    className={styles.formControlLabel}
                    value={'DefaultPickup'}
                    control={<Radio />}
                    label={<Typography variant={'bodyBold'}>Abholung</Typography>}
                  />

                  <Select
                    options={storesOptions}
                    selectedOption={storeChosen}
                    onOptionSelected={handleStoreChange}
                    paperClassname={styles.paper}
                  />
                </div>
                {mainAddress.mainAddress && (
                  <div className={styles.card}>
                    <FormControlLabel
                      className={styles.formControlLabel}
                      value={'Cargo'}
                      control={<Radio />}
                      label={<Typography variant={'bodyBold'}>Lieferung</Typography>}
                    />
                    <Box className={clsx(styles.contentBox)}>
                      <Typography variant={'body'}>
                        {mainAddress.mainAddress.address.name1}
                      </Typography>
                      <Typography variant={'body'}>
                        {mainAddress.mainAddress.address.name2}
                      </Typography>
                      <Typography variant={'body'}>
                        {mainAddress.mainAddress.address.street +
                          ' ' +
                          mainAddress.mainAddress.address.houseNumber}
                      </Typography>
                      <Typography variant={'body'}>
                        {mainAddress.mainAddress.address.zipCode +
                          ' ' +
                          mainAddress.mainAddress.address.city}
                      </Typography>
                    </Box>
                  </div>
                )}
              </div>
            </RadioGroup>
          </>
        )}
        <Typography className={styles.info} variant={'smallText'}>
          {t('SHOPPING_CART.PROMOTION.REQUIRED')}
        </Typography>{' '}
      </div>
      <div className={styles.buttonContainer}>
        <DarkButton onClick={handleSubmit} disabled={!!isEnteredNameError}>
          {t('SHOPPING_CART.PROMOTION.SUBMIT')}
        </DarkButton>{' '}
      </div>
    </div>
  )
}
