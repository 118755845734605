import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ReactComponent as ArrowBackIcon } from '@obeta/assets/icon/arrow_back.svg'
import { ReactComponent as ShareIcon } from '@obeta/assets/icon/share_1.svg'
import styles from './ArticleHeader.module.scss'
import { IResponsiveComponentProps } from '@obeta/models/lib/models/Components'
import { ReactComponent as MoreIcon } from '@obeta/assets/icon/more_horiz.svg'
import { ReactComponent as PrintIcon } from '@obeta/assets/icon/print.svg'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { BasePopover } from '@obeta/components/lib/dropdown-button/PopoverDropdownMenu'
import { usePrintArticleDataSheet } from '@obeta/data/lib/hooks/usePrintArticleDataSheet'
import { useProduct } from './ProductProvider'
import { LoadingIndicator } from '@obeta/components/lib/split-button/LoadingIndicator'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { datadogRum } from '@datadog/browser-rum'
import { Share } from '@capacitor/share'
import { Capacitor } from '@capacitor/core'
import { Box } from '@mui/material'
import { DropdownMenu } from '@obeta/components/lib/header/components/dropdown'
import { MenuItemColor } from '@obeta/components/lib/header/components/dropdown/types'
import { ScannerList } from '@obeta/components/lib/scannerlist/ScannerList'
import { ScannerListType } from '@obeta/models/lib/models/PrintService/PrintInput'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { PrintConfigDatasheet } from '@obeta/components/lib/print-config-datasheet/PrintConfigDatasheet'
import { isSubuser } from '@obeta/utils/lib/isSubuser'

const shareData = async (data: Pick<ShareData, 'title' | 'url'>) => {
  const canShare = await Share.canShare()

  if (!canShare.value) return datadogRum.addError(new Error('Data cannot be shared'), data)

  const url = new URL(window.location.href)

  if (Capacitor.isNativePlatform()) {
    // Share url for native part should looks like "https://mobile.obeta.de/..."
    const mobileObetaBaseUrl = new URL(process.env.REACT_APP_BASE_URL || '')

    url.protocol = mobileObetaBaseUrl.protocol || 'https:'
    url.host = mobileObetaBaseUrl.host || 'mobile.obeta.de'
  }

  try {
    await Share.share({ ...data, url: url.href })
  } catch (error) {
    datadogRum.addError(error)
  }
}

export const ArticleHeader: React.FC<IResponsiveComponentProps & { className?: string }> = (
  props
) => {
  const { mobile } = props
  const { t } = useTranslation()
  const popoverState = usePopoverState()
  const history = useHistory()
  const { print } = usePrintArticleDataSheet()
  const product = useProduct()
  const [isActive, setIsActive] = useState(true)
  const [isShareAvailable, setIsShareAvailable] = useState(false)
  const [isScannerListOpen, setScannerListOpen] = useState(false)
  const { id: articleId } = useParams()
  const { isLoggedIn, permissions, userId, companyId } = useUserDataV2()
  const isAdmin = !!(userId && companyId && !isSubuser({ userId, companyId }))

  const isIndividualDataSheetToggle = useFeatureToggle('UseIndividualDataSheetToggle')
  const [isOpenIndividualPrintConfig, setIsOpenIndividualPrintConfig] = useState(false)

  const showDatasheet = permissions?.Global_canUseCustomDataSheet

  useEffect(() => {
    const checkShareAvailability = async () => {
      if (Capacitor.isNativePlatform()) {
        const canShare = await Share.canShare()
        setIsShareAvailable(canShare.value)
      } else {
        setIsShareAvailable(!!navigator.share)
      }
    }

    checkShareAvailability()
  }, [])

  const sapId = product?.sapId

  const onPrint = async () => {
    if (isIndividualDataSheetToggle && showDatasheet) {
      setIsOpenIndividualPrintConfig(true)
      return
    }

    if (sapId) {
      setIsActive(false)
      try {
        await print(sapId)
        setIsActive(true)
      } catch (err) {
        setIsActive(true)
      }
    }
  }

  const actions = [
    isLoggedIn && (
      <DropdownMenu
        classes={{ titleContainerClassName: styles.titleContainerClassName }}
        key="scannerlistMenu"
        title={
          <Box className={styles.actionButtonBox}>
            <TertiaryButton key="print" leftIcon={<PrintIcon />}>
              {t<string>('ARTICLE_DETAIL.PRINT')}
              {!isActive && <LoadingIndicator />}
            </TertiaryButton>
          </Box>
        }
        options={[
          {
            name: t('TEMPLATES_CONTEXT_MENU.SCANNERLIST'),
            id: 'scannerlist',
          },
          { name: t('ARTICLE_DETAIL.DATA_SHIT'), id: 'print' },
        ]}
        color={MenuItemColor.Black}
        withBorder={false}
        onMenuItemClick={(data) => {
          if (data.id === 'print') onPrint()
          if (data.id === 'scannerlist') setScannerListOpen(true)
        }}
        keepDefaultLeftPosition={true}
        topSpacingToAnchorEl={'0.625rem'}
      />
    ),
    isScannerListOpen && (
      <ScannerList
        key="scannerlist"
        parentId={articleId}
        type={ScannerListType.Article}
        isOpen={isScannerListOpen}
        onClose={() => setScannerListOpen(false)}
      />
    ),
    isShareAvailable && (
      <TertiaryButton
        key="share"
        onClick={() => {
          shareData({
            title: document.title,
            url: window.location.href,
          })
        }}
        leftIcon={<ShareIcon />}
      >
        {t<string>('ARTICLE_DETAIL.SHARE_PRODUCT')}
      </TertiaryButton>
    ),
  ]

  const button = mobile ? (
    <div onClick={popoverState.handleClick}>
      <MoreIcon />
    </div>
  ) : (
    <div className={styles.actions}>{actions}</div>
  )

  const onPrintDatasheet = async (requestParamsUrl: string) => {
    if (sapId) {
      setIsActive(false)
      try {
        await print(sapId, requestParamsUrl)
        setIsActive(true)
      } catch (err) {
        setIsActive(true)
      }
    }
  }

  return (
    <div className={clsx(styles.header, props.className)}>
      {mobile && (
        <BasePopover
          withBackdrop={true}
          classes={{ paper: styles.popover }}
          mobile={true}
          onClose={popoverState.onClose}
          anchorEl={popoverState.anchorEl}
          open={popoverState.open}
        >
          {actions}
        </BasePopover>
      )}
      <TertiaryButton
        onClick={() => {
          history.goBack()
        }}
        leftIcon={<ArrowBackIcon />}
      >
        {t<string>('ARTICLE_DETAIL.BACK_TO_ITEM_SELECTION')}
      </TertiaryButton>

      {button}

      {isOpenIndividualPrintConfig && (
        <PrintConfigDatasheet
          isOpen={isIndividualDataSheetToggle}
          onClose={() => setIsOpenIndividualPrintConfig(false)}
          onSubmit={onPrintDatasheet}
          isAdmin={isAdmin}
          permissions={permissions}
        />
      )}
    </div>
  )
}
