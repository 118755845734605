import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning_amber.svg'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'
import { BasicDatePicker } from '../date-picker/BasicDatePicker'
import styles from './ShoppingCartDeliveryOptions.module.scss'

import {
  DisabledReason,
  ShippingDate,
  ShippingOptions,
  ShippingType,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import {
  ShoppingCartFilterEnum,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'

import dayjs from 'dayjs'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { ContentMask } from './ContentMask'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'

interface Props {
  cartContainsSpecialItems: boolean
  cartContainsNonSendableItems: boolean
  preFilterCartItems: (mode: ShoppingCartFilterEnum) => void
  specialTour: boolean
  shippingOptionsForDelivery: ShippingOptions[]
  cart: ShoppingCartV2
  selectedShippingDate: string
  selectedShippingType: string
  updateShippingDate: (date: string) => void
  updateShippingType: (shippingType: ShippingType) => void
  setValuesForMemoryBox: (param) => void
  isDeactivated?: boolean
}

export const ShoppingCartDeliveryOptions: React.FC<Props> = (props) => {
  // TODO: check state, it gets never set
  const [currentShippingDate] = React.useState('')

  const deliveryDatesForDatePicker = useRef<string[]>([])
  const {
    cartContainsSpecialItems,
    cartContainsNonSendableItems,
    preFilterCartItems,
    specialTour,
    shippingOptionsForDelivery,
    cart,
    selectedShippingDate,
    selectedShippingType,
    updateShippingDate,
    updateShippingType,
    setValuesForMemoryBox,
    isDeactivated,
  } = props

  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const theme = useTheme()

  const availableShippingTypes: string[] = ['']
  shippingOptionsForDelivery?.forEach((shippingOption) => {
    availableShippingTypes.push(shippingOption.shippingType)
  })

  useEffect(() => {
    // re-set possible delivery dates should delivery options change
    if (shippingOptionsForDelivery.length === 0) {
      return
    }
    const shippingDatesForInitialDeliveryType = shippingOptionsForDelivery
      .find(
        (shippingOption) =>
          shippingOption.shippingType === selectedShippingType && shippingOption.isEnabled
      )
      ?.shippingDates.map((shippingDate) => shippingDate.date) as string[]

    if (shippingDatesForInitialDeliveryType) {
      deliveryDatesForDatePicker.current = shippingDatesForInitialDeliveryType
      // setCurrentShippingDate(shippingDatesForInitialDeliveryType[0])
    }
  }, [selectedShippingType, shippingOptionsForDelivery])

  React.useEffect(() => {
    setValuesForMemoryBox({ shippingData: { shippingDate: currentShippingDate } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShippingDate])

  const [freeDefaultDeliveryThresholdReached, setFreeDefaultDeliveryThresholdReached] =
    useState<boolean>(false)
  const [freeUrgentDeliveryThresholdReached, setFreeUrgentDeliveryThresholdReached] =
    useState<boolean>(false)

  const requiredSpenditures = shippingOptionsForDelivery.map((item) => {
    let requiredAmount = 0
    if (cart.shoppingCartPrices && item?.shippingCosts?.freeDeliveryThreshold) {
      requiredAmount = item?.shippingCosts?.freeDeliveryThreshold - cart.shoppingCartPrices.net
    }
    return {
      shippingType: item.shippingType,
      requiredAmount: requiredAmount > 0 ? requiredAmount : 0,
    }
  })

  useEffect(() => {
    requiredSpenditures.forEach((item) => {
      if (item.shippingType === ShippingType.DefaultParcel && item.requiredAmount === 0) {
        setFreeDefaultDeliveryThresholdReached(true)
      }
      if (item.shippingType === ShippingType.FastParcel && item.requiredAmount === 0) {
        setFreeUrgentDeliveryThresholdReached(true)
      }
    })
  }, [requiredSpenditures])

  const defaultParcelShippingOption = shippingOptionsForDelivery.find(
    (shippingOption) => shippingOption.shippingType === ShippingType.DefaultParcel
  )
  const fastParcelShippingOption = shippingOptionsForDelivery.find(
    (shippingOption) => shippingOption.shippingType === ShippingType.FastParcel
  )

  const defaultParcelPrice = normalizePrice(
    defaultParcelShippingOption?.shippingCosts.total,
    defaultParcelShippingOption?.shippingCosts.currency ?? '€'
  )
  const fastParcelPrice = normalizePrice(
    fastParcelShippingOption?.shippingCosts.total,
    fastParcelShippingOption?.shippingCosts.currency ?? '€'
  )
  const showDeliveryDateWarning = shippingOptionsForDelivery.find(
    (shippingOption) => shippingOption.disabledReason === DisabledReason.NotAllAvailableInStore
  )

  const handleChangingShippingType = (event) => {
    const selectedShippingOption = shippingOptionsForDelivery.find(
      (shippingOption) => shippingOption.shippingType === event.currentTarget.value
    )
    if (selectedShippingOption) {
      updateShippingType(selectedShippingOption.shippingType)
    }

    deliveryDatesForDatePicker.current = selectedShippingOption?.shippingDates.map(
      (shippingDate: ShippingDate) => shippingDate.date
    ) as string[]
  }

  const defaultParcelDisabled =
    shippingOptionsForDelivery.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.DefaultParcel
    )?.isEnabled === false

  const fastParcelDisabled =
    shippingOptionsForDelivery.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.FastParcel
    )?.isEnabled === false

  const cargoDisabled =
    shippingOptionsForDelivery.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.Cargo
    )?.isEnabled === false

  return (
    <div className={styles.deliveryOptions}>
      {isDeactivated && <ContentMask />}
      <Box>
        <Typography data-testid="delivery-options-header" variant={'h4'}>
          {t('SHOPPING_CART.CHECKOUT.DELIVERY_OPTIONS')}
        </Typography>
      </Box>
      <Grid container direction={'row'} className={styles.optionsOuterGrid}>
        <Grid xs={12}>
          {/** ToDo: is this all meant to be grids in grids or are there missin grid items? and why using a grid if the most of the spacing is done individually anyhow? */}
          <BasicDatePicker
            datesAsString={deliveryDatesForDatePicker.current}
            updateShippingDate={updateShippingDate}
            selectedShippingDate={selectedShippingDate}
          />
        </Grid>
        <Grid>
          {/* Info Sonderartikel */}
          {cartContainsSpecialItems && (
            <Box pt={1}>
              <SimpleInfoBlock
                variant="dark"
                Icon={WarningIcon}
                body={t('SHOPPING_CART.CHECKOUT.DELIVERY_SPECIAL_ITEMS_WARNING')}
              />
            </Box>
          )}

          {showDeliveryDateWarning && (
            <Box pt={1}>
              <SimpleInfoBlock
                variant={'dark'}
                Icon={InfoIcon}
                body={t('SHOPPING_CART.CHECKOUT.DELIVERY_DATE_WARNING')}
              />
            </Box>
          )}

          {specialTour ? (
            <RadioGroup>
              <FormControlLabel
                value="4"
                control={
                  <Radio className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)} />
                }
                label={
                  <Typography data-testid="delivery-special-tour" variant={'bodyBold'}>
                    {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_SPECIAL_TOUR')}
                  </Typography>
                }
                className={styles.formControlLabel}
                checked={specialTour}
              />
              <Typography
                data-testid="delivery-special-tour-info"
                variant={'smallText'}
                className={`${styles.radioOptionInfoText} ${styles.success}`}
              >
                {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_DEFAULT_INFO', {
                  date: dayjs(selectedShippingDate).locale('de').format('dd, DD.MM.YYYY'),
                })}
              </Typography>
            </RadioGroup>
          ) : (
            <RadioGroup onChange={handleChangingShippingType} value={selectedShippingType}>
              {availableShippingTypes.includes(ShippingType.DefaultParcel) && (
                <Grid>
                  <FormControlLabel
                    data-testid="delivery-default"
                    value={ShippingType.DefaultParcel}
                    control={
                      <Radio
                        className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)}
                      />
                    }
                    label={
                      <Typography
                        variant={'bodyBold'}
                        color={
                          fastParcelDisabled || cartContainsNonSendableItems
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary
                        }
                      >
                        {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_DEFAULT', {
                          defaultDeliveryPrice:
                            defaultParcelShippingOption?.shippingCosts &&
                            defaultParcelShippingOption?.shippingCosts?.total > 0
                              ? defaultParcelPrice
                              : '',
                        })}
                      </Typography>
                    }
                    disabled={defaultParcelDisabled || cartContainsNonSendableItems}
                    className={styles.formControlLabel}
                  />
                </Grid>
              )}

              {availableShippingTypes.includes(ShippingType.DefaultParcel) &&
                !cartContainsNonSendableItems &&
                !defaultParcelDisabled && (
                  <Typography
                    data-testid="delivery-default-info"
                    variant={'smallText'}
                    className={`${styles.radioOptionInfoText} ${styles.success}`}
                  >
                    {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_DEFAULT_INFO', {
                      date: dayjs(selectedShippingDate).locale('de').format('dd, DD.MM.YYYY'),
                    })}
                    {!freeDefaultDeliveryThresholdReached &&
                      defaultParcelShippingOption?.shippingCosts &&
                      defaultParcelShippingOption?.shippingCosts?.total > 0 &&
                      t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.SAVE_DELIVERY_COSTS_INFO', {
                        amount: normalizePrice(
                          requiredSpenditures.find((item) => {
                            return item.shippingType === ShippingType.DefaultParcel
                          })?.requiredAmount,
                          '€'
                        ),
                      })}
                  </Typography>
                )}

              {availableShippingTypes.includes(ShippingType.DefaultParcel) &&
                cartContainsNonSendableItems && (
                  <Typography
                    data-testid="delivery-non-sendable-items"
                    variant={'smallText'}
                    className={styles.radioOptionInfoText}
                  >
                    {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.0')}
                    <u className={styles.cursorPointer}>
                      <strong
                        onClick={() => preFilterCartItems(ShoppingCartFilterEnum.NON_SENDABLE)}
                      >
                        {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.1')}
                      </strong>
                    </u>
                    {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.2')}
                  </Typography>
                )}

              {availableShippingTypes.includes(ShippingType.FastParcel) && (
                <Grid>
                  <FormControlLabel
                    data-testid="delivery-fast-parcel"
                    value={ShippingType.FastParcel}
                    control={
                      <Radio
                        className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)}
                      />
                    }
                    label={
                      <Typography
                        variant={'bodyBold'}
                        color={
                          fastParcelDisabled || cartContainsNonSendableItems
                            ? theme.palette.text.disabled
                            : theme.palette.text.primary
                        }
                      >
                        {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_URGENT', {
                          urgentDeliveryPrice:
                            fastParcelShippingOption?.shippingCosts &&
                            fastParcelShippingOption?.shippingCosts.total > 0
                              ? fastParcelPrice
                              : '',
                        })}
                      </Typography>
                    }
                    disabled={fastParcelDisabled || cartContainsNonSendableItems}
                    className={styles.formControlLabel}
                  />
                </Grid>
              )}
              {availableShippingTypes.includes(ShippingType.FastParcel) &&
                !cartContainsNonSendableItems &&
                !fastParcelDisabled && (
                  <Typography
                    data-testid="delivery-fast-parcel-info"
                    variant={'smallText'}
                    className={`${styles.radioOptionInfoText} ${styles.success}`}
                  >
                    {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_URGENT_INFO', {
                      date: dayjs(selectedShippingDate).locale('de').format('dd, DD.MM.YYYY'),
                      time: dayjs(
                        shippingOptionsForDelivery.find((item) => {
                          return item.shippingType === ShippingType.FastParcel
                        })?.shippingDates[0].time
                      ).format('HH'),
                    })}
                    {!freeUrgentDeliveryThresholdReached &&
                      fastParcelShippingOption?.shippingCosts &&
                      fastParcelShippingOption?.shippingCosts.total > 0 &&
                      t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.SAVE_DELIVERY_COSTS_INFO', {
                        amount: normalizePrice(
                          requiredSpenditures.find((item) => {
                            return item.shippingType === ShippingType.FastParcel
                          })?.requiredAmount,
                          '€'
                        ),
                      })}
                  </Typography>
                )}
              {availableShippingTypes.includes(ShippingType.FastParcel) &&
                cartContainsNonSendableItems && (
                  <Typography
                    data-testid="delivery-non-sendable-items"
                    variant={'smallText'}
                    className={styles.radioOptionInfoText}
                  >
                    {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.0')}
                    <u className={styles.cursorPointer}>
                      <strong
                        onClick={() => preFilterCartItems(ShoppingCartFilterEnum.NON_SENDABLE)}
                      >
                        {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.1')}
                      </strong>
                    </u>
                    {t('SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.2')}
                  </Typography>
                )}
              {availableShippingTypes.includes(ShippingType.Cargo) && (
                <Grid>
                  <FormControlLabel
                    data-testid="delivery-cargo"
                    value={ShippingType.Cargo}
                    control={
                      <Radio
                        className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)}
                      />
                    }
                    label={
                      <Typography
                        variant={'bodyBold'}
                        color={
                          cargoDisabled ? theme.palette.text.disabled : theme.palette.text.primary
                        }
                      >
                        {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_LKW')}
                      </Typography>
                    }
                    className={styles.formControlLabel}
                    disabled={cargoDisabled}
                  />
                </Grid>
              )}
              {availableShippingTypes.includes(ShippingType.Cargo) && !cargoDisabled && (
                <Typography
                  data-testid="delivery-cargo-info"
                  variant={'smallText'}
                  className={`${styles.radioOptionInfoText} ${styles.success}`}
                >
                  {t('SHOPPING_CART.CHECKOUT.DELIVERY_RADIO_OPTIONS.OPTION_LKW_INFO', {
                    date: dayjs(selectedShippingDate).locale('de').format('dd, DD.MM.YYYY'),
                  })}
                </Typography>
              )}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12}>
          <Divider className={styles.divider} />
        </Grid>
      </Grid>
    </div>
  )
}
