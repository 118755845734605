import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  SvgIcon,
  Typography,
} from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  heading: string
  body?: string
  handleConfirm: () => void
  openAlertDialog: boolean
}

export const Alert: React.FC<Props> = (props) => {
  const { heading, body, handleConfirm, openAlertDialog } = props
  const { t } = useTranslation()

  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { width: '18.75rem', m: 0, pb: 1 } }}
      open={openAlertDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {heading}
      </DialogTitle>
      {body && (
        <DialogContent sx={{ p: 0, px: 1, pb: 1, textAlign: 'center' }}>
          <Typography variant={'body'} color={'secondary'}>
            {body}
          </Typography>
        </DialogContent>
      )}
      <Divider variant="middle" sx={{ mx: 1 }} />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          pt: 1,
          px: 1.5,
          pb: 0,
        }}
      >
        <Button
          variant={'outlined'}
          fullWidth={false}
          startIcon={<SvgIcon component={CheckIcon} color="success" />}
          onClick={handleConfirm}
          autoFocus
        >
          {t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
