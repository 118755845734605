import { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import featureToggleService from '@obeta/data/lib/hooks/feature-toggles/FeatureToggleService'

export const useSetldClient = () => {
  const ldClient = useLDClient()

  const ldContext = ldClient?.getContext()

  useEffect(() => {
    featureToggleService.setClient(ldClient)
  }, [ldClient, ldContext])

  return {
    ldClient,
  }
}
