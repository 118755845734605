export const removeValues = <T>(values: T[], list: T[]): T[] => {
  let updatedValues: T[] = [...list]
  if (values.length > 0) {
    for (const filterValue of values) {
      updatedValues = updatedValues.filter((value) => value !== filterValue)
    }
    // Return updated list if values have been removed
    if (updatedValues.length < list.length) {
      return updatedValues
    }
  }
  return list
}
