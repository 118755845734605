import { useEventCallback } from '@mui/material/utils'
import { noop } from '@obeta/utils/lib/noop'
import { OfferItem } from '../product-card'

export function OfferItemWrapper(props: React.ComponentProps<typeof OfferItem>) {
  const { onChange, onAddClicked, changeProductAmount, onAddToCart = noop, ...others } = props

  const handleChange = useEventCallback(onChange)
  const handleAddClicked = useEventCallback(onAddClicked)
  const handleChangeProductAmount = useEventCallback(changeProductAmount)
  const handleAddToCart = useEventCallback(onAddToCart)

  // Memoize other callbacks if they are used in the future.

  return (
    <OfferItem
      onChange={handleChange}
      onAddClicked={handleAddClicked}
      changeProductAmount={handleChangeProductAmount}
      onAddToCart={handleAddToCart}
      {...others}
    />
  )
}
