import { useEffect } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { GetProductByOxomiDataQuery, GetProductByOxomiDataQueryVariables } from '@obeta/schema'
import { buildSearchUrlWithSearchTerm } from '@obeta/utils/lib/search/buildSearchUrlWithSearchTerm'
import { useUserSelectedStore } from './useUserSelectedStore'

interface ConnectorOptions {
  name: string
}
interface Connector {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addCapability: (name: string, func: (message: any) => void) => void
}
interface Buzz {
  Connector: new (options: ConnectorOptions) => Connector
}
declare const buzz: Buzz

const GET_PRODUCT_BY_LOOKUP = gql`
  query getProductByOxomiData($input: LookupInput!, $storeIds: [String!]!) {
    getProductByLookupIds(input: $input) {
      sapId
      stock(warehouseIds: $storeIds) {
        amount
      }
      prices {
        netPrice
      }
    }
  }
`

export const useOxomiProductEnhancement = () => {
  const apolloClient = useApolloClient()
  const selectedStore = useUserSelectedStore()
  const { warehouseId } = useWarehouseContext()

  async function fetchProductByOxomiPayload(oxomiPayload) {
    return await apolloClient.query<
      GetProductByOxomiDataQuery,
      GetProductByOxomiDataQueryVariables
    >({
      query: GET_PRODUCT_BY_LOOKUP,
      variables: {
        input: {
          searchItem: {
            ean: oxomiPayload.gtin,
            supplierArticleId: oxomiPayload.itemNumber,
            supplierIds: oxomiPayload.supplierNumbers.split(','),
          },
        },
        storeIds: [selectedStore.selectedStore?.id, warehouseId].filter(Boolean) as string[],
      },
    })
  }

  useEffect(() => {
    const enhanceProducts = () => {
      if (!buzz) return
      const shop = new buzz.Connector({
        name: 'SHOP',
      })
      shop.addCapability('enhanceProductData', async function (message) {
        const product = message.payload()
        const response = await fetchProductByOxomiPayload(product)

        if (
          !response.data.getProductByLookupIds ||
          response.data.getProductByLookupIds.length === 0
        ) {
          product.availability = {
            text: 'nicht verfügbar',
            state: 'unknown',
          }
          product.price = {
            amount: '',
          }
          message.reply(product)
          return
        }
        const productResponse = response.data.getProductByLookupIds
        const [firstProduct] = productResponse
        if (!firstProduct.prices) {
          product.availability = {
            text: 'nicht verfügbar',
            state: 'unknown',
          }
          product.price = {
            amount: '',
          }
          message.reply(product)
          return
        }

        if (firstProduct.stock && firstProduct.stock.length > 0) {
          const [firstStock] = firstProduct.stock

          if (firstStock) {
            const hasStock = firstStock.amount > 0
            product.availability = {}
            product.availability.text = hasStock
              ? `${firstStock.amount} verfügbar`
              : 'nicht verfügbar'
            product.availability.state = hasStock ? 'full' : 'unknown'
          }
        }

        product.price = {
          amount: normalizePrice(firstProduct.prices.netPrice),
        }

        message.reply(product)
      })

      shop.addCapability('fetchProductData', async function (message) {
        const product = message.payload()
        const response = await fetchProductByOxomiPayload(product)
        if (!response.data.getProductByLookupIds) return

        const productResponse = response.data.getProductByLookupIds
        const [firstProduct] = productResponse
        if (!firstProduct.prices) {
          product.availability = {
            text: 'nicht verfügbar',
            state: 'unknown',
          }
          product.price = {
            amount: '',
          }
          message.reply(product)
          return
        }

        if (firstProduct.stock && firstProduct.stock.length > 0) {
          const [firstStock] = firstProduct.stock

          if (firstStock) {
            const hasStock = firstStock.amount > 0
            product.availability = {}
            product.availability.text = hasStock
              ? `${firstStock.amount} verfügbar`
              : 'nicht verfügbar'
            product.availability.state = hasStock ? 'full' : 'unknown'
          }
        }

        product.price = {
          amount: normalizePrice(firstProduct.prices.netPrice),
        }

        message.reply(product)
      })
      shop.addCapability('addProductToBasket', async function (message) {
        const product = message.payload().product
        const response = await fetchProductByOxomiPayload(product)
        const openGtinSearch = () => {
          window.open(
            `${window.location.protocol}//${window.location.host}/${buildSearchUrlWithSearchTerm(
              product.gtin
            )}`,
            '_blank'
          )
        }

        if (
          !response.data.getProductByLookupIds ||
          response.data.getProductByLookupIds.length === 0
        ) {
          openGtinSearch()
          return
        }

        const productResponse = response.data.getProductByLookupIds
        const [firstProduct] = productResponse

        if (!firstProduct) {
          openGtinSearch()
        } else {
          window.open(
            `${window.location.protocol}//${window.location.host}/article-detail/${firstProduct.sapId}`,
            '_blank'
          )
        }
      })
    }

    document.addEventListener('oxomi-loaded', enhanceProducts)

    return () => {
      document.removeEventListener('oxomi-loaded', enhanceProducts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
