import { StackScreen } from '@obeta/components/lib/stack'
import { StackNavigator } from '@obeta/components/lib/stack/Navigator'
import { SlideTransition } from '@obeta/components/lib/stack/SlideTransition'
import { EtimClasses } from './ClassesSlide'
import { EtimSlides } from './types'
import { useFiltersStateContext } from '../hooks/useFiltersReducer'
import { Attributes } from './AttributesSlide'
import { KeywordPicker } from './KeywordPickerSlide'
import { Modal as BaseModal, ModalCloseIcon } from '@obeta/components/lib/modal/Modal'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import React from 'react'
import styles from './EtimSlider.module.scss'

interface IEtimSliderModal {
  etimsPopoverState: ReturnType<typeof usePopoverState>
}

export const EtimSliderModal: React.FC<IEtimSliderModal> = ({ etimsPopoverState }) => {
  const handleModalClose = () => etimsPopoverState.onClose()

  return (
    <BaseModal isFullScreen {...etimsPopoverState} keepMounted={false}>
      <div className={styles.root}>
        <ModalCloseIcon className={styles.close} onClose={handleModalClose} />
        <EtimSlider onClose={handleModalClose} />
      </div>
    </BaseModal>
  )
}

interface IEtimSlider {
  onClose: () => void
}

const EtimSlider: React.FC<IEtimSlider> = ({ onClose }) => {
  const { filtersState, dispatchFiltersAction } = useFiltersStateContext()

  return (
    <StackNavigator initialScreenName={EtimSlides.Etim}>
      <SlideTransition>
        <StackScreen className={styles.stackScreen} name={EtimSlides.Etim}>
          <EtimClasses
            selectedEtimClass={filtersState.searchParams.selectedEtim || null}
            dispatchEtimAction={dispatchFiltersAction}
          />
        </StackScreen>
        <StackScreen className={styles.stackScreen} name={EtimSlides.EtimAttributes}>
          <Attributes
            selectedEtimClass={filtersState.searchParams.selectedEtim || null}
            selectedEtimValues={filtersState.selectedEtimValueByFeatureName}
            dispatchEtimAction={dispatchFiltersAction}
          />
        </StackScreen>
        <StackScreen className={styles.stackScreen} name={EtimSlides.EtimAttributesKeywords}>
          <KeywordPicker
            selectedEtimValues={filtersState.selectedEtimValueByFeatureName}
            dispatchEtimsAction={dispatchFiltersAction}
            activeEtimFeatureName={filtersState.activeEtimFeatureName}
            onClose={onClose}
          />
        </StackScreen>
      </SlideTransition>
    </StackNavigator>
  )
}
