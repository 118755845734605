import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { datadogRum } from '@datadog/browser-rum'
import { ListItemIcon, Menu, MenuItem, PopoverOrigin, Typography, IconButton } from '@mui/material'

// Assets
import { ReactComponent as AddToReminderListIcon } from 'assets/icon/designsystem/add_to_reminderlist.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as ContentCopyIcon } from 'assets/icon/designsystem/content_copy.svg'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as ShareIcon } from 'assets/icon/designsystem/share.svg'

// Components
import { CartTemplateContextMenuOwnerInfo } from './list/CartTemplateContextMenuOwnerInfo'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserData } from '@obeta/data/lib/hooks/useUserData'

// Models
import { CartTemplate } from '@obeta/schema'
import {
  CartTemplateItemInput,
  DropdownTemplatesType,
} from '@obeta/models/lib/models/CartTemplates/CartTemplate'

// Styles
import styles from './CartTemplateContextMenu.module.scss'

// UI
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'

// Utils
import { buildFullyQualifiedUserId } from '@obeta/utils/lib/buildFullyQualifiedUserId'
import { isSubuser } from '@obeta/utils/lib/isSubuser'

interface Props {
  anchorElement: HTMLElement | null
  cartTemplate: CartTemplate
  isOnlyTemplate: boolean
  isOpen: boolean
  onClose: () => void
  onDuplicateTemplate: () => void
  onRenameTemplate: () => void
  onSwitchFavourite?: (event) => void
  onSwitchVisibility?: (event) => void
  productsToAdd: CartTemplateItemInput[]
}

export const CartTemplateContextMenuItems: React.FC<
  Props & {
    anchorElPopover: null | Element
    setAnchorElPopover: (anchorElPopover: null | Element) => void
    isPopoverOpen: boolean
    setIsPopoverOpen: (isPopoverOpen: boolean) => void
    handleCloseContextMenu: () => void
  }
> = (props) => {
  const {
    cartTemplate,
    isOnlyTemplate,
    onDuplicateTemplate,
    onRenameTemplate,
    productsToAdd,
    anchorElPopover,
    setAnchorElPopover,
    isPopoverOpen,
    setIsPopoverOpen,
    handleCloseContextMenu,
  } = props

  const isEmpty = !cartTemplate.itemCount
  const isTemplateDefault = cartTemplate.isDefault
  const ownerId = cartTemplate.ownerId
  const templateId = cartTemplate.id
  const templateName = cartTemplate.name

  const { mobile } = useBreakpoints()
  const { t } = useTranslation()
  const { companyId, userId } = useUserData()
  const fullyQualifiedUserId: string =
    (userId && companyId && buildFullyQualifiedUserId({ userId, companyId })) ?? ''
  const isOwnerOfCartTemplate = fullyQualifiedUserId === ownerId
  const isMainUser = (userId && companyId && !isSubuser({ userId, companyId })) ?? false

  const shopUrl = Capacitor.isNativePlatform()
    ? process.env.REACT_APP_BASE_URL
    : window.location.protocol + window.location.hostname

  const menuItemRef = useRef<HTMLLIElement>(null)

  const [isShareAvailable, setIsShareAvailable] = useState(false)

  // 'copy into another template' popover (mobile)/dropdown (tablet & desktop) opened
  const handleClickOnPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPopover(event.currentTarget)
    setIsPopoverOpen(true)
  }

  const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
  const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

  const handleOnDuplicateTemplate = () => {
    onDuplicateTemplate()
    handleCloseContextMenu()
  }

  const handleOnRenameTemplate = () => {
    onRenameTemplate()
    handleCloseContextMenu()
  }

  useEffect(() => {
    const checkShareAvailability = async () => {
      const canShare = await Share.canShare()
      setIsShareAvailable(canShare.value)
    }
    checkShareAvailability()
  }, [])

  const shareData = async (data: Pick<ShareData, 'title' | 'url'>) => {
    const canShare = await Share.canShare()

    if (!canShare.value) return datadogRum.addError(new Error('Data cannot be shared'), data)

    const url = new URL(`${shopUrl}/cart-template-details/${templateId}`)

    try {
      await Share.share({ ...data, url: url.href })
    } catch (error) {
      datadogRum.addError(error)
    }
  }
  const handleOnShare = (data: Pick<ShareData, 'title' | 'url'>) => {
    shareData(data).then(() => handleCloseContextMenu)
  }

  return (
    <div className={styles.items}>
      {(isMainUser || isOwnerOfCartTemplate) && (
        <MenuItem
          onClick={handleOnRenameTemplate}
          disabled={isTemplateDefault}
          className={styles.menuItem}
        >
          <ListItemIcon className={styles.listItemIcon}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          {t('TEMPLATES_CONTEXT_MENU.CHANGE_NAME')}
        </MenuItem>
      )}
      {isShareAvailable && (
        <MenuItem
          onClick={() =>
            handleOnShare({
              title: document.title,
              url: `${shopUrl}/cart-template-details/${templateId}`,
            })
          }
          className={styles.menuItem}
        >
          <ListItemIcon className={styles.listItemIcon}>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          {t('TEMPLATES_CONTEXT_MENU.SHARE')}
        </MenuItem>
      )}
      <MenuItem
        id={'copy-into-other-template-menu-item'}
        ref={menuItemRef}
        onClick={handleClickOnPopover}
        className={styles.menuItem}
        disabled={isOnlyTemplate || isEmpty}
      >
        <ListItemIcon className={styles.listItemIcon}>
          <AddToReminderListIcon fontSize="small" />
        </ListItemIcon>
        {t('TEMPLATES_CONTEXT_MENU.ADD_TO_LIST')}
      </MenuItem>

      <DropdownTemplatesBase
        buttonId={'copy-into-other-template-menu-item'}
        dropdown={{
          anchorEl: anchorElPopover,
          anchorOrigin,
          transformOrigin,
          open: isPopoverOpen,
          onClose: handleCloseContextMenu,
        }}
        idOfTemplateToHide={templateId}
        mobile={mobile}
        productsToAdd={productsToAdd}
        originTemplate={{ id: templateId, name: templateName ?? '' }}
        templatesType={DropdownTemplatesType.ADD_TEMPLATES}
      />
      <MenuItem onClick={handleOnDuplicateTemplate} className={styles.menuItem}>
        <ListItemIcon className={styles.listItemIcon}>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        {t('TEMPLATES_CONTEXT_MENU.DUPLICATE')}
      </MenuItem>
    </div>
  )
}

export const CartTemplateContextMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { mobile, tabletAll, desktop } = useBreakpoints()
  const { anchorElement, cartTemplate, isOpen, onClose } = props

  const [anchorElPopover, setAnchorElPopover] = useState<null | Element>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleCloseContextMenu = () => {
    setIsPopoverOpen(false)
    onClose()
  }

  const contextMenuElement: JSX.Element = (
    <Menu
      id="context-menu"
      classes={{
        list: styles.menu,
        paper: styles.paper,
      }}
      anchorEl={anchorElement}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      onClose={handleCloseContextMenu}
      MenuListProps={{
        'aria-labelledby': 'context-menu',
      }}
    >
      <CartTemplateContextMenuItems
        {...props}
        anchorElPopover={anchorElPopover}
        setAnchorElPopover={setAnchorElPopover}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        handleCloseContextMenu={handleCloseContextMenu}
      />
      <CartTemplateContextMenuOwnerInfo
        createdAt={cartTemplate.createdAt}
        ownerName={cartTemplate.ownerName}
      />
    </Menu>
  )

  const mobilePopover = (
    <PopoverFromBottom isOpen={isOpen} onClose={onClose}>
      <div className={styles.mobileRoot}>
        <div className={styles.mobileHeader}>
          <Typography variant="headline4Bold">
            {t('TEMPLATES_CONTEXT_MENU.MOBILE_HEADLINE')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <CartTemplateContextMenuItems
          {...props}
          anchorElPopover={anchorElPopover}
          setAnchorElPopover={setAnchorElPopover}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          handleCloseContextMenu={handleCloseContextMenu}
        />
        <CartTemplateContextMenuOwnerInfo
          createdAt={cartTemplate.createdAt}
          ownerName={cartTemplate.ownerName}
        />
      </div>
    </PopoverFromBottom>
  )

  return (
    <div>
      {(desktop || tabletAll) && contextMenuElement}
      {mobile && mobilePopover}
    </div>
  )
}
