import { useEffect, useState } from 'react'
import { Subject } from 'rxjs'

const maintenanceModeSubject: Subject<string> = new Subject()

export const getMaintenanceSubject = () => maintenanceModeSubject

export const useMaintenance = () => {
  const [maintenanceMessage, setMaintenance] = useState('')

  useEffect(() => {
    const sub = maintenanceModeSubject.subscribe((active) => {
      setMaintenance(active)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  return {
    maintenanceMessage,
  }
}
