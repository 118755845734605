import { useCurrentProductIds } from './useCurrentProductIds'
import { useQuery } from '@apollo/client'
import { ARTICLE_DETAILS } from '../queries/articleDetails'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'

export const useArticleDetails = (): ProductAggregate | undefined => {
  const variables = useCurrentProductIds()

  const { data } = useQuery<{ getProducts: ProductAggregate[] }>(ARTICLE_DETAILS, {
    variables,
  })

  return data?.getProducts[0]
}
