import clsx from 'clsx'
import React from 'react'
import { FormLabel, Radio, Checkbox } from '@mui/material'
import { TypographyEllipsis } from '../typography/TypographyEllipsis'
import { E2EProps } from '../types'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import styles from './CheckboxRadioItem.module.scss'

interface IRadioItemProps {
  label?: React.ReactText
  labelOptions?: {
    variant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
    className?: string
  }
  rightAdornment?: JSX.Element | null
  id: string
  className?: string
  disabled?: boolean
}

const createItem = <Props,>(Component: React.FC<Props>) => {
  return (props: IRadioItemProps & Props & E2EProps) => {
    const { label, rightAdornment, id, className, labelOptions, ...radioProps } = props
    const restRadioProps = radioProps as unknown as Props

    return (
      <FormLabel htmlFor={id} className={styles.item}>
        <Component
          id={id}
          data-testid="item-select-element"
          className={clsx(styles.component, styles.size, className)}
          {...restRadioProps}
        />
        {label && (
          <TypographyEllipsis
            className={clsx(styles.label, labelOptions?.className, {
              [styles.disabled]: radioProps.disabled,
            })}
            noWrap
            color="secondary.main"
            variant={labelOptions?.variant || 'bodyBold'}
            data-testid="item-label"
            rightAdornment={rightAdornment}
          >
            {label}
          </TypographyEllipsis>
        )}
      </FormLabel>
    )
  }
}

const RadioComp: React.FC<React.ComponentProps<typeof Checkbox>> = (props) => {
  const { className, ...restProps } = props
  return <Radio className={clsx(styles.radio, className)} {...restProps} />
}

const CheckboxComp: React.FC<React.ComponentProps<typeof Checkbox>> = (props) => {
  const { className, ...restProps } = props
  return <Checkbox className={clsx(styles.checkbox, className)} {...restProps} />
}

const CheckboxWithAmountItemComp: React.FC<React.ComponentProps<typeof Checkbox>> = (props) => {
  const { className, ...restProps } = props
  return <Checkbox className={clsx(styles.checkboxWithAmount, className)} {...restProps} />
}

export const RadioItem = createItem(RadioComp)
export const CheckboxItem = createItem(CheckboxComp)

export const CheckboxWithAmountItem = createItem(CheckboxWithAmountItemComp)
