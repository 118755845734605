import React, { useEffect } from 'react'
import { PromotionCheckoutView } from '../promotionPage/promotionCheckout'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { ApolloProvider } from '@apollo/client'
import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { PromotionDataContext, useInitPromotionData } from '@obeta/data/lib/hooks/usePromotionData'
import { calculateProgress } from '@obeta/utils/lib/promotion'

export interface IPromotionCheckoutContainerProps {
  promotionId: string
  promotionItems: ShoppingCartItem[]
}

const PromotionCheckoutComponent: React.FC<IPromotionCheckoutContainerProps> = (props) => {
  const apolloClient = useStrapiGql()

  return (
    <ApolloProvider client={apolloClient}>
      <PromotionCheckoutContainer {...props} />
    </ApolloProvider>
  )
}

const PromotionCheckoutContainer: React.FC<IPromotionCheckoutContainerProps> = (props) => {
  const { promotionId, promotionItems } = props
  const promotionData = useInitPromotionData(promotionId)
  const { content, criteriaType, setProgress } = promotionData
  const promotionProgress = calculateProgress(promotionItems, criteriaType)

  useEffect(() => {
    setProgress(promotionProgress)
  }, [promotionProgress, setProgress])

  if (!content) {
    return null
  }

  return (
    <PromotionDataContext.Provider value={promotionData}>
      <PromotionCheckoutView promotionId={promotionId} />
    </PromotionDataContext.Provider>
  )
}

export default PromotionCheckoutComponent

export async function getStaticProps() {
  return {
    props: {},
  }
}
