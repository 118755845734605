import { Box, Stack, Typography } from '@mui/material'
import { Category } from '@obeta/models/lib/models/Article/Shop/Product'
import React from 'react'
import { Breadcrumbs } from '@obeta/components/lib/breadcrumbs/Breadcrumbs'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { createCategoriesTree } from '@obeta/utils/lib/createCategoriesTree'
import styles from './ArticleCategories.module.scss'
import { CategoryIcon } from '@obeta/components/lib/categories/Categories'
import { constructCatId } from '@obeta/utils/lib/categories'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface ICategoriesBaseProps {
  icon: JSX.Element
  categories: Category[] | undefined
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, idx: number) => void
}

const BaseCategories: React.FC<ICategoriesBaseProps> = (props) => {
  const { icon, categories, onClick } = props
  if (!categories) {
    return null
  }

  return (
    <Box>
      <Breadcrumbs
        leftAdornment={icon}
        breadcrumbs={categories}
        renderBreadcrumb={(cat, idx) => {
          const first = idx === 0
          return (
            <div>
              <TertiaryButton onClick={(e) => onClick?.(e, idx)} fullWidth={false}>
                <Stack direction={'row'}>
                  <Typography
                    flex={1}
                    variant={first ? 'bodyBold' : 'body'}
                    fontWeight={300}
                    className={styles['categoriesText']}
                  >
                    {cat.title}
                  </Typography>
                  <Typography variant="bodyBold">({cat.productCount})</Typography>
                </Stack>
              </TertiaryButton>
            </div>
          )
        }}
      />
    </Box>
  )
}

type CategoriesWithoutIcon = Omit<ICategoriesBaseProps, 'icon'>

export const ObetaBaseCategories: React.FC<CategoriesWithoutIcon> = (props) => {
  const { categories } = props
  return (
    <BaseCategories
      icon={
        <CategoryIcon
          catId={constructCatId(categories && categories[0]?.id)}
          size="md"
          imgSize="xs"
        />
      }
      {...props}
    />
  )
}

export const DehaBaseCategories: React.FC<CategoriesWithoutIcon> = (props) => {
  const { categories } = props
  const { mobile } = useBreakpoints()

  return (
    <BaseCategories
      icon={
        <CategoryIcon
          catId={constructCatId(categories && categories[0]?.id)}
          size={mobile ? 'md' : 'xs'}
        />
      }
      {...props}
    />
  )
}

export const withRedirect = (
  Comp: React.FC<CategoriesWithoutIcon>,
  catType: keyof Pick<ArticleSearchParams, 'obetaCategory' | 'dehaCategory'>
): React.FC<Omit<CategoriesWithoutIcon, 'onClick'>> => {
  return (props) => {
    const changeSearchParams = useChangeSearchParams()
    const { categories } = props
    if (!categories) {
      return null
    }

    return (
      <Comp
        {...props}
        onClick={(e, idx) => {
          const chosenCategory = createCategoriesTree(categories.slice(0, idx + 1))
          if (!chosenCategory) {
            return
          }

          changeSearchParams({
            searchParams: {
              [catType]: chosenCategory,
            },
            route: 'push',
          })
        }}
      />
    )
  }
}

export const DehaCategories = withRedirect(DehaBaseCategories, 'dehaCategory')
export const ObetaCategories = withRedirect(ObetaBaseCategories, 'obetaCategory')
