import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ClickAwayListener, TextField } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as EditIcon } from '@obeta/assets/icon/designsystem/edit.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from './CartTemplateDetailsRenameField.module.scss'
import { FormHelperText } from '../form-helper-text/FormHelperText'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import {
  TemplateActionTypes,
  UpdateCartTemplateGraphQLResultAction,
} from '@obeta/data/lib/actions/template-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { TypographyEllipsis } from '../typography'

type Props = {
  hasEditingRights: boolean
  isRenaming: boolean
  templateName: string
  templateItemsCount: number
  onClose: () => void
  onRenameTemplate: (newName: string) => void
  onOpen: () => void
  isDefaultTemplate?: boolean
}

export const CartTemplateDetailsRenameField: FC<Props> = (props) => {
  const {
    hasEditingRights,
    isDefaultTemplate,
    isRenaming,
    templateName,
    templateItemsCount,
    onClose,
    onRenameTemplate,
    onOpen,
  } = props
  const { desktop } = useBreakpoints()
  const { t } = useTranslation()

  // Refs
  const inputRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement>(null)

  const waitForUpdateCartTemplateAction = useActionNotification(
    TemplateActionTypes.UpdateCartTemplateGraphQLResult
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: templateName,
    },
  })

  // Focus input element
  useEffect(() => {
    inputRef.current && isRenaming && inputRef.current.focus()
  }, [inputRef, isRenaming])

  /**
   * Handler to change cart template name.
   */
  const onSubmitNewCartTemplateName = () => {
    const newName = textFieldRef.current?.value
    onRenameTemplate(newName ?? '')

    waitForUpdateCartTemplateAction((action: UpdateCartTemplateGraphQLResultAction) => {
      if (action.success) {
        onClose()
      } else if (action.errorMessage === 'Template name already in use.') {
        setError('name', { type: 'taken' })
      }
    })
  }

  let errorTextName = ''
  if (errors.name) {
    if (errors.name.type === 'required') {
      errorTextName = t('TEMPLATES.TEMPLATE_NAME_ERROR_REQUIRED')
    } else if (errors.name.type === 'maxLength') {
      errorTextName = t('TEMPLATES.TEMPLATE_NAME_ERROR_MAX_LENGTH')
    } else if (errors.name.type === 'taken') {
      errorTextName = t('TEMPLATES.TEMPLATE_NAME_ERROR_TAKEN')
    }
  }

  return (
    <>
      {!isRenaming && (
        <div className={styles.templateName}>
          <TypographyEllipsis variant="h3">
            {`${templateName} (${templateItemsCount})`}
          </TypographyEllipsis>
          <div className={styles.editButton}>
            {!isDefaultTemplate && hasEditingRights && (
              <TertiaryIconButton
                size={desktop ? 'small' : 'large'}
                onClick={onOpen}
                icon={<EditIcon />}
              />
            )}
          </div>
        </div>
      )}
      {isRenaming && (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
          <form
            aria-label="form"
            className="w-96"
            onSubmit={handleSubmit(onSubmitNewCartTemplateName)}
          >
            <Controller
              control={control}
              defaultValue={templateName}
              rules={{
                required: true,
                maxLength: 20,
              }}
              name="name"
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <TextField
                    id="name"
                    autoFocus={true}
                    className={clsx(
                      styles.textFieldInput,
                      errors.name && styles.textFieldInputError
                    )}
                    defaultValue={templateName}
                    inputProps={{
                      ref: textFieldRef,
                      id: 'name-input',
                    }}
                    inputRef={inputRef}
                    placeholder={t('TEMPLATES.TEMPLATE_NAME')}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                  {errors.name && <FormHelperText errorText={errorTextName} />}
                </>
              )}
            />
          </form>
        </ClickAwayListener>
      )}
    </>
  )
}
