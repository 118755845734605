import { gql } from '@apollo/client'

/**
 * Get data for the different kinds of promotions (Warenkorbaktionen or SAP Zugaben) via promotionId.
 *
 * Since this information is retrieved via Strapi, please use the Strapi GraphQL client.

 * @example
 * const client = useStrapiGql()
 * const { data } = await client.query({
 *   query: GET_OBETA_PROMOTION_QUERY,
 *   variables: { id: '10' },
 * })
 */
export const GET_OBETA_PROMOTION_QUERY = gql`
  query getObetaPromotion($id: ID!) {
    obetaPromotion(id: $id) {
      data {
        attributes {
          name
          content
          startTime
          endTime
          algoliaQuery
          criteriaType
          createdAt
          updatedAt
          showInApp
          showForObeta
          showForHaeusler
          showForEldis
          criteriaUnit
          benefits {
            name
            amount
            goalToReach
            image {
              data {
                attributes {
                  url
                  mime
                  width
                  height
                  formats
                }
              }
            }
          }
          promotionType
        }
      }
    }
  }
`
