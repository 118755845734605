import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Divider, SvgIcon, Typography } from '@mui/material'

// Assets
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as EditIcon } from 'assets/icon/designsystem/edit.svg'
import { ReactComponent as PrintIcon } from '@obeta/assets//icon/designsystem/print.svg'

// Components
import { Checkbox } from '../checkbox/Checkbox'
import { DropdownMenu } from '../header/components/dropdown'
import { MenuItemColor } from '../header/components/dropdown/types'
import { OrderDetailsRenameField } from './OrderDetailsRenameField'
import { OrderStateItem } from './OrderStateItem'
import { OrderInfo } from './OrderInfo'
import { OrderShipping } from './OrderShipping'
import { ShippingDetails } from './ShippingDetails'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useDispatchOrderConfirmation } from '@obeta/data/lib/hooks/useDispatchOrderConfirmation'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useOrderDetailsContext } from '@obeta/data/lib/stores/useOrderDetailsContext'
import { useOrderSearch } from '@obeta/data/lib/hooks/useOrderSearch'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

// Icons
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'

// Models
import { MultiSelectionString } from '@obeta/models/lib/models'
import { OrderShippingDetail, OrderV2 } from '@obeta/schema'
import { OrderItemStateViewType } from '@obeta/models/lib/models/Orders/OrderListViewModels'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'

// Styles
import styles from './OrderDetailHeader.module.scss'

// UI
import { Dropdown2 } from '../dropdown2/Dropdown2'
import { SearchField } from '../search-field/SearchField'
import { MobileRadioGroupPopover } from '../mobile-radio-group-popover/MobileRadioGroupPopover'
import {
  SecondaryIconButton,
  TertiaryButton,
  TertiaryIconButton,
} from '../custom-button/CustomButton'

// Utils
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { updateOrderMetaDataGraphQL } from '@obeta/data/lib/actions'
import { isPrintAvailable } from '@obeta/utils/lib/orders-helpers'

type Props = {
  onSelectAll: () => void
  order: OrderV2
  orderShippingDetails: OrderShippingDetail[] | null
  selectedOrderItems: MultiSelectionString
  resetSelectedOrderItems: () => void
}

export const OrderDetailHeader: FC<Props> = (props) => {
  const { onSelectAll, order, orderShippingDetails, resetSelectedOrderItems, selectedOrderItems } =
    props

  const { desktop, mobile } = useBreakpoints()
  const dispatch = useDispatch()
  const history = useHistory()
  const { itemState, itemStateOptions, orderItems, searchTerm, setItemState, setSearchTerm } =
    useOrderDetailsContext()
  const { dispatchOrderConfirmationPdf } = useDispatchOrderConfirmation()
  const { getShippingAddress } = useOrderSearch()
  const user = useUserDataV2()

  const { t } = useTranslation()

  // Component state
  const [isRenaming, setIsRenaming] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)

  /**
   * Handler to go back. Use previous url if history is available.
   */
  const onGoBack = useCallback(() => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(isUiTarget('app') ? '/orders' : ShopRoutes.OrdersList)
    }
  }, [history])

  /**
   * Handler to filter by item state.
   * @param itemState OrderItemStateViewType
   */
  const onItemStateFilter = (itemState: OrderItemStateViewType) => {
    setItemState(itemState)
    resetSelectedOrderItems()
    setShowFilter(false)
  }

  const onRenameOrder = (newName: string) => {
    dispatch(
      updateOrderMetaDataGraphQL({
        orderId: order.id,
        orderName: newName,
      })
    )
  }

  const isDelivery =
    order.shippingType === 'Cargo' ||
    order.shippingType === 'DefaultParcel' ||
    order.shippingType === 'FastParcel'

  const isArchivedOrder = order.itemStates.some((itemState) => itemState.type === 'Archived')

  return (
    <div className={styles.wrapper}>
      <div className={styles.back}>
        <TertiaryButton
          leftIcon={<ArrowBackIcon />}
          size={desktop ? 'small' : 'large'}
          onClick={onGoBack}
        >
          {t('COMMON.BACK')}
        </TertiaryButton>
        {isPrintAvailable(order) &&
          (user?.permissions?.Global_canReadPrices !== PricePermissions.PurchasePrice ? (
            <TertiaryButton
              leftIcon={<PrintIcon />}
              size={desktop ? 'small' : 'large'}
              onClick={() => dispatchOrderConfirmationPdf(order.id, false)}
            >
              {desktop ? t('ORDERS.PRINT_ORDER') : null}
            </TertiaryButton>
          ) : (
            <DropdownMenu
              title={
                !mobile ? (
                  <TertiaryButton
                    size={desktop ? 'small' : 'large'}
                    leftIcon={<SvgIcon component={PrintIcon} />}
                  >
                    {t('ORDERS.PRINT_ORDER')}
                  </TertiaryButton>
                ) : (
                  <TertiaryIconButton size={'large'} icon={<PrintIcon />} />
                )
              }
              options={[
                {
                  name: t('ORDERS.ORDER_INCL_PRICES'),
                  id: `print_with_prices_${order.id}`,
                },
                {
                  name: t('ORDERS.ORDER_EXCL_PRICES'),
                  id: `print_without_prices_${order.id}`,
                },
              ]}
              color={MenuItemColor.Black}
              hideArrow={true}
              withBorder={false}
              onMenuItemClick={(data) => {
                if (data.id === `print_with_prices_${order.id}`) {
                  dispatchOrderConfirmationPdf(order.id, true)
                }

                if (data.id === `print_without_prices_${order.id}`)
                  dispatchOrderConfirmationPdf(order.id, false)
              }}
              keepDefaultLeftPosition={true}
              topSpacingToAnchorEl={'0.625rem'}
            />
          ))}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.titleAndPrices}>
        <div className={styles.titleAndEditButtonWrapper}>
          <OrderDetailsRenameField
            isRenaming={isRenaming}
            orderName={order.name}
            orderItemCount={order.itemCount}
            onRenameOrder={onRenameOrder}
            onClose={() => setIsRenaming(false)}
            orderId={order.id}
          />
          {!isArchivedOrder && (
            <TertiaryIconButton
              icon={<EditIcon />}
              size={desktop ? 'small' : 'large'}
              onClick={() => setIsRenaming(true)}
            />
          )}
        </div>
        <div className={styles.titleAndPricesInfo}>
          <div className={styles.titleAndPricesInfoItem}>
            <Typography variant="subTitle">{t('ORDERS.DETAILS.SURCHARGE')}</Typography>
            <Typography variant="headline4Bold">
              {normalizePrice(order.supplementaryCosts)}
            </Typography>
          </div>
          <div className={styles.titleAndPricesInfoItem}>
            <Typography variant="subTitle">{t('ORDERS.DETAILS.ORDER_VALUE')}</Typography>
            <Typography variant="headline4Bold">{normalizePrice(order.orderTotalSum)}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.flags}>
        {order.itemStates.map((itemState) => (
          <OrderStateItem key={itemState.type} itemState={itemState} />
        ))}
      </div>
      <Card className={styles.card} elevation={1}>
        <OrderInfo order={order} />
        <OrderShipping
          address={getShippingAddress(order.shippingAddress, order.shippingType, order.storeId)}
          date={order.shippingDate}
          detailView
          gap
          type={order.shippingType}
        />
      </Card>
      {orderShippingDetails && orderShippingDetails.length > 0 && (
        <Typography className={styles.shippingSectionTitle} variant="headline4Bold">
          {isDelivery
            ? t('ORDERS.SHIPPING_DETAILS.DELIVERIES')
            : t('ORDERS.SHIPPING_DETAILS.PICKUP')}
        </Typography>
      )}
      {orderShippingDetails?.map((orderShippingDetail) => {
        return (
          <ShippingDetails
            key={`${orderShippingDetail.orderState?.type ?? ''}-${
              orderShippingDetail.deliverySlipId ?? ''
            }-${orderShippingDetail.invoiceId ?? ''}`}
            orderShippingDetails={orderShippingDetail}
          />
        )
      })}

      <div className={styles.options}>
        <Typography className={styles.optionsTitle} variant="headline4Bold">{`${
          orderItems.length
        } ${t('COMMON.ARTICLE')}`}</Typography>
        <div className={styles.optionsContentNext}>
          {mobile ? (
            <>
              <SearchField
                placeholder={t('ORDERS.DETAILS.SEARCH.PLACEHOLDER')}
                value={searchTerm}
                onChange={(value) => {
                  setSearchTerm(value)
                  resetSelectedOrderItems()
                }}
                onReset={() => {
                  setSearchTerm('')
                  resetSelectedOrderItems()
                }}
              />
              <div className={styles.optionsContentNextCheckboxAndSelect}>
                <Checkbox
                  checked={selectedOrderItems.selectAll}
                  indeterminate={
                    (selectedOrderItems.selectAll && selectedOrderItems.exclude.length > 0) ||
                    selectedOrderItems.include.length > 0
                  }
                  label={`${t('COMMON.SELECT_ALL')} (${orderItems.length})`}
                  onChange={onSelectAll}
                />
                <SecondaryIconButton
                  icon={<FilterAltIcon />}
                  onClick={() => setShowFilter(!showFilter)}
                  size="large"
                />
                <MobileRadioGroupPopover
                  label={t('COMMON.STATUS')}
                  open={showFilter}
                  options={itemStateOptions}
                  value={itemState}
                  onClose={() => setShowFilter(false)}
                  onSubmit={(value) => onItemStateFilter(value as OrderItemStateViewType)}
                />
              </div>
            </>
          ) : (
            <>
              <Checkbox
                checked={selectedOrderItems.selectAll}
                indeterminate={
                  (selectedOrderItems.selectAll && selectedOrderItems.exclude.length > 0) ||
                  selectedOrderItems.include.length > 0
                }
                label={`${t('COMMON.SELECT_ALL')} (${orderItems.length})`}
                onChange={onSelectAll}
              />
              <SearchField
                placeholder={t('ORDERS.DETAILS.SEARCH.PLACEHOLDER')}
                value={searchTerm}
                onChange={(value) => {
                  setSearchTerm(value)
                  resetSelectedOrderItems()
                }}
                onReset={() => {
                  setSearchTerm('')
                  resetSelectedOrderItems()
                }}
              />
              <Dropdown2
                label={t('COMMON.STATUS')}
                options={itemStateOptions}
                value={itemState}
                onChange={(value) => {
                  onItemStateFilter(value as OrderItemStateViewType)
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
