import { gql, useQuery } from '@apollo/client'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import type { IProductIds } from './useCurrentProductIds'
import { handleError } from '@obeta/utils/lib/datadog.errors'

// TODO: subset of ProductAggregate
type RelatedProduct = ProductAggregate

export const RELATED_PRODUCTS = gql`
  query getRelated(
    $sapIds: [String!]
    $obetaIds: [String!]
    $types: [RelatedProductType!]!
    $warehouseIds: [String!]!
    $offset: Float
    $limit: Float
  ) {
    getProducts(sapIds: $sapIds, obetaIds: $obetaIds) {
      id
      sapId
      related(offset: $offset, limit: $limit, types: $types) {
        id
        sapId
        priceDimension
        title
        dehaId
        oxomiId
        obetaId
        unit
        minimumAmount
        isSendable
        isTopseller
        isCutProduct
        isForSale
        isBlackListed
        type
        prices {
          listPrice
          catalogPrice
          metalNeAddition
          strikeThroughPrice
          netPrice
          currency
        }
        supplierImageData {
          large
        }
        imageData {
          images {
            large
          }
        }
        images {
          url
          width
        }
        stock(warehouseIds: $warehouseIds) {
          sapId
          warehouseId
          amount
          unit
        }
        stockAvailabilityEstimate {
          sapId
          minValue
          maxValue
          error
          unit
        }
      }
    }
  }
`

export enum RelatedProductEnum {
  Accessories = 'Accessories',
  BaseProduct = 'BaseProduct',
  ConsistsOf = 'Consists_of',
  Followup = 'Followup',
  Mandatory = 'Mandatory',
  Similar = 'Similar',
  Select = 'Select',
  Sparepart = 'Sparepart',
  Others = 'Others',
  None = 'none',
}
const defaultRelatedProducts: RelatedProduct[] = []

export const useRelatedProducts = (
  productIds: IProductIds,
  types: RelatedProductEnum[],
  selectedStoreId: string | undefined
): RelatedProduct[] => {
  const relatedTypes = types.filter((type) => type !== RelatedProductEnum.None)
  const { warehouseId } = useWarehouseContext()
  const { error, data } = useQuery<{ getProducts: ProductAggregate[] }>(RELATED_PRODUCTS, {
    variables: {
      offset: 0,
      limit: 20,
      ...productIds,
      types: relatedTypes,
      warehouseIds: [selectedStoreId, warehouseId],
    },
    skip: relatedTypes.length === 0 || !selectedStoreId,
  })

  //TODO: if this proves relevant, advanced error handling for useQuery can be used.
  if (error) {
    error.message = 'Apollo client throws error in useRelatedProducts ' + error.message
    console.error(error)
    handleError(error)
  }

  return data?.getProducts[0]?.related || defaultRelatedProducts
}
