import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const useTranslateValues = () => {
  const { t } = useTranslation()

  const tValue = useCallback(
    (val: string | number) => {
      if (val === 'true') {
        return t<string>('COMMON.YES')
      }
      if (val === 'false') {
        return t<string>('COMMON.NO')
      }

      return val
    },
    [t]
  )

  return { tValue }
}
