import React from 'react'
import { CheckboxItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { useFundings } from '@obeta/data/lib/hooks/useFundings'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { DispatchFiltersAction } from '../reducer'
import { E2EProps } from '@obeta/components/lib/types'
import { Size } from '../../../searchPage/filters/Size'
import styles from './Sidebar.module.scss'
import { useFiltersStateContext } from '../../../searchPage/filters/hooks/useFiltersReducer'
import { useTranslation } from 'react-i18next'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'

interface IFundingsProps {
  searchParams: ArticleSearchParamsWithId
  dispatchFiltersAction: DispatchFiltersAction
}

const FUNDINGS_INFO_LINK = '/content/BEGFoederung'

export const Fundings = React.memo<IFundingsProps & E2EProps>(function Fundings(props) {
  const { searchParams, dispatchFiltersAction } = props
  const { fundings } = useFundings({ searchParams })
  const { filtersState } = useFiltersStateContext()
  const { t } = useTranslation()

  const onFilterAction = (e, isChecked) => {
    dispatchFiltersAction({
      type: 'setFundings',
      payload: { fundings: isChecked ? null : [e.value] },
    })
  }

  return (
    <>
      {fundings.map((sup) => {
        const isChecked = Boolean(filtersState.searchParams.fundings?.find((f) => f === sup.value))
        return (
          <div key={sup.value} className={styles.fundingsContainer}>
            <CheckboxItem
              onChange={() => onFilterAction(sup, isChecked)}
              checked={isChecked}
              id={sup.value}
              label={sup.value + t<string>('SEARCH.ARTICLELIST.FILTER.FUNDINGS_APPENDIX')}
              value={sup.value}
              rightAdornment={
                <Size className={styles.size} size={sup.count} datatestid="fundings" />
              }
              datatestid="fundings"
            />
            <ShopLink
              className={styles.fundingsLink}
              href={FUNDINGS_INFO_LINK}
              trackingActionName="sidebar-beg-info"
              target="_blank"
            >
              <InfoIcon className={styles.fundingsInfoIcon} />
            </ShopLink>
          </div>
        )
      })}
    </>
  )
})
