import { useCallback, useReducer } from 'react'
import {
  CartTemplatesListPageState,
  cartTemplatesListReducer,
  initialCartTemplatesListState,
} from './cartTemplatesListReducer'
import { SortBy, Tab } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { CartTemplate } from '@obeta/schema'
import { sort as _sort } from './sort'
import { search as _search } from './search'
import { filterByTab } from './filterByTab'
import { pipe } from '@obeta/utils/lib/pipe'

type Functions = {
  sort: (sortBy: SortBy) => void
  search: (searchTerm: string) => void
  changeTab: (view: Tab) => void
  updateTemplates: (templates: CartTemplate[]) => void
  resetShouldFetchProducts: () => void
}
type Hook = () => {
  state: CartTemplatesListPageState
  filteredSortedTemplates: CartTemplate[]
} & Functions

export const useCartTemplatesListState: Hook = () => {
  const [state, dispatch] = useReducer(cartTemplatesListReducer, initialCartTemplatesListState)

  const sort = useCallback(
    (sortBy: SortBy) => dispatch({ type: 'CHANGE_SORT_BY', payload: sortBy }),
    []
  )
  const search = useCallback(
    (searchTerm: string) => dispatch({ type: 'CHANGE_SEARCH_TERM', payload: searchTerm }),
    []
  )
  const changeTab = useCallback((tab: Tab) => dispatch({ type: 'CHANGE_TAB', payload: tab }), [])

  const updateTemplates = useCallback(
    (templates: CartTemplate[]) =>
      dispatch({ type: 'TEMPLATES_UPDATE_SUCCESS', payload: templates }),
    []
  )
  const resetShouldFetchProducts = useCallback(
    () => dispatch({ type: 'SHOULD_FETCH_PRODUCT_DATA_RESET' }),
    []
  )

  const filteredSortedTemplates: CartTemplate[] = pipe(
    state.templates,
    (templates) => filterByTab(templates, state.tab),
    (templates) => _search(templates, state.searchTerm),
    (templates) => _sort(templates, state.sortBy)
  )
  return {
    state,
    sort,
    search,
    changeTab,
    updateTemplates,
    filteredSortedTemplates,
    resetShouldFetchProducts,
  }
}
