import { useCallback } from 'react'
import { IRentArticle } from '@obeta/models/lib/models/RentArticle/types'
import { ALL_VALUE } from '@obeta/data/lib/hooks/rentArticles'
import { IFiltersCombined } from '../types'

/** Returns a function, that accepts a list of all available rentArticles.
 * The function filters the rentArticles based on location, category and search and returns the result */
export const useDataSelector = (filters: IFiltersCombined) => {
  return useCallback(
    (rentArticles: IRentArticle[]): IRentArticle[] => {
      const { category, location, search } = filters

      return rentArticles.filter((article) => {
        const { addresses } = article
        const addressesList = [addresses.main, ...(addresses.collapsible || [])]

        /** Filter by category */
        if (category.value !== ALL_VALUE && article.category !== category.value) {
          return false
        }

        /** Filter by location */
        if (location.value !== ALL_VALUE) {
          if (!addressesList.some((address) => address?.name === location.value)) {
            return false
          }
        }

        /** Filter by search string. Search is performed in title, description, category and addresses */
        if (search) {
          const searchStringLower = search.value.toLowerCase()

          const foundInTitle = article.title.toLowerCase().includes(searchStringLower)
          const foundInDescription = article.description.toLowerCase().includes(searchStringLower)
          const foundInCategory = article.category.toLowerCase().includes(searchStringLower)
          const foundInAddresses = addressesList.some((address) =>
            address?.name.toLowerCase().includes(searchStringLower)
          )

          if (!foundInTitle && !foundInDescription && !foundInCategory && !foundInAddresses) {
            return false
          }
        }

        return true
      })
    },
    [filters]
  )
}
