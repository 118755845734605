import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { EditorProps } from '@react-page/editor'

interface IStaticPage {
  content?: EditorProps['value']
  loading: boolean
}

const staticQuery = gql`
  query obetaStaticPage(
    $filters: ObetaStaticPageFiltersInput = { pageId: { eq: "terms" } }
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    obetaStaticPages(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        attributes {
          content
        }
      }
    }
  }
`

const getStaticFilters = (pageId?: string, slug?: string) => {
  if (pageId && slug) {
    return { pageId: { eq: pageId }, slug: { eq: slug } }
  }

  return pageId ? { pageId: { eq: pageId } } : { slug: { eq: slug } }
}

export const useStaticPage = (pageId?: string, slug?: string): IStaticPage => {
  const [staticPage, setStaticPage] = useState<IStaticPage | null>(null)
  const [loading, setLoading] = useState(true)
  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getStaticPage = async () => {
      if (!isMounted.current) return
      setLoading(true)
      if (!pageId && !slug) {
        setLoading(false)
        setStaticPage(null)
        return
      }

      const response = await client.query({
        query: staticQuery,
        variables: {
          filters: getStaticFilters(pageId, slug),
        },
      })

      const result = response.data.obetaStaticPages.data[0]?.attributes

      const page = !result
        ? null
        : {
            ...result,
            content: result.content,
          }
      delete page?.__typename
      setStaticPage(page)
      setLoading(false)
    }

    getStaticPage()
  }, [client, pageId, slug])

  return { content: staticPage?.content, loading }
}
