import { ProductAggregate, ProductImage } from '@obeta/models/lib/models/Article/Shop/Product'
import { useArticleDetailsProductMedia } from './useArticleDetailsProductMedia'
import { OxomiImage, OxomiVideo } from './useOxomiData'
import { useFeatureToggle } from './feature-toggles'

export type ThumbId = string | number

export interface IThumb {
  thumbId: ThumbId
  thumbUrl: string
  selected: boolean
}
export interface IImageItem extends Omit<IThumb, 'selected'> {
  url: string
  description?: string
  articleImgProxyData?: {
    width?: number
  }
}

// OxomiVideo already contains thumbUrl
// OxomiVideo contains previewUrl instead of url
export interface IVideoContentItem extends OxomiVideo, Omit<IImageItem, 'url'> {
  contentType: 'video'
}

export interface IImageContentItem extends IImageItem {
  contentType: 'image'
  turntableImages?: string[]
}

export type ContentItem = IVideoContentItem | IImageContentItem

export interface ArticleImages {
  width?: number
  url: string
}
export const mapArticleContentData = (p: {
  articleImagesLegacy: ProductImage[]
  oxomiImages: OxomiImage[]
  oxomiVideos: OxomiVideo[]
  articleImages?: ArticleImages[]
}) => {
  const { articleImagesLegacy, oxomiImages, oxomiVideos, articleImages = [] } = p

  //For now, we use this system as our carrier for articleImgProxyData.

  const items: ContentItem[] = []
  if (articleImages.length === 0) {
    articleImagesLegacy.forEach((imgData, index) => {
      items.push({
        contentType: 'image',
        url: imgData.large,
        thumbId: imgData.large,
        thumbUrl: imgData.large,
      })
    })
    oxomiImages.forEach((imgData, index) => {
      items.push({
        contentType: 'image',
        url: imgData.large,
        thumbId: imgData.thumb,
        thumbUrl: imgData.thumb,
      })
    })
  } else {
    articleImages.forEach((imgData, index) => {
      items.push({
        contentType: 'image',
        url: imgData.url,
        thumbId: imgData.url,
        thumbUrl: imgData.url,
        articleImgProxyData: { width: imgData.width },
      })
    })
  }

  oxomiVideos.forEach((videoData, index) => {
    items.push({
      contentType: 'video',
      thumbId: videoData.id,
      ...videoData,
    })
  })

  return items
}

export const useArticleContentData = (
  article: ProductAggregate | undefined,
  config = { video: true }
) => {
  const useImgProxy = useFeatureToggle('UseImgProxy')
  const articleImagesLegacy = article?.imageData?.images ?? []
  const { oxomiImages, oxomiVideos } = useArticleDetailsProductMedia(
    { images: true, video: config.video },
    article?.oxomiId,
    article?.supplierId
  )

  return mapArticleContentData({
    articleImagesLegacy,
    oxomiImages,
    oxomiVideos,
    articleImages: useImgProxy ? article?.images : [],
  })
}
