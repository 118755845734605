import { useTranslation } from 'react-i18next'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Typography from '@mui/material/Typography'
import { Tooltip } from '../../../tooltip/Tooltip'
import { StoreAddressBlock } from '../../../branch-dropdown/StoreAddressBlock'
import { ReactComponent as Brightness } from 'assets/icon/designsystem/brightness_2.svg'
// import { ReactComponent as LocalGasStation } from 'assets/icon/designsystem/local_gas_station.svg'
import { ReactComponent as AccessTimeScaleable } from 'assets/icon/designsystem/access_time_scaleable.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from './StoresMapInfo.module.scss'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export interface IMapInfoSectionProps {
  store?: StoreV2 | null
}

const parseOpeningHoursFromString = (timeString: string) => {
  const [hours, minutes] = timeString.split(':')
  return dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10))
}

const getSelectedStoreInfo = (store?: StoreV2 | null) => {
  const minOpeningTime = parseOpeningHoursFromString('06:00')
  const maxClosingTime = parseOpeningHoursFromString('18:00')

  let isStoreEarliestOpeningTime = false
  let isStoreLatestClosingTime = false

  if (store) {
    for (const hours of store.openingHours) {
      const openingTime = parseOpeningHoursFromString(hours.from)
      const closingTime = parseOpeningHoursFromString(hours.to)

      if (!isStoreEarliestOpeningTime && openingTime.isSameOrBefore(minOpeningTime))
        isStoreEarliestOpeningTime = true

      if (!isStoreLatestClosingTime && closingTime.isSameOrAfter(maxClosingTime))
        isStoreLatestClosingTime = true

      // No need to iterate further if both flags are true.
      if (isStoreEarliestOpeningTime && isStoreLatestClosingTime) {
        break
      }
    }
  }

  return {
    isStoreEarliestOpeningTime,
    isStoreLatestClosingTime,
  }
}
export const MapInfoSection = ({ store }: IMapInfoSectionProps) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  const { isStoreEarliestOpeningTime, isStoreLatestClosingTime } = getSelectedStoreInfo(store)

  return (
    <div className={styles.mapInfoOutline}>
      {store && (
        <>
          <div className={styles.mapInfo}>
            <div className={styles.mapInfoTop}>
              <Typography variant="bodyBold">{store.address.name1}</Typography>
              <div className={styles.infoIcons}>
                {isStoreEarliestOpeningTime && (
                  <Tooltip
                    title={t('LOCATION_PAGE.EARLY_OPENING_FROM_6')}
                    arrow
                    placement="bottom"
                    activateOnClick={!desktop}
                  >
                    <AccessTimeScaleable className={styles.infoIcon} />
                  </Tooltip>
                )}
                {/*
              TODO: Uncomment when we will decide what it should be
              <Tooltip
                title={t('LOCATION_PAGE.ELEKTRO_TANKSTELLE')}
                arrow
                placement="bottom"
                activateOnClick={!desktop}
              >
                <LocalGasStation className={styles.infoIcon} />
              </Tooltip>*/}
                {isStoreLatestClosingTime && (
                  <Tooltip
                    activateOnClick={!desktop}
                    title={t('LOCATION_PAGE.OPEN_UNTIL_18')}
                    arrow
                    placement="bottom"
                  >
                    <Brightness className={styles.infoIcon} />
                  </Tooltip>
                )}
              </div>
            </div>

            <div className={styles.mapInfoMain}>
              <StoreAddressBlock {...store} />
            </div>
          </div>
          <div className={styles.infoIconsBottom}>
            {isStoreEarliestOpeningTime && (
              <div className={styles.iconWithText}>
                <AccessTimeScaleable />
                <Typography variant="smallText">
                  {t('LOCATION_PAGE.EARLY_OPENING_FROM_6')}
                </Typography>
              </div>
            )}

            {/* TODO: Uncomment when we decide what it should be
        <div className={styles.iconWithText}>
          <LocalGasStation />
          <Typography variant="smallText">{t('LOCATION_PAGE.ELEKTRO_TANKSTELLE')}</Typography>
        </div>*/}
            {isStoreLatestClosingTime && (
              <div className={styles.iconWithText}>
                <Brightness />
                <Typography variant="smallText">{t('LOCATION_PAGE.OPEN_UNTIL_18')}</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
