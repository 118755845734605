import { ButtonBase, Collapse as MuiCollapse, Stack, Typography } from '@mui/material'
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '@obeta/assets/icon/arrow_down1.svg'
import styles from './Collapse.module.scss'
import clsx from 'clsx'
import { Box } from '@mui/system'
import { E2EProps } from '../types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyTypeMap } from '@mui/material/Typography/Typography'
import { Checkbox } from '../checkbox/Checkbox'

export interface ICollapseProps {
  initialExpanded?: boolean
  onToggle?: (state: boolean, groupId?: string) => void
  // When the component is initiated, it calls onToggle on each mount, so we can handle this behavior
  skipOnToggleFirstRender?: boolean
  header: React.ReactChild
  iconLeft?: boolean
  component?: React.ElementType | React.FC<{ className?: string }>
  className?: string
  classes?: IClasses
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { rotated?: boolean }>
  onClick?: MouseEventHandler
  withIcon?: boolean
  disabled?: boolean
  unmountOnExit?: boolean
  headerTextVariant?: string
  headerAlign?: TypographyTypeMap['props']['align']
  isExpanded?: boolean
  groupId?: string
  withCheckbox?: boolean
  ClassificationIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  indeterminate?: boolean
  parentCheckboxValue?: boolean
  onCheckboxChange?: (value: boolean) => void
  boxClassName?: string
  disabledCheckbox?: boolean
}

interface IClasses {
  header?: string
  icon?: string
  iconRotated?: string
  headerText?: string
  body?: string
  svgWrapper?: string
}

const DefIcon: ICollapseProps['Icon'] = (props) => {
  // state props like "rotated" are not passed to svg element
  // in order to avoid validation errors
  return <ArrowDownIcon className={props.className} />
}

export const Collapse: React.FC<ICollapseProps & E2EProps> = (props) => {
  const {
    initialExpanded = true,
    onToggle,
    skipOnToggleFirstRender,
    groupId,
    onClick,
    header,
    children,
    iconLeft = true,
    className,
    classes,
    component: Component = 'div',
    Icon = DefIcon,
    withIcon = true,
    disabled,
    unmountOnExit,
    datatestid,
    headerTextVariant = 'bodyBold',
    headerAlign = 'inherit',
    isExpanded,
    withCheckbox = false,
    ClassificationIcon,
    indeterminate = false,
    parentCheckboxValue,
    onCheckboxChange,
    boxClassName,
    disabledCheckbox,
  } = props
  const [expanded, setExpanded] = useState<boolean>(initialExpanded)
  const onToggleRef = useRef(onToggle)
  const isFirstRender = useRef(true)
  const [checked, setChecked] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked)
    if (onCheckboxChange) onCheckboxChange(event.target.checked)
  }

  useEffect(() => {
    // We can manage the state from outside, but when isExpanded is undefined, the initialExpanded prop should still work correctly
    if (isExpanded !== undefined) {
      if (isExpanded) {
        setExpanded(true)
      } else {
        setExpanded(false)
      }
    }
  }, [isExpanded])

  useEffect(() => {
    if (skipOnToggleFirstRender && isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    onToggleRef.current?.(expanded, groupId)
  }, [expanded, groupId, skipOnToggleFirstRender])

  let icon: JSX.Element | null = null
  if (withIcon) {
    // pass "rotated" state only to FC component
    const rotated = typeof Icon === 'string' ? undefined : expanded

    icon = (
      <Icon
        rotated={rotated}
        className={clsx(styles.icon, classes?.icon, {
          [styles.rotated]: expanded,
          [classes?.iconRotated || '']: expanded,
        })}
      />
    )
  }
  const iconWrap = <Box className={clsx(styles.svgContainer, classes?.svgWrapper)}>{icon}</Box>

  return (
    <Component className={clsx(className)} onClick={onClick} data-testid={datatestid}>
      <Box className={clsx(withCheckbox ? styles.wrapperCheckboxInCollapse : '', boxClassName)}>
        {withCheckbox && (
          <Checkbox
            checked={parentCheckboxValue ?? checked}
            indeterminate={indeterminate}
            onChange={handleChange}
            disabled={disabledCheckbox}
          />
        )}
        {ClassificationIcon && <ClassificationIcon />}

        <ButtonBase
          sx={{ width: '100%' }}
          disabled={disabled}
          onClick={(e) => {
            setExpanded((prevExpanded) => !prevExpanded)
          }}
        >
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes?.header}
          >
            <Stack direction={'row'} gap=".5rem" alignItems={'center'}>
              {iconLeft && icon && iconWrap}
              <Typography align={headerAlign} variant={headerTextVariant as Variant}>
                {header}
              </Typography>
            </Stack>
            {!iconLeft && icon && iconWrap}
          </Stack>
        </ButtonBase>
      </Box>

      <MuiCollapse
        timeout="auto"
        unmountOnExit={unmountOnExit}
        className={clsx(styles.body, classes?.body, { [styles.collapsed]: !expanded })}
        in={expanded}
      >
        {/**we need a wrapping child to make sure our spacing is part of the mui collapse collapse animation */}
        <div className={clsx(styles.bodyContentWrapper)}>{children}</div>
      </MuiCollapse>
    </Component>
  )
}
