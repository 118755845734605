import { Typography } from '@mui/material'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import React from 'react'
import { SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'

type LocationFilterTagProps = {
  branch: StoreV2
  onClick: (filterName: StoreV2) => void
}

export const LocationOverlayTag = ({ branch, onClick }: LocationFilterTagProps) => {
  return (
    <SecondaryButton onClick={() => onClick(branch)}>
      <Typography variant="bodyBold">{branch.address.name2}</Typography>
    </SecondaryButton>
  )
}
