import { CartTemplate } from '@obeta/schema'
import { Tab } from '@obeta/models/lib/models/CartTemplates/CartTemplate'

export const filterByTab = (templates: CartTemplate[], tab: Tab) => {
  if (tab === Tab.PUBLIC) {
    return templates.filter((template) => template.isShared)
  }
  if (tab === Tab.PRIVATE) {
    return templates.filter((template) => !template.isShared)
  }
  if (tab === Tab.FAVORITES) {
    return templates.filter((template) => template.isFavourite)
  }

  return templates
}
