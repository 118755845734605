import { FunctionComponent, SVGProps } from 'react'
import clsx from 'clsx'
import styles from './MapSettingControl.module.scss'

export const MapSettingControl = ({
  onClick,
  className,
  Icon,
}: {
  onClick?: () => void
  className: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>
}) => (
  <div onClick={onClick} className={clsx(styles.mapContolBtn, className)}>
    <Icon />
  </div>
)
