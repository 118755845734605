import React from 'react'
import styles from './SlideTransition.module.scss'
import { TransitionGroup } from './TransitionGroup'

export const SlideTransition: React.FC = (props) => {
  const { children } = props

  return (
    <div className={styles.transitionGroupWrap}>
      <TransitionGroup className={styles.transitionGroup}>{children}</TransitionGroup>
    </div>
  )
}
