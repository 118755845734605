import { Collapse } from '@obeta/components/lib/collapse/Collapse'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, Stack } from '@mui/material'
import styles from './ArticleClasses.module.scss'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

interface IArticleClassesProps {
  header: React.ReactChild
  withIcon?: boolean
  itemsLength: number
  collapseDisabled?: boolean
  canCollapse?: boolean
}

const maxRowsVisible = parseInt(styles.maxRowsVisible)
const itemsGap = parseInt(styles.itemsGap)
const desktopEtimItemHeight = parseInt(styles.desktopEtimItemHeight)

const MINIMUM_ITEMS_DESKTOP = 9
const MINIMUM_ITEMS_MOBILE_TABLET = 6

export const ArticleClassesExpandable: React.FC<IArticleClassesProps> = (props) => {
  const { header, children, withIcon, itemsLength, collapseDisabled, canCollapse = true } = props
  const wrapRef = useRef<HTMLDivElement>(null)
  const [showMore, setShowMore] = useState(false)

  let colsPerRow = 2

  const { desktop } = useBreakpoints()
  if (desktop) {
    colsPerRow = 3
  }

  const itemsGapPx = useRemToPx(itemsGap)
  const desktopEtimItemHeightPx = useRemToPx(desktopEtimItemHeight)

  const maxPossibleHeight =
    maxRowsVisible * desktopEtimItemHeightPx + itemsGapPx * maxRowsVisible - itemsGapPx

  const minItems = desktop ? MINIMUM_ITEMS_DESKTOP : MINIMUM_ITEMS_MOBILE_TABLET
  const canToggle = itemsLength > minItems

  const content = (
    <Stack direction={'column'} alignItems={'center'} gap="1rem">
      <Box
        ref={wrapRef}
        gridTemplateColumns={`repeat(${colsPerRow}, minmax(0, 1fr))`}
        maxHeight={maxPossibleHeight}
        className={clsx(styles.articleClasses, { [styles.showMore]: showMore })}
      >
        {children}
      </Box>
      <ToggleMore
        className={styles.toggleMore}
        canToggle={canToggle}
        showMore={showMore}
        toggleMore={() => {
          setShowMore((base) => !base)
        }}
      />
    </Stack>
  )

  return canCollapse ? (
    <Collapse header={header} withIcon={withIcon} disabled={collapseDisabled}>
      {content}
    </Collapse>
  ) : (
    <div>
      <div className={styles.container}>{header}</div>
      {content}
    </div>
  )
}
