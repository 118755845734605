import { gql, useApolloClient } from '@apollo/client'
import {
  DispatchInvoicePdfQuery,
  DispatchInvoicePdfQueryVariables,
  DispatchInvoiceZipQueryVariables,
  DispatchInvoiceZipQuery,
} from '@obeta/schema'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'

export const dispatchInvoiceZipQuery = gql`
  query dispatchInvoiceZip($input: InvoiceZipInput!) {
    dispatchInvoiceZip(input: $input)
  }
`

export const dispatchInvoicePdfQuery = gql`
  query dispatchInvoicePdf($invoiceId: String!) {
    dispatchInvoicePdf(invoiceId: $invoiceId)
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.SapInvoicePending,
    id: 'sap-invoice-pending',
    options: {
      message,
    },
  })
}

export const useDispatchInvoices = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const dispatchInvoicePdf = useCallback(
    async (invoiceId: string) => {
      showPendingMessage(t('MY_ACCOUNTING.PENDING_COPIES'))

      await client.query<DispatchInvoicePdfQuery, DispatchInvoicePdfQueryVariables>({
        query: dispatchInvoicePdfQuery,
        variables: { invoiceId: invoiceId },
      })
    },
    [client, t]
  )

  const dispatchInvoiceZip = useCallback(
    async (invoiceIds: string[]) => {
      showPendingMessage(t('MY_ACCOUNTING.PENDING_COPIES'))

      await client.query<DispatchInvoiceZipQuery, DispatchInvoiceZipQueryVariables>({
        query: dispatchInvoiceZipQuery,
        variables: { input: { invoiceIds: invoiceIds } },
      })
    },
    [client, t]
  )

  return { dispatchInvoicePdf, dispatchInvoiceZip }
}
