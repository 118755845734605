import { useMemo } from 'react'
import { IBenefit } from '@obeta/models/lib/models/Promotion/Promotion'

//currently only these positions are supported by definition of the design, if we ever have more than 3 benefits, we could make this dynamic
const positionsByCount: { [key: number]: number[] } = {
  1: [],
  2: [10, 90],
  3: [10, 50, 90],
}

interface IPromotionProgressOptions {
  progress: number
  stepPositions: number[]
  nextBenefitIndex: number
}

export const usePromotionProgress = (
  cartSum: number,
  benefits: IBenefit[]
): IPromotionProgressOptions => {
  const stepPositions = useMemo(() => positionsByCount[benefits.length], [benefits])
  const lastBenefitGoal = benefits[benefits.length - 1].goalToReach
  const receivedRewards = useMemo(
    () =>
      benefits.map((benefit) => {
        return benefit.goalToReach - cartSum <= 0
      }),
    [cartSum, benefits]
  )
  const nextBenefitIndex =
    receivedRewards.indexOf(false) >= 0 ? receivedRewards.indexOf(false) : benefits.length - 1
  const nextBenefitToAchieve = benefits[nextBenefitIndex]

  const progress = useMemo(() => {
    /*
     * 100% is max possible, but as benefits are not equidistantly placed eg(10€, 100€,50000€), but step labels are
     * we want to enlarge the progress to reflect how much percentage of the next acievement you really have,
     * not how much total of the last possible achievement you have
     */

    //easy way out / if last is achieved 100% are achieved
    if (lastBenefitGoal <= cartSum) return 100

    const currentProgressToNextGoal = (cartSum / nextBenefitToAchieve.goalToReach) * 100
    //if only one benefit is given, no need to scale
    if (benefits.length === 1) return currentProgressToNextGoal

    //else scale the percentages in relation to the goal position
    const nextGoalPosition = stepPositions[nextBenefitIndex]
    return nextGoalPosition * (currentProgressToNextGoal / 100)
  }, [
    lastBenefitGoal,
    cartSum,
    nextBenefitToAchieve.goalToReach,
    benefits.length,
    stepPositions,
    nextBenefitIndex,
  ])

  return { progress, stepPositions, nextBenefitIndex }
}
