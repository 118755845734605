import { PageContent } from '../../common/components/page-content/PageContent'
import React, { useMemo } from 'react'
import styles from './MessageCenter.module.scss'
import { MessageCard } from '@obeta/components/lib/message-card'
import { Message } from '@obeta/models/lib/models/Message/Message'
import { useSetReadOnScroll } from './hooks/useSetReadOnScroll'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { MangoQuerySortDirection } from 'rxdb'
import { useDeleteNotifications } from './hooks/useDeleteNotifactions'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'

export const MessageCenter: React.FC = () => {
  const query = useMemo(
    () => ({
      selector: {
        id: {
          $exists: true,
        },
      },
      sort: [{ createdAtNumber: 'desc' as MangoQuerySortDirection }],
    }),
    []
  )
  const messages = useEntities<Message>('message', query)
  const carts = useEntities<ShoppingCartV2>('cartsv2')

  useSetReadOnScroll(messages)
  const { deleteNotification } = useDeleteNotifications()

  return (
    <div data-name="message-center-page" className={styles.page}>
      <PageContent>
        <div className={styles.content}>
          <div className={styles.messages}>
            {messages.map((message) => {
              let cartName = ''
              if (message.data.cartId) {
                const cart = carts.find((sc) => sc.id === message.data.cartId)
                if (cart) cartName = cart.name
              }

              return (
                <MessageCard
                  onDelete={() => deleteNotification(message.id)}
                  key={message.id}
                  message={message}
                  cartName={cartName}
                  articleList={[]}
                />
              )
            })}
          </div>
        </div>
      </PageContent>
    </div>
  )
}
