import {
  CellMeasurerCache,
  CellMeasurer,
  ListRowRenderer,
  CellMeasurerProps,
} from 'react-virtualized'
import { useCallback, RefCallback, useRef } from 'react'
import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import useResizeObserver from '@react-hook/resize-observer'
import { useVirtualizedListAnimationRowStyle } from '@obeta/utils/lib/virtualized-list-animation'

interface IParams {
  cache: CellMeasurerCache
  render: (params: { index: number; measure: () => void }) => JSX.Element | null
  wrapperClassName?: string
}

export const useMeasuredRowRenderer = (p: IParams) => {
  const { cache, render, wrapperClassName } = p

  const rowRenderer: ListRowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      return (
        <RowWrapperMeasurer
          key={key}
          cache={cache}
          parent={parent}
          rowIndex={index}
          style={style}
          wrapperClassName={wrapperClassName}
          render={render}
        />
      )
    },
    [cache, render, wrapperClassName]
  )

  return { rowRenderer, cache }
}

const RowWrapperMeasurer: React.FC<Omit<CellMeasurerProps, 'children'> & IParams> = (props) => {
  const { cache, parent, rowIndex: index, style, wrapperClassName, render } = props
  const ref = useRef<HTMLDivElement>(null)
  useResizeObserver(ref.current, (entry) => {
    /**
     * 1)
     * in the mobile app, due to the way ionic hides inactive pages the parent collapses to 0 width, and therefore the whole cache gets invalid as all childrne have 0 width then as well
     * if the element resized to 0 we just leave the cache as is, as this wouldnt be a usefull state anyhow
     *
     * 2)
     * If measured containers change in size, we'll update the underlying cache element
     * RK: todo check this in greater detail
     * if more rows are loaded it can happen, that after realigning, the elements are a few pixels off
     * we wont consider smaller render differences rerender worthy (this would interrupt the scroll flow)
     */
    if (!entry.borderBoxSize || !entry.borderBoxSize[0]) {
      return
    }
    if (
      entry.borderBoxSize[0].blockSize > 0 &&
      Math.abs(cache.getHeight(index, 0) - Math.ceil(entry.borderBoxSize[0].blockSize)) > 3
    ) {
      cache.clear(index, 0)
    }
  })

  const { getStyle } = useVirtualizedListAnimationRowStyle()

  return (
    <CellMeasurer cache={cache} parent={parent} rowIndex={index}>
      {({ measure, registerChild }) => {
        return (
          <div style={getStyle(style, index)} data-test-id={`idx-${index}`}>
            <div
              ref={composeReactRefs(ref, registerChild as RefCallback<HTMLDivElement>)}
              className={wrapperClassName}
            >
              {render({ measure, index })}
            </div>
          </div>
        )
      }}
    </CellMeasurer>
  )
}
