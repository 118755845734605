import React, { useContext } from 'react'
import OfferListPage from '@obeta/layouts/lib/offerListPage/offerListPage'
import { useTranslation } from 'react-i18next'
import { UserContext } from '@obeta/data/lib/hooks/useUserData'
import { PleaseLoginScreen } from '../../common/components/please-login-screen/PleaseLoginScreen'

export const OfferList: React.FC = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useContext(UserContext)

  if (isLoggedIn) {
    return <OfferListPage />
  }

  return (
    <PleaseLoginScreen
      title={t('OFFER_OVERVIEW.GUEST_TEXT_TITLE')}
      body={t('OFFER_OVERVIEW.GUEST_TEXT_DESCRIPTION')}
    />
  )
}
