import React, { useEffect } from 'react'
import { Typography, Box, Stack } from '@mui/material'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useTranslation } from 'react-i18next'
import { datadogRum } from '@datadog/browser-rum'

export const NotFoundPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    datadogRum.addError(`Error: not found page - ${history.location.pathname}`)
  }, [history.location.pathname])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: '2rem',
      }}
    >
      <Typography variant="headline4Bold" sx={{ textAlign: 'center' }}>
        <Stack>
          Ein unbekannter Fehler ist aufgetreten
          <a
            href={ShopRoutes.Root}
            onClick={(e) => {
              e.preventDefault()
              history.push(ShopRoutes.Root)
            }}
            className="legal-button ion-padding"
          >
            {t('COMMON.START_PAGE')}
          </a>
        </Stack>
      </Typography>
    </Box>
  )
}
