import React, { useEffect, useRef, useState } from 'react'
import { SvgIcon } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TemplateIcon } from 'assets/icon/designsystem/playlist_add.svg'
import { useDispatch } from 'react-redux'
import { createNewTemplateGql } from '@obeta/data/lib/actions/template-actions'
import { PopoverProps } from '../dropdown-button/PopoverDropdownMenu'
import { DropdownButton } from '../dropdown-button'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { EntityDropdown, EntityDropdownSearchField } from '../entity-dropdown/EntityDropdown'
import { withUnit } from '@obeta/utils/lib/withUnit'
import styles from './DropdownClasses.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { FacetElement } from '@obeta/models/lib/models/Search'

export interface IDropdownClassesProps
  extends Omit<IDropdownClassBaseProps, 'dropdown' | 'dropdownId'>,
    Pick<IDropdownClassBaseProps['dropdown'], 'anchorOrigin' | 'transformOrigin'> {
  disabled?: boolean
  selectedArticleCount?: number
  withZeroBasedArticleCount?: boolean
  autoFlexed?: boolean
}

export const DropdownClasses: React.FC<IDropdownClassesProps> = (props) => {
  const {
    selectClass,
    anchorOrigin,
    transformOrigin,
    disabled,
    selectedArticleCount,
    withZeroBasedArticleCount,
    autoFlexed,
  } = props
  const { t } = useTranslation()
  const { handleClick, ...dropdown } = usePopoverState()
  const { mobile } = useBreakpoints()
  const buttonId = 'classes-button'

  const withZeroBasedArticleCountLabel = withZeroBasedArticleCount ? '(0)' : ''
  const withCount = selectedArticleCount
    ? `(${selectedArticleCount})`
    : withZeroBasedArticleCountLabel

  let title = withCount
  if (!mobile) {
    title = withUnit(t<string>('SHOPPING_CART.ACTIONS.TEMPLATE_desktop'), withCount)
  }

  return (
    <DropdownButton
      title={title}
      withEndIcon={!mobile}
      buttonId={buttonId}
      dropdownId={'templates-menu'}
      expanded={dropdown.open}
      disabled={disabled}
      onClick={handleClick}
      startIcon={<SvgIcon component={TemplateIcon} />}
      justifyContent="spaceBetween"
      className={autoFlexed ? styles.autoFlex : ''}
    >
      <DropdownClassesBase
        dropdown={{ ...dropdown, anchorOrigin, transformOrigin }}
        selectClass={selectClass}
        buttonId={buttonId}
        classes={[]}
      />
    </DropdownButton>
  )
}

export interface IDropdownClassBaseProps {
  dropdown: Pick<PopoverProps, 'onClose' | 'open' | 'anchorEl' | 'transformOrigin' | 'anchorOrigin'>
  selectClass: (option: { id: string; name: string; count: number }) => void
  buttonId?: string
  classes: FacetElement[]
}

export const DropdownClassesBase: React.FC<IDropdownClassBaseProps> = (props) => {
  const { selectClass, dropdown, buttonId, classes } = props
  const { mobile } = useBreakpoints()
  const [searchText, setSearchText] = useState('')
  const prevDropdownOpen = useRef(dropdown.open)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const classesFilteredBySearch = classes.filter((classItem) => {
    const lowerName = classItem.meta.name.toLowerCase()
    const lowerSearchText = searchText.toLowerCase()
    return lowerName.includes(lowerSearchText)
  })

  useEffect(() => {
    if (prevDropdownOpen.current && !dropdown.open) {
      setSearchText('')
    }
    prevDropdownOpen.current = dropdown.open
  }, [dropdown.open])

  return (
    <EntityDropdown
      buttonId={buttonId}
      createEntityTitle={t<string>('ARTICLE_DETAIL.CREATE_NEW_TEMPLATE')}
      topAdornment={<EntityDropdownSearchField mobile={mobile} onTextUpdated={setSearchText} />}
      mobile={mobile}
      onOptionSelected={(option) => {
        selectClass(option)
        dropdown.onClose()
      }}
      options={classesFilteredBySearch.map((classItem) => {
        return {
          id: classItem.value,
          name: classItem.meta.name,
          count: classItem.count,
        }
      })}
      onEntityCreated={(name) => {
        dispatch(createNewTemplateGql(name))
      }}
      {...dropdown}
    />
  )
}
