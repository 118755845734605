import React, { useEffect } from 'react'
import { PromotionDataContext, useInitPromotionData } from '@obeta/data/lib/hooks/usePromotionData'
import { usePromotionItemsFromActiveCart } from './hooks/usePromotionItemsFromActiveCart'
import { CustomEditor, EPlugins } from '../reactPageEditor/CustomEditor'
import { calculateProgress } from '@obeta/utils/lib/promotion'

export interface IPromotionHeaderProps {
  setData: (data) => void
  promotionId: string
}

export const PromotionHeader: React.FC<IPromotionHeaderProps> = (props) => {
  const { setData, promotionId } = props

  const promotionData = useInitPromotionData(promotionId)
  const { content, criteriaType, setProgress } = promotionData
  const promotionItems = usePromotionItemsFromActiveCart()
  const promotionProgress = calculateProgress(promotionItems, criteriaType)

  useEffect(() => {
    setProgress(promotionProgress)
  }, [promotionProgress, setProgress])

  useEffect(() => {
    setData(promotionData)
    //just do a string name comparison instead of deepeqal object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, promotionData.name])

  if (!content) {
    return null
  }

  return (
    <PromotionDataContext.Provider value={promotionData}>
      <CustomEditor
        value={content}
        pluginsList={[
          EPlugins.Image,
          EPlugins.ProgressBar,
          EPlugins.PromotionLayout,
          EPlugins.RichText,
        ]}
      />
    </PromotionDataContext.Provider>
  )
}
