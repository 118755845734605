import React, { useEffect, useState } from 'react'
import styles from './ShoppingCartList.module.scss'
import { AppBar, Button, Grid, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  ShoppingCardSkeleton,
  ShoppingCartItemV2,
} from '@obeta/components/lib/shoppingcart/ShoppingCartItemV2'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { ShoppingCartEmptyBox } from '@obeta/components/lib/shoppingcart/ShoppingCartEmptyBox'
import { useEntityMetaData } from '@obeta/data/lib/hooks/useEntityMetaData'
import { interFrameCommunication } from '@obeta/utils/lib/interFrameCommunication'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { useDispatch } from 'react-redux'
import {
  emptyShoppingCartGraphQL,
  resetShoppingCartGraphQL,
  CartsActionTypes,
} from '@obeta/data/lib/actions/cart-actions'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useSelectShoppingCart } from '@obeta/data/lib/hooks/useSelectShoppingCart'

export const ShoppingCartListPage: React.FC = () => {
  useAuthenticatedRoute()
  const { t } = useTranslation()
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false)
  const [showEmptyCartConfirm, setShowEmptyCartConfirm] = useState<boolean>(false)
  const history = useHistory()
  const title = t('SHOPPING_CART.SHOPPING_CARTS')
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const [cartToEmpty, setCartToEmpty] = useState<ShoppingCartV2 | null>(null)
  const loading = useEntityMetaData('cartsv2').isFetching
  const showPrices = false // disabled due to https://coditorei.atlassian.net/browse/COD-12030
  const dispatch = useDispatch()
  const waitForEmptyShoppingCartAction = useActionNotification(
    CartsActionTypes.EmptyShoppingCartGraphQLResult
  )
  const onSetActiveCart = useSelectShoppingCart()

  useEffect(() => {
    setIsInitialLoading(loading && !carts.length)
  }, [carts, loading])

  // Set height for iFrame during loading animation
  interFrameCommunication.setIFrameHeight()

  const isLoadedAllCartsEmpty =
    !loading && carts.length && carts.every((cart) => !cart.items.length)

  const emptyAndResetShoppingCart = () => {
    if (cartToEmpty) {
      dispatch(emptyShoppingCartGraphQL(cartToEmpty))
      waitForEmptyShoppingCartAction(() => {
        dispatch(resetShoppingCartGraphQL(cartToEmpty))
      })
    }
  }

  const onEmptyCartClick = (cart: ShoppingCartV2) => {
    setCartToEmpty(cart)
    setShowEmptyCartConfirm(true)
  }

  const handleConfirmEmptyCart = () => {
    emptyAndResetShoppingCart()
    setShowEmptyCartConfirm(false)
  }

  const handleCancelEmptyCart = () => {
    setShowEmptyCartConfirm(false)
  }

  const handleClick = (cartId: string) => {
    onSetActiveCart(cartId)
    history.push(`/shopping-cart-details/${cartId}`)
  }

  const getPageContent = () => {
    if (isInitialLoading) {
      return (
        <>
          <Grid item className={styles.appBarWrapper}>
            <AppBar
              color={'transparent'}
              elevation={0}
              className={styles.header}
              position={'static'}
            >
              <Toolbar variant={'dense'} className={styles.toolbar}>
                <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
                  {title}
                </Typography>
                {!isUiTarget('app') && (
                  <Button
                    variant={'tertiary'}
                    size={'small'}
                    className={styles.administration}
                    href={ShopRoutes.Projects}
                    target="_parent"
                  >
                    {t('SHOPPING_CART.NAVIGATION.PROJECT_MANAGEMENT')}
                  </Button>
                )}
              </Toolbar>
            </AppBar>
          </Grid>
          <Grid item container spacing={2}>
            {new Array(5).fill(null).map(() => {
              return (
                <Grid item xs={12} sm={6} lg={4}>
                  <ShoppingCardSkeleton showPrices={showPrices} />
                </Grid>
              )
            })}
          </Grid>
        </>
      )
    }

    if (!loading && isLoadedAllCartsEmpty) {
      return (
        <Grid item className={styles.shoppingCartEmptyBoxWrapper}>
          <ShoppingCartEmptyBox />
        </Grid>
      )
    }

    return (
      <>
        <Grid item>
          <AppBar color={'transparent'} elevation={0} className={styles.header} position={'static'}>
            <Toolbar variant={'dense'} className={styles.toolbar}>
              <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
                {title}
              </Typography>
              {!isUiTarget('app') && (
                <TertiaryButton onClick={() => history.push(ShopRoutes.Projects)}>
                  {t('SHOPPING_CART.NAVIGATION.PROJECT_MANAGEMENT')}
                </TertiaryButton>
              )}
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid
          item
          container
          rowSpacing={{ xs: 1, sm: 1.5, lg: 2 }}
          columnSpacing={{ sm: 1, lg: 2 }}
        >
          {carts
            ?.sort((a, b) => a.name.localeCompare(b.name, 'de'))
            .map((cart) => {
              if (cart.items.length > 0) {
                return (
                  <Grid key={cart.id} item xs={12} sm={6} lg={4}>
                    <ShoppingCartItemV2
                      cart={cart}
                      onCartSelected={(value) => handleClick(value as string)}
                      showPrices={showPrices}
                      onEmptyCartClick={onEmptyCartClick}
                    />
                  </Grid>
                )
              }
              return null
            })}
        </Grid>
        <Confirm
          heading={t('SHOPPING_CART.EMPTY_CART')}
          body={t('SHOPPING_CART.EMPTY_CART_CONFIRM')}
          handleConfirm={handleConfirmEmptyCart}
          handleCancel={handleCancelEmptyCart}
          openConfirmDialog={showEmptyCartConfirm}
          changesInCart={false}
        />
      </>
    )
  }

  return (
    <div className={styles.shoppingCartList}>
      <Grid container direction={'column'}>
        {getPageContent()}
      </Grid>
    </div>
  )
}
