import React, { memo } from 'react'
import { Box, Typography, Modal } from '@mui/material'
import styles from './OverlayThumbDownFeedback.module.scss'
import { useTranslation } from 'react-i18next'
import { TertiaryButton } from '../custom-button/CustomButton'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'

interface Props {
  showOverlayThankYouThumbDown: boolean
  setShowOverlayThankYouThumbDown: (status: boolean) => void
}

export const OverlayThankYouThumbDown: React.FC<Props> = memo((props) => {
  const { showOverlayThankYouThumbDown, setShowOverlayThankYouThumbDown } = props
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  const { shopSupportPhoneNumber } = useCompanyContactDetailsContext()

  const handleClose = () => {
    setShowOverlayThankYouThumbDown(false)
  }

  return (
    <Modal
      open={showOverlayThankYouThumbDown}
      onClose={handleClose}
      aria-labelledby="thankyou-thumbdown-title"
      aria-describedby="thankyou-thumbdown-description"
    >
      <div className={styles.wrapperContainer}>
        <Box className={clsx(styles.headerBox, styles.marBottomOneRem)}>
          <Typography variant={'headline4Bold'} color={'text.primary'}>
            {t('SEARCH.FEEDBACK.THANK_YOU_VERY_MUCH_FOR_YOUR_FEEDBACK')}
          </Typography>
          <TertiaryButton
            leftIcon={<CloseIcon />}
            onClick={handleClose}
            size={desktop ? 'small' : 'large'}
          />
        </Box>
        <Box className={styles.headerBox}>
          <Typography variant={'bodyBold'} color={'text.primary'}>
            {t('SEARCH.FEEDBACK.WE_ARE_HERE_FOR_ANY_FURTHER_INQUIRIES')}
          </Typography>
          <Typography variant={'bodyBold'} color={'text.primary'}>
            <span className={styles.obetaRed}>T&nbsp;</span>
            {shopSupportPhoneNumber}
          </Typography>
        </Box>
      </div>
    </Modal>
  )
})
