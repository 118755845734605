import React from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { ArticleSearchArea } from '@obeta/models/lib/models/Search'
import { RadioItem } from '../checbox-radio-item/CheckboxRadioItem'
import styles from './ScannerList.module.scss'
import { TemplateConfigItemWithInfo } from './templatesConfig'
import { TEMPLATE_VARIANTS } from '@obeta/models/lib/models/PrintService/PrintInput'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

const DEFAULT_BLOCK_WIDTH = 160
const DEFAULT_BLOCK_HEIGHT = 200

type ScannerListTemplateProps = {
  template: TemplateConfigItemWithInfo
  isActive: boolean
  updateActiveTemplate: (templateType: TEMPLATE_VARIANTS) => void
  templateOffset: number
  showInfoWarning?: boolean
}

export const ScannerListTemplate = ({
  template,
  isActive = false,
  updateActiveTemplate,
  templateOffset,
}: ScannerListTemplateProps) => {
  const { columns, rows, info, outlineType, startOffsetFromTop, templateType } = template

  const { desktop } = useBreakpoints()

  const blockWidth = DEFAULT_BLOCK_WIDTH
  const blockHeight = DEFAULT_BLOCK_HEIGHT

  const renderCells = () => {
    const cellStyle = {
      width: `${blockWidth / columns}px`,
      height: `${blockHeight / rows}px`,
    }

    const checkEngagedCell = (rowI, cellI) => {
      const currentCellFromTop = cellPerRow * rowI + cellI
      const currentCellFromBottom = (rows - rowI - 1) * cellPerRow + (cellPerRow - cellI - 1)

      const currentCell = startOffsetFromTop ? currentCellFromTop : currentCellFromBottom

      return currentCell <= templateOffset - 1
    }

    const cellPerRow = columns

    return Array.from({ length: rows }, (_, rowI) => {
      return (
        <div className={styles.row} key={rowI}>
          {Array.from({ length: cellPerRow }, (_, cellI) => {
            const firstLeftCell = cellI === 0
            const lastBottomCell = rowI === rows - 1

            return (
              <div
                key={cellI + rowI}
                className={clsx(
                  styles.cell,
                  outlineType === 'dashed' ? styles.cellDashed : styles.cellSolid,
                  {
                    [styles.engagedCell]: checkEngagedCell(rowI, cellI),
                    [styles.firstLeftCell]: firstLeftCell,
                    [styles.lastBottomCell]: lastBottomCell,
                  }
                )}
                style={cellStyle}
              ></div>
            )
          })}
        </div>
      )
    })
  }

  return (
    <div className={styles.templateBlock} onClick={() => updateActiveTemplate(templateType)}>
      {!desktop && (
        <RadioItem
          key={ArticleSearchArea.ALL}
          id="scannerlistTemplateId"
          value={ArticleSearchArea.ALL}
          checked={isActive}
        />
      )}
      <div className={styles.templateInfoSection}>
        {desktop && (
          <div className={styles.infoRadioWithTitle}>
            <RadioItem
              key={ArticleSearchArea.ALL}
              id="scannerlistTemplateId"
              value={ArticleSearchArea.ALL}
              checked={isActive}
            />
            <Typography variant="bodyBold">{info.title}</Typography>
          </div>
        )}
        {!desktop && (
          <Typography variant="bodyBold" marginBottom=".75rem" marginTop=".375rem">
            {info.title}
          </Typography>
        )}
        <Typography variant="smallText" marginBottom=".25rem">
          {info.info_cellSize}
        </Typography>
        <Typography variant="smallText" marginBottom=".25rem">
          {info.info_cellCount}
        </Typography>
        {renderCells()}
      </div>
    </div>
  )
}
