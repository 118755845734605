import clsx from 'clsx'
import { useEffect, useReducer, useRef, useState } from 'react'
import { SelectActions, ThumbSize } from './types'
import { ReactComponent as ZoomInIcon } from '@obeta/assets/icon/zoom_in.svg'
import { Box, IconButton } from '@mui/material'
import { Thumbs } from './Thumbs'
import { ImageGalleryModal } from './ImageGalleryModal'
import { Image } from './Image'
import styles from './ImageGallery.module.scss'
import { ContentHold } from './ContentHold'
import { NavigateButton } from '../navigate-button/NavigateButton'
import useResizeObserver from '@react-hook/resize-observer'
import type { ContentItem } from '@obeta/data/lib/hooks/useArticleGalleryContent'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface IClasses {
  selectedImage?: string
  selectedImageWrap?: string
  aspectRatioWrap?: string
}

interface IImageGalleryProps {
  items: ContentItem[]
  leftAdornment?: React.ReactChild
  modalThumbsRightAdornment?: React.ReactChild
  classes?: IClasses
  className?: string
  noRightOverFlowClass?: string
}

interface SelectState {
  currentIndex: number
  itemsLength: number
}

export const selectReducer = (state: SelectState, action: SelectActions): SelectState => {
  switch (action.type) {
    case 'next': {
      let nextIndex = state.currentIndex + 1
      if (nextIndex >= state.itemsLength) {
        nextIndex = 0
      }

      return { ...state, currentIndex: nextIndex }
    }

    case 'prev': {
      let nextIndex = state.currentIndex - 1
      if (nextIndex < 0) {
        nextIndex = state.itemsLength - 1
      }

      return { ...state, currentIndex: nextIndex }
    }

    case 'set': {
      return { ...state, currentIndex: action.payload.nextIndex }
    }

    case 'updateLength': {
      /**
       * TODO: in case new length is shorter than prev one update currentIndex
       */

      return { ...state, itemsLength: action.payload.newLength }
    }
  }
}

/**
 * About zoomIn implementation:
 * My first intention was to use react-quick-pinch-zoom lib, but it has issues
 * in firefox: lib aligns your image in firefox. transform is updated everytime
 * you image changes. Sometimes this leads to "jump" bug. This looks ugly.
 * I replaced "react-quick-pinch-zoom" with "react-prismazoom"
 *
 *
 * @param props
 * @returns
 */
export const ImageGallery: React.FC<IImageGalleryProps> = (props) => {
  const {
    items: contentItems,
    leftAdornment,
    modalThumbsRightAdornment,
    classes,
    className,
  } = props

  const [selectedState, dispatchSelectAction] = useReducer<typeof selectReducer>(selectReducer, {
    currentIndex: 0,
    itemsLength: contentItems.length,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const { mobile, tablet } = useBreakpoints()
  useEffect(() => {
    dispatchSelectAction({
      type: 'updateLength',
      payload: {
        newLength: contentItems.length,
      },
    })
  }, [contentItems.length])

  const imageWrapRef = useRef<HTMLDivElement>(null)
  const [selectedImageHeight, setSelectedImageHeight] = useState(0)

  useResizeObserver(imageWrapRef, (entry) => {
    const wrap = entry.target

    const height = parseFloat(getComputedStyle(wrap).paddingTop)
    setSelectedImageHeight(height)
  })

  const selectedContentData = contentItems[selectedState.currentIndex]

  const thumbsPlacement = tablet ? 'right' : 'bottom'
  const placementBottom = thumbsPlacement === 'bottom'
  const placementRight = thumbsPlacement === 'right'

  let url = ''
  if (selectedContentData) {
    url =
      selectedContentData.contentType === 'video'
        ? selectedContentData.previewUrl
        : selectedContentData.url
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const displayThumbNavigation = placementBottom && !mobile && contentItems.length > 1

  let thumbSize: ThumbSize = 64
  if (tablet) thumbSize = 135
  if (mobile) thumbSize = 80

  const innerImageWrap = (
    <div className={clsx(styles.selectedImageWrap, classes?.selectedImageWrap)}>
      <div ref={imageWrapRef} className={clsx(styles.aspectRatioWrap, classes?.aspectRatioWrap)}>
        {leftAdornment}
        <ContentHold className={clsx(styles.contentHold, classes?.selectedImage)}>
          <Image
            className={styles.image}
            onClick={openModal}
            src={url}
            alt={selectedContentData?.description}
            width={selectedContentData?.articleImgProxyData?.width}
          />
        </ContentHold>
        <IconButton onClick={openModal} className={styles.zoomInButton}>
          <ZoomInIcon />
        </IconButton>
      </div>
    </div>
  )

  return (
    <>
      <ImageGalleryModal
        marginThreshold={'xs'}
        withNavigateButtons={contentItems.length > 1}
        withDivider={!tablet}
        isFullScreen={mobile}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        items={contentItems}
        selectedIdx={selectedState.currentIndex}
        dispatchSelectAction={dispatchSelectAction}
        leftAdornment={tablet ? leftAdornment : undefined}
        rightThumbsAdornment={!tablet ? modalThumbsRightAdornment : undefined}
      />
      <div
        className={clsx(
          styles.gallery,
          {
            [styles.galleryPlacementBottom]: placementBottom,
            [styles.galleryPlacementRight]: placementRight,
            [styles.mobile]: mobile,
            [styles.tablet]: tablet,
          },
          className
        )}
      >
        {props.noRightOverFlowClass ? (
          <Box className={props.noRightOverFlowClass}>{innerImageWrap}</Box>
        ) : (
          innerImageWrap
        )}

        <div className={clsx(styles.thumbsWrapper, { [styles.withNav]: displayThumbNavigation })}>
          {displayThumbNavigation && (
            <NavigateButton
              margin={false}
              onClick={() => {
                dispatchSelectAction({ type: 'prev' })
              }}
              direction="left"
            />
          )}
          {/** why style can be undefined?
           * to make sure that thumbnails are not collapsed in case selected image didn't load */}
          <Box flex={1} height={'100%'} overflow={'hidden'} pb={mobile ? undefined : '.5rem'}>
            <Thumbs
              size={thumbSize}
              direction={tablet ? 'vertical' : 'horizontal'}
              selectedIdx={selectedState.currentIndex}
              style={selectedImageHeight ? { maxHeight: `${selectedImageHeight}px` } : undefined}
              thumbsPlacement={thumbsPlacement}
              thumbs={contentItems}
              onThumbClicked={(_, __, idx) => {
                dispatchSelectAction({
                  type: 'set',
                  payload: {
                    nextIndex: idx,
                  },
                })
              }}
            />
          </Box>
          {displayThumbNavigation && (
            <NavigateButton
              margin={false}
              onClick={() => {
                dispatchSelectAction({ type: 'next' })
              }}
              direction={'right'}
            />
          )}
        </div>
      </div>
    </>
  )
}
