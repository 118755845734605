import { OfferItemForDetailsPage } from '@obeta/models/lib/schema-models/offer-details'

/**
 * Checks if an offer item already has its amount changed. Returns the value for correct display in makeOfferItem.
 * @param offerItem Offer item
 * @param modifiedOfferItemAmounts Modified amount offer items
 * @returns Modified amount
 */
export const getModifiedOfferItemAmount = (
  offerItem: OfferItemForDetailsPage,
  modifiedOfferItemAmounts: { offerItemPosition: number; amount: number }[]
): number | undefined => {
  const offerItemMatch = modifiedOfferItemAmounts.find(
    (modifiedOfferItemAmount) =>
      modifiedOfferItemAmount.offerItemPosition === offerItem.offerItemPosition
  )
  if (offerItemMatch && offerItemMatch.amount !== offerItem.amount) {
    return offerItemMatch.amount
  }
  return undefined
}
