import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as StrikeThroughCartIcon } from 'assets/icon/designsystem/remove_shopping_cart.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'

interface Props {
  className?: string
  onClick: () => void
  variant?: 'secondary' | 'tertiary'
  amountOfProducts?: number
  disabled?: boolean
}

export const IdsTransferActionButton: FC<Props> = ({
  className,
  onClick,
  amountOfProducts,
  disabled,
  variant = 'tertiary',
}) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const theme = useTheme()

  const label =
    amountOfProducts !== undefined
      ? t('IDS.TRANSFER_BACK_WITH_AMOUNT', { amount: amountOfProducts })
      : t('IDS.TRANSFER_BACK')
  return (
    <div className={className}>
      {variant === 'tertiary' ? (
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={StrikeThroughCartIcon} />}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </TertiaryButton>
      ) : (
        <SecondaryButton
          fullWidth
          type="submit"
          leftIcon={
            <SvgIcon htmlColor={theme.palette.white.main} component={StrikeThroughCartIcon} />
          }
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </SecondaryButton>
      )}
    </div>
  )
}
