import { getURLSearchParamsByLocationSearch } from './getURLSearchParamsByLocationSearch'

/**
 * Remove specific key from history location search.
 * E.g.: ID params in detail pages
 * @param key key
 * @param search History location search
 */
export const removeKeyFromHistoryLocationSearch = (
  key: string,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  search: string
): string => {
  const urlSearchParams: { [key: string]: any } = getURLSearchParamsByLocationSearch(search)

  // Filter out specific key
  const filteredQueryParams = Object.fromEntries(
    Object.entries(urlSearchParams).filter(([k]) => {
      return k !== key
    })
  )

  return new URLSearchParams(
    filteredQueryParams as
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams
      | undefined
  ).toString()
}
