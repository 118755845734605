import { useFiltersStateContext } from '../hooks/useFiltersReducer'
import { AttributesCards } from './AttributesCards'
import { ClassesCards } from './ClassesCards'

export const EtimCards: React.FC = () => {
  const { filtersState, dispatchFiltersAction } = useFiltersStateContext()

  let view: JSX.Element | null = null
  if (filtersState.searchParams.selectedEtim) {
    view = (
      <AttributesCards
        dispatchEtimAction={dispatchFiltersAction}
        selectedEtimClass={filtersState.searchParams.selectedEtim}
        selectedEtimValues={filtersState.selectedEtimValueByFeatureName}
        activeEtimFeatureName={filtersState.activeEtimFeatureName}
      />
    )
  } else {
    view = <ClassesCards dispatchEtimAction={dispatchFiltersAction} />
  }

  return view
}
