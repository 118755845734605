import { PopoverOrigin } from '@mui/material'
import {
  SendCartResultAction,
  CartsActionTypes,
  SendCartAction,
} from '@obeta/data/lib/actions/cart-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import React, { useEffect, useState } from 'react'
import { Alert } from '@obeta/components/lib/alert/Alert'
import { useTranslation } from 'react-i18next'
import { IPopoverProps, Popover } from '../popover/Popover'
import { LoadingIndicator } from '@obeta/components/lib/alert/components/loading-indicator/LoadingIndicator'

type ControlerProps = Pick<IPopoverProps, 'anchorEl'>

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const CartSubmittedPopover: React.FC<ControlerProps> = React.memo(
  function NotificationsController(props) {
    const { anchorEl } = props

    const [submitttedCarts, setSubmittedCarts] = useState<number>(0)
    const { t } = useTranslation()
    const waitForSubmitStart = useActionNotification(CartsActionTypes.SendCart)
    const waitForSubmitted = useActionNotification(CartsActionTypes.SendCartResult)

    useEffect(() => {
      let unmounted = false

      // we pass -1 as second argument to waitForSubmitStart to listen to all
      const unsubscribe = waitForSubmitStart((action: SendCartAction) => {
        if (unmounted) {
          return
        }

        setSubmittedCarts((oldCount) => Math.max(0, oldCount + 1))

        waitForSubmitted((action: SendCartResultAction) => {
          if (unmounted) {
            return
          }
          setSubmittedCarts((oldCount) => Math.max(0, oldCount - 1))
        })
      }, -1)

      return () => {
        unmounted = true
        unsubscribe && unsubscribe()
      }
    }, [waitForSubmitStart, waitForSubmitted])

    return (
      <Popover
        anchorEl={anchorEl}
        open={submitttedCarts > 0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Alert
          type="info"
          message={t('ALERTS.CART_IS_PROCESSED') as string}
          endAdornment={<LoadingIndicator />}
        ></Alert>
      </Popover>
    )
  }
)
