// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginscreen__content{padding:0}.loginscreen__content .guestmode__content{padding:1.5rem;background-color:#fff;border-radius:1px}.loginscreen__content .guestmode__content div{font-weight:500}.loginscreen__content .guestmode__content p{color:var(--descriptive-text-color)}.loginscreen__content .guestmode__content p span{color:var(--color-primary);text-decoration:underline;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/common/components/please-login-screen/PleaseLoginScreen.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,0CACE,cAAA,CACA,qBAAA,CACA,iBAAA,CAEA,8CACE,eAAA,CAGF,4CACE,mCAAA,CAEA,iDACE,0BAAA,CACA,yBAAA,CACA,cAAA","sourcesContent":["@import 'libs/assets/theme/mixins.scss';\n\n.loginscreen__content {\n  padding: 0;\n  .guestmode__content {\n    padding: 1.5rem;\n    background-color: #fff;\n    border-radius: 1px;\n\n    div {\n      font-weight: 500;\n    }\n\n    p {\n      color: var(--descriptive-text-color);\n\n      span {\n        color: var(--color-primary);\n        text-decoration: underline;\n        cursor: pointer;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#e2061b",
	"primaryShade": "rgb(203.4,5.4,24.3)",
	"secondaryColor": "#3f3f3e",
	"secondaryShade": "rgb(56.7,56.7,55.8)",
	"secondaryTint": "rgb(82.404,82.404,81.096)",
	"tertiaryColor": "#fafafa",
	"tertiaryShade": "#e1e1e1",
	"tertiaryTint": "rgb(250.5,250.5,250.5)",
	"backdropHeightRem": "4.7rem",
	"backdropArticlesHeightRem": "6.5rem",
	"red100pct": "#e2061b",
	"red89pct": "#e7362b",
	"red55pct": "#f28e73",
	"red34pct": "#f8bca8",
	"orange": "#f18805",
	"orange89pct": "#f39520",
	"orange55pct": "#f7be76",
	"orange34pct": "#fad6aa",
	"darkGray": "#3f3f3e",
	"darkGray55K": "#929292",
	"darkGray34K": "#bebebe",
	"darkGray21K": "#d8d8d8",
	"lightGray": "#e7e7e7",
	"lightGray8K": "#f0f0f0",
	"lightGray5K": "#f6f6f6",
	"lightGray3K": "#fafafa",
	"green": "#27ae60",
	"black": "#000",
	"black10pct": "rgba(0,0,0,.1019607843)",
	"black15pct": "rgba(0,0,0,.1490196078)",
	"white": "#fff",
	"white50pct": "hsla(0,0%,100%,.5019607843)",
	"modalMarginThreshold": "2.5rem",
	"screenXsMin": "0",
	"screenSmMin": "768px",
	"screenMdMin": "1024px",
	"screenLgMin": "1174px",
	"slideTransitionTimeout": "300",
	"mobileHeaderHeight": "7.25rem",
	"shopMaxWidth": "78.125rem",
	"productStickyBoxHeight": "4.5rem",
	"productStickyBoxHeightMobile": "4.25rem",
	"desktopEtimItemHeight": "2.75rem",
	"listsMaxSize": "5",
	"listsGap": ".5rem"
};
export default ___CSS_LOADER_EXPORT___;
