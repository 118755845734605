import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'
import BarcodeLib from 'react-barcode'
import { TFunction } from 'i18next'
import { ReactComponent as LogoIcon } from 'assets/img/OnBoarding/obeta_logo.svg'
import { ReactComponent as PlusIcon } from 'assets/icon/designsystem/add.svg'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { useTranslation } from 'react-i18next'
import { useBarcodeScanner } from '@obeta/data/lib/hooks/useBarcodeScanner'
import { useActionNotification, useRemToPx } from '@obeta/data/lib/hooks'
import { UserContext } from '@obeta/data/lib/hooks/useUserData'
import { CustomerActionTypes } from '@obeta/data/lib/actions'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { Nullable, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import styles from './InfoSection.module.scss'

const ERROR_MESSAGE_TRANSLATIONS = {
  'Card does not exist for customer': 'CUSTOMER_PROFILE.CUSTOMER_CARD_EXPIRED_MESSAGE',
  'Card is disabled for reason': 'CUSTOMER_PROFILE.CUSTOMER_CARD_BLOCKED_MESSAGE',
  'Card is in use': 'CUSTOMER_PROFILE.CUSTOMER_CARD_ALREADY_TAKEN_MESSAGE',
}

function getTranslateErrorMessage(errorMessage: string, t: TFunction, defaultMessage: string) {
  const translationKey = Object.keys(ERROR_MESSAGE_TRANSLATIONS).find((key) =>
    errorMessage.startsWith(key)
  )
  return translationKey ? t(ERROR_MESSAGE_TRANSLATIONS[translationKey]) : defaultMessage
}

export const getCustomerCardId = (activeUser?: Nullable<UserV2>) => {
  return activeUser?.customerCard?.barcode && activeUser?.customerCard?.barcode !== '0'
    ? activeUser?.customerCard?.barcode
    : ''
}

export const InfoSection = () => {
  const { t } = useTranslation()
  const { startTransferCardScan } = useBarcodeScanner()
  const [cardUploadError, setCardUploadError] = useState<string | null>(null)
  const { user: activeUser } = useContext(UserContext)
  const { user: userV2 } = useUserDataV2()

  const customerCardActivatedId = getCustomerCardId(userV2)

  const waitForTransferResult = useActionNotification(CustomerActionTypes.UpdateCardResult)

  const barcodeHeight = useRemToPx(2)

  const handleTransferResult = (res) => {
    const error = res.error
    if (error) {
      const defaultMsg = t('CUSTOMER_PROFILE.CUSTOMER_CARD_UPLOAD_FAILED')
      const errorMsg = getTranslateErrorMessage(error.message, t, defaultMsg)
      setCardUploadError(errorMsg)
    }
  }

  return (
    <div className={styles.infoSection}>
      <LogoIcon className={styles.obetaIcon} />
      <div className={styles.textWrapper}>
        <Typography variant="headline4Bold" sx={{ marginBottom: '0.375rem' }}>
          {activeUser?.general?.name1 || '-'}
        </Typography>
        <Typography variant="body">{activeUser?.general?.name2 || '-'}</Typography>
        <Typography variant="body">
          {t('CUSTOMER_PROFILE.CARD_NUMBER', { cardNumber: activeUser?.general?.id || '-' })}
        </Typography>
      </div>
      {customerCardActivatedId ? (
        <BarcodeLib
          background={'transparent'}
          displayValue={false}
          renderer="svg"
          height={barcodeHeight}
          lineColor="#000"
          margin={0}
          width={2}
          value={customerCardActivatedId}
        />
      ) : (
        <div className={styles.addCardButtonWrapper}>
          <div className={styles.buttonWrapper}>
            <DarkButton
              onClick={async () => {
                await startTransferCardScan()
                waitForTransferResult(handleTransferResult)
              }}
              leftIcon={<PlusIcon />}
            >
              {t('CUSTOMER_PROFILE.ADD_CARD')}
            </DarkButton>
          </div>
          {cardUploadError && (
            <div className={styles.cardUploadError}>
              <WarningIcon className={styles.icon} />
              <Typography variant="smallText">{cardUploadError}</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
