import React from 'react'
import clsx from 'clsx'
import { InfoSection, LogoSection } from './components'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import styles from './CustomerCard.module.scss'

const isIos = isPlatform('ios')

export const CustomerCard = () => {
  return (
    <div
      className={clsx(styles.root, {
        [styles.isIos]: isIos,
      })}
    >
      <div className={styles.content}>
        <InfoSection />
        <LogoSection />
      </div>
    </div>
  )
}
