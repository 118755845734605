import React from 'react'
import { Size } from '../Size'
import { ReactComponent as ArrowDownIcon } from '@obeta/assets/icon/arrow_down1.svg'
import styles from './DesktopEtim.module.scss'
import { Box, Stack, Typography } from '@mui/material'

interface IDesktopEtimProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  size?: number
  name: React.ReactChild
  subLine?: React.ReactChild
  withArrowIcon: boolean
  typographyClassname?: string
}

export const DesktopEtim: React.FC<IDesktopEtimProps> = (props) => {
  const { size, name, subLine, withArrowIcon, onClick } = props

  const countEl =
    size !== undefined ? (
      <>
        &nbsp;
        <Size typographyVariant="smallText" size={size} />
      </>
    ) : null

  //default just name with count
  let content = (
    <Box height={'100%'} overflow="hidden">
      <Typography flex={1} component={'div'} variant="smallTextBold" className={styles.textEtim}>
        {name}
        {countEl}
      </Typography>
    </Box>
  )

  if (subLine) {
    content = (
      <Stack direction={'column'}>
        <Typography
          flex={1}
          variant="smallTextBold"
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          whiteSpace={'nowrap'}
        >
          {name}
        </Typography>
        <Stack direction="row">
          <Typography
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            component={'div'}
            variant="smallTextBold"
          >
            {subLine}
          </Typography>
          {countEl}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'stretch'}
      direction="row"
      className={styles.etim}
      onClick={onClick}
    >
      <Box flex={1} overflow={'hidden'} alignSelf={'flex-start'}>
        {content}
      </Box>
      {withArrowIcon && (
        <Box>
          <ArrowDownIcon className={styles.icon} />
        </Box>
      )}
    </Stack>
  )
}
