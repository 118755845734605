import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { Typography } from '@mui/material'
import './OnboardingSlider.scss'

export const OnboardingSlider = ({ slides }) => {
  return (
    <Swiper className="onboarding-swiper" pagination={{ clickable: true }} modules={[Pagination]}>
      {slides.map((sl, idx) => (
        <SwiperSlide key={idx}>
          <div className={`introslide introslide--${idx}`}>
            <div className="content">
              <div className="imagewrap">
                <div className="image" style={{ backgroundImage: `url(${sl.backgroundImage})` }} />
              </div>
              <div className="explanation">
                <Typography variant="headline3Bold">{sl.title}</Typography>
                <Typography variant="subTitle">{sl.text}</Typography>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
