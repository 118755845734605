import React, { FC, useEffect, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  CellMeasurerCache,
  List,
  CellMeasurer,
  WindowScroller,
  InfiniteLoader,
  Index,
} from 'react-virtualized'
import clsx from 'clsx'
import { NewsCard } from '@obeta/components/lib/newscard/NewsCard'
import { ListRowProps } from 'react-virtualized/dist/es/List'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { formatDateAsMonthNameAndYear } from '../../../startPage/NewsCardSection'
import { useNewsProvider } from '../../hooks/useNewsProvider'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import { useTranslation } from 'react-i18next'
import { NewsCategory } from '../ControlSection/ControlSection'
import styles from './ContentNews.module.scss'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

const DISTANCE_BETWEEN_ROWS_DESKTOP = 32
const DISTANCE_BETWEEN_ROWS_TABLET = 16

const getElementsPerRow = (desktop: boolean, mobile: boolean) => {
  if (desktop) return 3
  if (mobile) return 1
  return 2
}

const HEADER_HEIGHT = 172

export const NewsContent: FC = () => {
  const { news, pagination, total, updatePagination, isLoaded, filters } = useNewsProvider()
  const isNativeUITarget = isUiTarget('app')
  const { t } = useTranslation()
  const [cache] = useState<CellMeasurerCache>(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 350,
      minHeight: 250,
    })
  )

  const { desktop, mobile } = useBreakpoints()

  const elementsPerRow = getElementsPerRow(desktop, mobile)

  const isRowLoaded = ({ index }: Index) => index * elementsPerRow < news.length

  useEffect(() => {
    cache.clearAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  const loadMoreItems = async () => {
    news.length <= total && updatePagination()
  }

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const newsPerRow = news.slice(index * elementsPerRow, index * elementsPerRow + elementsPerRow)

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {() => (
          <div
            className={clsx(styles.newsCell, {
              [styles.oneColumnGrid]: elementsPerRow === 1,
              [styles.twoColumnGrid]: elementsPerRow === 2,
              [styles.threeColumnGrid]: elementsPerRow === 3,
            })}
            key={index}
            style={{
              ...style,
              height:
                // TODO: Investigate why we need manually set height for web(nextjs) app ( 2 places )
                typeof style.height === 'number' && !isNativeUITarget
                  ? style.height -
                    (desktop ? DISTANCE_BETWEEN_ROWS_DESKTOP : DISTANCE_BETWEEN_ROWS_TABLET)
                  : style.height,
            }}
          >
            {newsPerRow.map((newsEntry, idx) => {
              if (!newsEntry) {
                return <div key={idx} className={clsx(styles.newslistitem, styles.dummy)}></div>
              }

              return (
                <NewsCard
                  {...newsEntry}
                  classes={{
                    imageWrapperClassName: styles.newsImageWrapper,
                  }}
                  startDate={formatDateAsMonthNameAndYear(newsEntry.startDate)}
                  key={newsEntry.id}
                />
              )
            })}
          </div>
        )}
      </CellMeasurer>
    )
  }

  const onResize = () => cache.clearAll()

  const searchTerm = filters.search

  return (
    <>
      {isLoaded && !news.length && (
        <NoResults
          body={searchTerm ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.BODY') : undefined}
          title={
            searchTerm
              ? t('COMMON.FILTER_TABS.NO_RESULTS_WITH_FILTERS.TITLE', {
                  searchTerm: searchTerm,
                  tabName: filters.category || NewsCategory.All,
                })
              : t('COMMON.FILTER_TABS.NO_RESULTS.TITLE')
          }
        />
      )}
      <AutoSizer disableHeight onResize={onResize}>
        {({ width }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreItems}
            rowCount={Math.ceil(Math.max(0, total) / elementsPerRow)}
            threshold={elementsPerRow === 1 ? 10 : 3}
            minimumBatchSize={pagination.limit}
          >
            {({ onRowsRendered }) => (
              <WindowScroller scrollElement={window}>
                {({ height, isScrolling, scrollTop }) => (
                  <List
                    deferredMeasurementCache={cache}
                    autoHeight
                    isScrolling={isScrolling}
                    onRowsRendered={onRowsRendered}
                    height={height}
                    overscanRowCount={2}
                    rowCount={Math.ceil(Math.max(0, total) / elementsPerRow)}
                    rowHeight={cache.rowHeight}
                    estimatedRowSize={cache.defaultHeight}
                    rowRenderer={rowRenderer}
                    width={width}
                    scrollTop={scrollTop - HEADER_HEIGHT}
                  />
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </>
  )
}
