import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import styles from './WelcomeSection.module.scss'

export const WelcomeSection = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <Typography variant="headline2Bold">{t('LOCATION_PAGE.WELCOME_TITLE')}</Typography>
      <Typography variant="body">{t('LOCATION_PAGE.WELCOME_TEXT')}</Typography>
    </div>
  )
}
