import * as mobiscroll from '@mobiscroll/react5'
import { capitalizeFirstLetter } from './capitalizeFirstLetter'
import { getLocale } from './getLocale'

export const getMobiscrollLocale = () => {
  const locale = getLocale()
  const langCode = locale.slice(0, 2)

  return mobiscroll[`locale${capitalizeFirstLetter(langCode)}`]
}
