import { FC, ReactNode } from 'react'
import { Button, ButtonProps, Typography } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './FilterButton.module.scss'

export interface FilterButtonProps extends ButtonProps {
  active?: boolean
  icon?: ReactNode
  className?: string
}

export const FilterButton: FC<FilterButtonProps> = (props) => {
  const { active, children, icon, className, ...rest } = props

  return (
    <Button
      className={clsx(className, styles.filterButton, active ? styles.active : styles.inactive)}
      disableRipple
      size="large"
      {...rest}
    >
      {icon && <div className={clsx(styles.icon, styles.iconRight)}>{icon}</div>}
      <Typography variant="bodyBold">{children}</Typography>
    </Button>
  )
}
