import { Typography } from '@mui/material'
import { ReactComponent as ChevronLeft } from '@obeta/assets/icon/chevron_left20x20.svg'
import clsx from 'clsx'
import { TypographyEllipsis } from '../typography'
import styles from './Header.module.scss'

interface IHeaderProps {
  title: React.ReactText
  onBackClicked?: () => void
  rightAdornment?: React.ReactChild | null
  typographyVariant?: React.ComponentProps<typeof Typography>['variant']
}

export const Header: React.FC<IHeaderProps> = (props) => {
  const { onBackClicked, title, rightAdornment, typographyVariant = 'bodyBold' } = props

  return (
    <div className={clsx(styles.root, { [styles.pointer]: onBackClicked })} onClick={onBackClicked}>
      {Boolean(onBackClicked) && <ChevronLeft className={styles.backIcon} />}
      <TypographyEllipsis classes={{ root: styles.title }} variant={typographyVariant} noWrap>
        {title}
      </TypographyEllipsis>
      {rightAdornment}
    </div>
  )
}
