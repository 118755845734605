import { FC, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { Clipboard } from '@capacitor/clipboard'

// Assets
import { ReactComponent as ContentCopyIcon } from 'assets/icon/designsystem/content_copy.svg'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Styles
import styles from './OfferDetailHeaderClerkDataItem.module.scss'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'

export enum OfferDetailHeaderClerkDataType {
  EMail = 'EMAIL',
  Telephone = 'TELEPHONE',
}

type Props = {
  clerkDataType: OfferDetailHeaderClerkDataType
  value: string
}

export const OfferDetailHeaderClerkDataItem: FC<Props> = (props) => {
  const { clerkDataType, value } = props
  const { t } = useTranslation()

  const { mobile } = useBreakpoints()

  let iconButton: ReactNode | undefined
  let startLetter: string | undefined
  switch (clerkDataType) {
    case OfferDetailHeaderClerkDataType.EMail:
      iconButton = (
        <ContentCopyIcon
          className={styles.clerkDataItemIcon}
          onClick={async () => {
            await Clipboard.write({ string: value })

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.Success,
              id: 'copy-property',
              options: {
                heading: value,
                body: t<string>('MAIN.WAS_COPIED'),
              },
            })
          }}
        />
      )
      startLetter = 'M'
      break
    case OfferDetailHeaderClerkDataType.Telephone:
      startLetter = 'T'
      break
    default:
      break
  }

  return (
    <div className={styles.clerkDataItem}>
      <Typography
        className={styles.clerkDataItemStartLetter}
        variant={mobile ? 'bodyBold' : 'body'}
      >
        {startLetter}
      </Typography>
      <a
        className={styles.clerkDataItemValue}
        href={`${
          clerkDataType === OfferDetailHeaderClerkDataType.EMail ? 'mailto:' : 'tel:'
        }${value}`}
      >
        <Typography>{value}</Typography>
      </a>
      {iconButton}
    </div>
  )
}
