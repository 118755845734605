import { useState } from 'react'
import clsx from 'clsx'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import { StoresMap } from '@obeta/components/lib/stores-map/StoresMap'
import { Box, Card, Stack, Typography } from '@mui/material'
import { BaseNotificationRow } from '@obeta/components/lib/notifications/BaseNotificationLayout'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import notificationStyles from '@obeta/components/lib/notifications/Notification.module.scss'
import { getCookiebotPreferencesFlag } from '@obeta/data/lib/components/operations-integration/utils/getCookiebotFlags'
import { useCookiebotPreferencesConsentHandling } from '@obeta/data/lib/components/operations-integration/useCookiebotPreferencesConsentHandling'
import styles from './StoresMapSection.module.scss'

export const StoresMapSection = () => {
  const { stores, distanceValue, chosenLocation } = useLocationPageProvider()

  const [preferencesConsent, setPreferencesConsent] = useState(
    getCookiebotPreferencesFlag() ?? false
  )

  useCookiebotPreferencesConsentHandling(() => {
    setPreferencesConsent(getCookiebotPreferencesFlag())
  })

  return (
    <div className={styles.mapWrapper}>
      {!preferencesConsent ? (
        <Card className="cookieconsent-optout-preferences" elevation={2}>
          <Stack
            className={clsx(
              notificationStyles.cardContent,
              notificationStyles.darkGray,
              notificationStyles.whiteInfoBackground,
              {
                [notificationStyles['leftborder']]: 'darkGray',
              },
              notificationStyles.borderless
            )}
            padding={'1rem'}
            gap=".5rem"
          >
            <Box className={notificationStyles.icon}>
              <InfoIcon />
            </Box>
            <Stack direction={'column'} flex={1} gap={'0.5rem'} overflow={'hidden'}>
              <BaseNotificationRow>
                <Typography variant={'bodyBold'}>Hinweis zur Standortkarte</Typography>
              </BaseNotificationRow>
              <BaseNotificationRow>
                <Typography variant={'body'}>
                  Bitte akzeptieren Sie Präferenz-Cookies, um die Karte anzeigen zu können.
                  Cookie-Einstellungen{' '}
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/* @ts-ignore*/}
                  <TertiaryButton fullWidth={false} onClick={() => window?.Cookiebot?.renew()}>
                    hier ändern.
                  </TertiaryButton>
                </Typography>
              </BaseNotificationRow>
            </Stack>
          </Stack>
        </Card>
      ) : (
        <StoresMap
          chosenLocation={chosenLocation}
          currentStores={stores}
          currentLocationOptions={{
            circleRadius: distanceValue,
          }}
        />
      )}
    </div>
  )
}
