import styles from './FadeTransition.module.scss'
import { TransitionGroup } from './TransitionGroup'

export const FadeTransition: React.FC = (props) => {
  const { children } = props
  return (
    <div className={styles.transitionGroup}>
      <TransitionGroup>{children}</TransitionGroup>
    </div>
  )
}
