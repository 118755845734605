import React, { createContext, useContext, useState } from 'react'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'
import { OrderItemForDetailsPage } from '@obeta/models/lib/schema-models/order-details'
import { OrderItemStateViewType } from '@obeta/models/lib/models/Orders'

interface OrderDetailsContextValue {
  itemState: OrderItemStateViewType
  itemStateOptions: DropdownOption[]
  orderItems: OrderItemForDetailsPage[]
  searchTerm: string
  setItemState: (itemState: OrderItemStateViewType) => void
  setItemStateOptions: (itemStateOptions: DropdownOption[]) => void
  setOrderItems: React.Dispatch<React.SetStateAction<OrderItemForDetailsPage[]>>
  setSearchTerm: (searchTerm: string) => void
}

const OrderDetailsContext = createContext<OrderDetailsContextValue>({
  itemState: 'all',
  itemStateOptions: [],
  orderItems: [],
  searchTerm: '',
  setItemState: () => {
    //
  },
  setItemStateOptions: () => {
    //
  },
  setOrderItems: () => {
    //
  },
  setSearchTerm: () => {
    //
  },
})

export const OrderDetailsContextProvider = ({ children }) => {
  const [itemState, setItemState] = useState<OrderItemStateViewType>('all')
  const [itemStateOptions, setItemStateOptions] = useState<DropdownOption[]>([])
  const [orderItems, setOrderItems] = useState<OrderItemForDetailsPage[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')

  return (
    <OrderDetailsContext.Provider
      value={{
        itemState,
        itemStateOptions,
        orderItems,
        searchTerm,
        setItemState,
        setItemStateOptions,
        setOrderItems,
        setSearchTerm,
      }}
    >
      {children}
    </OrderDetailsContext.Provider>
  )
}

export const useOrderDetailsContext = () => {
  return useContext(OrderDetailsContext)
}
