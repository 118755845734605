import { FC, useCallback } from 'react'
import {
  SingleInputDateRangeField,
  LocalizationProvider,
  DateRange,
  PickersShortcutsItem,
  pickersLayoutClasses,
  DesktopDateRangePicker,
} from '@mui/x-date-pickers-pro'
import de from 'dayjs/locale/de'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import styles from './BasicDateRangePicker.module.scss'
import dayjs, { Dayjs } from 'dayjs'
import { SvgIcon } from '@mui/material'
import { ReactComponent as CalendarIcon } from 'assets/icon/designsystem/calendar_today.svg'
import { useTranslation } from 'react-i18next'

const localeMap = {
  de: de,
}

interface Props {
  mobile: boolean
  dateFrom: string
  dateTo: string
  setDates: (dateFrom: string, dateTo: string) => void
}

const BasicDateRangePicker: FC<Props> = (props) => {
  const { dateFrom, dateTo, setDates, mobile } = props
  const { t } = useTranslation()

  /**
   * Set start and end dates on mui date range picker change.
   * @param date DateRange with Dayjs adapter
   */
  const onSetDates = useCallback((date: DateRange<Dayjs>) => {
    if (
      !date[0] ||
      !date[1] ||
      !date[0].isValid() ||
      !date[1].isValid() // Still to do: Check not only if date is valid, but also if it is an actual date, e.g. 30th Feb!
    ) {
      return
    }

    // Still to do: Check if endDate is before or equal to startDate!
    setDates(date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: t('DATE.PREVIOUS_7_DAYS'),
      getValue: () => {
        const today = dayjs()
        return [today.subtract(6, 'day'), today]
      },
    },
    {
      label: t('DATE.PREVIOUS_3_MONTHS'),
      getValue: () => {
        const today = dayjs()
        return [today.subtract(3, 'month'), today]
      },
    },
    {
      label: t('DATE.PREVIOUS_6_MONTHS'),
      getValue: () => {
        const today = dayjs()
        return [today.subtract(6, 'month'), today]
      },
    },
    {
      label: t('DATE.THIS_YEAR'),
      getValue: () => {
        const today = dayjs()
        return [today.startOf('year'), today]
      },
    },
  ]

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap.de.name}>
      <DesktopDateRangePicker
        className={mobile ? styles.layout : ''}
        calendars={1}
        slotProps={{
          layout: {
            className: styles.dateRangePicker,
            sx: {
              [`.${pickersLayoutClasses.shortcuts}`]: {
                gridColumn: 2,
                gridRow: 3,
                width: 300,
                maxWidth: 300,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              },
            },
          },
          shortcuts: {
            items: shortcutsItems,
          },
          textField: {
            InputProps: {
              endAdornment: <SvgIcon component={CalendarIcon} className={styles.calendarIcon} />,
            },
          },
        }}
        value={[dayjs(dateFrom), dayjs(dateTo)]}
        onChange={(newDate) => onSetDates(newDate)}
        slots={{ field: SingleInputDateRangeField }}
        name="allowedRange"
      />
    </LocalizationProvider>
  )
}

export default BasicDateRangePicker
