import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/de'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { ActionButton } from '@obeta/components/lib/action-button/ActionButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { RaffleCheckbox } from './RaffleCheckbox'
import styles from './RaffleNotRegistered.module.scss'

dayjs.locale('de')
dayjs.extend(customParseFormat)

interface Props {
  startDate: string
  endDate: string
  onSubmit: () => void
}

const RAFFLE_CONSENT_URL =
  'https://s3.eu-central-1.amazonaws.com/headlesscms/Datenschutzhinweise_OB_a0a3f76927.pdf?updated_at=2023-05-24T13:29:52.956Z'
const TERMS_OF_PARTICIPATION_CONSENT_URL =
  'https://s3.eu-central-1.amazonaws.com/headlesscms/Teilnahmebedingungen_f5338e69d8.pdf?updated_at=2023-05-24T13:29:42.019Z'

const toFormattedOutputDate = (dateStr: string, withYear?: boolean) => {
  const date = dayjs(dateStr, 'DD/MM/YYYY')
  if (date.isValid()) return withYear ? date.format('DD. MMMM YYYY') : date.format('DD. MMMM')
  console.error('At least one date is not configured correctly')
  return null
}

const RaffleConditionEntry: React.FC<{ text: string }> = ({ text }) => (
  <li>
    <Typography className={styles.condition}>{text}</Typography>
  </li>
)

export const RaffleNotRegistered: React.FC<Props> = ({ startDate, endDate, onSubmit }) => {
  const { t } = useTranslation()
  const [isTermsOfParticipationConsentChecked, setIsTermsOfParticipationConsentChecked] =
    useState(false)
  const { mobile } = useBreakpoints()
  const [isRaffleConsentChecked, setIsRaffleConsentChecked] = useState(false)
  const outputStartDate = toFormattedOutputDate(startDate)
  const outputEndDate = toFormattedOutputDate(endDate, true)
  const canSubmit = isTermsOfParticipationConsentChecked && isRaffleConsentChecked

  return (
    <>
      <div data-testid="raffle-outer" className={styles.topSectionBackground} />
      <Box className={styles.sectionWrapper}>
        <Stack>
          <Typography className={styles.headline}>{t('RAFFLE.NOT_REGISTERED.HEADLINE')}</Typography>
          <Typography className={styles.conditionsContainer}>
            {t('RAFFLE.NOT_REGISTERED.CONDITIONS_HEADLINE')}
          </Typography>
          <ul className={styles.bulletList}>
            {outputStartDate !== null && outputEndDate !== null && (
              <RaffleConditionEntry
                text={t('RAFFLE.NOT_REGISTERED.CONDITIONS_0', {
                  start: outputStartDate,
                  end: outputEndDate,
                })}
              />
            )}
            <RaffleConditionEntry text={t('RAFFLE.NOT_REGISTERED.CONDITIONS_1')} />
            <RaffleConditionEntry text={t('RAFFLE.NOT_REGISTERED.CONDITIONS_2')} />
          </ul>
        </Stack>
        <Box className={clsx(styles.checkboxContainer, { [styles.center]: !mobile })}>
          <RaffleCheckbox
            checked={isTermsOfParticipationConsentChecked}
            linkText={t('RAFFLE.NOT_REGISTERED.TERMS_OF_PARTICIPATION')}
            linkPath={TERMS_OF_PARTICIPATION_CONSENT_URL}
            onChange={setIsTermsOfParticipationConsentChecked}
          />
          <RaffleCheckbox
            checked={isRaffleConsentChecked}
            linkText={t('RAFFLE.NOT_REGISTERED.RAFFLE')}
            linkPath={RAFFLE_CONSENT_URL}
            onChange={setIsRaffleConsentChecked}
          />
        </Box>
        <ActionButton
          onClick={onSubmit}
          disabled={!canSubmit}
          className={styles.submitButton}
          typographyVariant="body"
          color="red100pct"
          variant="contained"
        >
          {t('RAFFLE.NOT_REGISTERED.SUBMIT')}
        </ActionButton>
      </Box>
    </>
  )
}
