export const validateFloatingNumber = (value: string, allowNegative = false) => {
  const floatedNumRegexp = allowNegative
    ? /^-?(?:\d+([.,]\d*)?|[.,]\d+)?$/
    : /^(?:\d+([.,]\d*)?|[.,]\d+)$/

  return floatedNumRegexp.test(value)
}

export const validateIntegerNumber = (value: string, allowNegative = false) => {
  const integerNumRegexp = allowNegative ? /^-?\d+$/ : /^\d+$/

  return integerNumRegexp.test(value)
}
