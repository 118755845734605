import { TopsellerTag } from '@obeta/components/lib/tag/Tag'
import { Stack, Typography } from '@mui/material'
import { Property } from '@obeta/components/lib/property/Property'
import styles from './ArticleMeta.module.scss'
import { useTranslation } from 'react-i18next'
import { IArticle } from './types'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import '@obeta/assets/theme/swiper.scss'
import { PropsWithChildren } from 'react'

// TODO: type can be improved: instead of string use keys of IArticle interface
const productProperties: Record<string, { title: string; toString: (data: unknown) => string }> = {
  dehaId: {
    title: 'ARTICLE_NUMBER',
    toString: (id: string) => id,
  },
  supplierArticleId: {
    title: 'SUPPLIER_ORDER_NUMBER',
    toString: (id: string) => id,
  },
  supplierArticleId2: {
    title: 'SUPPLIER_ARTICLE_NUMBER',
    toString: (id: string) => id,
  },
  eans: {
    title: 'EAN',
    toString: (eans: string[]) => eans[0],
  },
}

const productPropertiesKeys = Object.keys(productProperties)

interface IArticleMetaProps extends WithBreakpoints<'desktop'> {
  article: IArticle | undefined
  titleClassName?: string
}

export const Title: React.FC<PropsWithChildren<{ className?: string }>> = (props) => {
  return (
    <Typography className={props.className} variant="headline3Bold" component="h3">
      {props.children}
    </Typography>
  )
}

export const Properties: React.FC<{
  desktop: boolean
  article: IArticle | undefined
  withCopy?: boolean
  className?: string
}> = (props) => {
  const { t } = useTranslation()
  const { article, withCopy = true, className, desktop } = props
  if (desktop) {
    return (
      <Stack
        direction={'row'}
        overflow={'hidden'}
        flexWrap={'wrap'}
        columnGap={'1rem'}
        rowGap={'.5rem'}
      >
        {productPropertiesKeys.map((prop) => {
          const val = article?.[prop] && productProperties[prop].toString(article?.[prop])

          if (!val) {
            return null
          }
          return (
            <Property
              key={prop}
              withCopy={withCopy}
              name={t<string>(`ARTICLE_DETAIL.${productProperties[prop].title}`)}
            >
              {val}
            </Property>
          )
        })}
      </Stack>
    )
  }
  return (
    <Swiper
      direction="horizontal"
      slidesPerView={'auto'}
      freeMode={true}
      className={clsx(styles.properties, className)}
    >
      {productPropertiesKeys.map((prop) => {
        const val = article?.[prop] && productProperties[prop].toString(article?.[prop])

        if (!val) {
          return null
        }
        return (
          <SwiperSlide key={prop} className={styles.property}>
            <Property
              withCopy={withCopy}
              name={t<string>(`ARTICLE_DETAIL.${productProperties[prop].title}`)}
            >
              {val}
            </Property>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export const Logo: React.FC<{ article: IArticle | undefined; className?: string }> = (props) => {
  const { article, className } = props
  const img = article?.supplierImageData.large
  if (!img) {
    return null
  }

  return (
    <img
      className={clsx(styles.companyLogo, className)}
      src={article?.supplierImageData.large}
      alt="article logo"
    />
  )
}

export const ArticleMeta: React.FC<IArticleMetaProps> = (props) => {
  const { article, desktop } = props

  return (
    <div className={styles.root}>
      <div className={styles.meta}>
        {article?.isTopseller && <TopsellerTag />}
        <Title className={props.titleClassName}>{article?.title}</Title>
        <Properties desktop={desktop} article={article} />
      </div>
      <Logo article={article} />
    </div>
  )
}
