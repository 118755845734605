import React, { useEffect, useRef } from 'react'
import { LocationNewsSection } from '../LocationNewsSection/LocationNewsSection'
import { WelcomeSection } from '../WelcomeSection/WelcomeSection'
import { LocationOverlaySection } from '../LocationOverlaySection/LocationOverlaySection'
import { MapControls } from '../MapControls/MapControls'
import { StoresMapSection } from '../StoresMapSection/StoresMapSection'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import styles from '../../LocationPage.module.scss'

export const MainScreen = () => {
  const { overlayLocation } = useLocationPageProvider()
  const lastScrollPosition = useRef(0)
  const { mobile } = useBreakpoints()

  useEffect(() => {
    if (mobile) {
      if (overlayLocation) lastScrollPosition.current = window.scrollY || 0

      if (lastScrollPosition.current) {
        window.scrollTo({ top: lastScrollPosition.current })
      }
    }
  }, [mobile, lastScrollPosition, overlayLocation])

  return (
    <div id="mainScreen" className={styles.mainScreenContent}>
      <WelcomeSection />
      <LocationNewsSection />
      <MapControls />
      <StoresMapSection />
      <LocationOverlaySection />
    </div>
  )
}
