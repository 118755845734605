import { createContext, useContext, useState, useMemo, FC } from 'react'
import { ITeaser } from '@obeta/models/lib/models/Teasers/Teasers'
import { IAllFilters, useTeasers } from '../../startPage/hooks/useTeasers'
import { useTeasersAssignedBrands } from './useTeasersAssignedBrands'
import { TeasersTotals, useTeasersTotals } from './useTeasersTotals'

export enum AllTeasersFilter {
  Category = 'category',
  Brand = 'brand',
}

export type AllTeasersFilters = Record<AllTeasersFilter, string>

interface IBrand {
  id: string
  filterName: string
  name: string
}

interface IAdvertaisingContextData {
  adverts: ITeaser[]
  filters: AllTeasersFilters
  updateFilters: (value: Partial<AllTeasersFilters>) => void
  isLoading: boolean
  assignedBrands: IBrand[]
  teasersTotals: TeasersTotals
}

export enum TeasersCategory {
  All = 'All',
  MegaDeal = 'megaDeal',
  WeekendDeal = 'weekendDeal',
  RedArticle = 'redArticle',
  ActionWeek = 'actionWeek',
}

export const ALL_BRANDS_OPTION_KEY = 'All'

export const AdvertisingContext = createContext<IAdvertaisingContextData | null>(null)

export const formatBrandFilterLabel = (label?: string) =>
  label ? label[0] + label.slice(1).toLowerCase() : ''

const transformFiltersFormat = (
  filters: AllTeasersFilters,
  assignedBrands: IBrand[]
): Partial<IAllFilters> => {
  const isCategory = (key: string) => key === AllTeasersFilter.Category
  const isBrand = (key: string) => key === AllTeasersFilter.Brand

  return Object.keys(filters).reduce((acc, key) => {
    if (!filters[key]) return acc

    if (isCategory(key)) {
      return filters[key] === TeasersCategory.All ? acc : { ...acc, [filters[key]]: { eq: true } }
    }

    if (isBrand(key)) {
      if (filters[key] === ALL_BRANDS_OPTION_KEY) {
        return acc
      } else {
        // Get all brands that matches with selected id
        const brandValues = assignedBrands.filter((brand) => brand.id === filters[key])

        return {
          ...acc,
          or: brandValues.map((brand) => {
            return {
              brand: {
                contains: `/${brand.id}/`,
              },
            }
          }),
        }
      }
    }

    return acc
  }, {})
}

export const AdvertisingContextProvider: FC = ({ children }) => {
  const [filters, setFilters] = useState<AllTeasersFilters>({
    category: TeasersCategory.All,
    brand: ALL_BRANDS_OPTION_KEY,
  })

  const [pagination] = useState({
    limit: 50,
  })

  // Although brands and totals data are part of the same resolver, we've split them into two hooks.
  // This separation allows us to call assignedBrands just once, instead of on every change in filters.brand
  const { assignedBrands } = useTeasersAssignedBrands()
  const { teasersTotals } = useTeasersTotals(filters.brand)

  const transformedFilters = useMemo(
    () => transformFiltersFormat(filters, assignedBrands),
    [filters, assignedBrands]
  )

  const { teasers, isLoading } = useTeasers({
    filters: transformedFilters,
    pagination,
  })

  const updateFilters = (newFilters: Partial<AllTeasersFilters>) => {
    setFilters((prev) => ({ ...prev, ...newFilters }))
  }

  return (
    <AdvertisingContext.Provider
      value={{
        adverts: teasers,
        teasersTotals,
        filters,
        isLoading,
        updateFilters,
        assignedBrands,
      }}
    >
      {children}
    </AdvertisingContext.Provider>
  )
}

export const useAdvertisingProvider = () => {
  const searchData = useContext(AdvertisingContext)

  if (!searchData) {
    throw new Error(
      'advertising is not defined. Make sure you call useAdvertisingProvider inside one of AdvertisingContext child'
    )
  }

  return searchData
}
