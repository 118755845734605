import { gql, useQuery } from '@apollo/client'
import { ArticleSearchParams, Facet } from '@obeta/models/lib/models/Search'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { useWarehouseContext } from '../stores/useWarehouseContext'

const SUPPLIERS_QUERY = gql`
  query GetSuppliers($searchString: String, $facets: [String!], $filter: String) {
    searchProducts(
      searchString: $searchString
      requestOptions: { facets: $facets, filters: $filter }
    ) {
      facets {
        name
        values {
          value
          count
          meta
        }
        meta
      }
    }
  }
`

const defRes = []

export const useSuppliers = (p: { searchParams?: ArticleSearchParams }) => {
  const { warehouseId } = useWarehouseContext()
  const s: ArticleSearchParams = {
    ...p.searchParams,
    suppliers: undefined, // do not filter suppliers
  }

  const filters = buildFilterParams(s, warehouseId)

  const { data, loading } = useQuery<{ searchProducts: { facets: Facet[] } }>(SUPPLIERS_QUERY, {
    variables: {
      facets: ['supplierFilterName'],
      filter: filters || '',
      searchString: s.searchString || '',
    },
  })

  return {
    suppliers:
      data?.searchProducts?.facets.find((facet) => facet.name === 'supplierFilterName')?.values ||
      defRes,
    loading,
  }
}
