import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'

// Components
import { OrderDetailHeader } from '@obeta/components/lib/orders/OrderDetailHeader'
import { OrderDetailProductList } from '@obeta/components/lib/orders/OrderDetailProductList'
import { OrderDetailsSelectionBar } from '@obeta/components/lib/orders/OrderDetailsSelectionBar'

// Context
import {
  OrderDetailsContextProvider,
  useOrderDetailsContext,
} from '@obeta/data/lib/stores/useOrderDetailsContext'

// Hooks
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useGetOrderHeaderById } from '@obeta/data/lib/hooks/useGetOrderHeaderById'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'

// Models
import { MultiSelectionString, ShoppingCartForDropdown } from '@obeta/models/lib/models'

// Providers
import { withProvider } from '@obeta/data/lib/providers/withProvider'

// Styles
import styles from './orderDetailsPage.module.scss'

// Utils
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { OrderActionTypes, UpdateOrderMetaDataGraphQLResultAction } from '@obeta/data/lib/actions'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useActionNotification } from '@obeta/data/lib/hooks'
import { useGetOrderShippingDetails } from '@obeta/data/lib/hooks/useGetOrderShippingDetails'

const INITIAL_SELECTED_ORDER_ITEMS: MultiSelectionString = {
  selectAll: false,
  include: [],
  exclude: [],
  search: {
    searchTerm: '',
    filter: '',
    orderBy: '',
    orderDir: '',
  },
}

const OrderDetailsPage: FC = withProvider(() => {
  useAuthenticatedRoute()
  const { order, getOrderHeaderById } = useGetOrderHeaderById()
  const { orderShippingDetails, getOrderShippingDetails } = useGetOrderShippingDetails()
  const cartsV2 = useCartsv2WithPricesAndStock()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const { itemState, orderItems, searchTerm } = useOrderDetailsContext()
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)
  const [selectedOrderItems, setSelectedOrderItems] = useState<MultiSelectionString>({
    ...INITIAL_SELECTED_ORDER_ITEMS,
  })

  const waitForUpdateOrderMetaDataAction = useActionNotification(
    OrderActionTypes.UpdateOrderMetaDataGraphQLResult
  )

  const shoppingCartsToMoveOrderItemsTo: ShoppingCartForDropdown[] = []
  cartsV2.forEach((cartV2) => {
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: cartV2.id,
      name: cartV2.name,
      articleCount: cartV2.items.length,
      offerId: cartV2.offerId,
      count: cartV2.items.length,
    }
    shoppingCartsToMoveOrderItemsTo.push(shoppingCartForDropdown)
  })

  const [modifiedOrderItemAmounts, setModifiedOrderItemAmounts] = useState<
    { orderItemId: string; amount: number }[]
  >([])

  // Set offer on mount by id and initial search offer items input
  useEffect(() => {
    getOrderHeaderById({ orderId: params.id }).then(() => setIsInitialLoading(false))
    getOrderShippingDetails({ orderId: params.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  // Refetch orderHeader + orderItems on successful update of order metadata (i.e. order name)
  waitForUpdateOrderMetaDataAction((action: UpdateOrderMetaDataGraphQLResultAction) => {
    if (action.success) {
      getOrderHeaderById({ orderId: params.id }).then(() => setIsInitialLoading(false))
    }
  })

  // Refetch orderHeader + orderItems should we receive a notification that the order has been updated
  useEffect(() => {
    const sub = getEventSubscription().subscribe((event) => {
      if (
        event.notificationType === NotificationType.OrderUpdate &&
        event.type === EventType.Data &&
        'orderId' in event.options &&
        event.options.orderId === params.id
      ) {
        getEventSubscription().next({
          type: EventType.Snackbar,
          notificationType: NotificationType.OrderUpdate,
          id: `${event.options.orderId}-${NotificationType.OrderUpdate}`,
          options: {
            orderId: event.options.orderId,
          },
        })
      }
    })
    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isInitialLoading && order === null) {
    history.push(ShopRoutes.OrdersList)
    // Render user facing notification to inform them that the order was not found
    getEventSubscription().next({
      options: { orderId: params.id },
      type: EventType.Toast,
      notificationType: NotificationType.OrderNotFound,
      id: `${NotificationType.OrderNotFound}-${params.id}`,
    })
    return null
  }

  const resetSelectedOrderItems = (): MultiSelectionString => {
    const deselectedOrderItems = {
      selectAll: false,
      include: [],
      exclude: [],
      search: {
        searchTerm: searchTerm,
        filter: itemState === 'all' ? '' : itemState,
        orderBy: '',
        orderDir: '',
      },
    }

    setSelectedOrderItems(deselectedOrderItems)
    return deselectedOrderItems
  }
  const selectAllOrderItems = (): MultiSelectionString => {
    trackClick('select-all-order-items', { offerId: params.id })

    const allSelectedOrderItems = {
      selectAll: true,
      include: [],
      exclude: [],
      search: {
        searchTerm: searchTerm,
        filter: itemState === 'all' ? '' : itemState,
        orderBy: '',
        orderDir: '',
      },
    }

    setSelectedOrderItems(allSelectedOrderItems)
    return allSelectedOrderItems
  }

  const onOrderItemAmountChanged = (orderItemId: string, amount: number) => {
    trackClick('on-order-item-amount-changed', {
      orderId: params.id,
      orderItemId,
      amount,
    })
    const updatedModifiedOrderItemAmounts = [...modifiedOrderItemAmounts]
    const idx = updatedModifiedOrderItemAmounts.findIndex((el) => el.orderItemId === orderItemId)

    if (idx >= 0) {
      updatedModifiedOrderItemAmounts[idx] = {
        orderItemId: orderItemId,
        amount: amount,
      }
    } else {
      updatedModifiedOrderItemAmounts.push({
        orderItemId: orderItemId,
        amount: amount,
      })
    }
    setModifiedOrderItemAmounts(updatedModifiedOrderItemAmounts)
  }
  const onSelectOrderItem = (orderItemId: string) => {
    selectOrderItem(
      order?.id,
      orderItemId,
      // if search term or status filter applied we consider a subset of items to be ALL, hence the following logic
      searchTerm !== '' || itemState !== 'all' ? orderItems.length : order?.itemCount ?? 0,
      selectedOrderItems,
      setSelectedOrderItems,
      selectAllOrderItems,
      resetSelectedOrderItems
    )
  }

  return (
    <>
      <div className={styles.background} />
      <div className={styles.orderDetails}>
        <Grid container direction="column" item className={styles.appBarWrapper}>
          {isInitialLoading || !order ? (
            <Typography variant="headline3Bold">{t('ORDERS.ORDER')}</Typography>
          ) : (
            <div>
              <OrderDetailHeader
                order={order}
                onSelectAll={() =>
                  selectedOrderItems.selectAll ? resetSelectedOrderItems() : selectAllOrderItems()
                }
                orderShippingDetails={orderShippingDetails}
                resetSelectedOrderItems={resetSelectedOrderItems}
                selectedOrderItems={selectedOrderItems}
              />
              <OrderDetailProductList
                itemCount={order.itemCount}
                onOrderItemAmountChanged={onOrderItemAmountChanged}
                onSelectOrderItem={onSelectOrderItem}
                selectedOrderItems={selectedOrderItems}
              />
            </div>
          )}
        </Grid>
      </div>
      <OrderDetailsSelectionBar
        dropDownCarts={shoppingCartsToMoveOrderItemsTo}
        modifiedOrderItemAmounts={modifiedOrderItemAmounts}
        order={order}
        resetSelectedOrderItems={resetSelectedOrderItems}
        searchTerm={searchTerm}
        selectedOrderItems={selectedOrderItems}
      />
    </>
  )
}, OrderDetailsContextProvider)

export const selectOrderItem = (
  orderId: string | undefined,
  orderItemId: string,
  orderItemCount: number,
  selectedOrderItems: MultiSelectionString,
  setSelectedOrderItems: React.Dispatch<React.SetStateAction<MultiSelectionString>>,
  selectAllOrderItems: () => MultiSelectionString,
  resetSelectedOrderItems: () => MultiSelectionString
) => {
  // Create updated selected order items MultiSelection object for DataDog tracking
  let updatedSelectedOrderItems: MultiSelectionString | undefined = undefined

  // 1) Nur einzelne oder keine Artikel ausgewählt
  if (!selectedOrderItems.selectAll) {
    if (selectedOrderItems.include.includes(orderItemId)) {
      // 1a auf INCLUDE list --> entfernen
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        include: [...selectedOrderItems.include.filter((el) => el !== orderItemId)],
      }
    } else {
      // 1b einzigstes element nicht auf include list --> toggle und ALLE auswählen
      if ([...selectedOrderItems.include, orderItemId].length === orderItemCount) {
        updatedSelectedOrderItems = selectAllOrderItems()
      } else {
        // 1c nicht auf INCLUDE list --> hinzufügen
        updatedSelectedOrderItems = {
          ...selectedOrderItems,
          include: [...selectedOrderItems.include, orderItemId],
        }
      }
    }
  }
  // 2) Alle ausgewählt --> Add to EXCLUDE list
  else if (selectedOrderItems.selectAll && selectedOrderItems.exclude.length === 0) {
    if (orderItemCount === 1) {
      updatedSelectedOrderItems = resetSelectedOrderItems()
    } else {
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        exclude: [...selectedOrderItems.exclude, orderItemId],
      }
    }
  }
  // 3) Alle ausgewählt und exclude Liste existiert
  // 3a) auf exclude list --> entnehmen
  else if (selectedOrderItems.selectAll && selectedOrderItems.exclude.includes(orderItemId)) {
    updatedSelectedOrderItems = {
      ...selectedOrderItems,
      exclude: [...selectedOrderItems.exclude.filter((el) => el !== orderItemId)],
    }
  }
  // 3b) nicht in exclude liste --> in exclude liste übernehmen
  else if (selectedOrderItems.selectAll && !selectedOrderItems.exclude.includes(orderItemId)) {
    if (
      selectedOrderItems.selectAll &&
      [...selectedOrderItems.exclude, orderItemId].length === orderItemCount
    ) {
      updatedSelectedOrderItems = resetSelectedOrderItems()
    } else {
      updatedSelectedOrderItems = {
        ...selectedOrderItems,
        exclude: [...selectedOrderItems.exclude, orderItemId],
      }
    }
  }

  if (updatedSelectedOrderItems) {
    setSelectedOrderItems(updatedSelectedOrderItems)
  }

  trackClick('select-order-item', {
    request: {
      orderId,
      orderItemId,
    },
    response: updatedSelectedOrderItems,
  })
}

export default OrderDetailsPage
