import { IPromotionData } from '@obeta/models/lib/models/Promotion/Promotion'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { capitalizeFirstLetter } from '@obeta/utils/lib/capitalizeFirstLetter'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useEffect } from 'react'

export const usePromotionAvailability = (promotion: IPromotionData | null) => {
  const domain = useAppDomain()
  const history = useHistory()

  useEffect(() => {
    if (!promotion) return

    const { showInApp } = promotion

    const isCapacitorApp = !isPlatform('web')
    const isNotAvailableForApp = isCapacitorApp && !showInApp
    const isNotValidForCurrentDomain = !promotion[`showFor${capitalizeFirstLetter(domain)}`]

    if (isNotAvailableForApp || isNotValidForCurrentDomain) {
      history.replace('/')
    }
  }, [promotion, history, domain])
}
