import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  useTheme,
  Tooltip,
  SvgIcon,
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StoreV2, StoreV2Types } from '@obeta/models/lib/models/Stores/StoreV2'
import { ShoppingCartFilterEnum } from '@obeta/models/lib/models/ShoppingCart'
import {
  ShippingDate,
  ShippingOptions,
  ShippingType,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import dayjs from 'dayjs'
import { BasicDatePicker } from '../date-picker/BasicDatePicker'
import styles from './ShoppingCartPickupOptions.module.scss'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import clsx from 'clsx'
import { ReactComponent as InformationIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning_amber.svg'
import { PartialShippingDataPickup } from '@obeta/models/lib/models/ShoppingCart/ShippingData'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'

interface Props {
  partialShippingDataPickup: PartialShippingDataPickup
  preFilterCartItems: (mode: ShoppingCartFilterEnum) => void
  savedShippingType: ShippingType
  selectedStore: StoreV2
  selectedShippingType: string
  selectedShippingDate: string
  updateShippingType: (shippingType: ShippingType) => void
  updateShippingDate: (date: string) => void
  setPartialShippingDataPickup: (shippingData: PartialShippingDataPickup) => void
  setValuesForMemoryBox: (param) => void
  shippingOptions: ShippingOptions[]
  stockSufficientForExpressPickup: boolean
  cartContainsSpecialItems: boolean
}

export const ShoppingCartPickupOptions: React.FC<Props> = (props) => {
  const {
    preFilterCartItems,
    selectedStore,
    selectedShippingType,
    selectedShippingDate,
    updateShippingDate,
    setValuesForMemoryBox,
    shippingOptions,
    updateShippingType,
    stockSufficientForExpressPickup,
    cartContainsSpecialItems,
  } = props

  const [pickupDatesForDatePicker, setPickupDatesForDatePicker] = React.useState<string[]>([])
  const [currentShippingDate, setCurrentShippingDate] = React.useState('')

  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const theme = useTheme()

  // TODO: state still needed if value gets never read?
  const [, setSelectedPickupType] = useState<ShippingType>(ShippingType.DefaultParcel)

  const defaultPickupDateAndTime =
    shippingOptions.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.DefaultPickup
    )?.shippingDates[0]?.date ?? ''

  const xpressPickupDateAndTime =
    shippingOptions.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.ExpressPickup
    )?.shippingDates[0]?.date ?? ''

  const selectedDefaultDeliveryDate =
    selectedShippingType === ShippingType.DefaultPickup
      ? selectedShippingDate
      : defaultPickupDateAndTime

  useEffect(() => {
    // re-set possible pickup dates should delivery options change
    if (shippingOptions.length === 0) {
      return
    }
    const shippingDatesForInitialPickupType = shippingOptions
      .find(
        (shippingOption) =>
          shippingOption.shippingType === selectedShippingType && shippingOption.isEnabled
      )
      ?.shippingDates.map((shippingDate) => shippingDate.date) as string[]

    if (shippingDatesForInitialPickupType) {
      setPickupDatesForDatePicker(shippingDatesForInitialPickupType)
      setCurrentShippingDate(shippingDatesForInitialPickupType[0])
    }
  }, [selectedShippingType, shippingOptions])

  useEffect(() => {
    setValuesForMemoryBox({ shippingData: { shippingDate: currentShippingDate } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShippingDate])

  const handleChangingShippingType = (event) => {
    const selectedShippingOption = shippingOptions.find((shippingOption) => {
      return shippingOption.shippingType === event.currentTarget.value
    })

    if (selectedShippingOption) {
      updateShippingType(selectedShippingOption.shippingType)
      setSelectedPickupType(selectedShippingOption.shippingType)
    }

    const deliveryDates = selectedShippingOption?.shippingDates.map(
      (shippingDate: ShippingDate) => shippingDate.date
    ) as string[]
    setPickupDatesForDatePicker(deliveryDates)
  }

  const defaultPickupDisabled =
    shippingOptions.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.DefaultPickup
    )?.isEnabled === false

  const expressPickupDisabledViaShippingOptions =
    shippingOptions.find(
      (shippingOption) => shippingOption.shippingType === ShippingType.ExpressPickup
    )?.isEnabled === false
  const expressPickupDisabled =
    expressPickupDisabledViaShippingOptions || !stockSufficientForExpressPickup

  return (
    <Grid container direction={'row'} spacing={1} className={styles.optionsOuterGrid}>
      <Grid xs={12}>
        <BasicDatePicker
          datesAsString={pickupDatesForDatePicker}
          updateShippingDate={updateShippingDate}
          selectedShippingDate={
            selectedShippingType === ShippingType.DefaultPickup
              ? selectedShippingDate ??
                shippingOptions.find(
                  (shippingOption) => shippingOption.shippingType === ShippingType.DefaultPickup
                )?.shippingDates[0]
              : currentShippingDate
          }
        />
      </Grid>
      <Grid>
        {/* Info Sonderartikel */}
        {cartContainsSpecialItems && (
          <Box pt={1}>
            <SimpleInfoBlock
              variant="dark"
              Icon={WarningIcon}
              body={t('SHOPPING_CART.CHECKOUT.DELIVERY_SPECIAL_ITEMS_WARNING')}
            />
          </Box>
        )}
        <RadioGroup
          onChange={handleChangingShippingType}
          value={selectedShippingType}
          className={styles.trial}
        >
          <FormControlLabel
            data-testid="default-pickup-option"
            className={styles.formControlLabel}
            color={defaultPickupDisabled ? theme.palette.text.disabled : theme.palette.text.primary}
            value={ShippingType.DefaultPickup}
            control={
              <Radio className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)} />
            }
            label={
              <Typography
                variant={'bodyBold'}
                color={
                  defaultPickupDisabled ? theme.palette.text.disabled : theme.palette.text.primary
                }
              >
                {selectedStore.type === StoreV2Types.normal
                  ? t('SHOPPING_CART.CHECKOUT.PICKUP_LOCATION_STORE', {
                      storeName: selectedStore.address.name1,
                    })
                  : t('SHOPPING_CART.CHECKOUT.PICKUP_LOCATION_NON_STORE', {
                      locationName: selectedStore.address.name1,
                    })}
              </Typography>
            }
            disabled={defaultPickupDisabled}
          />
          {!defaultPickupDisabled && (
            <Typography variant={'smallText'} className={clsx(styles.infoText, styles.success)}>
              {t('SHOPPING_CART.CHECKOUT.PICKUP_DATE', {
                date: dayjs(selectedDefaultDeliveryDate).locale('de').format('dd, DD.MM.YYYY'),
              })}
            </Typography>
          )}
          <FormControlLabel
            data-testid="express-pickup-option"
            className={styles.formControlLabel}
            value={ShippingType.ExpressPickup}
            control={
              <Radio className={clsx(!desktop && styles.enlargedRadioIconForTouchDevices)} />
            }
            label={
              <>
                <Typography
                  className={styles.xpressTitle}
                  variant={'bodyBold'}
                  color={
                    expressPickupDisabled ? theme.palette.text.disabled : theme.palette.text.primary
                  }
                >
                  {t('SHOPPING_CART.CHECKOUT.XPRESS_PICKUP_LOCATION', {
                    storeName: selectedStore.address.name1,
                  })}
                </Typography>
                {desktop && (
                  <Tooltip
                    classes={{ tooltip: styles.tooltip }}
                    title={<>{t('SHOPPING_CART.CHECKOUT.XPRESS_TOOLTIP_TEXT')}</>}
                  >
                    <SvgIcon component={InformationIcon} htmlColor={theme.palette.secondary.main} />
                  </Tooltip>
                )}
              </>
            }
            disabled={expressPickupDisabled}
          />
          {!expressPickupDisabled && (
            <Typography variant={'smallText'} className={clsx(styles.infoText, styles.success)}>
              {t('SHOPPING_CART.CHECKOUT.PICKUP_DATE', {
                date: dayjs(xpressPickupDateAndTime).locale('de').format('DD.MM.YYYY'),
                time: dayjs(xpressPickupDateAndTime).locale('de').format('HH'),
              })}
            </Typography>
          )}
          {/* Only show the below info message if the sole cause for the disabled option are the items with missing stock */}
          {!expressPickupDisabledViaShippingOptions && !stockSufficientForExpressPickup && (
            <Typography variant={'smallText'} className={styles.infoText}>
              {t('SHOPPING_CART.CHECKOUT.XPRESS_PICKUP_DISABLED.0')}
              <u className={styles.cursorPointer}>
                <strong onClick={() => preFilterCartItems(ShoppingCartFilterEnum.XPRESS)}>
                  {t('SHOPPING_CART.CHECKOUT.XPRESS_PICKUP_DISABLED.1')}
                </strong>
              </u>
              {t('SHOPPING_CART.CHECKOUT.XPRESS_PICKUP_DISABLED.2')}
            </Typography>
          )}
        </RadioGroup>
      </Grid>
      <Grid xs={12}>
        <LightGrayDivider className={styles.pickupDivider} />
      </Grid>
    </Grid>
  )
}
