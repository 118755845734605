import { useEffect, useRef, RefObject } from 'react'
import { useRxDB } from 'rxdb-hooks'
import { useDispatch } from 'react-redux'
import {
  getOxomiMetadata,
  loadCategories,
  loadUserGraphQl,
  loadUserProfile,
} from '@obeta/data/lib/actions'
import { UserData } from '@obeta/data/lib/hooks/useUserData'
import { IGlobalNotificationsApi } from '@obeta/components/lib/global-notifications/GlobalNotifications'
import { UserV2Data } from '@obeta/models/lib/models/Users/UserV2'

let lastRefetchTime = Date.now()

type UseFetchInitialDataProps = {
  isLoggedIn: boolean
  notificationsRef: RefObject<IGlobalNotificationsApi>
  userId: UserData['userId']
  companyId: UserV2Data['companyId']
}

export const useFetchInitialData = ({
  isLoggedIn,
  notificationsRef,
  userId,
  companyId,
}: UseFetchInitialDataProps) => {
  const db = useRxDB()
  const dispatch = useDispatch()
  const firstFetchRef = useRef(false)

  useEffect(() => {
    dispatch(getOxomiMetadata(15 * 1000 * 60))
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) {
      // user logged out. reset this flag so data can be fetched immediately
      // after user logged in again.
      firstFetchRef.current = false
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) dispatch(loadUserGraphQl())
  }, [isLoggedIn, dispatch])

  function fetchData() {
    if (isLoggedIn && userId && companyId && db.isLeader()) {
      dispatch(loadUserGraphQl())
      dispatch(loadCategories())
      dispatch(loadUserProfile({ userId, companyId }))
    }
  }

  const shouldFetchData = () => {
    if (lastRefetchTime + 10 * 1000 * 60 < Date.now()) {
      lastRefetchTime = Date.now()
      fetchData()
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(loadCategories())
      firstFetchRef.current = false
    } else if (isLoggedIn && userId && companyId) {
      if (!firstFetchRef.current) {
        firstFetchRef.current = true
        // Reset all alerts and toasts if the user is loggedIn
        notificationsRef.current?.reset()
        db.waitForLeadership().then(() => {
          fetchData()
        })
      }

      window.addEventListener('click', shouldFetchData)

      return () => {
        window.removeEventListener('click', shouldFetchData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userId, companyId])
}
