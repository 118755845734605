import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getRaffleInformation,
  GetRaffleInformationResultAction,
  RaffleActionTypes,
  takePartInRaffle,
  TakePartInRaffleResultAction,
} from '@obeta/data/lib/actions/raffle-actions'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'

interface UseRaffleActionsResult {
  isRegistered: boolean
  register: () => void
}
export const useRaffleActions = (): UseRaffleActionsResult => {
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const user = useUserDataV2()
  const dispatch = useDispatch()
  const waitForGetRaffleInformationResult = useActionNotification(
    RaffleActionTypes.GetRaffleInformationResult
  )
  const waitForTakePartInRaffleResult = useActionNotification(
    RaffleActionTypes.TakePartInRaffleResult
  )
  const isReadyForRaffleActions = user.fullUserId !== undefined && user.allTokensExist

  useEffect(() => {
    if (isReadyForRaffleActions) {
      dispatch(getRaffleInformation())
    }
  }, [dispatch, isReadyForRaffleActions])

  const register = useCallback(() => {
    if (isReadyForRaffleActions) {
      dispatch(takePartInRaffle())
    }
  }, [dispatch, isReadyForRaffleActions])

  waitForGetRaffleInformationResult((response: GetRaffleInformationResultAction) => {
    setIsRegistered(response.alreadyRegistered)
  })

  waitForTakePartInRaffleResult((response: TakePartInRaffleResultAction) => {
    setIsRegistered(response.success)
  })

  return { isRegistered, register }
}
