import * as React from 'react'
import styles from './BasicDatePicker.module.scss'
import TextField from '@mui/material/TextField'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import de from 'dayjs/locale/de'
import { useMemo } from 'react'

interface Props {
  datesAsString: string[]
  updateShippingDate: (date: string) => void
  selectedShippingDate?: string
}

const localeMap = {
  de: de,
}

export const BasicDatePicker: React.FC<Props> = (props) => {
  const { datesAsString, updateShippingDate, selectedShippingDate } = props

  const dates: dayjs.Dayjs[] = useMemo(() => {
    return datesAsString.map((dateString: string) => dayjs(dateString))
  }, [datesAsString])

  const [value, setValue] = React.useState<dayjs.Dayjs | null>(dates[0] ?? null)

  React.useEffect(() => {
    if (
      selectedShippingDate &&
      datesAsString.includes(dayjs(selectedShippingDate).format('YYYY-MM-DD'))
    ) {
      setValue(dayjs(selectedShippingDate))
      return
    }
    setValue(dates[0])
    dates[0] && updateShippingDate(dates[0].format('YYYY-MM-DDTHH:mm:ssZ'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesAsString])

  const disableDate = (date: dayjs.Dayjs) => {
    const result = dates.filter((providedDate) => {
      return providedDate.isSame(date, 'day')
    })

    return result.length === 0
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap.de}>
      <DatePicker
        className={styles.datepicker}
        OpenPickerButtonProps={{ disableRipple: true }}
        label=""
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          newValue?.isValid() && updateShippingDate(newValue.format('YYYY-MM-DDTHH:mm:ssZ'))
        }}
        minDate={dates[0]}
        maxDate={dates[dates.length - 1]}
        shouldDisableDate={disableDate}
        InputProps={{
          className: styles.outlinedInput,
          sx: {
            '& .MuiOutlinedInput-input': {
              height: '3rem',
              fontWeight: 700,
            },
          },
        }}
        renderInput={(params) => <TextField className={styles.textfield} {...params} />}
        PaperProps={{ className: styles.datePicker }}
        PopperProps={{ placement: 'bottom-start' }}
        mask="__.__.____"
        inputFormat="DD.MM.YYYY"
      />
    </LocalizationProvider>
  )
}
