import { IMediaElementOptions, IMediaElementProps, MediaElement, Player } from '../mediaelement'
import React, { useEffect, useRef } from 'react'
import { useVideoSlidesController } from './VideoSlidesController'

const mediaElementOptions: IMediaElementOptions = {
  stretching: 'responsive',
}

interface IArticleVideSlideProps {
  id: IMediaElementProps['id']
  poster: IMediaElementProps['poster']
  smallUrl: string
  mediumUrl: string
}

export const VideoSlide: React.FC<IArticleVideSlideProps> = (props) => {
  const player = useRef<Player>()
  const controller = useVideoSlidesController()

  useEffect(() => {
    // try to add slide to "controller" if possible
    if (controller && player.current) {
      const onPlay = () => {
        if (player.current) {
          controller.setPlayingId(player.current.id as string)
        }
      }
      player.current.media.addEventListener('play', onPlay)
      controller.addToController(player.current.id, player.current)

      return () => {
        if (player.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          player.current.media.removeEventListener('play', onPlay)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MediaElement
      ref={player}
      preload="none"
      mediaType="video"
      sources={[
        // "oxomi" doesn't provide much of meta info for sources, so type and quality should be hardcoded
        // "quality" names were chosen after inspection of resolution of sources.
        { src: props.smallUrl, type: 'video/mp4', quality: '480p' },
        { src: props.mediumUrl, type: 'video/mp4', quality: '720p' },
      ]}
      controls={true}
      options={mediaElementOptions}
      tracks={[]}
      {...props}
    />
  )
}
