import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { NewsCard } from '@obeta/components/lib/newscard/NewsCard'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { Section } from '../../../startPage/Section'
import { ProductsSwiper } from '../../../articleDetailsPage/ProductsSwiper'
import { formatDateAsMonthNameAndYear } from '../../../startPage/NewsCardSection'
import { useNews } from '../../../startPage/hooks/useNews'
import styles from './PinnedNewsSection.module.scss'

interface IPinnedNewsProps {
  pinnedNews: INewsCard[]
}

export const PinnedNewsView: FC<IPinnedNewsProps> = ({ pinnedNews }) => {
  const { mobile } = useBreakpoints()

  return (
    <div className={styles['pinnedNews-card-container']}>
      <ProductsSwiper
        closeNavigationButtons={mobile}
        cardWrapperClassName={styles.cardsWrapper}
        withNavigateButtons={!mobile}
        isSliderLoop={false}
        elementsPerRow={1}
        productElements={pinnedNews.map((newsEntry) => (
          <NewsCard
            {...newsEntry}
            options={{
              textMaxLines: !mobile ? 10 : 3,
            }}
            classes={{
              cardWrapperClassName: styles.cardWrapper,
              contentWrapperClassName: styles.contentWrapper,
              imageWrapperClassName: styles.imageWrapper,
            }}
            startDate={formatDateAsMonthNameAndYear(newsEntry.publishedAt)}
            key={newsEntry.id}
          />
        ))}
      />
    </div>
  )
}

export const PinnedNewsSection: FC = () => {
  const { t } = useTranslation()
  const { news: pinnedNews } = useNews({
    config: {
      filters: {
        pinned: {
          eq: true,
        },
      },
    },
  })

  if (!pinnedNews.length) {
    return null
  }

  return (
    <Section
      title={t('START.NEWS')}
      className={styles.pinnedSectionWrapper}
      content={<PinnedNewsView pinnedNews={pinnedNews} />}
    />
  )
}
