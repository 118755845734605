import { gql, useQuery } from '@apollo/client'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import {
  GetCrossSellingProductsQuery,
  GetCrossSellingProductsQueryVariables,
  GetUpSellingProductsQuery,
  GetUpSellingProductsQueryVariables,
} from '@obeta/schema'
import type { IProductIds } from './useCurrentProductIds'
import {
  CrossSellingProduct,
  UpSellingProduct,
} from '@obeta/models/lib/schema-models/recommendation'
import { ProductCollectionActionOptions } from './useProductCollectionActions'
import { trackClick, trackCustom } from '@obeta/utils/lib/tracking'

export interface UpCrossSellingReturnData {
  upSellingProducts: UpSellingProduct[]
  crossSellingProducts: CrossSellingProduct[]
  getTrackingOptions: (type: 'upselling' | 'crossselling') => ProductCollectionActionOptions
}

export const UPSELLING_PRODUCTS = gql`
  query getUpSellingProducts($sapIds: [String!], $obetaIds: [String!], $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds, obetaIds: $obetaIds) {
      id
      sapId
      upSelling(warehouseIds: $warehouseIds) {
        id
        sapId
        priceDimension
        title
        dehaId
        oxomiId
        obetaId
        unit
        minimumAmount
        isSendable
        isTopseller
        isCutProduct
        isForSale
        isBlackListed
        type
        prices {
          listPrice
          catalogPrice
          metalNeAddition
          strikeThroughPrice
          netPrice
          currency
        }
        supplierImageData {
          large
        }
        imageData {
          images {
            large
          }
        }
        images {
          url
          width
        }
        stock(warehouseIds: $warehouseIds) {
          sapId
          warehouseId
          amount
          unit
        }
        stockAvailabilityEstimate {
          sapId
          minValue
          maxValue
          error
          unit
        }
      }
    }
  }
`
export const CROSSSELLING_PRODUCTS = gql`
  query getCrossSellingProducts(
    $sapIds: [String!]
    $obetaIds: [String!]
    $warehouseIds: [String!]!
  ) {
    getProducts(sapIds: $sapIds, obetaIds: $obetaIds) {
      id
      sapId
      crossSelling(warehouseIds: $warehouseIds) {
        id
        sapId
        priceDimension
        title
        dehaId
        oxomiId
        obetaId
        unit
        minimumAmount
        isSendable
        isTopseller
        isCutProduct
        isBlackListed
        isForSale
        type
        prices {
          listPrice
          catalogPrice
          metalNeAddition
          strikeThroughPrice
          netPrice
          currency
        }
        supplierImageData {
          large
        }
        imageData {
          images {
            large
          }
        }
        images {
          url
          width
        }
        stock(warehouseIds: $warehouseIds) {
          sapId
          warehouseId
          amount
          unit
        }
        stockAvailabilityEstimate {
          sapId
          minValue
          maxValue
          error
          unit
        }
      }
    }
  }
`

const trackWithGroupAction = (
  name: string,
  type: 'upselling' | 'crossselling',
  context?: Record<string, unknown>
) => {
  trackCustom(`recommendation-${type}-product-interaction`, context)
  trackClick(name, context)
}

const getTrackingOptions = (
  type: 'upselling' | 'crossselling'
): ProductCollectionActionOptions => ({
  onAddToCart: (sapId) =>
    trackWithGroupAction(`recommendation-${type}-add-product-to-cart`, type, { sapId }),
  onAddToTemplate: (sapId) =>
    trackWithGroupAction(`recommendation-${type}-add-product-to-template`, type, { sapId }),
  onLinkClick: (sapId) =>
    trackWithGroupAction(`recommendation-${type}-navigate-to-product-details`, type, { sapId }),
  onSwipeLeft: () => trackClick(`recommendation-${type}-swiper-left`),
  onSwipeRight: () => trackClick(`recommendation-${type}-swiper-right`),
})

export const useUpCrosssSelling = (
  productIds: IProductIds,
  selectedStoreId?: string,
  shouldFetchUpAndCrossSelling = false
): UpCrossSellingReturnData => {
  const { warehouseId } = useWarehouseContext()
  const warehouseIds = [selectedStoreId, warehouseId].filter(Boolean) as string[]

  const dataUpSelling = useQuery<GetUpSellingProductsQuery, GetUpSellingProductsQueryVariables>(
    UPSELLING_PRODUCTS,
    {
      variables: {
        ...productIds,
        warehouseIds,
      },
      skip: !shouldFetchUpAndCrossSelling,
    }
  )

  const upSellingProducts: UpSellingProduct[] =
    dataUpSelling.data?.getProducts && dataUpSelling.data?.getProducts.length > 0
      ? dataUpSelling.data?.getProducts[0]?.upSelling || []
      : []

  const dataCrossSelling = useQuery<
    GetCrossSellingProductsQuery,
    GetCrossSellingProductsQueryVariables
  >(CROSSSELLING_PRODUCTS, {
    variables: {
      ...productIds,
      warehouseIds,
    },
    skip: !shouldFetchUpAndCrossSelling,
  })

  const crossSellingProducts: CrossSellingProduct[] =
    dataCrossSelling.data?.getProducts && dataCrossSelling.data?.getProducts.length > 0
      ? dataCrossSelling.data?.getProducts[0]?.crossSelling || []
      : []

  return {
    upSellingProducts,
    crossSellingProducts,
    getTrackingOptions,
  }
}
