import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, InputBase, IconButton, Box, CircularProgress } from '@mui/material'
import { Modal } from '../modal/Modal'
import { ScannerListTemplate } from './ScannerListTemplate'
import { getTemplatesConfigWithInfo } from './templatesConfig'
import {
  ScannerListType,
  TEMPLATE_VARIANTS,
} from '@obeta/models/lib/models/PrintService/PrintInput'
import { ReactComponent as CloseIcon } from 'assets/icon/close-outline.svg'
import { printScannerlist } from '@obeta/utils/lib/printPdf'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import styles from './ScannerList.module.scss'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'

type ScannerListProps = {
  type: ScannerListType
  isOpen: boolean
  onClose: () => void
  // We use childIds when there is a possibility of manually selecting elements, and parentId when we want to print the entire list
  parentId?: string
  // For select all mode
  childIdsExcluded?: string[] | number[]
  childIds?: string[] | number[]
  showInfoWarning?: boolean
}

export const ScannerList = ({
  type,
  isOpen = false,
  onClose,
  showInfoWarning = false,
  parentId,
  childIdsExcluded,
  childIds,
}: ScannerListProps) => {
  const { t } = useTranslation()
  const [activeTemplate, setActiveTemplate] = useState<TEMPLATE_VARIANTS>(
    TEMPLATE_VARIANTS.ZweckformC32258
  )
  const { tokens } = useUserDataV2()
  const { tablet, mobile } = useBreakpoints()
  const templates = getTemplatesConfigWithInfo(t)

  const [templateOffset, setTemplateOffset] = useState<number | ''>('')
  const [printProcessing, setPrintProcessing] = useState(false)

  const printPdf = async () => {
    setPrintProcessing(true)
    await printScannerlist({
      accessToken: tokens?.accessToken,
      tamplateInfo: {
        templateType: activeTemplate,
        offset: templateOffset || 0,
      },
      parentId,
      type,
      childIds,
      childIdsExcluded,
    })
    setPrintProcessing(false)
  }

  return (
    <Modal
      isFullScreen={tablet || mobile}
      fitContent
      disableAutoFocus
      disableBodyScroll
      onClose={onClose}
      open={isOpen}
    >
      <div className={styles.wrapper}>
        {printProcessing && (
          <div className={styles.processingIndication}>
            <CircularProgress />
          </div>
        )}
        <div className={styles.topSection}>
          <div className={styles.titleWrapper}>
            <Typography variant="headline4Bold">
              {t('COMPONENTS.SCANNERLIST.CHOOSE_PAPER_FORMAT')}
            </Typography>
            <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
              <CloseIcon className={styles.icon} />
            </IconButton>
          </div>
          <div className={styles.filterSection}>
            <Typography variant="body">{t('COMPONENTS.SCANNERLIST.USED_LABELS_COUNT')}</Typography>
            <InputBase
              value={templateOffset}
              onChange={(e) => {
                const inputValue = e.target.value
                const parsedValue = parseInt(inputValue)

                if (!isNaN(parsedValue)) {
                  setTemplateOffset(parsedValue)
                } else if (!inputValue) {
                  setTemplateOffset('')
                }
              }}
              placeholder={t('COMPONENTS.SCANNERLIST.OFFSET_NUMBER_PLACEHOLDER')}
              classes={{
                root: styles.searchRoot,
                input: styles.searchInput,
              }}
              inputProps={{ 'aria-label': 'search', inputMode: 'search' }}
            />
          </div>
          <div className={styles.templatesWrapper}>
            {Object.entries(templates).map(([templateType, template]) => {
              const isActive = templateType === activeTemplate

              return (
                <div
                  key={templateType}
                  className={clsx(styles.templateSection, {
                    [styles.active]: isActive,
                  })}
                >
                  <ScannerListTemplate
                    showInfoWarning={true}
                    templateOffset={templateOffset || 0}
                    template={template}
                    isActive={isActive}
                    updateActiveTemplate={(activeT) => {
                      setActiveTemplate(activeT)
                    }}
                  />
                </div>
              )
            })}
          </div>
          {showInfoWarning && (
            <Box pb={2}>
              <SimpleInfoBlock
                variant={'light'}
                Icon={InfoIcon}
                className={styles.warning}
                bodyTranslationPath={'COMPONENTS.SCANNERLIST.INFO_WARNING'}
              />
            </Box>
          )}
          <div className={styles.footer}>
            <TertiaryButton onClick={onClose}>{t('COMMON.BACK')}</TertiaryButton>
            <DarkButton onClick={printPdf}>{t('COMMON.PROCEED')}</DarkButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
