import { OpenPositionReceiptType } from '@obeta/schema'

export function mapReceiptTypeToDisplayValue(input: OpenPositionReceiptType) {
  switch (input) {
    case 'Invoice':
      return 'RG'
    case 'Credit':
      return 'GU'
    case 'Underpayment':
      return 'UZ'
    case 'Overpayment':
      return 'ÜZ'
    case 'Unknown':
    default:
      return null
  }
}
