import styles from './OrderButton.scss'
import { Button, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as CartIcon } from 'assets/icon/designsystem/cart.svg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRxDB } from 'rxdb-hooks'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'

interface Props {
  orderButtonEnabled: boolean
  userCanSubmitCart: boolean
  notifyShoppingCart: () => void
  submitShoppingCart: () => void
  deliveryDisabled?: boolean
  showShortButtonText?: boolean
}

export const OrderButton: React.FC<Props> = (props) => {
  const {
    orderButtonEnabled,
    userCanSubmitCart,
    notifyShoppingCart,
    submitShoppingCart,
    deliveryDisabled,
    showShortButtonText = false,
  } = props

  const theme = useTheme()
  const { tablet, mobile } = useBreakpoints()
  const { t } = useTranslation()
  const db = useRxDB()
  const [creditLimitExceeded, setCreditLimitExceeded] = useState(false)
  const userIsIdsUser = useIsIdsTransferBackPossible()

  useEffect(() => {
    const sub = db.getLocal$<{ exceeded: boolean }>('creditLimit').subscribe((res) => {
      if (res) {
        setCreditLimitExceeded(res._data.data.exceeded)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [db])

  let idsButtonText = ''
  if (!mobile && !tablet) {
    idsButtonText = userCanSubmitCart
      ? t('SHOPPING_CART.CART_SUBMIT_AND_TRANSFER_IDS')
      : t('SHOPPING_CART.CART_NOTIFY_AND_TRANSFER_IDS')
  } else if (tablet) {
    idsButtonText = userCanSubmitCart
      ? t('SHOPPING_CART.CART_SUBMIT_AND_TRANSFER_IDS_TABLET')
      : t('SHOPPING_CART.CART_NOTIFY_AND_TRANSFER_IDS_TABLET')
  }
  const orderButtonText = userIsIdsUser ? idsButtonText : t('SHOPPING_CART.CART_SUBMIT')
  const orderButtonTextMobile = userIsIdsUser
    ? idsButtonText
    : t('SHOPPING_CART.CART_SUBMIT_MOBILE')

  return (
    <div className={clsx(styles.wrapper, styles.fullWidth)}>
      {((creditLimitExceeded && userCanSubmitCart) || !orderButtonEnabled || deliveryDisabled) && (
        /* Order button disabled */
        <Button
          className={styles.fullWidth}
          type="submit"
          disabled={true}
          style={{ width: '100%' }}
          variant={'contained'}
          color={'primary'}
          startIcon={<SvgIcon htmlColor={theme.palette.white.main} component={CartIcon} />}
        >
          {orderButtonText}
        </Button>
      )}
      {!creditLimitExceeded && userCanSubmitCart && orderButtonEnabled && !deliveryDisabled && (
        /* Order button enabled */
        <Button
          className={styles.fullWidth}
          type="submit"
          disabled={false}
          style={{ width: '100%' }}
          variant={'contained'}
          color={'primary'}
          startIcon={<SvgIcon htmlColor={theme.palette.white.main} component={CartIcon} />}
          onClick={() => submitShoppingCart()}
        >
          {userIsIdsUser && orderButtonText}
          {!userIsIdsUser && !(mobile && showShortButtonText) && orderButtonText}
          {!userIsIdsUser && mobile && showShortButtonText && orderButtonTextMobile}
        </Button>
      )}
      {!userCanSubmitCart && orderButtonEnabled && (
        /* Order button enabled but only notify is allowed */
        <Button
          className={styles.fullWidth}
          type="submit"
          disabled={!orderButtonEnabled}
          style={{ width: '100%' }}
          variant={'contained'}
          color={'secondary'}
          startIcon={<SvgIcon htmlColor={theme.palette.white.main} component={CartIcon} />}
          onClick={() => notifyShoppingCart()}
        >
          {userIsIdsUser && orderButtonText}
          {!userIsIdsUser && !(mobile && showShortButtonText) && t('SHOPPING_CART.CART_NOTIFY')}
          {!userIsIdsUser && mobile && showShortButtonText && t('SHOPPING_CART.CART_NOTIFY_MOBILE')}
        </Button>
      )}
    </div>
  )
}
