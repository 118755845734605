import clsx from 'clsx'
import { BaseOption } from '../select/Select'

import styles from './SelectPopover.mobile.module.scss'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { Typography } from '@mui/material'

interface Props<Option extends BaseOption> {
  selectedOption?: Option
  options: Option[]
  open: boolean
  onClose: () => void
  onOptionSelected: (option: Option) => void
}

export const SelectPopover = <Option extends BaseOption>({
  options,
  selectedOption,
  open,
  onClose,
  onOptionSelected,
}: Props<Option>) => {
  return (
    <PopoverFromBottom isOpen={open} onClose={onClose} paperStyles={styles.paper}>
      {options && (
        <div>
          {options.map((option) => (
            <Typography
              variant="body"
              className={clsx(styles.option, {
                [styles.selected]: selectedOption?.id === option.id,
              })}
              key={option.id}
              onClick={() => {
                onOptionSelected(option)
              }}
            >
              {option.title}
            </Typography>
          ))}
        </div>
      )}
    </PopoverFromBottom>
  )
}
