import { useState, useEffect } from 'react'
import { map, switchMap, of } from 'rxjs'
import { MangoQuery, RxDocument } from 'rxdb'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useRxDB } from 'rxdb-hooks'

const defaultQuery: MangoQuery = {
  selector: {
    id: {
      $exists: true,
    },
  },
}

export function useCartsv2WithPricesAndStock(mangoQuery?: MangoQuery): ShoppingCartV2[] {
  const [entities, setEntities] = useState<ShoppingCartV2[]>([])
  const db = useRxDB()

  useEffect(() => {
    const coll = db['cartsv2']
    let queryToUse: MangoQuery
    if (mangoQuery) {
      queryToUse = mangoQuery
    } else {
      queryToUse = defaultQuery
    }

    const collSub = coll
      .find(queryToUse)
      .$.pipe(
        switchMap((docs) => {
          if (docs.length !== 0) {
            const clonedCarts: ShoppingCartV2[] = []
            const allSapIds: string[] = []
            const allCartIds: string[] = []
            docs.forEach((doc: RxDocument<ShoppingCartV2>) => {
              const clonedCart = doc.toMutableJSON()
              clonedCarts.push(clonedCart)
              clonedCart.items.forEach((item) => allSapIds.push(item.sapId))
              allCartIds.push(clonedCart.id)
            })
            return db.stocks.findByIds(allSapIds).$.pipe(
              map((stockMap) => {
                stockMap.forEach((stock, sapId) => {
                  clonedCarts.forEach((cart) => {
                    // There can be several items with the same sapId in the  cart (e.g. 'Schnittware')
                    const matchingShoppingCartItems = cart.items.filter(
                      (item) => item.sapId === sapId
                    )
                    matchingShoppingCartItems.forEach((shoppingCartItem) => {
                      if (shoppingCartItem && stock) {
                        shoppingCartItem.product.stock = stock.toMutableJSON().stock
                      }
                    })
                  })
                })
                return [...clonedCarts]
              }),
              switchMap((clonedCarts) => {
                return db.cartsv2prices.findByIds(allCartIds).$.pipe(
                  map((pricesMap) => {
                    pricesMap.forEach((rxDoc, cartId) => {
                      const cart = clonedCarts.find((cart) => cart.id === cartId)
                      if (cart) {
                        rxDoc.prices.shoppingCartItemPrices.forEach((price) => {
                          const shoppingCartItem = cart.items.find(
                            (item) => item.id === price.lineItemId
                          )
                          if (shoppingCartItem) {
                            shoppingCartItem.prices = price
                          }
                        })
                        cart.shoppingCartPrices = rxDoc.prices.shoppingCartPrices
                      }
                    })
                    return [...clonedCarts]
                  })
                )
              }),
              switchMap((clonedCarts) => {
                return db.stockavailabilityestimates.findByIds(allSapIds).$.pipe(
                  map((stockAvailabilityEstimatesMap) => {
                    stockAvailabilityEstimatesMap.forEach((rxDoc, sapId) => {
                      clonedCarts.forEach((cart) => {
                        // There can be several items with the same sapId in the  cart (e.g. 'Schnittware')
                        const matchingShoppingCartItems = cart.items.filter(
                          (item) => item.sapId === sapId
                        )
                        matchingShoppingCartItems.forEach((shoppingCartItem) => {
                          if (shoppingCartItem && rxDoc) {
                            shoppingCartItem.product.stockAvailabilityEstimate =
                              rxDoc.toMutableJSON().stockAvailabilityEstimate
                          }
                        })
                      })
                    })
                    return [...clonedCarts]
                  })
                )
              })
            )
          }
          return of([])
        })
      )
      .subscribe((data: ShoppingCartV2[]) => {
        setEntities(data)
      })

    return () => {
      collSub.unsubscribe()
    }
  }, [mangoQuery, db])

  return entities
}
