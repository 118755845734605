import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FavoriteBorderIcon } from '@obeta/assets/icon/designsystem/favourite_border.svg'
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useOffers } from '@obeta/data/lib/hooks/useOffers'
import { FilterSection } from '@obeta/models/lib/models/UI'
import {
  OfferDisplayViewType,
  OfferFlagViewType,
  OfferListViewFilters,
  OfferListPageAction,
} from '@obeta/models/lib/models'
import { OfferFilter, OfferFlag, OfferOrderBy } from '@obeta/schema'
import { useOfferListContext } from '@obeta/data/lib/stores/useOfferListContext'
import styles from './OfferActionsBar.module.scss'
import { Dropdown2 } from '../dropdown2/Dropdown2'
import { FilterButton } from '../filter-button/FilterButton'
import { MobileFilters } from '../filters/Filters'
import { RadioGroup } from '../radio-group/RadioGroup'
import { SearchField } from '../search-field/SearchField'
import { SecondaryIconButton } from '../custom-button/CustomButton'
import { addUniqueValue, removeValues } from '@obeta/utils/lib/array'

type Props = {
  activeDisplay: OfferDisplayViewType
  searchTerm: string
  onDisplaySelect: (display: OfferDisplayViewType) => void
  onSearch: (value: string) => void
}

export const OfferActionsBar: FC<Props> = (props) => {
  const { activeDisplay, searchTerm, onDisplaySelect, onSearch } = props

  const { mobile } = useBreakpoints()
  const { flagsOptions, sortByOptions } = useOffers()
  const { searchOffersInput, totalFavoriteOfferCount, totalOfferCount, setSearchOffersInput } =
    useOfferListContext()
  const { t } = useTranslation()

  // Component state
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [flag, setFlag] = useState<OfferFlagViewType>(flagsOptions[0].value as OfferFlagViewType)
  const [initialFilters, setInitialFilters] = useState<OfferListViewFilters | undefined>(undefined)
  // Note, following COD-16556, user-facing we order by 'startDate', but translate it to the backend as orderBy 'offerId' orderDir 'DESC'
  const [sortBy, setSortBy] = useState<OfferOrderBy>(sortByOptions[0].value as OfferOrderBy)

  const filterSections: FilterSection[] = [
    {
      id: 'flag',
      component: (
        <RadioGroup
          items={flagsOptions}
          value={flag}
          onChange={(value) => onFlagSelect(value as OfferFlagViewType)}
        />
      ),
      title: t('OFFERS.FLAGS.TITLE'),
    },
    {
      id: 'sortBy',
      component: (
        <RadioGroup
          items={sortByOptions}
          value={sortBy}
          onChange={(value) => onSortBy(value as OfferOrderBy)}
        />
      ),
      title: t('SEARCH.SORT_BY.TITLE'),
    },
  ]

  const getActiveDisplayOfferCount = useCallback((): number => {
    if (activeDisplay === 'isFavorite') {
      return totalFavoriteOfferCount
    }
    return totalOfferCount
  }, [activeDisplay, totalFavoriteOfferCount, totalOfferCount])

  /**
   * COD-16556 Sortierung “Angebot vom” sortiert neuste Angebote nach oben (höchste Zahl) --> startDate = DESC
   * COD-16556 Sortierung “Angebotsnummer” sortiert Angebote aufsteigend (kleine Angebotsnummer oben) --> offerId = ASC
   */
  const getOrderDir = useCallback((sortBy: OfferOrderBy) => {
    if (sortBy === 'startDate') {
      return 'DESC'
    }
    return 'ASC'
  }, [])

  /**
   * Handler to close mobile filters. Reset filters state.
   */
  const onMobileFiltersClose = useCallback(() => {
    if (initialFilters) {
      const flagValue = flag === 'all' ? null : flag

      setSearchOffersInput({
        action: OfferListPageAction.FlagSelect,
        filter: setOfferListFlagFilter(flagValue) ?? searchOffersInput.filter,
        limit: searchOffersInput.limit,
        offset: '0',
        orderBy: initialFilters.sortBy,
        orderDir: searchOffersInput.orderDir,
        searchTerm: searchOffersInput.searchTerm,
      })
      setFlag(initialFilters.flag)
      setSortBy(initialFilters.sortBy)
    }
    setInitialFilters(undefined)
    setFiltersOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, initialFilters, searchOffersInput])

  /**
   * Handler to change offer display view type.
   * @param display OfferDisplayViewType
   */
  const onDisplaySelectInit = (display: OfferDisplayViewType) => {
    onDisplaySelect(display)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  /**
   * Handler to change offer flag view type.
   * @param flag OfferFlagViewType
   */
  const onFlagSelect = (flag: OfferFlagViewType) => {
    setFlag(flag)
    const flagValue = flag === 'all' ? null : flag

    setSearchOffersInput({
      action: OfferListPageAction.FlagSelect,
      filter: setOfferListFlagFilter(flagValue) ?? searchOffersInput.filter,
      limit: searchOffersInput.limit,
      offset: '0',
      orderBy: searchOffersInput.orderBy,
      orderDir: searchOffersInput.orderDir,
      searchTerm: searchOffersInput.searchTerm,
    })
  }

  /**
   * Handler to change offer sort by.
   * @param sortBy OfferOrderBy
   */
  const onSortBy = useCallback(
    (sortBy: OfferOrderBy) => {
      setSortBy(sortBy)
      setSearchOffersInput({
        action: OfferListPageAction.OrderBy,
        filter: searchOffersInput.filter,
        limit: searchOffersInput.limit,
        offset: '0',
        // following COD-16556, orderBy 'startDate' gets translated to the backend as orderBy 'offerId' orderDir 'DESC'
        orderBy: sortBy === 'startDate' ? 'offerId' : sortBy,
        orderDir: getOrderDir(sortBy),
        searchTerm: searchOffersInput.searchTerm,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchOffersInput]
  )

  const setOfferListFlagFilter = (flag: OfferFlag | null): OfferFilter[] => {
    // Remove all flag filters from filter list
    const updatedFilters = removeValues<OfferFilter>(
      ['expired', 'expiresSoon', 'new', 'notExpired'],
      searchOffersInput.filter
    )

    // Add desired flag to filter list
    switch (flag) {
      case 'expired':
      case 'expiresSoon':
      case 'new':
      case 'notExpired': {
        return addUniqueValue<OfferFilter>(flag, updatedFilters)
      }
      default:
        break
    }
    return updatedFilters
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <SearchField
          className={styles.search}
          placeholder={t('OFFERS.SEARCH')}
          value={searchTerm}
          onChange={onSearch}
          onReset={() => onSearch('')}
        />
        {!mobile && (
          <div className={styles.flagAndSort}>
            <Dropdown2
              label={t('OFFERS.FLAGS.TITLE')}
              options={flagsOptions}
              value={flag}
              onChange={(value) => onFlagSelect(value as OfferFlagViewType)}
            />
            <Dropdown2
              label={t('SEARCH.SORT_BY.TITLE')}
              options={sortByOptions}
              value={sortBy}
              onChange={(value) => onSortBy(value as OfferOrderBy)}
            />
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomButtons}>
          <FilterButton
            active={activeDisplay === 'all'}
            onClick={() => activeDisplay !== 'all' && onDisplaySelectInit('all')}
          >
            {t('OFFERS.DISPLAY.ALL')} ({totalOfferCount})
          </FilterButton>
          <FilterButton
            active={activeDisplay === 'isFavorite'}
            disabled={totalFavoriteOfferCount < 1}
            icon={<FavoriteBorderIcon />}
            onClick={() => activeDisplay !== 'isFavorite' && onDisplaySelectInit('isFavorite')}
          >
            {t('OFFERS.DISPLAY.FAVORITES')} ({totalFavoriteOfferCount})
          </FilterButton>
        </div>
        {mobile && (
          <>
            <SecondaryIconButton
              icon={<FilterAltIcon />}
              onClick={() => setFiltersOpen(true)}
              size="small"
            />
            <MobileFilters
              amount={getActiveDisplayOfferCount()}
              open={filtersOpen}
              sections={filterSections}
              onClose={onMobileFiltersClose}
              onOpen={() => setInitialFilters({ flag, sortBy })}
              onSubmit={() => setFiltersOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  )
}
