import { Link as MuiLink, Typography } from '@mui/material'
import { IColumn, StripedTable } from '@obeta/components/lib/striped-table/StripedTable'
import { useMemo, useState } from 'react'
import { ReactComponent as TextSnippetIcon } from '@obeta/assets/icon/text_snippet.svg'
import { ReactComponent as DownloadIcon } from '@obeta/assets/icon/designsystem/download.svg'
import { useTranslation } from 'react-i18next'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import styles from './ArticleDownloads.module.scss'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { useArticleDetailsProductMedia } from '@obeta/data/lib/hooks/useArticleDetailsProductMedia'
import { IArticle } from './types'
import { SectionWrapper } from './SectionProvider'
import { LabelWithIcon } from '@obeta/components/lib/label-with-icon/LabelWithIcon'
import { SectionHeader } from './SectionHeader'
import { OxomiAttachment } from '@obeta/data/lib/hooks/useOxomiData'
import { Link } from '@obeta/components/lib/link/Link'

interface IDownloadColumns {
  typeName: string
  filename: string
  url: string
}

interface IDownloadData extends IDownloadColumns {
  filesize: string
}

const MAX_ITEMS_TO_SHOW = 7

interface IArticleDownloads {
  article: IArticle | undefined
}

interface IArticleDownloadsBase {
  oxomiAttachments: OxomiAttachment[]
  withLinkToDownloads?: boolean
}

export const DownloadsBase: React.FC<IArticleDownloadsBase> = (props) => {
  const { oxomiAttachments, children, withLinkToDownloads } = props
  const { t } = useTranslation()

  const columns = useMemo<IColumn<keyof IDownloadColumns, IDownloadData>[]>(() => {
    return [
      {
        accessor: 'typeName',
        format: (name: string) => {
          return <LabelWithIcon icon={<TextSnippetIcon />} label={name} />
        },
        flex: 18,
      },
      {
        accessor: 'filename',
        format: (fileName: string, data) => {
          return (
            <Typography noWrap variant="body">
              {fileName} • {data['filesize']}
            </Typography>
          )
        },
        flex: 78,
        minWidth: 0,
      },
      {
        accessor: 'url',
        alignment: 'right',
        format: (downloadUrl) => {
          return (
            <MuiLink className={styles.link} target="_blank" href={downloadUrl}>
              <DownloadIcon className={styles.icon} />
            </MuiLink>
          )
        },
        flex: 'none',
        padding: 'none',
      },
    ]
  }, [])

  return (
    <SectionWrapper
      available={oxomiAttachments.length > 0}
      id={ArticleDetailsSections.Downloads}
      className={styles.downloads}
    >
      <div className={styles.headerWrapper}>
        <SectionHeader>{t('ARTICLE_DETAIL.DOWNLOADS')}</SectionHeader>
        {withLinkToDownloads && (
          <Link>{t<string>('ARTICLE_DETAIL.TO_DOWNLOADS_IN_SHOP', { shopName: 'Obeta' })}</Link>
        )}
      </div>
      <StripedTable className={styles.table} columns={columns} data={oxomiAttachments} />
      {children}
    </SectionWrapper>
  )
}

export const ArticleDownloads: React.FC<IArticleDownloads> = (props) => {
  const { article } = props
  const [showMore, setShowMore] = useState(false)
  const { oxomiAttachments } = useArticleDetailsProductMedia(
    { attachments: true },
    article?.oxomiId,
    article?.supplierId
  )

  let data = oxomiAttachments
  if (!showMore) {
    data = oxomiAttachments.slice(0, MAX_ITEMS_TO_SHOW)
  }

  return (
    <DownloadsBase oxomiAttachments={data}>
      <ToggleMore
        canToggle={oxomiAttachments.length > MAX_ITEMS_TO_SHOW}
        showMore={showMore}
        toggleMore={() => {
          setShowMore((baseState) => !baseState)
        }}
      />
    </DownloadsBase>
  )
}
