import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const IncompleteCheckout: FC = () => {
  const { t } = useTranslation()
  return (
    <div style={{ height: '50vh', display: 'flex', justifyContent: 'center' }}>
      <div style={{ marginTop: '15rem' }}>
        <Typography variant="headline3Bold">{t('INFO.ORDER_ALREADY_COMPLETED')}</Typography>
      </div>
    </div>
  )
}
