import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { TabFilters } from '@obeta/components/lib/tab-filters/TabFilters'
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import {
  ALL_BRANDS_OPTION_KEY,
  AllTeasersFilter,
  formatBrandFilterLabel,
  TeasersCategory,
  useAdvertisingProvider,
} from '../../hooks/useAdvertaisingProvider'
import { MobileFilters } from '../MobileFilters/MobileFilters'
import styles from './ControlSection.module.scss'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'

// UI
import { Dropdown2 } from '@obeta/components/lib/dropdown2/Dropdown2'

export const MATH_FILTER_VALUE_TO_LABEL = {
  [TeasersCategory.All]: 'TEASERS.ALL_TEASERS_TAB',
  [TeasersCategory.MegaDeal]: 'TEASERS.MEGA_DEAL_TAB',
  [TeasersCategory.WeekendDeal]: 'TEASERS.WEEKEND_DEAL_TAB',
  [TeasersCategory.RedArticle]: 'TEASERS.RED_ARTIKEL_TAB',
  [TeasersCategory.ActionWeek]: 'TEASERS.PROMOTION_WEEK_TAB',
}

export const ControlSection = () => {
  const { t } = useTranslation()
  const { filters, updateFilters, isLoading, assignedBrands, teasersTotals } =
    useAdvertisingProvider()
  const { mobile, tablet } = useBreakpoints()
  const [showMobileFilters, setShowMobileFilters] = useState(false)

  const filterTabsOptions = [
    {
      label: t(MATH_FILTER_VALUE_TO_LABEL[TeasersCategory.All]),
      value: TeasersCategory.All,
      disabled: teasersTotals.All === 0,
      total: teasersTotals.All,
    },
    {
      label: t(MATH_FILTER_VALUE_TO_LABEL[TeasersCategory.MegaDeal]),
      value: TeasersCategory.MegaDeal,
      disabled: teasersTotals.MegaDeal === 0,
      total: teasersTotals.MegaDeal,
    },
    {
      label: t(MATH_FILTER_VALUE_TO_LABEL[TeasersCategory.WeekendDeal]),
      value: TeasersCategory.WeekendDeal,
      disabled: teasersTotals.WeekendDeal === 0,
      total: teasersTotals.WeekendDeal,
    },
    {
      label: t(MATH_FILTER_VALUE_TO_LABEL[TeasersCategory.RedArticle]),
      value: TeasersCategory.RedArticle,
      disabled: teasersTotals.RedArticle === 0,
      total: teasersTotals.RedArticle,
    },
    {
      label: t(MATH_FILTER_VALUE_TO_LABEL[TeasersCategory.ActionWeek]),
      value: TeasersCategory.ActionWeek,
      disabled: teasersTotals.ActionWeek === 0,
      total: teasersTotals.ActionWeek,
    },
  ]

  const openMobileFilters = () => setShowMobileFilters(true)

  /**
   * @deprecated
   */
  const filterByBrandOptsDeprecated = useMemo(() => {
    const brands = [
      {
        label: t('TEASERS.ALL_BRANDS'),
        value: ALL_BRANDS_OPTION_KEY,
        id: ALL_BRANDS_OPTION_KEY,
      },
    ]

    const uniqByFilterNameBrands = assignedBrands.filter(
      (brand, index, arr) =>
        index === arr.findIndex((otherBrand) => otherBrand.filterName === brand.filterName)
    )

    return uniqByFilterNameBrands?.length
      ? [
          ...brands,
          ...uniqByFilterNameBrands.map((brand) => ({
            value: brand.id,
            label: formatBrandFilterLabel(brand.filterName),
          })),
        ]
      : brands
  }, [assignedBrands, t])

  const filterByBrandsOpts: DropdownOption[] = useMemo(() => {
    const brands: DropdownOption[] = [
      {
        label: t('TEASERS.ALL_BRANDS'),
        value: ALL_BRANDS_OPTION_KEY,
      },
    ]

    const uniqByFilterNameBrands = assignedBrands.filter(
      (brand, index, arr) =>
        index === arr.findIndex((otherBrand) => otherBrand.filterName === brand.filterName)
    )

    return uniqByFilterNameBrands?.length
      ? [
          ...brands,
          ...uniqByFilterNameBrands.map((brand) => ({
            label: formatBrandFilterLabel(brand.filterName),
            value: brand.id,
          })),
        ]
      : brands
  }, [assignedBrands, t])

  return (
    <div className={styles.controlWrapper}>
      <div className={styles.filtersWrapper}>
        {!mobile && (
          <div className={styles.tabFiltersWrapper}>
            <TabFilters<string>
              options={filterTabsOptions}
              onChangeTab={(value) =>
                updateFilters({
                  [AllTeasersFilter.Category]: value,
                })
              }
              activeTab={filters.category || TeasersCategory.All}
            />
          </div>
        )}
        {mobile || tablet ? (
          <div>
            <IconButton onClick={openMobileFilters} className={styles.filterBtn}>
              <FilterAltIcon />
              {mobile && <Typography variant="bodyBold">{t('TEASERS.FILTER')}</Typography>}
            </IconButton>
            <MobileFilters
              groupOptions={filterTabsOptions}
              brandOptions={filterByBrandOptsDeprecated}
              filters={filters}
              open={showMobileFilters}
              updateFilters={updateFilters}
              isLoading={isLoading}
              onClose={() => setShowMobileFilters(false)}
            />
          </div>
        ) : (
          <Dropdown2
            label={t('TEASERS.FILTER_BY_BRAND_LABEL')}
            options={filterByBrandsOpts}
            value={filters.brand}
            onChange={(value: string) => updateFilters({ [AllTeasersFilter.Brand]: value })}
          />
        )}
      </div>
    </div>
  )
}
