import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Chip } from '@obeta/components/lib/chip/Chip'
import styles from './ActiveFilters.module.scss'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { useFiltersStateContext } from './filters/hooks/useFiltersReducer'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'

export const ActiveFilters: React.FC<WithBreakpoints<'tablet'>> = (props) => {
  const { t } = useTranslation()
  const { activeFilters: filters } = useFiltersStateContext()
  const { searchParams } = useArticlesSearchProvider()
  const changeSearchParams = useChangeSearchParams()
  const productFilter = searchParams?.productDehaIds

  if (!filters.length && !productFilter) {
    return null
  }

  return (
    <div className={styles.root}>
      <Typography noWrap variant="body" className={styles.label}>
        {props.tablet
          ? t<string>('SEARCH.ACTIVE_FILTERS_COUNT', { count: filters.length })
          : t<string>('SEARCH.ACTIVE_FILTERS')}
        :
      </Typography>
      <div className={styles.filters}>
        {productFilter && (
          <Chip
            title={
              searchParams?.productDehaIdsFilterLabel ?? t('SEARCH.ACTIVE_PRODUCT_DEHA_ID_FILTER')
            }
            label={
              searchParams?.productDehaIdsFilterLabel ?? t('SEARCH.ACTIVE_PRODUCT_DEHA_ID_FILTER')
            }
            className={styles.chip}
            key="product-filter"
            onDelete={() =>
              changeSearchParams({
                searchParams: { ...searchParams, productDehaIds: undefined },
                route: 'replace',
              })
            }
          />
        )}
        {filters.map((f) => {
          return (
            <Chip
              title={f.value}
              className={styles.chip}
              key={f.id}
              label={f.value}
              onDelete={f.remove}
            />
          )
        })}
      </div>
    </div>
  )
}
