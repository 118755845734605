import { FC } from 'react'
import { Tooltip } from '@mui/material'
import { ReactComponent as TruckIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { ShopLink } from '@obeta/components/lib/link/ShopLink'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'
import { useTranslation } from 'react-i18next'

type Props = {
  item: OpenPosition
}
export const OrderDetailsIconLink: FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const isNoLkwIconItem = (item: { receipt: string; receiptType: string }): boolean => {
    return (
      item.receipt === 'Verzugspauschale' ||
      item.receipt === 'Mahngebühr' ||
      item.receiptType !== 'Invoice'
    )
  }

  if (isNoLkwIconItem(item)) return null
  return (
    <ShopLink href={`${ShopRoutes.OrdersList}/?dateFrom=2000-01-01&searchTerm=${item.receipt}`}>
      <Tooltip title={t('MY_ACCOUNTING.GO_TO_ORDER_DETAILS')}>
        <TruckIcon />
      </Tooltip>
    </ShopLink>
  )
}
