import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { printCart } from '@obeta/utils/lib/printPdf'
import { useUserDataV2 } from './useUserDataV2'
import { useAppDomain } from './useAppDomain'
import { useSessionCartName } from './useSessionCartName'

export const useOnPrintCart = () => {
  const { tokens } = useUserDataV2()
  const domain = useAppDomain()
  const { t } = useTranslation()
  const { getSessionCartName, isSessionCart } = useSessionCartName()

  const onPrintCart = useCallback(
    (cart?: ShoppingCartV2) => {
      if (cart) {
        const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
        const notificationData = {
          id: `${t('PRINT.SHOPPING_CART_EVENT_ID')}-${cartName}`,
          heading: t('PRINT.SHOPPING_CART_PDF_HEADING'),
          body: t('PRINT.SHOPPING_CART_PDF_BODY', { cartName }),
        }
        printCart({
          cartId: cart.id,
          accessToken: tokens?.accessToken,
          cartName: cartName,
          domain,
          notificationData,
        })
      }
    },
    [domain, getSessionCartName, isSessionCart, t, tokens?.accessToken]
  )

  return onPrintCart
}
