import React from 'react'
import { Typography } from '@mui/material'
import { useGlobalPromotionTerms } from './hooks/useGlobalPromotionTerms'
import styles from './promotionTerms.module.scss'

export const PromotionTerms: React.FC = () => {
  const terms = useGlobalPromotionTerms()

  if (!terms) return null

  return (
    <div className={styles['terms-wrapper']}>
      <Typography variant="body" className={styles['terms-text']}>
        {terms}
      </Typography>
    </div>
  )
}
