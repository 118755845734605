import { useEffect, useRef, useState } from 'react'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { gql, useApolloClient } from '@apollo/client'
import { StoreV2 } from '@obeta/models/lib/models'

const locationNewsQuery = gql`
  query LocationNewsCollection(
    $filters: LocationNewsFiltersInput
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    locationNewsCollection(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          title
          imageEntry {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          text
          relatedStoreInfo {
            id
            company
            country
            region
            phoneNumber
            faxNumber
            mail
            latitude
            longitude
            openingHours {
              weekday
              from
              to
            }
            updatedAt
            address {
              name1
              name2
              name3
              name4
              street
              houseNumber
              zipCode
              city
              country
            }
          }
        }
      }
    }
  }
`

export interface ILocationNews {
  id: string
  text: string
  title: string
  imageUrl: string
  imageAlt: string
  relatedStoreInfo?: StoreV2
}

const transformLocationNewsResponse = (data) => {
  return data.map((data) => {
    const locationNewsItem = {
      id: data.id,
      ...data.attributes,
      imageUrl: data.attributes.imageEntry.data?.attributes.url,
      imageAlt: data.attributes.imageEntry.data?.attributes.alternativeText,
    }

    delete locationNewsItem.__typename
    delete locationNewsItem.imageEntry

    return locationNewsItem
  })
}

export const useLocationNews = () => {
  const [locationNews, setLocationNews] = useState<ILocationNews[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const apolloClient = useStrapiGql()
  const client = useApolloClient(apolloClient)
  const currentDate = new Date()

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getLocationNews = async () => {
      if (!isMounted.current) return

      setLoading(true)

      try {
        const response = await client.query({
          query: locationNewsQuery,
          variables: {
            sort: ['updatedAt:desc'],
            filters: {
              endDate: { gte: currentDate },
              startDate: { lte: currentDate },
            },
            pagination: {
              start: 0,
              limit: 3,
            },
          },
        })

        const result = transformLocationNewsResponse(response.data.locationNewsCollection.data)

        setLocationNews(result)

        setLoading(false)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }
        setError(err)
        setLoading(false)
      }
    }

    getLocationNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { locationNews, loading, error }
}
