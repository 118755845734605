import clsx from 'clsx'
import React, { useRef } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, Stack } from '@mui/material'
import styles from './ArticleClasses.module.scss'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { Collapse } from '@obeta/components/lib/collapse/Collapse'

interface IArticleClassesProps {
  header: React.ReactChild
  itemsLength: number
}

const maxRowsVisible = parseInt(styles.maxRowsVisible)
const itemsGap = parseInt(styles.itemsGap)
const desktopEtimItemHeight = parseInt(styles.desktopEtimItemHeight)

export const ArticleClassesShort: React.FC<IArticleClassesProps> = ({ children, header }) => {
  const wrapRef = useRef<HTMLDivElement>(null)

  let colsPerRow = 2

  const { desktop } = useBreakpoints()
  if (desktop) {
    colsPerRow = 3
  }

  const itemsGapPx = useRemToPx(itemsGap)
  const desktopEtimItemHeightPx = useRemToPx(desktopEtimItemHeight)

  const maxPossibleHeight =
    maxRowsVisible * desktopEtimItemHeightPx + itemsGapPx * maxRowsVisible - itemsGapPx

  return (
    <Collapse header={header}>
      <Stack direction={'column'} alignItems={'center'} gap="1rem">
        <Box
          ref={wrapRef}
          gridTemplateColumns={`repeat(${colsPerRow}, minmax(0, 1fr))`}
          maxHeight={maxPossibleHeight}
          className={clsx(styles.articleClasses)}
        >
          {children}
        </Box>
      </Stack>
    </Collapse>
  )
}
