import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/system'
import { Box, Button, ButtonBase, Dialog, Stack, SvgIcon, Typography } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import { TypographyLineClamp } from '@obeta/components/lib/typography'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ILastMonthAds } from '@obeta/models/lib/models/LastMonthAds/LastMonthAds'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as BackArrow } from 'assets/icon/designsystem/arrow_back.svg'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import styles from './MonthAdvertisementBlock.module.scss'
import generateShortUUID from 'short-uuid'
import { waitForField, waitForResolve } from '@obeta/utils/lib/waitFor'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'

const isMoreThatNLine = (el: Element, lineCount: number) => {
  const lineHeight = parseFloat(getComputedStyle(el).lineHeight)
  return Math.ceil(el.clientHeight / lineHeight) >= lineCount
}

export const MonthAdvertisementBlock: FC<ILastMonthAds> = ({
  title,
  text,
  oxomiCatalogId,
  activeMonth,
}) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const [canToggle, setCanToggle] = useState(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const { mobile, desktop } = useBreakpoints()
  const theme = useTheme()
  const [oxomiTargetId] = useState(generateShortUUID.generate)

  useOxomiIntegration({ documentId: String(oxomiCatalogId) })

  useLayoutEffect(() => {
    const el = descriptionRef.current
    if (!el) {
      return
    }
    setCanToggle(isMoreThatNLine(el, 5))
  }, [])

  useEffect(() => {
    const embedImageFromDocId = async () => {
      if (oxomiCatalogId) {
        const oxomi = await waitForField<OxomiGlobal>({ field: 'oxomi' })
        await waitForResolve({ callback: oxomi.isInitialized })

        window.oxomi.embedCatalog({
          target: `#${oxomiTargetId}`,
          catalog: oxomiCatalogId,
          showDetails: false,
        })
      }
    }

    embedImageFromDocId()
  }, [oxomiCatalogId, oxomiTargetId])

  useResizeObserver(descriptionRef, (entry) => {
    const target = entry.target
    setCanToggle(isMoreThatNLine(target, 5))
  })

  const downloadOxomi = () => {
    window.oxomi.downloadCatalog({ catalog: oxomiCatalogId })
  }

  const openOxomi = () => window.oxomi.openCatalog({ catalog: oxomiCatalogId })

  return (
    <div className={styles.wrapper}>
      <div id={oxomiTargetId} className={styles.imageSection}></div>
      <div className={styles.contentSection}>
        <div className={styles.textSection}>
          <Typography variant={desktop ? 'subTitle' : 'overline'} className={styles.publishedAt}>
            {activeMonth}
          </Typography>
          <Typography variant={desktop ? 'headline4Bold' : 'boldText'}>{title}</Typography>
          <TypographyLineClamp
            lines={5}
            variant="body"
            className={styles.teaser}
            ref={descriptionRef}
          >
            {text}
          </TypographyLineClamp>
          {mobile && canToggle && (
            <ButtonBase onClick={() => setShowMore(!showMore)}>
              <Stack width={'100%'} direction={'row'} justifyContent="center" spacing={0.5}>
                <ArrowDown />
                <Typography variant="bodyBold">{t('TEASERS.SHOW_MORE')}</Typography>
              </Stack>
            </ButtonBase>
          )}
          {mobile && (
            <Dialog open={showMore} onClose={() => setShowMore(false)} fullScreen>
              <Stack
                padding="calc(1.5rem + var(--obeta-safe-area-top-root)) 1.5rem 1.5rem 1.5rem"
                gap="1rem"
              >
                <Stack
                  direction={'row'}
                  justifyContent={'flex-start'}
                  paddingBottom={'1rem'}
                  borderBottom={`1px solid ${theme.palette.grayVariant.light}`}
                >
                  <Box sx={{ position: 'absolute' }}>
                    <TertiaryButton
                      size="small"
                      onClick={() => setShowMore(false)}
                      leftIcon={<BackArrow />}
                    >
                      {t('TEASERS.DETAIL_ADVERTISEMENT_BACK')}
                    </TertiaryButton>
                  </Box>
                  <Typography flex="1" textAlign={'center'} variant="bodyBold">
                    {t('TEASERS.DETAIL_ADVERTISEMENT_DESCRIPTION')}
                  </Typography>
                </Stack>
                <Typography>{text}</Typography>
              </Stack>
            </Dialog>
          )}
        </div>
        <div className={styles.controlSection}>
          <Button
            className={styles.previewButton}
            variant={'contained'}
            fullWidth={false}
            onClick={openOxomi}
          >
            {t(desktop ? 'TEASERS.VIEW_BROCHURE_DESKTOP' : 'TEASERS.VIEW_BROCHURE')}
          </Button>
          {oxomiCatalogId && (
            <Button
              onClick={downloadOxomi}
              variant={'outlined'}
              className={styles.download}
              fullWidth={false}
              startIcon={<SvgIcon component={DownloadIcon} color="secondary" />}
            >
              {t('TEASERS.DOWNLOAD')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
