import { useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Subject } from 'rxjs'
import { filter, bufferTime, map } from 'rxjs/operators'
import { Message } from '@obeta/models/lib/models/Message'
import { useDbUtils } from '@obeta/data/lib/hooks/useDbUtils'

const updateNotifications = gql`
  mutation updateUserNotifications($since: String) {
    updateUserNotifications(since: $since)
  }
`

function isIntersecting(entry: IntersectionObserverEntry) {
  return entry.isIntersecting || entry.intersectionRatio > 0
}

export const useSetReadOnScroll = (messages: Message[]) => {
  const { getCollectionSync } = useDbUtils()
  const sync = getCollectionSync('message')
  const [update] = useMutation(updateNotifications, {
    onCompleted: () => {
      sync && sync.reSync()
    },
  })

  useEffect(() => {
    let unsubscribe: () => void
    const doUpdate = async () => {
      const container = document.body

      const options = {
        root: container,
        rootMargin: '20px',
        threshold: 1,
      }

      const subject$ = new Subject<string>()

      const intersectionObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (isIntersecting(entry)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            subject$.next((entry.target as HTMLElement).dataset.since!)
          }
        })
      }, options)

      let highestSince = new Date('0')
      subject$
        .pipe(
          bufferTime(2000),
          map((since) => {
            let currentHighest = new Date(0)
            let sinceToTake = ''
            since.forEach((snc) => {
              const dSince = new Date(snc)
              if (dSince > currentHighest) {
                currentHighest = dSince
                sinceToTake = snc
              }
            })
            return sinceToTake
          }),
          filter((since) => {
            const dSince = new Date(since)
            if (dSince > highestSince) {
              highestSince = dSince
              return true
            }
            return false
          })
        )
        .subscribe((since) => {
          update({
            variables: {
              since,
            },
          })
        })

      const children = document.querySelectorAll('[data-name="message-card"]')
      for (let i = 0; i < children.length; i += 1) {
        const child = children[i]
        intersectionObserver.observe(child)
      }

      unsubscribe = () => {
        intersectionObserver.disconnect()
        subject$.unsubscribe()
      }
    }

    doUpdate()
    return () => {
      unsubscribe && unsubscribe()
    }
  }, [update, messages, getCollectionSync])
}
