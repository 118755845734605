import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { DatasheetFormSection } from '../datasheet-form-section/DatasheetFormSection'
import Box from '@mui/material/Box'
import { OfferPriceOptions } from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'
import { InputNumber } from '../../../input-number/InputNumber'
import styles from './PrintOptionIndividualFormFixedPrice.module.scss'

export const PrintOptionIndividualFormFixedPrice = () => {
  const { t } = useTranslation()

  const { control, setValue } = useFormContext()

  return (
    <form data-testid="individual-config-form-fixed-price">
      <div className={styles.wrapper}>
        <DatasheetFormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.OFFER_PRICE')}>
          <Box className={styles.offerPriceSection}>
            <Box className={styles.itemBox}>
              <Controller
                name="offerPrice.option"
                control={control}
                render={() => {
                  return (
                    <Controller
                      name="offerPrice.fixedPriceValue"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputNumber
                            onFocus={() => {
                              setValue('offerPrice.option', OfferPriceOptions.Fixed)
                            }}
                            value={value}
                            placeholder={t('INDIVIDUAL_DATASHEET.FORM_SECTION.SET_IN_EURO')}
                            InputProps={{
                              classes: { input: styles.input, root: styles.inputRoot },
                            }}
                            onChange={(inputValue) => {
                              if (inputValue === '') setValue('offerPrice.option', '')
                              onChange(inputValue)
                            }}
                          />
                        )
                      }}
                    />
                  )
                }}
              />
            </Box>
          </Box>
        </DatasheetFormSection>
      </div>
    </form>
  )
}
