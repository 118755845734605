import { SvgIcon } from '@mui/material'
import { ReactComponent as KeyboardArrowDownIcon } from '@obeta/assets/icon/designsystem/keyboard_arrow_down.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import styles from './ToggleMore.module.scss'
import { TertiaryButton } from '../custom-button/CustomButton'

interface IToggleMoreProps {
  showMore: boolean
  toggleMore: () => void
  canToggle: boolean
  className?: string
  position?: 'start' | 'center'
}

export const ToggleMore: React.FC<IToggleMoreProps> = (props) => {
  const { showMore, toggleMore, canToggle, position = 'center' } = props
  const { t } = useTranslation()

  if (!canToggle) {
    return null
  }

  return (
    <div
      className={clsx(styles.btnWrapper, {
        [styles.start]: position === 'start',
        [styles.center]: position === 'center',
      })}
    >
      <TertiaryButton
        size="small"
        onClick={toggleMore}
        leftIcon={
          <SvgIcon
            className={clsx({ [styles.showLessIcon]: showMore })}
            component={KeyboardArrowDownIcon}
          />
        }
      >
        {t<string>(showMore ? 'MAIN.SHOW_LESS' : 'MAIN.SHOW_MORE')}
      </TertiaryButton>
    </div>
  )
}
