import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ClickAwayListener, TextField, Toolbar, Typography } from '@mui/material'
import clsx from 'clsx'
import { modifyOrderIdForDisplay } from '@obeta/utils/lib/orders-helpers'

// Styles
import styles from './OrderDetailsRenameField.module.scss'

// UI
import { FormHelperText } from '../form-helper-text/FormHelperText'
import { useActionNotification } from '@obeta/data/lib/hooks'
import {
  OrderActionTypes,
  UpdateOrderMetaDataGraphQLResultAction,
} from '@obeta/data/lib/actions/order-actions'

type Props = {
  isRenaming: boolean
  orderName: string
  orderId: string
  orderItemCount: number
  onClose: () => void
  onRenameOrder: (newName: string) => void
}

export const OrderDetailsRenameField: FC<Props> = (props) => {
  const { isRenaming, orderId, orderName, orderItemCount, onClose, onRenameOrder } = props
  const { t } = useTranslation()

  // Refs
  const inputRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement>(null)

  const waitForUpdateOrderMetaDataAction = useActionNotification(
    OrderActionTypes.UpdateOrderMetaDataGraphQLResult
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: orderName,
    },
  })

  // Focus input element
  useEffect(() => {
    inputRef.current && isRenaming && inputRef.current.focus()
  }, [inputRef, isRenaming])

  /**
   * Handler to change cart template name.
   */
  const onSubmitNewOrderName = () => {
    const newName = textFieldRef.current?.value
    onRenameOrder(newName ?? '')

    waitForUpdateOrderMetaDataAction((action: UpdateOrderMetaDataGraphQLResultAction) => {
      if (action.success) {
        // Render notification of some sort
        onClose()
      }
    })
  }

  const modifiedOrderIdForDisplay = modifyOrderIdForDisplay(orderId)

  return (
    <Toolbar variant="dense" className={styles.toolbar}>
      {!isRenaming && (
        <Typography variant="headline3Bold">
          {orderName !== ''
            ? `${orderName} - ${modifiedOrderIdForDisplay} (${orderItemCount})`
            : `${modifiedOrderIdForDisplay} (${orderItemCount})`}
        </Typography>
      )}
      {isRenaming && (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
          <form aria-label="form" className="w-96" onSubmit={handleSubmit(onSubmitNewOrderName)}>
            <Controller
              control={control}
              defaultValue={orderName}
              rules={{
                required: true,
                maxLength: 20,
              }}
              name="name"
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <TextField
                    id="name"
                    autoFocus={true}
                    className={clsx(
                      styles.textFieldInput,
                      errors.name && styles.textFieldInputError
                    )}
                    defaultValue={orderName}
                    inputProps={{
                      ref: textFieldRef,
                      id: 'name-input',
                    }}
                    inputRef={inputRef}
                    placeholder={t('ORDERS.RENAME.PLACEHOLDER')}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                  {errors.name && (
                    <FormHelperText
                      errorText={
                        errors.name.type === 'maxLength'
                          ? t('TEMPLATES.TEMPLATE_NAME_ERROR_MAX_LENGTH')
                          : ''
                      }
                    />
                  )}
                </>
              )}
            />
          </form>
        </ClickAwayListener>
      )}
    </Toolbar>
  )
}
