import { gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import {
  DispatchConstructionDocumentationPdfQuery,
  DispatchConstructionDocumentationPdfQueryVariables,
  OrdersInput,
} from '@obeta/schema'

export const dispatchConstructionDocumentationPdfQuery = gql`
  query dispatchConstructionDocumentationPdf($input: OrdersInput!) {
    dispatchConstructionDocumentationPdf(input: $input)
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.SmallConstructionDocumentationPDFPending,
    id: 'small-construction-documentation-pending',
    options: {
      message,
    },
  })
}

export const useDispatchConstructionDocumentation = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const dispatchConstructionDocumentationPdf = useCallback(
    async (input: OrdersInput) => {
      showPendingMessage(t('MY_ACCOUNTING.PENDING_COPIES'))

      await client.query<
        DispatchConstructionDocumentationPdfQuery,
        DispatchConstructionDocumentationPdfQueryVariables
      >({
        query: dispatchConstructionDocumentationPdfQuery,
        variables: { input: input },
      })
    },
    [client, t]
  )

  return { dispatchConstructionDocumentationPdf }
}
