import {
  IIndividualConfig,
  DatasheetConfig,
  DatsheetPrintOptions,
} from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'

export const createParamsFromFormData = (data: DatasheetConfig) => {
  const params = new URLSearchParams()

  params.append('type', data.type)

  if (data.type === DatsheetPrintOptions.INDIVIDUAL) {
    if (data.showPricesWithVAT) params.append('showPricesWithVAT', data.showPricesWithVAT)
    addOfferPriceParams(params, data.offerPrice)
    addPriceTypeParams(params, data.priceType)
  }

  return `?${params.toString()}`
}

const addOfferPriceParams = (
  params: URLSearchParams,
  offerPrice?: IIndividualConfig['offerPrice']
) => {
  if (!offerPrice) return

  if (offerPrice.option) {
    params.append('offerPriceOption', offerPrice.option)
  }

  // value can be either empty string or number
  if (typeof offerPrice.fixedPriceValue === 'number') {
    params.append('fixedPriceValue', String(offerPrice.fixedPriceValue))
  }

  if (typeof offerPrice.priceMarkup === 'number') {
    params.append('priceMarkup', String(offerPrice.priceMarkup))
  }

  if (offerPrice.percentagePriceBaseValue) {
    params.append('percentagePriceBaseValue', offerPrice.percentagePriceBaseValue)
  }
}

const addPriceTypeParams = (
  params: URLSearchParams,
  priceType?: IIndividualConfig['priceType']
) => {
  if (!priceType) return
  if (priceType.listPrice) params.append('listPrice', String(priceType.listPrice))
  if (priceType.catalogPrice) params.append('catalogPrice', String(priceType.catalogPrice))
  if (priceType.metalSurcharge) params.append('metalSurcharge', String(priceType.metalSurcharge))
}
