import { gql, useQuery } from '@apollo/client'
import { ArticleSearchArea, ArticleSearchParams, Facet } from '@obeta/models/lib/models/Search'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { useWarehouseContext } from '@obeta/data/lib/stores/useWarehouseContext'
import { getFacetByWarehouseId } from '@obeta/utils/lib/getFacetByWarehouseId'

const AVAILABILITY_QUERY = gql`
  query GetAvailability($searchString: String, $facets: [String!], $filter: String) {
    searchProducts(
      searchString: $searchString
      requestOptions: { facets: $facets, filters: $filter }
    ) {
      facets {
        values {
          value
          count
        }
      }
    }
  }
`

export const useAvailability = (props: { searchParams?: ArticleSearchParams }) => {
  const { warehouseId } = useWarehouseContext()
  const s: ArticleSearchParams = {
    ...props.searchParams,
    articleSearchArea: undefined,
  }
  const filters = buildFilterParams(s, warehouseId)

  const { data, loading } = useQuery<{ searchProducts: { facets: Facet[] } }>(AVAILABILITY_QUERY, {
    variables: {
      facets: [getFacetByWarehouseId(warehouseId)],
      filter: filters || '',
      searchString: s.searchString || '',
    },
  })

  const facets = data?.searchProducts?.facets || []

  const allHints = facets?.[0]?.values.reduce((prevVal, val) => prevVal + val.count, 0)
  const immediatelyAvailHints = facets?.[0]?.values.find((el) => el.value === 'true')?.count || 0

  return {
    availability: {
      [ArticleSearchArea.ALL]: allHints,
      [ArticleSearchArea.IMMEDIATELY_AVAILABLE]: immediatelyAvailHints,
    },
    loading,
  }
}
