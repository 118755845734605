/* eslint-disable @typescript-eslint/no-explicit-any */
type Maybe<T> = T | null

/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: any
  DateTime: any
  Time: string
  Date: any
  Upload: any
  ObetaTeaserNewsTypeDynamicZoneInput: any
}

type UploadFileEntityResponse = {
  data?: Maybe<UploadFileEntity>
}

type UploadFileEntity = {
  id?: Maybe<Scalars['ID']>
  attributes?: Maybe<UploadFile>
}

type UploadFile = {
  name: Scalars['String']
  alternativeText?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  formats?: Maybe<Scalars['JSON']>
  hash: Scalars['String']
  ext?: Maybe<Scalars['String']>
  mime: Scalars['String']
  size: Scalars['Float']
  url: Scalars['String']
  previewUrl?: Maybe<Scalars['String']>
  provider: Scalars['String']
  provider_metadata?: Maybe<Scalars['JSON']>
  related?: any
}

enum Enum_Componentrentalaccessoriesrentalaccesories_Rental_Currency {
  Eur = 'EUR',
}

enum Enum_Componentrentalaccessoriesrentalaccesories_Rental_Duration {
  Week = 'week',
  Day = 'day',
}

export type IRentArticleAccessories = {
  id: Scalars['ID']
  description: Scalars['String']
  rental_price: Scalars['Float']
  rental_currency: Enum_Componentrentalaccessoriesrentalaccesories_Rental_Currency
  rental_duration: Enum_Componentrentalaccessoriesrentalaccesories_Rental_Duration
}

type ComponentRentalAccessoriesRentalLocationAddress = {
  id: Scalars['ID']
  name1: Scalars['String']
  name2?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  houseNumber?: Maybe<Scalars['String']>
}

export type ILocationOpeningHours = {
  id?: Scalars['ID']
  weekday?: Maybe<Scalars['Int']>
  from: Scalars['Time']
  to: Scalars['Time']
}

export enum ERentalCurrency {
  Eur = 'EUR',
}

export enum ERentalDuration {
  Week = 'week',
  Day = 'day',
}

type ObetaRentArticle = {
  title: Scalars['String']
  sapId: Scalars['String']
  dehaId: Scalars['String']
  media: UploadFileEntityResponse
  description: Scalars['String']
  rental_price: Scalars['Float']
  rental_currency: ERentalCurrency
  rental_duration: ERentalDuration
  rental_category: ObetaRentCategoryEntityResponse
  rental_locations: ObetaRentLocations
  accessories: Array<IRentArticleAccessories>
}

export type ObetaRentArticleEntity = {
  id: Scalars['ID']
  attributes: ObetaRentArticle
}

type ObetaRentCategory = {
  name: Scalars['String']
}

type ObetaRentCategoryEntity = {
  id?: Maybe<Scalars['ID']>
  attributes: ObetaRentCategory
}

type ObetaRentCategoryEntityResponse = {
  data: ObetaRentCategoryEntity
}

type ObetaRentLocation = {
  name: Scalars['String']
  mail: Scalars['String']
  phoneNumber: Scalars['String']
  obetaStoreId?: Scalars['String']
  address: ComponentRentalAccessoriesRentalLocationAddress
  openingHours: Array<ILocationOpeningHours>
  isDefaultStore?: boolean //Not comes from strapi, but is derived later
}

export type ObetaRentLocationEntity = {
  id?: Maybe<Scalars['ID']>
  attributes: ObetaRentLocation
}

export type ObetaRentLocations = {
  data: Array<ObetaRentLocationEntity>
}

export type ObetaRentArticleResponse = {
  obetaRentArticles: {
    data: Array<ObetaRentArticleEntity>
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
