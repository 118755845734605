import { Typography } from '@mui/material'
import { normalizeInt } from '@obeta/utils/lib/data-formatter/normalizeAmount'
import React from 'react'
import { E2EProps } from '@obeta/components/lib/types'

export const Size: React.FC<
  {
    size: number | undefined | null
    className?: string
    typographyVariant?: React.ComponentProps<typeof Typography>['variant']
  } & E2EProps
> = (props) => {
  const { typographyVariant = 'body', datatestid } = props
  if (!props.size) {
    return null
  }

  return (
    <Typography
      className={props.className}
      variant={typographyVariant}
      {...(datatestid && { 'data-testid': 'suppliers-item-count' })}
    >
      ({normalizeInt(props.size)})
    </Typography>
  )
}
