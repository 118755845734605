/**
 * Parses frontend filter object data to backend filter string.
 * @param isActive Offer item active state
 * @param supplierId Offer item supplier id
 * @returns Parsed offer items filter string
 */
export const parseSearchOfferItemsFilters = (
  isActive: boolean,
  supplierId: string | null
): string => {
  const filters: string[] = []
  if (isActive) {
    filters.push('notCancelled')
  }
  if (supplierId && supplierId.length > 0) {
    filters.push(`supplierId: ${supplierId}`)
  }

  return filters.join()
}
