import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'

export const PageContent = (props) => {
  const { ...rest } = props

  const {
    dimensions: { height: headerHeight },
  } = useHeaderDimensions()

  const keyboardHeight = useKeyboardHeight(true)

  return (
    <div
      {...rest}
      style={{
        paddingTop: `calc(${headerHeight}px + var(--obeta-safe-area-top-root))`,
        paddingBottom:
          keyboardHeight > 0
            ? keyboardHeight
            : `calc(4.125rem + var(--obeta-safe-area-bottom-root))`,
      }}
    >
      {props.children}
    </div>
  )
}
