import React from 'react'
import styles from './ContinueShopping.module.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { Button } from '@mui/material'

export const ContinueShopping: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={styles.continueButtonWrapper}>
      <Button
        type="submit"
        fullWidth={false}
        variant="contained"
        color="secondary"
        onClick={() => history.push(ShopRoutes.Root)}
        className={styles.continueButton}
      >
        {t('SHOPPING_CART.CHECKOUT.CONTINUE_SHOPPING')}
      </Button>
    </div>
  )
}
