import styles from './RaffleRegistered.module.scss'
import { ReactComponent as CheckmarkCircle } from '@obeta/assets/icon/checkmark-circle.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const RaffleRegistered: React.FC = () => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  return (
    <Box className={clsx(styles.registeredOuter, { [styles.compact]: mobile })}>
      <Stack alignItems={'center'} className={styles.registeredInner}>
        <CheckmarkCircle className={styles.checkmarkCircle} />
        <Typography className={styles.successText}>{t('RAFFLE.REGISTERED.HEADLINE')}</Typography>
        <Typography variant="bodyBold" className={styles.infoText}>
          {t('RAFFLE.REGISTERED.INFO')}
        </Typography>
      </Stack>
    </Box>
  )
}
