import React, { useContext } from 'react'
import CartTemplatesListPage from '@obeta/layouts/lib/cartTemplatesListPage/cartTemplatesListPage'
import { PleaseLoginScreen } from '../../common/components/please-login-screen/PleaseLoginScreen'
import { UserContext } from '@obeta/data/lib/hooks/useUserData'
import { useTranslation } from 'react-i18next'

export const CartTemplateList: React.FC = () => {
  const { t } = useTranslation()
  const { isLoggedIn } = useContext(UserContext)

  if (isLoggedIn) {
    return <CartTemplatesListPage />
  }

  return (
    <PleaseLoginScreen
      title={t('TEMPLATE_OVERVIEW.GUEST_TEXT_TITLE')}
      body={t('TEMPLATE_OVERVIEW.GUEST_TEXT_DESCRIPTION')}
    />
  )
}
