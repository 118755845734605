import { Typography } from '@mui/material'
import React from 'react'
import styles from './Message.module.scss'

export const Message: React.FC = (props) => {
  return (
    <Typography className={styles.message} component="p" variant="body">
      {props.children}
    </Typography>
  )
}
