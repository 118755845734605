import React from 'react'
import { ReactRouterProvider } from '@obeta/data/lib/hooks/useHistoryApi'
import { useLogoutRedirect } from '@obeta/data/lib/hooks/useLogoutRedirect'
import { GoBackOrExitAppProvider } from '@obeta/data/lib/hooks/useGoBackOrMinimize'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'

const LoginRedirect = (props) => {
  useLogoutRedirect('/')

  return props.children
}

export const RouterWrapper: React.FC = (props) => {
  const isMemoryRouter = typeof window === 'undefined'

  const content = (
    <ReactRouterProvider>
      <GoBackOrExitAppProvider>
        <LoginRedirect>{props.children}</LoginRedirect>
      </GoBackOrExitAppProvider>
    </ReactRouterProvider>
  )

  return isMemoryRouter ? (
    <MemoryRouter>{content}</MemoryRouter>
  ) : (
    <BrowserRouter>{content}</BrowserRouter>
  )
}
