import { Link as RLink, useMatch } from 'react-router-dom'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import styles from './Navigation.module.scss'

interface NavItem {
  path: string
  icon: JSX.Element
  title: string
}

export const NavLink = ({
  navItem,
  customClickHandler,
}: {
  navItem: NavItem
  customClickHandler?: () => void
}) => {
  const match = useMatch(navItem.path)

  return (
    <div
      className={clsx(styles.navBtn, {
        [styles.active]: match,
      })}
    >
      <RLink
        to={navItem.path}
        onClick={(e) => {
          if (customClickHandler) {
            e.preventDefault()
            customClickHandler?.()
          }
        }}
      >
        <div className={styles.content}>
          {navItem.icon}
          <Typography className={styles.title} variant="body">
            {navItem.title}
          </Typography>
        </div>
      </RLink>
    </div>
  )
}
