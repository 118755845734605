import { useProtectedRoutes } from '@obeta/data/lib/hooks/useNoAuthRedirect'

// Need locate this as separate component after UserV2Data context provider in the tree to keep context updated
export const AccessProtector = (props) => {
  const { children } = props

  // hook is used to protect some pages from access without authentication (redirect to '/' route)
  useProtectedRoutes('shoppingApp')

  return children
}
