import { datadogRum } from '@datadog/browser-rum'
import { ProviderConfig } from 'launchdarkly-react-client-sdk/src/types'

export const UNKNOWN_USER_CONTEXT = { kind: 'user', key: 'unknown_user' }

export const UNKNOWN_USER_KEY = 'unknown_user'

export const ldProviderConfig: ProviderConfig = {
  clientSideID: process.env.REACT_APP_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value)
        },
      },
    ],
  },
  context: UNKNOWN_USER_CONTEXT,
}
