import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { ActiveFilters } from './ActiveFilters'
import { ReactComponent as FilterIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { Typography } from '@mui/material'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { MobileSidebar } from './filters/facets/Sidebar'
import styles from './Filters.module.scss'
import { EtimSliderModal } from './filters/etims/EtimSlider'
import { EtimCards } from './filters/etims/EtimCards'
import { SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useFiltersStateContext } from './filters/hooks/useFiltersReducer'

type IFiltersProps = WithBreakpoints<'mobile' | 'tablet'>

const FilterButton: React.FC<{
  activeFiltersLabel: React.ReactChild | null
  onClick?: MouseEventHandler
}> = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SecondaryButton onClick={props.onClick} leftIcon={<FilterIcon />}>
        {t<string>('SEARCH.ARTICLELIST.FILTER.TITLE')} {props.activeFiltersLabel}
      </SecondaryButton>
    </div>
  )
}

const MobileFilters: React.FC = (props) => {
  const { t } = useTranslation()
  const mobileSidebarState = usePopoverState()
  const etimsState = usePopoverState()
  const { activeFilters } = useFiltersStateContext()

  const activeFiltersLabel =
    activeFilters.length > 0 ? (
      <Typography variant="body">
        ({activeFilters.length} {t<string>('MAIN.ACTIVE')})
      </Typography>
    ) : null

  return (
    <div className={styles.mobileRoot}>
      <div>
        <SecondaryButton onClick={etimsState.handleClick}>
          {t<string>('SEARCH.ARTICLE_CLASSES')}
        </SecondaryButton>
      </div>

      <FilterButton
        onClick={mobileSidebarState.handleClick}
        activeFiltersLabel={activeFiltersLabel}
      />
      <MobileSidebar {...mobileSidebarState} />
      <EtimSliderModal etimsPopoverState={etimsState} />
    </div>
  )
}

const TabletPortraitFilters: React.FC = (props) => {
  const mobileSidebarState = usePopoverState()

  return (
    <>
      <EtimCards />
      <div className={styles.tabletPortraitRoot}>
        <FilterButton onClick={mobileSidebarState.handleClick} activeFiltersLabel={null} />
        <ActiveFilters tablet={true} />
      </div>
      <MobileSidebar {...mobileSidebarState} />
    </>
  )
}

export const Filters: React.FC<IFiltersProps> = (props) => {
  const { mobile, tablet } = props

  if (mobile) {
    return <MobileFilters />
  }

  if (tablet) {
    return <TabletPortraitFilters />
  }

  return (
    <>
      <EtimCards />
      <ActiveFilters tablet={false} />
    </>
  )
}
