import { useLayoutEffect, useRef, useContext, FC } from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import styles from './StickyHeader.module.scss'
import { useSection } from './SectionProvider'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ArticleStickyHeaderContext } from './wrappers/ArticleStickyHeaderProvider'

export const StickyHeader: FC = () => {
  const { t } = useTranslation()
  const rootRef = useRef<HTMLDivElement>(null)
  const paperRef = useRef<HTMLDivElement>(null)
  const { isSectionVisible } = useSection()
  const { topStickyDistance } = useContext(ArticleStickyHeaderContext)

  useLayoutEffect(() => {
    const paper = paperRef.current
    const root = rootRef.current
    if (!root || !paper) {
      return
    }

    paper.style.top = `${root.offsetTop}px`
  })

  const getVerticalOffsetDesktop = (isScrollingUp: boolean) => {
    // We don't know height of these block when start scrolling from top, so use hardcode values
    const articleStickyHeaderHeight = 76
    const headerHeight = isScrollingUp ? 172 : 88
    const paperHeight = paperRef?.current?.offsetHeight || 0

    // Add some space to ensure that sticky elements are not too close to scroll sections.
    const safeGap = 10

    return articleStickyHeaderHeight + headerHeight + paperHeight + safeGap
  }

  return (
    <Paper
      ref={paperRef}
      className={styles.header}
      style={{
        position: 'sticky',
        top: `calc(${topStickyDistance}px + var(--obeta-safe-area-top-root))`,
      }}
    >
      {[
        {
          label: 'ARTICLE_DETAIL.TECHNICAL_SPECIFICATIONS',
          to: ArticleDetailsSections.TechnicalSpecification,
        },
        { label: 'ARTICLE_DETAIL.DESCRIPTION', to: ArticleDetailsSections.Description },
        { label: 'ARTICLE_DETAIL.SUITABLE_ACCESSORIES', to: ArticleDetailsSections.Accessories },
        { label: 'ARTICLE_DETAIL.DOWNLOADS', to: ArticleDetailsSections.Downloads },
        { label: 'ARTICLE_DETAIL.CATEGORY', to: ArticleDetailsSections.Category },
        {
          label: 'ARTICLE_DETAIL.STOCK_ASSORTMENT_CAGALOGUE',
          to: ArticleDetailsSections.StockAssortmentCatalog,
        },
        { label: 'ARTICLE_DETAIL.OXOMI_CATALOG', to: ArticleDetailsSections.OxomiCatalog },
        {
          label: 'ARTICLE_DETAIL.QUESTIONS_AND_ANSWERS',
          to: ArticleDetailsSections.QuestionsAndAnswers,
        },
        { label: 'ARTICLE_DETAIL.CUSTOMER_REVIEWS', to: ArticleDetailsSections.CustomerReviews },
      ].map((conf) => {
        if (!isSectionVisible(conf.to)) {
          return null
        }

        return (
          <div className={styles.headerWrapper} key={conf.label}>
            <TertiaryButton
              onClick={() => {
                const targetScrollElement = document.getElementById(conf.to)
                const root = window
                const topScrollDistance = window?.scrollY || 0

                if (targetScrollElement) {
                  // Get target element coordinates
                  const targetScrollElementRect = targetScrollElement.getBoundingClientRect()
                  const distanceToTop = targetScrollElementRect.top + topScrollDistance

                  // Get sum of height sticky elements when scroll from top and bottom
                  // We can't access them dynamically via refs, as they become visible only after scrolling starts.
                  const scrollTopElementsHeight = getVerticalOffsetDesktop(true)
                  const scrollBottomElementsHeight = getVerticalOffsetDesktop(false)

                  // Since we always add a vertical offset, which is equal to scrollTopElementsHeight, for top scrolling, we also include it here
                  const scrollingUpDiff =
                    topScrollDistance + scrollTopElementsHeight - distanceToTop

                  const isScrollingUp = scrollingUpDiff > 0
                  const scrollingUpDiffModule = Math.floor(Math.abs(scrollingUpDiff))

                  // It means that scroll value doesn't change in both cases - when we scrolled top before and have more sticky elements and when we scrolled bottom before
                  const skipScrollFromBottom = scrollingUpDiffModule === 0
                  const skipScrollFromTop =
                    scrollingUpDiffModule === scrollTopElementsHeight - scrollBottomElementsHeight

                  // We don't want the scroll to top/bottom if the position doesn't change.
                  if (skipScrollFromBottom || skipScrollFromTop) return

                  const verticalOffset = getVerticalOffsetDesktop(isScrollingUp)

                  root?.scrollTo({
                    top: distanceToTop - verticalOffset,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              {t(conf.label)}
            </TertiaryButton>
          </div>
        )
      })}
    </Paper>
  )
}
