import { createContext, useContext, useMemo, useState } from 'react'

// Models
import { OfferForListPage, OfferItemsForListPage } from '@obeta/models/lib/schema-models/offer-list'
import { OffersInputWithAction } from '@obeta/models/lib/models'
import { OffersInput } from '@obeta/schema'

// Constants

export const SEARCH_OFFERS_LIMIT = 24
const INITIAL_OFFER_SEARCH_STATE: OffersInput = {
  filter: [],
  limit: SEARCH_OFFERS_LIMIT.toString(),
  offset: '0',
  // Note, following COD-16556, user-facing we order by 'startDate', but translate it backend-facing as 'offerId DESC'
  orderBy: 'offerId',
  orderDir: 'DESC',
  searchTerm: '',
}

const initialEmptyFunction = () => {
  //
}

type ContextType = {
  hasOffers: boolean | undefined
  offers: OfferForListPage[]
  offersItems: OfferItemsForListPage[]
  searchOffersInput: OffersInputWithAction
  totalFavoriteOfferCount: number
  totalOfferCount: number
  setHasOffers: (hasOffers: boolean | undefined) => void
  setOffers: (offers: OfferForListPage[]) => void
  setOffersItems: (offersItems: OfferItemsForListPage[]) => void
  setSearchOffersInput: (searchOffersInput: OffersInputWithAction) => void
  setTotalFavoriteOfferCount: (totalFavoriteOfferCount: number) => void
  setTotalOfferCount: (totalOfferCount: number) => void
}

export const OfferListContext = createContext<ContextType>({
  hasOffers: undefined,
  offers: [],
  offersItems: [],
  searchOffersInput: INITIAL_OFFER_SEARCH_STATE,
  totalFavoriteOfferCount: 0,
  totalOfferCount: 0,
  setHasOffers: initialEmptyFunction,
  setOffers: initialEmptyFunction,
  setOffersItems: initialEmptyFunction,
  setSearchOffersInput: initialEmptyFunction,
  setTotalFavoriteOfferCount: initialEmptyFunction,
  setTotalOfferCount: initialEmptyFunction,
})

export const OfferListContextProvider = ({ children }) => {
  const [hasOffers, setHasOffers] = useState<boolean | undefined>(undefined)
  const [offers, setOffers] = useState<OfferForListPage[]>([])
  const [offersItems, setOffersItems] = useState<OfferItemsForListPage[]>([]) // Separate array to prevent infinite list page rerender by offers array (react-virtualized cache)
  const [searchOffersInput, setSearchOffersInput] = useState<OffersInputWithAction>(
    INITIAL_OFFER_SEARCH_STATE
  )
  const [totalFavoriteOfferCount, setTotalFavoriteOfferCount] = useState<number>(0)
  const [totalOfferCount, setTotalOfferCount] = useState<number>(0)

  const offerListContextProviderValue = useMemo(
    () => ({
      hasOffers,
      offers,
      offersItems,
      searchOffersInput,
      totalFavoriteOfferCount,
      totalOfferCount,
      setHasOffers,
      setOffers,
      setOffersItems,
      setSearchOffersInput,
      setTotalFavoriteOfferCount,
      setTotalOfferCount,
    }),
    [hasOffers, offers, offersItems, searchOffersInput, totalFavoriteOfferCount, totalOfferCount]
  )

  return (
    <OfferListContext.Provider value={offerListContextProviderValue}>
      {children}
    </OfferListContext.Provider>
  )
}

export const useOfferListContext = () => {
  return useContext(OfferListContext)
}
