import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './styles.module.scss'

import { SecondaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as FilterIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { SidebarMobile } from '../sidebar/SidebarMobile'
import { usePageContext } from '../context/context'
import { IFiltersCombined, TSetFilters, TSetFilter } from '../types'
import { ICategory, IFilter, ILocation } from '@obeta/models/lib/models/RentArticle/types'

export interface IFiltersMobileProps {
  className?: string
  isLoading: boolean
  locations: ILocation[] | null
  categories: ICategory[] | null
  filters: IFiltersCombined
  setFilters: TSetFilters
  setFilter: TSetFilter
  activeFilters: IFilter[]
  foundArticlesCount?: number
}

export const FiltersMobile: FC<IFiltersMobileProps> = ({
  className,
  isLoading,
  locations,
  categories,
  filters,
  setFilter,
  setFilters,
  activeFilters,
  foundArticlesCount,
}) => {
  const { mobile } = usePageContext()
  const { t } = useTranslation()
  const mobileSidebarState = usePopoverState()

  const activeFiltersLabel =
    activeFilters.length > 0 ? (
      <Typography variant="body">
        ({activeFilters.length} {t<string>('MAIN.ACTIVE')})
      </Typography>
    ) : null

  return (
    <div className={clsx(styles['filtersMobileRoot'], className)}>
      <SecondaryButton onClick={mobileSidebarState.handleClick} leftIcon={<FilterIcon />}>
        {t<string>('SEARCH.ARTICLELIST.FILTER.TITLE')} {mobile && activeFiltersLabel}
      </SecondaryButton>
      <SidebarMobile
        {...mobileSidebarState}
        isLoading={isLoading}
        locations={locations}
        categories={categories}
        filters={filters}
        setFilter={setFilter}
        setFilters={setFilters}
        foundArticlesCount={foundArticlesCount}
      />
    </div>
  )
}
