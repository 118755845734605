import React from 'react'
import styles from './ArticleMainInfo.module.scss'
import { ArticlePrice } from './ArticlePrice'
import { ProductProperties } from './ProductProperties'

import { IResponsiveComponentProps } from '@obeta/models/lib/models/Components'
import { IArticle } from './types'

import { Gallery } from './Gallery'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { Box } from '@mui/material'
import clsx from 'clsx'

interface IArticleMainInfoProps extends IResponsiveComponentProps {
  article: IArticle | undefined
  selectedStore: StoreV2 | undefined
  defaultStoreId: string | undefined
  noRightOverFlowClass?: string
}

export const ArticleMainInfo: React.FC<IArticleMainInfoProps> = (props) => {
  const { article, selectedStore, defaultStoreId } = props

  return (
    <div className={styles.mainInfo}>
      <Gallery article={article} noRightOverFlowClass={props.noRightOverFlowClass} />
      <Box className={clsx(props.noRightOverFlowClass)}>
        <ProductProperties article={article} defaultStoreId={defaultStoreId} />
      </Box>
      <Box className={clsx(styles.priceWrap, props.noRightOverFlowClass)}>
        <ArticlePrice article={article} selectedStore={selectedStore} />
      </Box>
    </div>
  )
}
