import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { handleError } from '@obeta/utils/lib/datadog.errors'

const newsQuery = gql`
  query obetaNewsDetails($id: ID) {
    obetaNews(id: $id) {
      data {
        id
        attributes {
          teaserText
          title
          content
          startDate
          publishedAt
          category
          teaser {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

export const useNewsDetails = (newsId: string): INewsCard | null => {
  const [detailedNews, setDetailedNews] = useState<INewsCard | null>(null)
  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getNews = async () => {
      try {
        if (!isMounted.current || !newsId) return

        const response = await client.query({
          query: newsQuery,
          variables: { id: newsId },
        })

        const result = response.data.obetaNews.data?.attributes
        const news = !result
          ? null
          : {
              ...result,
              id: newsId,
              imageUrl: result.teaser.data?.attributes.url,
              imageAlt: result.teaser.data?.attributes.alternativeText,
              content: result.content,
            }
        delete news.__typename
        delete news.teaser

        setDetailedNews(news)
      } catch (error) {
        error.message = `Apollo client throws error in getNews ${error.message}`
        handleError(error)
      }
    }

    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId])

  return detailedNews
}
