import { TransitionGroup as BaseTransitionGroup } from 'react-transition-group'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { useEffect, useState } from 'react'
import { useStackHistory } from './hooks/useStatckHistory'

const transitionTimeout = parseInt(scssVariables.slideTransitionTimeout)

type Direction = 'push' | 'pop'

export const TransitionGroup: React.FC<{ className?: string }> = (props) => {
  const { children, className } = props

  const stackHistory = useStackHistory()
  const currentKey = stackHistory.name
  const direction = stackHistory.direction

  /**
   * PROBLEM:
   * CSSTransition applies *-(enter|exit) and *-(enter|exit)-active
   * class names at the same time.
   * Because of this transition effects are broken in production mode
   * "push-enter" sets "transform: translateX(100%)"
   * And "push-enter-active" sets "transform: translateX(0)" and also defines transition.
   * In this case browser doesn't recognize that element position was translated.
   * Transition will work only if you set this classes IN SEPERATE FRAMES.
   *
   * After transition is finished make sure to call setActive with null to remove
   * any *-(enter|exit)-active class names.
   */
  const [active, setActive] = useState<Direction | null>(null)
  useEffect(() => {
    const rId = requestAnimationFrame(() => {
      setActive(direction)
    })

    return () => {
      cancelAnimationFrame(rId)
    }
  }, [direction, currentKey])

  useEffect(() => {
    if (active) {
      const timerId = setTimeout(() => {
        setActive(null)
      }, transitionTimeout)

      return () => {
        clearTimeout(timerId)
      }
    }
  }, [active])

  return (
    <BaseTransitionGroup
      className={className}
      component="div"
      data-direction={direction}
      data-active-direction={active}
    >
      {children}
    </BaseTransitionGroup>
  )
}
