import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'

// Models
import {
  UpdateOfferMetaDataInput,
  UpdateOfferMetaDataMutation,
  UpdateOfferMetaDataMutationVariables,
} from '@obeta/schema'

// Utils
import { trackCustom } from '@obeta/utils/lib/tracking'

// Constants
const UPDATE_OFFER_META_DATA_MUTATION = gql`
  mutation UpdateOfferMetaData($input: UpdateOfferMetaDataInput!) {
    updateOfferMetaData(input: $input) {
      success
    }
  }
`

export const useOfferActions = () => {
  const client = useApolloClient()

  /**
   * Handler to update offer favorite state.
   * @param input Mutation input
   */
  const updateOfferMetaData = useCallback(
    async (input: UpdateOfferMetaDataInput): Promise<UpdateOfferMetaDataInput | undefined> => {
      const response = await client.mutate<
        UpdateOfferMetaDataMutation,
        UpdateOfferMetaDataMutationVariables
      >({
        mutation: UPDATE_OFFER_META_DATA_MUTATION,
        variables: {
          input,
        },
      })

      const data = response.data?.updateOfferMetaData

      if (data?.success) {
        return input
      } else {
        trackCustom('update-meta-data-error')
      }
    },
    [client]
  )

  return {
    updateOfferMetaData,
  }
}
