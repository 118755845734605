import React from 'react'
import styles from './LoadingIndicator.module.scss'
import clsx from 'clsx'

export const LoadingIndicator: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={clsx(styles.circle, styles.animation, styles.circle1)} />
      <div className={clsx(styles.circle, styles.animation, styles.circle2)} />
      <div className={clsx(styles.circle, styles.animation, styles.circle3)} />
    </div>
  )
}
