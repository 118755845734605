import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import {
  createDigitalBillingEntry,
  CreateDigitalBillingEntryResultAction,
  DigitalBillingActionTypes,
  getDigitalBillingEntry,
  GetDigitalBillingEntryResultAction,
} from '@obeta/data/lib/actions/digital-billing-actions'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'

export type BillingType = 'pdf' | 'zugferd'

type UseDigitalBillingActions = () => {
  isRegistered: boolean
  register: (billingType: string, email: string) => void
}

const SEND_MAIL_API_ROUTE = '/api/sendmail'
const EMAIL_TO = 'rg-umstellung@obeta.de'

export const useDigitalBillingActions: UseDigitalBillingActions = () => {
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useUserDataV2()

  const waitForCreateDigitalBillingEntry = useActionNotification(
    DigitalBillingActionTypes.CreateDigitalBillingEntryResult
  )
  const waitForGetDigitalBillingEntry = useActionNotification(
    DigitalBillingActionTypes.GetDigitalBillingEntryResult
  )

  const isReadyForDigitalBillingActions = user.fullUserId !== undefined && user.allTokensExist

  const register = useCallback(
    (billingType: string, email: string) => {
      if (isReadyForDigitalBillingActions) {
        dispatch(createDigitalBillingEntry(billingType, email))
        const data = {
          to: EMAIL_TO,
          subject: t('DIGITAL_BILLING.NOT_REGISTERED.EMAIL_SUBJECT'),
          text: t('DIGITAL_BILLING.NOT_REGISTERED.EMAIL_TEXT', {
            email,
            billingType,
            user: user.fullUserId,
          }),
          host: process.env.NEXT_PUBLIC_MAILGUN_HOST,
          port: process.env.NEXT_PUBLIC_MAILGUN_PORT,
        }
        fetch(SEND_MAIL_API_ROUTE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(async (response) => response.json())
          .catch((error) => console.error('Error:', error))
      }
    },
    [dispatch, isReadyForDigitalBillingActions, t, user.fullUserId]
  )

  useEffect(() => {
    if (isReadyForDigitalBillingActions) {
      dispatch(getDigitalBillingEntry())
    }
  }, [dispatch, isReadyForDigitalBillingActions])

  waitForCreateDigitalBillingEntry((response: CreateDigitalBillingEntryResultAction) => {
    setIsRegistered(response.success)
  })
  waitForGetDigitalBillingEntry((response: GetDigitalBillingEntryResultAction) => {
    setIsRegistered(response.alreadyRegistered)
  })

  return {
    isRegistered,
    register,
  }
}
