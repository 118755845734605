import React, { FC } from 'react'
import clsx from 'clsx'
import { CircularProgress, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'assets/icon/close-outline.svg'
import { Modal } from '@obeta/components/lib/modal/Modal'
import { CommonSkeleton } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { AllTeasersFilter, AllTeasersFilters } from '../../hooks/useAdvertaisingProvider'
import styles from './MobileFilters.module.scss'

interface IOptions {
  label: string
  value: string
  disabled?: boolean
}

interface IMobileFiltersProps {
  open: boolean
  onClose: () => void
  isLoading?: boolean
  filters: Partial<AllTeasersFilters>
  groupOptions: IOptions[]
  updateFilters: (filters: Partial<AllTeasersFilters>) => void
  title?: string
  brandOptions: IOptions[]
}

type RadioFilterSectionProps = Pick<IMobileFiltersProps, 'title' | 'isLoading' | 'filters'> & {
  options: IOptions[]
  type: AllTeasersFilter
  updateFilters: (value: string, type: AllTeasersFilter) => void
}

export const RadioFilterSection: FC<RadioFilterSectionProps> = ({
  isLoading,
  options,
  updateFilters,
  filters,
  title,
  type,
}) => {
  return (
    <div className={styles.radioFilterSectionWrapper}>
      {title && (
        <Typography variant="headline4Bold" marginBottom={'0.5rem'}>
          {title}
        </Typography>
      )}
      {isLoading ? (
        <CommonSkeleton count={3} height={32} width={20} />
      ) : (
        <>
          {options.map((el) => {
            return (
              <RadioItem
                className={styles.item}
                onChange={() => updateFilters(el.value, type)}
                key={el.value + el.label}
                id={`location_${el.value}`}
                label={el.label}
                value={el.value}
                checked={el.value === filters[type]}
                disabled={el.disabled}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

export const MobileFilters: FC<IMobileFiltersProps> = (props) => {
  const { open, onClose, isLoading, filters, updateFilters, groupOptions, brandOptions } = props
  const { t } = useTranslation()

  const updateFiltersInner = (value: string, type: AllTeasersFilter) => {
    updateFilters({ ...filters, [type]: value })
  }

  const onCloseAndUpdate = () => {
    onClose()
  }

  return (
    <Modal isFullScreen open={open} onClose={onCloseAndUpdate}>
      <div className={styles.mobileRoot}>
        <div className={styles.modalHeader}>
          <div className={styles.titleWrapper}>
            <Typography variant="headline4Bold">{t('TEASERS.MOBILE_FILTER_TITLE')}</Typography>
            {isLoading && <CircularProgress size={16} sx={{ color: '#000000' }} />}
          </div>
          <IconButton aria-label="close" className={styles.closeButton} onClick={onCloseAndUpdate}>
            <CloseIcon className={styles.icon} />
          </IconButton>
        </div>
        <div className={clsx(styles.sidebarRoot)}>
          <RadioFilterSection
            options={groupOptions}
            filters={filters}
            updateFilters={updateFiltersInner}
            type={AllTeasersFilter.Category}
          />
          <RadioFilterSection
            title={t('TEASERS.FILTER_BY_BRAND_TITLE_MOBILE')}
            options={brandOptions}
            filters={filters}
            updateFilters={updateFiltersInner}
            type={AllTeasersFilter.Brand}
          />
        </div>
      </div>
    </Modal>
  )
}
