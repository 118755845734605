import React, { useState } from 'react'
import { Typography, ListItemButton, ListItemText, List, Collapse, Box, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import footerStyles from '../footer/Footer.module.scss'
import styles from './AccountInfo.module.scss'
import clsx from 'clsx'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { CreditInfoProps } from './AccountInfo'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'

export const AccountInfoMobile: React.FC<CreditInfoProps> = ({ creditInfo }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [showCreditLimitInfo, setShowCreditLimitInfo] = useState(false)

  const { accountingPhoneNumber, accountingEmailAddress } = useCompanyContactDetailsContext()

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <List component="nav" className={styles.mainDiv}>
        <div className={styles.headerContainer}>
          <ListItemButton
            className={clsx(footerStyles.linkListItem, styles.minContentWidth, styles.noPadding)}
            onClick={handleClick}
          >
            {open ? (
              <TertiaryIconButton icon={<ExpandLess />} size={'large'} />
            ) : (
              <TertiaryIconButton icon={<ExpandMore />} size={'large'} />
            )}
            <ListItemText
              primary={
                <Typography variant={'bodyBold'}>
                  {t('CUSTOMER_PROFILE.ACCOUNT_INFORMATION')}
                </Typography>
              }
            />
            <ListItemText />
          </ListItemButton>
        </div>

        <Collapse in={open}>
          <Box className={styles.infoUnitMobile}>
            <Typography
              variant="body"
              onClick={() => {
                setShowCreditLimitInfo(true)
              }}
              className={styles.creditLimitExtraStyling}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {t('CUSTOMER_PROFILE.CREDIT_LIMIT')}
            </Typography>
            <Typography variant="bodyBold">
              {normalizePrice(creditInfo.creditLimit, '€')}
            </Typography>
          </Box>
          <Box className={styles.infoUnitMobile}>
            <Typography variant={'body'}>{t('CUSTOMER_PROFILE.OPEN_ORDERS')}</Typography>
            <Typography variant="bodyBold">{normalizePrice(creditInfo.openOrders, '€')}</Typography>
          </Box>
          <Box className={styles.infoUnitMobile}>
            <Typography variant="body">{t('CUSTOMER_PROFILE.AVAILABLE_SUM')}</Typography>
            <Typography variant="bodyBold">
              {normalizePrice(creditInfo.creditAvailable, '€')}
            </Typography>
          </Box>
          <Box className={styles.infoUnitMobile}>
            <Typography variant="body">{t('CUSTOMER_PROFILE.OPEN_POSTS')}</Typography>
            <Typography variant="bodyBold">
              {normalizePrice(creditInfo.openPositions, '€')}
            </Typography>
          </Box>
          <Box className={clsx(styles.infoUnitMobile, styles.marginBottomHalfRem)}>
            <Typography variant="body">{t('CUSTOMER_PROFILE.NET_TOTAL')}</Typography>
            <Typography variant="bodyBold">{normalizePrice(creditInfo.creditUsed, '€')}</Typography>
          </Box>
        </Collapse>
      </List>
      <PopoverFromBottom
        onClose={() => {
          setShowCreditLimitInfo(false)
        }}
        isOpen={showCreditLimitInfo}
      >
        <Typography>{t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.0')}</Typography>
        <Typography variant={'bodyBold'}>{`T: ${accountingPhoneNumber}`}</Typography>
        <Typography>{t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.1')}</Typography>

        <Typography variant={'bodyBold'} color={'secondary'}>
          M:{' '}
          <Link
            href={`mailto:${accountingEmailAddress}`}
            underline="none"
            color="secondary"
            target="_top"
          >
            {accountingEmailAddress}
          </Link>
        </Typography>
        <Typography>{t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.2')}</Typography>
      </PopoverFromBottom>
    </>
  )
}
