import { Divider } from '@mui/material'
import { Dispatch, useRef, useState } from 'react'
import { Modal, ModalCloseIcon, ModalProps } from '../modal/Modal'
import { Thumbs } from './Thumbs'
import { SelectActions } from './types'
import { ZoomPan } from '../zoom-pan/ZoomPan'
import { ContentHold } from './ContentHold'
import { Image } from './Image'
import zoomIn from '@obeta/assets/icon/zoom_in.svg'
import zoomOut from '@obeta/assets/icon/zoom_out.svg'
import clsx from 'clsx'
import { Turntable } from '../articles/Turntable'
import { VideoSlidesController } from '../articles/VideoSlidesController'
import { VideoSlide } from '../articles/VideoSlide'
import { NavigateButton } from '../navigate-button/NavigateButton'
import { isTouchDevice } from '@obeta/utils/lib/isTouchDevice'
import { ContentItem } from '@obeta/data/lib/hooks/useArticleGalleryContent'
import styles from './ImageGalleryModal.module.scss'

const maxZoom = 3

interface IImageGalleryProps extends Pick<ModalProps, 'onClose' | 'marginThreshold'> {
  open: boolean
  rightThumbsAdornment?: React.ReactChild
  leftAdornment?: React.ReactChild
  items: ContentItem[]
  selectedIdx: number
  dispatchSelectAction: Dispatch<SelectActions>
  withNavigateButtons: boolean
  withDivider: boolean
  isFullScreen: boolean
}

const isTouch = isTouchDevice()

export const ImageGalleryModal: React.FC<IImageGalleryProps> = (props) => {
  const {
    open,
    rightThumbsAdornment,
    leftAdornment,
    items,
    onClose,
    selectedIdx,
    withDivider,
    withNavigateButtons,
    isFullScreen,
    marginThreshold,
    dispatchSelectAction,
  } = props
  const [zoomedIn, setZoomedIn] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const selectedItem = items[selectedIdx]

  let imgElement: JSX.Element
  switch (selectedItem?.contentType) {
    case 'image': {
      const turntableAvailable = Boolean(selectedItem.turntableImages && isTouch)
      if (selectedItem.turntableImages) {
        imgElement = (
          <Turntable
            ref={imgRef}
            loadingImage={selectedItem.url}
            images={selectedItem.turntableImages}
          />
        )
      } else {
        imgElement = (
          <Image
            ref={imgRef}
            src={selectedItem.url}
            alt={selectedItem.description}
            width={selectedItem.articleImgProxyData?.width}
          />
        )
      }
      imgElement = (
        <ZoomPan
          maxZoom={maxZoom}
          allowPan={!turntableAvailable}
          imgRef={imgRef}
          cursor={`url(${zoomedIn ? zoomOut : zoomIn}), auto`}
          onZoomChange={(zoom) => {
            setZoomedIn(zoom > 1)
          }}
          allowTouchEvents={true}
          className={styles.zoomPan}
        >
          {imgElement}
        </ZoomPan>
      )
      break
    }
    case 'video':
      imgElement = (
        <VideoSlidesController>
          <VideoSlide {...selectedItem} poster={selectedItem.thumbUrl} />
        </VideoSlidesController>
      )
      break
  }

  return (
    <Modal
      marginThreshold={marginThreshold}
      isFullScreen={isFullScreen}
      onClose={onClose}
      open={open}
      keepMounted={false}
      classNames={{
        content: styles.modal,
      }}
    >
      <div className={clsx(styles.root, { [styles.rootFullScreen]: isFullScreen })}>
        <ModalCloseIcon onClose={onClose} className={styles.closeBtn} />
        <div className={styles.selected}>
          {withNavigateButtons && (
            <NavigateButton
              className={styles.navigationLeft}
              direction="left"
              onClick={() => {
                dispatchSelectAction({ type: 'prev' })
              }}
            />
          )}
          <ContentHold className={styles.contentHold}>
            {leftAdornment}
            {imgElement}
          </ContentHold>
          {withNavigateButtons && (
            <NavigateButton
              className={styles.navigationRight}
              direction="right"
              onClick={() => {
                dispatchSelectAction({ type: 'next' })
              }}
            />
          )}
        </div>
        {withDivider && <Divider className={styles.divider} />}
        <div className={styles.thumbsWrapper}>
          <Thumbs
            size={80}
            className={clsx(styles.thumbs, {
              [styles.withThumbsAdornment]: Boolean(rightThumbsAdornment),
            })}
            onThumbClicked={(e, id, idx) => {
              dispatchSelectAction({ type: 'set', payload: { nextIndex: idx } })
            }}
            thumbsPlacement="bottom"
            thumbs={items}
            selectedIdx={selectedIdx}
          />
          {rightThumbsAdornment}
        </div>
      </div>
    </Modal>
  )
}
