import React from 'react'
import Collapse from '@mui/material/Collapse'
import { DatsheetPrintOptions } from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'
import { PrintOptionIndividualForm } from '../print-option-individual-form/PrintOptionIndividualForm'
import { PrintOptionIndividualFormFixedPrice } from '../print-option-individual-form-fixed-price/PrintOptionIndividualFormFixedPrice'
import { PricePermissions, UserV2Permissions } from '@obeta/models/lib/models/Users/UserV2'
import { useFormContext } from 'react-hook-form'

interface DatasheetFormManagerProps {
  permissions?: UserV2Permissions
  isAdmin?: boolean
}

export const DatasheetFormManager: React.FC<DatasheetFormManagerProps> = ({
  isAdmin,
  permissions,
}) => {
  const { watch } = useFormContext()
  const selectedPrintOption = watch('type')

  const { Global_canChangeDataSheetPrice, Global_canReadPrices } = permissions || {}
  const hasReadPricePermission = Global_canReadPrices === PricePermissions.PurchasePrice

  const showNotRestrictedPermissionForm =
    isAdmin || (Global_canChangeDataSheetPrice && hasReadPricePermission)
  const showIndividualFormRestrictedFixedPrice =
    !isAdmin && Global_canChangeDataSheetPrice && !hasReadPricePermission

  if (!isAdmin && !Global_canChangeDataSheetPrice) return null

  return (
    <Collapse in={selectedPrintOption === DatsheetPrintOptions.INDIVIDUAL} unmountOnExit>
      {showNotRestrictedPermissionForm && <PrintOptionIndividualForm />}
      {showIndividualFormRestrictedFixedPrice && <PrintOptionIndividualFormFixedPrice />}
    </Collapse>
  )
}
