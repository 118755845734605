import { memo } from 'react'
import { LocationOverlayTag } from './LocationOverlayTag'
import { Collapse } from '@obeta/components/lib/collapse/Collapse'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import styles from './LocationOverlayGroup.module.scss'

interface ILocationFilterGroupProps {
  groupTitle: string
  branches: StoreV2[]
  onClick: (filterName: StoreV2) => void
  initialExpanded?: boolean
  isExpanded?: boolean
  onToggle?: (state: boolean, group: string) => void
}

export const LocationOverlayGroup = memo(
  ({
    groupTitle,
    branches,
    onClick,
    initialExpanded = false,
    isExpanded,
    onToggle,
  }: ILocationFilterGroupProps) => {
    return (
      <div className={styles.wrapper}>
        <Collapse
          skipOnToggleFirstRender={true}
          onToggle={onToggle}
          isExpanded={isExpanded}
          headerAlign="left"
          iconLeft={false}
          initialExpanded={initialExpanded}
          groupId={groupTitle}
          header={groupTitle}
          headerTextVariant="boldText"
          classes={{
            header: styles.collapseHeader,
            svgWrapper: styles.collapseSvgWrapper,
          }}
        >
          <div className={styles.overlayGroup}>
            {branches.map((branch) => (
              <LocationOverlayTag key={branch.id} branch={branch} onClick={onClick} />
            ))}
          </div>
        </Collapse>
      </div>
    )
  }
)
