import { KeyboardEvent, useRef, useMemo, useEffect, useState } from 'react'
import clsx from 'clsx'
import parse from 'autosuggest-highlight/parse'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as SearchIcon } from 'assets/icon/search20x20.svg'
import styles from './PlacesAutocomplete.module.scss'

const autocompleteService: { current: google.maps.places.AutocompleteService | null } = {
  current: null,
}

type AutocompletePrediction = google.maps.places.AutocompletePrediction

type PredictionSubstring = google.maps.places.PredictionSubstring

type GeocoderResult = google.maps.GeocoderResult

type AutocompletionRequest = google.maps.places.AutocompletionRequest

export default function PlacesAutocomplete({ onGetCoordinatesCallback }) {
  const [value, setValue] = useState<AutocompletePrediction | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<AutocompletePrediction[]>([])

  const textFieldRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      textFieldRef.current?.blur()
    }
  }

  const fetchPlaces = useMemo(
    () =>
      debounce(
        (
          request: AutocompletionRequest,
          callback: (results: AutocompletePrediction[] | null) => void
        ) => {
          if (autocompleteService.current) {
            autocompleteService.current.getPlacePredictions({ ...request }, callback)
          }
        },
        400
      ),
    []
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }

    if (!autocompleteService.current) return

    if (inputValue === '') return setOptions(value ? [value] : [])

    fetchPlaces({ input: inputValue }, (results?: AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: AutocompletePrediction[] = []

        if (value) newOptions = [value]

        if (results) newOptions = [...newOptions, ...results]

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetchPlaces])

  const getPlaceCoordinates = (description: string | undefined) => {
    if (description) {
      const geocoder = new window.google.maps.Geocoder()

      geocoder.geocode(
        { address: description },
        (results: GeocoderResult[] | null, status: string | null) => {
          if (status === 'OK' && results !== null && results[0]) {
            const { lat, lng } = results[0].geometry.location

            const coordinatesRes = { latitude: lat(), longitude: lng() }

            onGetCoordinatesCallback(coordinatesRes)
          } else {
            throw new Error('Error getting coordinates')
          }
        }
      )
    } else {
      onGetCoordinatesCallback(null)
    }
  }

  return (
    <Autocomplete
      id="google-map-places-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Adresse eingeben"
      onChange={(_event, newValue: AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        getPlaceCoordinates(newValue?.description)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          className={clsx('outlined-lightGray', styles.field)}
          fullWidth
          sx={{ '& .MuiAutocomplete-endAdornment': { top: 'unset' } }}
          onKeyUp={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            inputMode: 'search',
            className: styles.inputRoot,
            classes: { input: styles.input },
            startAdornment: (
              <InputAdornment position="start" className={styles.adornment}>
                <SearchIcon className={styles.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: PredictionSubstring) => [match.offset, match.offset + match.length])
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item className={styles.iconWrapper}>
                <LocationOnIcon className={styles.locationIcon} />
              </Grid>
              <Grid item className={styles.contentWrapper}>
                {parts.map((part: { highlight: boolean; text: string }, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" className={styles.secondaryText}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
