import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Modal, ModalCloseIcon } from '@obeta/components/lib/modal/Modal'
import { useCustomerMainManager } from './CustomerMainManagerProvider'
import { PleaseLoginScreen } from '../please-login-screen/PleaseLoginScreen'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { GreetingSection } from './GreetingSection/GreetingSection'
import { CustomerCard } from './CustomerCard/CustomerCard'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { useBarcodeScanner } from '@obeta/data/lib/hooks/useBarcodeScanner'
import { Capacitor } from '@capacitor/core'
import { belowMdQuery, useMediaQuery } from '@obeta/data/lib/hooks'
import styles from './CustomerMain.module.scss'

export const CustomerMain: React.FC = () => {
  const { isCustomerCardOpen, customerMainCardVisibilityHandler } = useCustomerMainManager()
  const { t } = useTranslation()
  const { isLoggedIn } = useUserDataV2()
  const { scan } = useBarcodeScanner()
  const isNativePlatform = Capacitor.isNativePlatform()
  const isBelowMd = useMediaQuery(belowMdQuery)

  const onCloseModal = async () => {
    customerMainCardVisibilityHandler(false)
    await ScreenOrientation.unlock()
  }

  return (
    <Modal
      className={scan ? styles.hideModal : styles.showModal}
      isFullScreen={true}
      disableBodyScroll={true}
      open={isCustomerCardOpen}
      onClose={onCloseModal}
    >
      <div
        className={clsx(styles.root, {
          [styles.isLoggedIn]: isLoggedIn,
          [styles.forceRotateAppWeb]: isLoggedIn && !isNativePlatform && isBelowMd,
        })}
      >
        <ModalCloseIcon
          className={clsx(styles.close, {
            [styles.closeLoggedIn]: isLoggedIn,
          })}
          onClose={onCloseModal}
        />
        <div
          className={clsx(styles.content, {
            [styles.contentLoggedIn]: isLoggedIn,
          })}
        >
          {!isLoggedIn ? (
            <PleaseLoginScreen
              title={t('CUSTOMER_PROFILE.GUEST_TEXT_TITLE')}
              body={t('CUSTOMER_PROFILE.GUEST_TEXT_DESCRIPTION')}
            />
          ) : (
            <>
              <CustomerCard />
              <GreetingSection />
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}
