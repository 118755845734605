import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  OrderButtonActionType,
  OrderStateAfterSubmit,
  ShoppingCartV2,
} from '@obeta/models/lib/models'
import { ShoppingCartSubmitCartRequestTrackingData } from '@obeta/models/lib/models/ShoppingCart/ShoppingCartTrackingData'
import {
  clearOrderProcessInformation,
  isApprovalOrderProcess,
  isOrderProcessStarted,
} from '@obeta/utils/lib/order-process-information'
import { IdsFormFields } from '@obeta/schema'
import { mapShoppingCartV2ToShoppingCartSubmitCartRequestTrackingData } from './create-order-utils'
import { useOnCartUpdateAndVerifySuccess } from '../shoppingcart-checkout/useOnCartUpdateAndVerifySuccess'
import {
  CartsActionTypes,
  getIdsFormFields,
  GetIdsFormFieldsGraphQLResultAction,
} from '../../actions'
import { useActionNotification } from '../useActionNotification'
import { useCreateOrderSubmit } from './useCreateOrderSubmit'
import { useCreateOrderNotify } from './useCreateOrderNotify'
import { useOrderResponseHandling } from './useOrderResponseHandling'

const noop = () => 0

export const useCreateOrder = (cart?: ShoppingCartV2, isIdsSession?: boolean) => {
  const [neverStartedCheckout, setNeverStartedCheckout] = useState(false)
  const [approval, setApproval] = useState<{ active: boolean; email: string } | null>(null)
  const [idsFormFields, setIdsFormFields] = useState<IdsFormFields | null>(null)
  const [cartToTrack, setCartToTrack] = useState<ShoppingCartSubmitCartRequestTrackingData | null>(
    null
  )
  const dispatch = useDispatch()
  const hasEvaluatedOrderState = useRef(false)
  const didFinalizeOrderProcess = useRef(false)
  const didFetchIdsFormFields = useRef(false)
  const { order, setOrder } = useOrderResponseHandling({ cart, cartToTrack: cartToTrack })
  const notify = useCreateOrderNotify((approval) => setApproval(approval))
  const submit = useCreateOrderSubmit((cart: ShoppingCartV2) => {
    setOrder({ status: OrderStateAfterSubmit.Pending, response: null })
    setCartToTrack(mapShoppingCartV2ToShoppingCartSubmitCartRequestTrackingData(cart))
  })
  const waitForIdsFormFieldsResultAction = useActionNotification(
    CartsActionTypes.GetIdsFormFieldsGraphQLResult
  )
  const onCartUpdateAndVerifySuccess = useOnCartUpdateAndVerifySuccess({
    cart,
    selectedCartId: cart?.id ?? '',
    cartVerificationActionType: isApprovalOrderProcess()
      ? OrderButtonActionType.Notify
      : OrderButtonActionType.Submit,
    setOrderSubmitted: noop,
    setCartVerificationErrorCode: noop,
    setOrderDeclarationsInCart: noop,
    skipVerification: true,
  })

  // If order process was started on checkout page, submit or notify cart
  useEffect(() => {
    if (cart) {
      const isOrderInProgress = !didFinalizeOrderProcess.current && isOrderProcessStarted(cart.id)
      const shouldFinalizeOrder = !neverStartedCheckout && isOrderInProgress

      if (shouldFinalizeOrder && onCartUpdateAndVerifySuccess) {
        const finalizeOrder = () => {
          onCartUpdateAndVerifySuccess(
            isApprovalOrderProcess() ? () => notify(cart) : () => submit(cart)
          )
          setTimeout(() => clearOrderProcessInformation(), 300)
          didFinalizeOrderProcess.current = true
        }
        if (isIdsSession && !didFetchIdsFormFields.current) {
          dispatch(getIdsFormFields(cart.id))
          didFetchIdsFormFields.current = true

          waitForIdsFormFieldsResultAction((action: GetIdsFormFieldsGraphQLResultAction) => {
            if (
              action.idsFormFieldsResult?.success &&
              action.idsFormFieldsResult?.idsFormFields &&
              idsFormFields === null
            ) {
              setIdsFormFields(action.idsFormFieldsResult.idsFormFields)
              finalizeOrder()
            }
          })
        } else if (!isIdsSession) {
          finalizeOrder()
        }
      }
    }
  }, [
    cart,
    onCartUpdateAndVerifySuccess,
    neverStartedCheckout,
    notify,
    submit,
    isIdsSession,
    dispatch,
    waitForIdsFormFieldsResultAction,
    idsFormFields,
  ])

  // Check if user started the order process on the checkout page and remember that it was checked
  useEffect(() => {
    if (cart?.id && !hasEvaluatedOrderState.current) {
      if (!isOrderProcessStarted(cart.id)) setNeverStartedCheckout(true)
      hasEvaluatedOrderState.current = true
    }
  }, [cart])

  return { order, hasNotCompletedCheckout: neverStartedCheckout, approval, idsFormFields }
}
