import { FC } from 'react'
import { Marker } from '@react-google-maps/api'
import { LocationCoordinates } from '@obeta/models/lib/models/GoogleMap'

interface IStoresMapMarkersProps {
  currentPlace?: LocationCoordinates | null
}

export const PlacesMarker: FC<IStoresMapMarkersProps> = ({ currentPlace }) => {
  if (!currentPlace) return null

  return (
    <Marker
      position={{
        lat: parseFloat(String(currentPlace.latitude)),
        lng: parseFloat(String(currentPlace.longitude)),
      }}
      visible={!!currentPlace}
      key={currentPlace.latitude + currentPlace.longitude}
    ></Marker>
  )
}
