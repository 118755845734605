import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useEffect, useState } from 'react'

export const usePvProducts = (items: ShoppingCartItem[]) => {
  const [found, setFound] = useState(false)

  useEffect(() => {
    setFound(false)
    items?.forEach((item) => {
      if (item.product.isPvArticle) {
        setFound(true)
      }
    })
  }, [items])

  return found
}
