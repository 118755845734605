import { LoadOptions, OxomiOptions, useOxomiData } from './useOxomiData'

export const useArticleDetailsProductMedia = (
  loadOptions: LoadOptions,
  oxomiId?: string,
  supplierId = ''
) => {
  let oxomi: OxomiOptions | null = null
  if (oxomiId) {
    oxomi = {
      id: oxomiId,
      // SAP supplierId adds a leading L, which oxomi does not accept or know, so slice it
      supplierId: supplierId.startsWith('L') ? supplierId.slice(1) : supplierId,
    }
  }
  return useOxomiData(oxomi, loadOptions)
}
