import { Card, CardContent } from '@mui/material'
import { Collapse, ICollapseProps } from '../collapse/Collapse'
import { ReactComponent as KeyboardArrowUp } from '@obeta/assets/icon/designsystem/keyboard_arrow_up32.svg'
import styles from './CollapseCard.module.scss'
import clsx from 'clsx'
import { E2EProps } from '../types'

export const CollapseCardIcon: React.FC<{ rotated?: boolean }> = (props) => {
  return (
    <KeyboardArrowUp
      className={clsx(styles.icon, { [styles.rotated]: props.rotated })}
      data-testid="collapse-arrow"
    />
  )
}

export const CollapseCard: React.FC<Omit<ICollapseProps, 'className' | 'iconLeft'> & E2EProps> = (
  props
) => {
  const { children, component = Card, ...restProps } = props

  return (
    <Collapse
      component={component}
      classes={{ header: styles.header }}
      className={styles.card}
      iconLeft={false}
      Icon={CollapseCardIcon}
      {...restProps}
    >
      <CardContent className={styles.content}>{children}</CardContent>
    </Collapse>
  )
}
