import { FC, useCallback } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { CheckoutOrderPending } from '@obeta/components/lib/shoppingcart/CheckoutOrderPending'
import { CheckoutOrderFailure } from '@obeta/components/lib/shoppingcart/CheckoutOrderFailure'
import { CheckoutOrderSuccess } from '@obeta/components/lib/shoppingcart/CheckoutOrderSuccess'
import { CheckoutOrderApproval } from '@obeta/components/lib/shoppingcart/CheckoutOrderApproval'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { useCreateOrder } from '@obeta/data/lib/hooks/create-order/useCreateOrder'
import { useOnPrintCart } from '@obeta/data/lib/hooks/useOnPrintCart'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { OrderStateAfterSubmit } from '@obeta/models/lib/models'
import { IncompleteCheckout } from './IncompleteCheckout'
import styles from './CreateOrderPage.module.scss'
import { CartWritePermission } from '@obeta/models/lib/models/Users/UserV2'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useSubmitIdsForm } from '@obeta/data/lib/hooks/ids/useSubmitIdsForm'
import { getSessionContext } from '@obeta/utils/lib/session-context'

export const CreateOrderPage: FC = () => {
  const sessionContext = getSessionContext()
  const params = useParams()
  const carts = useCartsv2WithPricesAndStock()
  const cartId = params.cartId
  const cart = carts.find((sc) => sc.id === cartId)
  const { order, hasNotCompletedCheckout, approval, idsFormFields } = useCreateOrder(
    cart,
    sessionContext?.type === 'ids'
  )
  const onPrintCart = useOnPrintCart()
  const { isSessionCart } = useSessionCartName()
  const { user } = useUserDataV2()
  const submitIdsForm = useSubmitIdsForm()
  const userCanSubmitCart =
    user?.permissions?.Cart_allowedSubmitAction === CartWritePermission.Submit

  const submitIdsFormWithTimeout = useCallback(() => {
    return setTimeout(() => submitIdsForm({ cart, userCanSubmitCart, idsFormFields }), 1000)
  }, [cart, idsFormFields, submitIdsForm, userCanSubmitCart])

  if (!cart) return null

  const showLoading =
    !hasNotCompletedCheckout && order.status === OrderStateAfterSubmit.Unset && !approval?.active
  const showIncompleteCheckout =
    hasNotCompletedCheckout && order.status === OrderStateAfterSubmit.Unset

  const isSessionCartCheckout = isSessionCart(cart)

  return (
    <div className={clsx(styles.container, isSessionCartCheckout && styles.withOverlay)}>
      {isSessionCartCheckout && createPortal(<div className={styles.overlay}></div>, document.body)}
      {order.status === OrderStateAfterSubmit.Pending && cart && cart.shoppingCartPrices && (
        <CheckoutOrderPending
          numberOfPositions={cart.items.length}
          netSum={cart.shoppingCartPrices.netSum}
          totalSum={cart.shoppingCartPrices.totalSum}
          shippingDate={cart.shippingData.shippingDate}
          type={cart.shippingData.shippingType}
          isSessionCartCheckout={isSessionCartCheckout}
        />
      )}
      {order.status === OrderStateAfterSubmit.Error &&
        order.response?.type === 'id-missing-in-response' && (
          <CheckoutOrderFailure reason={order.response.value} />
        )}
      {order.status === OrderStateAfterSubmit.Success && order.response?.type === 'success' && (
        <CheckoutOrderSuccess
          orderId={order.response.value.orderId}
          date={order.response.value.date}
          time={order.response.value.time}
          pickupNumber={order.response.value.pickupNumber}
          shippingType={order.response.value.shippingType}
          storeName={order.response.value.storeName}
          isSessionCartCheckout={isSessionCartCheckout}
          onSuccess={submitIdsFormWithTimeout}
          shopPromotionEmail={order.response.value.shopPromotionEmail ?? ''}
          shopPromotionId={order.response.value.shopPromotionId ?? ''}
        />
      )}
      {approval?.active && (
        <CheckoutOrderApproval
          emailAddress={approval.email}
          onPrintCart={() => onPrintCart(cart)}
          isSessionCartCheckout={isSessionCartCheckout}
          onSuccess={submitIdsFormWithTimeout}
        />
      )}
      {showIncompleteCheckout && <IncompleteCheckout />}
      {showLoading && <CheckoutOrderPending placeholder withOverlay />}
    </div>
  )
}
