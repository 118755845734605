import { Skeleton } from '@mui/material'
import styles from './EtimSkeletons.module.scss'

const SKELETONS_COUNT = 6

export const renderSkeletons = (count = SKELETONS_COUNT, height = 65, width?: number | string) => {
  return new Array(count).fill(null).map((_, idx) => {
    return <Skeleton width={width} height={height} key={idx} />
  })
}

export const EtimSkeletons: React.FC<{ height?: number }> = (props) => {
  const { height = 65 } = props
  return <div className={styles.root}>{renderSkeletons(SKELETONS_COUNT, height)}</div>
}
