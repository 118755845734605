import React from 'react'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { ApolloProvider } from '@apollo/client'
import { useTeasersDetails } from '@obeta/data/lib/hooks/useTeasersDetails'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { CustomEditor, EPlugins } from '../reactPageEditor/CustomEditor'
import styles from './TeaserDetailsPage.module.scss'

export const TeaserDetailsPage: React.FC = () => {
  const apolloClient = useStrapiGql()
  return (
    <ApolloProvider client={apolloClient}>
      <TeaserDetailsPageView />
    </ApolloProvider>
  )
}

const TeaserDetailsPageView: React.FC = () => {
  const { teaserId } = useParams<{ teaserId: string }>()
  const teasersDetails = useTeasersDetails(teaserId)

  if (!teasersDetails || !('content' in teasersDetails.newsType)) return null

  return (
    <div className={styles['teaser-wrapper']}>
      <CustomEditor
        value={teasersDetails.newsType.content}
        pluginsList={[
          EPlugins.RichText,
          EPlugins.Image,
          EPlugins.Video,
          EPlugins.Spacer,
          EPlugins.PDFLayout,
          EPlugins.ProductCardLayoutPlugin,
        ]}
      />
    </div>
  )
}
