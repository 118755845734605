import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { ReactComponent as CheckCircleIcon } from '@obeta/assets/icon/designsystem/check_circle.svg'
import { GET_OBETA_PROMOTION_QUERY } from '@obeta/data/lib/queries/promotion'
import styles from './CheckoutPromotionInfo.module.scss'
import { Typography } from '@mui/material'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { calculateProgress } from '@obeta/utils/lib/promotion'
import { IPromotionData } from '@obeta/models/lib/models/Promotion/Promotion'

type CheckoutPromotionInfoProps = {
  activeCart: ShoppingCartV2
  promotionId: string
}

export const CheckoutPromotionInfo: FC<CheckoutPromotionInfoProps> = (props) => {
  const { activeCart, promotionId } = props

  const client = useApolloClient()
  const { t } = useTranslation()

  const [promotionData, setPromotionData] = useState<IPromotionData | undefined>(undefined)

  // Calculate difference between promotion goal and promotion article sum to display promotion name
  const sumDifference = useMemo(() => {
    const promotionItems = activeCart.items.filter((article) => article.isPromotionItem)
    let promotionProgress = 0
    let sumDifference = 1
    if (promotionData && promotionData.benefits?.length > 0) {
      promotionProgress = calculateProgress(promotionItems, promotionData?.criteriaType)
      sumDifference = promotionData.benefits[0]?.goalToReach - promotionProgress
      return sumDifference
    }
  }, [activeCart, promotionData])

  useEffect(() => {
    const getObetaPromotion = async () => {
      const response = await client.query({
        query: GET_OBETA_PROMOTION_QUERY,
        variables: { id: promotionId },
      })

      const data = response.data.obetaPromotion.data.attributes
      data && setPromotionData(data)
    }

    getObetaPromotion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId])

  if (!promotionData) return null
  if (sumDifference && sumDifference >= 0) return null

  return (
    <div className={styles.container}>
      <div className={styles.green}>
        <CheckCircleIcon />
      </div>
      <div className={styles.content}>
        <Typography className={styles.green} variant="bodyBold">
          {`${t('PROMOTIONS.EXTRA')}: ${promotionData.benefits[0].name}`}
        </Typography>
        <Typography className={styles.contentDelivery} variant="body">
          {t('PROMOTIONS.SEPARATE_DELIVERY')}
        </Typography>
      </div>
    </div>
  )
}
