import React from 'react'
import { ArticleCarouselCard, ShopProductCard } from '@obeta/components/lib/product-card'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import styles from './CustomSearchLayoutView.module.scss'
import { ProductsSwiper } from '../../articleDetailsPage/ProductsSwiper'
import { useArticleSearch } from '@obeta/data/lib/hooks/useArticleSearch'
import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'

type Props = { searchParams?: ArticleSearchParamsWithId }

export const CustomSearchLayoutView: React.FC<Props> = (props) => {
  const { searchParams } = props
  const { user, isLoggedIn } = useUserDataV2()
  const { selectedStore: store } = useUserSelectedStore()
  const { mobile, tablet, tabletAll } = useBreakpoints()
  const { carts, activeCart } = useShoppingCartsV2()

  const requestParams = {
    itemsPerPage: 10,
    page: 0,
    searchParams,
  }
  const data = useArticleSearch(requestParams)

  if (!data.searchResult.length) return null

  if (data.searchResult.length === 1) {
    return (
      <ShopProductCard
        key={data.searchResult[0].sapId}
        product={data.searchResult[0]}
        layout={`horizontal`}
        selectedStore={store}
        user={user}
        tablet={tabletAll}
        mobile={mobile}
        withListPrice={false}
        isLoggedIn={isLoggedIn}
        carts={carts}
        selectedCart={activeCart}
      />
    )
  }

  let elementsPerRow: number | 'auto' = 4
  if (mobile) {
    elementsPerRow = 'auto'
  } else if (tablet) {
    elementsPerRow = 2
  }

  return (
    <div>
      <ProductsSwiper
        withNavigateButtons={!mobile}
        cardWrapperClassName={styles.cardWrapper}
        elementsPerRow={elementsPerRow}
        productElements={data.searchResult.map((cartItem) => (
          <ArticleCarouselCard
            key={cartItem?.sapId}
            product={cartItem}
            selectedStore={store}
            user={user}
            tablet={tabletAll}
            mobile={mobile}
            isLoggedIn={isLoggedIn}
            carts={carts}
            selectedCart={activeCart}
          />
        ))}
      />
    </div>
  )
}
