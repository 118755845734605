import React from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as Close } from 'assets/icon/close_3.svg'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useShoppingCartById } from '@obeta/data/lib/hooks/useShoppingCartById'
import { updateCartGraphQL } from '@obeta/data/lib/actions/cart-actions'

export const DetachPromotionButton: React.FC = () => {
  const dispatch = useDispatch()
  const { cartId } = useParams<{ cartId: string }>()
  const cart = useShoppingCartById(cartId)

  const handleDetachPromotion = () => {
    if (!cart) return

    dispatch(updateCartGraphQL({ ...cart, promotionId: '' }))
  }

  return <Close onClick={handleDetachPromotion} />
}
