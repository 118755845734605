import { getURLSearchParamsByLocationSearch } from './getURLSearchParamsByLocationSearch'

export const getURLSearchParamsValue = <T, U>(
  key: string,
  search: string,
  defaultValues: T
): string | undefined => {
  let params: undefined | U = undefined
  if (search) {
    params = getURLSearchParamsByLocationSearch<U>(search)
    return params[key] ?? defaultValues[key]
  }
  return defaultValues[key]
}
