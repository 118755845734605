import React, { useCallback, useEffect, useState } from 'react'
import orderPendingStyles from './CheckoutOrderPending.module.scss'
import styles from './CheckoutOrderSuccess.module.scss'
import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import { ReactComponent as DownloadDoneIcon } from 'assets/icon/designsystem/download_done.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import { ContinueShopping } from './ContinueShopping'
import { printOrder } from '@obeta/utils/lib/printPdf'
import { OrderCreatedPromotionForm } from './OrderCreatedPromotionForm'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { GET_OBETA_PROMOTION_QUERY } from '@obeta/data/lib/queries/promotion'
import { IPromotionData } from '@obeta/models/lib/models/Promotion/Promotion'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { trackClick } from '@obeta/utils/lib/tracking'

interface Props {
  orderId: string
  date: string
  pickupNumber: string
  time: string
  shippingType: ShippingType
  storeName: string
  isSessionCartCheckout?: boolean
  onSuccess?: () => void
  shopPromotionEmail: string
  shopPromotionId: string
}

export const CheckoutOrderSuccess: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { tokens, user } = useUserDataV2()
  const {
    orderId,
    date,
    pickupNumber,
    shippingType,
    storeName,
    time,
    isSessionCartCheckout,
    onSuccess,
    shopPromotionId,
    shopPromotionEmail,
  } = props

  const client = useStrapiGql()
  const { desktop } = useBreakpoints()
  const history = useHistory()
  const isUseCreatedOrderPromotionForm = useFeatureToggle('UseCreatedOrderPromotionForm')

  const showGiveAwayForm = isUseCreatedOrderPromotionForm && desktop && shopPromotionId !== ''

  const [orderWithPriceAlreadyDownloaded, setOrderWithPriceAlreadyDownloaded] = useState(false)
  const [orderWithoutPriceAlreadyDownloaded, setOrderWithoutPriceAlreadyDownloaded] =
    useState(false)
  const [promotionData, setPromotionData] = useState<IPromotionData | undefined>(undefined)

  // Fetch promotion data from Strapi
  const getPromotion = useCallback(async () => {
    const response = await client.query({
      query: GET_OBETA_PROMOTION_QUERY,
      variables: { id: shopPromotionId },
    })
    setPromotionData(response.data.obetaPromotion.data.attributes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopPromotionId])

  useEffect(() => {
    getPromotion()
  }, [getPromotion])

  const SEND_MAIL_API_ROUTE = '/api/sendmail'

  useEffect(() => {
    if (onSuccess) onSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickDownloadOrder = (orderId: string, downloadOrderWithPrice = true) => {
    const notificationData = {
      id: t('PRINT.ORDER_EVENT_ID', { orderId }),
      heading: t('PRINT.ORDER_PDF_HEADING'),
      body: t('PRINT.ORDER_PDF_BODY', { orderId }),
    }
    printOrder({
      orderId: orderId,
      withPrice: downloadOrderWithPrice,
      accessToken: tokens?.accessToken,
      orderName: 'Auftrag',
      notificationData: notificationData,
    })

    downloadOrderWithPrice && setOrderWithPriceAlreadyDownloaded(true)
    !downloadOrderWithPrice && setOrderWithoutPriceAlreadyDownloaded(true)
  }

  const onPromotionSubmit = (enteredName: string, address: string) => {
    // Render user notification
    getEventSubscription().next({
      type: EventType.Data,
      notificationType: NotificationType.Success,
      id: 'promotion-address-form-submitted',
      options: { heading: t('SHOPPING_CART.PROMOTION.SUCCESS_NOTIFICATION'), body: '' },
    })

    // Send email
    const data = {
      to: shopPromotionEmail,
      subject: `Aktionswarenkorb ${promotionData?.benefits[0].name} Zugabendetails ${user?.customerNumber}`,
      text: t('ORDERS.CONSTRUCTION_DOCUMENTATION.EMAIL', {
        customerId: user?.customerNumber,
        promotionName: promotionData?.benefits[0].name,
        orderId,
        benefit: promotionData?.name,
        enteredName,
        address,
      }),
      host: process.env.NEXT_PUBLIC_MAILGUN_HOST,
      port: process.env.NEXT_PUBLIC_MAILGUN_PORT,
    }

    fetch(SEND_MAIL_API_ROUTE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => response.json())
      .catch((error) => console.error('Error:', error))

    // Log action in Datadog
    trackClick('promotion-address-form-submitted', {
      orderId: orderId,
    })

    // Close modal by redirecting to start page
    history.push(ShopRoutes.Root)
  }

  let dynamicHeightClass =
    shippingType === ShippingType.ExpressPickup
      ? orderPendingStyles.mainContainerHeightXpress
      : orderPendingStyles.mainContainerHeight

  if (showGiveAwayForm) {
    dynamicHeightClass = orderPendingStyles.unsetFixedHeight
  }

  return (
    <Grid
      container
      direction="row"
      className={clsx(orderPendingStyles.mainContainer, dynamicHeightClass)}
    >
      <Grid item xs={12}>
        <div className={orderPendingStyles.animationMainContainer}>
          <div className={styles.circleLoader}>
            <div className={styles.checkMark} />
          </div>
        </div>
        <Typography variant={'h3'} className={styles.headerText}>
          {t('SHOPPING_CART.THANK_YOU_FOR_YOUR_PURCHASE')}
        </Typography>
        <div
          className={clsx(
            styles.textLine2,
            shippingType === ShippingType.ExpressPickup && styles.marginsForTextLine2,
            styles.marginBottomOneAndHalfRem
          )}
        >
          <Typography variant={'boldText'}>
            {shippingType === ShippingType.ExpressPickup
              ? t('SHOPPING_CART.CHECKOUT.ORDER_WILL_BE_PROCESSED_XPRESS', { storeName: storeName })
              : t('SHOPPING_CART.CHECKOUT.ORDER_WILL_BE_PROCESSED')}
          </Typography>
        </div>
        <div className={clsx(styles.textLine2, styles.marginBottomHalfRem)}>
          <Typography variant={'boldText'}>
            {t('SHOPPING_CART.CHECKOUT.SENT_ORDER_DETAILS')}
          </Typography>
        </div>
        {shippingType === ShippingType.ExpressPickup && (
          <Box className={styles.dataBox}>
            <Typography variant={'body'} className={styles.marginRightHalfRem}>
              {t('SHOPPING_CART.CHECKOUT.NUMBER_ABBREVIATED')}.:
            </Typography>
            <Typography variant={'bodyBold'} className={styles.marginRightOneAndHalfRem}>
              {pickupNumber}
            </Typography>
            <Typography variant={'body'} className={styles.marginRightHalfRem}>
              {t('SHOPPING_CART.CHECKOUT.PICKUP')}:
            </Typography>
            <Typography variant={'bodyBold'}>
              {t('SHOPPING_CART.CHECKOUT.EXPRESS_COLLECTION_TIME')}
            </Typography>
          </Box>
        )}
        {shippingType !== ShippingType.ExpressPickup && (
          <Box className={styles.dataBox}>
            <Typography variant={'body'} className={styles.marginRightHalfRem}>
              {t('SHOPPING_CART.CHECKOUT.NUMBER_ABBREVIATED')}.:
            </Typography>
            <Typography variant={'bodyBold'} className={styles.marginRightOneAndHalfRem}>
              {orderId}
            </Typography>
            <Typography variant={'body'} className={styles.marginRightHalfRem}>
              {t('SHOPPING_CART.CHECKOUT.DATE')}:
            </Typography>
            <Typography variant={'bodyBold'} className={styles.marginRightOneAndHalfRem}>
              {date}
            </Typography>
            <Typography variant={'body'} className={styles.marginRightHalfRem}>
              {t('SHOPPING_CART.CHECKOUT.TIME')}
            </Typography>
            <Typography variant={'bodyBold'}>{time}</Typography>
          </Box>
        )}
        {shippingType !== ShippingType.ExpressPickup && !isSessionCartCheckout && (
          <Box className={styles.saveButtonsBox}>
            {user?.permissions?.Global_canReadPrices === PricePermissions.PurchasePrice && (
              <Button
                fullWidth={false}
                variant="outlined"
                onClick={() => onClickDownloadOrder(orderId)}
                className={styles.saveButton}
                startIcon={
                  <SvgIcon
                    component={orderWithPriceAlreadyDownloaded ? DownloadDoneIcon : DownloadIcon}
                  />
                }
              >
                {t('SHOPPING_CART.ACTIONS.SAVE_ORDER_WITH_PRICES')}
              </Button>
            )}
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={() => onClickDownloadOrder(orderId, false)}
              className={
                user?.permissions?.Global_canReadPrices === PricePermissions.PurchasePrice
                  ? styles.saveButton
                  : styles.fullWidthButton
              }
              startIcon={
                <SvgIcon
                  component={orderWithoutPriceAlreadyDownloaded ? DownloadDoneIcon : DownloadIcon}
                />
              }
            >
              {t('SHOPPING_CART.ACTIONS.SAVE_ORDER_WITHOUT_PRICES')}
            </Button>
          </Box>
        )}
        {!isSessionCartCheckout && !showGiveAwayForm && <ContinueShopping />}
        {showGiveAwayForm && (
          <OrderCreatedPromotionForm
            onSubmit={onPromotionSubmit}
            orderId={orderId}
            promotionType={promotionData?.promotionType ?? 'shop'}
          />
        )}
      </Grid>
    </Grid>
  )
}
