import { ButtonBase } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import styles from './ActionButton.module.scss'

interface IActionButton {
  type?: 'default' | 'underlined'
  noPaddingLeft?: boolean
  noPaddingTop?: boolean
  onClick?: () => void
}

export const ActionButton: React.FC<IActionButton> = (props) => {
  const type = props.type || 'default'

  return (
    <ButtonBase
      className={clsx(
        styles.button,
        styles[`button--${type}`],
        props.noPaddingLeft && styles['button--no-padding-left'],
        props.noPaddingTop && styles['button--no-padding-top']
      )}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonBase>
  )
}
