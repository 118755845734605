import {
  MultiSelectionNumber,
  OfferForDropdown,
  ShoppingCartForDropdown,
  ShoppingCartV2,
} from '@obeta/models/lib/models'
import { Grid, PopoverOrigin } from '@mui/material'
import clsx from 'clsx'
import styles from './OfferDetailsSelectionBar.module.scss'
import { OfferV3 } from '@obeta/schema'
import {
  addOrReplaceOfferInCartGraphQL,
  AddOrReplaceOfferInCartGraphQLResultAction,
  CartsActionTypes,
} from '@obeta/data/lib/actions'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'
import { ConfirmWithOfferSelect } from '../alert-and-confirm/ConfirmWithOfferSelect'
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { PrimaryButton } from '../custom-button/CustomButton'
import { SplitButton } from '../split-button/SplitButton'
import { SelectionBar } from '../selection-bar/SelectionBar'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useDispatch } from 'react-redux'
import { useActionNotification, useSelectShoppingCart } from '@obeta/data/lib/hooks'
import { useOfferDetailsContext } from '@obeta/data/lib/stores/useOfferDetailsContext'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import React, { useRef, useState } from 'react'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { DropdownTemplates } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { IdsTransferActionButton } from '../ids/IdsTransferActionButton'
import { useIdsBulkTransferBack } from '@obeta/data/lib/hooks/ids/useIdsBulkTransferBack'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { AnimatedCartButton } from '../add-items-to-cart-button/AnimatedCartButton'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import {
  EButtonState,
  useAnimatedButtonState,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'

interface Props {
  dropDownCarts: ShoppingCartForDropdown[]
  modifiedOfferItemAmounts: { offerItemPosition: number; amount: number }[]
  offer: OfferV3 | null
  resetSelectedOffers: () => void
  selectedOfferItems: MultiSelectionNumber
}

export const OfferDetailsSelectionBar: React.FC<Props> = ({
  dropDownCarts,
  modifiedOfferItemAmounts,
  offer,
  resetSelectedOffers,
  selectedOfferItems,
}) => {
  const { desktop, mobile, tabletAll } = useBreakpoints()
  const cartsV2 = useCartsv2WithPricesAndStock()
  const dispatch = useDispatch()
  const splitButtonRef = useRef<HTMLDivElement>(null)
  const popoverState = usePopoverState(splitButtonRef)
  const onSetActiveCart = useSelectShoppingCart()

  const [cartIdToShowConflictFor, setCartIdToShowConflictFor] = useState<string>('')
  const [offersForDropdown, setOffersForDropdown] = useState<OfferForDropdown[]>([])
  const [showOfferConflictDialog, setShowOfferConflictDialog] = useState(false)
  const waitForAction = useActionNotification(CartsActionTypes.AddOrReplaceOfferInCartGraphQLResult)
  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

  const bulkTransferIds = useIdsBulkTransferBack('offer')

  const userV2 = useUserV2()

  const defaultCartId = userV2?.settings?.defaultCartId || ''
  const defaultCart = cartsV2.find((cart) => cart.id === defaultCartId)

  const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
  const transformOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

  const showIdsTransferBackButton = useIsIdsTransferBackPossible()

  const { offerItems } = useOfferDetailsContext()
  const { getSessionCartName, isSessionCart } = useSessionCartName()
  const getSelectedOffersLength = () => {
    // SELECT ALL W/ OR W/OUT EXCLUDE
    if (selectedOfferItems.selectAll) {
      if (selectedOfferItems.search.searchTerm || selectedOfferItems.search.filter) {
        if (selectedOfferItems.exclude.length) {
          if (!offerItems?.length) return 0
          return offerItems?.length - selectedOfferItems.exclude.length || 0
        }
        return offerItems.length
      } else {
        if (selectedOfferItems.exclude.length) {
          if (!offer?.itemCount) return 0
          return offer?.itemCount - selectedOfferItems.exclude.length || 0
        }
        return offer?.itemCount ?? 0
      }
    }
    // INCLUDE ONLY
    if (selectedOfferItems.include) {
      return selectedOfferItems.include.length
    }
  }

  function handleAddToCart(
    cart: ShoppingCartV2 | ShoppingCartForDropdown | undefined,
    cartId: string
  ) {
    // resolve offer conflict first
    if (cart?.offerId !== '' && cart?.offerId !== offer?.offerId) {
      const name =
        dropDownCarts.find((dropDownCart) => dropDownCart.id === cart?.id)?.offerName || ''
      setOffersForDropdown([
        { id: cart?.offerId ?? '', name: name },
        { id: offer?.offerId ?? '', name: offer?.offerName ?? '' },
      ])
      setCartIdToShowConflictFor(cart?.id ?? '')
      setShowOfferConflictDialog(true)
    }

    // no offer conflict, proceed
    else {
      addMultipleOfferItemsToCart(cartId, true)
    }
  }
  const { setAnimationState: setButtonState, animationState } = useAnimatedButtonState()

  const selectedOffersLength = getSelectedOffersLength()
  const addMultipleOfferItemsToCart = (cartId: string, overwrite: boolean) => {
    if (offer) {
      if (isAddToCartProcessIndicator) {
        setButtonState(EButtonState.Loading)
      }
      dispatch(
        addOrReplaceOfferInCartGraphQL(
          offer.offerId as string,
          offer.offerName as string,
          selectedOffersLength ?? 0,
          cartId,
          overwrite,
          selectedOfferItems.include,
          selectedOfferItems.exclude,
          modifiedOfferItemAmounts,
          selectedOfferItems.search
        )
      )
      if (isAddToCartProcessIndicator) {
        waitForAction((action: AddOrReplaceOfferInCartGraphQLResultAction) => {
          setButtonState(action.success ? EButtonState.Success : EButtonState.Error)
          resetSelectedOffers()
        })
      } else {
        // deselect all offer items
        resetSelectedOffers()
      }
      onSetActiveCart(cartId)
    }
  }

  const handleOfferConflictDialogCancelled = () => {
    setShowOfferConflictDialog(false)
    setCartIdToShowConflictFor('')
  }
  const handleOfferConflictDialogConfirmed = (chosenOffer) => {
    if (offer) {
      // overwrite is only ever false if chosen offer is not the displayed offer
      addMultipleOfferItemsToCart(cartIdToShowConflictFor, chosenOffer.id === offer.offerId)
    }
    setShowOfferConflictDialog(false)
  }
  let cartName = '-'
  if (defaultCart) {
    cartName = isSessionCart(defaultCart) ? getSessionCartName(defaultCart) : defaultCart.name
  }

  return (
    <SelectionBar portalKey="offer-details-selection-bar">
      <Grid className={styles.selectionBar} container>
        <Grid className={styles.selectionBarItem} item xs={6}>
          <DropdownTemplates
            anchorOrigin={anchorOrigin}
            desktop={desktop}
            disabled={(selectedOffersLength as number) <= 0}
            mobile={mobile}
            offer={{
              offerId: offer?.offerId ?? '0',
              offerName: offer?.offerName ?? '',
              multiSelection: {
                selectAll: selectedOfferItems.selectAll,
                include: selectedOfferItems.include,
                exclude: selectedOfferItems.exclude,
                search: selectedOfferItems.search,
              },
              modifiedOfferItemAmounts,
              selectedItemCount: (selectedOffersLength as number) ?? 0,
            }}
            productsToAdd={[
              {
                productId: '',
                amount: 0,
              },
            ]}
            selectedArticleCount={selectedOffersLength}
            templatesType={DropdownTemplatesType.ADD_OFFER}
            transformOrigin={transformOrigin}
          />
        </Grid>
        <Grid className={clsx(styles.selectionBarItem, styles.selectionBarCart)} item xs={6}>
          {mobile &&
            (isAddToCartProcessIndicator ? (
              <AnimatedCartButton
                disabled={!selectedOffersLength}
                buttonState={animationState}
                onClick={() => handleAddToCart(defaultCart, defaultCartId)}
              />
            ) : (
              <PrimaryButton
                disabled={!selectedOffersLength}
                leftIcon={<AddShoppingCartIcon />}
                onClick={() => {
                  handleAddToCart(defaultCart, defaultCartId)
                }}
              />
            ))}
          {(tabletAll || desktop) && (
            <>
              {showIdsTransferBackButton && offer && (
                <IdsTransferActionButton
                  onClick={() =>
                    bulkTransferIds({
                      offerId: offer.offerId,
                      search: selectedOfferItems.search,
                      amount: modifiedOfferItemAmounts,
                      include: selectedOfferItems.include,
                      exclude: selectedOfferItems.exclude,
                    })
                  }
                  amountOfProducts={selectedOffersLength}
                  disabled={selectedOffersLength === 0}
                  className={styles.idsButton}
                />
              )}
              {isAddToCartProcessIndicator ? (
                <AnimatedCartButton
                  ref={splitButtonRef}
                  disabled={!selectedOffersLength}
                  buttonState={animationState}
                  cartsLength={cartsV2.length}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => handleAddToCart(defaultCart, defaultCartId)}
                  size={mobile ? 'large' : 'small'}
                />
              ) : cartsV2.length > 1 ? (
                <SplitButton
                  disabled={!selectedOffersLength}
                  loading={false}
                  size={mobile ? 'large' : 'small'}
                  title={cartName}
                  onArrowDownClicked={popoverState.handleClick}
                  onClick={() => {
                    handleAddToCart(defaultCart, defaultCartId)
                  }}
                />
              ) : (
                <PrimaryButton
                  disabled={!selectedOffersLength}
                  leftIcon={<AddShoppingCartIcon />}
                  size={mobile ? 'large' : 'small'}
                  onClick={() => handleAddToCart(defaultCart, defaultCartId)}
                >
                  {cartName}
                </PrimaryButton>
              )}
              <DropdownCartsBase
                carts={dropDownCarts}
                mobile={mobile}
                onOptionSelected={(cart) => {
                  handleAddToCart(cart, cart.id)
                }}
                dropdown={{
                  anchorEl: mobile ? null : popoverState.anchorEl,
                  onClose: popoverState.onClose,
                  open: popoverState.open,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <ConfirmWithOfferSelect
        handleConfirm={handleOfferConflictDialogConfirmed}
        handleCancel={handleOfferConflictDialogCancelled}
        offersForSelect={offersForDropdown}
        openDialog={showOfferConflictDialog}
      />
    </SelectionBar>
  )
}
