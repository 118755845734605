import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { SectionHeader } from './SectionHeader'
import { SectionWrapper } from './SectionProvider'
import styles from './CatalogInfo.module.scss'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CategoryIcon } from '@obeta/components/lib/categories/Categories'
import { constructCatId } from '@obeta/utils/lib/categories'
import {
  CatalogInfo as CatalogInfoModel,
  Category,
} from '@obeta/models/lib/models/Article/Shop/Product'
import { ObetaCategories } from './BaseCategories'
import clsx from 'clsx'

interface ICatalogInfo {
  available: boolean
  catalogInfo?: CatalogInfoModel
  obeta: Category[] | undefined
  isCategoriesVisible: boolean
  titleClassName?: string
}

export const CatalogInfo = (props: ICatalogInfo) => {
  const { titleClassName, available, catalogInfo, obeta, isCategoriesVisible } = props

  const { t } = useTranslation()
  return (
    <SectionWrapper
      available={available}
      id={ArticleDetailsSections.StockAssortmentCatalog}
      className={styles.categoriesWrap}
    >
      <Box className={clsx(titleClassName)}>
        <div className={clsx(styles.catalogTitle)}>
          {!isCategoriesVisible && obeta && obeta.length > 0 && (
            <CategoryIcon catId={constructCatId(obeta[0].id)} size="md" imgSize="xs" />
          )}
          <SectionHeader>
            {`${t<string>('ARTICLE_DETAIL.STOCK_RANGE_CATALOG')} ${catalogInfo?.year1}/${
              catalogInfo?.year2
            }`}
          </SectionHeader>
          <Typography className={styles.page}>{`(${t<string>('ARTICLE_DETAIL.PAGE')} ${
            catalogInfo?.page
          })`}</Typography>
        </div>
      </Box>

      {isCategoriesVisible && (
        <>
          <LightGrayDivider />
          <ObetaCategories categories={obeta} />
        </>
      )}
    </SectionWrapper>
  )
}
