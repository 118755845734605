import { useEffect, useState } from 'react'

export const useCookiebotAcceptAndDeclineHandler = (): { statisticsCookiesAllowed: boolean } => {
  const [statisticsCookiesAllowed, setStatisticsCookiesAllowed] = useState(false)

  useEffect(
    () => {
      const onAcceptStatisticsCookies = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        //explicitly looking for false to exclude local env where there is no Cookiebot
        if (window?.Cookiebot?.consent?.statistics) setStatisticsCookiesAllowed(true)
      }
      const onDeclineStatisticsCookies = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        //explicitly looking for false to exclude local env where there is no Cookiebot
        if (!window?.Cookiebot?.consent?.statistics) setStatisticsCookiesAllowed(false)
      }

      window?.addEventListener('CookiebotOnAccept', onAcceptStatisticsCookies, false)
      window?.addEventListener('CookiebotOnDecline', onDeclineStatisticsCookies, false)
      onAcceptStatisticsCookies()

      return () => {
        window.removeEventListener('CookiebotOnAccept', onAcceptStatisticsCookies)
        window.removeEventListener('CookiebotOnDecline', onDeclineStatisticsCookies)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    statisticsCookiesAllowed,
  }
}
