import { useCallback } from 'react'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'

export const useOpenPositionsFilters = () => {
  const filterOverdueItems = useCallback(
    (items: OpenPosition[]) => items.filter((item) => item.isOverdue),
    []
  )
  const filterDiscountableItems = useCallback(
    (items: OpenPosition[]) => items.filter((item) => item.isDiscount),
    []
  )
  const filterCreditItems = useCallback(
    (items: OpenPosition[]) => items.filter((item) => item.receiptType === 'Credit'),
    []
  )

  return {
    filterOverdueItems,
    filterDiscountableItems,
    filterCreditItems,
  }
}
