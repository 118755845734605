import React from 'react'
import { CSSTransition } from 'react-transition-group'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import styles from './Screen.module.scss'
import clsx from 'clsx'
import { useStackNavigatorContext } from './context'

const transitionTimeout = parseInt(scssVariables.slideTransitionTimeout)

export interface IScreenProps {
  name: string
  unmountOnExit?: boolean
  className?: string
}

export const StackScreen: React.FC<IScreenProps> = (props) => {
  const { name, className, unmountOnExit = true, children } = props

  const context = useStackNavigatorContext()

  const isRendered = context.state.stack.includes(name)
  const isActive = context.state.name === name

  if (!isRendered) return null

  // Be careful with class names.
  // CSSTransition have issues when className updates dynamically
  // (both in children components and CSSTransition component)
  // Unfortunately issue is still open.
  // https://github.com/reactjs/react-transition-group/issues/318
  return (
    <CSSTransition
      timeout={transitionTimeout}
      in={isActive}
      classNames={context.state.direction}
      mountOnEnter={false}
      unmountOnExit={unmountOnExit}
    >
      {/**
       * TODO: these styles should be part of SlideTransition component
       */}
      <div className={clsx(styles.page, className)}>{children}</div>
    </CSSTransition>
  )
}
