import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { SectionHeader } from './SectionHeader'
import { SectionWrapper } from './SectionProvider'
import styles from './CatalogInfo.module.scss'
import { useTranslation } from 'react-i18next'
import { Category } from '@obeta/models/lib/models/Article/Shop/Product'
import { DehaCategories } from './BaseCategories'
import clsx from 'clsx'
// import { ObetaCategories } from './BaseCategories'

interface IOnlineCategorieInfo {
  deha: Category[] | undefined
  titleClassName?: string
}

export const OnlineCategoryInfo = (props: IOnlineCategorieInfo) => {
  const { deha, titleClassName } = props
  const { t } = useTranslation()

  return (
    <SectionWrapper
      available={Boolean(deha?.length)}
      id={ArticleDetailsSections.Category}
      className={styles.categoriesWrap}
    >
      <SectionHeader className={clsx(titleClassName)}>
        {t('ARTICLE_DETAIL.ONLINE_CATEGORY')}
      </SectionHeader>
      <LightGrayDivider />
      <DehaCategories categories={deha} />
    </SectionWrapper>
  )
}
