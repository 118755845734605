import { FC, useEffect, useState } from 'react'
import { FilterButton, FilterButtonProps } from '@obeta/components/lib/filter-button/FilterButton'
import styles from './OpenPositionFilterBar.module.scss'
import { useTranslation } from 'react-i18next'
import { useMyAccountingContext } from '@obeta/data/lib/stores/useMyAccountingContext'
import { OpenPosition } from '@obeta/schema'
import { useOpenPositionsFilters } from '@obeta/data/lib/hooks/my-accounting/useOpenPositionsFilters'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Swiper, SwiperSlide } from 'swiper/react'
import '@obeta/assets/theme/swiper.scss'

const OpenPositionFilterButton: FC<FilterButtonProps> = (props) => {
  return (
    <FilterButton {...props} className={styles.filterButton}>
      {props.children}
    </FilterButton>
  )
}

type Props = { items: OpenPosition[] }
export const OpenPositionFilterBar: FC<Props> = ({ items }) => {
  const { t } = useTranslation()
  const { filter, setFilter } = useMyAccountingContext()
  const { filterOverdueItems, filterCreditItems, filterDiscountableItems } =
    useOpenPositionsFilters()
  const { desktop } = useBreakpoints()

  const [watchOverflow, setWatchOverflow] = useState(desktop)

  const amountOfOverdueItems = filterOverdueItems(items).length
  const amountOfDiscountableItems = filterDiscountableItems(items).length
  const amountOfCreditItems = filterCreditItems(items).length

  useEffect(() => {
    if (watchOverflow !== desktop) setWatchOverflow(desktop)
  }, [desktop, watchOverflow])

  return (
    <div>
      <Swiper
        key={String(watchOverflow)}
        watchOverflow={watchOverflow}
        direction="horizontal"
        slidesPerView={'auto'}
        scrollbar={false}
        spaceBetween={16}
        resizeObserver={true}
        className={styles.filterBar}
      >
        <SwiperSlide className={styles.crumb} key="all">
          <OpenPositionFilterButton
            disabled={items.length === 0}
            active={filter === 'all' && items.length !== 0}
            onClick={() => setFilter('all')}
          >
            {t('MY_ACCOUNTING.FILTER_ALL', { count: items.length })}
          </OpenPositionFilterButton>
        </SwiperSlide>
        <SwiperSlide className={styles.crumb} key="overdue">
          <OpenPositionFilterButton
            disabled={amountOfOverdueItems === 0}
            active={filter === 'overdue' && amountOfOverdueItems !== 0}
            onClick={() => setFilter('overdue')}
          >
            {t('MY_ACCOUNTING.FILTER_OVERDUE', { count: amountOfOverdueItems })}
          </OpenPositionFilterButton>
        </SwiperSlide>
        <SwiperSlide className={styles.crumb} key="discountable">
          <OpenPositionFilterButton
            disabled={amountOfDiscountableItems === 0}
            active={filter === 'discountable' && amountOfDiscountableItems !== 0}
            onClick={() => setFilter('discountable')}
          >
            {t('MY_ACCOUNTING.FILTER_DISCOUNTABLE', { count: amountOfDiscountableItems })}
          </OpenPositionFilterButton>
        </SwiperSlide>
        <SwiperSlide className={styles.crumb} key="credits">
          <OpenPositionFilterButton
            disabled={amountOfCreditItems === 0}
            active={filter === 'credits' && amountOfCreditItems !== 0}
            onClick={() => setFilter('credits')}
          >
            {t('MY_ACCOUNTING.FILTER_CREDITS', { count: amountOfCreditItems })}
          </OpenPositionFilterButton>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
