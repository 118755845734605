import { useOnlineStatus } from '@obeta/data/lib/hooks'
import { useMaintenance } from '../../hooks/useMaintenance'
import './OnlineStatusWarnings.scss'

export const OnlineStatusWarnings = () => {
  const isOnline = useOnlineStatus()
  const { maintenanceMessage } = useMaintenance()

  return (
    <>
      {!isOnline && (
        <div className="onlinestatus__warning">
          Offline: die Funktionen der App sind eingeschränkt.
        </div>
      )}
      {maintenanceMessage && (
        <div className="onlinestatus__warning">Wartung: {maintenanceMessage}</div>
      )}
    </>
  )
}
