import React, { useCallback, useContext, useLayoutEffect, useState } from 'react'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import Typography from '@mui/material/Typography/Typography'
import { validateContextValue } from '@obeta/utils/lib/validateContextValue'

type TypographyType = React.ComponentProps<typeof Typography>['variant']

/**
 * Stores state (enabled/disabled) of page sections.
 * This state allows you to display info about this ections
 * StickyHeader uses this states do display sections in header
 * Check story: libs\layouts\src\lib\articleDetailsPage\StickyHeader.stories.tsx
 * @param setVisibility - set state for section
 * @param isSectionVisible - check if state for section set to "enabled"
 * @param typographyHeaderVariant - variant used for section's header.
 */
interface ISectionApi {
  setVisibility: (id: ArticleDetailsSections, visible: boolean) => void
  isSectionVisible: (id: ArticleDetailsSections) => boolean
  typographyHeaderVariant?: TypographyType
}

const SectionContext = React.createContext<ISectionApi | null>(null)

export const useSection = () => {
  return validateContextValue(useContext(SectionContext), 'SectionContext', 'useSection')
}

export const SectionProvider: React.FC<{ typographyHeaderVariant?: TypographyType }> = (props) => {
  const { children, typographyHeaderVariant = 'headline3Bold' } = props

  const [sectionsAvailableSet, setSectionsAvailableSet] = useState<Set<ArticleDetailsSections>>(
    new Set()
  )

  const setVisibility = useCallback((id: ArticleDetailsSections, visible: boolean) => {
    setSectionsAvailableSet((base) => {
      const newSet = new Set(base)
      if (visible) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }

      return newSet
    })
  }, [])

  const isSectionVisible = (id: ArticleDetailsSections) => {
    return sectionsAvailableSet.has(id)
  }

  return (
    <SectionContext.Provider value={{ setVisibility, isSectionVisible, typographyHeaderVariant }}>
      {children}
    </SectionContext.Provider>
  )
}

export const SectionWrapper: React.FC<{
  id: ArticleDetailsSections
  available: boolean
  className?: string
}> = (props) => {
  const { id, available, className, children } = props
  const { setVisibility } = useSection()

  useLayoutEffect(() => {
    setVisibility(id, available)
  }, [available, id, setVisibility])

  if (!available) {
    return null
  }

  return (
    <div id={id} className={className}>
      {children}
    </div>
  )
}
