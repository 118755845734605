import React, { memo, useCallback } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ShoppingCartItem, ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'
import { CartItem } from '../product-card'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useReplacementArticle } from '@obeta/data/lib/hooks/useReplacementArticle'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import {
  addProductToCartGraphQL,
  AddCartContextEnum,
  AddProductToCartGraphQLActionResult,
  CartsActionTypes,
} from '@obeta/data/lib/actions'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import {
  useAnimatedButtonState,
  EButtonState,
  AnimatedButtonStateContext,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'
import { useDispatch } from 'react-redux'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { useActionNotification } from '@obeta/data/lib/hooks'

interface IDeadProductCard {
  cartItem: ShoppingCartItem
  userV2: UserV2 | null
  showReplacementSection?: boolean
  onDeleteClicked: (ShoppingCartItem, imminentAddToCartWillFollow?: boolean) => void

  deleteCartItems?: (items: ShoppingCartItem[]) => void
  activeCart?: ShoppingCartV2
}
export const DeadProductCard: React.FC<IDeadProductCard> = memo((props) => {
  const { cartItem, userV2, showReplacementSection, onDeleteClicked, deleteCartItems, activeCart } =
    props

  const { getSessionCartName, isSessionCart } = useSessionCartName()

  const activeCartName = activeCart
    ? isSessionCart(activeCart)
      ? getSessionCartName(activeCart)
      : activeCart.name
    : ''

  const { mobile, tabletAll } = useBreakpoints()
  const stocks = useStocksMap({
    isOnlineCurrentNotAvailable: false,
    stocks: cartItem.product.stock ? cartItem.product.stock ?? [] : undefined,
    user: userV2,
  })
  const { replacementArticleHref } = useReplacementArticle({
    replacementArticleSapId: cartItem.product.replacementArticleSapId,
    stocks: stocks,
  })
  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

  const animatedButtonStateVal = useAnimatedButtonState()
  const waitForAddProductToCartAction = useActionNotification(
    CartsActionTypes.AddProductToCartGraphQLResult
  )
  const { setAnimationState } = animatedButtonStateVal

  const dispatch = useDispatch()

  const onAddToCartDeadProductReplacement = useCallback(
    (items: ShoppingCartItem[], itemOfChoice?: ShoppingCartItem) => {
      if (!activeCart) return
      setAnimationState(EButtonState.Loading)
      items?.forEach((replacementItem) => {
        dispatch(
          addProductToCartGraphQL({
            cartId: activeCart.id,
            items: [
              {
                sapId: replacementItem.product.sapId,
                amount: replacementItem.amount,
                title: replacementItem.product.title,
              },
            ],
            context: AddCartContextEnum.Generic,
          })
        )
      })

      if (isAddToCartProcessIndicator) {
        waitForAddProductToCartAction((action: AddProductToCartGraphQLActionResult) => {
          setAnimationState(action.payload?.success ? EButtonState.Success : EButtonState.Error)
          if (itemOfChoice) {
            setTimeout(() => {
              onDeleteClicked(itemOfChoice, true)
            }, 250)
          }
        })
      } else if (itemOfChoice) {
        setTimeout(() => {
          onDeleteClicked(itemOfChoice, true)
        }, 250)
      }
    },
    [
      activeCart,
      dispatch,
      isAddToCartProcessIndicator,
      onDeleteClicked,
      setAnimationState,
      waitForAddProductToCartAction,
    ]
  )

  return (
    <AnimatedButtonStateContext.Provider value={animatedButtonStateVal}>
      <CartItem
        shoppingCartItem={cartItem}
        withCheckbox={false}
        changeProductAmount={() => {
          // Not applicable to this type of product card
        }}
        onDeleteClicked={onDeleteClicked}
        selectedStore={undefined}
        selected={false}
        user={userV2}
        isLoggedIn={true}
        mobile={mobile}
        tablet={tabletAll}
        productId={cartItem.product.sapId}
        deadProduct={true}
        replacementArticleHref={replacementArticleHref}
        showReplacementSection={!!(showReplacementSection && replacementArticleHref)}
        onAddToCartDeadProductReplacement={
          activeCart ? onAddToCartDeadProductReplacement : undefined
        }
        deleteCartItems={deleteCartItems}
        activeCartName={activeCartName}
        url={
          cartItem.product.images && cartItem.product.images?.length > 0
            ? cartItem.product.images[0].url
            : ''
        }
      />
    </AnimatedButtonStateContext.Provider>
  )
})
