import { useEffect, useMemo } from 'react'
import { useUserDataV2 } from './useUserDataV2'
import { useHistory } from './useHistoryApi'

const commonRoutes: RegExp[] = [
  /shopping-cart-list/,
  /shopping-cart-details(.*)/,
  /shopping-cart-checkout(.*)/,
]

const shoppingAppRoutes: RegExp[] = [...commonRoutes]

type AppType = 'shoppingApp'

export const useProtectedRoutes = (appType: AppType) => {
  const protectedRoutes = useMemo((): RegExp[] => {
    switch (appType) {
      case 'shoppingApp':
        return shoppingAppRoutes
      default:
        return []
    }
  }, [appType])

  const userData = useUserDataV2()
  const { userId, isLoggedIn, isTokensFetched } = userData

  const history = useHistory()

  useEffect(() => {
    const isRouteProtectedByAuth = protectedRoutes.some((route) =>
      history.location.pathname.match(route)
    )

    if (userId && isTokensFetched && !isLoggedIn && isRouteProtectedByAuth) {
      history.push('/') // TODO: should we use replace instead of push (???)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isLoggedIn, isTokensFetched, protectedRoutes])
}
