import { Typography } from '@mui/material'
import { IColumn, StripedTable } from '@obeta/components/lib/striped-table/StripedTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { ReactComponent as CompareArrowsIcon } from '@obeta/assets/icon/compare_arrows.svg'
import styles from './TechnicalSpecifications.module.scss'
import clsx from 'clsx'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { IArticle } from './types'
import { useArticleSearchFilter } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import { EtimData, ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { SectionWrapper } from './SectionProvider'
import { mapEtim } from '@obeta/utils/lib/mapEtims'
import { SectionHeader } from './SectionHeader'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { useTranslateValues } from '@obeta/data/lib/hooks/useTranslateValues'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SecondaryLightButton } from '@obeta/components/lib/custom-button/CustomButton'

// UI
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { getTechInfoValue } from '@obeta/components/lib/pdf/PdfArticleDataSheet/pdfArticleDataSheetHelpers/configs'
import { OverflowTooltipTypography } from '@obeta/components/lib/typography'

type ITechnicalSpecification = EtimData
type ITechnicalSpecificationColumns = ITechnicalSpecification & { selected?: boolean }

const DESKTOP_MAX_ITEMS_TO_SHOW = 16
const MAX_ITEMS_TO_SHOW = 10
const DESKTOP_MIN_ITEMS_TO_SHOW = 8

const idWithPrefix = (id: string) => {
  return `tech-data-${id}`
}

interface ITechnicalSpecificationProps {
  article: IArticle | undefined
  initialShowAll?: boolean
  withToggleButton?: boolean
}

interface ITechnicalSpecificationBaseProps extends ITechnicalSpecificationProps {
  onEtimsUpdate?: (selectedEtim: EtimData, selectedEtims: Set<EtimData>) => void
  withSelection?: boolean
}

const defaultEtimData: ITechnicalSpecification[] = []

export const transformStrippedTableTechInfo = (
  etimData: ProductAggregate['etimData'],
  t: TFunction
) => {
  if (!etimData) return []

  return etimData.reduce((acc, etimDataItem) => {
    return [
      ...acc,
      {
        ...etimDataItem,
        value1: getTechInfoValue(etimDataItem, t),
      },
    ]
  }, [] as ProductAggregate['etimData'])
}

// TODO: I think it can be reused for ArticleEtimFilter
const generateSearchParams = (
  selectedEtims: Set<ITechnicalSpecification>,
  selectedEtim: EtimData
): ArticleSearchParams => {
  const selectedEtimEntries = [...selectedEtims].map(mapEtim).flat()
  return {
    selectedEtim: selectedEtim
      ? {
          id: selectedEtim?.classId,
          name: selectedEtim?.classText,
        }
      : undefined,
    etim: selectedEtimEntries,
  }
}

export const TechnicalSpecificationsBase: React.FC<ITechnicalSpecificationBaseProps> = (props) => {
  const {
    article,
    withToggleButton = true,
    initialShowAll = false,
    onEtimsUpdate,
    withSelection = true,
  } = props
  const { tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const etimData = article?.etimData || defaultEtimData
  // TODO: this should be tmp. We need to figure out the logic behind selectedEtim in gql api
  const selectedEtim = etimData[0]
  const [selectedEtims, setSelectedEtims] = useState(new Set<ITechnicalSpecification>())
  const { tValue } = useTranslateValues()

  useEffect(() => {
    setSelectedEtims(new Set())
  }, [etimData])

  useEffect(() => {
    onEtimsUpdate?.(selectedEtim, selectedEtims)
  }, [onEtimsUpdate, selectedEtim, selectedEtims])

  const [showAllItems, setShowAllItems] = useState<boolean>(initialShowAll)
  const columns = useMemo(() => {
    const cols: IColumn<keyof ITechnicalSpecificationColumns, ITechnicalSpecificationColumns>[] = [
      {
        accessor: 'featureText',
        minWidth: 0,
        flex: 46,
        format: (name: string) => {
          const overflowVariantProps = {
            component: desktop ? 'label' : 'span',
            htmlFor: desktop ? idWithPrefix(name) : undefined,
          }
          const hoverVariantProps = {
            component: 'label',
            htmlFor: idWithPrefix(name),
          }

          return (
            <OverflowTooltipTypography
              text={name}
              overflowVariantProps={overflowVariantProps}
              hoverVariantProps={hoverVariantProps}
            />
          )
        },
      },
      {
        accessor: 'value1',
        flex: 46,
        alignment: withSelection ? 'left' : 'right',
        format: (_value1, data) => {
          const value1 = _value1 as string
          let val = value1

          if (data.value2) {
            val = `${tValue(value1)} - ${tValue(data.value2)}`
          }

          return (
            <Typography noWrap variant="bodyBold">
              {tValue(val)}
            </Typography>
          )
        },
      },
    ]

    if (withSelection) {
      cols.push({
        accessor: 'selected',
        padding: 'none',
        alignment: 'right',
        flex: 8,
        format: (_, data) => {
          const { featureText: name, featureId: currentFeatureId } = data

          const findEtimInSet = (set: Set<EtimData>) =>
            Array.from(set).find(({ featureId }) => featureId === currentFeatureId)
          const isChecked = !!findEtimInSet(selectedEtims)

          return (
            <Checkbox
              id={idWithPrefix(name)}
              name={name}
              checked={isChecked}
              onChange={(e) => {
                const checked = e.target.checked
                setSelectedEtims((baseEtims) => {
                  const updatedEtimsSet = new Set(baseEtims)
                  if (checked) {
                    updatedEtimsSet.add(data)
                  } else {
                    const etimToDelete = findEtimInSet(updatedEtimsSet)
                    if (etimToDelete) {
                      updatedEtimsSet.delete(etimToDelete)
                    }
                  }

                  return updatedEtimsSet
                })
              }}
              className={styles.checkbox}
            />
          )
        },
      })
    }

    return cols
  }, [desktop, selectedEtims, tValue, withSelection])

  const available = etimData.length > 0
  const [canToggle, column1Data, column2Data] = useMemo<
    [boolean, ITechnicalSpecification[], ITechnicalSpecification[]]
  >(() => {
    if (!available) {
      return [false, etimData, etimData]
    }

    let itemsLength = etimData.length
    if (desktop || tabletWide) {
      if (itemsLength <= DESKTOP_MIN_ITEMS_TO_SHOW) {
        return [false, etimData, []]
      }

      const canToggle = itemsLength > DESKTOP_MAX_ITEMS_TO_SHOW

      if (!showAllItems && canToggle) {
        itemsLength = DESKTOP_MAX_ITEMS_TO_SHOW
      }

      const col1Length = Math.ceil(itemsLength / 2)
      const col1 = etimData.slice(0, col1Length)
      const col2 = etimData.slice(col1Length, itemsLength)

      return [canToggle, col1, col2]
    }

    if (itemsLength > MAX_ITEMS_TO_SHOW) {
      if (!showAllItems) {
        itemsLength = MAX_ITEMS_TO_SHOW
      }

      return [true, etimData.slice(0, itemsLength), []]
    } else {
      return [false, etimData, []]
    }
  }, [available, desktop, tabletWide, etimData, showAllItems])

  const preparedTableData1 = useMemo(() => {
    return transformStrippedTableTechInfo(column1Data, t)
  }, [column1Data, t])
  const preparedTableData2 = useMemo(() => {
    return transformStrippedTableTechInfo(column2Data, t)
  }, [column2Data, t])

  return (
    <SectionWrapper
      available={available}
      id={ArticleDetailsSections.TechnicalSpecification}
      className={styles.root}
    >
      <SectionHeader>{t<string>('ARTICLE_DETAIL.TECHNICAL_SPECIFICATIONS')}</SectionHeader>
      <div
        className={clsx(styles.data, {
          [styles.oneCol]: column2Data.length <= 0,
          [styles.dataLg]: desktop || tabletWide,
        })}
      >
        <StripedTable columns={columns} data={preparedTableData1} />
        {column2Data.length > 0 && <StripedTable columns={columns} data={preparedTableData2} />}
      </div>
      {/** Use SvgIcon to set correct dimensions (20x20 instead of 24x24) */}
      {withToggleButton && (
        <ToggleMore
          canToggle={canToggle}
          showMore={showAllItems}
          toggleMore={() => {
            setShowAllItems((baseState) => !baseState)
          }}
        />
      )}
      {props.children}
    </SectionWrapper>
  )
}

export const TechnicalSpecifications: React.FC<ITechnicalSpecificationProps> = (props) => {
  const changeSearchParams = useChangeSearchParams()
  const { t } = useTranslation()
  const [localSearchParams, setLocalSearchParams] = useState<ArticleSearchParams>({})

  const onEtimsUpdateRef = useRef<ITechnicalSpecificationBaseProps['onEtimsUpdate']>(
    (selectedEtim, selectedEtims) => {
      setLocalSearchParams(generateSearchParams(selectedEtims, selectedEtim))
    }
  )

  const { searchFilter: allFilterResults } = useArticleSearchFilter(localSearchParams)

  return (
    // Need to fix this page styles problem
    <TechnicalSpecificationsBase {...props} onEtimsUpdate={onEtimsUpdateRef.current}>
      <div className={styles['btn-wrapper']}>
        <SecondaryLightButton
          leftIcon={<CompareArrowsIcon />}
          disabled={!allFilterResults.hitCount}
          onClick={() => {
            changeSearchParams({
              searchParams: localSearchParams,
              route: 'push',
            })
          }}
        >
          {`${t<string>('ARTICLE_DETAIL.COMPARABLE_ITEMS')} (${allFilterResults.hitCount})`}
        </SecondaryLightButton>
      </div>
    </TechnicalSpecificationsBase>
  )
}
