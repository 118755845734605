import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import styles from './RentableArticlesPage.module.scss'

import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Sidebar } from './sidebar/Sidebar'
import { FiltersMobile } from './filters/FiltersMobile'
import { PageContextProvider } from './context/context'
import { ActiveFilters } from './filters/ActiveFilters'
import { IRentArticle } from '@obeta/models/lib/models/RentArticle/types'
import { ArticleCard } from './articleCard/ArticleCard'
import { useFiltersState } from './hooks/useFiltersState'
import { useRentArticlesData } from '@obeta/data/lib/hooks/rentArticles'
import { useDataSelector } from './hooks/useDataSelector'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

// UI
import { SearchField } from '@obeta/components/lib/search-field/SearchField'

interface IProductsFeedProps {
  isError: boolean
  rentArticles: IRentArticle[] | null
}

interface RentableArticlesPage {
  strapiApolloClient: ApolloClient<NormalizedCacheObject>
}

const ProductsFeed: FC<IProductsFeedProps> = ({ rentArticles, isError }) => {
  const { t } = useTranslation()

  if (isError) {
    return <Typography variant="subTitle">{t('RENTABLE_ARTICLES.ERROR_STATE')}</Typography>
  }

  if (!rentArticles?.length) {
    return <Typography variant="subTitle">{t('RENTABLE_ARTICLES.EMPTY_ARTICLES')}</Typography>
  }

  return (
    <Stack spacing={0.5}>
      {rentArticles.map((p) => (
        <ArticleCard key={p.id} article={p} />
      ))}
    </Stack>
  )
}

export const RentableArticlesPage: FC<RentableArticlesPage> = ({ strapiApolloClient }) => {
  const { activeFilters, filters, setFilter, setFilters, resetFilter } = useFiltersState()

  const dataSelector = useDataSelector(filters)

  const {
    preparedData: { preparedRentableArticles, preparedCategories, preparedLocations },
    isLoading,
    isError,
  } = useRentArticlesData(dataSelector, strapiApolloClient)

  const breakpoints = useBreakpoints()
  const { mobile, tabletWide, desktop } = breakpoints
  const { t } = useTranslation()

  return (
    <PageContextProvider value={breakpoints}>
      <div className={styles['content']}>
        {tabletWide || desktop ? (
          <Sidebar
            className={styles['aside']}
            isLoading={isLoading}
            locations={preparedLocations}
            categories={preparedCategories}
            filters={filters}
            setFilter={setFilter}
          />
        ) : (
          <FiltersMobile
            className={styles['filtersMobile']}
            isLoading={isLoading}
            locations={preparedLocations}
            categories={preparedCategories}
            activeFilters={activeFilters}
            filters={filters}
            setFilters={setFilters}
            setFilter={setFilter}
            foundArticlesCount={preparedRentableArticles?.length}
          />
        )}
        <div className={styles['pageTitle']}>
          <Typography variant={'headline3Bold'}>{t('RENTABLE_ARTICLES.PAGE_TITLE')}</Typography>
        </div>
        <div className={styles['search']}>
          <SearchField
            placeholder={t('RENTABLE_ARTICLES.SEARCH_PLACEHOLDER')}
            value={filters.search.value}
            onChange={(value) => setFilter({ type: 'search', value })}
            onReset={() => setFilter({ type: 'search', value: '' })}
          />
        </div>
        {!mobile && (
          <div className={styles['activeFilters']}>
            <ActiveFilters activeFilters={activeFilters} resetFilter={resetFilter} />
          </div>
        )}
        <div className={styles['feed']}>
          <ProductsFeed rentArticles={preparedRentableArticles} isError={isError} />
        </div>
      </div>
    </PageContextProvider>
  )
}
