import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import PlacesAutocomplete from '@obeta/components/lib/places-autocomplete/PlacesAutocomplete'
import styles from './MapControls.module.scss'

export const MapControls = () => {
  const { t } = useTranslation()
  const { setChosenLocation } = useLocationPageProvider()

  return (
    <div className={styles.mapControlsWrapper}>
      <div className={styles.searchWrapper}>
        <Typography variant="headline4Bold">{t('LOCATION_PAGE.FIND_BRANCH_TITLE')}</Typography>
        <div className={styles.autocompleteWrapper}>
          <PlacesAutocomplete onGetCoordinatesCallback={setChosenLocation} />
        </div>
      </div>
      {
        //TODO: to clarify if it will be used again - quick fix disable control by COD-16265
        /*
      <div className={styles.distanceSliderWrapper}>
        <Typography variant="boldText">{t('LOCATION_PAGE.DISTANCE_TO_BRANCH_TITLE')}</Typography>

          <DistanceSlider
          track={'normal'}
          aria-labelledby="track-false-slider"
          min={0}
          onChange={onChangeDistance}
          step={2500}
          max={20000}
          value={distanceValue}
          marks={DISTANCE_MARKS}
          </div>
          /> */
      }
    </div>
  )
}
