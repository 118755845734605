import { Camera } from '@capacitor/camera'
import { EventType, getEventSubscription, NotificationType } from './pubSub'
import { TFunction } from 'react-i18next'

export enum CameraPermissionMessage {
  GRANTED = 'Granted',
  DENIED = 'permission denied',
  RESTRICTED = 'restricted',
  UNEXPECTED = 'unexpected state',
  UNKNOWN = 'unknown',
}

/*
  granted: Every permission in this alias has been granted by the end user (or prompting is not necessary).
  denied: One or more permissions in this alias have been denied by the end user. (denied always)
  prompt: The end user should be prompted for permission, because it has neither been granted nor denied.
  prompt-with-rationale: The end user has denied permission before, but has not blocked the prompt yet. (hint has shown, but the user denied)
 */
export enum CameraPermissionType {
  DENIED = 'prompt-with-rationale',
  DENIED_ALWAYS = 'denied',
  NOT_REQUESTED = 'prompt',
  GRANTED = 'granted',
  LIMITED = 'limited',
}

export const checkCameraAuthorization = async (): Promise<CameraPermissionMessage> => {
  const prevPermissions = await Camera.checkPermissions()

  const isCurrentAccessNotRequested = prevPermissions.camera === CameraPermissionType.NOT_REQUESTED

  // Show system prompt with requestPermissions if it has never been requested before.
  if (isCurrentAccessNotRequested) {
    const currentPermissions = await Camera.requestPermissions()
    return getPermission(currentPermissions.camera)
  }

  return getPermission(prevPermissions.camera)
}

const getPermission = (perm: string): CameraPermissionMessage => {
  const permissionMap = {
    [CameraPermissionType.GRANTED]: CameraPermissionMessage.GRANTED,
    [CameraPermissionType.NOT_REQUESTED]: CameraPermissionMessage.GRANTED,
    [CameraPermissionType.DENIED_ALWAYS]: CameraPermissionMessage.DENIED,
    [CameraPermissionType.DENIED]: CameraPermissionMessage.DENIED,
    [CameraPermissionType.LIMITED]: CameraPermissionMessage.RESTRICTED,
  }

  return permissionMap[perm] || CameraPermissionMessage.UNKNOWN
}

export const handleCameraPermissionError = (errMessage: CameraPermissionMessage, t: TFunction) => {
  const permissionMessages = {
    [CameraPermissionMessage.DENIED]: t('PERMISSIONS.CAMERA.DENIED_PERMISSION_MESSAGE'),
    [CameraPermissionMessage.RESTRICTED]: t('PERMISSIONS.CAMERA.RESTRICTED_PERMISSION_MESSAGE'),
    [CameraPermissionMessage.UNEXPECTED]: t('PERMISSIONS.CAMERA.UNEXPECTED_PERMISSION_MESSAGE'),
    default: t('PERMISSIONS.CAMERA.UNKNOWN_PERMISSION_MESSAGE'),
  }

  const message = permissionMessages[errMessage] || permissionMessages.default

  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.Error,
    id: 'camera-permission-error',
    options: { message },
  })
}
