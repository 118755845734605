import { gql, useApolloClient } from '@apollo/client'
import {
  DispatchOrderConfirmationPdfQuery,
  DispatchOrderConfirmationPdfQueryVariables,
} from '@obeta/schema'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'

export const dispatchOrderConfirmationPdfQuery = gql`
  query dispatchOrderConfirmationPdf($orderId: String!, $withPrice: Boolean!) {
    dispatchOrderConfirmationPdf(orderId: $orderId, withPrice: $withPrice)
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.OrderConfirmationPDFPending,
    id: 'order-confirmation-pending',
    options: {
      message,
    },
  })
}

export const useDispatchOrderConfirmation = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const dispatchOrderConfirmationPdf = useCallback(
    async (orderId: string, withPrice: boolean) => {
      showPendingMessage(t('ORDERS.NOTIFICATION.PENDING_DELIVERY_SLIP'))

      await client.query<
        DispatchOrderConfirmationPdfQuery,
        DispatchOrderConfirmationPdfQueryVariables
      >({
        query: dispatchOrderConfirmationPdfQuery,
        variables: { orderId: orderId, withPrice: withPrice },
      })
    },
    [client, t]
  )

  return { dispatchOrderConfirmationPdf }
}
