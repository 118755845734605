import { createContext, useContext } from 'react'

export interface StackNavigatorState {
  stack: string[]
  name: string
  index: number
  length: number
  direction: 'push' | 'pop'
}

export interface StackNavigatorContextValue {
  state: StackNavigatorState
  push(name: string): void
  go(delta: number): void
}

export const StackNavigatorContext = createContext<StackNavigatorContextValue | null>(null)

export function useStackNavigatorContext() {
  const ctx = useContext(StackNavigatorContext)

  if (!ctx) {
    throw new Error('useStackNavigatorContext must be used within a StackNavigatorContextProvider')
  }

  return ctx
}
