import { FC, useEffect, useRef } from 'react'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { datadogRum } from '@datadog/browser-rum'
import { useOxomiProductEnhancement } from '@obeta/data/lib/hooks/useOxomiProductEnhancement'

const OXOMI_STORY_CONTAINER_ID = 'story-container'

export const OxomiStoryPage: FC = () => {
  const params = useParams()
  const storyContainer = useRef(null)
  const oxomiData = useOxomiIntegration({ documentId: '', version: 'v2', skipInit: true })

  useOxomiProductEnhancement()

  useEffect(() => {
    function oxomiLoadedCheck() {
      if (oxomiData && oxomiData.portal && oxomiData.accessToken) {
        const portal = oxomiData.portal
        const accessToken = oxomiData.accessToken
        window.oxomi.init({
          user: 'obeta',
          portal,
          accessToken,
          errorHandler: function (obj) {
            console.error('Failed to init oxomi:', obj.json.message)
            datadogRum.addError(obj.json.message, {
              type: 'oxomi init on story page failed',
              portal,
              accessToken,
            })
          },
        })
      }
    }

    document.addEventListener('oxomi-loaded', oxomiLoadedCheck)
    return () => {
      document.removeEventListener('oxomi-loaded', oxomiLoadedCheck)
    }
  }, [oxomiData])

  useEffect(() => {
    function oxomiConfiguredCheck() {
      window.oxomi.embedStory({
        target: `#${OXOMI_STORY_CONTAINER_ID}`,
        story: params.id,
      })
    }
    document.addEventListener('oxomi-configured', oxomiConfiguredCheck)
    return () => {
      document.removeEventListener('oxomi-configured', oxomiConfiguredCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id={OXOMI_STORY_CONTAINER_ID} ref={storyContainer} />
}
