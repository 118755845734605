import React from 'react'
import dayjs from 'dayjs'
import { Typography } from '@mui/material'
import styles from './promotionCheckout.module.scss'

interface IPromotionDateRangeProps {
  endTime: string
  startTime: string
}

const FULL_YEAR = 'DD.MM.YYYY'
const DATE_MONTH = 'DD.MM'

export const PromotionDateRange: React.FC<IPromotionDateRangeProps> = (props) => {
  const { endTime, startTime } = props

  const startMoment = dayjs(startTime)
  const endMoment = dayjs(endTime)

  const startDateFormatStr = startMoment.year() === endMoment.year() ? DATE_MONTH : FULL_YEAR
  const start = startMoment.format(startDateFormatStr)
  const end = endMoment.format(FULL_YEAR)
  const promotionDateRange = `${start} - ${end}`

  return (
    <Typography variant="smallText" className={styles['promotion-checkout-date']}>
      {promotionDateRange}
    </Typography>
  )
}
