import React, { createContext, useState } from 'react'

export const ArticleStickyHeaderContext = createContext({
  updateStickyHeaderPosition: (topDistance: number) => {
    //
  },
  topStickyDistance: 0,
})

export const ArticleStickyHeaderProvider = ({ children }) => {
  const [topStickyDistance, setTopStickyDistance] = useState(0)

  const updateStickyHeaderPosition = (value) => setTopStickyDistance(value)

  return (
    <ArticleStickyHeaderContext.Provider
      value={{
        updateStickyHeaderPosition,
        topStickyDistance,
      }}
    >
      {children}
    </ArticleStickyHeaderContext.Provider>
  )
}
