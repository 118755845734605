import React from 'react'
import editAddressOverlayStyles from './CheckoutEditAddressOverlay.module.scss'
import inquireOfferOverlayStyles from './CheckoutInquireOfferOverlay.module.scss'
import checkoutStoreCardStyles from './CheckoutStoreCard.module.scss'
import styles from './CheckoutOfferInquiryFeedbackOverlay.module.scss'
import { Box, SvgIcon, Typography, Modal, Divider, useTheme, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import clsx from 'clsx'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'

interface OfferInquiryFeedbackOverlay {
  store: StoreV2
  states: OfferInquiryFeedbackStates
}

export interface OfferInquiryFeedbackStates {
  showSuccessOverlay?: boolean
  setShowSuccessOverlay?: (value: boolean) => void
  showFailureOverlay?: boolean
  setShowFailureOverlay?: (value: boolean) => void
}

export const CheckoutOfferInquiryFeedbackOverlay: React.FC<OfferInquiryFeedbackOverlay> = (
  props
) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { store, states } = props
  const openingHours = formatOpeningHours(store.openingHours)

  const handleClose = () => {
    states.showSuccessOverlay && states.setShowSuccessOverlay && states.setShowSuccessOverlay(false)
    states.showFailureOverlay && states.setShowFailureOverlay && states.setShowFailureOverlay(false)
  }

  return (
    <Modal
      open={(states.showSuccessOverlay as boolean) || (states?.showFailureOverlay as boolean)}
      onClose={handleClose}
      aria-labelledby="modal-modal-checkout-offer-inquiry-feedback"
      aria-describedby="modal-modal-offer-inquiry-feedback-description"
      className={inquireOfferOverlayStyles.modal}
    >
      <div className={inquireOfferOverlayStyles.mainDiv}>
        <Box className={clsx(inquireOfferOverlayStyles.heading, styles.marginBottomOneRem)}>
          <Typography variant={'h4'} className={inquireOfferOverlayStyles.textInquireOffer}>
            {states.showSuccessOverlay && t('OFFERINQUIRY.THANK_YOU_FOR_YOUR_INQUIRY')}
            {states.showFailureOverlay && t('OFFERINQUIRY.ERROR.THERE_WAS_AN_ERROR')}
          </Typography>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleClose}
            className={clsx(
              editAddressOverlayStyles.buttonAddressLocationCard,
              inquireOfferOverlayStyles.noMargin
            )}
          >
            <SvgIcon
              component={CloseIcon}
              fontSize={'large'}
              htmlColor={theme.palette.grayVariant.dark}
            />
          </IconButton>
        </Box>

        <Typography variant={'bodyBold'} className={styles.lineHeight20px}>
          {states.showSuccessOverlay && t('OFFERINQUIRY.INQUIRY_WAS_SENT_SUCCESSFULLY')}
          {states.showFailureOverlay && t('OFFERINQUIRY.ERROR.CONTACT_THE_STORE')}
        </Typography>

        <Divider className={editAddressOverlayStyles.divider} />

        <div className={styles.wrapperStoreData}>
          <div className={clsx(styles.divStoreData, styles.firstDivStoreData)}>
            <Typography
              variant={'bodyBold'}
              className={clsx(styles.lineHeight20px, styles.marginBottomHalfRem)}
            >
              {store.address.name1}
            </Typography>
            <Typography variant={'body'} className={styles.lineHeight20px}>
              {store.address.street} {store.address.houseNumber}
            </Typography>
            <Typography variant={'body'} className={styles.lineHeight20px}>
              {store.address.zipCode} {store.address.city}
            </Typography>
          </div>

          <div className={styles.divStoreData}>
            <Typography
              variant={'body'}
              className={clsx(styles.lineHeight20px, styles.marginBottomHalfRem)}
            >
              <span className={styles.headerT}>T </span>
              {store.phoneNumber}
            </Typography>

            <div className={checkoutStoreCardStyles.flexRow}>
              <div className={checkoutStoreCardStyles.days}>
                {openingHours.map((openingHourGrouping) => {
                  return (
                    <Typography variant="body" key={Math.random()}>
                      {openingHourGrouping.days}
                    </Typography>
                  )
                })}
              </div>
              <div className={checkoutStoreCardStyles.times}>
                {openingHours.map((openingHourGrouping) => {
                  return (
                    <Typography variant="body" key={Math.random()}>
                      {openingHourGrouping.times}
                    </Typography>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
