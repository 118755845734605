import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useApolloClient } from '@apollo/client'
import { useOrderDetailsContext } from '../stores/useOrderDetailsContext'
import {
  GetOrderHeaderByIdQuery,
  OrderHeaderByIdInput,
  GetOrderHeaderByIdQueryVariables,
  OrderV2,
  OrderItemStateType,
} from '@obeta/schema'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import { ORDER_ITEM_STATE_TYPE_TRANSLATIONS } from '@obeta/models/lib/models/Orders/OrderListViewModels'

export const GET_ORDER_HEADER_BY_ID_QUERY = gql`
  query getOrderHeaderById($input: OrderHeaderByIdInput!) {
    getOrderHeaderById(input: $input) {
      success
      errorMessage
      errorCode
      order {
        id
        offerId
        name
        userId
        type
        itemStates {
          type
          count
        }
        collector
        purchaser
        clerk
        projectName
        customerReference
        commissionText
        shippingType
        shippingAddress
        shippingDate
        storeId
        orderTotalSum
        supplementaryCosts
        itemCount
        createdAt
        lastUpdated
      }
    }
  }
`

export const useGetOrderHeaderById = () => {
  const client = useApolloClient()
  const { setItemStateOptions } = useOrderDetailsContext()
  const { t } = useTranslation()

  const [order, setOrder] = useState<OrderV2 | null>(null)

  /**
   * Handler to search orders by input variables.
   * @param input OrdersInput
   */
  const getOrderHeaderById = useCallback(
    async (input: OrderHeaderByIdInput) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await client.query<
        GetOrderHeaderByIdQuery,
        GetOrderHeaderByIdQueryVariables
      >({
        query: GET_ORDER_HEADER_BY_ID_QUERY,
        variables: {
          input: input,
        },
        fetchPolicy: 'no-cache',
      })

      const data = response.data.getOrderHeaderById

      if (data.success) {
        trackCustom('get-order-header-by-id', {
          request: input,
          response: data.order,
        })

        if (data.order) {
          setOrder(data.order as OrderV2)

          // Map item states to dropdown options
          const itemStateOptions = data.order.itemStates.map((itemState) => {
            return {
              label: t(
                getTranslationString<OrderItemStateType>(
                  ORDER_ITEM_STATE_TYPE_TRANSLATIONS,
                  itemState.type
                )
              ),
              value: itemState.type,
            }
          })

          setItemStateOptions([
            {
              label: t('COMMON.ALL_UPPER_CASE'),
              value: 'all',
            },
            ...itemStateOptions,
          ])
        }
      } else {
        trackCustom('get-order-header-by-id-error', data)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  )

  return {
    order,
    getOrderHeaderById,
  }
}
