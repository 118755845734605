import { FC, ReactNode } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Styles
import styles from './OfferDetailHeaderInfoItem.module.scss'

type Props = {
  children?: ReactNode
  chromaticIgnore?: boolean // Ignore design change detection for dynamic values
  flexDirectionColumn?: boolean
  title: string
  value?: string
}

export const OfferDetailHeaderInfoItem: FC<Props> = (props) => {
  const { children, chromaticIgnore, flexDirectionColumn, title, value } = props

  const { mobile } = useBreakpoints()

  return (
    <div
      className={clsx(styles.infoItem, flexDirectionColumn && styles.infoItemColumn)}
      data-chromatic={chromaticIgnore && 'ignore'}
    >
      <Typography variant={mobile ? 'body' : 'body1'}>{title}</Typography>
      {value && <Typography variant={mobile ? 'bodyBold' : 'boldText'}>{value}</Typography>}
      {children ?? null}
    </div>
  )
}
