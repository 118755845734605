import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './ActiveFilters.module.scss'

import { Chip } from '@obeta/components/lib/chip/Chip'
import { IFilter } from '@obeta/models/lib/models/RentArticle/types'

interface IActiveFiltersProps {
  activeFilters: IFilter[]
  resetFilter(filter: IFilter): void
}

export const ActiveFilters: FC<IActiveFiltersProps> = ({ activeFilters, resetFilter }) => {
  const { t } = useTranslation()

  if (!activeFilters.length) {
    return null
  }

  return (
    <div className={styles['root']}>
      <Typography noWrap variant="body" className={styles['label']}>
        {t('SEARCH.ACTIVE_FILTERS')}:
      </Typography>
      <div className={styles['filters']}>
        {activeFilters.map((f) => {
          return (
            <Chip
              title={f.value}
              className={styles['chip']}
              key={f.value}
              label={f.value}
              onDelete={() => resetFilter(f)}
            />
          )
        })}
      </div>
    </div>
  )
}
