import { getShoppingCartPrices } from '@obeta/data/lib/actions/cart-actions'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const usePromotionItemsFromActiveCart = () => {
  const { id: promotionId } = useParams<{ id: string }>()
  const selectedCart = useSelectedCart()
  const carts = useCartsv2WithPricesAndStock()
  const activeCart = carts.find((el) => el.id === selectedCart?.id)

  const dispatch = useDispatch()

  useEffect(() => {
    if (activeCart?.id !== undefined) {
      //load the prices seperatly in case we need the sum
      dispatch(getShoppingCartPrices(activeCart.id))
    }
  }, [activeCart?.id, dispatch])

  if (!activeCart || promotionId !== activeCart.promotionId) return []

  return activeCart.items.filter((article) => article.isPromotionItem)
}
