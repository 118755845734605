import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const InfoSection = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      <Typography variant="headline2Bold">{t('SUPPLIERS_OVERVIEW.TITLE')}</Typography>
      <Typography variant="body">{t('SUPPLIERS_OVERVIEW.INFO_TEXT')}</Typography>
    </Box>
  )
}
