import { IThumbsProps } from './types'
import { Thumb } from './Thumb'
import { ReactComponent as PlayIcon } from '@obeta/assets/icon/play.svg'
import { ReactComponent as Icon360 } from '@obeta/assets/icon/360icon1.svg'
import styles from './Thumbs.module.scss'
import { ThumbLabel } from './ThumbLabel'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import { Mousewheel } from 'swiper/modules'
import SwiperCore from 'swiper'
import '@obeta/assets/theme/swiper.scss'
import clsx from 'clsx'
import { setRefValue } from '@obeta/utils/lib/setRefValue'

SwiperCore.use([Mousewheel])

export const Thumbs = React.forwardRef<SwiperCore, IThumbsProps>(function Thumbs(props, ref) {
  const { direction = 'horizontal', thumbs, selectedIdx, onThumbClicked, size } = props
  const { t } = useTranslation()
  const [swiperApi, setSwiperApi] = useState<SwiperCore>()

  useEffect(() => {
    if (swiperApi) {
      swiperApi.slideTo(selectedIdx || 0)
    }
  }, [selectedIdx, swiperApi])

  return (
    <Swiper
      onSwiper={(swiper) => {
        setSwiperApi(swiper)
        setRefValue(ref, swiper)
      }}
      direction={direction}
      freeMode={true}
      loop={false}
      slidesPerView={'auto'}
      pagination={false}
      mousewheel={{
        forceToAxis: true,
      }}
      className={clsx(styles.swiper, {
        [styles.vertical]: direction === 'vertical',
        [styles.horizontal]: direction === 'horizontal',
      })}
    >
      {thumbs.map((thumbData, idx) => {
        const isTurntable = thumbData.contentType === 'image' && thumbData.turntableImages
        const isVideo = thumbData.contentType === 'video'

        let label: JSX.Element | undefined
        if (isTurntable) {
          label = <ThumbLabel icon={<Icon360 />} text="360°" />
        } else if (isVideo) {
          label = <ThumbLabel icon={<PlayIcon />} text={t<string>('COMMON.VIDEO')} />
        }
        return (
          <SwiperSlide key={thumbData.thumbId}>
            <Thumb
              index={idx}
              size={size}
              thumbId={thumbData.thumbId}
              key={thumbData.thumbId}
              onClick={(e) => {
                onThumbClicked?.(e, thumbData.thumbId, idx)
              }}
              thumbUrl={thumbData.thumbUrl}
              selected={idx === selectedIdx}
              label={label}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
})
