import React from 'react'
import styles from './CartTemplatesEmptyBox.module.scss'
import { Box, Button, SvgIcon, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'assets/icon/designsystem/add.svg'

interface Props {
  onCreateTemplateButtonClicked: () => void
}
export const CartTemplatesEmptyBox: React.FC<Props> = (props) => {
  const { onCreateTemplateButtonClicked } = props
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box className={styles.wrapper}>
      <Typography variant={'h4'}>{t('TEMPLATES.NO_TEMPLATES_AVAILABLE')}</Typography>
      <Button
        variant={'tertiary'}
        className={styles.createNew}
        onClick={() => onCreateTemplateButtonClicked()}
        startIcon={<SvgIcon component={AddIcon} htmlColor={theme.palette.secondary.main} />}
      >
        {t('TEMPLATES.NEW_TEMPLATE')}
      </Button>
    </Box>
  )
}
