import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  AddCartContextEnum,
  addProductToCartGraphQL,
  CartsActionTypes,
  ProductItem,
} from '../actions/cart-actions'
import { useActionNotification } from './useActionNotification'

export const useAddItemsToCart = (p?: { onSuccess: () => void }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const waitForAction = useActionNotification(CartsActionTypes.AddProductToCartGraphQLResult)
  const onSuccessRef = useRef(p?.onSuccess)

  const add = useCallback(
    (cartId: ShoppingCartV2['id'], items: ProductItem[]) => {
      setLoading(true)
      dispatch(
        addProductToCartGraphQL({
          cartId: cartId,
          items,
          context: AddCartContextEnum.CartImport,
        })
      )
      waitForAction(() => {
        setLoading(false)
        onSuccessRef.current?.()
      })
    },
    [dispatch, waitForAction]
  )

  return { loading, add }
}
