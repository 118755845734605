import React, { useEffect, useRef } from 'react'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { ApolloProvider } from '@apollo/client'
import { useNewsDetails } from '@obeta/data/lib/hooks/useNewsDetails'
import { CustomEditor, EPlugins } from '../reactPageEditor/CustomEditor'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import styles from './NewsDetailsPage.module.scss'
import { Section } from '../startPage/Section'
import { NewsCardSection } from '../startPage/NewsCardSection'
import { useTranslation } from 'react-i18next'
import { trackCustom } from '@obeta/utils/lib/tracking'

export const NewsDetailPage: React.FC = () => {
  const apolloClient = useStrapiGql()
  return (
    <ApolloProvider client={apolloClient}>
      <NewsDetailsPageView />
    </ApolloProvider>
  )
}

const NewsDetailsPageView: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>()
  const detailedData = useNewsDetails(newsId)
  const didTrackPageView = useRef(false)

  useEffect(() => {
    if (
      detailedData &&
      detailedData.title &&
      detailedData.title.length > 0 &&
      !didTrackPageView.current
    ) {
      trackCustom('page-view-with-name', {
        name: detailedData.title,
        combinedPathAndName: `${window.location.pathname} (${detailedData.title})`,
      })
      didTrackPageView.current = true
    }
  }, [detailedData])

  if (!detailedData) return null

  return (
    <div>
      <CustomEditor
        value={detailedData.content}
        pluginsList={[
          EPlugins.RichText,
          EPlugins.Image,
          EPlugins.Video,
          EPlugins.Spacer,
          EPlugins.PDFLayout,
          EPlugins.ProductCardLayoutPlugin,
          EPlugins.RafflePlugin,
          EPlugins.DigitalBillingPlugin,
        ]}
      />
      <OtherNewsBlock newsId={newsId} />
    </div>
  )
}

type OtherNewsBlockProps = { newsId: string }
const OtherNewsBlock: React.FC<OtherNewsBlockProps> = ({ newsId }) => {
  const { t } = useTranslation()
  return (
    <div className={styles['other-news-block-wrapper']}>
      <div className={styles['other-news-block-bg']} />
      <Section
        title={t('START.OTHER_NEWS')}
        content={<NewsCardSection excludedIds={[newsId]} />}
        className={styles['news-section-no-margin']}
      />
    </div>
  )
}
