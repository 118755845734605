import { Player } from '../mediaelement'
import React, { useCallback, useRef, useState, useEffect, useMemo, useContext } from 'react'
import { setRefValue } from '@obeta/utils/lib/setRefValue'

export interface IVideoSlidesControllerAPI {
  playingId: string
  pauseActive: () => void
}

interface IVideoSlidesControllerState {
  addToController: (id: string, player: Player) => void
  setPlayingId: React.Dispatch<React.SetStateAction<string>>
}

const VideoSlidesControllerContext = React.createContext<IVideoSlidesControllerState | null>(null)

export const VideoSlidesController = React.forwardRef<
  IVideoSlidesControllerAPI | undefined,
  { children?: React.ReactNode | JSX.Element | false | null }
>(function VideoSlidesController(props, ref) {
  const playersRef = useRef<Player>({})
  const [playingId, setPlayingId] = useState<IVideoSlidesControllerAPI['playingId']>('')

  const addToController = useCallback((id: string, player: Player) => {
    playersRef.current[id] = player
  }, [])

  // set api
  useEffect(() => {
    const pauseActive = () => {
      if (!playingId || !playersRef.current[playingId]) {
        return
      }

      playersRef.current[playingId].pause()
      setPlayingId('')
    }

    setRefValue(ref, { pauseActive, playingId })
  }, [playingId, ref])

  // to optimize components that will retrive these methods from useContext.
  const controllerState = useMemo(() => {
    return { addToController, setPlayingId }
  }, [addToController])

  return <VideoSlidesControllerContext.Provider {...props} value={controllerState} />
})

export const useVideoSlidesController = () => {
  return useContext(VideoSlidesControllerContext)
}
