import { useCallback, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useHistory, useParams } from './useHistoryApi'
import {
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables,
  HandleFailedPaymentMutation,
  HandleFailedPaymentMutationVariables,
  PaymentMethod,
} from '@obeta/schema'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Browser } from '@capacitor/browser'

const defaultPaymentMethods = ['Invoice', 'Cash'] as const
export type DefaultPaymentMethod = typeof defaultPaymentMethods[number]
export type PaymentMethodWithDefaults = PaymentMethod | DefaultPaymentMethod | undefined
type CreatePaymentTransaction = (cartId: string, method?: PaymentMethodWithDefaults) => void
type HandleFailedPayment = (cartId: string, method?: PaymentMethodWithDefaults) => void
type UsePaymentActions = (options: {
  cartId?: string
  paymentMethod: PaymentMethodWithDefaults
}) => {
  createPaymentTransaction: CreatePaymentTransaction
  handleFailedPayment: HandleFailedPayment
}

export const isDefaultPaymentMethod = (
  value?: PaymentMethodWithDefaults
): value is DefaultPaymentMethod => {
  if (!value) return true
  return defaultPaymentMethods.includes(value as DefaultPaymentMethod)
}
const isProviderPaymentMethod = (value?: PaymentMethodWithDefaults): value is PaymentMethod =>
  !isDefaultPaymentMethod(value)

const CREATE_PAYMENT_TRANSACTION = gql`
  mutation createPaymentTransaction($input: PaymentTransactionInput!) {
    createPaymentTransaction(input: $input) {
      redirectUrl
    }
  }
`
const HANDLE_FAILED_PAYMENT = gql`
  mutation handleFailedPayment($input: PaymentFailedInput!) {
    handleFailedPayment(input: $input) {
      message
    }
  }
`

const URL_PARAMETER_REDIRECT = 'pspRedirect'

const getBaseUrl = () => {
  const url = new URL(window.location.href)

  if (isPlatform('web')) {
    return url.origin
  } else {
    return process.env.REACT_APP_REDIRECT_URL_APP || ''
  }
}

const buildUrl = (baseUrl: string, path: string) => {
  return `${baseUrl}${path}`
}

const buildRedirectUrlSuccess = (cartId: string) => {
  if (typeof window === 'undefined') return ''

  const url = new URL(window.location.href)
  const searchParameters = url.searchParams

  // Remove any existing redirect parameters to avoid conflicts
  searchParameters.delete(URL_PARAMETER_REDIRECT)

  const baseUrl = getBaseUrl()
  const urlWithoutQueryParams = buildUrl(baseUrl, `${ShopRoutes.CreateOrderPage}/${cartId}`)

  const searchString = searchParameters.toString()
  const redirectUrl = searchString
    ? `${urlWithoutQueryParams}?${searchString}`
    : urlWithoutQueryParams

  return redirectUrl
}

// Build redirect URL for error scenario
const buildRedirectUrlError = (param: string) => {
  if (typeof window === 'undefined') return ''

  const url = new URL(window.location.href)
  const searchParameters = url.searchParams

  // Remove any existing redirect parameters to avoid conflicts
  searchParameters.delete(URL_PARAMETER_REDIRECT)
  searchParameters.set(URL_PARAMETER_REDIRECT, param)

  const baseUrl = getBaseUrl()
  const urlWithoutQueryParams = buildUrl(baseUrl, url.pathname)

  const redirectUrl = `${urlWithoutQueryParams}?${searchParameters.toString()}`

  return redirectUrl
}

/**
 * Custom hook to handle payment transactions and redirect the user to the payment provider.
 * The hook also interprets the associated URL query parameters and executes the associated functions.
 *
 * @param onRedirectSuccess action to get called on redirect success
 * @param onRedirectSuccessCondition condition that has to been met to call redirect success action
 */
export const usePaymentHandler: UsePaymentActions = ({ cartId, paymentMethod }) => {
  const history = useHistory()
  const apolloClient = useApolloClient()
  const params = useParams()
  const isErrorRedirectFromPaymentProvider = params.pspRedirect?.includes('error')
  const didHandleFailedPayment = useRef(false)

  const createPaymentTransaction = useCallback<CreatePaymentTransaction>(
    async (cartId, method) => {
      if (isProviderPaymentMethod(method)) {
        const result = await apolloClient.mutate<
          CreatePaymentTransactionMutation,
          CreatePaymentTransactionMutationVariables
        >({
          mutation: CREATE_PAYMENT_TRANSACTION,
          variables: {
            input: {
              cartId,
              method,
              successUrl: buildRedirectUrlSuccess(cartId),
              errorUrl: buildRedirectUrlError('error'),
              notificationFailedUrl: buildRedirectUrlError('notificationFailed'),
            },
          },
        })
        if (result.data?.createPaymentTransaction.redirectUrl) {
          const url = result.data.createPaymentTransaction.redirectUrl
          if (isPlatform('web')) {
            history.push(url)
          } else {
            Browser.open({
              url,
            })
          }
        }
      }
    },
    [apolloClient, history]
  )
  const handleFailedPayment = useCallback<HandleFailedPayment>(
    async (cartId, method) => {
      if (isProviderPaymentMethod(method)) {
        const response = await apolloClient.mutate<
          HandleFailedPaymentMutation,
          HandleFailedPaymentMutationVariables
        >({
          mutation: HANDLE_FAILED_PAYMENT,
          variables: {
            input: {
              cartId,
              method,
            },
          },
        })
        if (
          response?.data?.handleFailedPayment.message &&
          response?.data?.handleFailedPayment.message.length > 0
        ) {
          getEventSubscription().next({
            type: EventType.Alert,
            notificationType: NotificationType.Error,
            id: 'failed-payment-message',
            options: {
              message: response.data.handleFailedPayment.message,
              id: 'failed-payment-message',
            },
          })
        }
      }
    },
    [apolloClient]
  )

  useEffect(() => {
    if (
      isErrorRedirectFromPaymentProvider &&
      !didHandleFailedPayment.current &&
      cartId &&
      paymentMethod
    ) {
      handleFailedPayment(cartId, paymentMethod)
      didHandleFailedPayment.current = true
    }
  }, [isErrorRedirectFromPaymentProvider, cartId, handleFailedPayment, paymentMethod])

  return {
    createPaymentTransaction,
    handleFailedPayment,
  }
}
