import { FC } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'
import { initApollo } from '@obeta/app-bootstrap/lib/apolloClient'
import { Section } from '../../../startPage/Section'
import { ProductsSwiper } from '../../../articleDetailsPage/ProductsSwiper'
import { MonthAdvertisementBlock } from '../MonthAdvertisementBlock/MonthAdvertisementBlock'
import { useMonthAdverts } from '../../hooks/useMonthAdverts'
import { ILastMonthAds } from '@obeta/models/lib/models/LastMonthAds/LastMonthAds'
import { formatDateAsMonthNameAndYear } from '../../../startPage/NewsCardSection'
import styles from './MonthsAdvertisementSection.module.scss'
import { useRxDB } from 'rxdb-hooks'

const graphqlUrlShop = process.env.NEXT_PUBLIC_GRAPHQL_HOST as string
const graphqlUrlApp = process.env.REACT_APP_NEXT_PUBLIC_GRAPHQL_HOST as string

export const MonthsAdvertisementView: FC<{ monthAdverts: ILastMonthAds[] }> = ({
  monthAdverts,
}) => {
  const { mobile } = useBreakpoints()
  const db = useRxDB()
  const appActions = useAppActions()
  const client = initApollo(graphqlUrlApp || graphqlUrlShop, appActions, false, db)

  return (
    <ApolloProvider client={client}>
      <div className={styles.cardContainer}>
        <ProductsSwiper
          initialSlide={monthAdverts.length - 1}
          closeNavigationButtons={mobile}
          cardWrapperClassName={styles.cardWrapper}
          withNavigateButtons={!mobile}
          isSliderLoop={false}
          elementsPerRow={1}
          productElements={monthAdverts.map((advert) => (
            <MonthAdvertisementBlock
              key={advert.id}
              {...advert}
              activeMonth={formatDateAsMonthNameAndYear(advert.activeMonth)}
            />
          ))}
        />
      </div>
    </ApolloProvider>
  )
}

export const MonthsAdvertisementSection: FC = () => {
  const { monthAdverts } = useMonthAdverts()

  if (!monthAdverts.length) {
    return null
  }

  return (
    <Section
      className={styles.sectionWrapper}
      content={<MonthsAdvertisementView monthAdverts={monthAdverts} />}
    />
  )
}
