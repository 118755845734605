import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as Close2 } from 'assets/icon/close_2.svg'
import { ReactComponent as ThumbUp } from 'assets/icon/designsystem/thumb_up.svg'
import { ReactComponent as ThumbDown } from 'assets/icon/designsystem/thumb_down.svg'
import styles from './SearchFeedback.module.scss'
import { useTranslation } from 'react-i18next'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  setShowSearchFeedback: (status: boolean) => void
  setShowSearchFeedbackConsentOverlay: (status: boolean) => void
  searchFeedbackValue?: SearchFeedbackValue
  setSearchFeedbackValue?: (value: SearchFeedbackValue) => void
  statisticsCookiesAllowed?: boolean
  feedbackButtonClicked: boolean
  setFeedbackButtonClicked: (buttonClicked: boolean) => void
  setShowThankYouThumbUp: (value: boolean) => void
  setShowOverlayThumbDownFeedback: (value: boolean) => void
  setSendFeedbackToDataDog: (value: boolean) => void
  onCloseSearchFeedback: () => void
}

export interface SearchFeedbackValue {
  evaluation?: 'postive' | 'negative'
  comment?: string
}
export const SearchFeedback: React.FC<Props> = (props: Props) => {
  const {
    setShowSearchFeedback,
    setShowSearchFeedbackConsentOverlay,
    searchFeedbackValue,
    setSearchFeedbackValue,
    statisticsCookiesAllowed,
    feedbackButtonClicked,
    setFeedbackButtonClicked,
    setShowThankYouThumbUp,
    setShowOverlayThumbDownFeedback,
    setSendFeedbackToDataDog,
    onCloseSearchFeedback,
  } = props
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  useEffect(
    () => {
      if (feedbackButtonClicked && statisticsCookiesAllowed) {
        if (searchFeedbackValue?.evaluation === 'postive') {
          setSendFeedbackToDataDog(true)
          setShowThankYouThumbUp(true)
        } else if (searchFeedbackValue?.evaluation === 'negative') {
          setShowOverlayThumbDownFeedback(true)
        }
        setFeedbackButtonClicked(false)
        setShowSearchFeedback(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedbackButtonClicked, statisticsCookiesAllowed]
  )

  return (
    <div className={styles.mainWrapper}>
      <Box className={styles.boxTextAndCloseButton}>
        <Typography variant="body2" className={styles.text}>
          {t('SEARCH.FEEDBACK.ARE_YOU_CONTENT_WITH_SEARCH')}
        </Typography>
        <TertiaryIconButton onClick={onCloseSearchFeedback} icon={<Close2 />}></TertiaryIconButton>
      </Box>
      <Box className={clsx(styles.thumbs, styles.marginTopHalfRem)}>
        <TertiaryIconButton
          onClick={() => {
            setSearchFeedbackValue && setSearchFeedbackValue({ evaluation: 'postive' })
            setFeedbackButtonClicked(true)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            //explicitly looking for false to exclude local env where there is no Cookiebot (undefined)
            if (window?.Cookiebot?.consent?.statistics === false) {
              setShowSearchFeedbackConsentOverlay(true)
            }
          }}
          icon={<ThumbUp />}
          size={desktop ? 'small' : 'large'}
        ></TertiaryIconButton>
        <TertiaryIconButton
          onClick={() => {
            setSearchFeedbackValue && setSearchFeedbackValue({ evaluation: 'negative' })
            setFeedbackButtonClicked(true)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            //explicitly looking for false to exclude local env where there is no Cookiebot
            if (window?.Cookiebot?.consent?.statistics === false) {
              setShowSearchFeedbackConsentOverlay(true)
            }
          }}
          icon={<ThumbDown />}
          size={desktop ? 'small' : 'large'}
        ></TertiaryIconButton>
      </Box>
    </div>
  )
}
