import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { ObetaRentArticleResponse } from './graphqlTypes'

export const ARTICLE_IMAGES_DATA_QUERY = gql`
  query ($sapIds: [String!], $obetaIds: [String!], $dehaIds: [String!]) {
    products: getProducts(sapIds: $sapIds, obetaIds: $obetaIds, dehaIds: $dehaIds) {
      sapId
      dehaId
      oxomiId
      supplierId
      imageData {
        images {
          large
        }
      }
    }
  }
`

export type TArticlesImagesDataResponse = {
  products: Pick<ProductAggregate, 'oxomiId' | 'supplierId' | 'imageData' | 'sapId' | 'dehaId'>[]
}

function prepareQueryVariables(
  rentArticlesRaw: ObetaRentArticleResponse
): { sapIds?: string[]; dehaIds?: string[] } | null {
  const sapIds: string[] = []
  const dehaIds: string[] = []

  rentArticlesRaw.obetaRentArticles.data.forEach((article) => {
    const { sapId, dehaId, media } = article.attributes
    const strapiImageUrl = media.data?.attributes?.url

    if (strapiImageUrl) {
      return
    }

    if (sapId) {
      sapIds.push(sapId)
    }

    if (dehaId) {
      dehaIds.push(dehaId)
    }
  })

  /** If nothing to fetch, query won't be fired */
  if (!sapIds.length && !dehaIds.length) {
    return null
  }

  /** Skip empty lists to prevent BE validation error */
  return {
    sapIds: sapIds.length > 0 ? sapIds : undefined,
    dehaIds: dehaIds.length > 0 ? dehaIds : undefined,
  }
}

/**
 * Fetch article images data (oxomiId, supplierId, imageData) for rentArticles (from strapi) with dehaId or sapId
 * Also include sapId and dehaId in the response in order to merge this data to strapi's rentArticles
 * */
export const useFetchArticlesImagesData = (rentArticlesRaw: ObetaRentArticleResponse | null) => {
  const [variables, setVariables] = useState<object | null>(null)

  useEffect(() => {
    if (rentArticlesRaw) {
      const _variables = prepareQueryVariables(rentArticlesRaw)
      if (_variables) {
        setVariables(_variables)
      }
    }
  }, [rentArticlesRaw])
  const { data, error, loading } = useQuery<TArticlesImagesDataResponse>(
    ARTICLE_IMAGES_DATA_QUERY,
    {
      variables: variables || undefined,
      skip: !variables,
    }
  )

  if (error) {
    return {
      data: { products: [] },
      isLoading: loading,
    }
  }

  return {
    data: data || null,
    isLoading: loading,
  }
}
