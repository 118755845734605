import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import { CheckboxItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { Scrollbar } from '@obeta/components/lib/scrollbar/Scrollbar'
import { ArticleSearchParamsWithId, FacetElement } from '@obeta/models/lib/models/Search'
import cardStyles from '../CardContent.module.scss'
import { useFiltersStateContext } from '../hooks/useFiltersReducer'
import { DispatchFiltersAction } from '../reducer'
import { Size } from '../Size'
import { useSuppliers } from '@obeta/data/lib/hooks/useSuppliers'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { SuppliersSkeletons } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import { trackClick } from '@obeta/utils/lib/tracking'
import { ReactComponent as SortIcon } from 'assets/icon/designsystem/import_export.svg'
import { useLocalStorage } from '@obeta/data/lib/hooks/useLocalStorage'
import styles from './Suppliers.module.scss'

// Hooks
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useLocalSearchControlled } from '@obeta/data/lib/hooks/useLocalSearchControlled'

// UI
import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import { useTranslation } from 'react-i18next'
import { TertiaryIconButton } from '@obeta/components/lib/custom-button/CustomButton'

// TODO: virtualization ?

interface ISuppliersProps {
  suppliers: ArticleSearchParamsWithId['suppliers']
  dispatchFilterAction: DispatchFiltersAction
  suppliersList: FacetElement[]
  loading: boolean
}

const suppliersKeys = ['value']

export const Suppliers: React.FC = (props) => {
  const { filtersState, dispatchFiltersAction } = useFiltersStateContext()
  const selectedSuppliers = filtersState.searchParams.suppliers
  const { searchParams } = useArticlesSearchProvider()
  const { suppliers: suppliersList, loading } = useSuppliers({
    searchParams,
  })

  return (
    <SuppliersList
      suppliers={selectedSuppliers}
      dispatchFilterAction={dispatchFiltersAction}
      suppliersList={suppliersList}
      loading={loading}
    />
  )
}

type SupplierSort = 'alphabetical' | 'relevance'

const alphabeticalSort = (suppliers: FacetElement[]) => {
  return [...suppliers].sort((nameA, nameB) => {
    return nameA.value.localeCompare(nameB.value, 'de', {
      usage: 'sort',
      sensitivity: 'accent',
      numeric: true,
    })
  })
}

const sortSuppliers = (suppliers: FacetElement[], sortBy: SupplierSort) => {
  if (sortBy === 'alphabetical') return alphabeticalSort(suppliers)

  return suppliers
}

const SuppliersList = React.memo<ISuppliersProps>(function SuppliersList(props) {
  const { suppliers: selectedSuppliers, dispatchFilterAction, suppliersList, loading } = props
  const { t } = useTranslation()
  const { search } = useLocalSearchControlled()

  const [sortBy, setSortBy] = useLocalStorage<SupplierSort>('sortBy', 'relevance')

  const [filteredSuppliers, setFilteredSuppliers] = useState<FacetElement[]>(suppliersList)
  const [searchText, setSearchText] = useState<string>('')

  const sortedSuppliers = useMemo(
    () => sortSuppliers(filteredSuppliers, sortBy),
    [filteredSuppliers, sortBy]
  )

  // Search suppliers by search text and keys
  useDebouncedEffect(
    () => {
      setFilteredSuppliers(search(suppliersList, searchText, suppliersKeys))
    },
    [suppliersList, searchText, suppliersKeys],
    500
  )

  const onFilterAction = (sup) => {
    dispatchFilterAction({
      type: 'toggleSupplier',
      payload: { supplier: { id: sup.value, name: sup.value } },
    })
  }

  const handleSort = () => {
    const toggleSort = (currentSort: SupplierSort) =>
      currentSort === 'relevance' ? 'alphabetical' : 'relevance'

    setSortBy((prevSort) => toggleSort(prevSort))

    trackClick('facet-supplier-sorting-toggled', { type: toggleSort(sortBy) })
  }

  return (
    <div className={styles.root}>
      <SearchField
        data-testid="suppliers-search-field"
        value={searchText}
        onChange={setSearchText}
        onReset={() => setSearchText('')}
      />
      {sortedSuppliers.length > 0 && (
        <TertiaryIconButton
          onClick={handleSort}
          icon={
            <SortIcon
              className={clsx(styles.suppliersSortIcon, {
                [styles.reversed]: sortBy === 'alphabetical',
              })}
            />
          }
        >
          {sortBy === 'alphabetical'
            ? t('SEARCH.SORT_BY.RELEVANCE')
            : t('SEARCH.SORT_BY.ALPHABETICAL')}
        </TertiaryIconButton>
      )}
      {loading ? (
        <SuppliersSkeletons />
      ) : (
        <Scrollbar className={clsx(cardStyles.cardContent, styles.list)}>
          {sortedSuppliers.map((sup) => {
            const selected = Boolean(selectedSuppliers && selectedSuppliers[sup.value])

            return (
              <CheckboxItem
                onChange={() => onFilterAction(sup)}
                key={sup.value}
                id={sup.value}
                label={sup.value}
                value={sup.value}
                checked={selected}
                rightAdornment={
                  <Size className={styles.size} size={sup.count} datatestid="suppliers" />
                }
                datatestid="suppliers"
              />
            )
          })}
        </Scrollbar>
      )}
    </div>
  )
})
