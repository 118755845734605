import { Typography } from '@mui/material'
import slate from '@react-page/plugins-slate'
import { clsx } from 'clsx'
import React from 'react'
import { CollapsibleHeader, CollapsibleWrapper } from './customSlatePlugin'
import { colorsPlugin } from './colorsPlugin'
import { subtitlePlugin } from './subtitlePlugin'
import styles from './customText.module.scss'

const CustomP = (props) => (
  <Typography variant="body2" paragraph {...props}>
    {props.children}
  </Typography>
)
const CustomA = (props) => (
  <div className={styles['anchor-wrapper']}>
    <a
      className={styles['red-link']}
      style={props.style}
      href={props.href}
      target={props.openInNewWindow ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {props.children}
    </a>
  </div>
)

const CustomPre = (props) => <pre className={styles['pre-block-wrapper']} {...props} />
const CustomCode = (props) => (
  <div className={styles['code-block-wrapper']}>
    <code className={styles['code-wrapper']} {...props} />
  </div>
)
const EM = (props) => <em className={styles['common-font']} {...props} />
const Underline = (props) => <u className={styles['common-font']} {...props} />
const Strong = (props) => <strong className={styles['bold-text-wrapper']} {...props} />
const Heading1 = (props) => (
  <Typography className={styles['heading-wrapper']} variant="h1">
    {props.children}
  </Typography>
)
const Heading2 = (props) => (
  <Typography className={styles['heading-wrapper']} variant="headline2Bold">
    {props.children}
  </Typography>
)
const Heading3 = (props) => (
  <Typography className={styles['heading-wrapper']} variant="h3">
    {props.children}
  </Typography>
)
const Heading4 = (props) => (
  <Typography className={styles['heading-wrapper']} variant="h4">
    {props.children}
  </Typography>
)

const BlockQuote = (props) => (
  <div className={clsx(styles['common-font'], styles['blockquote-container'])} {...props}>
    {props.children}
  </div>
)

export const slatePlugin = slate((defaultSlateConfig) => ({
  ...defaultSlateConfig,
  plugins: {
    ...defaultSlateConfig.plugins,
    paragraphs: {
      ...defaultSlateConfig.plugins.paragraphs,
      paragraph: defaultSlateConfig.plugins.paragraphs.paragraph((paragraphDef) => ({
        ...paragraphDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children }) => <CustomP style={style}>{children}</CustomP>,
      })),
      pre: defaultSlateConfig.plugins.paragraphs.pre((preDef) => ({
        ...preDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children }) => <CustomPre style={style}>{children}</CustomPre>,
      })),
    },
    link: {
      ...defaultSlateConfig.plugins.link,
      link: defaultSlateConfig.plugins.link.link((linkDef) => ({
        ...linkDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children, href, openInNewWindow }) => (
          <CustomA {...{ style, href, openInNewWindow }}>{children}</CustomA>
        ),
      })),
    },
    headings: {
      ...defaultSlateConfig.plugins.headings,
      h1: defaultSlateConfig.plugins.headings.h1((hDef) => ({
        ...hDef,
        Component: ({ style, children }) => <Heading1 style={style}>{children}</Heading1>,
      })),
      h2: defaultSlateConfig.plugins.headings.h2((hDef) => ({
        ...hDef,
        Component: ({ style, children }) => <Heading2 style={style}>{children}</Heading2>,
      })),
      h3: defaultSlateConfig.plugins.headings.h3((hDef) => ({
        ...hDef,
        Component: ({ style, children }) => <Heading3 style={style}>{children}</Heading3>,
      })),
      h4: defaultSlateConfig.plugins.headings.h4((hDef) => ({
        ...hDef,
        Component: ({ style, children }) => <Heading4 style={style}>{children}</Heading4>,
      })),
      subtitle: subtitlePlugin(),
    },
    emphasize: {
      ...defaultSlateConfig.plugins.emphasize,
      em: defaultSlateConfig.plugins.emphasize.em((emDef) => ({
        ...emDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children }) => <EM style={style}>{children}</EM>,
      })),
      strong: defaultSlateConfig.plugins.emphasize.strong((strongDef) => ({
        ...strongDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children }) => <Strong style={style}>{children}</Strong>,
      })),
      underline: defaultSlateConfig.plugins.emphasize.underline((underlineDef) => ({
        ...underlineDef, // spread it, so that the new config contains all defaults
        Component: ({ style, children }) => <Underline style={style}>{children}</Underline>,
      })),
    },
    code: {
      ...defaultSlateConfig.plugins.code,
      mark: defaultSlateConfig.plugins.code.mark((markDef) => ({
        ...markDef,
        Component: ({ style, children }) => <CustomCode style={style}>{children}</CustomCode>,
      })),
    },
    quotes: {
      ...defaultSlateConfig.plugins.quotes,
      blockQuote: defaultSlateConfig.plugins.quotes.blockQuote((quoteDef) => ({
        ...quoteDef,
        Component: ({ style, children }) => <BlockQuote style={style}>{children}</BlockQuote>,
      })),
    },
    customPlugins: {
      collapsibleWrapper: CollapsibleWrapper,
      collapsibleHeader: CollapsibleHeader,
      colors: colorsPlugin(),
    },
  },
}))
