const USER_ID_SEPARATOR = '-'
export function buildFullyQualifiedUserId({
  userId,
  companyId,
}: {
  userId: string
  companyId: string
}): string {
  if (userId.length === 0 || companyId.length === 0) {
    throw new Error(
      `Can not build fullyQualifiedUserId from empty userId or companyId (userId:"${userId}", companyId: "${companyId}")`
    )
  }

  // handles i.e. the following  user ids "1010-6509-9", "1010-6509", "6509-9"
  if (userId.includes(USER_ID_SEPARATOR)) {
    const parts = userId.split(USER_ID_SEPARATOR)
    if (parts[0] === companyId) {
      return parts[2] ? `${parts[0]}-${parts[1]}-${parts[2]}` : `${parts[0]}-${parts[1]}`
    }
    const userIdWithoutSubUserId = parts[0]
    return parts[1]
      ? `${companyId}-${userIdWithoutSubUserId}-${parts[1]}`
      : `${companyId}-${userIdWithoutSubUserId}`
  }
  return `${companyId}-${userId}`
}
