import { FC } from 'react'
import styles from './DigitalBillingRegistered.module.scss'
import { ReactComponent as SuccessIcon } from '@obeta/assets/icon/designsystem/leaf_digitalerRechnungsversand.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const DigitalBillingRegistered: FC = () => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  return (
    <Box>
      <Box className={clsx(styles.registeredOuter, { [styles.compact]: mobile })}>
        <Stack alignItems={'center'} className={styles.registeredInner}>
          <SuccessIcon className={styles.checkmarkCircle} />
          <Typography className={styles.successText}>
            {t('DIGITAL_BILLING.REGISTERED.HEADLINE')}
          </Typography>
          <Typography variant="bodyBold" className={styles.infoText}>
            {t('DIGITAL_BILLING.REGISTERED.INFO')}
          </Typography>
        </Stack>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ pt: 1.25 }}>
        <Typography variant="bodyBold">{t('DIGITAL_BILLING.HINT_LABEL')}</Typography>
        <Typography>{t('DIGITAL_BILLING.HINT')}</Typography>
      </Box>
    </Box>
  )
}
