import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as Settings } from 'assets/icon/designsystem/settings.svg'
import { ReactComponent as Info } from 'assets/icon/designsystem/info.svg'
import styles from './SearchFeedbackConsentOverlay.module.scss'
import searchFeedbackStyles from './SearchFeedback.module.scss'
import { useTranslation } from 'react-i18next'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  setShowSearchFeedbackConsentOverlay: (status: boolean) => void
}
export const SearchFeedbackConsentOverlay: React.FC<Props> = (props: Props) => {
  const { setShowSearchFeedbackConsentOverlay } = props
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()

  return (
    <div className={searchFeedbackStyles.mainWrapper}>
      <Box className={styles.wrapperIconAndText}>
        <Info className={styles.infoIcon} />
        <Typography variant="body2" className={styles.text}>
          {t('SEARCH.FEEDBACK.ACCEPT_COOKIES')}
        </Typography>
      </Box>

      <div className={styles.setCookieButton}>
        <TertiaryIconButton
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window?.Cookiebot?.show()
            setShowSearchFeedbackConsentOverlay(false)
          }}
          icon={<Settings />}
          size={desktop ? 'small' : 'large'}
        >
          {t('SEARCH.FEEDBACK.SET_COOKIE_CHOICE')}
        </TertiaryIconButton>
      </div>
    </div>
  )
}
