import React, { useContext } from 'react'
import { IArticle } from './types'

/**
 * Article details page uses product(article) across almost all sections/views.
 * Use context to avoid prop drilling problem
 */
const ProductContext = React.createContext<IArticle | null>(null)

export const ProductProvider: React.FC<{ product: IArticle | undefined | null }> = (props) => {
  return (
    <ProductContext.Provider value={props.product || null}>
      {props.children}
    </ProductContext.Provider>
  )
}

export const useProduct = () => {
  return useContext(ProductContext)
}
