import { NotificationEnum } from '../GraphQL/globalTypes'

export enum MessageStatus {
  new = 'new',
  read = 'read',
  deleted = 'deleted',
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Message<D = Record<string, any>> {
  id: string
  event: NotificationEnum
  data: D
  status: MessageStatus
  updatedAt: string
  createdAt: string
}
