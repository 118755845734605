import { FC, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { CustomEditor, EPlugins } from '../reactPageEditor/CustomEditor'
import { useStaticPage } from '@obeta/data/lib/hooks/useStaticPage'
import styles from './staticPage.module.scss'
import { PageIds, ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory, useLocation, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'

export const StaticPage: FC<{ page?: PageIds }> = ({ page }) => {
  const apolloClient = useStrapiGql()

  return (
    <ApolloProvider client={apolloClient}>
      <StaticPageView page={page} />
    </ApolloProvider>
  )
}

const pagesWithDomain = [ShopRoutes.AboutUs, ShopRoutes.PrivacyProtection, ShopRoutes.Terms]
const StaticPageView: FC<{ page?: PageIds }> = ({ page }) => {
  const { slug } = useParams()
  const location = useLocation()
  const history = useHistory()
  const pathName = location.pathname as ShopRoutes

  const domain = useAppDomain()
  const pageId = pagesWithDomain.includes(pathName) ? `${page}-${domain}` : page

  const staticPage = useStaticPage(pageId, slug)

  useEffect(() => {
    if (!staticPage.loading && !staticPage.content) {
      history.replace('/404')
    }
  }, [history, staticPage.content, staticPage.loading])

  return (
    <div className={styles['wrapper']}>
      <CustomEditor
        value={staticPage.content}
        pluginsList={[
          EPlugins.RichText,
          EPlugins.Image,
          EPlugins.Video,
          EPlugins.Spacer,
          EPlugins.PDFLayout,
          EPlugins.ProductCardLayoutPlugin,
          EPlugins.RafflePlugin,
          EPlugins.DigitalBillingPlugin,
        ]}
      ></CustomEditor>
    </div>
  )
}
