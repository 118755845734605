import React, { FC, memo } from 'react'
import { ILocationNews } from '../../hooks/useLocationNews'
import { Typography } from '@mui/material'
import { TypographyLineClamp } from '@obeta/components/lib/typography/TypographyLineClamp'
import { BranchDropdown } from '@obeta/components/lib/branch-dropdown/BranchDropdown'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { LocationOverlayTag } from '../LocationOverlaySection/LocationOverlayTag'
import { ILocationPageContextData } from '../../hooks/LocationPageContextProvider'
import styles from './LocationNewsCard.module.scss'

export const LocationNewsCard: FC<
  ILocationNews & {
    textMaxLines?: number
    changeOverlayLocation: ILocationPageContextData['changeOverlayLocation']
  }
> = memo((props) => {
  const {
    title,
    text,
    relatedStoreInfo,
    imageUrl = '',
    imageAlt = '',
    textMaxLines,
    changeOverlayLocation,
  } = props
  const { mobile } = useBreakpoints()

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.imageWrapper}>
        <img src={imageUrl} alt={imageAlt} />
      </div>
      <div className={styles.contentWrapper}>
        <Typography variant="boldText" className={styles.title}>
          {title}
        </Typography>
        <TypographyLineClamp lines={textMaxLines || 3} variant="body" enforceHeight={true}>
          {text}
        </TypographyLineClamp>
      </div>
      {relatedStoreInfo &&
        (!mobile ? (
          <BranchDropdown storeInfo={relatedStoreInfo} title={relatedStoreInfo.address.name2} />
        ) : (
          <LocationOverlayTag branch={relatedStoreInfo} onClick={changeOverlayLocation} />
        ))}
    </div>
  )
})
