import { FC, useMemo, useState } from 'react'
import { LayoutPlugin } from './plugins/layout'
import { CustomImagePlugin } from './plugins/customImage'
import { slatePlugin } from './plugins/customText'
import { ProgressBarPlugin } from './plugins/progressbarPlugin'
import { PDFLayoutPlugin } from './plugins/pdfLayout'
import { ProductCardLayoutPlugin } from './plugins/customSearchLayout'
import { RafflePlugin } from './plugins/RafflePlugin/RafflePlugin'
import { DigitalBillingPlugin } from './plugins/DigitalBillingPlugin/DigitalBillingPlugin'
import Editor from '@react-page/editor'
import video from '@react-page/plugins-video'
import spacer from '@react-page/plugins-spacer'
import '@obeta/assets/theme/react-page.scss'
import { ValueWithLegacy } from '@react-page/editor/lib/core/types'
import { InsufficientCookieConsentToShowVideosPlugin } from './plugins/VideoPluginNoCookies/InsufficientCookieConsentToShowVideosPlugin'
import { getCookiebotMarketingFlag } from '@obeta/data/lib/components/operations-integration/utils/getCookiebotFlags'
import { useCookiebotMarketingConsentHandling } from '@obeta/data/lib/components/operations-integration/useCookiebotMarketingConsentHandling'

export enum EPlugins {
  RichText = 'slatePlugin',
  Image = 'CustomImagePlugin',
  PromotionLayout = 'LayoutPlugin',
  ProgressBar = 'ProgressBarPlugin',
  Video = 'Video',
  Spacer = 'Spacer',
  PDFLayout = 'PDFLayout',
  ProductCardLayoutPlugin = 'ProductCardLayoutPlugin',
  RafflePlugin = 'RafflePlugin',
  DigitalBillingPlugin = 'DigitalBillingPlugin',
}
interface ICustomEditorProps {
  value?: ValueWithLegacy | null | string
  pluginsList: EPlugins[]
}
const pluginsByName = {
  [EPlugins.RichText]: slatePlugin,
  [EPlugins.Image]: CustomImagePlugin,
  [EPlugins.PromotionLayout]: LayoutPlugin,
  [EPlugins.ProgressBar]: ProgressBarPlugin,
  [EPlugins.Video]: video,
  [EPlugins.Spacer]: spacer,
  [EPlugins.PDFLayout]: PDFLayoutPlugin,
  [EPlugins.ProductCardLayoutPlugin]: ProductCardLayoutPlugin,
  [EPlugins.RafflePlugin]: RafflePlugin,
  [EPlugins.DigitalBillingPlugin]: DigitalBillingPlugin,
}

export const CustomEditor: FC<ICustomEditorProps> = (props) => {
  const { value, pluginsList } = props
  const [marketingConsent, setMarketingConsent] = useState(getCookiebotMarketingFlag() ?? false)

  useCookiebotMarketingConsentHandling(() => {
    setMarketingConsent(getCookiebotMarketingFlag())
  })

  const cellPlugins = useMemo(
    () =>
      pluginsList.map((pluginName) => {
        let plugin = pluginsByName[pluginName]

        if (pluginName === EPlugins.Video) {
          plugin = marketingConsent ? video : InsufficientCookieConsentToShowVideosPlugin
        }

        return plugin
      }),
    [marketingConsent, pluginsList]
  )

  return <Editor value={value as ValueWithLegacy} cellPlugins={cellPlugins} readOnly />
}
