import React from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from '../message/Message'

interface IErrorRemoveArticlesProps {
  cartName: string
  articlesList: string[]
  support: string
}

export const ErrorRemoveArticles: React.FC<IErrorRemoveArticlesProps> = (props) => {
  const { t } = useTranslation()
  const articlesList = props.articlesList.slice(0)
  let lastItem: string | undefined
  if (articlesList.length > 1) {
    lastItem = articlesList.pop()
  }

  return (
    <>
      <Message>
        {t('MESSAGE_CENTER.CANNOT_SUBMIT_POSITIONS_DESC', { cartName: props.cartName })}{' '}
        {articlesList.map((item) => `"${item}"`).join(', ')}
        {lastItem ? ` ${t('COMMON.AND')} "${lastItem}".` : '.'}
      </Message>
      <Message>{t('MESSAGE_CENTER.DELETE_ARTICLES_AND_TRY_AGAIN')}</Message>
      {/*
        TODO: Define CTAs for these actions
      <Message>
        <ActionButton noPaddingLeft={true} noPaddingTop={true} type="underlined">
          {t('MESSAGE_CENTER.COPY_AVAILABLE_CARTS')}
        </ActionButton>
      </Message>
      <Message>
        <ActionButton noPaddingLeft={true} noPaddingTop={true} type="underlined">
          {t('MESSAGE_CENTER.CONTACT_SUPPORT', { support: props.support })}
        </ActionButton>
      </Message>*/}
    </>
  )
}
