import { Collapse, ICollapseProps } from '@obeta/components/lib/collapse/Collapse'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { CollapseCard, CollapseCardIcon } from '@obeta/components/lib/collapse-card/CollapseCard'
import styles from './CollapseComponent.module.scss'
import clsx from 'clsx'
import { E2EProps } from '@obeta/components/lib/types'

export const CollapseComponent: React.FC<
  ICollapseProps & Partial<WithBreakpoints<'mobile'>> & E2EProps
> = (props) => {
  const { mobile, className, ...collapseProps } = props

  // todo: 3rd oprion. navigation component ?
  /**
   *  CategoriesWrapper component: use either CollapseComponent either *some other comp*.
   *
   *    - Collpase uses ActionButton, but we still should pass CatIcon with title as children
   *    of this button (custom collapse header)
   *
   *    - Can we use CardComponent for cats?
   */

  if (mobile) {
    return (
      <Collapse
        className={clsx(styles.root, className)}
        {...collapseProps}
        Icon={CollapseCardIcon}
      />
    )
  }

  return <CollapseCard {...collapseProps} Icon={CollapseCardIcon} />
}
