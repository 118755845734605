import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import {
  CartsActionTypes,
  getOciFormFields,
  GetOciFormFieldsGraphQLResultAction,
} from '../../actions'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { useActionNotification } from '../useActionNotification'
import { useUserDataV2 } from '../useUserDataV2'
import { submitOciPostForm } from '@obeta/utils/lib/ociHelper'

type TransferOciCartAndSubmit = (cart?: ShoppingCartV2) => void
type UseOciCartTransfer = () => TransferOciCartAndSubmit

export const useOciCartTransfer: UseOciCartTransfer = () => {
  const dispatch = useDispatch()
  const waitForOciFormFieldsResultAction = useActionNotification(
    CartsActionTypes.GetOciFormFieldsGraphQLResult
  )
  const { userId, companyId } = useUserDataV2()

  const validUserConditions = userId && companyId && userId?.length > 0 && companyId?.length > 0

  return useCallback(
    async (cart?: ShoppingCartV2) => {
      const sessionContext = getSessionContext()
      if (cart && sessionContext?.hookUrl && validUserConditions) {
        dispatch(getOciFormFields(cart.id))
        waitForOciFormFieldsResultAction((action: GetOciFormFieldsGraphQLResultAction) => {
          if (action.ociFormFieldsResult?.success && action.ociFormFieldsResult?.formFields) {
            submitOciPostForm(
              action.ociFormFieldsResult.formFields,
              sessionContext.hookUrl as string
            )
          }
        })
      }
    },
    [dispatch, validUserConditions, waitForOciFormFieldsResultAction]
  )
}
