import React, { createContext } from 'react'
import { CartTemplateDetailsStateHandler } from './reducer/useCartTemplateDetailsState'
import { initialCartTemplateDetailsState } from './reducer/cartTemplateDetailsReducer'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { CartTemplateForDetailsPage } from '@obeta/models/lib/schema-models'

const initialEmptyFunction = () => {
  /**/
}

export type CartTemplateDetailsContextType = CartTemplateDetailsStateHandler & {
  isInitiallyLoading: boolean
  isLoadingProducts: boolean
  isLoadingItems: boolean
  selectedStore?: StoreV2
  cartTemplate: CartTemplateForDetailsPage
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

export const CartTemplateDetailsContext = createContext<CartTemplateDetailsContextType>({
  getSelectedCartTemplateItemsLength(): number {
    return 0
  },
  state: initialCartTemplateDetailsState,
  changePage: initialEmptyFunction,
  limit: 0,
  offset: 0,
  search: initialEmptyFunction,
  receiveItems: initialEmptyFunction,
  receiveProducts: initialEmptyFunction,
  updateCartTemplateState: initialEmptyFunction,
  updateCartTemplateItemsState: initialEmptyFunction,
  getAmountOfPages: () => 0,
  isInitiallyLoading: false,
  isLoadingProducts: false,
  isLoadingItems: false,
  selectedStore: undefined,
  cartTemplate: {} as CartTemplateForDetailsPage,
  paginatedItems: [],
  searchTerm: '',
  resetSelectedItems: initialEmptyFunction,
  selectAllItems: initialEmptyFunction,
  selectItem: initialEmptyFunction,
  setSearchTerm: initialEmptyFunction,
})

interface Props {
  value: CartTemplateDetailsContextType
}

export const CartTemplateDetailsProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <CartTemplateDetailsContext.Provider value={value}>
      {children}
    </CartTemplateDetailsContext.Provider>
  )
}
