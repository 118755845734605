import { ICategory, IFilter, ILocation } from '@obeta/models/lib/models/RentArticle/types'

export const ALL_VALUE = 'Alle'
export const ALL_CATEGORIES: ICategory = {
  type: 'category',
  value: ALL_VALUE,
  count: 0,
}
export const ALL_LOCATIONS: ILocation = {
  type: 'location',
  value: ALL_VALUE,
}
export const EMPTY_SEARCH: IFilter = {
  type: 'search',
  value: '',
}
