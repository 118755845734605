import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'
import {
  getOpenPositions,
  GetOpenPositionsResultAction,
  OpenPositionsActionTypes,
} from '../../actions/open-positions-actions'
import { useActionNotification } from '../useActionNotification'
import { useEntities } from '../useEntities'
import { useSortedAndFilteredOpenPositions } from './useSortedAndFilteredOpenPositions'

type OpenPositionsPageStatus = 'loading' | 'empty' | 'dataAvailable' | 'noDataAvailable'
type OpenPositionsPageData = {
  status: OpenPositionsPageStatus
  openPositions: OpenPosition[]
  searchResults: OpenPosition[]
  sortedAndFilteredOpenPositions: OpenPosition[]
  onFilterItemsBySearchTerm: (searchTerm: string) => void
}
type UseOpenPositionsPageData = () => OpenPositionsPageData

export const useOpenPositionsPageData: UseOpenPositionsPageData = () => {
  const openPositionsLocalState = useRef<Array<OpenPosition & { initialPosition: number }> | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(true)
  const openPositionsRxDb: Array<OpenPosition & { initialPosition: number }> =
    useEntities('openpositions')
  // only use (offline) data from entities if we were not able to fetch data
  const openPositions = openPositionsLocalState.current
    ? openPositionsLocalState.current
    : openPositionsRxDb
  const {
    items: sortedAndFilteredOpenPositions,
    onFilterItemsBySearchTerm,
    searchResults,
  } = useSortedAndFilteredOpenPositions({
    openPositions,
  })
  const dispatch = useDispatch()
  const waitForOpenPositionsResult = useActionNotification(
    OpenPositionsActionTypes.GetOpenPositionsResult
  )

  let status: OpenPositionsPageStatus = 'empty'

  useEffect(() => {
    dispatch(getOpenPositions())

    waitForOpenPositionsResult((result: GetOpenPositionsResultAction) => {
      openPositionsLocalState.current = result.items as Array<
        OpenPosition & { initialPosition: number }
      >
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (openPositions.length > 0) {
    status = 'dataAvailable'
  } else if (isLoading) {
    status = 'loading'
  } else if (openPositions.length === 0) {
    status = 'noDataAvailable'
  }

  return {
    openPositions,
    sortedAndFilteredOpenPositions: sortedAndFilteredOpenPositions,
    status,
    onFilterItemsBySearchTerm,
    searchResults,
  }
}
