import { TextAvailable } from '@obeta/components/lib/text-available/TextAvailable'
import { Typography } from '@mui/material'

export interface ArticleStickyHeaderPriceProps {
  userCanReadPrices: boolean
  price: string | null
  mobile?: boolean
}

type Props = ArticleStickyHeaderPriceProps & { loadingPrices: boolean }

export const ArticleStickyHeaderPrice: React.FC<Props> = ({
  userCanReadPrices,
  price,
  mobile,
  loadingPrices,
}) => {
  if (!userCanReadPrices) return null

  return (
    <TextAvailable available={!loadingPrices}>
      <Typography noWrap variant={`${mobile ? 'boldText' : 'headline4Bold'}`}>
        {price}
      </Typography>
    </TextAvailable>
  )
}
