import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import generateShortUUID from 'short-uuid'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { initApollo } from '@obeta/app-bootstrap/lib/apolloClient'
import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'
import { useOxomiIntegration } from '@obeta/data/lib/hooks/useOxomiIntegration'
import { waitForField } from '@obeta/utils/lib/waitFor/waitForField'
import { waitForResolve } from '@obeta/utils/lib/waitFor/waitForResolve'
import { OxomiGlobal } from '@obeta/models/lib/models/Oxomi'
import styles from './pdfLayout.module.scss'
import { useRxDB } from 'rxdb-hooks'

const graphqlUrlShop = process.env.NEXT_PUBLIC_GRAPHQL_HOST as string
const graphqlUrlApp = process.env.REACT_APP_NEXT_PUBLIC_GRAPHQL_HOST as string

const PDFLayout = (props) => {
  const appActions = useAppActions()
  const db = useRxDB()
  const client = initApollo(graphqlUrlApp || graphqlUrlShop, appActions, false, db)

  return (
    <ApolloProvider client={client}>
      <PDFLayoutView {...props} />
    </ApolloProvider>
  )
}

const PDFLayoutView = (props) => {
  const { data } = props
  const { documentId } = data
  const [oxomiTargetId] = useState(generateShortUUID.generate)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  useOxomiIntegration({ documentId })

  useEffect(() => {
    const setupPdfLayout = async () => {
      setLoading(true)
      try {
        const oxomi = await waitForField<OxomiGlobal>({ field: 'oxomi' })
        await waitForResolve({ callback: oxomi.isInitialized })

        oxomi.embedCatalog({
          target: `#${oxomiTargetId}`,
          catalog: documentId,
        })
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    setupPdfLayout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id={oxomiTargetId} className={styles['pdf-layout-wrapper']}>
      {isLoading && <Typography>{t('MAIN.LOADING')}</Typography>}
      {error && <Typography>{t('MAIN.ERROR')}</Typography>}
    </div>
  )
}

export const PDFLayoutPlugin = {
  id: 'de/obeta/plugins/pdf-layout-plugin',
  title: 'PDF Layout',
  description: 'Layout with image and text to open by click PDF file',
  Renderer: PDFLayout,
  version: 1,
}
