import React, { useRef } from 'react'
import { PageContent } from '../../page-content/PageContent'
import { PageMarginWrapper } from '@obeta/components/lib/page-margin-wrapper/PageMarginWrapper'
import { PageElementsProvider } from '@obeta/components/lib/page-elements/PageElements'
import { ResponsiveHeader } from '@obeta/components/lib/responsive-header'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { ReactRouterParamsProvider } from '@obeta/data/lib/hooks/useHistoryApi'
import { PageBackgroundWrapper } from '@obeta/components/lib/page-background-wrapper/PageBackgroundWrapper'
import styles from './Content.module.scss'

const LEGACY_PAGES = ['/carts/import', '/message-center']

const checkLegacyPage = (path: string) => LEGACY_PAGES.indexOf(path) !== -1

const getRoutePathname = (path: string) => (path === '/start' ? ShopRoutes.Root : path)

export const WrappedContentRoutes = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const { location } = useHistory()

  const ROUTE_PATHNAME = getRoutePathname(location.pathname)

  const isLegacyPage = checkLegacyPage(ROUTE_PATHNAME)

  return (
    <ReactRouterParamsProvider>
      {/* The responsive header uses the useParams hook, so it needs to be wrapped in the ReactRouterParamsProvider as well. */}
      <ResponsiveHeader headerRef={headerRef} />
      <div className={styles.contentWrapper}>
        <div className={styles.pageWrapper}>
          {!isLegacyPage ? (
            <PageBackgroundWrapper path={ROUTE_PATHNAME}>
              <div className={styles.root}>
                <PageMarginWrapper pageRoute={ROUTE_PATHNAME}>
                  <PageContent>
                    <PageElementsProvider pageRoute={ROUTE_PATHNAME} headerElementRef={headerRef}>
                      {children}
                    </PageElementsProvider>
                  </PageContent>
                </PageMarginWrapper>
              </div>
            </PageBackgroundWrapper>
          ) : (
            children
          )}
        </div>
      </div>
    </ReactRouterParamsProvider>
  )
}
