import React, { memo } from 'react'
import { Box, Typography, Modal, TextField } from '@mui/material'
import styles from './OverlayThumbDownFeedback.module.scss'
import { useTranslation } from 'react-i18next'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { SearchFeedbackValue } from '../search-feedback/SearchFeedback'

interface Props {
  showOverlayThumbDownFeedback: boolean
  setShowOverlayThumbDownFeedback: (status: boolean) => void
  setShowOverlayThankYouThumbDown: (status: boolean) => void
  setSearchFeedbackValue: (value: SearchFeedbackValue) => void
  searchFeedbackValue: SearchFeedbackValue
  setSendFeedbackToDataDog: (value: boolean) => void
}

export const OverlayThumbDownFeedback: React.FC<Props> = memo((props) => {
  const {
    showOverlayThumbDownFeedback,
    setShowOverlayThumbDownFeedback,
    setShowOverlayThankYouThumbDown,
    setSearchFeedbackValue,
    searchFeedbackValue,
    setSendFeedbackToDataDog,
  } = props
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' })
  const inputSearchFeedbackComment = useWatch({ control, name: 'searchFeedbackComment' }) ?? ''

  const handleClose = () => {
    setShowOverlayThumbDownFeedback(false)
    setSendFeedbackToDataDog(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: Record<string, any>) => {
    setSearchFeedbackValue({ ...searchFeedbackValue, comment: data.searchFeedbackComment })
    setShowOverlayThankYouThumbDown(true)
    handleClose()
  }

  return (
    <Modal
      open={showOverlayThumbDownFeedback}
      onClose={handleClose}
      aria-labelledby="thumbdown-feedback-title"
      aria-describedby="thumbdown-feedback-description"
    >
      <div className={styles.wrapperContainer}>
        <Box className={styles.headerBox}>
          <Typography variant={'headline4Bold'} color={'text.primary'}>
            {t('SEARCH.FEEDBACK.THANK_YOU_FOR_YOUR_FEEDBACK')}
          </Typography>
          <TertiaryButton
            leftIcon={<CloseIcon />}
            onClick={handleClose}
            size={desktop ? 'small' : 'large'}
          />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Box className={clsx(styles.marTopOneRem, styles.marBottomHalfRem)}>
            <Typography variant={'body'} color={'text.primary'}>
              {t('SEARCH.FEEDBACK.YOUR_OPINION_MATTERS')}
            </Typography>
            <Controller
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  className={clsx(styles.textField, styles.marTopHalfRem)}
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 2000 }}
                  variant="outlined"
                  id="searchFeedbackComment"
                  name="searchFeedbackComment"
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('SEARCH.FEEDBACK.THUMB_DOWN_PLACEHOLDER')}
                />
              )}
              name="searchFeedbackComment"
              control={control}
              rules={{
                maxLength: 2000,
              }}
              defaultValue={''}
            />
          </Box>
          <div className={styles.divCharCount}>
            <Typography
              data-testid="thumb-down-comment-charCount"
              variant={'smallText'}
              className={styles.charCount}
            >
              {(inputSearchFeedbackComment as string).length}{' '}
              {t('SEARCH.FEEDBACK.THUMB_DOWN_FEEDBACK_MAX_LENGTH')}
            </Typography>
          </div>
          <LightGrayDivider className={clsx(styles.marTopOneRem, styles.marBottomOneRem)} />
          <Box className={styles.boxButtons}>
            <TertiaryButton onClick={handleClose} size={'large'}>
              {t('SEARCH.FEEDBACK.ABORT')}
            </TertiaryButton>
            <DarkButton type="submit" disabled={!isValid} fullWidth>
              {t('SEARCH.FEEDBACK.SUBMIT_FEEDBACK')}
            </DarkButton>
          </Box>
        </form>
      </div>
    </Modal>
  )
})
