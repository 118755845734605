import {
  CartVerificationErrorCode,
  OrderButtonActionType,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import {
  CartsActionTypes,
  updateCartGraphQL,
  verifyCartGraphQL,
  VerifyCartGraphQLResultAction,
  verifyOfferInCartGraphQL,
  VerifyOfferInCartGraphQLResultAction,
} from '../../actions'
import { useActionNotification } from '../useActionNotification'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useDispatch } from 'react-redux'
import { displayOfferInCartNotValid } from '@obeta/utils/lib/displayOrderFailureNotifications'

export function afterCartVerification(
  selectedCartId: string,
  setCartVerificationErrorCode: (cartVerificationErrorCode: CartVerificationErrorCode) => void,
  setOrderSubmitted: (orderSubmitted: boolean) => void,
  cartVerificationActionType: OrderButtonActionType.Notify | OrderButtonActionType.Submit,
  callback
) {
  return (action: VerifyCartGraphQLResultAction) => {
    // 3. lack of permissions prevents notify as well as ordersubmit
    if (action.results.length > 0) {
      setOrderSubmitted(false)
      const errorCode = action.results[0].errorCode
      switch (errorCode) {
        case CartVerificationErrorCode.UserNotPermitted: {
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.NoPermissionGranted,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.UserNotPermitted)
          break
        }
        case CartVerificationErrorCode.ShippingBlocked: {
          // show notification
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.ShippingBlocked,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.ShippingBlocked)
          break
        }
        case CartVerificationErrorCode.OrderingBlocked: {
          // show notification
          getEventSubscription().next({
            type: EventType.Toast,
            notificationType: NotificationType.OrderFailure,
            id: `${selectedCartId}-${NotificationType.OrderFailure}`,
            options: {
              reason: OrderFailureReason.OrderingBlocked,
            },
          })
          setCartVerificationErrorCode(CartVerificationErrorCode.OrderingBlocked)
          break
        }
        case CartVerificationErrorCode.CreditLimitExceeded: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.CreditLimitExceeded)
          }
          break
        }
        case CartVerificationErrorCode.ExpressOutOfStock: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ExpressOutOfStock)
          }
          break
        }
        case CartVerificationErrorCode.ExpressOrderDeadlineExceeded: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ExpressOrderDeadlineExceeded)
          }
          break
        }
        case CartVerificationErrorCode.ContainsProductsNotForSale: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ContainsProductsNotForSale)
          }
          break
        }
        case CartVerificationErrorCode.ShippingDateNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ShippingDateNotPossible)
          }
          break
        }
        case CartVerificationErrorCode.ShippingDateNotPossibleAnymore: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.ShippingDateNotPossibleAnymore)
          }
          break
        }
        case CartVerificationErrorCode.UserIsCashCustomer: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.UserIsCashCustomer)
          }
          break
        }
        case CartVerificationErrorCode.DeliveryOptionNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.DeliveryOptionNotPossible)
          }
          break
        }
        case CartVerificationErrorCode.PickupOptionNotPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.PickupOptionNotPossible)
          }
          break
        }
        case CartVerificationErrorCode.OnlyPickupPossible: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.OnlyPickupPossible)
          }
          break
        }
        case CartVerificationErrorCode.WrongPhoneNumber: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.WrongPhoneNumber)
          }
          break
        }
        case CartVerificationErrorCode.AddressNotWithinDeliveryArea: {
          if (cartVerificationActionType === OrderButtonActionType.Submit) {
            setCartVerificationErrorCode(CartVerificationErrorCode.AddressNotWithinDeliveryArea)
          }
        }
      }

      // notify is always allowed (only prevented if permissions are not granted)
      if (
        cartVerificationActionType === OrderButtonActionType.Notify &&
        errorCode !== CartVerificationErrorCode.UserNotPermitted
      ) {
        callback()
      }
      return
    }
    callback()
  }
}

export function useOnCartUpdateAndVerifySuccess({
  cart,
  setOrderSubmitted,
  cartVerificationActionType,
  setCartVerificationErrorCode,
  setOrderDeclarationsInCart,
  selectedCartId,
  skipVerification,
}: {
  cart?: ShoppingCartV2
  setOrderSubmitted: (orderSubmitted: boolean) => void
  cartVerificationActionType: OrderButtonActionType
  setCartVerificationErrorCode: (cartVerificationErrorCode: CartVerificationErrorCode) => void
  setOrderDeclarationsInCart: () => void
  selectedCartId: string
  skipVerification?: boolean
}) {
  const dispatch = useDispatch()
  const waitForResultAction = useActionNotification(CartsActionTypes.VerifyCartGraphQLResult)
  const waitForUpdateCartResultAction = useActionNotification(
    CartsActionTypes.UpdateCartGraphQLResult
  )
  const waitForVerifyOfferInCartResultAction = useActionNotification(
    CartsActionTypes.VerifyOfferInCartGraphQLResult
  )

  if (!cart) return

  return (callback, preventCartUpdate?: boolean) => {
    if (cartVerificationActionType === OrderButtonActionType.Submit && setOrderSubmitted) {
      setOrderSubmitted(true)
    }

    // cart update on dedicated create-order page is done in a previous step
    if (!preventCartUpdate) {
      setOrderDeclarationsInCart()

      // 1. save
      dispatch(updateCartGraphQL(cart))
    }

    // 2a. - no verification needed? -> submit or notify immediately
    if (skipVerification) {
      callback()
      return
    }

    // 2b. verify
    waitForUpdateCartResultAction(() => {
      dispatch(verifyCartGraphQL(selectedCartId))
    })
    waitForResultAction((resultAction) => {
      const callAfterCartVerification = afterCartVerification(
        selectedCartId,
        setCartVerificationErrorCode,
        setOrderSubmitted,
        cartVerificationActionType,
        callback
      )

      if (cart.offerId !== '') {
        // 3. verify offer in cart
        dispatch(verifyOfferInCartGraphQL(selectedCartId))
        waitForVerifyOfferInCartResultAction((action: VerifyOfferInCartGraphQLResultAction) => {
          if (!action.success) {
            displayOfferInCartNotValid(selectedCartId, action.result)
          } else {
            callAfterCartVerification(resultAction)
          }
        })
      } else {
        callAfterCartVerification(resultAction)
      }
    })
  }
}
