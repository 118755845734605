interface StackReducerState {
  stack: string[]
  index: number
}

type StackReducerPushAction = {
  type: 'push'
  payload: {
    name: string
  }
}

type StackReducerGoAction = {
  type: 'go'
  payload: {
    delta: number
  }
}

type StackReducerAction = StackReducerPushAction | StackReducerGoAction

export function stackNavigatorReducer(
  state: StackReducerState,
  action: StackReducerAction
): StackReducerState {
  switch (action.type) {
    case 'push': {
      const newStack = state.stack.slice(0, state.index + 1)
      newStack.push(action.payload.name)

      return {
        stack: newStack,
        index: newStack.length - 1,
      }
    }

    case 'go': {
      const newIndex = state.index + action.payload.delta

      return {
        stack: state.stack,
        index: Math.max(0, Math.min(state.stack.length - 1, newIndex)),
      }
    }
  }
}

interface StackNavigatorReducerInitializerOptions {
  initialScreenName: string
}

export function stackNavigatorReducerInitializer(
  options: StackNavigatorReducerInitializerOptions
): StackReducerState {
  return {
    stack: [options.initialScreenName],
    index: 0,
  }
}
