import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from '../modal/Modal'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as CloseIcon } from 'assets/icon/close_3.svg'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import { RadioItem } from '../checbox-radio-item/CheckboxRadioItem'
import { getCheckboxItemConfig } from './utils/getCheckboxItemConfig'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { createParamsFromFormData } from './utils/createParamsFromFormData'
import {
  DatsheetPrintOptions,
  DatasheetConfig,
} from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'

import { DatasheetFormManager } from './components/datasheet-form-manager/DatasheetFormManager'
import styles from './PrintConfigDatasheet.module.scss'
import { PricePermissions, UserV2Permissions } from '@obeta/models/lib/models/Users/UserV2'

type IndividualDatasheetProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (requestParamsUrl: string) => void
  permissions?: UserV2Permissions
  isAdmin?: boolean
}

export const PrintConfigDatasheet: FC<IndividualDatasheetProps> = ({
  isOpen,
  onClose,
  onSubmit,
  permissions,
  isAdmin,
}) => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()

  const { Global_canChangeDataSheetPrice, Global_canReadPrices } = permissions || {}

  const methods = useForm<DatasheetConfig>({
    defaultValues: { type: DatsheetPrintOptions.STANDART },
  })

  const { handleSubmit } = methods

  const onDatasheetSubmit = (data: DatasheetConfig) => {
    const requestParamsUrl = createParamsFromFormData(data)
    onSubmit(requestParamsUrl)
  }

  const getIndividualConfigTitle = () => {
    if (isAdmin) {
      return (
        <Trans
          i18nKey="INDIVIDUAL_DATASHEET.INDIVIDUAL_CONFIG_INFO_TEXT_ADMIN"
          // TODO: redirect to admin page. https://www.figma.com/design/cNewKGVjW8VmrdhtsceSw8/Profileinstellungen-User-Account?node-id=0-1&t=gMiktY8fJS2R1YAN-0.
          components={{ strong: <strong className={styles.settingsLink} /> }}
        />
      )
    }

    if (Global_canChangeDataSheetPrice && Global_canReadPrices === PricePermissions.PurchasePrice) {
      return t('INDIVIDUAL_DATASHEET.INDIVIDUAL_CONFIG_INFO_TEXT_USER_WITH_GLOBAL_PRICE_RIGHTS')
    }

    return t('INDIVIDUAL_DATASHEET.INDIVIDUAL_CONFIG_INFO_TEXT_USER_WITH_RESTRICTED_PRICE_RIGHTS')
  }

  return (
    <Modal
      isFullScreen={mobile}
      classNames={{ content: styles.modal }}
      disableAutoFocus
      disableBodyScroll
      onClose={onClose}
      open={isOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.topSection}>
          <div className={styles.titleWrapper}>
            <Typography variant="headline4Bold">{t('INDIVIDUAL_DATASHEET.PRINT')}</Typography>
            <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
              <CloseIcon className={styles.icon} />
            </IconButton>
          </div>
        </div>

        <div className={styles.infoSection}>
          <Typography variant="subTitle">{t('INDIVIDUAL_DATASHEET.TITLE')}</Typography>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore https://github.com/react-hook-form/react-hook-form/issues/4745, https://github.com/react-hook-form/react-hook-form/pull/6753*/}
        <FormProvider {...methods}>
          <div className={styles.configurationSection}>
            <Controller
              name="type"
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <div className={styles.printOption}>
                      <RadioItem
                        onChange={onChange}
                        checked={value === DatsheetPrintOptions.STANDART}
                        label={t('INDIVIDUAL_DATASHEET.STANDART_CONFIG')}
                        {...getCheckboxItemConfig(DatsheetPrintOptions.STANDART)}
                      />
                      <div className={styles.printInfoText}>
                        <Typography variant="body">
                          {t('INDIVIDUAL_DATASHEET.STANDART_CONFIG_INFO_TEXT')}
                        </Typography>
                      </div>
                    </div>
                    <div className={styles.printOption}>
                      <RadioItem
                        onChange={onChange}
                        checked={value === DatsheetPrintOptions.INDIVIDUAL}
                        label={t('INDIVIDUAL_DATASHEET.INDIVIDUAL_CONFIG')}
                        {...getCheckboxItemConfig(DatsheetPrintOptions.INDIVIDUAL)}
                      />
                      <div className={styles.printInfoText}>
                        <Typography variant="body">{getIndividualConfigTitle()}</Typography>
                      </div>
                    </div>
                  </>
                )
              }}
            />
            <DatasheetFormManager isAdmin={isAdmin} permissions={permissions} />
          </div>
        </FormProvider>
        <div className={styles.bottomSection}>
          <div className={styles.bottomSectionButtonsWrapper}>
            <TertiaryButton onClick={onClose}>{t('BUTTON.ABORT')}</TertiaryButton>
            <DarkButton type="submit" onClick={handleSubmit(onDatasheetSubmit)}>
              {t('BUTTON.PRINT')}
            </DarkButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
