import clsx from 'clsx'
import styles from './CustomImage.module.scss'

export const CustomImagePlugin = {
  Renderer: ({ data }) => (
    <div className={clsx(styles['image-wrapper'], 'obeta-custom-image')}>
      <img src={data.url} alt="illustration" />
    </div>
  ),
  id: 'de/obeta/plugins/strapi-image',
  isInlinable: true,
  title: 'Image',
  description: 'Custom image plugin',
  version: 1,
}
