import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useActionNotification } from '../../hooks'
import { CartsActionTypes, updateCartGraphQL } from '../../actions'
import { startOrderProcess } from '@obeta/utils/lib/order-process-information'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useHistory } from '../../hooks/useHistoryApi'

type CreateOrderRedirectOptions = {
  cart?: ShoppingCartV2
  commission: string
  phone: string
  remark: string
  onCartUpdate?: () => void
  preventRedirect?: boolean
  isApprovalRequest?: boolean
}
export const useCreateOrderRedirect = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const waitForUpdateCartResultAction = useActionNotification(
    CartsActionTypes.UpdateCartGraphQLResult
  )
  return useCallback(
    ({
      cart,
      commission,
      phone,
      remark,
      onCartUpdate,
      preventRedirect,
      isApprovalRequest,
    }: CreateOrderRedirectOptions) => {
      if (cart) {
        // Some fields must be sent before submitting, otherwise they will not be saved. (commission, phone and remark)
        // Remove fields that do not need to be updated. Maybe we need to remove further fields later on
        const { paymentMethod, ...cartWithoutPaymentMethod } = cart
        dispatch(
          updateCartGraphQL({
            ...cartWithoutPaymentMethod,
            commission,
            phone,
            remark,
          })
        )
        waitForUpdateCartResultAction(() => {
          startOrderProcess(cart.id, Boolean(isApprovalRequest))
          if (onCartUpdate) onCartUpdate()
          if (!preventRedirect) history.push(`${ShopRoutes.CreateOrderPage}/${cart.id}`)
        })
      }
    },
    [dispatch, history, waitForUpdateCartResultAction]
  )
}
