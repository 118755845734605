import { gql, useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'

import { GetProductImagesQuery, GetProductImagesQueryVariables } from '@obeta/schema'
import { ProductImage } from '@obeta/models/lib/schema-models/product-image'

export const searchQuery = gql`
  query getProductImages($sapIds: [String!]) {
    getProducts(sapIds: $sapIds) {
      sapId
      images {
        url
        width
      }
    }
  }
`

export const useProductImages = (
  sapIds: string[],
  skip?: boolean
): { images: ProductImage[]; isFetchingImages: boolean } => {
  const [isFetchingImages, setIsFetchingImages] = useState(false)
  const [memoizedSapIds, setMemoizedSapIds] = useState<string[]>([])
  const [memoizedImages, setMemoizedImages] = useState<ProductImage[]>([])
  const client = useApolloClient()

  const get = async (sapIds: string[]) => {
    if (sapIds.length > 0) {
      setIsFetchingImages(true)
      const response = await client.query<GetProductImagesQuery, GetProductImagesQueryVariables>({
        query: searchQuery,
        variables: {
          sapIds: sapIds,
        },
      })
      setMemoizedImages([...memoizedImages, ...response.data.getProducts])
      setIsFetchingImages(false)
      const allSapIds = [...sapIds, ...memoizedSapIds]
      const uniqueSapIdsSet = new Set(allSapIds)
      const newMemoizedSapIds = Array.from(uniqueSapIdsSet)
      setMemoizedSapIds(newMemoizedSapIds)
    }
  }

  useEffect(() => {
    const itemsToFetch = sapIds.filter((item) => !memoizedSapIds.includes(item))
    if (!skip) {
      get(itemsToFetch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, JSON.stringify(sapIds)])

  return { images: memoizedImages, isFetchingImages }
}
