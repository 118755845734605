import React from 'react'
import { Typography } from '@mui/material'
import styles from './WarningSelectAnotherPayment.module.scss'
import { ReactComponent as Warning } from 'assets/icon/designsystem/warning.svg'
import { useTranslation } from 'react-i18next'

export const WarningSelectAnotherPayment: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.warningContainer}>
      <Warning className={styles.warningIcon}></Warning>
      <Typography variant="smallText" className={styles.warningText}>
        {t('SHOPPING_CART.CHECKOUT.SELECT_ANOTHER_PAYMENT')}
      </Typography>
    </div>
  )
}
