import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { StoresMap } from '@obeta/components/lib/stores-map/StoresMap'
import { Modal } from '@obeta/components/lib/modal/Modal'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import { StackScreen, useStackHistory } from '@obeta/components/lib/stack'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { SvgIcon } from '@mui/material'
import { LOCATION_SCREENS } from '../../LocationPage'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useTranslation } from 'react-i18next'
import styles from './LocationOverlay.module.scss'

const adaptMainScreenHeight = (
  mainScreen: HTMLElement | null,
  screenContentWrapperRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  if (mainScreen) {
    const mainContainerHeight = screenContentWrapperRef?.current?.offsetHeight || 0

    mainScreen.style.height = mainContainerHeight + 'px'
    mainScreen.style.overflow = 'hidden'
  }
}

const unsetMainScreenHeight = (mainScreen: HTMLElement | null) => {
  if (mainScreen) {
    mainScreen.style.height = 'unset'
    mainScreen.style.overflow = 'unset'
  }
}

export const LocationOverlay = () => {
  const { desktop, mobile } = useBreakpoints()
  const { overlayLocation, changeOverlayLocation } = useLocationPageProvider()
  const history = useStackHistory()
  const isNativeUITarget = isUiTarget('app')
  const { t } = useTranslation()
  const screenContentWrapperRef = useRef<HTMLDivElement | null>(null)
  const mainScreen = document.getElementById('mainScreen')

  useEffect(() => {
    if (mobile && overlayLocation) {
      history.push(LOCATION_SCREENS.STORE_OVERLAY)
    }

    if (!mobile) {
      unsetMainScreenHeight(mainScreen)
      history.push(LOCATION_SCREENS.MAIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlayLocation, mobile])

  useEffect(() => {
    if (mobile && history.name === LOCATION_SCREENS.STORE_OVERLAY) window.scrollTo({ top: 0 })
  }, [isNativeUITarget, history.name, mobile])

  // Since the current screen uses position: absolute to maintain the animation,
  // while the main one uses position: static,
  // we set the height of the main screen to match the current one to maintain the correct height proportions
  useEffect(() => {
    if (mobile && history.name === LOCATION_SCREENS.STORE_OVERLAY) {
      adaptMainScreenHeight(mainScreen, screenContentWrapperRef)
    }
  }, [mainScreen, mobile, history.name, screenContentWrapperRef])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {mobile ? (
        <StackScreen
          className={clsx(styles.stackScreen, styles.stackScreenDetail)}
          name={LOCATION_SCREENS.STORE_OVERLAY}
        >
          <div className={styles.screenContentWrapper} ref={screenContentWrapperRef}>
            <div className={styles.backButton}>
              <TertiaryButton
                fullWidth={false}
                leftIcon={<SvgIcon component={ChevronLeftIcon} />}
                size="large"
                onClick={() => {
                  changeOverlayLocation(null)
                  unsetMainScreenHeight(mainScreen)
                  history.goBack()
                }}
              >
                {t('LOCATION_PAGE.BACK_TO_LOCATIONS')}
              </TertiaryButton>
            </div>
            <div className={clsx(styles.overlayWrapper, styles.overlayWrapperMobile)}>
              <StoresMap
                isFullScreenHidden={true}
                currentStore={overlayLocation}
                classes={{
                  mapContainer: styles.mapContainer,
                }}
                onCloseMap={() => changeOverlayLocation(null)}
                isOverlayMode={true}
              />
            </div>
          </div>
        </StackScreen>
      ) : (
        <Modal
          isFullScreen={false}
          disableAutoFocus
          classNames={{ content: styles.modalContent }}
          fitContent={desktop}
          disableBodyScroll={true}
          onClose={() => changeOverlayLocation(null)}
          open={!!overlayLocation}
        >
          <div className={styles.overlayWrapper}>
            <StoresMap
              currentStore={overlayLocation}
              classes={{
                mapContainer: styles.mapContainer,
              }}
              onCloseMap={() => changeOverlayLocation(null)}
              isOverlayMode={true}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
