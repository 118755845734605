import clsx from 'clsx'
import React from 'react'
import styles from './HorizontalDummy.module.scss'
import { Skeleton } from '@mui/material'

export const HorizontalDummy: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className, ...restProps } = props

  return (
    <div className={clsx(styles.root, className)} {...restProps}>
      <div className={styles.row}>
        <div className={styles.image}>
          <Skeleton variant="rectangular" className={styles.skeleton}></Skeleton>
        </div>
        <div className={styles.metawrap}>
          <Skeleton variant="rectangular" className={styles.titleSkeleton}></Skeleton>
          <Skeleton variant="rectangular" className={styles.contentSkeleton}></Skeleton>
        </div>
      </div>
    </div>
  )
}
