import { Chip as MuiChip, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as CloseIcon3 } from '@obeta/assets/icon/close_3.svg'
import styles from './Chip.module.scss'
import clsx from 'clsx'

export const Chip: React.FC<React.ComponentProps<typeof MuiChip>> = (props) => {
  const { className, label: _label, classes = {}, ...rest } = props

  let deleteIcon = props.deleteIcon
  if (!deleteIcon) {
    deleteIcon = <CloseIcon3 />
  }

  let label = _label
  if (typeof _label === 'string') {
    label = <Typography variant="bodyBold">{label}</Typography>
  }

  return (
    <MuiChip
      label={label}
      className={clsx(styles.chip, className)}
      classes={{
        ...classes,
        label: clsx(styles.label, classes.label),
        deleteIcon: clsx(styles.icon, classes.deleteIcon),
      }}
      deleteIcon={deleteIcon}
      {...rest}
    />
  )
}
