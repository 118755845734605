import React, { useContext } from 'react'
import { Fade } from '@mui/material'
import {
  ShoppingCartContextEnum,
  ShoppingCartItem,
  ShoppingCartPrices,
} from '@obeta/models/lib/models/ShoppingCart'
import { ShoppingCartDetailsSelectionBarMain } from './ShoppingCartDetailsSelectionBarMain'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import { IsPaymentMethodPermittedContext } from '@obeta/data/lib/hooks/useIsPaymentMethodPermittedProvider'

interface Props {
  cartId: string
  closeNotificationBox?: () => void
  context: ShoppingCartContextEnum
  enableEditingMode?: () => void
  orderButtonEnabled?: boolean
  shoppingCartPrices: ShoppingCartPrices
  notifyShoppingCart?: () => void
  submitShoppingCart?: () => void
  userCanSubmitCart?: boolean
  userCanReadPrices?: boolean
  isDeactivated?: boolean
  deliveryDisabled?: boolean
  optimisticNetSum?: number
  setUtilizeOptimisticPrices?: (boolean) => void
  utilizeOptimisticPrices?: boolean
  deadProductsInCart?: boolean
  itemsDiscontinuedWithPartialStock?: ShoppingCartItem[]
  transferOciCartAndSubmit?: () => void
  transferIdsCartAndSubmit?: () => void
}

export const GenericControlStickyBox: React.FC<Props> = (props) => {
  const { desktop } = useBreakpoints()
  const { isSelectedPaymentMethodPermitted } = useContext(IsPaymentMethodPermittedContext)

  const defaultProps = {
    cartId: '',
    deliveryMode: false,
    enableEditingMode: () => {
      //
    },
    orderButtonEnabled: true,
    notifyShoppingCart: () => {
      //
    },
    submitShoppingCart: () => {
      //
    },
    userCanSubmitCart: true,
    userCanReadPrices: true,
    isDeactivated: false,
  }
  const mergedProps = {
    ...defaultProps,
    ...props,
  }

  const {
    cartId,
    context,
    enableEditingMode,
    orderButtonEnabled,
    shoppingCartPrices,
    notifyShoppingCart,
    submitShoppingCart,
    userCanSubmitCart,
    userCanReadPrices,
    isDeactivated,
    deliveryDisabled = false,
    deadProductsInCart,
    ...restProps
  } = mergedProps

  const displayThreshold = desktop ? 600 : 0
  const trigger = useScrollTrigger({
    target: typeof window !== 'undefined' ? window : undefined,
    disableHysteresis: true,
    threshold: displayThreshold,
  })

  return (
    <Fade in={!desktop || trigger}>
      <Box role="presentation" sx={{ position: 'fixed', zIndex: '1' }}>
        <ShoppingCartDetailsSelectionBarMain
          cartId={cartId}
          context={context}
          enableEditingMode={enableEditingMode}
          orderButtonEnabled={orderButtonEnabled && isSelectedPaymentMethodPermitted}
          shoppingCartPrices={shoppingCartPrices}
          notifyShoppingCart={notifyShoppingCart}
          submitShoppingCart={submitShoppingCart}
          userCanSubmitCart={userCanSubmitCart}
          userCanReadPrices={userCanReadPrices}
          isDeactivated={isDeactivated}
          deliveryDisabled={deliveryDisabled}
          deadProductsInCart={deadProductsInCart}
          {...restProps}
        />
      </Box>
    </Fade>
  )
}
