import { gql, useApolloClient } from '@apollo/client'
import { DispatchDeliverySlipPdfQuery, DispatchDeliverySlipPdfQueryVariables } from '@obeta/schema'
import { useCallback } from 'react'

import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'

export const dispatchDeliverySlipPdfQuery = gql`
  query dispatchDeliverySlipPdf($deliverySlipId: String!) {
    dispatchDeliverySlipPdf(deliverySlipId: $deliverySlipId)
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.DeliverySlipPDFPending,
    id: 'delivery-slip-pending',
    options: {
      message,
    },
  })
}

export const useDispatchDeliverySlip = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const dispatchDeliverySlipPdf = useCallback(
    async (deliverySlipId: string) => {
      showPendingMessage(t('ORDERS.NOTIFICATION.PENDING_DELIVERY_SLIP'))

      await client.query<DispatchDeliverySlipPdfQuery, DispatchDeliverySlipPdfQueryVariables>({
        query: dispatchDeliverySlipPdfQuery,
        variables: { deliverySlipId: deliverySlipId },
      })
    },
    [client, t]
  )

  return { dispatchDeliverySlipPdf }
}
