import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'

export const calculateProgress = (
  promotionItems: ShoppingCartItem[],
  criteriaType: string
): number => {
  const calculateSum = () =>
    promotionItems.reduce((acc, item) => {
      const { prices, product } = item
      const { priceDimension } = product

      return acc + ((prices?.netPrice || 0) * item.amount) / priceDimension
    }, 0)

  const calculateAmount = () => promotionItems.reduce((acc, item) => acc + item.amount, 0)
  const isSumType = criteriaType === 'sum'
  return isSumType ? calculateSum() : calculateAmount()
}
