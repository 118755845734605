import { FC } from 'react'
import styles from './ShoppingCartDetailsHeader.module.scss'
import {
  Box,
  Card,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditCartIcon } from 'assets/icon/designsystem/add_shopping_cart_edit.svg'
import { ReactComponent as EmptyCartIcon } from 'assets/icon/designsystem/empty_shopping_cart.svg'
import {
  ShoppingCartContextEnum,
  ShoppingCartForDropdown,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as CheckboxBorderIcon } from 'assets/icon/designsystem/check_box_outline_blank.svg'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { ReactComponent as CheckboxIcon } from 'assets/icon/designsystem/check_box.svg'
import clsx from 'clsx'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import {
  SecondaryLightButton,
  SecondaryIconButton,
  TertiaryButton,
  TertiaryIconButton,
} from '../custom-button/CustomButton'
import { Stack } from '@mui/system'
import { Checkbox } from '../checkbox/Checkbox'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'

// UI
import { Dropdown2 } from '../dropdown2/Dropdown2'

// Hooks
import { useSelectShoppingCart } from '@obeta/data/lib/hooks/useSelectShoppingCart'

interface Props {
  activeCartId: string
  articleCount: number
  cartData: ShoppingCartForDropdown[]
  context: ShoppingCartContextEnum
  enableEditingMode: () => void
  nonSendableArticleCount: number
  nonExpressArticleCount: number
  nonExpressFilterActive: boolean
  nonSendableFilterActive: boolean
  selectedAll: boolean
  selectedCount: number
  toggleFilteredNonSendableArticles: (checked: boolean) => void
  toggleFilteredNonExpressArticles: (checked: boolean) => void
  updateAllSelectedArticles: (checked: boolean) => void
  onEmptyCartClick: () => void
}

export const ShoppingCartDetailsHeader: FC<Props> = (props) => {
  const {
    activeCartId,
    articleCount,
    cartData,
    context,
    enableEditingMode,
    nonSendableArticleCount,
    nonExpressArticleCount,
    nonExpressFilterActive,
    nonSendableFilterActive,
    selectedAll,
    selectedCount,
    toggleFilteredNonSendableArticles,
    toggleFilteredNonExpressArticles,
    updateAllSelectedArticles,
    onEmptyCartClick,
  } = props
  const history = useHistory()

  const { t } = useTranslation()
  const theme = useTheme()
  const { mobile, desktop } = useBreakpoints()
  const { getSessionCartName, isSessionCart } = useSessionCartName()
  const onSetActiveCart = useSelectShoppingCart()

  const cartOptions: DropdownOption[] = cartData.map((cart) => {
    const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
    return {
      label: `${cartName} (${cart.articleCount})`,
      value: cart.id,
    }
  })

  const handleAll = (e) => {
    updateAllSelectedArticles(e.target.checked)
  }

  const handleNonSendableArticles = (e) => {
    toggleFilteredNonSendableArticles(e.target.checked)
  }

  const handleNonExpressArticles = (e) => {
    toggleFilteredNonExpressArticles(e.target.checked)
  }

  const activeCart = cartData.find((cart) => cart.id === activeCartId)
  if (!activeCart) {
    return null
  }

  const handleChangeClick = (cartId: string) => {
    onSetActiveCart(cartId)
    history.push(`/shopping-cart-details/${cartId}`)
  }

  const headingAndScSelect: JSX.Element = (
    <Stack alignItems="start" direction={{ xs: 'column', sm: 'row' }} gap=".5rem">
      <Typography variant={'h3'} color={theme.palette.secondary.main} sx={{ pr: 0.5 }}>
        {t('SHOPPING_CART.SHOPPING_CART')}
      </Typography>
      <Dropdown2
        options={cartOptions}
        value={activeCartId}
        onChange={(value) => handleChangeClick(value as string)}
      />
    </Stack>
  )

  if (context === ShoppingCartContextEnum.DETAILS) {
    return (
      <Box className={clsx(styles.flexRow, styles.genericHeader, styles.outterBox)}>
        {headingAndScSelect}
        <Box className={styles.buttonWrapper}>
          {!mobile ? (
            <TertiaryButton
              leftIcon={<EmptyCartIcon />}
              size={desktop ? 'small' : 'large'}
              onClick={onEmptyCartClick}
              data-id="empty-cart"
            >
              <Typography variant={'bodyBold'}>{t('SHOPPING_CART.EMPTY')}</Typography>
            </TertiaryButton>
          ) : (
            <TertiaryIconButton
              icon={<EmptyCartIcon />}
              size={'large'}
              onClick={onEmptyCartClick}
              data-id="empty-cart"
            />
          )}

          {!mobile ? (
            <SecondaryLightButton
              data-id="edit-cart"
              leftIcon={<SvgIcon component={EditCartIcon} />}
              onClick={() => enableEditingMode()}
            >
              {desktop ? t('SHOPPING_CART.EDIT_CART') : t('SHOPPING_CART.EDIT')}
            </SecondaryLightButton>
          ) : (
            <SecondaryIconButton
              data-id="edit-cart"
              icon={<EditCartIcon />}
              size={'large'}
              onClick={() => enableEditingMode()}
            />
          )}
        </Box>
      </Box>
    )
  }

  const nonSendableLabel: JSX.Element = (
    <FormControlLabel
      className={styles.formControlLabel}
      control={
        <MuiCheckbox
          checked={nonSendableFilterActive}
          onChange={handleNonSendableArticles}
          sx={{ pl: 0.5, pr: 0.25 }}
          icon={<CheckboxBorderIcon color={scssVariables.darkGray34K} className={styles.icon} />}
          checkedIcon={<CheckboxIcon color={scssVariables.red100pct} className={styles.icon} />}
          className={clsx(mobile && styles.checkboxMobile)}
        />
      }
      disabled={nonSendableArticleCount === 0}
      label={
        <Typography variant={'bodyBold'}>{`${t(
          'SHOPPING_CART.CHECKOUT.NON_SENDABLE_ITEMS.1'
        )} (${nonSendableArticleCount})`}</Typography>
      }
    />
  )

  const nonXpressLabel: JSX.Element = (
    <FormControlLabel
      className={styles.formControlLabel}
      control={
        <MuiCheckbox
          checked={nonExpressFilterActive}
          onChange={handleNonExpressArticles}
          sx={{ pl: 0.5, pr: 0.25 }}
          icon={<CheckboxBorderIcon color={scssVariables.darkGray34K} className={styles.icon} />}
          checkedIcon={<CheckboxIcon color={scssVariables.red100pct} className={styles.icon} />}
          className={clsx(mobile && styles.checkboxMobile)}
        />
      }
      disabled={nonExpressArticleCount === 0}
      label={
        <Typography variant={'bodyBold'}>{`${t(
          'SHOPPING_CART.CHECKOUT.XPRESS_PICKUP_DISABLED.1'
        )} (${nonExpressArticleCount})`}</Typography>
      }
    />
  )

  if (context === ShoppingCartContextEnum.DETAILS_EDITING) {
    return (
      <>
        {mobile && headingAndScSelect}
        <Box className={clsx(mobile ? styles.editingHeaderMobile : styles.editingHeader)}>
          <Checkbox
            checked={selectedAll}
            className={styles.allCheckbox}
            label={`alle (${articleCount})`}
            indeterminate={!selectedAll && selectedCount > 0}
            onChange={handleAll}
          />
          <>
            <Card
              elevation={nonSendableFilterActive ? 2 : 0}
              className={
                nonSendableArticleCount === 0
                  ? clsx(styles.filterCard, styles.disabled)
                  : nonSendableFilterActive
                  ? clsx(styles.filterCard, styles.red)
                  : clsx(styles.filterCard, styles.gray)
              }
            >
              {nonSendableLabel}
            </Card>
            <Card
              elevation={nonExpressFilterActive ? 2 : 0}
              className={
                nonExpressArticleCount === 0
                  ? clsx(styles.filterCard, styles.disabled)
                  : nonExpressFilterActive
                  ? clsx(styles.filterCard, styles.red)
                  : clsx(styles.filterCard, styles.gray)
              }
            >
              {nonXpressLabel}
            </Card>
          </>
        </Box>
      </>
    )
  }

  return null
}
