import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { ShopConfig, GetShopConfigQuery } from '@obeta/schema'
import { handleError } from '@obeta/utils/lib/datadog.errors'

const loadShopConfigDataQuery = gql`
  query getShopConfig {
    getShopConfig {
      valueAddedTax
      shippingCostDefaultParcelTotal
      shippingCostDefaultParcelFreeDeliveryThreshold
      shippingCostFastParcelTotal
      shippingCostFastParcelFreeDeliveryThreshold
    }
  }
`

type CachedConfigData = {
  data: ShopConfig
  expiresAt: number
}

const TTLCACHEVALUE = 7200000 //2 hours
export const useLoadShopConfig = () => {
  const [shopConfigData, setShopConfigData] = useState<ShopConfig>({
    shippingCostDefaultParcelFreeDeliveryThreshold: 0,
    shippingCostDefaultParcelTotal: 0,
    shippingCostFastParcelFreeDeliveryThreshold: 0,
    shippingCostFastParcelTotal: 0,
    valueAddedTax: 0,
  })
  const apolloClient = useApolloClient()
  const loadConfigData = async () => {
    try {
      const cachedConfigData =
        localStorage.getItem('shopConfigData') &&
        (JSON.parse(localStorage.getItem('shopConfigData') as string) as CachedConfigData)
      const now = new Date()
      if (cachedConfigData && cachedConfigData.expiresAt > now.getTime()) {
        setShopConfigData(cachedConfigData.data)
      } else {
        const response = await apolloClient.query<GetShopConfigQuery>({
          query: loadShopConfigDataQuery,
        })
        if (response.data?.getShopConfig) {
          const now = new Date()
          localStorage.setItem(
            'shopConfigData',
            JSON.stringify({
              data: response.data.getShopConfig,
              expiresAt: now.getTime() + TTLCACHEVALUE,
            })
          )
          setShopConfigData(response.data.getShopConfig)
        }
      }
    } catch (error) {
      error.message = 'Apollo client throws error in loadShopConfigDataQuery ' + error.message
      handleError(error)
    }
  }

  useEffect(() => {
    loadConfigData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient])

  return shopConfigData
}
