import { EtimData } from '@obeta/models/lib/models/Article/Shop/Product'

const oldFacetTypeNames = {
  alphanumerisch: 'string',
  numerisch: 'number',
  Range: 'range',
  logisch: 'bool',
}

const replaceCommasWithDots = (value: string) => value.replace(',', '.')

const getValueWithoutUnit = (entry: EtimData) => {
  const { unit, value1 } = entry
  return unit ? value1.split(' ')[0] : value1
}

const parseValueBasedOnType = (entry: EtimData) => {
  const valueWithoutUnit = getValueWithoutUnit(entry)
  if (entry.type === 'numerical') {
    return replaceCommasWithDots(valueWithoutUnit)
  }

  return valueWithoutUnit
}

export const mapEtim = (entry: EtimData) => {
  const isRange = Boolean(entry.value1 && entry.value2)
  if (isRange) {
    return [
      {
        value: getValueWithoutUnit(entry),
        facet: {
          name: `zEtimData.features.${entry.classId}.${entry.featureId}_MIN`,
          meta: {
            name: entry.featureText,
            type: 'range',
          },
          values: [],
        },
      },
      {
        value: entry.value2,
        facet: {
          name: `zEtimData.features.${entry.classId}.${entry.featureId}_MAX`,
          meta: {
            name: entry.featureText,
            type: 'range',
          },
          values: [],
        },
      },
    ]
  }

  return {
    value: parseValueBasedOnType(entry),
    facet: {
      name: `zEtimData.features.${entry.classId}.${entry.featureId}`,
      meta: {
        name: entry.featureText,
        type: entry.type !== undefined ? oldFacetTypeNames[entry.type] : null,
      },
      values: [],
    },
  }
}
