import React, { memo, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './MyAccountingSelectionBar.module.scss'
import { useOpenPositionsFilters } from '@obeta/data/lib/hooks/my-accounting/useOpenPositionsFilters'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SecondaryButton } from '../custom-button/CustomButton'
import { SelectionBar } from '../selection-bar/SelectionBar'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ReactComponent as PrintIcon } from 'assets/icon/designsystem/print.svg'
import { ReactComponent as ExportIcon } from 'assets/icon/designsystem/export.svg'
import { ReactComponent as PdfFileFormatSymbolIcon } from 'assets/icon/pdf-file-format-symbol.svg'
import {
  OpenPositionFilter,
  useMyAccountingContext,
} from '@obeta/data/lib/stores/useMyAccountingContext'
import { OpenPosition } from '@obeta/schema'
import { useDispatchInvoices } from '@obeta/data/lib/hooks/useDispatchInvoice'
import { useAppDomain } from '@obeta/data/lib/hooks/useAppDomain'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { printOpenPositionList } from '@obeta/utils/lib/printPdf'
import { createOpenPositionCsv } from '@obeta/utils/lib/my-accounting/createOpenPositionCsv'
import { useOpenPositionsPageData } from '@obeta/data/lib/hooks/my-accounting/useOpenPositionsPageData'

interface MyAccountingSelectionBarProps {
  openPositions: number
  items: OpenPosition[]
}

export const MyAccountingSelectionBar: React.FC<MyAccountingSelectionBarProps> = memo((props) => {
  const { mobile, tablet } = useBreakpoints()
  const { t } = useTranslation()
  const { sortedAndFilteredOpenPositions } = useOpenPositionsPageData()
  const { filter, selectedItems, sorting, searchAttribute, searchOperator, searchTerm } =
    useMyAccountingContext()
  const { dispatchInvoiceZip, dispatchInvoicePdf } = useDispatchInvoices()
  const { openPositions, items } = props
  const { filterOverdueItems, filterCreditItems, filterDiscountableItems } =
    useOpenPositionsFilters()
  const { tokens } = useUserDataV2()
  const domain = useAppDomain()
  const print = (filter: OpenPositionFilter) =>
    printOpenPositionList({
      filter,
      sorting,
      search: {
        searchAttributeId: searchAttribute.id,
        searchOperatorId: searchOperator.id,
        searchTerm,
      },
      accessToken: tokens?.accessToken,
      domain,
    })

  const calculateTotalOpenAmount = () => {
    if (selectedItems.length === 0) return openPositions

    let totalOpenAmount = 0
    selectedItems.forEach((item) => {
      totalOpenAmount += item.openAmount
    })

    return totalOpenAmount
  }

  const currentViewElementsToPrint = useMemo(() => {
    switch (filter) {
      case 'overdue':
        return filterOverdueItems(items).length
      case 'discountable':
        return filterDiscountableItems(items).length
      case 'credits':
        return filterCreditItems(items).length
      default:
        return items.length
    }
  }, [filter, filterOverdueItems, items, filterDiscountableItems, filterCreditItems])

  const onPrint = async () => {
    try {
      await print(filter)
    } catch (error) {
      if (error instanceof Error) {
        handleError(error)
        throw new Error('Error on printing open position list')
      }
    }
  }

  return (
    <SelectionBar portalKey="my-account-selection-bar">
      <div className={styles.mainDiv}>
        <Box className={styles.selectionBarButtonsGroup}>
          {!mobile && (
            <SecondaryButton
              disabled={selectedItems.length === 0}
              leftIcon={<PrintIcon />}
              onClick={async () => {
                if (selectedItems.length === 1) {
                  const [selectedItem] = selectedItems
                  await dispatchInvoicePdf(selectedItem.receipt)
                } else {
                  await dispatchInvoiceZip(selectedItems.map(({ receipt }) => receipt))
                }
              }}
            >
              {`${t('MY_ACCOUNTING.STICKY_BAR_OPTIONS.PRINT_SELECTED')} (${selectedItems.length})`}
            </SecondaryButton>
          )}

          {!mobile && (
            <SecondaryButton
              disabled={selectedItems.length === 0}
              leftIcon={<ExportIcon />}
              onClick={() => {
                const sortedItems = sortedAndFilteredOpenPositions.filter((item) =>
                  Boolean(selectedItems.find((selectedItem) => selectedItem.id === item.id))
                )
                const file = createOpenPositionCsv(sortedItems, t)
                const element = document.createElement('a')
                element.href = URL.createObjectURL(file)
                element.download = 'OffenePosten-Faelligkeit.csv'
                document.body.appendChild(element)
                element.click()
              }}
            >
              {`${t('MY_ACCOUNTING.STICKY_BAR_OPTIONS.CSV_EXPORT')} (${selectedItems.length})`}
            </SecondaryButton>
          )}

          {!mobile && !tablet && (
            <SecondaryButton leftIcon={<PdfFileFormatSymbolIcon />} onClick={onPrint}>
              {`${t(
                'MY_ACCOUNTING.STICKY_BAR_OPTIONS.PDF_OF_CURRENT_VIEW'
              )} (${currentViewElementsToPrint})`}
            </SecondaryButton>
          )}
        </Box>

        <div>
          <Box className={styles.totalSumSection}>
            <Typography variant={'body'}>
              {selectedItems.length === 0
                ? t('MY_ACCOUNTING.STICKY_BAR_OPTIONS.OPEN_POSITIONS')
                : t('MY_ACCOUNTING.STICKY_BAR_OPTIONS.SELECTED_POSITIONS')}
            </Typography>
            <Typography variant="bodyBold">
              {normalizePrice(calculateTotalOpenAmount(), '€')}
            </Typography>
          </Box>
        </div>
      </div>
    </SelectionBar>
  )
})
