import React from 'react'
import { Typography, Box, Link } from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import styles from './AccountInfo.module.scss'
import stylesCheckbox from '../checkbox/Checkbox.module.scss'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '../tooltip/Tooltip'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { CreditInfo } from '@obeta/models/lib/schema-models/userV2-credit-info'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { AccountInfoMobile } from './AccountInfoMobile'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'

export interface CreditInfoProps {
  creditInfo: Exclude<CreditInfo, null | undefined>
}

export const UpperLineForTablet: React.FC<CreditInfoProps> = ({ creditInfo }) => {
  const { t } = useTranslation()
  const { desktop, tabletAll } = useBreakpoints()

  const { accountingPhoneNumber, accountingEmailAddress } = useCompanyContactDetailsContext()

  const toolTipTextToDisplay: JSX.Element = (
    <>
      <Typography variant={'smallText'}>
        {t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.0')}
      </Typography>
      <Typography variant={'smallTextBold'}>{`T: ${accountingPhoneNumber}`}</Typography>
      <Typography variant={'smallText'}>
        {t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.1')}
      </Typography>
      <Typography variant={'smallTextBold'} color={'secondary'}>
        M:&nbsp;
        <Link
          href={`mailto:${accountingEmailAddress}`}
          underline="none"
          color="secondary"
          target="_top"
        >
          {accountingEmailAddress}
        </Link>
      </Typography>
      <Typography variant={'smallText'}>
        {t('CUSTOMER_PROFILE.CREDIT_LIMIT_CHANGE_REQUEST.2')}
      </Typography>
    </>
  )

  return (
    <>
      <Box className={styles.infoUnit}>
        {desktop && (
          <Typography
            variant="body"
            className={tabletAll ? styles.underlineText : ''}
            sx={{
              ...(tabletAll
                ? {
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }
                : {}),
            }}
          >
            {t('CUSTOMER_PROFILE.CREDIT_LIMIT')} &nbsp;
          </Typography>
        )}

        {tabletAll && (
          <Tooltip activateOnClick={true} title={toolTipTextToDisplay}>
            <>
              <Typography
                variant="body"
                className={tabletAll ? styles.underlineText : ''}
                sx={{
                  ...(tabletAll
                    ? {
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }
                    : {}),
                }}
              >
                {t('CUSTOMER_PROFILE.CREDIT_LIMIT')}
              </Typography>
              &nbsp;
            </>
          </Tooltip>
        )}

        <Typography variant="bodyBold">{normalizePrice(creditInfo.creditLimit, '€')}</Typography>
        {desktop && (
          <Tooltip title={toolTipTextToDisplay}>
            <InfoIcon className={clsx(stylesCheckbox.tooltipIcon, styles.marginInfoIcon)} />
          </Tooltip>
        )}
      </Box>
      <div className={styles.rectangle} />
      <Box className={styles.infoUnit}>
        <Typography variant="body">{t('CUSTOMER_PROFILE.OPEN_ORDERS')} &nbsp;</Typography>
        <Typography variant="bodyBold">{normalizePrice(creditInfo.openOrders, '€')}</Typography>
      </Box>
      <div className={styles.rectangle} />
      <Box className={styles.infoUnit}>
        <Typography variant="body">{t('CUSTOMER_PROFILE.AVAILABLE_SUM')} &nbsp;</Typography>
        <Typography variant="bodyBold">
          {normalizePrice(creditInfo.creditAvailable, '€')}
        </Typography>
      </Box>
    </>
  )
}

export const AccountInfoDesktopTabletWide: React.FC<CreditInfoProps> = ({ creditInfo }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.mainDiv}>
      <UpperLineForTablet creditInfo={creditInfo} />
      <div className={styles.rectangle} />
      <Box className={styles.infoUnit}>
        <Typography variant="body">{t('CUSTOMER_PROFILE.OPEN_POSTS')} &nbsp;</Typography>
        <Typography variant="bodyBold">{normalizePrice(creditInfo.openPositions, '€')}</Typography>
      </Box>
      <div className={styles.rectangle} />
      <Box className={styles.infoUnit}>
        <Typography variant="body">{t('CUSTOMER_PROFILE.NET_TOTAL')} &nbsp;</Typography>
        <Typography variant="bodyBold">{normalizePrice(creditInfo.creditUsed, '€')}</Typography>
      </Box>
    </div>
  )
}

export const AccountInfoTablet: React.FC<CreditInfoProps> = ({ creditInfo }) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.mainDiv, styles.columnDirection)}>
      <div className={styles.wrapperTabletFirstLine}>
        <UpperLineForTablet creditInfo={creditInfo} />
      </div>
      <div className={clsx(styles.wrapperTabletSecondLine)}>
        <Box className={styles.infoUnit}>
          <Typography variant="body">{t('CUSTOMER_PROFILE.OPEN_POSTS')} &nbsp;</Typography>
          <Typography variant="bodyBold">
            {normalizePrice(creditInfo.openPositions, '€')}
          </Typography>
        </Box>
        <div className={clsx(styles.rectangle, styles.MarginLeftRightOneAndHalfRem)} />
        <Box className={styles.infoUnit}>
          <Typography variant="body">{t('CUSTOMER_PROFILE.NET_TOTAL')} &nbsp;</Typography>
          <Typography variant="bodyBold">{normalizePrice(creditInfo.creditUsed, '€')}</Typography>
        </Box>
      </div>
    </div>
  )
}

export const AccountInfo: React.FC<CreditInfoProps> = ({ creditInfo }) => {
  const { desktop, tabletWide, mobile, tablet } = useBreakpoints()
  return (
    <>
      {(desktop || tabletWide) && creditInfo && (
        <div>
          <AccountInfoDesktopTabletWide creditInfo={creditInfo} />
        </div>
      )}
      {tablet && creditInfo && (
        <div>
          <AccountInfoTablet creditInfo={creditInfo} />
        </div>
      )}
      {mobile && creditInfo && (
        <div className={styles.noPaddingTopAndBottomForNav}>
          <AccountInfoMobile creditInfo={creditInfo} />
        </div>
      )}
    </>
  )
}
