import { useEffect, useRef, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'

const termsQuery = gql`
  query promotionTerms {
    promotionTerms(publicationState: LIVE) {
      data {
        attributes {
          terms
        }
      }
    }
  }
`
export const useGlobalPromotionTerms = (): string => {
  const [terms, setTerms] = useState<string>('')

  const client = useApolloClient()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getTerms = async () => {
      if (!isMounted.current) return

      const response = await client.query({
        query: termsQuery,
        variables: {},
      })

      const result = response.data.promotionTerms
      setTerms(result.data?.attributes.terms)
    }

    getTerms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return terms
}
