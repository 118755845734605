import React, { useEffect, useState } from 'react'
import { Filters } from '../searchPage/Filters'
import { CategoryHeader } from '../searchPage/CategoryHeader'
import { SearchList } from '../searchPage/SearchList'
import { FiltersStateProvider } from '../searchPage/filters/hooks/useFiltersReducer'
import { Sidebar } from '../searchPage/filters/facets/Sidebar'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useSearchPageMeta } from '@obeta/data/lib/hooks/useSearchPageMeta'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import styles from './promotionContentLongList.module.scss'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SearchIcon } from 'assets/icon/designsystem/search.svg'

interface ILongListProps {
  searchString?: string
  onAddToCart: (cart: ShoppingCartV2) => void
}

export const PromotionContentLongList: React.FC<ILongListProps> = (props) => {
  const { searchString, onAddToCart } = props
  const { t } = useTranslation()
  const { mobile, tabletAll, desktop, tablet } = useBreakpoints()
  const { title, hitCount, rootId } = useSearchPageMeta()
  const changeSearchParams = useChangeSearchParams()

  const [inputValue, setInputValue] = useState('')
  const [currentSearchString, setCurrentSearchString] = useState(searchString)

  useEffect(() => {
    setCurrentSearchString(searchString)
  }, [searchString])

  useEffect(() => {
    if (!currentSearchString) {
      return
    }
    changeSearchParams({
      searchParams: { searchString: currentSearchString },
      route: 'none',
    })
  }, [currentSearchString, changeSearchParams])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value)
  const handleSearch = () => {
    let searchTerm = searchString
    if (inputValue) searchTerm += ' ' + inputValue

    setCurrentSearchString(searchTerm)
  }

  const startAdornment = (
    <SearchIcon
      className={styles['search-icon']}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      color="darkGray"
    />
  )
  const searchListHeader = (
    <div className={styles['input-wrapper']}>
      <TextField
        classes={{
          root: styles['input-root'],
        }}
        placeholder={t('SEARCH.SEARCH_PLACEHOLDER')}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment,
          onKeyPress: (e) => {
            if (e.key === 'Enter') {
              handleSearch()
            }
          },
        }}
      />
    </div>
  )

  return (
    <div className={styles['long-list-wrapper']}>
      <FiltersStateProvider withoutRedirect>
        {!(mobile || tablet) && <Sidebar />}
        <div className={styles['long-list-items']}>
          <CategoryHeader
            mobile={mobile}
            catId={rootId}
            title={title}
            size={hitCount}
            rightAdornment={null}
            bottomAdornment={<Filters mobile={mobile} tablet={tablet} />}
            header={searchListHeader}
          />
          <SearchList
            focusFirstSearchResult={false} //disable this for promo pages as is scrolls the promo content of screen
            mobile={mobile}
            tabletAll={tabletAll}
            desktop={desktop}
            onAddToCart={onAddToCart}
          />
        </div>
      </FiltersStateProvider>
    </div>
  )
}
