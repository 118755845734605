import React, { useMemo } from 'react'
import { ActionButton } from '@obeta/components/lib/message-center-action-button/ActionButton'
import { PopoverDropdownMenu } from '@obeta/components/lib/dropdown-button'
import { Typography } from '@mui/material'
import { ReactComponent as More } from 'assets/icon/more_vertical.svg'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { useDispatch } from 'react-redux'
import { updateCartGraphQL } from '@obeta/data/lib/actions/cart-actions'
import { useShoppingCartById } from '@obeta/data/lib/hooks/useShoppingCartById'

interface IMoreMenuProps {
  promotionId?: string
}

const OPEN_PROMOTION_PAGE_ID = 1
const CANCEL_BTN_ID = 2

export const PromotionMoreMenu: React.FC<IMoreMenuProps> = (props) => {
  const { promotionId } = props
  const { handleClick, ...dropdown } = usePopoverState()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { cartId } = useParams<{ cartId: string }>()
  const cartById = useShoppingCartById(cartId)

  const actionOptions = useMemo(
    () => [
      { id: OPEN_PROMOTION_PAGE_ID, label: t('PROMOTIONS.CONDITIONS') },
      { id: CANCEL_BTN_ID, label: t('PROMOTIONS.REMOVE_ACTION') },
    ],
    [t]
  )

  const handleRedirectToPromotion = () => {
    if (!promotionId) return

    history.push(`/promotion/${promotionId}`)
  }

  const handleCancelPromotion = () => {
    if (!cartById) return

    dispatch(updateCartGraphQL({ ...cartById, promotionId: '' }))
  }

  const handleItemClick = (id: number) => () => {
    if (id === OPEN_PROMOTION_PAGE_ID) handleRedirectToPromotion()
    if (id === CANCEL_BTN_ID) handleCancelPromotion()
  }

  return (
    <>
      <div onClick={handleClick}>
        <More />
      </div>
      <PopoverDropdownMenu
        formatOption={(opt) => (
          <ActionButton key={opt.id} onClick={handleItemClick(opt.id)} noPaddingLeft noPaddingTop>
            <Typography variant="body">{opt.label}</Typography>
          </ActionButton>
        )}
        options={actionOptions}
        onOptionSelected={() => {
          dropdown.onClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...dropdown}
      />
    </>
  )
}
