import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useBarcodeScanner } from '@obeta/data/lib/hooks/useBarcodeScanner'
import CloseIcon from '@mui/icons-material/Close'
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn'
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { ScreenOrientationResult } from '@capacitor/screen-orientation/dist/esm/definitions'
import styles from './Scannerbutton.module.scss'

export const Scannerbutton = () => {
  const { stopScan, toggleTorch } = useBarcodeScanner()
  const [flash, setFlash] = useState(false)
  const [orientation, setOrientation] =
    useState<ScreenOrientationResult['type']>('portrait-primary')

  const flashLightHandler = () => {
    toggleTorch()
    setFlash(!flash)
  }

  useEffect(() => {
    const updateScreenOrientation = async () => {
      const orientationType = await ScreenOrientation.orientation()
      setOrientation(orientationType.type)
    }

    let listener
    const registerListener = async () => {
      listener = await ScreenOrientation.addListener(
        'screenOrientationChange',
        updateScreenOrientation
      )
    }
    registerListener()
    updateScreenOrientation()

    return () => {
      listener?.remove()
    }
  }, [])

  return (
    <>
      <div className={styles.scanToolbarWrapper}>
        <div
          className={clsx(styles.closeicon, {
            [styles.closeiconLandscape]: orientation.includes('landscape'),
          })}
          onClick={stopScan}
        >
          <CloseIcon fontSize="large" />
        </div>
        <div
          className={clsx(styles.flashicon, {
            [styles.flashiconLandscape]: orientation.includes('landscape'),
          })}
          onClick={flashLightHandler}
        >
          {flash ? (
            <FlashlightOnIcon fontSize="large" color="primary" />
          ) : (
            <FlashlightOffIcon fontSize="large" />
          )}
        </div>
      </div>
      <div className={styles.scanbox}></div>
    </>
  )
}
