import { useTranslation } from 'react-i18next'

// Models
import { DropdownOption } from '@obeta/models/lib/models/UI'

export const useOffers = () => {
  const { t } = useTranslation()

  const flagsOptions: DropdownOption[] = [
    { value: 'all', label: t('OFFERS.FLAGS.ALL') },
    { value: 'notExpired', label: t('OFFERS.FLAGS.ACTIVE') },
    { value: 'expiresSoon', label: t('OFFERS.FLAGS.EXPIRES_SOON') },
    { value: 'new', label: t('OFFERS.FLAGS.NEW') },
    { value: 'expired', label: t('OFFERS.FLAGS.EXPIRED') },
  ]

  const sortByOptions: DropdownOption[] = [
    { value: 'startDate', label: t('OFFERS.ORDER_BY.START_DATE') },
    { value: 'offerName', label: t('OFFERS.ORDER_BY.NAME') },
    { value: 'offerId', label: t('OFFERS.ORDER_BY.ID') },
  ]

  return {
    flagsOptions,
    sortByOptions,
  }
}
