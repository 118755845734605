import { PopoverProps as MuiPopoverProps, Popover as MuiPopover } from '@mui/material'
import clsx from 'clsx'
import React, { useLayoutEffect, useState } from 'react'
import styles from './Popover.module.scss'

/**
 * @param {string | Element | undefined} anchorEl if string: id of element. only needed to support IonTabBar component
 *
 */
export interface IPopoverProps extends Omit<MuiPopoverProps, 'anchorEl' | 'onClose'> {
  anchorEl?: MuiPopoverProps['anchorEl'] | string
  withBackdrop?: boolean
}

export const Popover: React.FC<IPopoverProps> = (props) => {
  const { anchorEl: _anchorEl, withBackdrop = false, classes, ...popoverProps } = props

  const [anchorEl, setAnchorEl] = useState<MuiPopoverProps['anchorEl'] | undefined>()

  useLayoutEffect(() => {
    if (typeof _anchorEl === 'string') {
      const el = document.getElementById(_anchorEl)
      setAnchorEl(el)
      return
    }

    setAnchorEl(_anchorEl)
  }, [_anchorEl])

  return (
    <MuiPopover
      classes={{
        ...classes,
        root: clsx({ [styles.root]: !withBackdrop }, classes?.root),
        paper: clsx(styles.paper, classes?.paper),
      }}
      disableEnforceFocus={true}
      anchorEl={anchorEl}
      marginThreshold={0}
      keepMounted={false}
      hideBackdrop={true}
      {...popoverProps}
    />
  )
}
