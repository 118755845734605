import styles from './SlideWrap.module.scss'

/**
 *
 * flex-column + 1rem gap
 * @param props
 * @returns
 */
export const SlideWrap: React.FC = (props) => {
  return <div className={styles.root}>{props.children}</div>
}
