import { ApolloProvider } from '@apollo/client'
import { initApollo } from '@obeta/app-bootstrap/lib/apolloClient'
import { useAppActions } from '@obeta/data/lib/hooks/useAppActions'
import { Section } from '../../startPage/Section'
import { CustomSearchLayoutView } from './customSearchLayoutView'
import { ReactComponent as MenuBurger } from 'assets/icon/menu-burger.svg'
import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import styles from './CustomSearchLayoutView.module.scss'
import { useRxDB } from 'rxdb-hooks'

const graphqlUrlShop = process.env.NEXT_PUBLIC_GRAPHQL_HOST as string
const graphqlUrlApp = process.env.REACT_APP_NEXT_PUBLIC_GRAPHQL_HOST as string

const CustomSearchLayoutPlugin = (props) => {
  const { data } = props
  const appActions = useAppActions()
  const changeSearchParams = useChangeSearchParams()
  const db = useRxDB()
  const client = initApollo(graphqlUrlApp || graphqlUrlShop, appActions, false, db)

  const searchParams = useMemo(() => {
    const params = {
      id: 'custom-search',
      suppliers: {},
      productIds: [],
      productDehaIds: [],
      searchString: '',
    }

    if (data.algoliaQuery) {
      params.searchString = data.algoliaQuery
    }

    if (data.supplierFilterName) {
      params.suppliers[data.supplierFilterName] = {
        id: data.supplierFilterName,
        name: data.supplierFilterName,
      }
    }
    if (data.sapIds) {
      params.productIds = data.sapIds
        .split(',')
        .filter(Boolean)
        .map((id) => id.trim())
    }

    if (data.dehaIds) {
      params.productDehaIds = data.dehaIds
        .split(',')
        .filter(Boolean)
        .map((id) => id.trim())
    }

    return params
  }, [data])

  const handleOpenFullSearch = () => {
    changeSearchParams({
      searchParams,
      route: 'push',
    })
  }

  return (
    <ApolloProvider client={client}>
      <div className={styles['top-section-background']} />
      <div className={styles['top-section-wrapper']}>
        <Section
          className={styles['section-wrapper']}
          title="Zugehörige Artikel"
          rightAdornment={
            <div className={styles['right-adornment-wrapper']} onClick={handleOpenFullSearch}>
              <MenuBurger />
              <Typography variant="bodyBold" className={styles['right-adornment-text']}>
                Artikel anzeigen
              </Typography>
            </div>
          }
          content={<CustomSearchLayoutView {...props} searchParams={searchParams} />}
        />
      </div>
    </ApolloProvider>
  )
}

export const ProductCardLayoutPlugin = {
  id: 'de/obeta/plugins/custom-searches-layout-plugin',
  title: 'Custom Search Layout',
  description: 'Layout for products search',
  Renderer: CustomSearchLayoutPlugin,
  version: 1,
}
