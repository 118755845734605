import React, { FunctionComponent } from 'react'
import { Box, SvgIcon, Typography } from '@mui/material'
import { ReactComponent as TecselectIcon } from 'assets/icon/designsystem/Tecselect_grau1.svg'
import { normalizeNumber } from '@obeta/utils/lib/data-formatter/normalizeNumber'
import styles from './Notification.module.scss'
import clsx from 'clsx'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { Trans } from 'react-i18next'

interface Props {
  variant: 'veryLight' | 'light' | 'medium' | 'dark'
  Icon: FunctionComponent
  body?: string | React.ReactNode
  bodyTranslationPath?: string
  iconFontSize?: 'small' | 'inherit' | 'large' | 'medium'
  tecselectPoints?: number | null
  maximumWidth?: string
  className?: string
  containerClassname?: string
  iconClassName?: string
  iconVariant?: 'info' | 'warning'
  textVariant?: 'smallText' | 'body'
}

export const SimpleInfoBlock: React.FC<Props> = (props) => {
  const defaultProps: {
    tecselectPoints: number | null
    maximumWidth: string
    iconVariant: 'info' | 'warning' | undefined
  } = {
    iconVariant: 'info',
    tecselectPoints: null,
    maximumWidth: '1250px',
  }

  props = {
    ...defaultProps,
    ...props,
  }

  const {
    body,
    bodyTranslationPath,
    iconFontSize = undefined,
    Icon,
    iconClassName,
    iconVariant,
    tecselectPoints,
    variant,
    maximumWidth,
    className,
    containerClassname,
    textVariant = 'body',
  } = props

  let background
  let color
  let iconColor
  switch (variant) {
    case 'veryLight':
      background = styles.whiteInfoBackground
      color = iconColor = scssVariables.darkGray55K
      break
    case 'light':
      background = styles.lightInfoBackground
      color = iconColor = scssVariables.darkGray
      break
    case 'medium':
      background = styles.mediumInfoBackground
      color = scssVariables.darkGray
      break
    case 'dark':
      background = styles.darkInfoBackground
      color = iconColor = scssVariables.white
  }

  if (iconVariant === 'warning') {
    iconColor = scssVariables.red100pct
  }

  return (
    <Box
      maxWidth={maximumWidth}
      className={clsx(
        styles.simpleNotification,
        styles.flexCenter,
        styles.halfPadding,
        background,
        containerClassname
      )}
    >
      <Box
        className={clsx(
          styles.flexRow,
          styles.halfMarginRight,
          styles.alignSelfStart,
          iconClassName && iconClassName
        )}
      >
        <SvgIcon htmlColor={iconColor} component={Icon} fontSize={iconFontSize} />
      </Box>
      {tecselectPoints !== null ? (
        <Typography variant={textVariant} color={color} className={className || ''}>
          Sie erhalten {normalizeNumber(tecselectPoints, 0)}
          <TecselectIcon
            style={{
              height: '0.88rem',
              marginLeft: '0.125rem',
              marginRight: '0.125rem',
              marginBottom: '-0.188rem',
            }}
          />
          Punkte
        </Typography>
      ) : (
        <Typography variant={textVariant} color={color} className={className || ''}>
          {bodyTranslationPath && (
            <Trans i18nKey={bodyTranslationPath} components={{ strong: <strong /> }} />
          )}
          {body && body}
        </Typography>
      )}
    </Box>
  )
}
