import { FC } from 'react'
import { Circle, OverlayView } from '@react-google-maps/api'
import styles from './CurrentLocationMarker.module.scss'

const circleOptions = {
  fillColor: '#d8d8d8',
  fillOpacity: 0.4,
  strokeWeight: 1,
  strokeColor: '#e2061b',
  clickable: false,
  editable: false,
  zIndex: 1,
}

export interface Location {
  lat: number
  lng: number
}

export interface ICurrentLocationOptions {
  circleRadius?: number
}

interface ICurrentLocationMarkerProps {
  currentLocation: Location | null
  currentLocationOptions?: ICurrentLocationOptions
}

export const CurrentLocationMarker: FC<ICurrentLocationMarkerProps> = ({
  currentLocation,
  currentLocationOptions,
}) => {
  if (!currentLocation) {
    return null
  }

  return (
    <OverlayView position={currentLocation} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <>
        <Circle
          center={currentLocation}
          radius={currentLocationOptions?.circleRadius || 0}
          options={circleOptions}
        />
        <div className={styles.currentLocationMarker} />
      </>
    </OverlayView>
  )
}
