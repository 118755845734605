import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Styles
import styles from './OfferDetailHeaderDatesContent.module.scss'

// Utils
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import dayjs from 'dayjs'
import { Clipboard } from '@capacitor/clipboard'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'

type Props = {
  id: string
  endDate: string
  lastUpdated: string
  startDate: string
}

export const OfferDetailHeaderDatesContent: FC<Props> = (props) => {
  const { id, endDate, lastUpdated, startDate } = props

  const { mobile } = useBreakpoints()
  const { t } = useTranslation()

  // Get difference between enddate and today, then add 1(!) to be able to display the duration the offer is still valid for
  const durationOfferValidToInDays = dayjs(endDate).diff(dayjs(), 'day') + 1

  return (
    <div className={styles.datesContent}>
      <div className={styles.datesContentSection}>
        {mobile && <Typography variant="body">{t('OFFERS.META_DATA.ID')}</Typography>}
        <Typography variant="body">{t('OFFERS.META_DATA.START_DATE')}</Typography>
        <Typography variant="body">{t('OFFERS.META_DATA.LAST_UPDATED')}</Typography>
        <Typography variant="body">
          {durationOfferValidToInDays > 1
            ? t('OFFERS.META_DATA.END_DATE_LONG_MULTIPLE', { days: durationOfferValidToInDays })
            : t('OFFERS.META_DATA.END_DATE_LONG_SINGLE', { days: durationOfferValidToInDays })}
        </Typography>
      </div>
      <div className={styles.datesContentSection}>
        {mobile && (
          <Typography
            className={styles.datesContentItemId}
            variant="bodyBold"
            onClick={async () => {
              await Clipboard.write({ string: id })

              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.Success,
                id: 'copy-property',
                options: {
                  heading: id,
                  body: t<string>('MAIN.WAS_COPIED'),
                },
              })
            }}
          >
            {id}
          </Typography>
        )}
        <Typography variant="bodyBold">{dateFormatter(startDate)}</Typography>
        <Typography variant="bodyBold">{dateFormatter(lastUpdated)}</Typography>
        <Typography variant="bodyBold">{dateFormatter(endDate)}</Typography>
      </div>
    </div>
  )
}
