import React from 'react'
import { PopoverOrigin, Popover, Paper } from '@mui/material'
import styles from './PopoverFromBottom.module.scss'
import clsx from 'clsx'

interface Props {
  isOpen: boolean
  onClose: () => void
  paperStyles?: string
  rootStyles?: string
  backdropStyles?: string
}

const popoverOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const PopoverFromBottom: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  paperStyles,
  backdropStyles,
  rootStyles,
}) => {
  return (
    <Popover
      transitionDuration={0}
      transformOrigin={popoverOrigin}
      anchorOrigin={popoverOrigin}
      open={isOpen}
      classes={{ paper: styles.popover, root: rootStyles || '' }}
      keepMounted={false}
      slotProps={{
        backdrop: {
          invisible: false,
          onClick: onClose,
          className: backdropStyles || '',
        },
      }}
    >
      <Paper className={clsx(styles.popoverPaper, paperStyles)}>{children}</Paper>
    </Popover>
  )
}
