import {
  EventType,
  getEventSubscription,
  NotificationEvent,
  NotificationType,
} from '@obeta/utils/lib/pubSub'
import { ShoppingCartSubmitCartRequestTrackingData } from '@obeta/models/lib/models/ShoppingCart/ShoppingCartTrackingData'
import {
  OrderStateAfterSubmit,
  OrderSuccessResponsePayload,
  ShoppingCartV2,
  StoreV2,
} from '@obeta/models/lib/models'
import { buildOrderState } from './create-order-utils'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useEffect, useRef, useState } from 'react'
import { clearOrderProcessInformation } from '@obeta/utils/lib/order-process-information'
import { useEntities } from '../useEntities'
import { useCreditLimitPreAlertContext } from '../../stores/useCreditLimitPreAlertContext'

type OrderResponse =
  | { type: 'success'; value: OrderSuccessResponsePayload }
  | { type: 'id-missing-in-response'; value: string }

export const useOrderResponseHandling = ({
  cart,
  cartToTrack,
}: {
  cart?: ShoppingCartV2
  cartToTrack: ShoppingCartSubmitCartRequestTrackingData | null
}) => {
  const didTrackCart = useRef(false)
  const [order, setOrder] = useState<{
    status: OrderStateAfterSubmit
    response: OrderResponse | null
  }>({
    status: OrderStateAfterSubmit.Unset,
    response: null,
  })
  const stores = useEntities<StoreV2>('storesv2')
  const { showCreditLimitPreAlert } = useCreditLimitPreAlertContext()

  function handleSuccessfulResponse(
    event: NotificationEvent,
    cartToTrack: ShoppingCartSubmitCartRequestTrackingData | null
  ) {
    setOrder({
      status: OrderStateAfterSubmit.Success,
      response: {
        type: 'success',
        value: buildOrderState(event, stores),
      },
    })
    showCreditLimitPreAlert()
    if (cartToTrack) {
      const { startTime, ...cartToTrackWithoutStartTime } = { ...cartToTrack }
      trackCustom('submit-cart-response', {
        ...cartToTrackWithoutStartTime,
        success: true,
        orderId: 'orderId' in event.options ? event.options.orderId : '',
        time: Date.now() - cartToTrack.startTime,
      })
    }
  }

  function handleErrorResponse(
    event: NotificationEvent,
    cartToTrack: ShoppingCartSubmitCartRequestTrackingData | null
  ) {
    setOrder({
      status: OrderStateAfterSubmit.Error,
      response: {
        type: 'id-missing-in-response',
        value: 'reason' in event.options ? event.options.reason : '',
      },
    })

    if (cartToTrack) {
      const { startTime, ...cartToTrackWithoutStartTime } = { ...cartToTrack }
      trackCustom('submit-cart-response', {
        ...cartToTrackWithoutStartTime,
        reason: 'reason' in event.options,
        success: false,
        time: Date.now() - cartToTrack.startTime,
      })
    }
  }

  // handle the response of either successful or failed order process
  useEffect(() => {
    if (!cart || didTrackCart.current) return
    const sub = getEventSubscription().subscribe((event) => {
      if (event.type !== EventType.Data) return

      if (event.notificationType === NotificationType.OrderSuccess) {
        handleSuccessfulResponse(event, cartToTrack)
      } else if (
        event.notificationType === NotificationType.OrderFailure ||
        event.notificationType === NotificationType.OrderIdMissingInResponse
      ) {
        handleErrorResponse(event, cartToTrack)
      }

      clearOrderProcessInformation()
      didTrackCart.current = true
    })
    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, cartToTrack])

  return { order, setOrder }
}
