import { Typography } from '@mui/material'
import React from 'react'

import { normalizeInt } from '@obeta/utils/lib/data-formatter/normalizeAmount'
import { E2EProps } from '@obeta/components/lib/types'

interface ISizeProps {
  size: number | undefined | null
  className?: string
  typographyVariant?: React.ComponentProps<typeof Typography>['variant']
}

export const Size: React.FC<ISizeProps & E2EProps> = (props) => {
  const { typographyVariant = 'body', datatestid } = props
  if (!props.size) {
    return null
  }

  return (
    <Typography
      className={props.className}
      variant={typographyVariant}
      {...(datatestid && { 'data-testid': datatestid })}
    >
      ({normalizeInt(props.size)})
    </Typography>
  )
}
