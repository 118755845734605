import { useState, useEffect, useRef } from 'react'
import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ObetaRentArticleResponse } from './graphqlTypes'

export const RENT_ARTICLES_QUERY = gql`
  query {
    obetaRentArticles(pagination: { limit: 100 }) {
      data {
        id
        attributes {
          title
          sapId
          dehaId
          media {
            data {
              attributes {
                name
                alternativeText
                url
              }
            }
          }
          description
          rental_price
          rental_currency
          rental_duration
          rental_category {
            data {
              attributes {
                name
              }
            }
          }
          rental_locations(pagination: { limit: 100 }) {
            data {
              attributes {
                obetaStoreId
                mail
                address {
                  name1
                  zipCode
                  city
                  street
                  houseNumber
                }
                phoneNumber
                openingHours {
                  id
                  weekday
                  from
                  to
                }
              }
            }
          }
          accessories {
            id
            description
            rental_price
            rental_currency
            rental_duration
          }
        }
      }
    }
  }
`

export const useFetchRentArticlesData = (
  strapiApolloClient: ApolloClient<NormalizedCacheObject>
): IRentArticlesData => {
  const [data, setData] = useState<ObetaRentArticleResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!isMounted.current) return
      setIsLoading(true)

      try {
        const response = await strapiApolloClient.query({
          query: RENT_ARTICLES_QUERY,
        })

        setData(response.data)
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { data, isLoading, isError }
}

interface IRentArticlesData {
  data: ObetaRentArticleResponse | null
  isLoading: boolean
  isError: boolean
}
