import React, { PropsWithChildren } from 'react'
import { IResponsiveWrapperProps, ResponsiveWrapper } from './components/ResponsiveWrapper'
import { ObetaHeader } from '../header'

interface IResponsiveHeaderProps extends IResponsiveWrapperProps {
  headerRef: React.RefObject<HTMLDivElement>
}

export const ResponsiveHeader: React.FC<PropsWithChildren<IResponsiveHeaderProps>> = (props) => {
  const { backdropClassName, headerRef } = props
  return (
    <ResponsiveWrapper backdropClassName={backdropClassName}>
      <ObetaHeader ref={headerRef} />
    </ResponsiveWrapper>
  )
}
