import { gql, useQuery } from '@apollo/client'
import { Category } from '@obeta/models/lib/models/Category'
import { ArticleSearchParams, Facet } from '@obeta/models/lib/models/Search'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { CategoryFacetsResult, constructCategoryFacet } from '@obeta/utils/lib/search/facets'
import { useWarehouseContext } from '../stores/useWarehouseContext'

const CATEGORIES_QUERY = gql`
  query GetCategories($searchString: String, $facets: [String!], $filter: String) {
    searchProducts(
      searchString: $searchString
      requestOptions: { facets: $facets, filters: $filter }
    ) {
      facets {
        name
        values {
          value
          count
          meta
        }
        meta
      }
    }
  }
`

export const useCategories = (p: {
  searchParams?: ArticleSearchParams
  type: keyof Pick<ArticleSearchParams, 'obetaCategory' | 'dehaCategory'>
  currentCategory: Category | undefined
}): { categories: CategoryFacetsResult; loading: boolean } => {
  const { warehouseId } = useWarehouseContext()

  const s: ArticleSearchParams = {
    ...p.searchParams, // allows user to configure other filters.
    [p.type]: p.currentCategory,
  }

  const categoryFacets = constructCategoryFacet(
    p.currentCategory?.level,
    p.type === 'obetaCategory' ? 'obetaCatalogIds' : 'dehaCatalogIds'
  )
  const categoryFilters = buildFilterParams(s, warehouseId)

  const { data, loading } = useQuery<{ searchProducts: { facets: Facet[] } }>(CATEGORIES_QUERY, {
    variables: {
      facets: categoryFacets.facets,
      filter: categoryFilters || '',
      searchString: s.searchString || '',
    },
  })

  const facets = data?.searchProducts?.facets || []
  return { categories: categoryFacets.createCatalog(facets), loading }
}
