import React from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import clsx from 'clsx'
import { BaseNotificationRow } from '@obeta/components/lib/notifications/BaseNotificationLayout'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import styles from '@obeta/components/lib/notifications/Notification.module.scss'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'

export const InsufficientCookieConsentToShowVideosPlugin = {
  id: 'ory/editor/core/content/video',
  title: '',
  description: '',
  version: 1,
  Renderer: () => (
    <Card className="cookieconsent-optout-marketing" elevation={2}>
      <Stack
        className={clsx(
          styles.cardContent,
          styles.darkGray,
          styles.lightInfoBackground,
          {
            [styles['leftborder']]: 'darkGray',
          },
          styles.borderless
        )}
        padding={'1rem'}
        gap=".5rem"
      >
        <Box className={styles.icon}>
          <InfoIcon />
        </Box>
        <Stack direction={'column'} flex={1} gap={'0.5rem'} overflow={'hidden'}>
          <BaseNotificationRow>
            <Typography variant={'bodyBold'}>Hinweis Videoansicht</Typography>
          </BaseNotificationRow>
          <BaseNotificationRow>
            <Typography variant={'body'}>
              Akzeptieren Sie Marketing-Cookies, um dieses Video anzusehen. Cookie-Einstellungen{' '}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore*/}
              <TertiaryButton fullWidth={false} onClick={() => window?.Cookiebot?.renew()}>
                hier ändern.
              </TertiaryButton>
            </Typography>
          </BaseNotificationRow>
        </Stack>
      </Stack>
    </Card>
  ),
}
