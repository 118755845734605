/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum NotificationEnum {
  ConstructionDocumentationCreated = 'ConstructionDocumentationCreated',
  ConstructionDocumentationFailed = 'ConstructionDocumentationFailed',
  OrderCreated = 'OrderCreated',
  OrderFailed = 'OrderFailed',
  CartNotified = 'CartNotified',
  OrderIdMissingInResponse = 'OrderIdMissingInResponse',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
