import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { LightGrayDivider } from '../../../light-gray-divider/LightGrayDivider'
import styles from './DatasheetFormSection.module.scss'

type DatasheetFormSectionProps = {
  title: string
  children: React.ReactNode
}

export const DatasheetFormSection: FC<DatasheetFormSectionProps> = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <Typography variant="bodyBold">{title}</Typography>
      <LightGrayDivider className={styles.divider} />
      {children}
    </div>
  )
}
