import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { CameraSource } from '@capacitor/camera'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { useTranslation } from 'react-i18next'
import styles from './ImagePickerPopover.module.scss'

type ImagePickerPopoverProps = {
  isOpen: boolean
  onClose: () => void
  takePictureCallback: (target: CameraSource) => Promise<void>
}

export const ImagePickerPopover = ({
  isOpen,
  onClose,
  takePictureCallback,
}: ImagePickerPopoverProps) => {
  const { t } = useTranslation()

  const options = [
    { source: CameraSource.Photos, label: t('PERMISSIONS.GET_PHOTO_POPUP_OPTIONS.PHOTOS_OPTION') },
    { source: CameraSource.Camera, label: t('PERMISSIONS.GET_PHOTO_POPUP_OPTIONS.CAMERA_OPTION') },
  ]

  return (
    <PopoverFromBottom isOpen={isOpen} paperStyles={styles.moreMenu} onClose={onClose}>
      <List className={styles.takePicturePopover}>
        <ListItem className={styles.title}>
          <ListItemText primary={t('PERMISSIONS.GET_PHOTO_POPUP_OPTIONS.TITLE')} />
        </ListItem>
        {options.map((option) => (
          <ListItem key={option.source} onClick={async () => takePictureCallback(option.source)}>
            <ListItemText className={styles.itemText} primary={option.label} />
          </ListItem>
        ))}
        <ListItem onClick={onClose}>
          <ListItemText primary={t('BUTTON.ABORT')} />
        </ListItem>
      </List>
    </PopoverFromBottom>
  )
}
