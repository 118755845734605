import React from 'react'
import orderPendingStyles from './CheckoutOrderPending.module.scss'
import orderSuccessStyles from './CheckoutOrderSuccess.module.scss'
import styles from './CheckoutOrderFailure.module.scss'
import { ReactComponent as FailureIcon } from 'assets/icon/designsystem/highlight_off.svg'
import { Grid, SvgIcon, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ContinueShopping } from './ContinueShopping'
import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'

interface Props {
  reason: string
}

export const CheckoutOrderFailure: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { shopSupportPhoneNumber } = useCompanyContactDetailsContext()

  const { reason } = props

  return (
    <Grid
      container
      direction="row"
      className={clsx(orderPendingStyles.mainContainer, styles.mainContainerHeight)}
    >
      <Grid item xs={12}>
        <div className={styles.failureIconContainer}>
          <SvgIcon className={styles.failureIcon} component={FailureIcon} />
        </div>

        <Typography variant={'h3'} className={orderSuccessStyles.headerText}>
          {t('SHOPPING_CART.A_PROBLEM_OCCURRED')}
        </Typography>

        <div
          className={clsx(
            orderSuccessStyles.textLine2,
            orderSuccessStyles.marginBottomHalfRem,
            styles.descriptionOrderFailed
          )}
        >
          <Typography variant={'boldText'}>
            {reason in OrderFailureReason
              ? t('SHOPPING_CART.DESCRIPTION_PLACING_ORDER_FAILED')
              : reason}
          </Typography>
        </div>

        <div className={orderSuccessStyles.textLine2}>
          <Typography variant={'boldText'}>
            <Typography variant={'boldText'} color={'primary'}>
              T
            </Typography>{' '}
            {shopSupportPhoneNumber}
          </Typography>
        </div>
        <ContinueShopping />
      </Grid>
    </Grid>
  )
}
