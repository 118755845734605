import { DatasheetFormSection } from '../datasheet-form-section/DatasheetFormSection'
import { useTranslation } from 'react-i18next'
import { AllPricesSection } from './sections/all-prices-section/AllPricesSection'
import { OfferPriceSection } from './sections/offer-price-section/OfferPriceSection'
import { PriceTypeSection } from './sections/price-type-section/PriceTypeSection'
import styles from './PrintOptionIndividualForm.module.scss'

export const PrintOptionIndividualForm = () => {
  const { t } = useTranslation()

  return (
    <form data-testid="individual-config-form">
      <div className={styles.wrapper}>
        <DatasheetFormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.ALL_PRICES')}>
          <AllPricesSection />
        </DatasheetFormSection>
        <DatasheetFormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.OFFER_PRICE')}>
          <OfferPriceSection />
        </DatasheetFormSection>
        <DatasheetFormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.PRICE_TYPE')}>
          <PriceTypeSection />
        </DatasheetFormSection>
      </div>
    </form>
  )
}
