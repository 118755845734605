import { useTranslation } from 'react-i18next'
import { LocationOverlayGroup } from './LocationOverlayGroup'
import { Typography } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import { Browser } from '@capacitor/browser'
import { TertiaryResponsiveIconButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import { STORENET_URL } from '@obeta/utils/lib/config'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { StoreV2 } from '@obeta/models/lib/models'
import styles from './LocationOverlaySection.module.scss'

const sortStoresByName = (stores: StoreV2[]) => {
  return stores.length
    ? stores.sort((storePrev, storeNext) =>
        storePrev.address.name2.localeCompare(storeNext.address.name2)
      )
    : []
}

export const LocationOverlaySection = () => {
  const { t } = useTranslation()
  const { changeOverlayLocation, stores } = useLocationPageProvider()

  const { user } = useUserDataV2()
  const userStoreId = user?.settings?.defaultStoreId || null

  const [groupState, setGroupState] = useState<Record<string, boolean>>({})

  const openAttachment = () => Browser.open({ url: `${STORENET_URL}?cb=${new Date().getTime()}` }) //add cachebuster to always get the newest version

  const groupStoresByRegion = useMemo(() => {
    const res = sortStoresByName(stores).reduce((acc, item) => {
      const region = item.region

      if (!acc[region]) {
        acc[region] = []
      }
      acc[region].push(item)
      return acc
    }, {})

    return res as Record<string, StoreV2[]>
  }, [stores])

  // In case userStoreId has been changed, we need to sync our group state and open/re-open the group dropdown again
  useEffect(() => {
    const preSelectedRegion = Object.keys(groupStoresByRegion).find((region) => {
      return groupStoresByRegion[region]?.find((store) => store.id === userStoreId)
    })

    if (preSelectedRegion) {
      setGroupState((prev) => ({ ...prev, [preSelectedRegion]: true }))
    }
  }, [groupStoresByRegion, userStoreId])

  /* We need to sync the state for previously opened groups to understand that we need to update a certain group that depends
   on userStoreId changes if it has been closed, and we change userStoreId with a different store but in the same category. */
  const onToggleGroup = useCallback((state: boolean, group: string) => {
    setGroupState((prev) => ({ ...prev, [group]: state }))
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <Typography variant="headline3Bold" className={styles.title}>
          {t('LOCATION_PAGE.ALL_BRANCHES_TITLE')}
        </Typography>
        {STORENET_URL && (
          <TertiaryResponsiveIconButton icon={<DownloadIcon />} onClick={openAttachment}>
            {t('LOCATION_PAGE.DOWNLOAD_BRANCH_INFO_TITLE')}
          </TertiaryResponsiveIconButton>
        )}
      </div>
      {Object.keys(groupStoresByRegion)
        .sort()
        .map((region) => {
          return (
            <LocationOverlayGroup
              onToggle={onToggleGroup}
              isExpanded={groupState[region]}
              key={region}
              groupTitle={region}
              branches={groupStoresByRegion[region] || []}
              onClick={changeOverlayLocation}
            />
          )
        })}
    </div>
  )
}
