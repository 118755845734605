import { Tab, SortBy } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { CartTemplate } from '@obeta/schema'

export type CartTemplatesListPageState = {
  templates: CartTemplate[]
  searchTerm: string
  page: number
  currentPageAll: number
  currentPageFavorite: number
  currentPagePrivate: number
  currentPagePublic: number
  sortBy: SortBy
  tab: Tab
  shouldFetchProductData: boolean
  isInitialLoading: boolean
}

type CartTemplatesListPageAction =
  | { type: 'CHANGE_INITIAL_LOADING'; payload: boolean }
  | { type: 'CHANGE_SORT_BY'; payload: SortBy }
  | { type: 'CHANGE_TAB'; payload: Tab }
  | { type: 'CHANGE_SEARCH_TERM'; payload: string }
  | { type: 'CHANGE_PAGE'; payload: number }
  | { type: 'RESET_PAGE' }
  | { type: 'SHOULD_FETCH_PRODUCT_DATA_RESET' }
  | { type: 'TEMPLATES_UPDATE_SUCCESS'; payload: CartTemplate[] }

const filterToPage: Record<
  Tab,
  'currentPageAll' | 'currentPageFavorite' | 'currentPagePrivate' | 'currentPagePublic'
> = {
  [Tab.ALL]: 'currentPageAll',
  [Tab.FAVORITES]: 'currentPageFavorite',
  [Tab.PRIVATE]: 'currentPagePrivate',
  [Tab.PUBLIC]: 'currentPagePublic',
}

export function cartTemplatesListReducer(
  state: CartTemplatesListPageState,
  action: CartTemplatesListPageAction
): CartTemplatesListPageState {
  switch (action.type) {
    case 'TEMPLATES_UPDATE_SUCCESS': {
      const templates = action.payload
      return { ...state, templates, shouldFetchProductData: true }
    }
    case 'CHANGE_SORT_BY': {
      const sortBy = action.payload
      return { ...state, shouldFetchProductData: true, sortBy }
    }
    case 'CHANGE_SEARCH_TERM': {
      const searchTerm = action.payload
      return { ...state, ...initialPageStates, shouldFetchProductData: true, searchTerm }
    }
    case 'CHANGE_PAGE': {
      const page = action.payload
      return { ...state, [filterToPage[state.tab]]: page, page, shouldFetchProductData: true }
    }
    case 'CHANGE_TAB': {
      const tab = action.payload
      return { ...state, tab: tab, page: state[filterToPage[tab]], shouldFetchProductData: true }
    }
    case 'CHANGE_INITIAL_LOADING':
      return { ...state, isInitialLoading: action.payload }
    case 'SHOULD_FETCH_PRODUCT_DATA_RESET':
      return { ...state, shouldFetchProductData: false }
    default:
      return state
  }
}

const initialPageStates = {
  currentPageAll: 1,
  currentPageFavorite: 1,
  currentPagePrivate: 1,
  currentPagePublic: 1,
  page: 1,
}
export const initialCartTemplatesListState: CartTemplatesListPageState = {
  templates: [],
  ...initialPageStates,
  sortBy: SortBy.LAST_UPDATED,
  tab: Tab.ALL,
  shouldFetchProductData: false,
  isInitialLoading: true,
  searchTerm: '',
}
