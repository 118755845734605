import { FC } from 'react'

// Styles
import { Typography } from '@mui/material'

// Styles
import styles from './OfferListItemMetaDataItem.module.scss'

type Props = {
  title: string
  value: string
}

export const OfferListItemMetaDataItem: FC<Props> = (props) => {
  const { title, value } = props

  return (
    <div className={styles.metaDataItem}>
      <Typography className={styles.metaDataItemTitle}>{title}:</Typography>
      <Typography className={styles.metaDataItemValue}>{value}</Typography>
    </div>
  )
}
