// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout> | null
  return (...args: unknown[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}
