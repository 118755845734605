import { gql, useQuery } from '@apollo/client'
import { CompanyBaseData } from '@obeta/models/lib/models/Company/CompanyBaseData'

const COMPANY_DATA_AGGREGATE = gql`
  query getCompanyBaseDataAggregate($input: CompanyInput!) {
    getCompanyBaseData(input: $input) {
      phone
      fax
      companyId
      infoEmailAddress
      servicePhoneNumber
      serviceEmailAddress
      shopSupportPhoneNumber
      shopSupportEmailAddress
      url
      shortName
      fullName
      complementaryFullName
      manager
      districtCourt
      complementaryCertificateOfRegistration
      salesTaxIdentificationNumber
      taxNumber
      bankName
      bankBic
      bankIban
      bankAccountNumber
      bankBLZ
      address {
        city
        zipCode
        street
        houseNumber
      }
    }
  }
`

export const useCompanyBaseData = (companyId: string): CompanyBaseData => {
  const { data } = useQuery<{ getCompanyBaseData: CompanyBaseData }>(COMPANY_DATA_AGGREGATE, {
    variables: { input: { companyId: companyId } },
  })

  return data?.getCompanyBaseData as CompanyBaseData
}
