import { ClickAwayListener, PopoverOrigin } from '@mui/material'
import { Alert } from '@obeta/components/lib/alert/Alert'
import { SettingsButton } from '@obeta/components/lib/alert/components/settings-button/SettingsButton'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPopoverProps, Popover } from '../popover/Popover'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { CartsActionTypes, SendCartResultAction } from '@obeta/data/lib/actions/cart-actions'
import styles from './PermissionForPushNotification.module.scss'
import { isPlatform } from '@obeta/utils/lib/isPlatform'

interface INotifcationsProps extends Omit<IPopoverProps, 'open'> {
  asModal?: boolean
  openCondition?: 'onCartSubmit'
  className?: string
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const Notifications: React.FC<INotifcationsProps> = (props) => {
  const { asModal = true, openCondition, className, ...popoverProps } = props
  const { t } = useTranslation()
  const [hasPermission, setHasPermission] = useState(false)
  const [openAditionalCondition, setOpenAditionalCondition] = useState(
    openCondition === 'onCartSubmit' ? false : true
  )

  const [closedByUser, setClosedByUser] = useState(false)
  const waitForSubmit = useActionNotification(CartsActionTypes.SendCartResult)

  useEffect(() => {
    let unmounted = false

    ;(async () => {
      const permissionStatus = await FirebaseMessaging.checkPermissions()
      if (unmounted) {
        return
      }

      if (permissionStatus.receive === 'granted') {
        setHasPermission(true)
      }
    })()

    return () => {
      unmounted = true
    }
  }, [])

  useEffect(() => {
    if (openCondition !== 'onCartSubmit') {
      return
    }

    let unmounted = false
    waitForSubmit((sendCartResultAction: SendCartResultAction) => {
      if (unmounted) {
        return
      }

      // if submitting failed, it would be annoying for the user to
      // get this popover additionally, so let's only show it on successful submits
      if (sendCartResultAction.success) {
        setOpenAditionalCondition(true)
      }
    })

    return () => {
      unmounted = true
    }
  }, [openCondition, waitForSubmit])

  const message = (
    <>
      {t('COMMON.NOW')} <b>{t('ALERTS.ACTIVATE_PUSH_NOTIFACTIONS')}</b> {t('COMMON.AND')}{' '}
      {t('ALERTS.GET_CURRENT_INFO')}
    </>
  )

  const open = !hasPermission && openAditionalCondition

  if (!open && !asModal) {
    return null
  }

  const alert = (
    <Alert
      className={className}
      type="notifcation"
      message={message}
      endAdornment={
        <SettingsButton
          onClick={async () => {
            const result = await FirebaseMessaging.requestPermissions()
            if (result.receive === 'granted') {
              setHasPermission(true)

              // if the user has granted permission after click, we need to also register for a push token
              try {
                await FirebaseMessaging.getToken()
              } catch (err) {
                // Sentry.captureException(err) TODO replace former Sentry-Code with DataDog
              }
            }
          }}
        />
      }
    />
  )

  if (asModal) {
    return (
      <Popover
        anchorEl={document.body}
        open={open && !closedByUser}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: styles.popoverRoot }}
        {...popoverProps}
      >
        <ClickAwayListener
          onClickAway={() => {
            setClosedByUser(true)
          }}
          // disableReactTree={true}
        >
          {/**
           * Wrap children with "div" to make ClickAwayListener reference children component.
           * Otherwise ClickWayListener won't work
           */}
          <div>{alert}</div>
        </ClickAwayListener>
      </Popover>
    )
  }

  return alert
}

export const PermissionForPushNotifications = React.memo<INotifcationsProps>(
  function PermissionForPushNotifications(props) {
    if (!isPlatform('ios')) {
      return null
    }

    return <Notifications {...props} />
  }
)
