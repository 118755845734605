import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { submitIdsPostForm } from '@obeta/utils/lib/idsHelper'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { IdsFormFields } from '@obeta/schema'
import { CartsActionTypes, resetShoppingCartGraphQL } from '../../actions'
import { useActionNotification } from '../useActionNotification'
import { useHistory } from '../useHistoryApi'

type SubmitIdsFormOptions = {
  cart?: ShoppingCartV2
  idsFormFields: IdsFormFields | null
  userCanSubmitCart?: boolean
  withRedirect?: boolean
}

export const useSubmitIdsForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const waitForResetShoppingCartAction = useActionNotification(
    CartsActionTypes.ResetShoppingCartGraphQLResult
  )

  const submitIdsForm = useCallback(
    ({ cart, userCanSubmitCart, idsFormFields, withRedirect }: SubmitIdsFormOptions) => {
      const sessionContext = getSessionContext()
      if (!cart || !sessionContext || !idsFormFields) return null
      if (userCanSubmitCart) {
        dispatch(resetShoppingCartGraphQL(cart))
      } else {
        submitIdsPostForm(
          idsFormFields,
          sessionContext.hookUrl as string,
          sessionContext.target as string
        )
        return
      }

      waitForResetShoppingCartAction(() => {
        if (withRedirect) history.push('/')
        submitIdsPostForm(
          idsFormFields,
          sessionContext.hookUrl as string,
          sessionContext.target as string
        )
      })
    },
    [dispatch, history, waitForResetShoppingCartAction]
  )

  return submitIdsForm
}
