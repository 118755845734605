import React, { useEffect, useState } from 'react'
import styles from './ConstructionDocumentationPicker.module.scss'
import { clsx } from 'clsx'
import { createOrdersInputByQueryParamsAndURLSearchParams } from '@obeta/utils/lib/orders-helpers'
import { ConstructionDocumentationOption } from '../orders/ConstructionDocumentationOption'
import { Box, FormControl, Modal, RadioGroup, Skeleton, Typography } from '@mui/material'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import {
  DEFAULT_ORDER_LIST_QUERY_PARAMS,
  OrderListURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { GetProductCountForConstructionDocumentationQuery, OrdersInput } from '@obeta/schema'
import { getURLSearchParamsByLocationSearch } from '@obeta/utils/lib/virtualized-list'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { PRODUCT_COUNT_QUERY, useOrderSearch } from '@obeta/data/lib/hooks/useOrderSearch'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as ErrorOutlineIcon } from 'assets/icon/designsystem/error_outline.svg'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'
import { useTranslation } from 'react-i18next'

interface Props {
  searchResultCount: number
  open: boolean
  selectedOption: string
  onDownload: (option: string) => void
  setShowConstructionDocumentationPicker: (i: boolean) => void
}
export const ConstructionDocumentationPicker: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { queryOrders } = useOrderSearch()

  const {
    open,
    searchResultCount,
    selectedOption,
    onDownload,
    setShowConstructionDocumentationPicker,
  } = props
  const { desktop } = useBreakpoints()
  const history = useHistory()

  const [productCountThresholdExceeded, setProductCountThresholdExceeded] = useState(false)
  const [productCountRetrieved, setProductCountRetrieved] = useState(false)
  const PRODUCT_COUNT_THRESHOLD = 500
  const largeVersionTemporarilyDisabled = true

  useEffect(() => {
    const urlSearchParams = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
      history.location.search
    )
    const input: OrdersInput = createOrdersInputByQueryParamsAndURLSearchParams(
      // Please note, that the backend is overwriting the limit set within params
      DEFAULT_ORDER_LIST_QUERY_PARAMS,
      urlSearchParams
    )
    const checkProductCountExceeded = async () => {
      const data = await queryOrders<GetProductCountForConstructionDocumentationQuery>(
        input,
        PRODUCT_COUNT_QUERY
      )
      setProductCountRetrieved(true)
      if (
        data &&
        data.getProductCountForConstructionDocumentation.sapIdsCount > PRODUCT_COUNT_THRESHOLD
      ) {
        setProductCountThresholdExceeded(true)
      } else {
        setProductCountThresholdExceeded(false)
      }
    }
    checkProductCountExceeded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [optionToBeDownloaded, setOptionToBeDownloaded] = useState(selectedOption)

  const handleClose = () => {
    setShowConstructionDocumentationPicker(false)
  }
  const handleSelectOptionToBeDownloaded = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setOptionToBeDownloaded(event.target.value)
  }

  const handleStartDownload = () => {
    onDownload(optionToBeDownloaded)
    setShowConstructionDocumentationPicker(false)
  }

  return (
    <Modal
      open={open || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={clsx(styles.outerGrid)}>
        <div className={styles.headerBox}>
          <div className={styles.heading}>
            <Typography variant={'h4'} color={'text.primary'}>
              {t('ORDERS.CONSTRUCTION_DOCUMENTATION.TITLE')}
            </Typography>
            <TertiaryButton
              leftIcon={<CloseIcon />}
              onClick={handleClose}
              size={desktop ? 'small' : 'large'}
            />
          </div>

          {productCountRetrieved ? (
            <Typography variant={'bodyBold'}>
              {t('ORDERS.CONSTRUCTION_DOCUMENTATION.CHOOSE_OPTION', { count: searchResultCount })}
            </Typography>
          ) : (
            <TextSkeleton className={styles.skeletonTitle}></TextSkeleton>
          )}
        </div>
        {productCountRetrieved && productCountThresholdExceeded && (
          <Box>
            <SimpleInfoBlock
              variant="light"
              Icon={ErrorOutlineIcon}
              iconClassName={styles.iconStyling}
              iconFontSize={'large'}
              iconVariant={productCountThresholdExceeded ? 'warning' : 'info'}
              body={t('ORDERS.CONSTRUCTION_DOCUMENTATION.EXCEEDING_PRODUCT_COUNT_WARNING')}
            />
          </Box>
        )}
        {productCountRetrieved ? (
          <FormControl>
            <RadioGroup
              defaultValue={selectedOption}
              className={styles.radioGroup}
              onChange={handleSelectOptionToBeDownloaded}
            >
              <div className={styles.optionGrid}>
                <ConstructionDocumentationOption
                  disabled={productCountThresholdExceeded || largeVersionTemporarilyDisabled}
                  id={'large'}
                  heading={t('ORDERS.CONSTRUCTION_DOCUMENTATION.LARGE')}
                  body1={t(
                    'ORDERS.CONSTRUCTION_DOCUMENTATION.OBETA_AND_SUPPLIER_DATASHEETS_ZIPPED'
                  )}
                  body2={t('ORDERS.CONSTRUCTION_DOCUMENTATION.OPTIMISED_FOR_DIGITAL_ARCHIVING')}
                  selected={optionToBeDownloaded === 'large'}
                />

                <ConstructionDocumentationOption
                  disabled={productCountThresholdExceeded}
                  id={'small'}
                  heading={t('ORDERS.CONSTRUCTION_DOCUMENTATION.SMALL')}
                  body1={t('ORDERS.CONSTRUCTION_DOCUMENTATION.OBETA_DATASHEETS_ONLY')}
                  body2={t('ORDERS.CONSTRUCTION_DOCUMENTATION.OPTIMISED_FOR_PRINTING')}
                  selected={optionToBeDownloaded === 'small'}
                />
              </div>
            </RadioGroup>
          </FormControl>
        ) : (
          <div className={clsx(styles.optionGrid, styles.fullHeight)}>
            <Skeleton variant="rectangular" className={styles.skeleton}></Skeleton>
            <Skeleton variant="rectangular" className={styles.skeleton}></Skeleton>
          </div>
        )}
        <div className={styles.dividerWrapper}>
          <LightGrayDivider />
        </div>
        <div className={styles.footerGrid}>
          <DarkButton
            disabled={!productCountRetrieved}
            fullWidth={false}
            onClick={productCountThresholdExceeded ? handleClose : handleStartDownload}
          >
            {productCountThresholdExceeded
              ? t('ORDERS.CONSTRUCTION_DOCUMENTATION.CLOSE')
              : t('ORDERS.CONSTRUCTION_DOCUMENTATION.START_DOWNLOAD')}
          </DarkButton>
        </div>
      </div>
    </Modal>
  )
}
