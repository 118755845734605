import { Stack } from '@mui/material'
import React from 'react'
import { IArticle } from './types'
import styles from './ArticleCategories.module.scss'
import { CatalogInfo } from './CatalogInfo'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OnlineCategoryInfo } from './OnlineCategoryInfo'

interface IArticleCategoriesProps {
  article: IArticle | undefined
  titleClassName?: string
}

export const ArticleCategories: React.FC<IArticleCategoriesProps> = (props) => {
  const { article, titleClassName } = props
  const deha = article?.categories?.deha
  const obeta = article?.categories?.obeta
  const catalogInfo = article?.catalogInfo
  const { mobile } = useBreakpoints()

  return (
    <Stack className={styles.root} gap={mobile ? '2.5rem' : '4rem'}>
      <OnlineCategoryInfo deha={deha} titleClassName={titleClassName} />
      <CatalogInfo
        titleClassName={titleClassName}
        available={Boolean(obeta?.length || catalogInfo?.page)}
        catalogInfo={catalogInfo}
        obeta={obeta}
        isCategoriesVisible={true}
      />
    </Stack>
  )
}
