import { useApolloClient } from '@apollo/client'
import { useEffect, useState, useCallback, createContext, useContext } from 'react'
import { IPromotionData } from '@obeta/models/lib/models/Promotion/Promotion'
import { GET_OBETA_PROMOTION_QUERY } from '../queries/promotion'

const defaultPromotionData: IPromotionData = {
  name: '',
  content: null,
  startTime: '',
  endTime: '',
  algoliaQuery: '',
  teaser: '',
  criteriaType: '',
  createdAt: '',
  updatedAt: '',
  showInApp: true,
  showForObeta: true,
  showForHaeusler: true,
  showForEldis: true,
  benefits: [],
  __typename: '',
  progress: 0,
  setProgress: () => null,
  promotionType: 'shop',
  criteriaUnit: '',
}
export const PromotionDataContext = createContext<IPromotionData>(defaultPromotionData)

export const usePromotionData = (): IPromotionData => {
  return useContext(PromotionDataContext)
}

export const useInitPromotionData = (promotionId: string): IPromotionData => {
  const client = useApolloClient()
  const [promotionData, setPromotionData] = useState(defaultPromotionData)
  const [progress, setProgressValue] = useState(0)
  const getPromotion = useCallback(async () => {
    const response = await client.query({
      query: GET_OBETA_PROMOTION_QUERY,
      variables: { id: promotionId },
    })
    setPromotionData(response.data.obetaPromotion.data.attributes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionId])

  useEffect(() => {
    getPromotion()
  }, [getPromotion])

  const setProgress = (value: number) => setProgressValue(value)

  return { ...promotionData, progress, setProgress }
}
