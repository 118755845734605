import { isUiTarget } from './isUiTarget'
import { AppLauncher } from '@capacitor/app-launcher'
import { CapacitorHttp, HttpResponse } from '@capacitor/core'

export const submitElbridgePostFormToConfigurator = async (configuratorUrl: string) => {
  const isNativeUITarget = isUiTarget('app')

  const elBridgeForm = document.createElement('form')
  elBridgeForm.target = '_blank'
  elBridgeForm.method = 'POST'
  elBridgeForm.action = configuratorUrl

  const inputVersion = document.createElement('input')
  inputVersion.type = 'hidden'
  inputVersion.name = 'version'
  inputVersion.value = '1.0'

  const inputHookUrl = document.createElement('input')
  inputHookUrl.type = 'hidden'
  inputHookUrl.name = 'hookurl'
  inputHookUrl.value = `${window.location.protocol}//${window.location.host}/elbridge`

  elBridgeForm.appendChild(inputVersion)
  elBridgeForm.appendChild(inputHookUrl)

  document.body.appendChild(elBridgeForm)

  if (isNativeUITarget) {
    const formData = new URLSearchParams()
    formData.append('version', '1.0')
    formData.append('hookurl', process.env.REACT_APP_BASE_URL + '/elbridge')

    const options = {
      url: configuratorUrl,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: formData.toString(),
    }

    try {
      const response: HttpResponse = await CapacitorHttp.post(options)
      AppLauncher.openUrl({ url: response.url })
    } catch (error) {
      throw new Error(`Error while sending request: ${error.message}`)
    }
  } else {
    elBridgeForm.submit()
  }
}
