import React from 'react'
import styles from './ConstructionDocumentationOption.module.scss'
import { Typography, Card, CardContent, Radio, FormControlLabel } from '@mui/material'
import clsx from 'clsx'

interface Props {
  id: string
  heading: string
  body1: string
  body2: string
  selected: boolean
  disabled?: boolean
}

export const ConstructionDocumentationOption: React.FC<Props> = (props) => {
  const { id, heading, body1, body2, selected, disabled = false } = props

  const textColor = disabled ? 'text.disabled' : 'text.primary'

  return (
    <Card
      className={clsx(styles.card, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      elevation={0}
    >
      <CardContent className={styles.cardContent}>
        <div>
          {id === 'large' && (
            <FormControlLabel
              disabled={disabled}
              color={textColor}
              className={clsx(styles.formControlLabel, styles.halfLineHeight)}
              value={id}
              label={
                <Typography
                  variant={'bodyBold'}
                  color={textColor}
                  className={styles.halfPaddingTop}
                >
                  {heading}
                </Typography>
              }
              control={<Radio disabled={disabled} />}
            />
          )}

          {id === 'small' && (
            <FormControlLabel
              checked={selected}
              color={textColor}
              className={clsx(styles.formControlLabel, styles.halfLineHeight)}
              value={id}
              label={
                <Typography variant={'bodyBold'} color={textColor}>
                  {heading}
                </Typography>
              }
              control={<Radio disabled={disabled} />}
            />
          )}
        </div>
        <div className={styles.contentBox}>
          <Typography variant={'body'} color={textColor}>
            {body1}
          </Typography>
          <br />
          <Typography variant={'body'} color={textColor}>
            {body2}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
