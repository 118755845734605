import { FC } from 'react'
import clsx from 'clsx'
import styles from './CollapseComponent.module.scss'

import { Collapse, ICollapseProps } from '../collapse/Collapse'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { CollapseCard, CollapseCardIcon } from '../collapse-card/CollapseCard'
import { E2EProps } from '../types'

export const CollapseComponent: FC<ICollapseProps & WithBreakpoints<'mobile'> & E2EProps> = (
  props
) => {
  const { mobile, className, ...collapseProps } = props

  if (mobile) {
    return (
      <Collapse
        className={clsx(styles.root, className)}
        classes={{ header: styles.collapseHeader }}
        {...collapseProps}
        Icon={CollapseCardIcon}
        iconLeft={false}
      />
    )
  }

  return <CollapseCard {...collapseProps} Icon={CollapseCardIcon} />
}
