import { useEffect, useRef, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useUserSelectedStore } from './useUserSelectedStore'
import { useUserDataV2 } from './useUserDataV2'
import { priceStockSearchQuery } from '../queries/priceStockSearchQuery'
import { useWarehouseContext } from '../stores/useWarehouseContext'

import {
  CompleteSearchProduct,
  MaybeCompleteSearchProduct,
} from '@obeta/models/lib/schema-models/search'
import { ProductAggregate } from '@obeta/schema'

const searchQuery = gql`
  query searchPromotionProducts($searchString: String, $hitsPerPage: Int, $page: Int) {
    searchProducts(
      searchString: $searchString
      requestOptions: { hitsPerPage: $hitsPerPage, page: $page }
    ) {
      nbHits
      queryId
      products {
        title
        sapId
        articleDescription
        imageData {
          sapId
          images {
            large
          }
        }
        images {
          width
          url
        }
        supplierImageData {
          large
        }
        isDeleted
        isForSale
        isSendable
        isDiscontinued
        priceDimension
        minimumAmount
        oxomiId
        obetaId
        dehaId
        unit
        type
        isTopseller
      }
    }
  }
`

interface IPromotionArticlesListParams {
  articleData: MaybeCompleteSearchProduct[]
  itemsCount: number
  isLoading: boolean
  isDataLoaded: boolean
}

export const usePromotionArticlesList = (
  searchString = '',
  page = 0
): IPromotionArticlesListParams => {
  const client = useApolloClient()
  const { isLoggedIn } = useUserDataV2()
  const { selectedStore } = useUserSelectedStore()
  const isMounted = useRef(true)

  const [articleData, setArticleData] = useState<MaybeCompleteSearchProduct[]>([])
  const [itemsCount, setItemsCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)
  const selectedStoreId = selectedStore?.id
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const doSearch = async () => {
      if (!isMounted.current || !searchString) return
      setIsLoading(true)

      const response = await client.query({
        query: searchQuery,
        variables: {
          searchString,
          hitsPerPage: 10,
          page: page,
        },
      })
      const result = response.data.searchProducts
      setArticleData(result.products)
      setItemsCount(result.nbHits)

      const sapIds = result.products.map((prod) => prod.sapId)
      if (sapIds.length && isLoggedIn) {
        const warehouseIds: string[] = [warehouseId]
        if (selectedStoreId) warehouseIds.push(selectedStoreId)

        const priceStocksResult = await client.query({
          query: priceStockSearchQuery,
          variables: {
            sapIds,
            warehouseIds,
          },
        })
        const productData: ProductAggregate[] = priceStocksResult.data.getProducts
        setArticleData((current) => {
          productData.forEach((data) => {
            const el = current.find((p) => p?.sapId === data?.sapId)
            if (el) {
              ;(el as CompleteSearchProduct).stock = data.stock
              ;(el as CompleteSearchProduct).prices = data.prices
            }
          })
          return [...current]
        })
      }

      setIsLoading(false)
      setIsDataLoaded(true)
    }
    doSearch()
  }, [client, page, searchString, selectedStoreId, isLoggedIn, warehouseId])

  return { articleData, itemsCount, isLoading, isDataLoaded }
}
