import { Typography } from '@mui/material'
import styles from './ThumbLabel.module.scss'

interface IThumbLabel {
  text: React.ReactText
  icon: React.ReactChild
}

export const ThumbLabel: React.FC<IThumbLabel> = (props) => {
  return (
    <div className={styles.root}>
      {props.icon}
      <Typography className={styles.label}>{props.text}</Typography>
    </div>
  )
}
