import { FC, useCallback, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as ThumbUpIcon } from 'assets/icon/designsystem/thumb_up.svg'
import { ReactComponent as ThumbDownIcon } from 'assets/icon/designsystem/thumb_down.svg'
import { ReactComponent as SettingsIcon } from 'assets/icon/designsystem/settings.svg'
import { FeedbackState, FeedbackValue } from '@obeta/models/lib/models/Feedback/Feedback'
import styles from './Feedback.module.scss'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { Modal } from '../modal/Modal'
import { DarkButton, TertiaryButton, TertiaryIconButton } from '../custom-button/CustomButton'
import { isActive, saveFeedbackTime, sendFeedback } from '@obeta/utils/lib/feedback-helpers'
import { getCookiebotStatisticsFlag } from '@obeta/data/lib/components/operations-integration/utils/getCookiebotFlags'
import { useCompanyContactDetailsContext } from '@obeta/data/lib/stores/useCompanyContactDetailsContext'

type FeedbackProps = {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any // Optional custom params for Datadog
  placeholder?: string
  title: string
}

/**
 * Customer feedback component to provide positive or negative reviews for a module.
 * Debug Cookiebot in local development: Remove {window?.location?.origin !== 'http://localhost:4200' && (...)} check in _app.tsx.
 * @param props
 * @returns
 */
export const Feedback: FC<FeedbackProps> = (props) => {
  const { id, params, placeholder, title } = props

  const { t } = useTranslation()
  const { shopSupportPhoneNumber } = useCompanyContactDetailsContext()

  const { control, handleSubmit } = useForm({ mode: 'onChange' })
  const comment = useWatch({ control, name: 'comment' }) ?? ''

  const [state, setState] = useState<FeedbackState>(FeedbackState.Initial)
  const [value, setValue] = useState<FeedbackValue | undefined>(undefined)

  const onCancel = useCallback(() => {
    try {
      if (value) {
        sendFeedback(id, value, undefined, params)
        saveFeedbackTime(id)
        setValue(undefined)
      }
    } catch (error) {
      localStorage.removeItem(id)
    }
    setState(FeedbackState.Finished)
  }, [id, params, value])

  const onCloseContainer = useCallback(() => {
    saveFeedbackTime(id)
    setState(FeedbackState.Finished)
  }, [id])

  const onCloseModal = useCallback(() => {
    saveFeedbackTime(id)
    setState(FeedbackState.Finished)
  }, [id])

  /**
   * Handler to change cookie consent when trying to provide module feedback.
   */
  const onChangeConsent = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.Cookiebot?.show()
    setState(FeedbackState.Initial)
  }, [])

  /**
   * Handler to set feedback evaluation value.
   * @param value FeedbackValue
   */
  const onClickEvaluation = useCallback((value: FeedbackValue) => {
    // Check if Cookiebot statistics are enabled to display a hint to change cookie consent
    if (getCookiebotStatisticsFlag()) {
      setValue(value)
      setState(FeedbackState.Comment)
    } else {
      setState(FeedbackState.ChangeConsent)
    }
  }, [])

  /**
   * Handler to submit feedback evaluation value (+ optional comment) to Datadog.
   * @param Data Comment
   */
  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: Record<string, any>) => {
      try {
        if (value) {
          sendFeedback(id, value, data.comment, params)
          saveFeedbackTime(id)
          setValue(undefined)
        }
      } catch (error) {
        localStorage.removeItem(id)
      }
      setState(FeedbackState.Success)
    },
    [id, params, value]
  )

  return (
    <>
      {isActive(id) &&
        state !== FeedbackState.Finished &&
        (state === FeedbackState.Initial || state === FeedbackState.ChangeConsent) && (
          <div
            className={clsx(
              styles.feedback,
              state === FeedbackState.Initial && styles.feedbackCenter
            )}
          >
            {state === FeedbackState.Initial && (
              <>
                <div className={styles.feedbackContent}>
                  <Typography variant="body">{title}</Typography>
                  <div className={styles.feedbackContentClose}>
                    <TertiaryIconButton
                      icon={<CloseIcon />}
                      size="small"
                      onClick={onCloseContainer}
                    />
                  </div>
                </div>
                <div className={styles.feedbackButtons}>
                  <TertiaryIconButton
                    icon={<ThumbUpIcon />}
                    size="small"
                    onClick={() => onClickEvaluation('positive')}
                  />
                  <TertiaryIconButton
                    icon={<ThumbDownIcon />}
                    size="small"
                    onClick={() => onClickEvaluation('negative')}
                  />
                </div>
              </>
            )}
            {state === FeedbackState.ChangeConsent && (
              <>
                <div className={styles.feedbackContent}>
                  <InfoIcon className={styles.feedbackChangeConsentInfoIcon} />
                  <div className={styles.feedbackChangeConsentInfo}>
                    <Typography variant="bodyBold">{t('FEEDBACK.COOKIES.HINT')}:</Typography>
                    <Typography variant="body"> {t('FEEDBACK.COOKIES.ACCEPT')}</Typography>
                  </div>
                </div>
                <div className={clsx(styles.feedbackContent, styles.feedbackChangeConsentSettings)}>
                  <TertiaryButton
                    leftIcon={<SettingsIcon />}
                    size="small"
                    onClick={onChangeConsent}
                  >
                    {t('FEEDBACK.COOKIES.CHANGE_CONSENT')}
                  </TertiaryButton>
                </div>
              </>
            )}
          </div>
        )}
      <Modal
        aria-describedby="feedback-description"
        aria-labelledby="feedback-title"
        open={state === FeedbackState.Comment || state === FeedbackState.Success}
        onClose={onCloseModal}
      >
        <div className={styles.feedbackModal}>
          <div className={styles.feedbackModalHeader}>
            <Typography variant="headline4Bold">
              {state === FeedbackState.Comment
                ? t('FEEDBACK.COMMENT.TITLE')
                : t('FEEDBACK.SUCCESS.TITLE')}
            </Typography>
            <TertiaryIconButton
              color="text.primary"
              icon={<CloseIcon />}
              size="small"
              onClick={onCloseModal}
            />
          </div>
          {state === FeedbackState.Comment && (
            <form className={styles.feedbackModalContent} onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="body">{t('FEEDBACK.COMMENT.LABEL')}</Typography>
              <div className={styles.feedbackModalContentForm}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="comment"
                  render={({ field: { onChange, onBlur } }) => (
                    <TextField
                      id="comment"
                      className={styles.feedbackModalContentFormTextField}
                      inputProps={{ maxLength: 2000 }}
                      multiline={true}
                      name="comment"
                      placeholder={placeholder ?? t('FEEDBACK.PLACEHOLDER')}
                      rows={2}
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                  rules={{
                    maxLength: 2000,
                  }}
                />
                <Typography
                  className={styles.feedbackModalContentFormCommentLength}
                  data-testid="feedback-comment-length"
                  variant="body"
                >
                  {`${(comment as string).length} / 2000 ${t('FEEDBACK.COMMENT.CHARS')}`}
                </Typography>
              </div>
              <LightGrayDivider />
              <div className={styles.feedbackModalActions}>
                <TertiaryButton onClick={onCancel} size="large">
                  {t('FEEDBACK.COMMENT.CANCEL')}
                </TertiaryButton>
                <DarkButton fullWidth type="submit">
                  {t('FEEDBACK.COMMENT.SUBMIT')}
                </DarkButton>
              </div>
            </form>
          )}
          {state === FeedbackState.Success && (
            <div className={styles.feedbackModalContentSuccess}>
              <Typography variant="bodyBold">{t('FEEDBACK.SUCCESS.TEXT')}</Typography>
              <div className={styles.feedbackModalContentSuccessPhone}>
                <Typography className={styles.feedbackModalContentSuccessPhoneT} variant="bodyBold">
                  T
                </Typography>
                <Typography variant="bodyBold">{shopSupportPhoneNumber}</Typography>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
