import React from 'react'
import { SvgIcon, Typography } from '@mui/material'
import styles from './promotionProgressStatus.module.scss'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check_circle.svg'
import { ReactComponent as Warning } from 'assets/icon/designsystem/warning_amber.svg'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { useTranslation } from 'react-i18next'

interface IPromotionProgressStatusProps {
  isAchieved: boolean
  name: string
  sum: number
  criteriaType?: 'amount' | 'sum' | string
  criteriaUnit?: string
}

export const PromotionProgressStatus: React.FC<IPromotionProgressStatusProps> = (props) => {
  const { isAchieved, name, sum, criteriaType, criteriaUnit } = props
  const { t } = useTranslation()

  const icon = isAchieved ? CheckIcon : Warning
  const iconColor = isAchieved ? 'success' : 'warning'
  const unit = criteriaUnit ?? 'Artikel'
  return (
    <div className={styles['progress-status-wrapper']}>
      <SvgIcon component={icon} color={iconColor} />
      <div className={styles['progress-status-text']}>
        {isAchieved ? (
          <Typography variant="bodyBold" className={styles['progress-status-reward-text']}>
            {t('PROMOTIONS.ACHIEVEMENT_UNLOCKED', { achievementName: name })}
          </Typography>
        ) : (
          <Typography variant="bodyBold" className={styles['progress-status-sum-text']}>
            {t('PROMOTIONS.SUM_LEFT', {
              price: criteriaType === 'sum' ? normalizePrice(sum, '€') : `${sum} ${unit}`,
            })}
          </Typography>
        )}
        {isAchieved && (
          <Typography variant="bodyBold" className={styles['progress-status-delivery-text']}>
            &nbsp;{`(${t('PROMOTIONS.SEPARATE_DELIVERY')})`}
          </Typography>
        )}
      </div>
    </div>
  )
}
