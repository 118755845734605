import { useCallback } from 'react'
import { submitCartGraphQL } from '../../actions'
import { trackClick } from '@obeta/utils/lib/tracking'
import { filter, map, race, take, timer } from 'rxjs'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { useCompanyContactDetailsContext } from '../../stores/useCompanyContactDetailsContext'

export const useCreateOrderSubmit = (onStartSubmit: (cart: ShoppingCartV2) => void) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { shopSupportPhoneNumber } = useCompanyContactDetailsContext()

  const submitCart = useCallback(
    (cart) => {
      dispatch(submitCartGraphQL(cart.id))
      trackClick('submit-cart-request', {
        cartId: cart.id,
      })

      if (onStartSubmit) {
        onStartSubmit(cart)
      }

      race(
        timer(120 * 1000).pipe(
          map(() => {
            return false
          })
        ),
        getEventSubscription().pipe(
          filter((notificationObject) => {
            return (
              notificationObject.id === `${cart.id}-${NotificationType.OrderSuccess}` ||
              notificationObject.id === `${cart.id}-${NotificationType.OrderFailure}`
            )
          })
        )
      )
        .pipe(take(1))
        .subscribe((result) => {
          if (typeof result === 'boolean') {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.OrderLimbo,
              id: `${cart.id}-${NotificationType.OrderLimbo}`,
              options: {
                heading: t('SHOPPING_CART.CHECKOUT.NOTIFICATION.PROCESSING_ERROR'),
                body: t(
                  'SHOPPING_CART.CHECKOUT.NOTIFICATION.CHECK_ORDER_STATUS_AND_CONTACT_SUPPORT'
                ),
                contact: shopSupportPhoneNumber,
                cartId: cart.id,
              },
            })
          }
        })

      if (cart?.shoppingCartPrices) {
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.OrderPending,
          id: `${cart.id}-${NotificationType.OrderPending}`,
          options: {
            positionTotal: cart.items.length.toString(),
            shippingDate: cart.shippingData.shippingDate,
            shippingType: cart.shippingData.shippingType,
            shoppingCartPrices: cart.shoppingCartPrices,
          },
        })
      }
    },
    [dispatch, onStartSubmit, t, shopSupportPhoneNumber]
  )
  return submitCart
}
