import { useEffect } from 'react'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { StatusBar } from '@capacitor/status-bar'
import { Style as StatusBarStyle } from '@capacitor/status-bar/dist/esm/definitions'
import { SplashScreen } from '@capacitor/splash-screen'
import { detectInsets } from '../utils/detectInsets'
import { belowMdQuery, useMediaQuery } from '@obeta/data/lib/hooks'

export const useStatusBarAndSplashScreenSetup = () => {
  const isBelowMd = useMediaQuery(belowMdQuery)

  useEffect(() => {
    if (!isPlatform('web')) {
      /**
       * @see https://capacitorjs.com/docs/apis/status-bar#ios-note
       */
      setTimeout(() => {
        /*
          This timeout became necessary for Android 13 - why? - circumstances are not known, this is a hotfix.
        Before, we had the issue that neither the status bar color nor the web view overlay mode were properly interpreted on some devices (e.g., Pixel 6 Pro).
        Seems related to: https://github.com/ionic-team/capacitor/issues/6423.
        */
        if (!isPlatform('ios')) {
          StatusBar.setOverlaysWebView({ overlay: true })
        }
        // The statusbar style does indicate wether the statusbar background is dark or light, the os determines the font color then
        StatusBar.setStyle({ style: StatusBarStyle.Light })
      }, 0)

      // Splash screen duration is preconfigured, this hides the splash as soon as possible
      SplashScreen.hide()
      detectInsets()
    }
  }, [isBelowMd])
}
