import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'
import { OverridableStringUnion } from '@mui/types'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { ReactComponent as ChevronRight } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as SortIcon } from 'assets/icon/designsystem/import_export.svg'
import { ReactComponent as PdfIcon } from 'assets/icon/pdf-file-format-symbol.svg'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { mapReceiptTypeToDisplayValue } from '@obeta/utils/lib/mapOpenPositionReceiptType'
import { OrderDetailsIconLink } from './OrderDetailsIconLink'
import styles from './OpenPositionsTable.module.scss'
import {
  OpenPositionSortingOrderBy,
  useMyAccountingContext,
} from '@obeta/data/lib/stores/useMyAccountingContext'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import stylesCheckbox from '@obeta/components/lib/product-card/withCheckbox.module.scss'
import { Checkbox } from '@obeta/components/lib/checkbox/Checkbox'
import { useDispatchInvoices } from '@obeta/data/lib/hooks/useDispatchInvoice'

type OpenPositionsTableProps = {
  items: OpenPosition[]
}
type OpenPositionTableRowProps = {
  item: OpenPosition
  cumulatedAmount: number
}

const LazyOpenPositionMobileInfo = dynamic(() =>
  import('./OpenPositionMobileInfo').then((mod) => mod.OpenPositionMobileInfo)
)
export const TableCellRightAligned: FC<{ className?: string }> = ({ children, className }) => {
  const { mobile } = useBreakpoints()

  return (
    <TableCell
      className={clsx(className, styles.cell, styles.rightAligned, mobile && styles.mobileCell)}
    >
      {children}
    </TableCell>
  )
}

type CellProps = {
  right?: boolean
  iconRight?: boolean
  orderBy?: OpenPositionSortingOrderBy
  sortableClassName?: string
  className?: string
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}
export const Cell: FC<CellProps> = ({
  children,
  className,
  right,
  iconRight,
  variant,
  orderBy,
  sortableClassName,
}) => {
  const { mobile, desktop } = useBreakpoints()
  const { sorting, setSorting } = useMyAccountingContext()

  const TableCellComponent = right ? TableCellRightAligned : TableCell
  return (
    <TableCellComponent
      className={clsx(
        className,
        styles.cell,
        mobile && styles.condensedCell,
        mobile && styles.mobileCell
      )}
      style={orderBy ? { display: 'flex' } : {}}
    >
      {!orderBy && <Typography variant={variant}>{children}</Typography>}
      {orderBy && (
        <div
          className={clsx(
            sortableClassName,
            styles.sortableHead,
            right && styles.rightAligned,
            !desktop && styles.increasedTouchArea,
            !right && styles.leftAligned
          )}
          onClick={() => {
            if (sorting?.orderBy === orderBy) {
              const order = sorting.order === 'asc' ? 'desc' : 'asc'
              setSorting({ orderBy, order })
            } else {
              setSorting({ orderBy, order: 'asc' })
            }
          }}
        >
          {!desktop && right && !iconRight && <SortIcon />}
          <Typography variant={variant}>{children}</Typography>
          {(desktop || !right || iconRight) && <SortIcon />}
        </div>
      )}
    </TableCellComponent>
  )
}

const OpenPositionTableRow: FC<OpenPositionTableRowProps> = ({ item, cumulatedAmount }) => {
  const { filter } = useMyAccountingContext()
  const [isMobileInfoVisible, setIsMobileInfoVisible] = useState(false)
  const { tablet, mobile } = useBreakpoints()
  const { t } = useTranslation()
  const { selectedItems, setSelectedItems, setMasterCheckboxChecked } = useMyAccountingContext()
  const { dispatchInvoicePdf } = useDispatchInvoices()

  const receiptTypeDisplayValue = mapReceiptTypeToDisplayValue(item.receiptType)
  const receipt =
    receiptTypeDisplayValue !== null && !mobile
      ? `${item.receipt} ${receiptTypeDisplayValue}`
      : item.receipt

  const checkBoxClicked = () => {
    setSelectedItems((prevItems: OpenPosition[]) => {
      if (prevItems.includes(item)) {
        return prevItems.filter((prevItem) => prevItem !== item)
      } else {
        return prevItems.concat([item])
      }
    })
  }
  const isNoPdfIconItem = (item: { receipt: string }): boolean => {
    return (
      item.receipt === 'Verzugspauschale' ||
      item.receipt === 'Mahngebühr' ||
      item.receipt.startsWith('23')
    )
  }

  useEffect(() => {
    if (selectedItems.length === 0) setMasterCheckboxChecked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.length])

  return (
    <>
      <TableRow className={clsx(styles.tableBodyRow, mobile && styles.mobile)}>
        <Cell right sortableClassName={styles.receiptHead}>
          <div style={{ display: 'block' }}>
            <Box className={styles.checkBoxPdfContainer}>
              {!mobile && (
                <Checkbox
                  key={item.id}
                  checked={selectedItems.includes(item)}
                  className={styles.checkbox}
                  onChange={checkBoxClicked}
                />
              )}
              <div className={styles.receiptContainer}>
                {!mobile && !isNoPdfIconItem(item) && (
                  <PdfIcon
                    className={styles.pdfIcon}
                    onClick={() => dispatchInvoicePdf(item.receipt)}
                  />
                )}
                <div>{receipt}</div>
              </div>
            </Box>
          </div>
        </Cell>
        {!mobile && <Cell right>{dateFormatter(item.invoiceFromDate)}</Cell>}
        {!mobile && <Cell right>{normalizePrice(item.invoiceAmount, '€')}</Cell>}
        {filter !== 'overdue' && !mobile && !tablet && (
          <Cell right>
            <div>
              {normalizePrice(item.openAmount, '€')}
              {item.isDiscount && <div>{t('MY_ACCOUNTING.DISCOUNT')}</div>}
            </div>
          </Cell>
        )}
        {filter === 'overdue' && !mobile && !tablet && <Cell right>{item.dunningLevel}</Cell>}
        {filter === 'discountable' && !mobile && !tablet && (
          <Cell right>{dateFormatter(item.discountDate)}</Cell>
        )}
        <TableCellRightAligned>
          <div
            className={clsx(
              styles.rightAligned,
              item.isOverdue && styles.overdueDate,
              item.isDiscount && styles.discountDate
            )}
          >
            <Typography variant="bodyBold">{dateFormatter(item.invoiceDueDate)}</Typography>
            {item.isOverdue && <Typography variant="bodyBold">überfällig</Typography>}
          </div>
        </TableCellRightAligned>
        <Cell className={styles.cumulatedAmountCell} right>
          {normalizePrice(cumulatedAmount)}
        </Cell>
        <TableCellRightAligned>
          <div className={styles.icon}>
            {!mobile && <OrderDetailsIconLink item={item} />}
            {mobile && (
              <span onClick={() => setIsMobileInfoVisible(true)}>
                <ChevronRight />
              </span>
            )}
          </div>
        </TableCellRightAligned>
      </TableRow>
      {isMobileInfoVisible && (
        <LazyOpenPositionMobileInfo item={item} close={() => setIsMobileInfoVisible(false)} />
      )}
    </>
  )
}

interface PropsTableHead {
  totalCountItems: number
  selectAllItems: () => void
}
const OpenPositionsTableHead: FC<PropsTableHead> = (props) => {
  const { filter } = useMyAccountingContext()
  const { totalCountItems, selectAllItems } = props
  const { t } = useTranslation()
  const { tablet, mobile, desktop } = useBreakpoints()
  const { selectedItems, setSelectedItems, masterCheckboxChecked, setMasterCheckboxChecked } =
    useMyAccountingContext()

  const onClickMasterCheckbox = () => {
    if (masterCheckboxChecked) {
      if (totalCountItems === selectedItems.length) {
        setSelectedItems([])
        setMasterCheckboxChecked(false)
      } else {
        selectAllItems()
      }
    } else {
      selectAllItems()
      setMasterCheckboxChecked(true)
    }
  }

  return (
    <TableHead className={clsx(styles.tableHead, mobile && styles.mobileCell)}>
      <TableRow>
        <Box className={clsx(styles.checkBoxPdfContainer, styles.checkBoxHeaderWrapper)}>
          {!mobile && (
            <Checkbox
              checked={masterCheckboxChecked}
              indeterminate={selectedItems.length > 0 && selectedItems.length !== totalCountItems}
              className={clsx(stylesCheckbox.checkbox, styles.checkbox, styles.masterCheckBox)}
              onChange={onClickMasterCheckbox}
            />
          )}

          <Cell orderBy="receipt" variant="bodyBold">
            {t('MY_ACCOUNTING.RECEIPT')}
          </Cell>
        </Box>

        {!mobile && (
          <Cell orderBy="invoiceFromDate" right variant="bodyBold">
            {t('MY_ACCOUNTING.FROM_DATE')}
          </Cell>
        )}
        {!mobile && (
          <Cell orderBy="invoiceAmount" right variant="bodyBold">
            {desktop ? (
              t('MY_ACCOUNTING.AMOUNT_INVOICE')
            ) : (
              <>
                <span>{t('MY_ACCOUNTING.AMOUNT_INVOICE_CHUNKS.0')}</span>
                <br />
                <span>{t('MY_ACCOUNTING.AMOUNT_INVOICE_CHUNKS.1')}</span>
              </>
            )}
          </Cell>
        )}
        {filter !== 'overdue' && !mobile && !tablet && (
          <Cell orderBy="openAmount" right variant="bodyBold">
            {t('MY_ACCOUNTING.AMOUNT_OUTSTANDING_CHUNKS.0')}
            {desktop ? <>&nbsp;</> : <br />}
            {t('MY_ACCOUNTING.AMOUNT_OUTSTANDING_CHUNKS.1')}
          </Cell>
        )}
        {filter === 'overdue' && !mobile && !tablet && (
          <Cell orderBy="dunningLevel" right variant="bodyBold">
            {t('MY_ACCOUNTING.DUNNING_LEVEL')}
          </Cell>
        )}
        {filter === 'discountable' && !mobile && !tablet && (
          <Cell orderBy="discountDate" right variant="bodyBold">
            {desktop ? (
              t('MY_ACCOUNTING.DISCOUNTABLE')
            ) : (
              <>
                <span>{t('MY_ACCOUNTING.DISCOUNTABLE_CHUNKS.0')}</span>
                <br />
                <span>{t('MY_ACCOUNTING.DISCOUNTABLE_CHUNKS.1')}</span>
              </>
            )}
          </Cell>
        )}
        <Cell
          orderBy="invoiceDueDate"
          right
          iconRight={mobile}
          variant="bodyBold"
          className={styles.noWrap}
        >
          {t(mobile ? 'MY_ACCOUNTING.DUE_DATE_SHORT' : 'MY_ACCOUNTING.DUE_DATE')}
        </Cell>
        <Cell right variant="bodyBold">
          {t('MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.0')}
          <br />
          <div>
            {t('MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.1')}&nbsp;
            {t('MY_ACCOUNTING.ACCUMULATED_OPEN_AMOUNT.2')}
          </div>
        </Cell>
        <TableCellRightAligned></TableCellRightAligned>
      </TableRow>
    </TableHead>
  )
}

export const OpenPositionsTable: FC<OpenPositionsTableProps> = ({ items }) => {
  const { searchTerm, setSelectedItems } = useMyAccountingContext()

  let cumulated = 0

  if (items.length === 0) return <NoResults className={styles.noResult} searchTerm={searchTerm} />
  const selectAllItems = () => {
    setSelectedItems([])
    setSelectedItems(items)
  }

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table aria-label="my-accounting-table">
        <OpenPositionsTableHead totalCountItems={items.length} selectAllItems={selectAllItems} />
        <TableBody>
          {items.map((item, index) => {
            cumulated += item.openAmount
            return <OpenPositionTableRow key={item.id} item={item} cumulatedAmount={cumulated} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
