import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import {
  validateFloatingNumber,
  validateIntegerNumber,
} from '@obeta/utils/lib/regexp/validateFloatingNumber'
import { TextFieldProps } from '@mui/material/TextField/TextField'

type InputNumberProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string | number) => void
  allowNegative?: boolean
  value?: string | number
  withDecimals?: boolean
}

// We can use both "," and "." separators, but a valid number should have a "." separator.
const replaceCommasWithDots = (value: string) => value.replace(',', '.')

export const InputNumber = (props: InputNumberProps) => {
  const [localValue, setLocalValue] = useState<string>('')

  const { onChange, value, allowNegative = false, withDecimals = true, variant, ...rest } = props

  // sync in case we trigger update via state outside
  useEffect(() => {
    if (value === undefined) return setLocalValue('')

    const preparedLocalValue = allowNegative && localValue === '-' ? '' : localValue

    if (Number(value) !== Number(replaceCommasWithDots(preparedLocalValue))) {
      setLocalValue(String(value))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowNegative, value])

  return (
    <TextField
      {...rest}
      variant={variant || 'outlined'}
      value={localValue}
      onChange={(e) => {
        const inputValue = e.target.value

        if (inputValue === '') {
          setLocalValue(inputValue)
          onChange(inputValue)
          return
        }

        if (
          withDecimals
            ? validateFloatingNumber(inputValue, allowNegative)
            : validateIntegerNumber(inputValue, allowNegative)
        ) {
          setLocalValue(inputValue)

          const preparedLocalValue =
            allowNegative && inputValue === '-' ? '' : Number(replaceCommasWithDots(inputValue))

          onChange(preparedLocalValue)
        }
      }}
    />
  )
}
