import { hasWindow } from './ssr'

export const isTouchDevice = () => {
  if (!hasWindow()) {
    return false
  }

  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).msMaxTouchPoints > 0
  )
}
