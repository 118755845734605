import styles from './SuppliersOverviewPage.module.scss'
import { SuppliersOverviewContextProvider } from './hooks/useSuppliersOverviewProvider'
import {
  SuppliersListSection,
  InfoSection,
  SuppliersOverviewTopSection,
  SuppliersOverviewControlSection,
} from './components/index'

export const SuppliersOverviewPage = () => {
  return (
    <>
      <div className={styles.background} />
      <div className={styles.suppliersPageWrapper}>
        <InfoSection />
        <SuppliersOverviewTopSection />
        <SuppliersOverviewContextProvider>
          <SuppliersOverviewControlSection />
          <SuppliersListSection />
        </SuppliersOverviewContextProvider>
      </div>
    </>
  )
}
