import { FC } from 'react'
import { PinnedNewsSection } from './components/PinnedNewsSection/PinnedNewsSection'
import { ControlSection } from './components/ControlSection/ControlSection'
import { NewsContent } from './components/ContentNews/ContentNews'
import { NewsContextProvider } from './hooks/useNewsProvider'
import styles from './NewsPage.module.scss'

export const NewsPage: FC = () => {
  return (
    <>
      <div className={styles.background} />
      <div className={styles.newsPageWrapper}>
        <NewsContextProvider>
          <PinnedNewsSection />
          <ControlSection />
          <NewsContent />
        </NewsContextProvider>
      </div>
    </>
  )
}
