import { List, ListItem } from '@mui/material'
import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'
import { useStackHistory } from '@obeta/components/lib/stack'
import { Header } from '@obeta/components/lib/stack/Header'
import { SlideWrap } from '@obeta/components/lib/stack/SlideWrap'
import { DispatchFiltersAction } from '../reducer'
import styles from './KeywordPickerSlide.module.scss'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Size } from '../Size'
import { EtimSkeletons } from './EtimSkeletons'
import { useFeaturePointerData } from '../hooks/useFeaturePointerData'
import { useTranslateValues } from '@obeta/data/lib/hooks/useTranslateValues'
import { SelectedEtimValue } from '@obeta/models/lib/models/Search'

interface IKeywordPickerProps {
  activeEtimFeatureName: string | null
  selectedEtimValues: Record<string, SelectedEtimValue>
  dispatchEtimsAction: DispatchFiltersAction
  onClose: () => void
}

const Item: React.FC<{
  onClick: MouseEventHandler
  label: React.ReactText
  count: number
  active: boolean
  id: string
}> = (props) => {
  const { active, count, label, id } = props
  return (
    <ListItem className={styles.listItem} disablePadding key={id} onClick={props.onClick}>
      <RadioItem
        checked={active}
        id={id}
        label={label}
        rightAdornment={<Size className={styles.size} size={count} />}
      />
    </ListItem>
  )
}

export const KeywordPicker: React.FC<IKeywordPickerProps> = (props) => {
  const { activeEtimFeatureName, selectedEtimValues, dispatchEtimsAction, onClose } = props
  const stackHistory = useStackHistory()
  const {
    total,
    activeEtimValue: active,
    featurePointer,
    selectFeature,
  } = useFeaturePointerData({ activeEtimFeatureName, selectedEtimValues, dispatchEtimsAction })

  const { t } = useTranslation()
  const { tValue } = useTranslateValues()

  let view: JSX.Element | null = null
  if (featurePointer) {
    view = (
      <List disablePadding>
        <Item
          key="reset"
          label={t<string>('COMMON.ALL')}
          id="reset"
          count={total}
          active={!active}
          onClick={() => {
            if (!active) {
              return
            }

            dispatchEtimsAction({
              type: 'resetSelectedEtimValue',
              payload: {
                selectedValue: active.value,
              },
            })
          }}
        />
        {featurePointer.values.map((val) => {
          return (
            <Item
              key={val.value}
              id={val.value}
              active={active?.value === val.value}
              label={tValue(val.value)}
              count={val.count}
              onClick={() => {
                selectFeature(val)
                onClose()
              }}
            />
          )
        })}
      </List>
    )
  } else {
    view = <EtimSkeletons />
  }

  return (
    <SlideWrap>
      <Header
        title={featurePointer?.meta.name || ''}
        onBackClicked={() => {
          stackHistory.goBack()
        }}
        rightAdornment={<Size size={total} />}
      />
      {view}
    </SlideWrap>
  )
}
