import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { ProductsSwiper } from './ProductsSwiper'
import { RelatedProductEnum, useRelatedProducts } from '@obeta/data/lib/hooks/useRelatedProducts'
import { SectionWrapper } from './SectionProvider'
import { ArticleCarouselCard, ArticleAccessoriesCard } from '@obeta/components/lib/product-card'
import styles from './ArticleAccessories.module.scss'
import { useCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { SectionHeader } from './SectionHeader'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import clsx from 'clsx'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'

const MAX_ITEMS_TO_SHOW = 4

interface IArticleAccessoriesProps extends WithBreakpoints<'mobile' | 'tabletAll'> {
  selectedStore: StoreV2 | undefined
}

export const ArticleAccessories: React.FC<IArticleAccessoriesProps> = (props) => {
  const { mobile, tabletAll, selectedStore } = props
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const productdIds = useCurrentProductIds()
  const items = useRelatedProducts(
    productdIds,
    [RelatedProductEnum.Mandatory, RelatedProductEnum.Sparepart, RelatedProductEnum.Accessories],
    selectedStore?.id
  )
  const products = showMore || mobile ? items : items.slice(0, MAX_ITEMS_TO_SHOW)
  const { isLoggedIn, user } = useUserDataV2()
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()

  const asSlider = mobile

  const accesoriesCards = products.map((p, i) => {
    const params = {
      key: i,
      carts: carts,
      selectedCart: selectedCart,
      tablet: Boolean(tabletAll),
      selectedStore: selectedStore,
      mobile: Boolean(mobile),
      product: p,
      user: user,
      isLoggedIn: isLoggedIn,
      productId: p.sapId,
    }
    return asSlider ? <ArticleCarouselCard {...params} /> : <ArticleAccessoriesCard {...params} />
  })

  return (
    <SectionWrapper
      available={products.length > 0}
      className={styles.accessories}
      id={ArticleDetailsSections.Accessories}
    >
      <div className={styles.header}>
        <SectionHeader>{t('ARTICLE_DETAIL.SUITABLE_ACCESSORIES')}</SectionHeader>
      </div>
      <div className={styles.list}>
        {asSlider ? (
          <ProductsSwiper
            cardWrapperClassName={clsx(styles.cardWrapper)}
            elementsPerRow={'auto'}
            productElements={accesoriesCards}
          />
        ) : (
          accesoriesCards
        )}
      </div>
      {!mobile && (
        <ToggleMore
          canToggle={items.length > MAX_ITEMS_TO_SHOW}
          showMore={showMore}
          toggleMore={() => {
            setShowMore((baseState) => !baseState)
          }}
        />
      )}
    </SectionWrapper>
  )
}
