import { gql, useMutation } from '@apollo/client'
import { useDbUtils } from '@obeta/data/lib/hooks/useDbUtils'

const DELETE_MESSAGE = gql`
  mutation DeleteUserNotifications($id: String!) {
    deleteUserNotifications(id: $id)
  }
`

export const useDeleteNotifications = () => {
  const { getCollectionSync } = useDbUtils()
  const replState = getCollectionSync('message')

  const [runDeleteNotiifactions] = useMutation(DELETE_MESSAGE, {
    onCompleted: (data) => {
      replState?.reSync()
    },
  })

  const deleteNotification = (id: string) => {
    runDeleteNotiifactions({
      variables: {
        id,
      },
    })
  }

  return { deleteNotification }
}
