import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './SectionHeader.module.scss'

export interface ISectionHeaderProps {
  marginBottom?: 'xs' | 'md' | 'xl' | 'none'
  variant?: React.ComponentProps<typeof Typography>['variant']
  className?: string
}

export const SectionHeader: React.FC<ISectionHeaderProps> = (props) => {
  const { marginBottom = 'none', variant = 'headline3Bold' } = props

  return (
    <Typography
      color="secondary"
      variant={variant}
      component="h3"
      className={clsx(props.className, styles.marginBottom, styles[marginBottom || ''])}
    >
      {props.children}
    </Typography>
  )
}
