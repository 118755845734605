import { FC } from 'react'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Section } from '../../../startPage/Section'
import { ProductsSwiper } from '../../../articleDetailsPage/ProductsSwiper'
import { LocationNewsCard } from '../LocationNewsCard/LocationNewsCard'
import { ILocationNews, useLocationNews } from '../../hooks/useLocationNews'
import { useLocationPageProvider } from '../../hooks/LocationPageContextProvider'
import styles from './LocationNewsSection.module.scss'

interface ILocationNewsProps {
  locationNews: ILocationNews[]
}

export const LocationNewsView: FC<ILocationNewsProps> = ({ locationNews }) => {
  const { mobile } = useBreakpoints()
  const { changeOverlayLocation } = useLocationPageProvider()

  const elementsPerRow = mobile ? 1 : 3
  const elementsPerRowAdapted =
    locationNews.length < elementsPerRow ? locationNews.length : elementsPerRow

  return (
    <div className={styles.locationNewsCard}>
      <ProductsSwiper
        allowTouchMove={mobile}
        closeNavigationButtons={mobile}
        cardWrapperClassName={styles.cardsWrapper}
        withNavigateButtons={mobile}
        isSliderLoop={false}
        elementsPerRow={elementsPerRowAdapted}
        productElements={locationNews.map((locationNewsEntry) => (
          <LocationNewsCard
            {...locationNewsEntry}
            changeOverlayLocation={changeOverlayLocation}
            key={locationNewsEntry.id}
            textMaxLines={mobile ? 4 : 3}
          />
        ))}
      />
    </div>
  )
}

export const LocationNewsSection: FC = () => {
  const { locationNews } = useLocationNews()

  if (!locationNews.length) {
    return null
  }

  return (
    <Section
      className={styles.locationPageSectionWrapper}
      content={<LocationNewsView locationNews={locationNews} />}
    />
  )
}
