import React from 'react'
import { Box, Typography } from '@mui/material'
import { ISupplierOverviewItem } from '../../hooks/useSuppliersOverviewProvider'
import { ReactComponent as TecselectIcon } from 'assets/icon/designsystem/Tecselect_grau1.svg'
import { ReactComponent as VoltimumIcon } from '@obeta/assets/icon/voltimum.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { DropdownMenu } from '@obeta/components/lib/header/components/dropdown'
import { MenuItemColor } from '@obeta/components/lib/header/components/dropdown/types'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { Browser } from '@capacitor/browser'
import { BasePopover } from '@obeta/components/lib/dropdown-button'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ReactComponent as KeyboardArrowDownIcon } from '@obeta/assets/icon/designsystem/keyboard_arrow_down.svg'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import styles from './SuppliersOverviewCard.module.scss'

const suppliersOverviewListOptions = {
  Artikelliste: {
    name: 'Artikelliste',
    id: 'Artikelliste',
  },
  Lieferantenwebsite: {
    name: 'Lieferantenwebsite',
    id: 'Lieferantenwebsite',
  },
}

export const SuppliersOverviewCard = ({
  name,
  logoUrl,
  hasTecselect,
  hasVoltimum,
  homepage,
  filterName,
}: ISupplierOverviewItem) => {
  const popoverState = usePopoverState()
  const changeSearchParams = useChangeSearchParams()

  const { mobile } = useBreakpoints()

  const onOpenHomePage = () => {
    const target = '_blank'
    isPlatform('web') ? window.open(homepage, target) : Browser.open({ url: homepage })
  }

  const onOpenSearchPage = () => {
    const data = {
      articleSearchArea: '0', //  all
      obetaCategory: null,
      suppliers: { [filterName]: { id: filterName, name: filterName } },
    }

    changeSearchParams({ searchParams: data, route: 'push' })
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.suppliersOverviewTags}>
        {hasTecselect && <TecselectIcon />}
        {hasVoltimum && <VoltimumIcon className={styles.voltimumIcon} />}
      </Box>
      <Box className={styles.suppliersOverviewImg}>
        <Box className={styles.suppliersOverviewImgContainer}>
          <img src={logoUrl.large} alt="supplier logo" />
        </Box>
      </Box>
      <Box className={styles.suppliersOverviewFooter}>
        {mobile ? (
          <>
            <TertiaryButton
              key="homepage"
              onClick={popoverState.handleClick}
              rightIcon={<KeyboardArrowDownIcon />}
            >
              {name}
            </TertiaryButton>
            <BasePopover
              withBackdrop={true}
              classes={{ paper: styles.popover }}
              mobile={true}
              {...popoverState}
            >
              {homepage && (
                <TertiaryButton
                  key={suppliersOverviewListOptions.Lieferantenwebsite.id}
                  onClick={onOpenHomePage}
                >
                  {suppliersOverviewListOptions.Lieferantenwebsite.name}
                </TertiaryButton>
              )}
              <TertiaryButton
                key={suppliersOverviewListOptions.Artikelliste.id}
                onClick={onOpenSearchPage}
              >
                {suppliersOverviewListOptions.Artikelliste.name}
              </TertiaryButton>
            </BasePopover>
          </>
        ) : (
          <DropdownMenu
            triggerComponent={'button'}
            isMobileDropdown={false}
            title={
              <Box className={styles.footerDropdownTitleWrapper}>
                <Typography variant={'bodyBold'} title={name}>
                  {name}
                </Typography>
              </Box>
            }
            options={[
              suppliersOverviewListOptions.Artikelliste,
              ...(homepage ? [suppliersOverviewListOptions.Lieferantenwebsite] : []),
            ]}
            color={MenuItemColor.Black}
            withBorder={false}
            onMenuItemClick={(data) => {
              if (data.name === 'Lieferantenwebsite') onOpenHomePage()
              if (data.name === 'Artikelliste') onOpenSearchPage()
            }}
            keepDefaultLeftPosition={true}
            topSpacingToAnchorEl={'0.625rem'}
          />
        )}
      </Box>
    </Box>
  )
}
