import React from 'react'
import { Typography } from '@mui/material'
import styles from './CartTemplatesListAppBar.module.scss'
import { ReactComponent as AddIcon } from 'assets/icon/designsystem/add.svg'
import { TertiaryButton } from '../../custom-button/CustomButton'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  title: string
  onCreateTemplateButtonClicked: () => void
}

export const CartTemplatesListAppBar: React.FC<Props> = ({
  title,
  onCreateTemplateButtonClicked,
}) => {
  const { desktop } = useBreakpoints()
  const { t } = useTranslation()

  return (
    <div className={styles.toolbar}>
      <Typography variant={'h3'} color={'text.primary'}>
        {title}
      </Typography>
      <TertiaryButton
        size={desktop ? 'small' : 'large'}
        onClick={() => onCreateTemplateButtonClicked()}
        leftIcon={<AddIcon />}
      >
        {t('TEMPLATES.NEW_TEMPLATE')}
      </TertiaryButton>
    </div>
  )
}
