import { useCallback } from 'react'
import { CartsActionTypes, notifyCartGraphQL, NotifyCartGraphQLResultAction } from '../../actions'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useDispatch } from 'react-redux'
import { useOnPrintCart } from '../../hooks/useOnPrintCart'
import { useActionNotification } from '../../hooks'

export const useCreateOrderNotify = (
  setApprovalData: ({ active: boolean, email: string }) => void
) => {
  const dispatch = useDispatch()
  const onPrintCart = useOnPrintCart()
  const waitForNotifyCartResultAction = useActionNotification(
    CartsActionTypes.NotifyCartGraphQLResult
  )
  const notify = useCallback(
    (cart) => {
      dispatch(notifyCartGraphQL(cart.id))
      waitForNotifyCartResultAction((action: NotifyCartGraphQLResultAction) => {
        setApprovalData({ active: true, email: action.notificationEmail })
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.CartApproval,
          id: `${cart.id}-${NotificationType.CartApproval}`,
          options: {
            emailAddress: action.notificationEmail,
            onPrintCart: () => onPrintCart(cart),
          },
        })
      })
    },
    [dispatch, onPrintCart, waitForNotifyCartResultAction, setApprovalData]
  )

  return notify
}
