import { FC } from 'react'
import { Typography, useTheme, Stack } from '@mui/material'
import { formatOpeningHours } from '@obeta/utils/lib/data-formatter/formatOpeningHours'
import styles from './StoreAddressBlock.module.scss'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'

export interface AddressBlockProps {
  city: string
  email: string
  fax?: string
  opening_hours?: {
    days: string
    times: string
  }[]
  phone?: string
  postcode: string
  street: string
}

type AddressBlockLineProps = {
  value?: string
  label: string
}

const AddressBlockLine = ({ value, label }: AddressBlockLineProps) => {
  const theme = useTheme()

  if (!value) {
    return null
  }

  return (
    <Typography variant="body">
      <Typography variant="bodyBold" color={theme.palette.primary.main}>
        {label}&ensp;
      </Typography>
      {value}
    </Typography>
  )
}

export const StoreAddressBlock: FC<StoreV2> = (props) => {
  const { street, city, zipCode, houseNumber } = props.address
  const { mail, phoneNumber, faxNumber, openingHours } = props

  return (
    <Stack gap="1rem" sx={{ width: '100%' }}>
      <div className={styles.addressGroup}>
        <Typography variant="body">
          {street} {houseNumber}
        </Typography>
        <Typography variant="body">
          {zipCode} {city}
        </Typography>
      </div>
      <div className={styles.addressGroup}>
        <AddressBlockLine value={phoneNumber} label={'T'} />
        <AddressBlockLine value={faxNumber} label={'F'} />
        <AddressBlockLine value={mail} label={'M'} />
      </div>
      <div className={styles.addressGroup}>
        {formatOpeningHours(openingHours).map((record, index) => (
          <AddressBlockLine
            key={`${record.days}-${record.times}`}
            value={record.times}
            label={`${record.days}:`}
          />
        ))}
      </div>
    </Stack>
  )
}
