import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import { EventType, getEventSubscription, NotificationType } from './pubSub'
import { VerifyOfferInShoppingCartResult } from '@obeta/models/lib/models'

type DisplayOrderFailureFunction = (options?: {
  removeNotification?: boolean
  cartId: string
}) => void

const buildId = (baseId: string, removeNotification) =>
  removeNotification ? `REMOVE::${baseId}` : baseId

export const displayCreditLimitExceeded: DisplayOrderFailureFunction = (options) => {
  if (!options?.cartId) return
  const baseId = `${options.cartId}-${NotificationType.OrderFailure}-${OrderFailureReason.CreditLimitExceeded}`
  const id = buildId(baseId, options.removeNotification)
  const value = {
    type: EventType.Toast,
    notificationType: NotificationType.OrderFailure,
    id,
    options: {
      reason: OrderFailureReason.CreditLimitExceeded,
      ...(options?.removeNotification ? { action: 'REMOVE' } : {}),
    },
  }
  getEventSubscription().next(value)
}

export const displayOrderBlockIsActivated: DisplayOrderFailureFunction = (options) => {
  const skipOrderingBlockedNotification = localStorage.getItem('skipOrderingBlockedNotification')
  if (!options?.cartId || skipOrderingBlockedNotification === '1') return
  const baseId = `${options.cartId}-${NotificationType.OrderFailure}-${OrderFailureReason.OrderingBlocked}`
  const id = buildId(baseId, options.removeNotification)
  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.OrderFailure,
    id,
    options: {
      reason: OrderFailureReason.OrderingBlocked,
      ...(options?.removeNotification ? { action: 'REMOVE' } : {}),
    },
  })
}
export const displayShippingBlockIsActivated = (cartId: string) => {
  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.OrderFailure,
    id: `${cartId}-${NotificationType.OrderFailure}-${OrderFailureReason.ShippingBlocked}`,
    options: {
      reason: OrderFailureReason.ShippingBlocked,
    },
  })
}

export const displayPickupBlockIsActivated = (cartId: string) => {
  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.CartHasItemsNoPickupPossible,
    id: cartId,
    options: {
      reason: OrderFailureReason.OrderingBlocked,
    },
  })
}

export const displayOfferInCartNotValid = (
  cartId: string,
  result: VerifyOfferInShoppingCartResult
) => {
  const changedCartItemIds = result.removedRelations.concat(
    result.removedCartItems,
    result.addedRelations
  )
  // diverse articles come with empty dehaId, will be filtered and labeled
  if (changedCartItemIds.indexOf('') !== -1) {
    changedCartItemIds.push('diverse Artikel')
  }

  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.OfferNotValid,
    id: `${cartId}-${NotificationType.OfferNotValid}-${result.removedRelations.length}-${result.removedCartItems.length}-${result.addedRelations.length}`,
    options: {
      reason: OrderFailureReason.OfferNotValid,
      changedCartItemIds: changedCartItemIds.filter((dehaId) => dehaId !== ''),
    },
  })
}
