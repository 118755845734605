import { trackCustom } from './tracking'

const CART_TO_COMPLETE_STORAGE_KEY = 'cartToComplete'

export const startOrderProcess = (
  cartId: string,
  isApprovalRequest?: boolean,
  notificationEmail?: string
) => {
  const objectToStore = { cartId, isApprovalRequest: Boolean(isApprovalRequest), notificationEmail }
  if (cartId.length === 0) return
  localStorage.setItem(CART_TO_COMPLETE_STORAGE_KEY, JSON.stringify(objectToStore))
}

export const isOrderProcessStarted = (cartId: string): boolean => {
  const objectFromStorage = localStorage.getItem(CART_TO_COMPLETE_STORAGE_KEY)
  if (!objectFromStorage) return false

  const cartToComplete = JSON.parse(objectFromStorage)
  if (cartToComplete.cartId !== cartId) {
    trackCustom('order-completion-difference-in-cart-ids', {
      cartIdFromLocalStorage: cartToComplete.cartId,
      cartIdFromUrl: cartId,
    })
    return false
  }
  return cartToComplete.cartId === cartId
}

export const isApprovalOrderProcess = (): boolean => {
  const objectFromStorage = localStorage.getItem(CART_TO_COMPLETE_STORAGE_KEY)
  if (!objectFromStorage) return false

  const cartToComplete = JSON.parse(objectFromStorage)
  return cartToComplete.isApprovalRequest
}

export const clearOrderProcessInformation = () => {
  localStorage.removeItem(CART_TO_COMPLETE_STORAGE_KEY)
}
