import { FC } from 'react'
import styles from './OrderListLoading.module.scss'
import { TextSkeleton } from '../text-skeleton/TextSkeleton'
import { Divider } from '@mui/material'

export const OrderListLoading: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <TextSkeleton height="1.75rem" width="6.125rem" />
        <TextSkeleton height="1.75rem" width="100%" />
        <div className={styles.filtersContent}>
          <TextSkeleton height="23rem" width="100%" />
          <TextSkeleton height="23rem" width="100%" />
        </div>
      </div>
      <div className={styles.content}>
        <TextSkeleton height="1.75rem" width="100%" />
        <Divider />
        <TextSkeleton height="1.75rem" width="100%" />
        <TextSkeleton height="19.875rem" width="100%" />
        <TextSkeleton height="19.875rem" width="100%" />
      </div>
    </div>
  )
}
