import React, { createRef, SourceHTMLAttributes, TrackHTMLAttributes } from 'react'
import { renderToString } from 'react-dom/server'
import 'mediaelement/build/mediaelementplayer.min.css'
import 'mediaelement-plugins/dist/quality/quality.min.css'
import { hasWindow } from '@obeta/utils/lib/ssr'
import styles from './MediaElement.module.scss'

if (hasWindow()) {
  require('mediaelement')
  require('mediaelement-plugins/dist/quality/quality.min.js')
  require('mediaelement-plugins/dist/quality/quality-i18n.js')
}

interface ISource extends SourceHTMLAttributes<HTMLSourceElement> {
  quality: string
  src?: string
}

export interface IMediaElementOptions {
  stretching?: 'responsive' | 'fill' | 'none'
  success?: (media: HTMLElement, node: HTMLElement) => void
  error?: (media: HTMLElement, node: HTMLElement) => void
}
export interface IMediaElementProps {
  id: string
  mediaType: 'video' | 'audio'
  width?: string | number
  height?: string | number
  controls: boolean
  preload: string
  poster: string
  sources: ISource[]
  tracks: TrackHTMLAttributes<HTMLTrackElement>[]
  options: IMediaElementOptions
}

interface IMedaiElementProxyProps extends IMediaElementProps {
  playerRef: React.ForwardedRef<Player | undefined>
}

interface IMediElemetProxyState {
  player: Player | null
}

const Media: React.FC<IMediaElementProps> = (props) => {
  const sources = props.sources
  const tracks = props.tracks
  const sourceTags: React.ReactElement[] = []
  const tracksTags: React.ReactElement[] = []
  for (let i = 0, total = sources.length; i < total; i += 1) {
    const source = sources[i]
    const { quality, ...restSource } = source
    if (source.src) {
      sourceTags.push(<source key={source.src} data-quality={quality} {...restSource} />)
    }
  }

  for (let i = 0, total = tracks.length; i < total; i += 1) {
    const track = tracks[i]
    tracksTags.push(<track key={track.src} {...track} />)
  }

  const Component = props.mediaType
  return (
    <Component
      id={props.id}
      width={props.width}
      height={props.height}
      controls={props.controls}
      preload={props.preload}
      poster={props.poster}
    >
      {sourceTags}
      {tracksTags}
    </Component>
  )
}

// there is no typing for custom MediaElementPlayer window combination type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Player = Record<string, any>

/**
 * We use class because we need "componentWillUnmount" life cycle method
 * to tear down player BEFORE react ties to remove element from dom
 *
 * We also don't render media element directly (using React.createElement)
 * Reason: after player is destoyed "mediaelement" lib will insert different
 * dom element for media. React won't be able to remove this element (because it's different)
 * It will lead to exception.
 */
class MediaElementProxy extends React.Component<IMedaiElementProxyProps, IMediElemetProxyState> {
  containerRef = createRef<HTMLDivElement>()

  componentDidMount() {
    const container = this.containerRef.current
    // there is no typing for custom MediaElementPlayer window combination type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any).MediaElementPlayer || !container || !hasWindow()) {
      return
    }

    const mediaEl = renderToString(<Media {...this.props} />)
    container.innerHTML = mediaEl

    const options = Object.assign({}, this.props.options, {
      features: ['playpause', 'progress', 'time', 'volume', 'fullscreen', 'tracks', 'quality'],
    })
    // there is no typing for custom MediaElementPlayer window combination type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const player = new (window as any).MediaElementPlayer(this.props.id, options)
    this.setState({ player })
    if (typeof this.props.playerRef === 'function') {
      this.props.playerRef(player)
    } else if (this.props.playerRef) {
      this.props.playerRef.current = player
    }
  }

  componentWillUnmount() {
    if (this.state?.player) {
      this.state.player.remove()
    }
  }

  render() {
    return <div ref={this.containerRef} className={styles.root} />
  }
}

export const MediaElement = React.forwardRef<Player | undefined, IMediaElementProps>(
  function MediaElement(props, ref) {
    return <MediaElementProxy key={`mediaelement-${props.id}`} playerRef={ref} {...props} />
  }
)
