import React, { useCallback, useEffect, useState } from 'react'
import { CellMeasurerCache, CellMeasurerCacheParams } from 'react-virtualized'

// Components
import { InfiniteWindowScroller, TInfiniteLoaderProps } from '../../window-scroller'
import { TemplateListItemV2 } from '../TemplateListItemV2'
import { Grid } from '@mui/material'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'

// Models
import { CartTemplate } from '@obeta/schema'
import { ITemplateMobileListProps } from './types'

// Styles
import styles from './LazyList.module.scss'

const ROW_HEIGHT = 197
const ROW_HEIGHT_MOBILE = 201
const ROW_HEIGHT_TABLET = 209

const INITIAL_CACHE: CellMeasurerCacheParams = {
  defaultHeight: ROW_HEIGHT,
  fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
}
const ITEMS_PER_PAGE = 32

export const LazyList: React.FC<ITemplateMobileListProps> = (props: ITemplateMobileListProps) => {
  const {
    deleteTemplate,
    duplicateTemplate,
    toggleFavorite,
    onRenameTemplateRequestedViaContextMenu,
    toggleVisibility,
    isUpdatingItemImages,
    amountOfTemplates,
    carts,
    filteredSortedTemplates,
  } = props

  const { desktop, mobile, tablet, tabletWide, tabletAll } = useBreakpoints()

  const [cache] = useState(new CellMeasurerCache(INITIAL_CACHE))
  const [rvData, setRvData] = useState<{
    elementsPerRow: number
    rowCount: number
    rowTotalCount: number
  }>({
    elementsPerRow: 0,
    rowCount: 0,
    rowTotalCount: 0,
  })
  const [templates, setTemplates] = useState<CartTemplate[]>([])

  useEffect(() => {
    filteredSortedTemplates.length > 0
      ? setTemplates(filteredSortedTemplates.slice(0, ITEMS_PER_PAGE))
      : setTemplates([])
  }, [filteredSortedTemplates])

  useEffect(() => {
    if (desktop) {
      setRvData({
        elementsPerRow: 4,
        rowCount: Math.ceil(templates.length / 4),
        rowTotalCount: Math.ceil(filteredSortedTemplates.length / 4),
      })
    }
    if (mobile) {
      setRvData({
        elementsPerRow: 1,
        rowCount: Math.ceil(templates.length),
        rowTotalCount: Math.ceil(filteredSortedTemplates.length),
      })
    }
    if (tablet) {
      setRvData({
        elementsPerRow: 2,
        rowCount: Math.ceil(templates.length / 2),
        rowTotalCount: Math.ceil(filteredSortedTemplates.length / 2),
      })
    }
    if (tabletWide) {
      setRvData({
        elementsPerRow: 3,
        rowCount: Math.ceil(templates.length / 3),
        rowTotalCount: Math.ceil(filteredSortedTemplates.length / 3),
      })
    }
  }, [desktop, filteredSortedTemplates, mobile, tablet, tabletWide, templates])

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => Boolean(templates[index * rvData.elementsPerRow]), // Check if actual template is loaded by elements per row value (breakpoint)
    [templates, rvData]
  )

  /**
   * Load more rows (templates) when InfiniteLoader threshold is reached.
   * @param p Row start and stop index
   */
  const loadMoreRows = async () => {
    setTemplates([
      ...templates,
      ...filteredSortedTemplates.slice(templates.length, templates.length + ITEMS_PER_PAGE),
    ])
  }
  let rowHeight = ROW_HEIGHT_MOBILE
  if (tabletAll) {
    rowHeight = ROW_HEIGHT_TABLET
  }
  if (desktop) {
    rowHeight = ROW_HEIGHT
  }

  const { rowRenderer } = useMeasuredRowRenderer({
    cache,
    render: ({ index }) => {
      const templatesSlice = templates.slice(
        index * rvData.elementsPerRow,
        index * rvData.elementsPerRow + rvData.elementsPerRow
      )
      if (templatesSlice && templatesSlice.length > 0) {
        return (
          <Grid container columnSpacing={{ sm: 1, md: 1.25, lg: 1 }} key={index}>
            {templatesSlice.map((template) => (
              <Grid className={styles.item} item key={template.id}>
                <TemplateListItemV2
                  carts={carts}
                  template={template}
                  onDeleteTemplate={deleteTemplate}
                  onDuplicateTemplate={duplicateTemplate}
                  onFavoriteToggled={toggleFavorite}
                  onRenameTemplate={onRenameTemplateRequestedViaContextMenu}
                  onVisibilityToggled={toggleVisibility}
                  isOnlyTemplate={amountOfTemplates < 2}
                  cartTemplatesCount={amountOfTemplates}
                  isLoading={isUpdatingItemImages}
                />
              </Grid>
            ))}
          </Grid>
        )
      }
      return null
    },
  })
  return (
    <InfiniteWindowScroller
      infiniteLoader={{
        isRowLoaded,
        loadMoreRows,
        minimumBatchSize: ITEMS_PER_PAGE / rvData.elementsPerRow,
        threshold: 2,
      }}
      list={{
        rowRenderer,
        rowHeight: rowHeight,
        deferredMeasurementCache: cache,
      }}
      rowTotalCount={rvData.rowTotalCount}
      rowCount={rvData.rowCount}
    />
  )
}
