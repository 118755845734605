/*
   Since we cannot load Oxomi images using Oxomi’s tools within a webview, we are implementing our own custom handlers.
 To achieve this, we gather the parameters that Oxomi's handlers utilize
 */
export const extractDownloadCatalogParams = (target: HTMLAnchorElement) => {
  const form = target.closest('form')

  if (!form)
    throw new Error(
      'Unable to extract oxomi download catalog params ( actionUrl, portal, user, accessToken, catalog )'
    )

  const actionUrl = form.action
  const portal = (form.querySelector('input[name="portal"]') as HTMLInputElement)?.value
  const user = (form.querySelector('input[name="user"]') as HTMLInputElement)?.value
  const accessToken = (form.querySelector('input[name="accessToken"]') as HTMLInputElement)?.value
  const catalog = (form.querySelector('input[name="catalog"]') as HTMLInputElement)?.value

  return { actionUrl, portal, user, accessToken, catalog }
}

export const extractOpenCatalogParams = (text: string) => {
  const extractCatalogParamsRegexp = /catalog:\s*'(\d+)',\s*page:\s*'(\d+)',\s*query:\s*'([^']+)'/

  const match = extractCatalogParamsRegexp.exec(text)

  if (!match)
    throw new Error('Unable to extract oxomi open catalog params ( catalog, page, query )')

  const [, catalog, page, query] = match

  return { catalog, query, page }
}
