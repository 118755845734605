import { FC } from 'react'
import { useTranslation } from 'react-i18next'

// Styles
import styles from './ActionBar.module.scss'

// UI
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'

type ActionBarProps = {
  submitText?: string
  onClose: () => void
  onSubmit: () => void
}

/**
 * UI component to be displayed on mobile devices when MobileFilters or PopoverFromBottom UI components are used.
 * @param props ActionBarProps
 * @returns ActionBar UI component
 */
export const ActionBar: FC<ActionBarProps> = (props) => {
  const { submitText, onClose, onSubmit } = props

  const { t } = useTranslation()

  return (
    <div className={styles.actionBar}>
      <TertiaryButton onClick={onClose}>{t('BUTTON.ABORT')}</TertiaryButton>
      <DarkButton onClick={onSubmit}>{submitText ?? t('BUTTON.SAVE')}</DarkButton>
    </div>
  )
}
