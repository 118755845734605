import { FC, Fragment, useCallback } from 'react'
import { Paper, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './ArticleCard.module.scss'

import { ReactComponent as LinkIcon } from '@obeta/assets//icon/designsystem/link.svg'
import { Addresses } from './Address'
import { Collapse } from '@obeta/components/lib/collapse/Collapse'
import { IRentArticle, IAccessory } from '@obeta/models/lib/models/RentArticle/types'
import { ProductOxomiImage } from '@obeta/components/lib/product-images/ProductOxomiImage'
import { ProductImage } from '@obeta/components/lib/product-card/layouts/ProductImage'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

export interface IArticleCardProps {
  article: IRentArticle
}

export interface IAccessoriesMapProps {
  accessories: IAccessory[]
}

const AccessoriesMap: FC<IAccessoriesMapProps> = ({ accessories }) => {
  return (
    <div className={styles['accessoriesGrid']}>
      {accessories.map(({ description, price }, index) => (
        <Fragment key={index}>
          <Typography variant="body">{description}</Typography>
          <Typography className={styles['accessoryPrice']} variant="boldText">
            {price}
          </Typography>
        </Fragment>
      ))}
    </div>
  )
}

export const ArticleCard: FC<IArticleCardProps> = ({ article }) => {
  const {
    oxomiId,
    supplierId,
    strapiImageUrl,
    productImageUrl,
    title,
    description,
    accessories,
    addresses,
    price,
    category,
    articleUrl,
  } = article
  const { t } = useTranslation()
  const history = useHistory()

  const handleRedirect = useCallback(() => {
    if (articleUrl) {
      history.push(articleUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleUrl])

  return (
    <Paper className={styles['root']}>
      <div className={styles['main']}>
        <div className={styles['img']}>
          <ProductImage size="112">
            <ProductOxomiImage
              oxomiId={oxomiId}
              supplierId={supplierId}
              src={strapiImageUrl || productImageUrl}
              alt={title}
            />
          </ProductImage>
        </div>

        {articleUrl && (
          <div className={styles['articleLinkWrapper']}>
            <div onClick={handleRedirect} className={styles['articleLink']}>
              <LinkIcon className={styles['icon']} />
              <Typography variant="bodyBold">{t('RENTABLE_ARTICLES.ARTICLE_LINK')}</Typography>
            </div>
          </div>
        )}

        <Typography className={styles['title']} variant="boldText">
          {title}
        </Typography>

        <Typography className={styles['category']} variant="smallText" color="text.secondary">
          {category}
        </Typography>

        <Typography variant="body" className={styles['description']}>
          {description}
        </Typography>

        <div className={styles['price']}>
          <Typography variant="boldText">{price}</Typography>
        </div>
      </div>

      {accessories && (
        <Collapse
          className={styles['accessories']}
          classes={{ header: styles.collapseHeader }}
          headerTextVariant="boldText"
          header={t('RENTABLE_ARTICLES.ACCESSORIES')}
          iconLeft={false}
          initialExpanded={false}
          unmountOnExit
        >
          <AccessoriesMap accessories={accessories} />
        </Collapse>
      )}
      <Divider className={styles['divider']} />
      <Addresses className={styles['addresses']} addresses={addresses} article={article} />
    </Paper>
  )
}
