import { Typography } from '@mui/material'
import { useTranslateValues } from '@obeta/data/lib/hooks/useTranslateValues'
import { Facet, SelectedEtimValue } from '@obeta/models/lib/models/Search'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SelectedFeatureInfo.module.scss'
import { Size } from './Size'
import { calcTotalSize } from './utils/calcTotalSize'

type Variant = React.ComponentProps<typeof Typography>['variant']

const Info: React.FC<{
  size: number | undefined | null
  name: React.ReactText
  sizeTypographyVariant?: Variant
  typographyVariant?: Variant
}> = (props) => {
  const { sizeTypographyVariant, typographyVariant = 'bodyBold' } = props

  return (
    <div className={styles.root}>
      <Typography className={styles.text} variant={typographyVariant}>
        {props.name}
      </Typography>
      <Size typographyVariant={sizeTypographyVariant} size={props.size} />
    </div>
  )
}

export const SelectedFeatureInfo: React.FC<{
  selectedValue: SelectedEtimValue | null | undefined
  attribute: Facet
  sizeTypographyVariant?: Variant
  typographyVariant?: Variant
}> = (props) => {
  const { attribute, selectedValue, sizeTypographyVariant, typographyVariant } = props

  const { t } = useTranslation()
  const { tValue } = useTranslateValues()

  return selectedValue ? (
    <Info
      sizeTypographyVariant={sizeTypographyVariant}
      typographyVariant={typographyVariant}
      size={selectedValue.count}
      name={tValue(selectedValue.value)}
    ></Info>
  ) : (
    <Info
      sizeTypographyVariant={sizeTypographyVariant}
      typographyVariant={typographyVariant}
      size={calcTotalSize(attribute.values)}
      name={t<string>('COMMON.ALL')}
    ></Info>
  )
}
