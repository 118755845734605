import { useTranslation } from 'react-i18next'
import { DispatchFiltersAction } from '../reducer'
import { DesktopEtim } from './DesktopEtim'
import { renderSkeletons } from './EtimSkeletons'
import { useArticleFilterData } from '@obeta/data/lib/hooks/useArticleSearchFilter'
import React, { useCallback } from 'react'
import { ArticleClassesShort } from '../../ArticleClassesShort'
import { DropdownClassesBase } from '@obeta/components/lib/dropdown-classes/DropdownClasses'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { ReactComponent as ArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import { ReactComponent as ArrowUpIcon } from 'assets/icon/designsystem/arrow_up.svg'
import styles from './ClassesCards.module.scss'

interface IClassesCardsProps {
  dispatchEtimAction: DispatchFiltersAction
}

const AMOUNT_OF_CLASSES_DESKTOP = 8
const AMOUNT_OF_CLASSES_MOBILE_TABLET = 5
export const ClassesCards: React.FC<IClassesCardsProps> = React.memo(function ClassesCards({
  dispatchEtimAction,
}) {
  const { handleClick: showDropdownClasses, ...dropdownClasses } = usePopoverState()
  const { desktop } = useBreakpoints()

  const {
    articleSearchFilter: { etimClasses },
    loading,
  } = useArticleFilterData()

  const canShow = loading || etimClasses.length > 0

  const { t } = useTranslation()
  const selectClass = useCallback(
    (etimClass) => {
      dispatchEtimAction({
        type: 'selectEtimClass',
        payload: {
          etim: etimClass,
        },
      })
    },
    [dispatchEtimAction]
  )

  if (!canShow) {
    return null
  }

  const amountOfClassesWithoutDropdown = desktop
    ? AMOUNT_OF_CLASSES_DESKTOP
    : AMOUNT_OF_CLASSES_MOBILE_TABLET
  const amountOfElements = amountOfClassesWithoutDropdown + 1

  const shouldTruncate = etimClasses.length > amountOfElements
  const items = shouldTruncate ? etimClasses.slice(0, amountOfClassesWithoutDropdown) : etimClasses

  const arrow = dropdownClasses.open ? <ArrowUpIcon /> : <ArrowDownIcon />
  return (
    <ArticleClassesShort itemsLength={etimClasses.length} header={t('SEARCH.ARTICLE_CLASSES')}>
      {loading
        ? renderSkeletons()
        : items.map((etimClass) => {
            return (
              <DesktopEtim
                withArrowIcon={false}
                key={etimClass.value}
                name={etimClass.meta.name}
                size={etimClass.count}
                onClick={() => selectClass(etimClass)}
              />
            )
          })}
      {etimClasses.length > amountOfElements && (
        <>
          <DesktopEtim
            withArrowIcon={false}
            key="etim-additional-classes-dropdown"
            name={
              <div className={styles.dropdown}>
                {t('SEARCH.ARTICLE_CLASSES_MORE')} {arrow}
              </div>
            }
            onClick={showDropdownClasses}
          />
          <DropdownClassesBase
            classes={etimClasses.slice(amountOfClassesWithoutDropdown)}
            dropdown={dropdownClasses}
            selectClass={(option) =>
              selectClass({ value: option.id, meta: { name: option.name }, count: option.count })
            }
          />
        </>
      )}
    </ArticleClassesShort>
  )
})
