import { TEMPLATE_VARIANTS } from '@obeta/models/lib/models/PrintService/PrintInput'

type TemplateConfig = {
  [key in TEMPLATE_VARIANTS]: TemplateConfigItemWithInfo
}

export type TemplateConfigItem = {
  columns: number
  templateType: TEMPLATE_VARIANTS
  rows: number
  outlineType: 'solid' | 'dashed'
  startOffsetFromTop: boolean
}

export type TemplateConfigItemWithInfo = TemplateConfigItem & {
  info: {
    title: string
    info_cellSize: string
    info_cellCount: string
  }
}

export const TEMPLATES_CONFIG: Record<TEMPLATE_VARIANTS, TemplateConfigItem> = {
  [TEMPLATE_VARIANTS.Zweckform4780]: {
    columns: 4,
    templateType: TEMPLATE_VARIANTS.Zweckform4780,
    rows: 10,
    outlineType: 'solid',
    startOffsetFromTop: true,
  },
  [TEMPLATE_VARIANTS.Zweckform3490]: {
    templateType: TEMPLATE_VARIANTS.Zweckform3490,
    columns: 3,
    rows: 8,
    outlineType: 'solid',
    startOffsetFromTop: true,
  },
  [TEMPLATE_VARIANTS.Zweckform4781]: {
    templateType: TEMPLATE_VARIANTS.Zweckform4781,
    columns: 2,
    rows: 6,
    outlineType: 'solid',
    startOffsetFromTop: true,
  },
  [TEMPLATE_VARIANTS.ZweckformC32258]: {
    templateType: TEMPLATE_VARIANTS.ZweckformC32258,
    columns: 2,
    rows: 7,
    outlineType: 'dashed',
    startOffsetFromTop: false,
  },
}

export const getTemplatesConfigWithInfo = (t): TemplateConfig => {
  return {
    [TEMPLATE_VARIANTS.Zweckform4780]: {
      info: {
        title: t('COMPONENTS.SCANNERLIST.TEMPLATE_NUMBER_TITLE', {
          number: 4780,
        }),
        info_cellSize: '48,5 x 25,4 mm',
        info_cellCount: t('COMPONENTS.SCANNERLIST.ADHESIVE_LABELS', {
          size: 40,
        }),
      },
      ...TEMPLATES_CONFIG[TEMPLATE_VARIANTS.Zweckform4780],
    },
    [TEMPLATE_VARIANTS.Zweckform3490]: {
      info: {
        title: t('COMPONENTS.SCANNERLIST.TEMPLATE_NUMBER_TITLE', {
          number: 3490,
        }),
        info_cellSize: '70 x 36 mm',
        info_cellCount: t('COMPONENTS.SCANNERLIST.ADHESIVE_LABELS', {
          size: 24,
        }),
      },
      ...TEMPLATES_CONFIG[TEMPLATE_VARIANTS.Zweckform3490],
    },
    [TEMPLATE_VARIANTS.Zweckform4781]: {
      info: {
        title: t('COMPONENTS.SCANNERLIST.TEMPLATE_NUMBER_TITLE', {
          number: 4781,
        }),
        info_cellSize: '97 x 42,3 mm',
        info_cellCount: t('COMPONENTS.SCANNERLIST.ADHESIVE_LABELS', {
          size: 12,
        }),
      },
      ...TEMPLATES_CONFIG[TEMPLATE_VARIANTS.Zweckform4781],
    },
    [TEMPLATE_VARIANTS.ZweckformC32258]: {
      info: {
        title: t('COMPONENTS.SCANNERLIST.TEMPLATE_NUMBER_TITLE', {
          number: 'C32258',
        }),
        info_cellSize: '105 x 38 mm',
        info_cellCount: t('COMPONENTS.SCANNERLIST.PERFORATED_SHELF_SCREEN', {
          size: 14,
        }),
      },
      ...TEMPLATES_CONFIG[TEMPLATE_VARIANTS.ZweckformC32258],
    },
  }
}
