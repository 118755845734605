import { IColumn as IBaseColumn, TableRow } from '../table-row/TableRow'
import clsx from 'clsx'
import React from 'react'
import styles from './StripedTable.module.scss'

export interface IColumn<Key extends keyof T, T extends { [P in Key]?: T[P] }>
  extends IBaseColumn<Key, T> {
  header?: React.ReactChild
  padding?: 'none'
}

export interface IStripedTableProps<Key extends string, T extends { [key in Key]?: T[Key] }> {
  columns: IColumn<Key, T>[]
  data: T[]
  className?: string
  cellClassName?: string
  borderType?: 'table' | 'row'
  columnCount?: number
  columnGap?: number
}

export const StripedTable = <Key extends string, T extends { [key in Key]?: T[Key] }>(
  props: IStripedTableProps<Key, T>
) => {
  const {
    columns,
    data,
    className,
    columnCount = 1,
    columnGap,
    cellClassName,
    borderType = 'table',
  } = props

  return (
    <div
      className={clsx(className, {
        [styles.table]: borderType === 'row',
        [styles.tableWithBorder]: borderType === 'table',
      })}
      style={{ columnCount, columnGap: columnGap || '1rem' }}
      role="table"
    >
      <div role="rowgroup">
        <div role="row">
          {columns.map((col) => {
            if (!col.header) {
              return null
            }

            return (
              <div role="columnheader" key={col.accessor}>
                {col.header}
              </div>
            )
          })}
        </div>
      </div>
      <div role="rowgroup" className={styles.body}>
        {data.map((rowData, idx) => {
          return (
            <TableRow
              key={idx}
              columns={columns}
              data={rowData}
              classes={{
                row: clsx(styles.row, {
                  [styles.rowWithBorders]: borderType === 'row',
                  [styles.firstRow]: borderType === 'row' && idx === 0,
                  [styles.lastRow]: borderType === 'row' && idx === data.length - 1,
                }),
                cell: (col: IColumn<Key, T>) => {
                  return clsx(styles.cell, styles[`${col.padding}Padding`], cellClassName)
                },
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
