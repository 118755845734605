import { FC } from 'react'
import { StoreAddressBlock } from './StoreAddressBlock'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { Collapse } from '../collapse/Collapse'
import styles from './BranchDropdown.module.scss'

interface IBranchDropdownProps {
  storeInfo: StoreV2
  title: string
  withIcon?: boolean
}

export const BranchDropdown: FC<IBranchDropdownProps> = ({ storeInfo, title, withIcon = true }) => {
  return (
    <Collapse
      withIcon={withIcon}
      className={styles.collapseWrapper}
      iconLeft={false}
      initialExpanded={false}
      header={title}
      classes={{
        body: styles.collapseBody,
        header: styles.collapseHeader,
      }}
    >
      <StoreAddressBlock {...storeInfo} />
    </Collapse>
  )
}
