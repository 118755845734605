import { useEffect, useRef, useState, useCallback, FC } from 'react'
import { GoogleMap } from '@react-google-maps/api'
import clsx from 'clsx'
import { Geolocation } from '@capacitor/geolocation'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { containerStyle, getMapOptions } from './mapConfigs'
import { ReactComponent as UserLocation } from 'assets/icon/designsystem/my_location.svg'
import { ReactComponent as ZoomIn } from 'assets/icon/designsystem/zoonIn.svg'
import { ReactComponent as ZoomOut } from 'assets/icon/designsystem/zoomOut.svg'
import { ReactComponent as FullScreen } from 'assets/icon/designsystem/fullscreen.svg'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { MapInfoSection } from './components/StoresMapInfo/StoresMapInfo'
import { StoresMapMarkers } from './components/StoresMapMarkers'
import { MapSettingControl } from './components/MapSettingControl/MapSettingControl'
import { getClosestPlaces } from './utils/getNearestPlaces'
import {
  CurrentLocationMarker,
  ICurrentLocationOptions,
  Location,
} from './components/CurrentLocationMarker/CurrentLocationMarker'
import { PlacesMarker } from './components/PlacesMarker'
import { useGoogleMapsProvider } from '@obeta/data/lib/hooks/googleMaps/GoogleMapsContextProvider'
import { LocationCoordinates } from '@obeta/models/lib/models/GoogleMap'
import styles from './StoresMap.module.scss'

interface IMapComponentProps {
  currentStores?: StoreV2[]
  currentStore?: StoreV2 | null
  isOverlayMode?: boolean
  onCloseMap?: () => void
  currentLocationOptions?: ICurrentLocationOptions
  classes?: {
    mapContainer?: string
  }
  mapOptions?: google.maps.MapOptions
  isFullScreenHidden?: boolean
  chosenLocation?: LocationCoordinates | null
  nearestPlacesCount?: number
}

const transformCoordinatesFromStore = (store: StoreV2) => {
  return {
    lat: parseFloat(store.latitude),
    lng: parseFloat(store.longitude),
  }
}

export const StoresMap: FC<IMapComponentProps> = (props) => {
  const {
    currentLocationOptions,
    currentStores,
    currentStore,
    isOverlayMode = false,
    onCloseMap,
    mapOptions,
    chosenLocation,
    nearestPlacesCount = 3,
    isFullScreenHidden = false,
  } = props

  if (currentStores && currentStore) {
    throw new Error(
      "Both 'currentStores' and 'currentStore' cannot be provided simultaneously. 'currentStore' is only applicable when 'isOverlayMode' is set to 'true'."
    )
  }

  const { isLoaded } = useGoogleMapsProvider()
  const [lastSelectedActiveStore, setActiveStore] = useState<StoreV2 | null>(currentStore || null)
  /**
   * dont show the active store if it is not part of the search results anymore to not confuse the user with currently shown information
   **/
  const activeStore =
    currentStore || currentStores?.find((store) => store.id === lastSelectedActiveStore?.id) || null
  const isWeb = isPlatform('web')
  const isIos = isPlatform('ios')
  const mapRef = useRef<google.maps.Map | null>(null)
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null)
  const isFirstMapLoad = useRef(true)

  const onLoad = useCallback(
    (map) => {
      if (currentStores?.length && !isOverlayMode) {
        const bounds = new window.google.maps.LatLngBounds()

        currentStores?.forEach((store) => {
          bounds.extend(transformCoordinatesFromStore(store))
        })

        map.fitBounds(bounds)
        if (currentStores.length === 1) {
          map.setZoom(10)
        }
      }

      // We can have only 1 store in overlay mode, so no need finBounds here
      if (isOverlayMode && currentStore) {
        map.panTo(transformCoordinatesFromStore(currentStore))
        map.setZoom(10)
      }

      mapRef.current = map
      isFirstMapLoad.current = false
    },
    [isOverlayMode, currentStores, currentStore]
  )

  // Logic that helps us zoom to the zone that contains the place nearest to the chosen coordinates.
  useEffect(() => {
    if (!isFirstMapLoad.current && chosenLocation !== undefined) {
      if (chosenLocation) {
        // bound only nearest places ( This allows us to apply automatic zoom to them )
        const moveToChosenPlace = (position: LocationCoordinates) => {
          if (currentStores?.length) {
            const closestPlaces = getClosestPlaces(position, currentStores, nearestPlacesCount)

            const bounds = new window.google.maps.LatLngBounds()

            bounds.extend({ lat: chosenLocation.latitude, lng: chosenLocation.longitude })

            closestPlaces?.forEach((store) => {
              bounds.extend(transformCoordinatesFromStore(store))
            })

            if (mapRef.current) mapRef.current.fitBounds(bounds)
          } else {
            mapRef.current?.panTo({
              lat: position.latitude,
              lng: position.longitude,
            })
          }
        }

        moveToChosenPlace(chosenLocation)
      } else {
        // bound all places if chosenLocation is reseted and equal null
        const showAllPlaces = () => {
          const bounds = new window.google.maps.LatLngBounds()

          currentStores?.forEach((store) => {
            bounds.extend(transformCoordinatesFromStore(store))
          })

          mapRef.current?.fitBounds(bounds)
        }

        showAllPlaces()
      }
    }
  }, [currentStores, chosenLocation, nearestPlacesCount])

  useEffect(() => {
    if (mapRef.current && (currentStores?.length || currentStore)) {
      onLoad(mapRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStores, currentStore])

  const onUnmount = useCallback(function callback() {
    mapRef.current = null
  }, [])

  const moveToCoordinates = (position) => {
    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }

    setCurrentLocation(coordinates)

    mapRef.current?.panTo(coordinates)

    if (mapRef.current?.getZoom() !== 10) {
      mapRef.current?.setZoom(10)
    }
  }

  const updateUserLocation = async () => {
    if (!isWeb) {
      const requestedPermission = await Geolocation.requestPermissions()
      if (requestedPermission.location !== 'granted') {
        console.error('Unable to retrieve your location')
        return
      }
      const position = await Geolocation.getCurrentPosition()
      moveToCoordinates(position)
    } else {
      if (!navigator.geolocation) {
        console.error('Unable to retrieve your location')
        return
      }

      navigator.geolocation.getCurrentPosition(
        (position) => moveToCoordinates(position),
        () => console.error('Unable to retrieve your location'),
        {
          enableHighAccuracy: false,
          maximumAge: 30000,
        }
      )
    }
  }

  const zoomIn = () => {
    const zoom = mapRef.current?.getZoom() || 0
    mapRef.current?.setZoom(zoom + 1)
  }

  const zoomOut = () => {
    const zoom = mapRef.current?.getZoom() || 0
    mapRef.current?.setZoom(zoom - 1)
  }

  const openFullscreen = () => {
    const mapContainer = document.getElementById('map-container') as HTMLElement

    if (!document.fullscreenElement) {
      mapContainer.requestFullscreen?.()
    } else {
      document.exitFullscreen?.()
    }
  }

  const onSetActiveStore = (store) => {
    if (store.id === activeStore?.id) {
      setActiveStore(null)
      return
    }

    setActiveStore(store)

    mapRef.current?.panTo({
      lat: Number(store.latitude),
      lng: Number(store.longitude),
    })
  }

  const getMapSettingControlFullScreen = () => {
    if (!isFullScreenHidden) {
      if (isOverlayMode || (!isOverlayMode && !isIos)) {
        return (
          <MapSettingControl
            onClick={!isOverlayMode ? openFullscreen : onCloseMap}
            Icon={!isOverlayMode ? FullScreen : CloseIcon}
            className={styles.fullscreenSetting}
          />
        )
      }
    }

    return null
  }

  return (
    <div className={styles.mapComponentWrapper}>
      <div className={clsx(styles.mapContainer, props.classes?.mapContainer)} id={'map-container'}>
        {isLoaded && (
          <GoogleMap
            options={getMapOptions(mapOptions)}
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <StoresMapMarkers
              onSetActiveStore={onSetActiveStore}
              currentStores={isOverlayMode && currentStore ? [currentStore] : currentStores}
              activeStore={activeStore}
            />
            <CurrentLocationMarker
              currentLocation={currentLocation}
              currentLocationOptions={currentLocationOptions}
            />
            <PlacesMarker currentPlace={chosenLocation} />
            <MapSettingControl
              onClick={updateUserLocation}
              Icon={UserLocation}
              className={styles.userLocationSetting}
            />
            <MapSettingControl onClick={zoomIn} Icon={ZoomIn} className={styles.zoomInSetting} />
            <MapSettingControl onClick={zoomOut} Icon={ZoomOut} className={styles.zoomOutSetting} />
            {getMapSettingControlFullScreen()}
          </GoogleMap>
        )}
      </div>
      <MapInfoSection store={activeStore} />
    </div>
  )
}
