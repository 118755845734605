import clsx from 'clsx'
import React from 'react'
import styles from './ContentHold.module.scss'

export const ContentHold: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, ...rest } = props
  return (
    <div className={clsx(styles.hold, className)} {...rest}>
      {children}
    </div>
  )
}
