import { Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { Directory, Filesystem } from '@capacitor/filesystem'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { isPlatform } from './isPlatform'
import fileDownload from 'js-file-download'
import { ShopDomains } from './domains'
import { EventType, getEventSubscription, NotificationType } from './pubSub'
import { PRINT_SERVICE_URL } from './config'
import { FileOpener } from '@capacitor-community/file-opener'
import { handleError } from './datadog.errors'
import { datadogRum } from '@datadog/browser-rum'
import {
  OpenPositionFilter,
  OpenPositionSearch,
  OpenPositionSorting,
  PrintCartViewTypes,
  ScannerListType,
} from '@obeta/models/lib/models/PrintService/PrintInput'
import { isMobile } from '../lib/isMobile'

const baseRoute = `${PRINT_SERVICE_URL}/api/print`

export interface INotificationData {
  id: string
  heading: string
  body: string
}

interface IPdfConf {
  route: string
  accessToken?: Tokens['accessToken']
  pdfName: string
  domain?: ShopDomains
  notificationData?: INotificationData
  base?: string
  instantDownload?: boolean
}

export const requestPdfUrl = async ({ route, accessToken, base = baseRoute }) => {
  const printRoute = `${base}${route}`

  const config = {
    responseType: 'blob',
    headers: {
      ...(accessToken ? { Authorization: 'bearer ' + accessToken } : {}),
    },
  } as const

  axiosRetry(axios, { retries: 3 })

  try {
    const response = await axios.get(printRoute, config)

    if (response?.data) {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      return URL.createObjectURL(pdfBlob)
    } else {
      throw new Error('No data returned from print service')
    }
  } catch (error) {
    if (error instanceof Error) {
      handleError(error)
    }
    throw new Error('Error while fetching PDF URL')
  }
}

export const printPdf = async ({
  route,
  accessToken,
  pdfName,
  notificationData,
  base = baseRoute,
  instantDownload = false,
}: IPdfConf) => {
  const printRoute = `${base}${route}`

  if (notificationData) {
    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.Success,
      id: notificationData.id,
      options: {
        heading: notificationData.heading,
        body: notificationData.body,
      },
    })
  }

  const config = {
    responseType: 'blob',
    headers: {
      ...(accessToken ? { Authorization: 'bearer ' + accessToken } : {}),
    },
  } as const

  if (isPlatform('web')) {
    if (instantDownload || isMobile()) {
      axiosRetry(axios, { retries: 3 })
      await axios
        .get(printRoute, config)
        .then((res) => {
          if (res?.data) {
            fileDownload(new File([res.data], '', { type: 'application/pdf' }), pdfName)
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            handleError(error)
          }
          throw new Error('No data returned from print service')
        })
    } else {
      const pdfOverviewUrl = `/pdf-overview?route=${route}`
      window.open(pdfOverviewUrl, '_blank')
    }
  } else {
    try {
      const downloadFileResult = await Filesystem.downloadFile({
        url: printRoute,
        path: pdfName,
        directory: Directory.Cache,
        headers: config.headers,
      })

      await FileOpener.open({
        filePath: downloadFileResult.path || '',
        contentType: 'application/pdf',
      })
    } catch (e) {
      datadogRum.addError(e, {
        type: 'request',
        'request.cause': 'printservice',
        'request.url': printRoute || '',
        url: printRoute,
      })
    }
  }
}

export const printCart = (
  p: {
    cartId: string
    accessToken: Tokens['accessToken'] | undefined
    cartName?: string
    notificationData?: INotificationData
    view?: PrintCartViewTypes
  } & Pick<IPdfConf, 'domain'>
) => {
  const { cartId, accessToken, cartName, domain, notificationData, view = 'detailliert' } = p

  return printPdf({
    route: `/cart/${cartId}?view=${view}`,
    accessToken,
    pdfName: 'Warenkorb-' + cartName + '.pdf',
    domain,
    notificationData,
  })
}

export const printArticleDataSheet = (
  p: {
    sapId: string
    accessToken: Tokens['accessToken'] | undefined
    notificationData?: INotificationData
    requestParamsUrl?: string
  } & Pick<IPdfConf, 'domain'>
) => {
  const { sapId, accessToken, domain, notificationData, requestParamsUrl = '' } = p

  return printPdf({
    route: `/article-datasheet/${sapId}${requestParamsUrl}`,
    accessToken,
    pdfName: `datenblatt-${sapId}.pdf`,
    domain,
    notificationData,
  })
}

export const printScannerlist = (
  p: {
    accessToken: Tokens['accessToken'] | undefined
    notificationData?: INotificationData
    tamplateInfo: {
      templateType: string
      offset: number
    }
    parentId?: string
    childIds?: string[] | number[]
    childIdsExcluded?: string[] | number[]
    type: ScannerListType
  } & Pick<IPdfConf, 'domain'>
) => {
  const {
    accessToken,
    domain,
    notificationData,
    childIds,
    childIdsExcluded,
    type,
    tamplateInfo,
    parentId,
  } = p

  const { templateType, offset } = tamplateInfo

  const route = `/scannerlist/?templateType=${templateType}&offset=${offset}&type=${type}&childIdsExcluded=${childIdsExcluded}&parentId=${parentId}&childIds=${childIds}`

  return printPdf({
    route,
    accessToken,
    pdfName: `scannerlist.pdf`,
    domain,
    notificationData,
  })
}

export const printOrder = (p: {
  orderId: string
  withPrice: boolean
  accessToken: Tokens['accessToken'] | undefined
  orderName?: string
  notificationData?: INotificationData
}) => {
  const { orderId, withPrice, accessToken, orderName, notificationData } = p
  const route: string = withPrice ? '/order/' + orderId + '?withprice=1' : '/order/' + orderId

  return printPdf({
    route: route,
    accessToken,
    pdfName: orderName + '.pdf',
    notificationData,
  })
}

export const printOpenPositionList = (
  p: {
    filter: OpenPositionFilter
    sorting: OpenPositionSorting
    search: OpenPositionSearch
    accessToken: Tokens['accessToken'] | undefined
  } & Pick<IPdfConf, 'domain'>
) => {
  const { filter, sorting, search, accessToken, domain } = p
  const { orderBy, order } = sorting
  const { searchAttributeId, searchOperatorId, searchTerm } = search

  const openPositionRoute = `/openPosition/${filter}`
  const orderParams = `?orderBy=${orderBy}&order=${order}`
  const searchParams = searchTerm
    ? `&searchAttributeId=${searchAttributeId}&searchOperatorId=${searchOperatorId}&searchTerm=${searchTerm}`
    : ''

  const route = openPositionRoute + orderParams + searchParams

  return printPdf({
    route,
    accessToken,
    pdfName: 'openpositions.pdf',
    domain,
  })
}
