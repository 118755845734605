import {
  DropdownTemplates as BaseDropdownTemplates,
  IDropdownTemplatesProps as IBaseDropdownTemplatesProps,
} from '@obeta/components/lib/dropdown-templates/DropdownTemplates'
import { useProduct } from './ProductProvider'

interface IDropdownTemplatesProps
  extends Omit<IBaseDropdownTemplatesProps, 'productsToAdd' | 'selectedArticleCount'> {
  productAmount: number
}

export const DropdownTemplates: React.FC<IDropdownTemplatesProps> = (props) => {
  const { productAmount, ...restProps } = props
  const product = useProduct()

  return (
    <BaseDropdownTemplates
      productsToAdd={() => {
        if (!product) {
          return []
        }
        return [{ productId: product.sapId, amount: productAmount }]
      }}
      {...restProps}
    />
  )
}
