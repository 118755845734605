import { ButtonBase, Typography } from '@mui/material'
import { ReactComponent as ContentCopyIcon } from '@obeta/assets/icon/content_copy.svg'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Property.module.scss'
import { Clipboard } from '@capacitor/clipboard'

interface IPropertyProps {
  name: string
  withCopy?: boolean
}

const CopyButton: React.FC<{ name: string; valueRef: RefObject<HTMLSpanElement> }> = (props) => {
  const { t } = useTranslation()
  const copy = async () => {
    const textEl = props.valueRef.current
    if (!textEl) {
      return
    }

    await Clipboard.write({ string: textEl.textContent || '' })

    getEventSubscription().next({
      type: EventType.Toast,
      notificationType: NotificationType.Success,
      id: 'copy-property',
      options: {
        heading: `${props.name} ${textEl.textContent}`,
        body: t<string>('MAIN.WAS_COPIED'),
      },
    })
  }

  return (
    <ButtonBase onClick={copy}>
      <ContentCopyIcon />
    </ButtonBase>
  )
}

export const Property: React.FC<IPropertyProps> = (props) => {
  const { name, withCopy = true } = props
  const valueRef = useRef<HTMLSpanElement>(null)

  return (
    <div className={styles.property}>
      <Typography variant="body">{name}:</Typography>
      <Typography ref={valueRef} variant="bodyBold" className={styles['propertyNumber']}>
        {props.children}
      </Typography>
      {withCopy && <CopyButton valueRef={valueRef} name={name} />}
    </div>
  )
}
