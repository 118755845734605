import {
  CartTemplateForDetailsPage,
  MaybeCompleteCartTemplateDetailsItem,
} from '@obeta/models/lib/schema-models/cart-template-details'
import { CartTemplateItemInput } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { MultiSelectionString } from '@obeta/models/lib/models'

export interface CartTemplateDetailsPageState {
  searchTerm: string
  page: number
  shouldFetchItems: boolean
  shouldFetchProductData: boolean
  isAllSelected: boolean
  shouldResetItems: boolean
  cartTemplate?: CartTemplateForDetailsPage
  items: MaybeCompleteCartTemplateDetailsItem[]
  selectedItemIds: string[]
  selectedItemsToAdd: CartTemplateItemInput[]
  selectedCartTemplateItems: MultiSelectionString
}

type CartTemplateDetailsPageAction =
  | { type: 'CHANGE_INITIAL_LOADING'; payload: boolean }
  | { type: 'CHANGE_SEARCH_TERM'; payload: string }
  | { type: 'CHANGE_PAGE'; payload: number }
  | { type: 'RESET_PAGE' }
  | { type: 'FETCHED_ITEMS' }
  | { type: 'FETCHED_PRODUCTS' }
  | { type: 'SELECT_ALL'; payload: boolean }
  | { type: 'SELECT_ITEM'; payload: string } // payload = cartTemplateItemId
  | { type: 'RESET_SELECTED_ITEMS' }
  | { type: 'SELECT_ALL_ITEMS' }
  | { type: 'SELECT_SINGLE'; payload: MaybeCompleteCartTemplateDetailsItem }
  | { type: 'UNSELECT_SINGLE'; payload: MaybeCompleteCartTemplateDetailsItem }
  | { type: 'UPDATE_CART_TEMPLATE'; payload: CartTemplateForDetailsPage }
  | { type: 'UPDATE_CART_TEMPLATE_ITEMS'; payload: MaybeCompleteCartTemplateDetailsItem[] }

export function cartTemplatesListReducer(
  state: CartTemplateDetailsPageState,
  action: CartTemplateDetailsPageAction
): CartTemplateDetailsPageState {
  switch (action.type) {
    // Determine if selectedCartTemplateItems in affected by any of the below cases!
    case 'CHANGE_INITIAL_LOADING':
      return {
        ...state,
        shouldFetchItems: true,
      }
    case 'CHANGE_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
        selectedCartTemplateItems: {
          ...state.selectedCartTemplateItems,
          search: {
            ...state.selectedCartTemplateItems.search,
            searchTerm: action.payload,
          },
        },
        page: 1,
        shouldResetItems: true,
        shouldFetchItems: true,
      }
    case 'CHANGE_PAGE':
      return {
        ...state,
        page: action.payload,
        shouldFetchItems: true,
      }
    case 'RESET_PAGE':
      return {
        ...state,
        page: 1,
        shouldFetchItems: true,
      }
    case 'FETCHED_ITEMS':
      return {
        ...state,
        shouldFetchItems: false,
        shouldFetchProductData: true,
      }
    case 'FETCHED_PRODUCTS':
      return {
        ...state,
        shouldFetchProductData: false,
      }
    case 'UPDATE_CART_TEMPLATE_ITEMS':
      return {
        ...state,
        shouldResetItems: false,
        items: action.payload,
      }

    case 'UPDATE_CART_TEMPLATE': {
      return {
        ...state,
        cartTemplate: action.payload,
      }
    }

    case 'SELECT_ITEM': {
      const itemCountToCompareAgainst =
        state.cartTemplate?.cartTemplateItems?.resultsCount ?? // item count once search (incl. filter) is applied
        state.cartTemplate?.itemCount // actual count of ALL items within cart template

      // 1) Nur einzelne oder keine Artikel ausgewählt
      if (!state.selectedCartTemplateItems.selectAll)
        if (state.selectedCartTemplateItems.include.includes(action.payload)) {
          // 1a auf INCLUDE list --> entfernen
          return {
            ...state,
            selectedCartTemplateItems: {
              ...state.selectedCartTemplateItems,
              include: [
                ...state.selectedCartTemplateItems.include.filter((el) => el !== action.payload),
              ],
            },
          }
        } else {
          // 1b einziges element nicht auf include list --> toggle und ALLE auswählen
          if (
            [...state.selectedCartTemplateItems.include, action.payload].length ===
            itemCountToCompareAgainst
          ) {
            return {
              ...state,
              selectedCartTemplateItems: {
                selectAll: true,
                include: [],
                exclude: [],
                search: state.selectedCartTemplateItems.search,
              },
            }
          } else {
            // 1c nicht auf INCLUDE list --> hinzufügen
            return {
              ...state,
              selectedCartTemplateItems: {
                ...state.selectedCartTemplateItems,
                include: [...state.selectedCartTemplateItems.include, action.payload],
              },
            }
          }
        }
      // 2) Alle ausgewählt --> Add to EXCLUDE list
      else if (
        state.selectedCartTemplateItems.selectAll &&
        state.selectedCartTemplateItems.exclude.length === 0
      ) {
        return {
          ...state,
          selectedCartTemplateItems: {
            ...state.selectedCartTemplateItems,
            exclude: [...state.selectedCartTemplateItems.exclude, action.payload],
          },
        }
      }
      // 3) Alle ausgewählt und exclude Liste existiert
      // 3a) auf exclude list --> entnehmen
      else if (
        state.selectedCartTemplateItems.selectAll &&
        state.selectedCartTemplateItems.exclude.includes(action.payload)
      ) {
        return {
          ...state,
          selectedCartTemplateItems: {
            ...state.selectedCartTemplateItems,
            exclude: [
              ...state.selectedCartTemplateItems.exclude.filter((el) => el !== action.payload),
            ],
          },
        }
      }
      // 3b) nicht in exclude liste --> in exclude liste übernehmen
      else if (
        state.selectedCartTemplateItems.selectAll &&
        !state.selectedCartTemplateItems.exclude.includes(action.payload)
      ) {
        if (
          state.selectedCartTemplateItems.selectAll &&
          [...state.selectedCartTemplateItems.exclude, action.payload].length ===
            itemCountToCompareAgainst // search term and filter applied!
        ) {
          return {
            ...state,
            selectedCartTemplateItems: {
              selectAll: false,
              include: [],
              exclude: [],
              search: {
                searchTerm: '',
                filter: '',
                orderBy: '',
                orderDir: '',
              },
            },
          }
        } else {
          return {
            ...state,
            selectedCartTemplateItems: {
              ...state.selectedCartTemplateItems,
              exclude: [...state.selectedCartTemplateItems.exclude, action.payload],
            },
          }
        }
      }
      return {
        ...state,
      }
    }

    case 'RESET_SELECTED_ITEMS': {
      return {
        ...state,
        selectedCartTemplateItems: {
          selectAll: false,
          include: [],
          exclude: [],
          search: {
            searchTerm: state.searchTerm,
            filter: '',
            orderBy: '',
            orderDir: '',
          },
        },
      }
    }

    case 'SELECT_ALL_ITEMS': {
      return {
        ...state,
        selectedCartTemplateItems: {
          selectAll: true,
          include: [],
          exclude: [],
          search: {
            searchTerm: state.searchTerm,
            filter: '',
            orderBy: '',
            orderDir: '',
          },
        },
      }
    }

    case 'SELECT_ALL': {
      const selectedItemIds = action.payload ? state.items.map((item) => item.id) : []
      const selectedItemsToAdd = action.payload
        ? state.items.map((item) => {
            return { productId: item.productId, amount: item.amount }
          })
        : []
      return {
        ...state,
        isAllSelected: action.payload,
        selectedItemIds: selectedItemIds,
        selectedItemsToAdd: selectedItemsToAdd,
      }
    }
    case 'SELECT_SINGLE': {
      return {
        ...state,
        selectedItemIds: [...state.selectedItemIds, action.payload.id],
        selectedItemsToAdd: [
          ...state.selectedItemsToAdd,
          { productId: action.payload.productId, amount: action.payload.amount },
        ],
      }
    }
    case 'UNSELECT_SINGLE': {
      return {
        ...state,
        isAllSelected: false,
        selectedItemIds: [
          ...state.selectedItemIds.filter((selectedItemId) => selectedItemId !== action.payload.id),
        ],
        selectedItemsToAdd: [
          ...state.selectedItemsToAdd.filter(
            (selectedItemToAdd) => selectedItemToAdd.productId !== action.payload.productId
          ),
        ],
      }
    }
  }
}

export const initialCartTemplateDetailsState: CartTemplateDetailsPageState = {
  selectedCartTemplateItems: {
    selectAll: false,
    include: [],
    exclude: [],
    search: {
      filter: '',
      orderBy: '',
      orderDir: '',
      searchTerm: '',
    },
  },
  shouldFetchItems: true,
  shouldFetchProductData: false,
  page: 1,
  searchTerm: '',
  items: [],
  isAllSelected: false,
  selectedItemIds: [],
  selectedItemsToAdd: [],
  shouldResetItems: false,
}
