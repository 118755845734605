import { useCallback, useEffect, useState } from 'react'
import { OpenPosition } from '@obeta/models/lib/schema-models/open-positions'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  OpenPositionSorting,
  OpenPositionSortingOrderBy,
  useMyAccountingContext,
} from '../../stores/useMyAccountingContext'
import { useOpenPositionsFilters } from './useOpenPositionsFilters'

dayjs.extend(customParseFormat)

export type OpenPositionSearchOperator = 'eq' | 'lt' | 'gt'
export type OpenPositionSearchAttribute = 'receipt' | 'invoiceDueDate' | 'invoiceFromDate'

function compareBySearchOperator<T>(left: T, right: T, searchOperator: OpenPositionSearchOperator) {
  switch (searchOperator) {
    case 'eq':
      return left === right
    case 'gt':
      return left > right
    case 'lt':
      return left < right
  }
}

export const matchesNumberValue = (
  left: string,
  right: string,
  searchOperator: OpenPositionSearchOperator
) => {
  const leftAsNumber = parseInt(left)
  const rightAsNumber = parseInt(right)
  return compareBySearchOperator(leftAsNumber, rightAsNumber, searchOperator)
}

export const matchesDateValue = (
  dateAsString: string,
  searchTerm: string,
  searchOperator: OpenPositionSearchOperator
) => {
  const date = dayjs(dateAsString).toDate()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  const searchTermAsDate = dayjs(searchTerm, 'DD.MM.YYYY').toDate()
  const left = searchOperator === 'eq' ? date.getTime() : date
  const right = searchOperator === 'eq' ? searchTermAsDate.getTime() : searchTermAsDate
  return compareBySearchOperator(left, right, searchOperator)
}

function sortItemsByFieldName(
  items: OpenPosition[],
  sorting: OpenPositionSorting,
  fieldName: OpenPositionSortingOrderBy,
  fieldType?: 'date' | 'intAsString'
) {
  return items.sort((a, b) => {
    let left = 0
    let right = 0

    if (fieldType === 'date') {
      left = new Date(a[fieldName]).getTime()
      right = new Date(b[fieldName]).getTime()
    } else if (fieldType === 'intAsString') {
      left = parseInt(a[fieldName] as string)
      right = parseInt(b[fieldName] as string)
    } else {
      left = a[fieldName] as number
      right = b[fieldName] as number
    }

    //sorting based on receipt always asc:
    return sorting.order === 'asc'
      ? left - right || parseInt(a.receipt as string) - parseInt(b.receipt as string)
      : right - left || parseInt(a.receipt as string) - parseInt(b.receipt as string)
  })
}

export function sortItems(items: OpenPosition[], sorting: OpenPositionSorting) {
  switch (sorting.orderBy) {
    case 'invoiceDueDate': {
      return sortItemsByFieldName(items, sorting, 'invoiceDueDate', 'date')
    }
    case 'invoiceFromDate': {
      return sortItemsByFieldName(items, sorting, 'invoiceFromDate', 'date')
    }
    case 'discountDate': {
      return sortItemsByFieldName(items, sorting, 'discountDate', 'date')
    }
    case 'receipt': {
      return sortItemsByFieldName(items, sorting, 'receipt', 'intAsString')
    }
    case 'dunningLevel': {
      return sortItemsByFieldName(items, sorting, 'dunningLevel')
    }
    case 'invoiceAmount': {
      return sortItemsByFieldName(items, sorting, 'invoiceAmount')
    }
    case 'openAmount': {
      return sortItemsByFieldName(items, sorting, 'openAmount')
    }
    default: {
      return sortItemsByFieldName(items, sorting, 'invoiceDueDate', 'date')
    }
  }
}

export const useSortedAndFilteredOpenPositions = ({
  openPositions,
}: {
  openPositions: OpenPosition[]
}) => {
  const {
    filter,
    searchOperator,
    searchAttribute,
    setMasterCheckboxChecked,
    setSelectedItems,
    sorting,
  } = useMyAccountingContext()
  const [searchResults, setSearchResults] = useState<OpenPosition[]>(openPositions)
  const { filterDiscountableItems, filterCreditItems, filterOverdueItems } =
    useOpenPositionsFilters()

  useEffect(() => {
    setSearchResults(openPositions)
  }, [openPositions])

  const onFilterItemsBySearchTerm = useCallback(
    (searchTerm: string) => {
      const items = openPositions.filter((item) => {
        if (searchTerm.length === 0) return true
        switch (searchAttribute.id) {
          case 'receipt':
            return matchesNumberValue(item.receipt, searchTerm, searchOperator.id)
          case 'invoiceDueDate':
            return matchesDateValue(item.invoiceDueDate, searchTerm, searchOperator.id)
          case 'invoiceFromDate':
            return matchesDateValue(item.invoiceFromDate, searchTerm, searchOperator.id)
        }
        return true
      })
      setSearchResults(items)
      setMasterCheckboxChecked(false)
      setSelectedItems([])
    },
    [
      openPositions,
      setMasterCheckboxChecked,
      setSelectedItems,
      searchAttribute.id,
      searchOperator.id,
    ]
  )

  let items: OpenPosition[] = []
  switch (filter) {
    case 'all':
      items = searchResults
      break
    case 'overdue':
      items = filterOverdueItems(searchResults)
      break
    case 'credits':
      items = filterCreditItems(searchResults)
      break
    case 'discountable':
      items = filterDiscountableItems(searchResults)
      break
  }

  const mutableItems = [...items]
  const sortedItems = sortItems(mutableItems, sorting)
  return {
    items: sortedItems,
    searchResults,
    onFilterItemsBySearchTerm,
  }
}
