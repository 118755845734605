import { Skeleton, Box, Avatar } from '@mui/material'
import styles from './SidebarSkeletons.module.scss'

export const CommonSkeleton: React.FC<{ count: number; height: number; width: number }> = ({
  count,
  height,
  width,
}) => {
  return (
    <>
      {new Array(count).fill(null).map((_, idx) => {
        return (
          <Box className={styles.BoxContainer} key={idx}>
            <Box>
              <Skeleton className={styles.Avatar} height={height} width={width} key={idx}>
                <Avatar />
              </Skeleton>
            </Box>
            <Box className={styles.SkeletonBox}>
              <Skeleton className={styles.SkeletonInner}></Skeleton>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

export const CategoriesListSkeletons = () => {
  return <CommonSkeleton count={3} height={65} width={40} />
}

export const SuppliersSkeletons = () => {
  return <CommonSkeleton count={3} height={32} width={20} />
}
