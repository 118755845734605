import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import React, { useCallback, useContext, useState } from 'react'
import { CellMeasurerCache } from 'react-virtualized'
import styles from './LazyList.module.scss'
import { ITemplateMobileListProps } from './types'
import { CartTemplateDetailsContext } from '@obeta/data/lib/hooks/cart-templates/details/CartTemplateDetailsContext'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { TemplateItemWrapper } from './TemplateItemWrapper'
import { InfiniteWindowScroller, TInfiniteLoaderProps } from '../../window-scroller'
import { HorizontalDummy } from '../../product-card/dummies/HorizontalDummy'

const MAX_ITEMS = 1000
const ITEMS_PER_PAGE = 16

export const LazyList: React.FC<Omit<ITemplateMobileListProps, 'selectedArticleIds'>> = (
  props: Omit<ITemplateMobileListProps, 'selectedArticleIds'>
) => {
  const {
    changeProductAmount,
    deleteSingleCartTemplateItem,
    mobile,
    tabletAll,
    updateIndividualSelectedArticles,
    carts,
    selectedCart,
  } = props
  const { selectedStore, isLoadingProducts, paginatedItems, changePage, cartTemplate, state } =
    useContext(CartTemplateDetailsContext)
  const { isLoggedIn, user: userV2 } = useUserDataV2()
  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
      defaultHeight: 300,
      minHeight: 100, // expected minimum row height
    })
  )

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => Boolean(paginatedItems[index]),
    [paginatedItems]
  )
  const loadMoreRows = async (p: { startIndex: number; stopIndex: number }) => {
    const page = Math.floor(p.stopIndex / ITEMS_PER_PAGE) + 1
    const amountOfAvailableItems =
      cartTemplate.cartTemplateItems?.resultsCount ?? cartTemplate.itemCount
    if (paginatedItems.length < amountOfAvailableItems) {
      changePage(page)
    }
  }

  // Renders single cart template item by row index
  const { rowRenderer } = useMeasuredRowRenderer({
    cache,
    render: ({ index }) => {
      const item = paginatedItems[index]
      if (!item) {
        return <HorizontalDummy />
      }
      return item ? (
        <TemplateItemWrapper
          key={`template-item-${item.product.sapId}`}
          className={styles.templateItem}
          cartTemplateItem={item}
          withCheckbox={true}
          changeProductAmount={changeProductAmount}
          onDeleteClicked={deleteSingleCartTemplateItem}
          selectedStore={selectedStore}
          selected={
            (state.selectedCartTemplateItems.selectAll &&
              !state.selectedCartTemplateItems.exclude.includes(item.id)) ||
            state.selectedCartTemplateItems.include.includes(item.id)
          }
          loadingPrices={isLoadingProducts}
          loadingStocks={isLoadingProducts}
          user={userV2}
          isLoggedIn={isLoggedIn}
          mobile={mobile}
          tablet={tabletAll}
          productId={item?.product?.sapId ?? ''}
          selectedCart={selectedCart}
          carts={carts}
          onChange={() => {
            updateIndividualSelectedArticles(item)
          }}
          url={
            item.product.images && item.product.images.length > 0 ? item.product.images[0].url : ''
          }
        />
      ) : (
        <HorizontalDummy />
      )
    },
    wrapperClassName: styles.row,
  })

  return (
    <InfiniteWindowScroller
      rowTotalCount={MAX_ITEMS} // Total row count for cart template items (including not loaded yet articles)
      rowCount={paginatedItems.length} // Row count for loaded cart template items
      infiniteLoader={{
        isRowLoaded,
        loadMoreRows,
        minimumBatchSize: ITEMS_PER_PAGE, // Amount of cart template items (articles) to load at a time
        threshold: mobile || tabletAll ? 5 : 10, // Load more items when within the last 5/10 rows
      }}
      list={{
        rowRenderer,
        rowHeight: cache.rowHeight,
        deferredMeasurementCache: cache,
      }}
    />
  )
}
