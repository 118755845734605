import { FC, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

import { CollapseComponent } from '@obeta/components/lib/collapseComponent/CollapseComponent'
import { CommonSkeleton } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import { ShowMoreList } from '@obeta/components/lib/show-more-list/ShowMoreList'
import { usePageContext } from '../context/context'
import { IFiltersCombined, TSetFilter } from '../types'
import { ILocation } from '@obeta/models/lib/models/RentArticle/types'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { RadioItem } from '@obeta/components/lib/checbox-radio-item/CheckboxRadioItem'

// UI
import { SearchField } from '@obeta/components/lib/search-field/SearchField'

const rowHeightRem = parseFloat(styles.itemHeight)

interface ILocationsMapProps {
  locations: ILocation[] | null
  setFilter: TSetFilter
  filters: IFiltersCombined
}

interface ILocationFilterProps extends ILocationsMapProps {
  isLoading: boolean
}

export const LocationFilter: FC<ILocationFilterProps> = ({
  isLoading,
  locations,
  setFilter,
  filters,
}) => {
  const { mobile, tablet } = usePageContext()
  const { t } = useTranslation()
  const [locationsSearch, setLocationsSearch] = useState('')

  const locationsToShow =
    locationsSearch && locations
      ? locations.filter((loc) => loc.value.toLowerCase().includes(locationsSearch.toLowerCase()))
      : locations

  return (
    <CollapseComponent header={t('RENTABLE_ARTICLES.LOCATION')} mobile={mobile || tablet}>
      <div className={styles.root}>
        <SearchField
          className={styles['search']}
          value={locationsSearch}
          onChange={setLocationsSearch}
          onReset={() => setLocationsSearch('')}
        />
        {isLoading ? (
          <CommonSkeleton count={3} height={32} width={20} />
        ) : (
          <LocationsMap locations={locationsToShow} filters={filters} setFilter={setFilter} />
        )}
      </div>
    </CollapseComponent>
  )
}

const LocationsMap = memo<ILocationsMapProps>(({ locations, filters, setFilter }) => {
  const rowHeight = useRemToPx(rowHeightRem)

  if (!locations) {
    return null
  }

  return (
    <ShowMoreList
      classes={{ listWrap: styles['list'] }}
      itemHeight={rowHeight}
      gapHeight={8}
      maxSize={7}
      listSize={locations.length}
    >
      {locations.map((el) => (
        <RadioItem
          onChange={() => setFilter(el)}
          key={el.value}
          id={`location_${el.value}`}
          label={el.value}
          value={el.value}
          checked={filters.location.value === el.value}
        />
      ))}
    </ShowMoreList>
  )
})
