import { ComponentType, FC, ReactNode } from 'react'

interface WithContextProviderProps {
  children?: ReactNode
}

/**
 * Generic Higher-Order Component (HOC) that wraps a given component with a specified provider.
 * @param Component The component to be wrapped by the provider
 * @param Provider The provider component that wraps the wrapped component
 * @returns A new component that renders the wrapped component within the specified provider.
 *
 * This HOC can be used to wrap any component with a context provider or any other type of provider,
 * ensuring that the wrapped component has access to the context or functionality provided by the provider.
 *
 * Example usage:
 * ```
 * const MyComponentWithProvider = withProvider(MyComponent, MyContextProvider);
 * ```
 */
export const withProvider = <P,>(
  Component: ComponentType<P>,
  Provider: ComponentType<WithContextProviderProps>
) => {
  // Define a new component that wraps the WrappedComponent with the Provider
  const ComponentWithProvider: FC<P> = (props) => {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    )
  }

  // Set the display name for debugging purposes
  const wrappedComponentName = Component.displayName || Component.name || 'Component'
  ComponentWithProvider.displayName = `withProvider(${wrappedComponentName})`

  return ComponentWithProvider
}
