import { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './NoResults.module.scss'
import { useTranslation } from 'react-i18next'

type SearchTermProps = {
  searchTerm: string
  title?: never
  body?: never
}
type BodyAndTitleProps = {
  title: string
  body?: string
  searchTerm?: never
}
type ContentProps = SearchTermProps | BodyAndTitleProps
type Props = ContentProps & { className?: string }

export const NoResults: FC<Props> = (props) => {
  const { className } = props
  const { t } = useTranslation()

  let noResultsTitle, noResultsBody

  if ('searchTerm' in props) {
    const { searchTerm } = props as SearchTermProps
    noResultsTitle = t('SEARCH.NO_RESULTS.TITLE', { searchTerm })
    noResultsBody = t('SEARCH.NO_RESULTS.BODY')
  } else {
    const { body, title } = props as BodyAndTitleProps
    noResultsBody = body
    noResultsTitle = title
  }

  return (
    <div className={clsx(styles.noResults, className && className)}>
      <Typography variant="h3" align="center">
        {noResultsTitle}
      </Typography>
      {noResultsBody && <Typography variant="subTitle">{noResultsBody}</Typography>}
    </div>
  )
}
