import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import styles from './TitleSection.module.scss'

export const TitleSection = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.titleSectionWrapper}>
      <Typography variant="headline2Bold">{t('START.TEASERS')}</Typography>
      <Typography variant="body">
        Hier finden Sie unsere aktuelle Monatswerbung sowie weitere Informationen zu unseren
        Empfehlungen. Profitieren Sie von exklusiven Rabatten und Sonderangeboten. Durchstöbern Sie
        jetzt unsere Empfehlungen und sichern Sie sich unschlagbare Preise.
      </Typography>
    </div>
  )
}
