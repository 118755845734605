import { createContext, useContext, useState } from 'react'

const initialEmptyFunction = () => {
  //
}

type ContextType = {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

export const CartTemplatesListContext = createContext<ContextType>({
  searchTerm: '',
  setSearchTerm: initialEmptyFunction,
})

export const CartTemplatesListContextProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  return (
    <CartTemplatesListContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </CartTemplatesListContext.Provider>
  )
}

export const useCartTemplatesListContext = () => {
  return useContext(CartTemplatesListContext)
}
