import { useCallback, useReducer } from 'react'
import {
  CartTemplateDetailsPageState,
  cartTemplatesListReducer,
  initialCartTemplateDetailsState,
} from './cartTemplateDetailsReducer'
import { CartTemplateForDetailsPage } from '@obeta/models/lib/schema-models/cart-template-details'
import { MaybeCompleteCartTemplateDetailsItem } from '@obeta/models/lib/schema-models'

type Functions = {
  search: (searchTerm: string) => void
  changePage: (page: number) => void
  receiveItems: () => void
  receiveProducts: () => void
  updateCartTemplateState: (cartTemplate: CartTemplateForDetailsPage) => void
  updateCartTemplateItemsState: (items: MaybeCompleteCartTemplateDetailsItem[]) => void
  getAmountOfPages: (template: CartTemplateForDetailsPage) => number
  getSelectedCartTemplateItemsLength: () => number
  selectItem: (cartTemplateItemId: string) => void
  resetSelectedItems: () => void
  selectAllItems: () => void
}

export type CartTemplateDetailsStateHandler = {
  state: CartTemplateDetailsPageState
  paginatedItems: MaybeCompleteCartTemplateDetailsItem[]
  offset: number
  limit: number
} & Functions
type Hook = () => CartTemplateDetailsStateHandler

const ITEMS_PER_PAGE = 16

export const useCartTemplateDetailsState: Hook = () => {
  const [state, dispatch] = useReducer(cartTemplatesListReducer, initialCartTemplateDetailsState)

  const search = useCallback(
    (searchTerm: string) => dispatch({ type: 'CHANGE_SEARCH_TERM', payload: searchTerm }),
    []
  )
  const changePage = useCallback(
    (page: number) => dispatch({ type: 'CHANGE_PAGE', payload: page }),
    []
  )
  const updateCartTemplateState = useCallback(
    (cartTemplate: CartTemplateForDetailsPage) =>
      dispatch({ type: 'UPDATE_CART_TEMPLATE', payload: cartTemplate }),
    []
  )
  const updateCartTemplateItemsState = useCallback(
    (items: MaybeCompleteCartTemplateDetailsItem[]) =>
      dispatch({ type: 'UPDATE_CART_TEMPLATE_ITEMS', payload: items }),
    []
  )

  // Batch operations
  const selectItem = useCallback(
    (cartTemplateItemId: string) => dispatch({ type: 'SELECT_ITEM', payload: cartTemplateItemId }),
    []
  )
  const resetSelectedItems = useCallback(() => dispatch({ type: 'RESET_SELECTED_ITEMS' }), [])
  const selectAllItems = useCallback(() => dispatch({ type: 'SELECT_ALL_ITEMS' }), [])

  const receiveItems = useCallback(() => dispatch({ type: 'FETCHED_ITEMS' }), [])
  const receiveProducts = useCallback(() => dispatch({ type: 'FETCHED_PRODUCTS' }), [])

  const getAmountOfPages = (template: CartTemplateForDetailsPage) => {
    if (!template.cartTemplateItems?.items) return 0
    const amountOfItems = template.cartTemplateItems.resultsCount ?? template.itemCount
    let amountOfPages = 0
    if (amountOfItems > ITEMS_PER_PAGE) {
      amountOfPages = Math.ceil(amountOfItems / ITEMS_PER_PAGE)
    }
    return amountOfPages
  }

  const getSelectedCartTemplateItemsLength = (): number => {
    // Note: Use resultsCount when searching and/or filtering vs. itemCount if NO search or filter applied
    // SELECT ALL W/ OR W/OUT EXCLUDE
    if (state.selectedCartTemplateItems.selectAll) {
      // filter or search applied
      if (state.selectedCartTemplateItems.search.searchTerm !== '') {
        if (state.selectedCartTemplateItems.exclude.length) {
          if (!state.cartTemplate?.cartTemplateItems?.resultsCount) {
            return 0
          }
          return (
            state.cartTemplate?.cartTemplateItems?.resultsCount -
              state.selectedCartTemplateItems.exclude.length ?? 0
          )
        }
        return state.cartTemplate?.cartTemplateItems?.resultsCount ?? 0
        // no filter or search applied
      } else {
        if (state.selectedCartTemplateItems.exclude.length) {
          if (!state.cartTemplate?.itemCount) {
            return 0
          }
          return state.cartTemplate?.itemCount - state.selectedCartTemplateItems.exclude.length ?? 0
        }
        return state.cartTemplate?.itemCount ?? 0
      }
    }
    // INCLUDE ONLY
    if (state.selectedCartTemplateItems.include.length) {
      return state.selectedCartTemplateItems.include.length
    }
    return 0
  }

  const limit = ITEMS_PER_PAGE
  const offset = (state.page - 1) * limit

  const paginatedItems = state.items ?? []
  return {
    state,
    offset,
    limit,
    search,
    changePage,
    receiveItems,
    getAmountOfPages,
    getSelectedCartTemplateItemsLength,
    receiveProducts,
    updateCartTemplateState,
    updateCartTemplateItemsState,
    paginatedItems,
    selectItem,
    resetSelectedItems,
    selectAllItems,
  }
}
