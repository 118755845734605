export enum OfferPriceOptions {
  NoSumPrice = 'noSumPrice',
  Price = 'price',
  Fixed = 'fixed',
  Percentage = 'percentage',
}

type PriceType = {
  listPrice?: boolean
  catalogPrice?: boolean
  metalSurcharge?: boolean
}

export enum PercentagePriceBaseValueOption {
  Net = 'net',
  List = 'list',
  Catalog = 'catalog',
}

export enum ShowPricesWithVAT {
  Exclusive = 'exclusive',
  Inclusive = 'inclusive',
}

type OfferPrice = {
  option: OfferPriceOptions
  fixedPriceValue: string | number
  percentagePriceBaseValue?: PercentagePriceBaseValueOption
  priceMarkup: string | number
}

export interface IIndividualConfig {
  type: DatsheetPrintOptions.INDIVIDUAL
  showPricesWithVAT?: ShowPricesWithVAT
  offerPrice?: OfferPrice
  priceType: PriceType
}

interface StandartConfig {
  type: DatsheetPrintOptions.STANDART
}

export type DatasheetConfig = IIndividualConfig | StandartConfig

export enum DatsheetPrintOptions {
  STANDART = 'standart',
  INDIVIDUAL = 'individual',
}
