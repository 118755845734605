import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import {
  belowMdHeightQueryLandscape,
  belowMdWidthQueryPortrait,
  useMediaQuery,
} from '@obeta/data/lib/hooks'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { StatusBar } from '@capacitor/status-bar'

interface Props {
  isCustomerCardOpen: boolean
  customerMainCardVisibilityHandler: (isOpen: boolean) => void
}

export const CustomerMainManagerContextDefaultValues = {
  isCustomerCardOpen: false,
  customerMainCardVisibilityHandler: () => {
    //
  },
}

export const CustomerMainManagerContext = React.createContext<Props>(
  CustomerMainManagerContextDefaultValues
)

export const useCustomerMainManager = () => {
  return useContext(CustomerMainManagerContext)
}

const isIos = isPlatform('ios')

export const CustomerMainManagerProvider: React.FC = (props) => {
  const [isCustomerCardOpen, setCustomerCardOpen] = useState(false)
  const { isLoggedIn } = useUserDataV2()

  const isBelowMdHeight = useMediaQuery(belowMdHeightQueryLandscape)
  const isBelowMdWidth = useMediaQuery(belowMdWidthQueryPortrait)

  useEffect(() => {
    const runLockScreenManager = async () => {
      const isMobileDimension = isBelowMdHeight || isBelowMdWidth

      if (isMobileDimension) {
        if (isIos) {
          await ScreenOrientation.lock({ orientation: 'landscape-primary' })
        } else {
          await ScreenOrientation.lock({ orientation: 'landscape-secondary' })
        }
      }
    }

    const isRunLockScreenForCustomerCard = isLoggedIn && isCustomerCardOpen && !isPlatform('web')

    isRunLockScreenForCustomerCard && runLockScreenManager()
  }, [isLoggedIn, isBelowMdHeight, isBelowMdWidth, isCustomerCardOpen])

  useEffect(() => {
    // We need to ensure that every time the isCustomerCardOpen state changes to false, the unlock function will be executed.
    const runUnlockScreenManager = async () => {
      await ScreenOrientation.unlock()
      await StatusBar.show()
    }

    !isCustomerCardOpen && !isPlatform('web') && runUnlockScreenManager()
  }, [isCustomerCardOpen])

  const customerMainCardVisibilityHandler = useCallback(async (isOpen: boolean) => {
    if (isOpen && !isPlatform('web')) await StatusBar.hide()

    setCustomerCardOpen(isOpen)
  }, [])

  const contextValue = useMemo(
    () => ({
      isCustomerCardOpen,
      customerMainCardVisibilityHandler,
    }),
    [isCustomerCardOpen, customerMainCardVisibilityHandler]
  )

  return (
    <CustomerMainManagerContext.Provider value={contextValue}>
      {props.children}
    </CustomerMainManagerContext.Provider>
  )
}
