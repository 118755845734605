import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import { debounce } from '@obeta/utils/lib/debounce'
import { useSuppliersOverviewProvider } from '../../hooks/useSuppliersOverviewProvider'
import { TabFilters } from '@obeta/components/lib/tab-filters/TabFilters'
import { Typography } from '@mui/material'
import styles from './SuppliersOverviewControlSection.module.scss'

export enum SuppliersNewsCategory {
  All = 'All',
  Tecselect = 'hasTecselect',
  Voltimum = 'hasVoltimum',
}

export const MATH_SUPPLIERS_CAREGORY_FILTER_LABEL = {
  [SuppliersNewsCategory.All]: 'SUPPLIERS_OVERVIEW.SUPPLIER_NEWS_TYPE.ALL',
  [SuppliersNewsCategory.Tecselect]: 'SUPPLIERS_OVERVIEW.SUPPLIER_NEWS_TYPE.TECSELECT',
}

export const SuppliersOverviewControlSection: FC = () => {
  const { updateFilters, filters, totals } = useSuppliersOverviewProvider()
  const { t } = useTranslation()

  const filterTabsOptions = [
    {
      label: t(MATH_SUPPLIERS_CAREGORY_FILTER_LABEL[SuppliersNewsCategory.All]),
      value: SuppliersNewsCategory.All,
      disabled: totals.all === 0,
      total: totals.all,
    },
    {
      label: t(MATH_SUPPLIERS_CAREGORY_FILTER_LABEL[SuppliersNewsCategory.Tecselect]),
      value: SuppliersNewsCategory.Tecselect,
      disabled: totals.hasTecselect === 0,
      total: totals.hasTecselect,
    },
    //TODO we cant filter for voltimum currenlty so we remove the tab until this is clarified see #cod-16669
    // {
    //   label: t('SUPPLIERS_OVERVIEW.SUPPLIER_NEWS_TYPE.VOLTIMUM'),
    //   value: SuppliersNewsCategory.Voltimum,
    //   disabled: true,
    // },
  ]

  const updateNewsCategoryFilter = (value: string) => {
    if (value === 'All') {
      updateFilters({ ...filters, hasTecselect: null })
    } else {
      updateFilters({ ...filters, [value]: true })
    }
  }

  const updateSearchNewsFilter = (value) => updateFilters({ ...filters, name: value })

  const getActiveTab = () => {
    if (filters.hasTecselect) return SuppliersNewsCategory.Tecselect

    return SuppliersNewsCategory.All
  }

  return (
    <div className={styles.controlWrapper}>
      <Typography variant="headline3Bold">{t('SUPPLIERS_OVERVIEW.ALL_SUPPLIERS_TITLE')}</Typography>
      <div className={styles.searchWrapper}>
        <SearchField
          placeholder={t('SUPPLIERS_OVERVIEW.SUPPLIERS_OVERVIEW_SEARCH_PLACEHOLDER')}
          onChange={debounce(updateSearchNewsFilter, 500)}
        />
      </div>
      <div className={styles.supplierTypeFilterWrapper}>
        <TabFilters<string>
          options={filterTabsOptions}
          onChangeTab={updateNewsCategoryFilter}
          activeTab={getActiveTab()}
        />
      </div>
    </div>
  )
}
