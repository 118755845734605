import React from 'react'

/**
 * The hook wraps a state together with a useEffect, to allow calling a
 * callback function on state change, but after rerender cycle
 *
 * @param initialState initial value of the state
 * @param callback gets called after the state has changed
 */
export const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = React.useState(initialState)

  const didMount = React.useRef(false)

  React.useEffect(() => {
    if (didMount.current) {
      callback(state)
    } else {
      didMount.current = true
    }
  }, [state, callback])

  return [state, setState]
}
