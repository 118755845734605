import React, { useEffect, useRef, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { PromotionHeader } from './promotionHeader'
import { PromotionContent } from './promotionContent'
import { PromotionTerms } from './promotionTerms'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'
import { IPromotionData } from '@obeta/models/lib/models/Promotion/Promotion'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { usePromotionAvailability } from './hooks/usePromotionAvailability'
import styles from './promotionPage.module.scss'

export const PromotionPage: React.FC = () => {
  const apolloClient = useStrapiGql()
  const { id: promotionId } = useParams<{ id: string }>()
  const [promotionData, setPromotionData] = useState<IPromotionData | null>(null)
  usePromotionAvailability(promotionData)
  const didTrackPageView = useRef(false)

  useEffect(() => {
    if (
      promotionData &&
      promotionData.name &&
      promotionData.name.length > 0 &&
      !didTrackPageView.current
    ) {
      trackCustom('page-view-with-name', {
        name: promotionData.name,
        combinedPathAndName: `${window.location.pathname} (${promotionData.name})`,
      })
      didTrackPageView.current = true
    }
  }, [promotionData])

  return (
    <div className={styles['promotion-page-wrapper']}>
      <ApolloProvider client={apolloClient}>
        <PromotionHeader setData={setPromotionData} promotionId={promotionId} />
      </ApolloProvider>
      <PromotionContent
        title={promotionData?.name}
        query={promotionData?.algoliaQuery}
        promotionId={promotionId}
      />
      <ApolloProvider client={apolloClient}>
        <PromotionTerms />
      </ApolloProvider>
    </div>
  )
}
