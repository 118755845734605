import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import styles from './CartTemplateCreateDialog.module.scss'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import clsx from 'clsx'

// Assets
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// UI
import { FormHelperText } from '../form-helper-text/FormHelperText'
import {
  CreateNewTemplateGqlResultAction,
  TemplateActionTypes,
} from '@obeta/data/lib/actions/template-actions'
import { useActionNotification } from '@obeta/data/lib/hooks'

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (name: string) => void
}

export const CartTemplateCreateDialog: React.FC<Props> = (props) => {
  const { open, onClose, onSubmit } = props
  const { mobile, tabletAll } = useBreakpoints()
  const { t } = useTranslation()

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
  })

  const waitForCreateNewTemplateAction = useActionNotification(
    TemplateActionTypes.CreateNewTemplateGqlResult
  )

  const onNameSubmit = (values: { name: string }) => {
    onSubmit(values.name)
    waitForCreateNewTemplateAction((action: CreateNewTemplateGqlResultAction) => {
      if (action.errorMessage === 'Template already exists') {
        setError('name', { type: 'taken' })
      }
    })
  }

  let templateNameErrorText = ''

  if (errors?.name?.type === 'required') {
    templateNameErrorText = t('TEMPLATES.TEMPLATE_NAME_ERROR_REQUIRED')
  } else if (errors?.name?.type === 'maxLength') {
    templateNameErrorText = t('TEMPLATES.TEMPLATE_NAME_ERROR_MAX_LENGTH')
  } else if (errors?.name?.type === 'taken') {
    templateNameErrorText = t('TEMPLATES.TEMPLATE_NAME_ERROR_TAKEN')
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // dialogFixed class: Workaround for bug: https://github.com/mui/material-ui/issues/23032
      className={clsx(styles.dialog, (mobile || tabletAll) && styles.dialogFixed)}
    >
      <DialogTitle className={styles.title}>
        <Typography variant={'boldText'}>{t('TEMPLATES.DIALOG_NAME_TEMPLATE')}</Typography>
        <Button
          className={styles.close}
          variant={'tertiary'}
          startIcon={<SvgIcon component={CloseIcon} color="secondary" />}
          onClick={onClose}
        />
      </DialogTitle>
      <form className={styles.form} onSubmit={handleSubmit(onNameSubmit)}>
        <DialogContent className={styles.content}>
          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 20,
            }}
            name="name"
            render={({ field: { value, onBlur, onChange } }) => (
              <>
                <TextField
                  autoFocus
                  className={clsx(errors.name && styles.textFieldInputError)}
                  fullWidth
                  placeholder={t('TEMPLATES.TEMPLATE_NAME')}
                  type="text"
                  value={value}
                  variant="outlined"
                  onBlur={onBlur}
                  onChange={onChange}
                />
                {errors.name && <FormHelperText errorText={templateNameErrorText} />}
              </>
            )}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button disabled={!isValid} variant="contained" color="secondary" type="submit">
            {t('TEMPLATES.DIALOG_CREATE_TEMPLATE')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
