import React from 'react'
import styles from './TelephoneRequiredPopUp.module.scss'
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  TextField,
  Box,
  SvgIcon,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { Controller, useForm } from 'react-hook-form'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'

interface Props {
  isOpen: boolean
  saveTelephoneNumber: (telephone: string) => void
  setShowTelephoneRequiredModal: (show: boolean) => void
}

export const TelephoneRequiredPopUp: React.FC<Props> = (props) => {
  const { isOpen, saveTelephoneNumber, setShowTelephoneRequiredModal } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const {
    control,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onChange',
  })

  const handleUpdateTelephone = () => {
    saveTelephoneNumber(getValues('phone'))
    setShowTelephoneRequiredModal(false)
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.dialog}
    >
      <Typography variant={'headline4Bold'}>
        {t('SHOPPING_CART.CHECKOUT.ERROR.MISSING_DATA')}
      </Typography>
      <Typography variant={'body'} className={styles.marginTop}>
        {t('SHOPPING_CART.CHECKOUT.ENTER_PHONE_NUMBER')}
      </Typography>
      <Typography variant={'body'} className={styles.marginTop}>
        {`${t('SHOPPING_CART.CHECKOUT.PHONE')}*`}
      </Typography>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            id="phone"
            name="phone"
            placeholder={'+49'}
            className={styles.telephoneField}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            helperText={
              errors.phone ? (
                <Box display={'flex'}>
                  <SvgIcon
                    className={styles.helperTextIcon}
                    component={WarningIcon}
                    fontSize={'small'}
                    htmlColor={theme.palette.primary.main}
                  />
                  <Typography variant={'smallText'} color={theme.palette.primary.main}>
                    {t('SHOPPING_CART.CHECKOUT.ERROR.PHONE_INVALID')}
                  </Typography>
                </Box>
              ) : (
                ''
              )
            }
          />
        )}
        name="phone"
        control={control}
        defaultValue={''}
        rules={{
          required: true,
          minLength: 6,
          maxLength: 20,
          // Only  +, - , and digits allowed
          pattern: /^[0-9+-]+$/,
        }}
      />
      <Typography variant={'smallText'} className={styles.requiredField}>
        {`*${t('SHOPPING_CART.CHECKOUT.REQUIRED_FIELD')}`}
      </Typography>
      <LightGrayDivider className={styles.divider} />
      <DialogActions>
        <Button
          disabled={!!errors.phone}
          variant={'contained'}
          color={'secondary'}
          onClick={handleUpdateTelephone}
          className={styles.saveButton}
        >
          {t('SHOPPING_CART.CHECKOUT.APPLY_PHONE_NUMBER')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
