import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { normalizeInt } from '@obeta/utils/lib/data-formatter/normalizeAmount'
import { useFiltersStateContext } from '../../hooks/useFiltersReducer'
import { IState } from '../../../../searchPage/filters/reducer'
import styles from './MobileSideBarFooter.module.scss'

export const MobileSideBarFooter = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation()
  const { hits: selectedFilterHits } = useArticlesSearchProvider()
  const { filtersState, dispatchFiltersAction } = useFiltersStateContext()
  const filtersStateBeforeFiltering = useRef<IState | null>(null)

  useEffect(() => {
    filtersStateBeforeFiltering.current = filtersState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Since we apply filters in runtime we only need to close modal to show results
  const handleApplyButtonClick = () => onClose()

  // Reset all filters to state before filtering
  const handleAbortButtonClick = () => {
    if (filtersStateBeforeFiltering.current) {
      dispatchFiltersAction({
        type: 'abortApplyFilters',
        payload: filtersStateBeforeFiltering.current,
      })

      onClose()
    }
  }

  return (
    <div className={styles.mobileSideBarFooterWrapper}>
      <Box display={'flex'} sx={{ gap: '0.75rem' }}>
        <Button className={styles.abortButton} color={'secondary'} onClick={handleAbortButtonClick}>
          {t('BUTTON.ABORT')}
        </Button>
        <Button
          className={styles.applyButton}
          variant={'contained'}
          color={'secondary'}
          onClick={handleApplyButtonClick}
        >
          {t('RENTABLE_ARTICLES.SHOW_N_RESULTS_plural', {
            ammount: normalizeInt(selectedFilterHits),
          })}
        </Button>
      </Box>
    </div>
  )
}
