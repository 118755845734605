import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getVoltimumPoints, VoltimumRequestItem } from '../actions/cart-actions'

type UseVoltimumPoints = (items: VoltimumRequestItem[]) => {
  isLoadingVoltimumPoints: boolean
  voltimumPoints: number
  sapIds: string[]
}

/**
 * Custom React hook for fetching and managing voltimum points.
 * The hook memoizes already fetched items and only fetches items with sap ids
 * which are not memoized
 *
 * @param {Array<VoltimumRequestItem>} items - Array of items to fetch voltimum points for.
 * @returns {Object} - Object containing voltimumPoints, isLoadingVoltimumPoints, and sapIds.
 */
export const useVoltimumPoints: UseVoltimumPoints = (items) => {
  const [sapIdsAndPoints, setSapIdsAndPoints] = useState<Array<{ sapId: string; points: number }>>(
    []
  )
  const [memoizedSapIds, setMemoizedSapIds] = useState<string[]>([])
  const [isLoadingVoltimumPoints, setIsLoadingVoltimumPoints] = useState<boolean>(true)
  const dispatch = useDispatch()

  useEffect(() => {
    const itemsToFetch = items.filter((item) => !memoizedSapIds.includes(item.sapId))
    if (itemsToFetch.length > 0) {
      dispatch(
        getVoltimumPoints(itemsToFetch, (sapIdsAndPointsFromCurrentFetch) => {
          setIsLoadingVoltimumPoints(false)
          const allSapIds = [
            ...sapIdsAndPoints.map(({ sapId }) => sapId),
            ...items.map(({ sapId }) => sapId),
          ]
          const uniqueSapIdsSet = new Set(allSapIds)
          const newMemoizedSapIds = Array.from(uniqueSapIdsSet)
          setSapIdsAndPoints([...sapIdsAndPoints, ...sapIdsAndPointsFromCurrentFetch])
          setMemoizedSapIds(newMemoizedSapIds)
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)])

  let sum = 0
  items.forEach((item) => {
    const foundItem = sapIdsAndPoints.find((tuple) => tuple.sapId === item.sapId)
    if (!foundItem) return
    sum += item.amount * foundItem.points
  })
  return {
    voltimumPoints: sum,
    isLoadingVoltimumPoints,
    sapIds: sapIdsAndPoints.map(({ sapId }) => sapId),
  }
}
