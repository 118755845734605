const calculateIndexesByRowsPerPage = (page: number, selectedRowsPerPage: number) => {
  const startIndex = page * selectedRowsPerPage
  const stopIndex = (page + 1) * selectedRowsPerPage - 1

  return { startIndex, stopIndex }
}

export function calculatePaginationIndexes<T>({
  currentPage,
  itemsPerPage,
  products,
  isFetching,
}: {
  currentPage: number
  itemsPerPage: number
  products: Array<T>
  isFetching: boolean
}) {
  if (products.length === 0 && !isFetching) {
    return { startIndex: 0, stopIndex: 0, actualItemsPerPage: 0 }
  }

  if (isFetching) {
    return { startIndex: 0, stopIndex: 4, actualItemsPerPage: 5 }
  }

  const { startIndex, stopIndex: _stopIndex } = calculateIndexesByRowsPerPage(
    currentPage,
    itemsPerPage
  )

  const stopIndex = Math.min(_stopIndex, products.length - 1)

  const actualItemsPerPage = Math.max(
    stopIndex !== startIndex ||
      (!isFetching && startIndex % itemsPerPage === 0 && stopIndex % itemsPerPage === 0)
      ? stopIndex - startIndex + 1
      : stopIndex - startIndex,
    0
  )

  return { startIndex, stopIndex, actualItemsPerPage }
}
