export const parseUnit = (rawUnit?: string) => {
  let unit = rawUnit
  let slice
  if (rawUnit && rawUnit.startsWith('%/')) {
    const els = /^%\/(.*)\s(.*)$/.exec(rawUnit)
    if (els?.length === 3) {
      slice = `je 100 ${els[1]}`
      unit = els[2]
    }
  }
  if (rawUnit && rawUnit.startsWith('/')) {
    const els = /^\/(.*)\s(.*)$/.exec(rawUnit)
    if (els?.length === 3) {
      slice = `je ${els[1]}`
      unit = els[2]
    }
  }

  return { unit, slice }
}
