import React, { memo } from 'react'
import stylesSearchFeedback from './SearchFeedback.module.scss'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TertiaryIconButton } from '../custom-button/CustomButton'
import { ReactComponent as Close2 } from 'assets/icon/close_2.svg'

interface Props {
  setShowThankYouThumbUp: (status: boolean) => void
}

export const ThankYouThumbUp: React.FC<Props> = memo((props) => {
  const { setShowThankYouThumbUp } = props
  const { t } = useTranslation()

  return (
    <div className={stylesSearchFeedback.mainWrapper}>
      <Box className={stylesSearchFeedback.boxTextAndCloseButton}>
        <Typography variant="bodyBold" className={stylesSearchFeedback.text}>
          {t('SEARCH.FEEDBACK.THANK_YOU_VERY_MUCH_FOR_YOUR_FEEDBACK')}
        </Typography>
        <TertiaryIconButton
          onClick={() => setShowThankYouThumbUp(false)}
          icon={<Close2 />}
        ></TertiaryIconButton>
      </Box>
    </div>
  )
})
