import { ButtonBase, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './ActionButton.module.scss'
import { E2EProps } from '../types'

interface IClasses {
  typography?: string
}

export interface IActionButtonProps extends React.ComponentProps<typeof ButtonBase> {
  icon?: JSX.Element
  className?: string
  typographyVariant?: React.ComponentProps<typeof Typography>['variant']
  typographyWeight?: React.ComponentProps<typeof Typography>['fontWeight']
  typographyComponent?: React.ElementType
  gap?: 'xxs' | 'xs'
  classes?: IClasses
  variant?: 'contained' | 'outlined'
  color?: 'red100pct' | 'darkGray' | 'lightGray'
  padding?: 'xxs' | 'xs' | 'md'
  verticalPadding?: 'xs'
  justifyContent?: 'left' | 'center' | 'right'
  noWrap?: boolean
  underline?: boolean
  rightAdornment?: JSX.Element | null
  bgColor?: 'white'
  stretch?: boolean
}

const variantsWithPadding: IActionButtonProps['variant'][] = ['contained', 'outlined']

/**
 * Unfortunately Mui Button component doesn't allow to pass typography props.
 * With this custom Button it is easier to implement design as you can pass typographyVariant
 * @param props
 * @returns
 */

export const ActionButton: React.FC<IActionButtonProps & E2EProps> = (props) => {
  const {
    icon,
    className,
    variant,
    color = 'red100pct',
    typographyVariant = 'bodyBold',
    gap = 'xs',
    padding: _padding,
    verticalPadding,
    classes,
    children,
    justifyContent = 'center',
    noWrap,
    underline = false,
    typographyComponent,
    rightAdornment,
    typographyWeight,
    bgColor,
    stretch,
    disabled,
    ...rest
  } = props

  let padding = _padding
  if (~variantsWithPadding.indexOf(variant) && !_padding) {
    padding = 'xs'
  }

  return (
    <ButtonBase
      className={clsx(
        styles.button,
        styles[`${gap}Gap`],
        styles[variant || ''],
        styles[color],
        styles[`${padding}Padding`],
        styles[justifyContent],
        styles[`${verticalPadding}VerticalPadding`],
        styles[`bgColor--${bgColor || ''}`],
        { [styles.stretch]: stretch },
        { [styles.disabled]: disabled },
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {icon}
      {children && (
        <Typography
          noWrap={noWrap}
          className={clsx({ [styles.underline]: underline }, classes?.typography)}
          variant={typographyVariant}
          color={''} //set color to empty so the default color prop does not apply, as the local styles overule
          fontWeight={typographyWeight}
          data-testid="button-label"
        >
          {children}
        </Typography>
      )}
      {rightAdornment}
    </ButtonBase>
  )
}
