import { Paper, Typography } from '@mui/material'
import { NotificationEnum } from '@obeta/models/lib/models/GraphQL/globalTypes'
import { Message as MessageModel } from '@obeta/models/lib/models/Message'
import { dateTimeFormatter } from '@obeta/utils/lib/dateFormatter'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorRemoveArticles } from './components/error-remove-articles/ErrorRemoveArticles'
import { FooterActionButton } from './components/footer-action-button/FooterActionButton'
import { Message } from './components/message/Message'
import styles from './MessageCard.module.scss'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

interface IMessageCard {
  message: MessageModel
  cartName: string
  articleList: string[]
  onDelete: () => void
}

export const MessageCard: React.FC<IMessageCard> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { message, onDelete } = props

  let title = ''
  let status = 'success'
  let body
  if (
    [NotificationEnum.OrderFailed, NotificationEnum.OrderIdMissingInResponse].indexOf(
      message.event
    ) !== -1
  ) {
    status = 'error'
    title = t('MESSAGE_CENTER.CANNOT_SUBMIT_CART_TITLE')
    body = (
      <ErrorRemoveArticles
        cartName={props.cartName}
        articlesList={props.articleList}
        support={''}
      />
    )
  } else if (message.event === NotificationEnum.CartNotified) {
    title = t('MESSAGE_CENTER.CART_REPORTED')
    body = (
      <Message>
        {t('MESSAGE_CENTER.CART_CAN_BE_SUBMITTED', {
          cartName: props.cartName,
          positions: props.articleList
            .map((item) => {
              return `"${item}"`
            })
            .join(', '),
          count: props.articleList.length,
        })}
      </Message>
    )
  } else if (message.event === NotificationEnum.OrderCreated) {
    title = t('MESSAGE_CENTER.CART_SENT')
    body = (
      <Message>
        {t('MESSAGE_CENTER.CART_SUBMITTED_SUCCESFULLY', {
          cartName: props.cartName,
        })}
      </Message>
    )
  }

  return (
    <Paper className={styles.card} data-name="message-card" data-since={message.createdAt}>
      <div className={styles.dateWrapper}>
        <Typography component="span" variant="body" className={styles.date}>
          {t('MESSAGE_CENTER.DATE', { date: dateTimeFormatter(message.createdAt) })}
        </Typography>
      </div>
      <div className={styles.header}>
        <span className={clsx(styles.status, styles[`status--${status}`])} />
        <Typography className={styles.title} component="span">
          {title}
        </Typography>
      </div>
      <div className={styles.body}>{body}</div>
      <div className={styles.footer}>
        <FooterActionButton onClick={onDelete}>
          {t('MESSAGE_CENTER.DELETE_MESSAGE')}
        </FooterActionButton>
        <FooterActionButton
          onClick={() => history.push('/shopping-cart-details/' + message.data.cartId)}
        >
          {t('MESSAGE_CENTER.OPEN_CART')}
        </FooterActionButton>
      </div>
    </Paper>
  )
}
