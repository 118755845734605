import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import { RadioItem } from '../../../../../checbox-radio-item/CheckboxRadioItem'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import { getCheckboxItemConfig } from '../../../../utils/getCheckboxItemConfig'
import {
  OfferPriceOptions,
  PercentagePriceBaseValueOption,
} from '@obeta/models/lib/models/Datasheet/IndividualDatasheet'
import { InputNumber } from '../../../../../input-number/InputNumber'
import styles from './OfferPriceSection.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

const pricePercentageOptions = [
  { value: PercentagePriceBaseValueOption.Net, label: 'NET_PRICE' },
  { value: PercentagePriceBaseValueOption.List, label: 'LIST_PRICE' },
  { value: PercentagePriceBaseValueOption.Catalog, label: 'CATALOG_PRICE' },
]

export const OfferPriceSection = () => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const { control, setValue, getValues } = useFormContext()

  const resetFixedPriceValue = (fixedPriceValue: string, selectedOption: OfferPriceOptions) => {
    if (fixedPriceValue && selectedOption !== OfferPriceOptions.Fixed) {
      setValue('offerPrice.fixedPriceValue', '')
    }
  }

  const resetPriceMarkup = (
    priceMarkup: string,
    selectedOption: OfferPriceOptions,
    percentagePriceBaseValue: PercentagePriceBaseValueOption
  ) => {
    if (
      (percentagePriceBaseValue || priceMarkup) &&
      selectedOption !== OfferPriceOptions.Percentage
    ) {
      setValue('offerPrice.priceMarkup', '')
      setValue('offerPrice.percentagePriceBaseValue', '')
    }
  }

  const resetFieldsAfterChangeOption = () => {
    const fixedPriceValue = getValues('offerPrice.fixedPriceValue')
    const priceMarkup = getValues('offerPrice.priceMarkup')
    const selectedOption = getValues('offerPrice.option')
    const percentagePriceBaseValue = getValues('offerPrice.percentagePriceBaseValue')

    resetFixedPriceValue(fixedPriceValue, selectedOption)
    resetPriceMarkup(priceMarkup, selectedOption, percentagePriceBaseValue)
  }

  return (
    <Box className={styles.wrapper}>
      <Controller
        defaultValue={OfferPriceOptions.Price}
        name="offerPrice.option"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Box className={styles.itemBox}>
                <RadioItem
                  label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.WITHOUT_COMBINED_PRICE_DISPLAY')}
                  onChange={() => {
                    onChange(OfferPriceOptions.NoSumPrice)
                    resetFieldsAfterChangeOption()
                  }}
                  checked={value === OfferPriceOptions.NoSumPrice}
                  {...getCheckboxItemConfig<OfferPriceOptions>(OfferPriceOptions.NoSumPrice)}
                />
              </Box>

              <Box className={styles.itemBox}>
                <RadioItem
                  label={t('INDIVIDUAL_DATASHEET.FORM_SECTION.PRICE')}
                  onChange={() => {
                    onChange(OfferPriceOptions.Price)
                    resetFieldsAfterChangeOption()
                  }}
                  checked={value === OfferPriceOptions.Price}
                  {...getCheckboxItemConfig<OfferPriceOptions>(OfferPriceOptions.Price)}
                />
              </Box>

              <Box className={styles.itemBoxSmallPadding}>
                <RadioItem
                  onChange={() => onChange(OfferPriceOptions.Fixed)}
                  checked={value === OfferPriceOptions.Fixed}
                  {...getCheckboxItemConfig<OfferPriceOptions>(OfferPriceOptions.Fixed)}
                />
                <Controller
                  name="offerPrice.fixedPriceValue"
                  control={control}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputNumber
                        InputProps={{ classes: { input: styles.inputNumber } }}
                        onFocus={() => {
                          setValue('offerPrice.option', OfferPriceOptions.Fixed)
                          resetFieldsAfterChangeOption()
                        }}
                        value={value}
                        onChange={(inputValue) => onChange(inputValue)}
                        placeholder={t('INDIVIDUAL_DATASHEET.FORM_SECTION.SET_IN_EURO')}
                      />
                    )
                  }}
                />
              </Box>

              <Box className={clsx(styles.itemBoxSmallPadding, styles.percentagePriceBaseValue)}>
                <div className={styles.percentagePriceBaseValueRow}>
                  <RadioItem
                    onChange={() => {
                      onChange(OfferPriceOptions.Percentage)
                      resetFieldsAfterChangeOption()
                    }}
                    checked={value === OfferPriceOptions.Percentage}
                    {...getCheckboxItemConfig<OfferPriceOptions>(OfferPriceOptions.Percentage)}
                  />

                  <Controller
                    name="offerPrice.percentagePriceBaseValue"
                    control={control}
                    // We needed to use defaultValue in react hooks form v6 to prevent a warning message, but in v7, it was fixed.
                    defaultValue={''}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className={styles.percentagePriceBaseValueSelect}
                          renderValue={
                            value
                              ? undefined
                              : () => (
                                  <Typography
                                    className={styles.percentagePriceBaseValuePlaceholder}
                                    variant="bodyBold"
                                  >
                                    {t(
                                      'INDIVIDUAL_DATASHEET.FORM_SECTION.PRICE_BASIS_FOR_PERCENTAGE_PRICE'
                                    )}
                                  </Typography>
                                )
                          }
                          onFocus={() => {
                            setValue('offerPrice.option', OfferPriceOptions.Percentage)
                            resetFieldsAfterChangeOption()
                          }}
                          displayEmpty
                          variant="outlined"
                          value={value || ''}
                          onChange={(e) => {
                            onChange(value === e.target.value ? undefined : e.target.value)
                          }}
                        >
                          {pricePercentageOptions.map((option) => (
                            <MenuItem
                              onClick={() => {
                                if (option.value === value) onChange(undefined)
                              }}
                              key={option.value}
                              value={option.value}
                              selected={option.value === value}
                            >
                              <Typography variant={value === option.value ? 'bodyBold' : undefined}>
                                {t(`INDIVIDUAL_DATASHEET.FORM_SECTION.${option.label}`)}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )
                    }}
                  />
                </div>
                <Controller
                  name="offerPrice.priceMarkup"
                  control={control}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className={styles.priceMarkupWrapper}>
                        <InputNumber
                          fullWidth={mobile}
                          onFocus={() => {
                            setValue('offerPrice.option', OfferPriceOptions.Percentage)
                            resetFieldsAfterChangeOption()
                          }}
                          value={value}
                          onChange={(inputValue) => onChange(inputValue)}
                          InputProps={{
                            classes: { input: styles.inputNumber, root: styles.priceMarkupRoot },
                          }}
                          placeholder={t(
                            'INDIVIDUAL_DATASHEET.FORM_SECTION.PRICE_SURCHARGE_PERCENTAGE'
                          )}
                        />
                      </div>
                    )
                  }}
                />
              </Box>
            </>
          )
        }}
      />
    </Box>
  )
}
