import { FC } from 'react'
import { Marker } from '@react-google-maps/api'
import ObetaLocationMarker from 'assets/icon/designsystem/obeta location.svg'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'

interface IStoresMapMarkersProps {
  activeStore: StoreV2 | null
  currentStores?: StoreV2[]
  onSetActiveStore: (store: StoreV2) => void
}

export const StoresMapMarkers: FC<IStoresMapMarkersProps> = ({
  onSetActiveStore,
  currentStores,
  activeStore,
}) => {
  if (!currentStores?.length) {
    return null
  }

  return (
    <>
      {currentStores.map((store) => {
        return (
          <Marker
            onClick={() => onSetActiveStore(store)}
            position={{
              lat: parseFloat(store.latitude),
              lng: parseFloat(store.longitude),
            }}
            visible={
              !!currentStores?.length &&
              currentStores.find((currStore) => currStore.id === store.id) !== undefined
            }
            key={store.id}
            icon={{
              url: ObetaLocationMarker,
              scaledSize:
                activeStore?.id === store.id
                  ? new window.google.maps.Size(51, 51)
                  : new window.google.maps.Size(36, 36),
            }}
          ></Marker>
        )
      })}
    </>
  )
}
